<template>
     <div v-if="footer_menu.page2 && on_job" style="margin:-20px 0px 70px 0px;">
        <el-card class="box-card" shadow="never" v-for="app in apps" :key="app.type_title">
            <h4>{{ app.type_title }}</h4>
            <el-row :gutter="30">
                <el-col :span="6" v-for="item in app.type_app" :key="item.label">
                    <router-link :to="item.link_url">
                        <div>
                        <el-image class="app-icon" fit="contain" :src="item.icon_url"></el-image>
                        </div>
                    <span class="app-span">{{ item.label }}</span>
                    </router-link> 
                </el-col>
            </el-row>
        </el-card>
        <el-card class="footer">
            <el-row type="flex" class="row-bg" justify="space-between">
                <el-col :span="6">
                    <div style="margin-bottom: -3px;" @click="select_menu(1)">
                        <el-image fit="cover" :src="footer_menu.image_src1" alt=""></el-image>
                    </div>
                    <div :style="footer_menu.style1" @click="select_menu(1)">首页</div>
                </el-col>
                <el-col :span="6">
                    <div style="margin-bottom: -3px;" @click="select_menu(2)">
                        <el-image fit="cover" :src="footer_menu.image_src2" alt=""></el-image>
                    </div>
                    <div :style="footer_menu.style2" @click="select_menu(2)">应用</div>
                </el-col>
                <el-col :span="6">
                    <div style="margin-bottom: -3px;" @click="select_menu(3)">
                        <el-image fit="cover" :src="footer_menu.image_src3" alt=""></el-image>
                    </div>
                    <div :style="footer_menu.style3" @click="select_menu(3)">我的</div>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import conf from '../conf'
import qs from 'qs'
import axios from 'axios'
export default {
    data() {
        return {
            on_job: false,
            // pnl: '',
            // pname: '',
            // squareUrl: '',
            // nickname: '',
            // sec_nm: '',
            // gw_sn: '',
            // zw_nm: '',
            // work_age: 0,
            footer_menu: {
                page1: false,
                image_src1: require('@/assets/icon/iconPark24/606266/首页_home.svg'),
                style1: {
                    color: '#606266',
                },
                page2: true,
                image_src2: require('@/assets/icon/iconPark24/全部_all-application.svg'),
                style2: {
                    color: '#409EFF',
                },
                page3: false,
                image_src3: require('@/assets/icon/iconPark24/606266/用户_use.svg'),
                style3: {
                    color: '#606266',
                }
            },
            // apps: [],
            apps: [{
                type_title: '人力行政',
                type_app: [{
                    link_url: '/proposal',
                    icon_url: require('@/assets/icon/md/持续改善.png'),
                    label: '持续改善'
                },{
                    link_url: '/questionList',
                    icon_url: require('@/assets/icon/md/我要提问.png'),
                    label: '我要提问'
                },{
                    link_url: '/sportsclock',
                    icon_url: require('@/assets/icon/md/运动打卡.png'),
                    label: '运动打卡'
                },{
                    link_url: '/blackList',
                    icon_url: require('@/assets/icon/md/黑名单.png'),
                    label: '黑名单'
                },{
                    link_url: '/workOrderList',
                    icon_url: require('@/assets/icon/md/工务维修.png'),
                    label: '工务维修'
                },{
                    link_url: '/weekMenuList',
                    icon_url: require('@/assets/icon/md/餐厅菜谱.png'),
                    label: '菜谱管理'
                },{
                    link_url: '/ehsCheckList',
                    icon_url: require('@/assets/menu/EHS.png'),
                    label: 'EHS'
                },{
                    link_url: '/roomCheckList',
                    icon_url: require('@/assets/icon/md/宿舍7S.png'),
                    label: '宿舍7S'
                },{
                    link_url: '/remoteClock',
                    icon_url: require('@/assets/icon/md/远程打卡.png'),
                    label: '远程打卡'
                },{
                    link_url: '/deliverWaterList',
                    icon_url: require('@/assets/icon/md/桶装水.png'),
                    label: '送水列表'
                },{
                    link_url: '/dinnerOrderList',
                    icon_url: require('@/assets/icon/md/桶装水.png'),
                    label: '餐厅订餐'
                },{
                    link_url: '/dinnerAdmin',
                    icon_url: require('@/assets/icon/md/桶装水.png'),
                    label: '订餐管理'
                }]
            },{
                type_title: '制造MES',
                type_app: [{
                    link_url: '/inputOutput6',
                    icon_url: require('@/assets/icon/md/投入产出.png'),
                    label: '投入产出'
                },{
                    link_url: '/workInProcess',
                    icon_url: require('@/assets/icon/md/在制明细.png'),
                    label: '在制明细'
                },{
                    link_url: '/preDispatch2',
                    icon_url: require('@/assets/icon/md/部件接收.png'),
                    label: '部件接收'
                },{
                    link_url: '/equipment',
                    icon_url: require('@/assets/icon/md/电脑车.png'),
                    label: '电脑车'
                },{
                    link_url: '/productInventory2',
                    icon_url: require('@/assets/icon/md/成品库存.png'),
                    label: '成品库存'
                },{
                    link_url: '/checkList',
                    icon_url: require('@/assets/icon/md/生产巡检.png'),
                    label: '生产巡检'
                },{
                    link_url: '/workInProcess',
                    icon_url: require('@/assets/icon/md/首件确认.png'),
                    label: '首件确认'
                },{
                    link_url: '/inputOutput4',
                    icon_url: require('@/assets/icon/md/线别预警.png'),
                    label: '线别预警'
                },{
                    link_url: '/secYcRecord',
                    icon_url: require('@/assets/icon/md/线别异常.png'),
                    label: '线别异常'
                }]
            },{
                type_title: '件资提报',
                type_app: [{
                    link_url: '/pieceCapital',
                    icon_url: require('@/assets/icon/md/员工件资.png'),
                    label: '员工件资'
                },{
                    link_url: '/pieceCapitalStatus2',
                    icon_url: require('@/assets/icon/md/件资状况.png'),
                    label: '件资状况'
                },{
                    link_url: '/pieceCapitalLeave',
                    icon_url: require('@/assets/icon/md/离职件资.png'),
                    label: '离职件资'
                },{
                    link_url: '/partProcess',
                    icon_url: require('@/assets/icon/md/工艺车间.png'),
                    label: '工艺车间'
                },{
                    link_url: '/surfaceProcess',
                    icon_url: require('@/assets/icon/md/帮面件资.png'),
                    label: '帮面件资'
                },{
                    link_url: '/formingProcess',
                    icon_url: require('@/assets/icon/md/成型件资.png'),
                    label: '成型件资'
                },{
                    link_url: '/blankingProcess',
                    icon_url: require('@/assets/icon/md/冲裁件资.png'),
                    label: '冲裁件资'
                },{
                    link_url: '/shoelacesProcess',
                    icon_url: require('@/assets/icon/md/鞋带组.png'),
                    label: '鞋带组'
                },{
                    link_url: '/packagingProcess',
                    icon_url: require('@/assets/icon/md/大包装.png'),
                    label: '大包装'
                },{
                    link_url: '/surfaceProcessAudit',
                    icon_url: require('@/assets/icon/md/帮面审核.png'),
                    label: '帮面审核'
                },{
                    link_url: '/formingProcessAudit',
                    icon_url: require('@/assets/icon/md/成型审核.png'),
                    label: '成型审核'
                },{
                    link_url: '/ShoelacesProcessAudit',
                    icon_url: require('@/assets/icon/md/鞋带组审核.png'),
                    label: '鞋带组审核'
                },{
                    link_url: '/packagingProcessAudit',
                    icon_url: require('@/assets/icon/md/大包装审核.png'),
                    label: '大包装审核'
                }]
            },{
                type_title: '开发样品',
                type_app: [{
                    link_url: '/sample',
                    icon_url: require('@/assets/icon/md/样鞋借用.png'),
                    label: '样鞋借用'
                },{
                    link_url: '/sampleManage',
                    icon_url: require('@/assets/icon/md/样鞋管理.png'),
                    label: '样鞋管理'
                },{
                    link_url: '/sampleCompletion2',
                    icon_url: require('@/assets/icon/md/样品完工.png'),
                    label: '样品完工'
                }]
            },{
                type_title: '固定资产',
                type_app: [{
                    link_url: '/maintain',
                    icon_url: require('@/assets/icon/md/设备保养.png'),
                    label: '设备保养'
                },{
                    link_url: '/repairOrderList',
                    icon_url: require('@/assets/icon/md/设备报修.png'),
                    label: '设备报修'
                },{
                    link_url: '/feedbackList',
                    icon_url: require('@/assets/icon/md/线别异常.png'),
                    label: '异常反馈'
                }]
            }],
        }
    },
    methods: {
        select_menu(id) {
            if(id === 1) {
                window.location.href = '/homeIndex'
            }else if(id === 2) {
                window.location.href = '/homeApps'
            }else if(id === 3) {
                window.location.href = '/homeInfo'
            }
        },
        async GetAllApps() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WeChat/GetAllApps', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                res.data.ResponseData.forEach(item => {
                    // require('@/assets/icon/md/设备报修.png'),
                    // var path = '@/assets/icon/md/' + item.label + '.png'
                    // item.icon_url = require('@/assets/icon/md/' + item.label + '.png')
                    item.icon_url = conf.image_domain + item.FImgPath
                    this.apps.push(item)
                })
            }
        },
    },
    mounted() {
        this.$route.meta.title = '应用中心'
        window.document.title = '应用中心'
        // this.GetAllApps()
    },
    created() {
        const on_job = localStorage.getItem('ON_JOB')
        if(on_job) {
            this.on_job = on_job === 'Y' ? true : false
        }
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            // this.pnl = '55540' // dev
        }

        // this.pnl = '66135' // dev
        // this.on_job = true  // dev
    }
}
</script>

<style scoped>
    a:link { 
        text-decoration: none;
        color: #606266;
    }
    a:visited {
        text-decoration: none;
        color: #606266;
    }
    a:active { 
        text-decoration: blink;
        color: #606266;
    }
    .app-icon {
        width: 40px;
        height: 40px;
        margin-bottom: 4px;
        margin-top: 20px;
    }
    .app-span {
        font-size: 12px; 
        /* font-weight: 550; */
    }
    .box-card {
        padding: 0px 0px 15px 0px;
        margin-bottom: 5px;
        border-radius: 8px;
        /* margin: 15px -10px 5px -10px; */
    }
    .box-card h4 {
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 0px 10px 20px;
        margin: -20px -20px 10px -20px;
        background-color: rgb(217, 236, 255);
    }
    .footer {
        position: fixed; /* 使用fixed定位 */
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding: 0px 0px 15px 0px;
        font-size: 11px;
    }
</style>