<template>
    <div>
        <el-form :model="ehsForm" :rules="rules" ref="ehsForm" label-width="110px" size="medium">
            <el-form-item label="提报工号" prop="pnl">
                <el-input v-model="ehsForm.pnl" placeholder="请输入员工工号" size="medium" @blur="getPersonByPnl"></el-input>
            </el-form-item>
            <el-form-item label="提报姓名" prop="pname">
                <el-input v-model="ehsForm.pname" size="medium" :disabled="true"></el-input>
            </el-form-item>
            <!-- <el-form-item label="所在部门" prop="sec_nm">
                <el-input v-model="ehsForm.sec_nm" size="medium" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="员工职务" prop="zw_nm">
                <el-input v-model="ehsForm.zw_nm" size="medium" :disabled="true"></el-input>
            </el-form-item> -->
            <el-form-item label="提报日期" prop="idate">
                <el-date-picker
                    v-model="ehsForm.idate"
                    size="medium"
                    type="date"
                    align="center"
                    style="width: 100%"
                    placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="问题类别" prop="type" v-if="ehsForm.isManager">
                <el-select 
                    v-model="ehsForm.type" 
                    placeholder="请选择问题类别" 
                    size="medium" 
                    @change="typeChange" 
                    style="width: 100%">
                    <el-option
                        v-for="item in ehsForm.ehs_type"
                        :key="item.FID"
                        :label="item.FNAME"
                        :value="item.FID">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="二级类别" prop="types" v-if="ehsForm.isManager">
                <el-select 
                    v-model="ehsForm.types" 
                    placeholder="请选择二级类别" 
                    size="medium" 
                    @change="typesChange" 
                    :disabled="ehsForm.types_disabled"
                    style="width: 100%">
                    <el-option
                        v-for="item in ehsForm.ehs_types"
                        :key="item.FID"
                        :label="item.FNAME"
                        :value="item.FID">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="三级类别" prop="typess" v-if="ehsForm.isManager">
                <el-select 
                    v-model="ehsForm.typess" 
                    placeholder="请选择三级类别" 
                    size="medium" 
                    @change="typessChange"
                    :disabled="ehsForm.typess_disabled"
                    style="width: 100%">
                    <el-option
                        v-for="item in ehsForm.ehs_typess"
                        :key="item.FID"
                        :label="item.FNAME"
                        :value="item.FID">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="问题等级" prop="grade" v-if="ehsForm.isManager">
                  <el-select 
                    v-model="ehsForm.grade" 
                    placeholder="请选择问题等级" 
                    size="medium" 
                    style="width: 100%">
                    <el-option
                        v-for="item in ehsForm.grades"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="整改部门" prop="zsec_nm">
                <el-select 
                    v-model="ehsForm.zsec_nm" 
                    placeholder="请选择整改部门" 
                    size="medium" 
                    @change="GetPNAME" 
                    style="width: 100%">
                    <el-option
                        v-for="item in sec_data"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="负责人" prop="zpnl">
                <el-select 
                    v-model="ehsForm.zpnl" 
                    placeholder="请选择负责人" 
                    size="medium" 
                    @change="SetPnl" 
                    style="width: 100%">
                    <el-option
                        v-for="item in pnl_data"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        <!-- <span style="float: left">{{ item.value }} - {{ item.label }} </span> -->
                        <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span> -->
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="抄送" prop="cspnl">
                <el-select 
                    multiple 
                    v-model="ehsForm.cspname" 
                    placeholder="请选择负责人" 
                    size="medium" 
                    @change="SetCSPnl" 
                    style="width: 100%">
                    <el-option
                        v-for="item in pnl_data"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        <span style="float: left">{{ item.value }} - {{ item.label }} </span>
                        <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span> -->
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="要求完成日期" prop="odate">
                <el-date-picker
                    v-model="ehsForm.odate"
                    size="medium"
                    type="date"
                    align="center"
                    style="width: 100%"
                    placeholder="选择要求完成日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="问题地址" prop="addr">
                <el-input v-model="ehsForm.addr" placeholder="请输入问题车间名称" size="medium"></el-input>
            </el-form-item>
            <el-form-item label="问题描述" prop="desc">
                <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入问题或者建议描述"
                    v-model="ehsForm.desc">
                </el-input>
            </el-form-item>
            <el-form-item label="问题图片" prop="image1">
                <el-upload 
                    action='' 
                    ref="upload"
                    :on-change="getFile" 
                    :limit="3" 
                    list-type="picture"
                    :on-remove="handleRemove"
                    :auto-upload="false" 
                    :on-exceed="handleExceed"
                    style="width: 100%;" >
                    <el-button size="medium" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item>
            <br> 
            <el-row style="margin-bottom: 20px">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="ehsForm.isLoading"
                        @click="submitForm('ehsForm')">提交问题</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf.js'
import moment from 'moment'
import { compressImage } from '../../plugin/compress.js'
export default {
    data() {
        return {
            ehsForm: {
                pnl: '',
                pname: '',
                sec_no: '',
                sec_nm: '',
                zw_no: '',
                zw_nm: '',
                zpnl: '',
                zpname: '',
                cspnl: '',
                cspname: '',
                zsec_no: '',
                zsec_nm: '',
                idate: moment().format('YYYY-MM-DD'),
                odate: '',
                desc: '',
                addr: '',
                ehs_total_type: {},
                ehs_type: [],
                ehs_types: [],
                ehs_typess: [],
                type:'',
                types:'',
                typess:'',
                typess_name: '',
                types_disabled: true,
                typess_disabled: true,
                isLoading: false,
                fileList: [],
                imageList: [],
                grade: '',
                isManager: false,
                grades: [{
                    label: '高',
                    value: '高'
                },{
                    label: '中',
                    value: '中'
                },{
                    label: '低',
                    value: '低'
                }]
            },
            sec_data: [],
            pnl_data: [],
            rules: {
                pnl: [
                    {required: true, message: '请输入员工工号', trigger: 'blur'}
                ],
                pname: [
                    {required: true, message: '请输入员工姓名', trigger: 'change'}
                ],
                // sec_nm: [
                //     {required: true, message: '请输入所在部门', trigger: 'change'}
                // ],
                // zw_nm: [
                //     {required: true, message: '请输入员工职务', trigger: 'change'}
                // ],
                idate: [
                    {required: true, message: '请选择提报日期', trigger: 'change'}
                ],
                addr: [
                    {required: true, message: '请输入问题车间名称', trigger: 'blur'}
                ],
                desc: [
                    {required: true, message: '请输入问题描述', trigger: 'blur'}
                ],
                zsec_nm: [
                     {required: true, message: '请选择整改部门', trigger: 'change'}
                ],
                zpname: [
                    {required: true, message: '请选择负责人', trigger: 'change'}
                ],
                odate: [
                    {required: true, message: '请选择要求完成日期', trigger: 'change'}
                ]
                // image1: [
                //     {required: true, message: '请选择一张图片', trigger: 'change'}
                // ]
            },
        }
    },
    methods: {
        typeChange(id) {
            this.ehsForm.types = ''
            this.ehsForm.ehs_types = []
            this.ehsForm.ehs_types = this.ehs_total_type.Table2.filter(item => item.FPID === id)
            this.ehsForm.types = this.ehsForm.ehs_types[0].FNAME
            this.ehsForm.types_disabled = false
        },
        typesChange(id) {
            this.ehsForm.typess = ''
            this.ehsForm.ehs_typess = []
            this.ehsForm.ehs_typess = this.ehs_total_type.Table3.filter(item => item.FPID === id)
            this.ehsForm.typess = this.ehsForm.ehs_typess[0].FNAME
            this.ehsForm.typess_name = this.ehsForm.ehs_typess[0].FNAME
            this.ehsForm.typess_disabled = false
        },
        typessChange(id) {
            this.ehsForm.typess_name = this.ehs_total_type.Table3.filter(item => item.FID === id)[0].FNAME
        },
        getFile(file, fileList) {
            console.log('file', file)
            console.log('fileList', fileList)
            this.ehsForm.fileList = []
            this.ehsForm.imageList = []
            fileList.forEach(item => {
                this.ehsForm.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.ehsForm.imageList.push(result)
                })
            })
        },
        handleRemove(file, fileList) {
            console.log('file', file)
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.ehsForm.fileList = []
            this.ehsForm.imageList = []
            fileList.forEach(item => {
                this.ehsForm.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.ehsForm.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.ehsForm)
                    this.SaveEHS()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveEHS() {
            this.ehsForm.isLoading = true
            const wxHrEhsDTO = {
                FPNL: this.ehsForm.pnl,
                FPNAME: this.ehsForm.pname,
                FDATE: this.ehsForm.idate,
                FSEC_NO: this.ehsForm.sec_no,
                FSEC_NM: this.ehsForm.sec_nm,
                FZW_NO: this.ehsForm.zw_no,
                FZW_NM: this.ehsForm.zw_nm,
                FTYPE_NO: this.ehsForm.typess,
                FTYPE_NM: this.ehsForm.typess_name,
                FDESC: this.ehsForm.desc,
                FADDR: this.ehsForm.addr,
                FZPNL: this.ehsForm.zpnl,
                FZPNAME: this.ehsForm.zpname,
                FZSEC_NO: this.ehsForm.zsec_no,
                FZSEC_NM: this.ehsForm.zsec_nm,
                FGRADE: this.ehsForm.grade,
                FZDATE: this.ehsForm.odate,
                FISMANAGER: this.ehsForm.isManager,
                FCSPNL: this.ehsForm.cspnl
            }

            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EHS/SaveEHS', qs.stringify({
                wxHrEhsDTO,
                IMAGE1: encodeURIComponent(this.ehsForm.imageList[0]),
                IMAGE2: encodeURIComponent(this.ehsForm.imageList[1]),
                IMAGE3: encodeURIComponent(this.ehsForm.imageList[2]),
                FILENAME1: this.ehsForm.fileList[0],
                FILENAME2: this.ehsForm.fileList[1],
                FILENAME3: this.ehsForm.fileList[2],
            }))

            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                console.log('问题提报单提交成功:', res.data.ResponseData)
                this.$message({
                    message: '问题提报单提交成功',
                    center: true,
                    type: 'success'
                })

                setTimeout(function(){
                    window.location.href = '/EHSList'
                },1000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '问题提报单提交失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                this.$message({
                    message: '问题提报单提交失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }

            this.ehsForm.isLoading = false
        },
        async getEHSType() {
            this.ehs_total_type = {}
            this.ehsForm.ehs_type = []
            this.ehsForm.ehs_types = []
            this.ehsForm.ehs_typess = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EHS/EHSTypes')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.ehs_total_type = res.data.ResponseData
                this.ehsForm.ehs_type = res.data.ResponseData.Table1
                this.ehsForm.ehs_types = res.data.ResponseData.Table2
                this.ehsForm.ehs_typess = res.data.ResponseData.Table3
            }
        },
        async getPersonByPnl() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SportsClock/PersonByPnl', qs.stringify({
                pnl: this.ehsForm.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.ehsForm.pname = res.data.ResponseData[0].pname
                this.ehsForm.sec_no = res.data.ResponseData[0].sec_no
                this.ehsForm.sec_nm = res.data.ResponseData[0].sec_nm
                this.ehsForm.zw_no = res.data.ResponseData[0].zw_no
                this.ehsForm.zw_nm = res.data.ResponseData[0].zw_nm
            }
        },
        async GetAdmin() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EHS/EHSAdmins')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.map(item => {
                    if(this.ehsForm.pnl === item.FPNL) {
                        this.ehsForm.isManager = true
                        this.getEHSType()
                    }
                })
            }
        },
        async GetSEC() {
            this.sec_data = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EHS/GetSEC')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.map(item => {
                    this.sec_data.push({
                        label: item.FSEC_NM,
                        value: item.FSEC_NM
                    })
                })
            }
        },
        async GetPNAME() {
            this.pnl_data = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EHS/GetPNAME', qs.stringify({
                sec_nm: this.ehsForm.zsec_nm
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.map(item => {
                    this.pnl_data.push({
                        label: item.FPNAME,
                        value: item.FPNL
                    })
                })
            }
        },
        SetPnl(val) {
            let obj = this.pnl_data.find(item => {
                return item.value === val
            })
            
            this.ehsForm.zpname = obj.label
        },
        SetCSPnl(val) {
            this.ehsForm.cspnl = val.toString()
            // console.log(' this.ehsForm.cspnl',  this.ehsForm.cspnl)
        }
    },
    mounted() {
        this.GetAdmin()
        this.getPersonByPnl()
        this.GetSEC()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.ehsForm.pnl = pnl
            // this.ehsForm.pnl = '50283' // dev
            // this.ehsForm.pnl = '0332' // dev
            // this.ehsForm.pnl = '58250' // dev
        }
    }
}
</script>