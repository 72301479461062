<template>
    <div>
        <el-alert type="success" show-icon>
            <template slot='title'>
                <div class="iconSize" style="text-align: left;">请勿重复提交问题，已支持发表追问，耐心等待回复</div>
            </template>
        </el-alert>
        <el-form :model="quesForm" :rules="rules" ref="quesForm" size="medium" label-width="80px">
            <el-form-item label="问题性质" prop="gClass">
                <el-select v-model="quesForm.gClass" placeholder="请选择问题类别" 
                    size="medium" style="width: 100%">
                    <el-option v-for="item in class_list" :key="item.className" :value="item.className"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="问题类别" prop="gType">
                <el-select v-model="quesForm.gType" placeholder="请选择问题类别" 
                    size="medium" style="width: 100%">
                    <el-option v-for="item in type_list" :key="item.typeName" :value="item.typeName"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="问题描述" prop="gContent">
                <el-input type="textarea" :rows="6" v-model="quesForm.gContent" placeholder="请输入你的提问的内容"></el-input>
            </el-form-item>
            <el-form-item label="问题图片" prop="image1">
                <el-upload 
                    action='' 
                    ref="upload"
                    :on-change="getFile" 
                    :limit="3" 
                    list-type="picture"
                    :auto-upload="false" 
                    :on-exceed="handleExceed"
                    style="width: 100%;" >
                    <el-button size="medium" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="验证码" prop="phoneCode" size="medium">
                 <el-input v-model="quesForm.phoneCode" placeholder="请输入验证码" :disabled="!this.CAPTCHA">
                    <el-button type="primary" slot="append" :loading="quesForm.isGetCodeLoading" @click="getCode">获取验证码</el-button>
                </el-input>
            </el-form-item>
            <el-alert
                type="success"
                v-if="quesForm.isShowSuccess"
                show-icon>
                <template slot='title'>
                    <div class="iconSize">验证码已发送，请在手机查收！</div>
                </template>
            </el-alert>
            <el-row style="margin-top:20px">
                <el-col :span="24">
                    <el-button type="primary" style="width: 100%" :loading="quesForm.isLoading" 
                    @click="submitForm('quesForm')">提交</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import { compressImage } from '../../plugin/compress.js'
export default {
    data() {
        return {
            quesForm: {
                gClass: '',
                gType: '',
                gContent: '',
                isLoading: false,
                isGetCodeLoading: false,
                phoneCode: '',
                isShowSuccess: false,
                fileList: [],
                imageList: [],
            }, 
            CAPTCHA: '',
            pnl: '',
            pname: '',
            sec_no: '',
            type_list: [],
            class_list: [{
                className: '咨询类'
            },{
                className: '投诉类'
            }],
            rules: {
                gClass: [
                    {required: true, message: '请选择问题性质', trigger: 'change'}
                ],
                gType: [
                    {required: true, message: '请选择问题类别', trigger: 'change'}
                ],
                gContent: [
                    {required: true, message: '请输入问题描述', trigger: 'blur'}
                ],
                phoneCode: [
                    {required: true, message: '请输入验证码', trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                console.log('valid', valid)
                if(valid && this.CheckCode() && await this.CheckRepeat()) {
                    console.log(this.quesForm)
                    this.SaveQuestion()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveQuestion() {
            this.quesForm.isLoading = true
            const dto = {
                GCLASS: this.quesForm.gClass,
                GTYPE: this.quesForm.gType, 
                GCONTENT: this.quesForm.gContent, 
                PNL: this.pnl,
                PNAME: this.pname,
                SEC_NO: this.sec_no
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlAsks/SaveQuestionModel', qs.stringify({
                dto,
                IMAGE1: encodeURIComponent(this.quesForm.imageList[0]),
                IMAGE2: encodeURIComponent(this.quesForm.imageList[1]),
                IMAGE3: encodeURIComponent(this.quesForm.imageList[2]),
                FILENAME1: this.quesForm.fileList[0],
                FILENAME2: this.quesForm.fileList[1],
                FILENAME3: this.quesForm.fileList[2]
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$message({
                    message: '提交成功！',
                    center: true,
                    type: 'success'
                })
                setTimeout(() => {
                    window.location.href = '/questionList'
                }, 100)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.quesForm.isLoading = false
        },
        async getCode() {
            this.quesForm.isGetCodeLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AliSendSMS/SendCaptchaPnl', qs.stringify({
                pnl: this.pnl,
                templateCode: conf.smsInfo.templateCode,
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK' && res.data.ResponseData.Code !== 'OK') {
                this.$message({
                    message: '验证码获取失败：' + res.data.ResponseData.Message,
                    center: true,
                    type: 'error'
                })
            }else if(res.data.Code === 0  && res.status === 200 && res.statusText === 'OK' && res.data.ResponseData.Code === 'OK') {
                this.quesForm.isShowSuccess = true
                this.CAPTCHA = res.data.ResponseData.CAPTCHA
                console.log('this.CAPTCHA', this.CAPTCHA)
            }
            this.quesForm.isGetCodeLoading = false
        },
        CheckCode() {
            if(this.CAPTCHA === this.quesForm.phoneCode) {
                return true
            }else{
                this.$message({
                    message: '请输入正确的验证码',
                    center: true,
                    type: 'error'
                })
                return false
            }
        },
        async CheckRepeat() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlAsks/QuestionCount', qs.stringify({
                GTYPE: this.quesForm.gType,
                GCONTENT: this.quesForm.gContent,
                PNL: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                if(res.data.ResponseData[0].count > 0) {
                    this.$message({
                        message: '请勿重复提交！',
                        center: true,
                        type: 'error'
                    })
                    console.log('count', res.data.ResponseData[0].count)
                    return false
                } else {
                    return true
                }
            }
        },
        async getQuestionTpye() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlAsks/QuestionType')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.type_list = res.data.ResponseData
            }
        },
        getFile(file, fileList) {
            console.log('file', file)
            console.log('fileList', fileList)
            this.quesForm.fileList = []
            this.quesForm.imageList = []
            fileList.forEach(item => {
                this.quesForm.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.quesForm.imageList.push(result)
                })
            })
        },
        handleRemove(file, fileList) {
            console.log('file', file)
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.quesForm.fileList = []
            this.quesForm.imageList = []
            fileList.forEach(item => {
                this.quesForm.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.quesForm.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        },
    },
    mounted() {
        this.getQuestionTpye()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            // this.pnl = '49462'
        }
        const pname = localStorage.getItem('PNAME')
        if(pname) {
            this.pname = pname
        }
        const sec_no = localStorage.getItem('SEC_NO')
        if(sec_no){
            this.sec_no = sec_no
        }
    }
}
</script>