<template>
    <div>
        <el-descriptions v-if="list.length > 0" class="margin-top" title="件资信息" :column="1" size="medium" border>
            <template slot="extra">
                <el-select 
                    v-model="GjjzLzDTO.yymm" 
                    size="medium"
                    filterable 
                    placeholder="请选择件资月份" 
                    @change="yymmChange" 
                    style="width: 100%">
                    <el-option
                        v-for="item in list"
                        :key="item.DBB_ID"
                        :label="item.yymm"
                        :value="item.DB_ID">
                    </el-option>
                </el-select>
                <!-- <el-button type="primary" size="small">操作</el-button> -->
                <!-- <el-button :type="item.btn_type" size="small" style="width: 90%;font-weight: bold; margin-top: 8px; font-size: 13px" @click="selection3(item)">{{item.label}}</el-button> -->
            </template>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-postcard"></i> 工号
                </template>
                <el-tag size="small">{{ GjjzLzDTO.pnl }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-user"></i> 姓名
                </template>
                {{ GjjzLzDTO.pname }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-office-building"></i> 部门
                </template>
                {{ GjjzLzDTO.sec_nm }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-money"></i> 件资工资
                </template>
                {{ GjjzLzDTO.amt }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-money"></i> 件资补贴
                </template>
                {{ GjjzLzDTO.amt1 }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-money"></i> 合计
                </template>
                {{ GjjzLzDTO.samt }}
            </el-descriptions-item>
            <el-descriptions-item v-if="!isVerify">
                <template slot="label">
                    <i class="el-icon-time"></i> 确认时间
                </template>
                {{ GjjzLzDTO.verifyTime }}
            </el-descriptions-item>
        </el-descriptions>
        <el-empty v-if="list.length === 0" description="没有数据"></el-empty>
        <el-alert type="error" v-if="list.length > 0" show-icon style="margin-top: 20px">
            <template slot='title'>
                <div class="iconSize">件资金额正确请点击【件资确认无误】按钮，通过自拍完成人脸校验</div>
            </template>
        </el-alert>
        <el-row :gutter="20" style="margin-top:20px" v-if="list.length > 0">
            <el-col :span="12">
                <a :href="'tel:' + phone">
                    <el-button style="width: 100%" size="medium" type="danger">件资有误反馈</el-button>
                </a>
            </el-col>
            <el-col :span="12">
                <el-button style="width: 100%" size="medium" type="success" :loading="isLoading" @click="ChooseImage" :disabled="!isVerify">件资无误确认</el-button>
            </el-col>
        </el-row>
        <el-result v-if="isSuccess" icon="success" title="件资确认成功">
        </el-result>
        <el-result v-if="isError" icon="error" title="件资确认失败" subTitle="请换个角度再来一次！">
            <template slot="extra">
                {{error_info}}
            </template>
        </el-result>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            phone: '059522923035',
            list: [],
            GjjzLzDTO: {
                pnl: '',
                pname: '',
                sec_nm: '',
                amt: '',
                verifyer: '',
                verifyTime: '',
                amt1: '',
                samt: '',
                yymm: ''
            },
            IMAGE1: '',
            isLoading: false,
            isSuccess: false,
            isError: false,
            isVerify: false
        }
    },
    methods: {
        yymmChange(id) {
            if(id) {
                let item = this.list.find(item => item.DB_ID == id)
                this.GjjzLzDTO.pname = item.pname
                this.GjjzLzDTO.sec_nm = item.sec_nm
                // this.GjjzLzDTO.yymm = item.yymm
                this.GjjzLzDTO.amt = item.amt
                this.GjjzLzDTO.verifyer = item.verifyer
                this.GjjzLzDTO.verifyTime = item.verifyTime
                this.GjjzLzDTO.amt1 = item.amt1
                this.GjjzLzDTO.samt = item.samt
                this.isVerify = !item.verifyer ? true : false
            }
            // console.log('id', id)
        },
        async GetGjjzLzData() {
            // this.GjjzLzDTO.pname = ''
            // this.GjjzLzDTO.sec_nm = ''
            // this.GjjzLzDTO.yymm = ''
            // this.GjjzLzDTO.amt = ''
            // this.GjjzLzDTO.verifyer = ''
            // this.GjjzLzDTO.verifyTime = ''
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzLzs/GetGjjzLzData', qs.stringify({
                pnl: this.GjjzLzDTO.pnl
            }))

            console.log(res)
            this.loading = false
            // console.log('res.data.ResponseData', res.data.ResponseData)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.list = res.data.ResponseData
                // this.list.forEach(item => {
                //     this.GjjzLzDTO.pname = item.pname
                //     this.GjjzLzDTO.sec_nm = item.sec_nm
                //     this.GjjzLzDTO.yymm = item.yymm
                //     this.GjjzLzDTO.amt = item.amt
                //     this.GjjzLzDTO.verifyer = item.verifyer
                //     this.GjjzLzDTO.verifyTime = item.verifyTime
                //     this.GjjzLzDTO.amt1 = item.amt1
                //     this.GjjzLzDTO.samt = item.samt
                //     this.isVerify = !item.verifyer ? true : false
                // })
                if(this.list.length > 0) {
                    this.GjjzLzDTO.pname = this.list[0].pname
                    this.GjjzLzDTO.sec_nm = this.list[0].sec_nm
                    this.GjjzLzDTO.yymm = this.list[0].yymm
                    this.GjjzLzDTO.amt = this.list[0].amt
                    this.GjjzLzDTO.verifyer = this.list[0].verifyer
                    this.GjjzLzDTO.verifyTime = this.list[0].verifyTime
                    this.GjjzLzDTO.amt1 = this.list[0].amt1
                    this.GjjzLzDTO.samt = this.list[0].samt
                    this.isVerify = !this.list[0].verifyer ? true : false
                }
            }
        },
        async ChooseImage() {
            wx.chooseImage({
                count: 1, // 默认9
                sizeType: ['compressed'], // sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                sourceType: ['camera'], // sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                success: res => {
                    var localIds = res.localIds // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                    this.GetLocalImage(localIds[0])
                }
            })
        },
        async GetLocalImage(localId) {
            wx.getLocalImgData({
                localId: localId, // 图片的localID
                success: res => {
                    var localData = res.localData // localData是图片的base64数据，可以用img标签显示
                    this.IMAGE1 = localData
                    // this.FILENAME1 = this.SubStringBase64(localData)
                    this.FaceCompare()
                }
            })
        },
        async FaceCompare() {
            const loading = this.$loading({
                lock: true,
                text: '识别中，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/UniUbi/FaceRecognition', qs.stringify({
                pnl: this.GjjzLzDTO.pnl,
                IMAGE1: encodeURIComponent(this.IMAGE1)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                if(res.data.ResponseData.DATA.score > 0.8) { // 泛宇接口 下调识别率
                    loading.close()
                    this.ConfirmGjjzLzData()
                }else {
                    this.error_info ='失败1：' + res.data.Message
                    this.isError = true
                    this.isSuccess = false
                }
            }else {
                this.error_info = '失败2：' + res.data.Message
                this.isError = true
                this.isSuccess = false
            }
            loading.close()
        },
        async ConfirmGjjzLzData() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzLzs/ConfirmGjjzLzData', qs.stringify({
                dto: this.GjjzLzDTO
            }))

            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.isSuccess = true
                this.isError = false
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['chooseImage'], // 必填，需要使用的JS接口列表
                })
            }
        },
    },
    mounted() {
       const id = this.$route.params.id
        if(id) {
            this.GjjzLzDTO.pnl = id
        }
        
        this.GetGjjzLzData()
        this.GetSignature()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.GjjzLzDTO.pnl = pnl 
            // this.GjjzLzDTO.pnl = '13200' // dev
            // this.GjjzLzDTO.pnl = '62188' // dev
        }
    }
}
</script>