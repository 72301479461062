<template>
    <div>
        <el-alert type="info" center :closable="false">
            <template slot='title'>
                <el-link icon="el-icon-thumb" @click="switchChange">
                    <div style="text-decoration: underline; color: #409EFF;">《操作手册 - 离职申请》</div>
                </el-link>
            </template>
        </el-alert>
        <el-drawer
            :visible.sync="drawer"
            :direction="direction"
            size="80%"
            :modal="false">
            <iframe frameborder="0" style="width: 100%;" height="380px" scrolling="yes" seamless="seamless" src="./html/操作手册 - 离职申请.html"></iframe>
        </el-drawer>
        <div style="text-align: right">
            <el-switch
                style="margin-bottom: 20px;"
                v-model="help_show"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="true"
                active-text="帮助"
                inactive-value="false">
            </el-switch> 
        </div>
        <div style="height: 220px; margin-bottom: 20px;" v-if="help_show === 'true'">
            <el-steps direction="vertical" :active="active" finish-status="success">
                <el-step title="完善离职申请单" description="点击人脸识别按钮，完成人脸识别，将跳转到离职申请单界面"></el-step>
                <el-step title="接收消息推送" description="您的离职申请通过之后，将会收到微信推送信息，请点击推送信息"></el-step>
                <el-step title="填写离职确认单" description="再次完成人脸识别，将跳转到离职确认单，填写表单以完成离职流程"></el-step>
            </el-steps>
        </div>
        <el-descriptions class="margin-top" :column="1" size="medium" border>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-tickets"></i>部门
                </template>
            {{sec_nm}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-user"></i>姓名
                </template>
            {{pname}}
            </el-descriptions-item>
        </el-descriptions>
        <!-- <el-alert
            style="margin-top: 20px"
            type="warning"
            show-icon>
            <template slot='title'>
                <div class="iconSize" style="align-text: left;">人脸识别之后，如需输入帐号密码，则：默认帐号：寰球工号，默认密码：abc.123</div>
            </template>
        </el-alert> -->
        <el-result v-if="isSuccess" icon="success" title="识别成功" subTitle="跳转中，请稍后...">
        </el-result>
         <el-result v-if="isError" icon="error" title="识别失败" subTitle="请换个角度再来一次！">
             {{error_info}}
        </el-result>
        <el-row v-if="!isSuccess" style="margin-top: 20px">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    @click="GetJSAPI">人脸识别</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            pnl: '',
            image1: '',
            sec_nm: '',
            pname: '',
            isSuccess: false,
            isError: false,
            error_info: '',
            ekp_url: '',
            help_show: false,
            direction: 'btt',
            drawer: false,
            active: 0,
        }
    },
    methods: {
        PageClose() {
            wx.closeWindow()
        },
        switchChange() {
            if(this.help_show) {
                this.drawer = true
            }else {
                this.drawer = false
            }
        },
        async GetJSAPI() {
            await this.ChooseImage()
        },
        async ChooseImage() {
            wx.chooseImage({
                count: 1, // 默认9
                sizeType: ['compressed'], // sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                sourceType: ['camera'], // sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                success: res => {
                    var localIds = res.localIds // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                    this.GetLocalImage(localIds[0])
                }
            })
        },
        async GetLocalImage(localId) {
             wx.getLocalImgData({
                localId: localId, // 图片的localID
                success: res => {
                    var localData = res.localData // localData是图片的base64数据，可以用img标签显示
                    this.image1 = localData
                    this.FaceCompare()
                    // this.CheckFaceLiving()
                }
            })
        },
        async CheckFaceLiving() {
            const loading = this.$loading({
                lock: true,
                text: '识别中，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/BaiduFace/BaiduFace', qs.stringify({
                IMAGE1: encodeURIComponent(this.image1)
                // IMAGE1: 'https://viapi-test.oss-cn-shanghai.aliyuncs.com/test-team/ceshi/CompareFaceA1.jpg'
            }))
            console.log(res)
            // this.$message({
            //     message: res, // dev
            //     center: true,
            //     type: 'warning'
            // })
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                this.ret = res.data.ResponseData.Data.Elements[0].Results[0].Rate
                if(res.data.ResponseData.Data.Elements[0].Results[0].Rate > 50) {
                    this.isSuccess = true
                    this.isError = false
                    // this.FaceCompare()
                }
                else {
                    this.isSuccess = false
                    this.isError = true
                }
            }else {
                this.error_info = res.data.Message
                this.isSuccess = false
                this.isError = true
            }
            loading.close()
        },
        async FaceCompare() {
            const loading = this.$loading({
                lock: true,
                text: '识别中，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/UniUbi/FaceRecognition', qs.stringify({
            // const res = await axios.post(conf.api_url + '/gateway/weixinapi/AliFace/AliFaceCompare', qs.stringify({
                pnl: this.pnl,
                IMAGE1: encodeURIComponent(this.image1)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                // if(res.data.ResponseData.DATA.score > 0.9) { // 泛宇接口
                if(res.data.ResponseData.DATA.score > 0.8) { // 泛宇接口 下调识别率
                // if(res.data.ResponseData.Data.Confidence > 90) { // ali接口
                    this.isSuccess = true
                    this.isError = false
                    // await this.SetEKPQUitInfo()
                    setTimeout(()=>{
                        window.location.href = this.ekp_url
                    },2000)
                }else {
                    this.isError = true
                    this.isSuccess = false
                }
            }else {
                this.error_info = res.data.Message
                this.isSuccess = false
                this.isError = true
            }
            loading.close()
        },
        // async SetEKPQUitInfo() {
        //     const res = await axios.post(conf.api_url + '/gateway/weixinapi/EKPAutoQuit/EKPQuitInsert', qs.stringify({
        //         PNL: this.pnl
        //     }))
        //     console.log(res)
        //     // if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
        //     //     console.log(res.data.Message)
        //     //     console.log('离职数据写入完成')
        //     // }
        // },
        async GetEKPQuitInfo() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/QuitTemplateMessage/EKPQuitInfo', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // if(res.data.ResponseData.length === 0) {
                //     this.ekp_url = conf.ekp_url
                //     this.active = 0
                // }else {
                    if(res.data.ResponseData[0].fstate === 0) { // 申请
                        this.active = 0
                        this.ekp_url = res.data.ResponseData[0].furl
                    }else if(res.data.ResponseData[0].fstate === 1){ 
                        this.active = 1
                        this.ekp_url = res.data.ResponseData[0].furl
                    }else if(res.data.ResponseData[0].fstate === 2) { // 确认
                        this.active = 2
                        this.ekp_url = res.data.ResponseData[0].furl
                    }
                    // else if(res.data.ResponseData[0].fstate === 9){
                    //     this.ekp_url = conf.ekp_url
                    //     this.active = 0
                    // }
                // }
            }
        },
        async GetSignature() {
            // this.ret = 'window.entryUrl：' + window.entryUrl
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
                // url: this.ekp_url ? decodeURIComponent('http://wx.qzhqxf.com/') : decodeURIComponent(window.entryUrl)
            }))

            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                // this.ret += ',' + res.data.ResponseData
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['chooseImage'] // 必填，需要使用的JS接口列表
                })
            }
            // else {
            //     this.ret += ',else：' + res.data.Message
            // }
        },
        // async GetToken() {
        //     const res = await axios.get(conf.api_url + '/gateway/weixinapi/UniUbi/UniUbiToken')
        //     console.log(res)
        //     if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
        //        console.log(res.data.ResponseData)
        //     }
        // },
        // async GetSportImage() {
        //     const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrSports/GetByID', qs.stringify({
        //         // DB_ID: '849811d38a694af29f336df7adb8a611'
        //         DB_ID: '26ddd9c7e9164ba1b55a6652d4fdd866'
        //     }))
        //     console.log(res)
        //     if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
        //         console.log(res.data.ResponseData.IMAGE1)
        //         this.image1 = res.data.ResponseData.IMAGE1
        //         // this.FaceCompare() // dev
        //         this.CheckFaceLiving() // dev
        //     }
        // },
        // async GetMachineImage() {
        //     const res = await axios.post(conf.api_url + '/gateway/weixinapi/UniUbi/LocalPersonImage', qs.stringify({
        //         pnl: this.pnl
        //     }))
        //     console.log(res)
        //     if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
        //         console.log(res.data.ResponseData[0].face_template)
        //         this.image1 = res.data.ResponseData[0].face_template
        //         // this.FaceCompare() // dev
        //         this.CheckFaceLiving() // dev
        //     }
        // },
        // async SendMessage() {
        //     // const res = await axios.post(conf.api_url + '/gateway/weixinapi/QuitTemplateMessage/QuitConfirm', qs.stringify({
        //     //     pnl: this.pnl
        //     // }))
        //     console.log(this.pnl)
        //     const res = await axios.get(conf.api_url + '/gateway/weixinapi/QuitTemplateMessage/GetQuitConfirm', {
        //         pnl: this.pnl
        //     })
        //     console.log(res)
        //     if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
        //         console.log(res.data.ResponseData)
        //     }
        // }
    },
    mounted() {
        // console.log('id', this.$route)
        // const id = this.$route.params.id
        // if(id) {
        //     // console.log('id', id)
        //     this.ekp_url = id
        //     window.location.href = 'http://wx.qzhqxf.com/faceAuth'
        // }
        // this.GetToken() //dev
        this.GetSignature() // prod
        // this.GetSportImage() // dev
        // this.GetMachineImage() // dev
        // this.SendMessage() // dev 
        // this.CheckFaceLiving() // dev
        this.GetEKPQuitInfo() 
    },
    created() {
        // console.log('id2', this.$route)
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
        // this.pnl = '1290' // dev
        // this.pnl = '35019' // dev
        // this.pnl = '50283' // dev
        // this.pnl = '55129'
        const sec_nm = localStorage.getItem('SEC_NM')
        if(sec_nm){
            this.sec_nm = sec_nm
        }
        const pname = localStorage.getItem('PNAME')
        if(pname){
            this.pname = pname
        }
    }
}
</script>