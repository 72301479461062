<template>
    <div>
        <el-alert type="warning" show-icon>
            <template slot='title'>
                <div class="iconSize" style="text-align: left;">首次登录需要绑定。密码为寰球鞋服APP密码</div>
            </template>
        </el-alert>
        <el-alert type="info" center :closable="false">
            <template slot='title'>
                <el-link icon="el-icon-thumb" @click="showHelp">
                    <div style="text-decoration: underline; color: #409EFF;">《操作手册 - 公众号绑定》</div>
                </el-link>
            </template>
        </el-alert>
        <el-drawer
            :visible.sync="drawer"
            :direction="direction"
            size="80%"
            :modal="false">
            <iframe frameborder="0" style="width: 100%;" height="380px" scrolling="yes" seamless="seamless" src="./html/操作手册 - 公众号绑定.html"></iframe>
        </el-drawer>
        <el-form :model="regForm" :rules="rules" ref="regForm" label-width="65px" size="medium">
            <el-form-item label="工号" prop="pnl" size="medium">
                <el-input v-model="regForm.pnl" placeholder="请输入工号" @blur="getPersonByPnl"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" size="medium">
                <el-input v-model="regForm.password" show-password placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="24" style="text-align: right;">
                    <el-popover placement="left" width="200" v-model="visible">
                        <p>此操作将会把登录密码发送到您绑定的手机上，确定要继续吗?</p>
                        <div style="text-align: center; margin: 0">
                            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                            <el-button type="primary" size="mini" @click="SendPassword">确定</el-button>
                        </div>
                        <template slot='reference'>
                            <el-link 
                                :underline="false" 
                                type="info" 
                                style="font-size: 10px;" 
                                @click="CheckPNL">忘记密码</el-link>
                        </template>
                    </el-popover>
                </el-col>
            </el-row>
            <br>
            <el-alert
                type="success"
                v-if="regForm.isShowSuccess2"
                show-icon>
                <template slot='title'>
                    <div class="iconSize">密码已成功发送到账号所绑定的手机上！</div>
                </template>
            </el-alert>
            <el-form-item label="手机号" prop="phoneNumber" size="medium">
                <el-input v-model="regForm.phoneNumber" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="phoneCode" size="medium">
                 <el-input v-model="regForm.phoneCode" placeholder="请输入验证码" :disabled="!this.CAPTCHA">
                    <el-button type="primary" slot="append" :loading="regForm.isLoading" @click="getCode">获取验证码</el-button>
                </el-input>
            </el-form-item>
            <el-alert
                type="success"
                v-if="regForm.isShowSuccess"
                show-icon>
                <template slot='title'>
                    <div class="iconSize">验证码已发送，请在手机查收！</div>
                </template>
            </el-alert>
            <br>
            <el-row>
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="regForm.isLoading"
                        @click="submitForm('regForm')">绑定微信</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf'
import qs from 'qs'
export default {
    data() {
        return {
            direction: 'btt',
            drawer: false,
            regForm: {
                pnl: '',
                phoneNumber: '',
                password: '',
                phoneCode: '',
                isShowSuccess: false,
                isShowSuccess2: false,
                regPhoneNumber: '',
                isLoading: false,
            },
            visible: false,
            CAPTCHA: '',
            openid: '',
            sec_no: '',
            sec_nm: '',
            nickname: '',
            headimgurl: '',
            pname: '',
            rules: {
                pnl: [
                    {required: true, message: '请输入工号', trigger: 'blur'}
                ],
                phoneNumber: [
                    {required: true, message: '请输入手机号', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'}
                ],
                phoneCode: [
                    {required: true, message: '请输入验证码', trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        showHelp() {
            this.drawer = true
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CheckCode() && this.CheckPhone()){
                    console.log(this.regForm)
                    this.SaveRegInfo()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveRegInfo() {
            this.regForm.isLoading = true
            const user = {
                openid: this.openid,
                nickname: this.nickname,
                headimgurl: this.headimgurl,
                pnl: this.regForm.pnl,
                pname: this.pname,
                phone: this.regForm.phoneNumber,
                secno: this.sec_no,
                secnm: this.sec_nm
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxUsers/SaveUser', qs.stringify({
                user,
                password: this.regForm.password
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                localStorage.setItem('PNL', this.regForm.pnl)
                localStorage.setItem('PHONE', this.regForm.phoneNumber)
                localStorage.setItem('SEC_NO', this.sec_no)
                localStorage.setItem('SEC_NM', this.sec_nm)

                this.$message({
                    message: '绑定成功！',
                    center: true,
                    type: 'success'
                })
                setTimeout(function(){
                    window.location.href = '/'
                },1000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                  this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.regForm.isLoading = false
        },
        async getCode() {
            if(!this.regForm.phoneNumber) {
                this.$message({
                    message: '请输入手机号码',
                    center: true,
                    type: 'error'
                })
                return
            }
            this.regForm.isLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AliSendSMS/SendCaptcha', qs.stringify({
                phoneNumber: this.regForm.phoneNumber,
                templateCode: conf.smsInfo.templateCode,
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK' && res.data.ResponseData.Code !== 'OK') {
                this.$message({
                    message: '验证码获取失败：' + this.regForm.phoneNumber + ' 不是有效电话号码',
                    center: true,
                    type: 'success'
                })
            }else if(res.data.Code === 0  && res.status === 200 && res.statusText === 'OK' && res.data.ResponseData.Code === 'OK') {
                this.regForm.isShowSuccess = true
                this.CAPTCHA = res.data.ResponseData.CAPTCHA
                console.log('this.CAPTCHA', this.CAPTCHA)
            }
            this.regForm.isLoading = false
        },
        CheckCode() {
            if(this.CAPTCHA === this.regForm.phoneCode) {
                return true
            }else{
                this.$message({
                    message: '验证码错误，请重新获取',
                    center: true,
                    type: 'error'
                })
                return false
            }
        },
        CheckPhone() {
            if(this.regForm.regPhoneNumber.trim() === this.regForm.phoneNumber.trim()) {
                return true
            }else {
                this.$message({
                    message: '预留手机号与现在的手机号不符',
                    center: true,
                    type: 'error'
                })
                return false
            }
        },
        async SendPassword() {
            this.visible = false
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AliSendSMS/SendPassword', qs.stringify({
                templateCode: conf.smsInfo.templateCode2,
                pnl: this.regForm.pnl,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.ResponseData.Code === 'OK') {
                this.regForm.isShowSuccess2 = true
            }
        },
        async CheckPNL() {
            this.visible = true
            if(!this.regForm.pnl) {
                this.$message({
                    message: '请输入用户名！',
                    center: true,
                    type: 'error'
                })
            }else{
                this.visible = false
                this.getPersonByPnl()
            }
        },
        async getPersonByPnl() {
            // const res = await axios.post(conf.api_url + '/gateway/weixinapi/SportsClock/PersonByPnl', qs.stringify({
            //     pnl: this.regForm.pnl
            // }))
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsPersons/PersonInfo', qs.stringify({
                pnl: this.regForm.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                // this.regForm.phoneNumber = res.data.ResponseData[0].tel
                this.regForm.regPhoneNumber = res.data.ResponseData[0].TEL
                this.sec_no = res.data.ResponseData[0].SEC_NO
                this.sec_nm = res.data.ResponseData[0].SEC_NM
                this.pname = res.data.ResponseData[0].PNAME
                if(res.data.ResponseData[0].DDATE){
                    this.regForm.pnl = ''
                    this.$message({
                        message: '工号：' + res.data.ResponseData[0].PNL + '，已离职，请重新录入',
                        center: true,
                        type: 'error',
                        duration: 2400
                    })
                }
            }
        },
    },
    created() {
        const openid = localStorage.getItem('openid')
        if(openid){
            this.openid = openid
        }
        const nickname = localStorage.getItem('nickname')
        if(nickname){
            this.nickname = nickname
        }
        const headimgurl = localStorage.getItem('headimgurl')
        if(headimgurl){
            this.headimgurl = headimgurl
        }
    }
}
</script>