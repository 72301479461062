<template>
    <div>
        <el-card>
            <img src="https://iconfont.alicdn.com/p/illus_3d/file/yyXDHn8yIe6f/94b0024e-045a-4ff6-ad76-b35bd4993e15.png" 
                style="width: 100%;display: block;">
            <div class="bottom clearfix">
                <el-button     
                    size="medium"
                    style="width: 100%;margin-top:20px"
                    :loading="isLoading"
                    type="primary" 
                    @click="EndCall">立即处理</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs' 
export default {
    data() {
        return {
            orgNo: '',
            logId: '',
            isLoading: false
        }
    },
    methods: {
        async EndCall() {
            this.isLoading = true
            // console.log('url', conf.Andon.endCall)
            const res = await axios.post(conf.Andon.endCall, qs.stringify({
                orgNo: this.orgNo,
                logId: this.logId,
                result: '立即处理'
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.$message({
                    message: res.data.ResponseData[0].body.msg,
                    center: true,
                    type: 'success'
                })
            }
            this.isLoading = false
        }
    },
    mounted() {
        const orgNo = this.$route.params.orgNo
        if(orgNo) {
            this.orgNo = orgNo
        }
        const logId = this.$route.params.logId
        if(logId) {
            this.logId = logId
        }
    },
    created() {

    }
}
</script>