<template>
    <div>
        <div v-if="on_job">
            <el-tabs v-model="activeName" tab-position="bottom" @tab-click="tab_change" style="margin-top: -10px">
                <el-tab-pane name="first" class="tab_pane">  
                    <span slot="label">
                        <div class="tab_pane_span_div">
                            <el-image lazy fit="cover" :src="require('@/assets/icon/iconPark24/首页_home.svg')" alt=""></el-image>
                        </div>
                        <div class="tab_pane_span_div2">首页</div>
                    </span>
                    <div class="tab_pane_div">
                        <el-carousel height="40px" direction="vertical">
                            <el-carousel-item v-for="item in ekp_notices" :key="item.title">
                                <el-alert
                                    center
                                    style="background-color: rgb(217, 236, 255);"
                                    :closable="false">
                                    <template slot='title'>
                                        <el-link @click="toEKP(item.url)" :underline="false">
                                            {{ item.title }}
                                        </el-link>
                                    </template>
                                </el-alert>
                            </el-carousel-item>
                        </el-carousel>

                        <el-row type="flex" justify="space-between" style="margin-top: 10px;">
                            <el-col :span="4">
                                <router-link to="/weekMenuImage">
                                    <el-card class="card" :body-style="{ padding: '14px' }">
                                        <el-image fit="fill" :src="require('@/assets/icon/iconPark48/煮饭_cook.svg')"></el-image>
                                    </el-card>
                                    <span class="span">餐厅菜谱</span>
                                </router-link> 
                            </el-col>
                            <el-col :span="4">
                                <router-link to="/pieceCapital">
                                    <el-card class="card" :body-style="{ padding: '14px' }">
                                        <el-image fit="fill" :src="require('@/assets/icon/iconPark48/工具_tool.svg')"></el-image>
                                    </el-card>
                                    <span class="span">工务维修</span>
                                </router-link> 
                            </el-col>
                            <el-col :span="4">
                                <router-link to="/pieceCapital">
                                    <el-card class="card" :body-style="{ padding: '14px' }">
                                        <el-image fit="fill" :src="require('@/assets/menu/EHS.png')"></el-image>
                                    </el-card>
                                    <span class="span">EHS</span>
                                </router-link> 
                            </el-col>
                            <el-col :span="4">
                                <router-link to="/pieceCapital">
                                    <el-card class="card" :body-style="{ padding: '14px' }">
                                        <el-image fit="fill" :src="require('@/assets/icon/iconPark48/单人床_single-bed.svg')"></el-image>
                                    </el-card>
                                    <span class="span">宿舍7S</span>
                                </router-link> 
                            </el-col>
                            <el-col :span="4">
                                <router-link to="/pieceCapital">
                                    <el-card class="card" :body-style="{ padding: '14px' }">
                                        <el-image fit="fill" :src="require('@/assets/icon/iconPark48/指纹_fingerprint.svg')"></el-image>
                                    </el-card>
                                    <span class="span">远程打卡</span>
                                </router-link> 
                            </el-col>
                        </el-row>

                        <el-tabs style="margin-top: 20px">
                            <el-tab-pane> 
                                <span slot="label">
                                    <div style="font-size: small;font-weight: bold;">人力行政</div>
                                </span>
                                <el-scrollbar style="height:500px;overflow: hidden;" ref="scrollbar">
                                <h5>人力行政</h5>
                                <el-row type="flex" style="margin-bottom: 20px;">
                                    <el-col :span="4">
                                        <router-link to="/pieceCapital">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/线上办公室_online-meeting.svg')"></el-image>
                                        </el-card>
                                        <span class="span">我有建议</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/帮助中心_helpcenter.svg')"></el-image>
                                        </el-card>
                                        <span class="span">我要提问</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/详情列表_view-list.svg')"></el-image>
                                        </el-card>
                                        <span class="span">黑名单</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalLeave">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/运动_sport.svg')"></el-image>
                                        </el-card>
                                        <span class="span">运动打卡</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalLeave">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/指纹_fingerprint.svg')"></el-image>
                                        </el-card>
                                        <span class="span">远程打卡</span>
                                        </router-link> 
                                    </el-col>
                                </el-row>
                                <el-row type="flex" style="margin-bottom: 20px;">
                                    <el-col :span="4">
                                        <router-link to="/pieceCapital">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/工具_tool.svg')"></el-image>
                                        </el-card>
                                        <span class="span">工务维修</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/煮饭_cook.svg')"></el-image>
                                        </el-card>
                                        <span class="span">餐厅菜谱</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/单人床_single-bed.svg')"></el-image>
                                        </el-card>
                                        <span class="span">宿舍7S</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalLeave">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/menu/EHS.png')"></el-image>
                                        </el-card>
                                        <span class="span">EHS</span>
                                        </router-link> 
                                    </el-col>
                                </el-row>
                                <h5>制造MES</h5>
                                <el-row type="flex" style="margin-bottom: 20px;">
                                    <el-col :span="4">
                                        <router-link to="/pieceCapital">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/双向数据互传_transfer-data.svg')"></el-image>
                                        </el-card>
                                        <span class="span">投入产出</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/圆环_ring.svg')"></el-image>
                                        </el-card>
                                        <span class="span">在制明细</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/内部传输_internal-transmission.svg')"></el-image>
                                        </el-card>
                                        <span class="span">部件接收</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalLeave">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/显示器_monitor-one.svg')"></el-image>
                                        </el-card>
                                        <span class="span">电脑车</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalLeave">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/广告产品_ad-product.svg')"></el-image>
                                        </el-card>
                                        <span class="span">成品库存</span>
                                        </router-link> 
                                    </el-col>
                                </el-row>
                                <el-row type="flex" style="margin-bottom: 20px;">
                                    <el-col :span="4">
                                        <router-link to="/pieceCapital">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/检查列表_checklist.svg')"></el-image>
                                        </el-card>
                                        <span class="span">生产巡检</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/成功文件1_file-success-one.svg')"></el-image>
                                        </el-card>
                                        <span class="span">首件确认</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/流水线_assembly-line.svg')"></el-image>
                                        </el-card>
                                        <span class="span">线别预警</span>
                                        </router-link> 
                                    </el-col>
                                </el-row>
                                <h5>员工件资</h5>
                                <el-row type="flex" style="margin-bottom: 20px;">
                                    <el-col :span="4">
                                        <router-link to="/pieceCapital">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/消费_consume.svg')"></el-image>
                                        </el-card>
                                        <span class="span">员工件资</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/货币流通_currency.svg')"></el-image>
                                        </el-card>
                                        <span class="span">件资状态</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/支出_expenses-one.svg')"></el-image>
                                        </el-card>
                                        <span class="span">离职件资</span>
                                        </router-link> 
                                    </el-col>
                                </el-row>
                                <el-row type="flex" style="margin-bottom: 20px;">
                                    <el-col :span="4">
                                        <router-link to="/pieceCapital">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/机器人2_robot-two.svg')"></el-image>
                                        </el-card>
                                        <span class="span">工艺车间</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/纹理_texture.svg')"></el-image>
                                        </el-card>
                                        <span class="span">帮面件资</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/平头节点_node-square.svg')"></el-image>
                                        </el-card>
                                        <span class="span">成型件资</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/裁切_cutting.svg')"></el-image>
                                        </el-card>
                                        <span class="span">冲裁件资</span>
                                        </router-link> 
                                    </el-col>
                                </el-row>
                                <el-row type="flex" style="margin-bottom: 20px;">
                                    <el-col :span="4">
                                        <router-link to="/pieceCapital">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/审核_audit.svg')"></el-image>
                                        </el-card>
                                        <span class="span">帮面审核</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/审核_audit.svg')"></el-image>
                                        </el-card>
                                        <span class="span">成型审核</span>
                                        </router-link> 
                                    </el-col>
                                </el-row>
                                <h5>固定资产</h5>
                                <el-row type="flex" style="margin-bottom: 20px;">
                                    <el-col :span="4">
                                        <router-link to="/pieceCapital">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/清除_clear.svg')"></el-image>
                                        </el-card>
                                        <span class="span">设备保养</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                    <el-col :span="4">
                                        <router-link to="/pieceCapitalStatus2">
                                        <el-card class="card" :body-style="{ padding: '14px' }">
                                            <el-image fit="cover" :src="require('@/assets/icon/iconPark48/设备_devices.svg')"></el-image>
                                        </el-card>
                                        <span class="span">设备报修</span>
                                        </router-link> 
                                    </el-col>
                                    <el-col :span="1"></el-col>
                                </el-row>
                                </el-scrollbar>
                            </el-tab-pane> 
                            <el-tab-pane> 
                                <span slot="label">
                                    <div style="font-size: small;font-weight: bold;">制造MES</div>
                                </span>
                                
                            </el-tab-pane> 
                            <el-tab-pane> 
                                <span slot="label">
                                    <div style="font-size:x-small;font-weight: bold;">员工件资</div>
                                </span>
                            </el-tab-pane> 
                            <el-tab-pane> 
                                <span slot="label">
                                    <div style="font-size:x-small;font-weight: bold;">固定资产</div>
                                </span>
                               
       
                            </el-tab-pane> 
                        </el-tabs>

                     




                    
                  
                    </div>
                </el-tab-pane>
                <el-tab-pane name="second" class="tab_pane">
                    <span slot="label">
                        <div class="tab_pane_span_div">
                            <el-image lazy fit="cover" :src="require('@/assets/icon/iconPark24/用户_user.svg')" alt=""></el-image>
                        </div>
                        <div class="tab_pane_span_div2">我的</div>
                    </span>
                    <div class="tab_pane_div">
                        <el-card class="box-card">
                            <el-row>
                                <el-col :span="5" style="text-align: left;">
                                    <div>
                                        <el-avatar style="border-radius: 10px;" shape="square" :size="50" :src="squareUrl"></el-avatar>
                                    </div>
                                </el-col>
                                <el-col :span="15" style="text-align: left;">
                                    <div @click="GetDetailInfo">
                                        <el-image fit="contain" :src="require('@/assets/icon/iconPark16/男性_male.svg')"></el-image>
                                        <span style="font-size: 15px;"> {{ pname}}</span> 
                                        <span style="font-size: 11px;">_{{ pnl }}</span> 
                                    </div>
                                    <div style="padding-top: 5px;">
                                        <span style="font-size: medium;">微信号{{ nickname }}</span> 
                                    </div>
                                </el-col>
                                <el-col :span="4" style="text-align: right;">
                                    <div>
                                        <el-image fit="contain" :src="require('@/assets/icon/iconPark16/VIP_vip_warning.svg')" alt=""></el-image>
                                        <span style="font-size: 9px;margin-left: 5px">{{ work_age }}</span> 
                                    </div>
                                    <div style="padding-top: 5px;">
                                        <el-image fit="contain" :src="require('@/assets/icon/iconPark16/正确用户_right-user.svg')" alt=""></el-image>
                                        <span style="font-size: smaller;margin-left: 5px">在线</span> 
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                        <el-table 
                            :data="tabel_data_mine" 
                            stripe 
                            size="medium" 
                            :show-header="false" 
                            v-loading="loading_minie" 
                            @row-click="tabel_mine_click"
                            :cell-style="() => {return 'fontSize: 16px;'}">
                            <el-table-column width="45px" align="right">
                                <template slot-scope="scope">
                                    <el-image fit="contain" :src="scope.row.icon_url"></el-image>
                                </template>
                            </el-table-column>
                            <el-table-column prop="label" width="100px">
                                <template slot-scope="scope">
                                    <div style="margin-top: -3px;">
                                        {{ scope.row.label }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="value" align="right">
                            </el-table-column>
                        </el-table>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-result 
            icon="error" 
            title="您已离职" 
            subTitle="请点击关闭按钮，退出该页面；如果是返厂员工，请点击注册按钮，重新注册" 
            v-if="!on_job">
            <template slot="extra">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-button type="primary" style="width: 100%" size="medium" @click="PageClose">关闭</el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-button type="primary" style="width: 100%" size="medium" @click="Logout">注册</el-button>
                    </el-col>
                </el-row>
            </template>
        </el-result>
        
    </div>
</template>

<script>
import conf from '../conf'
import qs from 'qs'
import axios from 'axios'
import wx from 'weixin-js-sdk'
export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
            squareUrl: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
            nickname: '',
            pnl: '',
            sec_nm: '',
            zw_nm: '',
            work_age: '',
            on_job: true,
            pname: '',
            activeName: 'first',
            tabel_data_mine: [],
            loading_minie: false,
            ekp_notices: [{
                title: '本周末职能部门上班安排（4月6日）',
                name: '张思婷',
                date: '04-03',
                url: 'http://ekp.qzhqxf.com:9611/sys/news/sys_news_main/sysNewsMain.do?method=view&fdId=18ea2b5f25239c9835310ec4fa6a953e',
            },{
                title: '工艺车间组织结构图更新公告',
                name: '张立萍',
                date: '04-02',
                url: 'http://ekp.qzhqxf.com:9611/sys/news/sys_news_main/sysNewsMain.do?method=view&fdId=18e9dac29b16114477029544b8cbdb9e',
            },{
                title: '截止2024年4月可享受年休假人员名单公告',
                name: '张思婷',
                date: '04-03',
                url: 'http://ekp.qzhqxf.com:9611/sys/news/sys_news_main/sysNewsMain.do?method=view&fdId=18e9d800e87858de0e7847a46b5ab64f',
            }]
        }
    },
    methods: {
        tab_change(tab) {
            console.log('tab', tab)
            console.log('this.$route.meta', this.$route)
            console.log('window.document.title', window.document.title)
            if(tab.name === 'second') {
                this.$route.meta.title = '个人中心'
                window.document.title = '个人中心'
                console.log('this.$route.meta.title', this.$route.meta.title)
            }else {
                this.$route.meta.title = '寰球移动办公'
                console.log('this.$route.meta.title', this.$route.meta.title)
                window.document.title = '寰球移动办公'
            }
        },
        async getOpenid(code) {
            // const res = await axios.get(conf.api_url + `/gateway/weixinapi/TokenAuthorize/OpenID?code=${code}`)
            // const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/OpenIDPost', qs.stringify({
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/AccessToken', qs.stringify({
                code: code
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                localStorage.setItem('openid', res.data.ResponseData.OPENID)
                localStorage.setItem('access_token', res.data.ResponseData.ACCESS_TOKEN)
                // this.$store.commit('setOpenId', res.data.ResponseData.OPENID)
                // this.$store.commit('setAccessToken', res.data.ResponseData.ACCESS_TOKEN)
                window.location.replace(conf.server_url)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async getAccessCode() {
            const state = ''
            // const state = conf.api_url + '/gateway/weixinapi/TokenAuthorize/WeiXinAuth'
            const scope = 'snsapi_userinfo'
            // const redirectUrl = encodeURIComponent(conf.server_url + '/gateway/weixinapi/TokenAuthorize/WeiXinAuth')
            const redirectUrl = encodeURIComponent(conf.server_url)
            // const redirectUrl = encodeURIComponent(conf.server_url + '/jump') // http://wx.qzhqxf.com/jump
            // console.log('redirectUrl:', redirectUrl)
            const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${conf.weixinInfo.appid}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
            // console.log('url:' + url)
            // window.location.href = url
            window.location.replace(url)
        },
        async getUserInfo(openid) {
            // const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/CheckExpires', qs.stringify({
            //     openid: openid,
            // }))
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/UserInfo', qs.stringify({
                access_token: localStorage.getItem('access_token'),
                // access_token: this.$store.getters.getAccessToken,
                openid: openid
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.squareUrl = res.data.ResponseData.HEADIMGURL
                this.nickname = res.data.ResponseData.NICKNAME
                localStorage.setItem('nickname', this.nickname)
                localStorage.setItem('headimgurl', this.squareUrl)
                this.CheckFirstLogin(openid)
            }else if(res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                localStorage.setItem('openid', '')
                // this.$store.commit('setOpenId', '')
                this.getAccessCode()
            }
        },
        async CheckFirstLogin(openid) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxUsers/CheckOpenid', qs.stringify({
                openid
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                window.location.href = '/register'
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 0) {
                // 用openid取员工信息
                localStorage.setItem('PNL', res.data.ResponseData[0].PNL)
                localStorage.setItem('PNAME', res.data.ResponseData[0].PNAME)
                localStorage.setItem('PHONE', res.data.ResponseData[0].PHONE)
                localStorage.setItem('SEC_NO', res.data.ResponseData[0].SECNO)
                localStorage.setItem('SEC_NM', res.data.ResponseData[0].SECNM)
            }
        },
        GetDetailInfo() {
            window.location.href = '/detailInfo'
        },
        Logout() {
            localStorage.setItem('PNL', '')
            localStorage.setItem('PNAME', '')
            localStorage.setItem('PHONE', '')
            localStorage.setItem('SEC_NO', '')
            localStorage.setItem('SEC_NM', '')
            // localStorage.setItem('openid', '')
            // localStorage.setItem('access_token', '')
            window.location.href = '/register'
            // this.$store.commit('setOpenId', '')
            // this.$store.commit('setAccessToken', '')
        },
        async getPersonByPnl() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsPersons/PersonInfo', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.sec_nm = res.data.ResponseData[0].SEC_NM
                // this.work_age = '约 ' + res.data.ResponseData[0].WORK_AGE + ' 年'
                 this.work_age = '1460'
                this.zw_nm = res.data.ResponseData[0].ZW_NM
                // this.$store.commit('setOnJob', !res.data.ResponseData[0].DDATE ? true : false)
                this.on_job = !res.data.ResponseData[0].DDATE ? true : false
            }
        },
        async getMenuAuth() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxMenu/WxMenuAuth', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.$store.commit('setMenuAuth', res.data.ResponseData)
            }
        },
        PageClose() {
            wx.closeWindow()
        },
        toEKP(url) {
            window.location.href = url
        },
        tabel_mine_click(row) {
            console.log('row', row)
            window.location.href = row.link_url
        }
    },
    mounted() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            this.getPersonByPnl()
            // this.getMenuAuth() // 菜单权限
        }
        const pname = localStorage.getItem('PNAME')
        if(pname){
            this.pname = pname
        }

        this.tabel_data_mine = []
        this.tabel_data_mine.push({
            link_url: '/attendance',
            icon_url: require('@/assets/icon/iconPark24/计划_plan.svg'),
            label: '考勤',
            value:  '>'
        })
        this.tabel_data_mine.push({
            link_url: '/salary',
            icon_url: require('@/assets/icon/iconPark24/钞票_paper-money.svg'),
            label: '薪资',
            value: '>'
        })
        this.tabel_data_mine.push({
            link_url: '/reward',
            icon_url: require('@/assets/icon/iconPark24/奖杯_trophy.svg'),
            label: '奖惩',
            value: '>'
        })
        this.tabel_data_mine.push({
            link_url: '/guarantee',
            icon_url: require('@/assets/icon/iconPark24/保护_protect.svg'),
            label: '保障',
            value: '>'
        })
        this.tabel_data_mine.push({
            link_url: '/waterElectricity',
            icon_url: require('@/assets/icon/iconPark24/水电费_water-rate-two.svg'),
            label: '水电',
            value: '>'
        })
    },
    created() {
        // console.log('this is Home') // dev
        // console.log('this.$route.query.openid', this.$route.query.openid) // dev
        
        // if(!this.$route.query.openid){ // dev -----
        //     // 非跳转
        //     const openid = localStorage.getItem('openid') 
        //     // const openid = this.$store.getters.getOpenId
        //     if(!openid) {
        //         if(this.$route.query.code) {
        //             this.getOpenid(this.$route.query.code)
        //             // 2021-07-08 测试可以，部署不行
        //             // const url = `${conf.api_url}/gateway/weixinapi/TokenAuthorize/WeiXinAuthHistory?code=${this.$route.query.code}`
        //             // const url = `http://www.qzhqxf.com:17563/gateway/weixinapi/TokenAuthorize/WeiXinAuthHistory?code=${this.$route.query.code}`
        //             // console.log('url:' + url)
        //             // window.location.replace(url)
        //         }else {
        //             this.getAccessCode()
        //         }
        //     }else{
        //         this.getUserInfo(openid)
        //     }
        // }else{ // 跳转 传 openid
        //     localStorage.setItem('openid', this.$route.query.openid)
        //     // this.$store.commit('setOpenId', this.$route.query.openid)
        //     this.getUserInfo(this.$route.query.openid)
        // } // dev ----------------
    }
}
</script>

<style scoped>
    a:link { 
        text-decoration: none;
        color: #606266;
    }
    a:visited {
        text-decoration: none;
        color: #606266;
    }
    a:active { 
        text-decoration: blink;
        color: #606266;
    }
    .box-card {
        margin: 0px;
        padding: 5px 0px 5px 0px;
        /* background-color: #C6E2FF; */
        background-color: #B3D8FF;
        border-radius: 10px;
    }
    .card {
        width: 56px;
        height: 56px;
        border-radius: 10px;
        margin-bottom: 5px;
    }
    .image {
        margin: -20px;
        /* border-radius: 5px */
        width: 40px;
        height: 40px;
    }
    .span {
        font-size: 11px; 
        font-weight: 550;
    }
    .tab_pane_div {
        height: calc(100vh - 80px);
        /* overflow: auto; */
        overflow: hidden; /* 隐藏滚动条 */
    }
    .tab_pane_span_div {
        margin-bottom: -38px;
        padding: 0 66px;
    }
    .tab_pane_span_div2 {
        font-size: 11px; 
    }
    h5 {
        text-align: left;
        margin: 0px 0px 20px 0px; 
        background-color: rgb(217, 236, 255);
        /* background-color: #EBEEF5; */
        padding: 10px 0px 8px 8px; 
        border-radius: 5px
    }
</style>