<template>
    <div>
        <!-- <el-row style="margin-bottom: 20px" :gutter="5">
            <el-col :span="4" style="font-size: 13px;font-weight: bold;text-align: right;">部门：</el-col>
            <el-col :span="20" style="font-size: 14px;text-align: left;font-weight: bold;color: #F56C6C;">{{sec_nm}}</el-col>
        </el-row> -->
        <!-- <el-row style="margin-bottom: 20px"> -->
            <!-- <el-col :span="3" style="margin-top: 5px;font-size: 13px;align-text: left">部门：</el-col>
            <el-col :span="5" style="margin-top: 5px;font-size: 13px;font-weight: bold">{{sec_nm}}</el-col> -->
            <!-- <el-col :span="6" style="margin-top: 5px;font-size: 13px;text-align: left">提报部门：</el-col>
            <el-col :span="18" >
                <el-autocomplete
                    style="width: 100%"
                    size="small"
                    class="inline-input"
                    v-model="j_sec_nm"
                    :fetch-suggestions="querySearch"
                    :placeholder="sec_nm"
                    :trigger-on-focus="false"
                    @select="handleSelect"
                ></el-autocomplete>
            </el-col>
        </el-row> -->
        <el-row style="margin-bottom: 10px" :gutter="10">
            <el-col :span="19">
                <el-descriptions :column="2" size="small" border>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            部门
                        </template>
                        {{ sec_nm }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-user"></i>
                            姓名
                        </template>
                        {{ pname }}
                    </el-descriptions-item>
                </el-descriptions>
            </el-col>
            <el-col :span="5">
                <el-button 
                    size="small" 
                    type="danger" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI">扫码</el-button>
            </el-col>
        </el-row>
        <el-table :data="tableData" size="small" :show-header="false" style="margin-bottom: 20px">
            <el-table-column prop="label" width="90px">
            </el-table-column>
            <el-table-column prop="value" align="right" class-name="table_col">
                <template slot-scope="scope">
                    <div class="table-col">{{scope.row.value}}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-row style="margin-bottom: 20px" :gutter="5">
            <el-col :span="4" style="margin-top: 5px;font-size: 13px;font-weight: bold;text-align: right;">数量：</el-col>
            <el-col :span="20" style="font-size: 13px;text-align: left;font-weight: bold">
                <el-input-number 
                    size="small" 
                    v-model="blpart.qty" 
                    @change="QtyChange" 
                    :min="1" 
                    style="width: 100%;color: #F56C6C;font-weight: bold;"></el-input-number>
            </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px" :gutter="5">
            <el-col :span="4" style="margin-top: 5px;font-size: 13px;font-weight: bold;text-align: right;">部门：</el-col>
            <el-col :span="20" style="text-align: left">
                <!-- <el-autocomplete
                    style="width: 100%;"
                    size="small"
                    :rows="1"
                    popper-class="my-autocomplete"
                    v-model="blpart.j_sec_no"
                    :fetch-suggestions="querySearch2"
                    @change="SecChange"
                    placeholder="请输入部门编号或者部门名称">
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.value }}</div>
                    </template>
                </el-autocomplete> -->
                <el-select v-model="blpart.j_sec_no" placeholder="请选择部门" size="small" style="width: 100%;" @change="JSecChange">
                    <el-option
                        v-for="item in secs"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        <span style="float: left">{{ item.label }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <!-- <el-row v-if="is_sec" style="margin-bottom: 20px" :gutter="5">
            <el-col :span="4" style="margin-top: 5px;font-size: 13px;font-weight: bold;text-align: right;">点检员：</el-col>
            <el-col :span="14" style="font-size: 13px;text-align: left;font-weight: bold">
                <el-autocomplete
                    style="width: 100%;"
                    size="small"
                    :rows="1"
                    popper-class="my-autocomplete"
                    v-model="blpart.pnl1"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入工号或者姓名">
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.value }}</div>
                    </template>
                </el-autocomplete>
            </el-col>
            <el-col :span="6" style="text-align: right;">
                <el-checkbox size="small" v-model="is_sec" @change="IsSecChange" style="width: 90%;" label="本组" border></el-checkbox>
            </el-col>
        </el-row>
        <el-row v-if="!is_sec" style="margin-bottom: 20px" :gutter="5">
            <el-col :span="4" style="margin-top: 5px;font-size: 13px;font-weight: bold;text-align: right;">点检员：</el-col>
            <el-col :span="14" style="font-size: 13px;text-align: left;font-weight: bold">
                <el-input v-model="blpart.pnl2" size="small" placeholder="请输入工号"></el-input>
            </el-col>
            <el-col :span="6" style="text-align: right;">
                <el-checkbox v-model="is_sec" size="small" @change="IsSecChange" style="width: 90%;" label="本组" border></el-checkbox>
            </el-col>
        </el-row> -->
        <el-row style="margin-bottom: 20px" :gutter="5">
            <el-col :span="4" style="margin-top: 5px;font-size: 13px;font-weight: bold;text-align: right;">工序：</el-col>
            <el-col :span="20" style="text-align: left;">
                <el-select v-model="blpart.j_no" placeholder="请选择工序" size="small" style="width: 100%;">
                    <el-option
                        v-for="item in processes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        <span style="float: left">{{ item.label }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row style="margin-bottom: 10px">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    size="medium"
                    :loading="isLoading"
                    @click="SavePartUser">确认交库</el-button>
            </el-col>
        </el-row>
        <el-collapse v-model="activeNames">
            <el-collapse-item name="1">
                <template slot="title">
                    <div style="font-weight: bold">上传记录</div>
                </template>
                <el-row :gutter="10" style="margin: 0px">
                    <el-col :span="12">
                        <el-date-picker
                            size="small"
                            v-model="date1"
                            align="right"
                            type="date"
                            @change="dateChange"
                            placeholder="选择日期"
                            style="width: 100%"
                            value-format="yyyy-MM-dd"
                            >
                        </el-date-picker>
                    </el-col>
                    <el-col :span="12">
                        <el-date-picker
                            size="small"
                            v-model="date2"
                            align="right"
                            type="date"
                            @change="dateChange"
                            placeholder="选择日期"
                            style="width: 100%"
                            value-format="yyyy-MM-dd"
                            >
                        </el-date-picker>
                    </el-col>
                </el-row>
                <el-table :data="tableCheckData" size="small" style="width: 100%">
                    <el-table-column prop="odr_no" label="日期 / 指令号 / 批号 / 工序 / 部件 / 尺码 / 数量">
                        <!-- <template slot-scope="scope">
                            <div>{{scope.row.odr_no}}</div>
                            <div>{{scope.row.cwhy}}</div>
                        </template> -->
                    </el-table-column>
                    <!-- <el-table-column prop="sch_no" label="批号"> -->
                        <!-- <template slot-scope="scope">
                            <div>{{scope.row.cman}}</div>
                            <div>{{scope.row.chow}}</div>
                        </template> -->
                    <!-- </el-table-column> -->
                    <!-- <el-table-column prop="part_nm" label="部件 / 尺码 / 数量"></el-table-column> -->
                    <!-- <el-table-column prop="osize" label="尺码"></el-table-column>
                    <el-table-column prop="qty" label="数量"></el-table-column> -->
                </el-table>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import wx from 'weixin-js-sdk'
import moment from 'moment'
export default {
    data() {
        return {
            processes: [],
            code_values: [],
            tableData: [],
            tableCheckData: [],
            activeNames: ['0'],
            sec_data: [],
            blpart: {
                vou_no: '',
                odr_no: '',
                sch_no: '',
                part_no: '',
                part_nm: '',
                j_sec_no: '',
                osize: '',
                qty: '',
                pnl: '',
                insertman: '',
                pvou_no: '',
                j_no: '',
                pnl1: '',
                qno: '',
                pnl2: ''
            },
            isLoading: false,
            sec_nm: '',
            sec_no: '',
            j_sec_nm: '',
            pnls: [],
            pname: '',
            secs: [],
            sqty: 0,
            is_sec: true,
            date1: moment().format('YYYY-MM-DD'),
            date2: moment().format('YYYY-MM-DD')
        }
    },
    methods: {
        dateChange() {
            if(this.date1 && this.date2) {
                this.GetPartList()
            }
        },
        GetJSAPI() {
            // 测试数据
            // let result = "QAS/2108006-001;01;BW-A003;外头;10.5;15;HPA20210901611" // dev
            // let result = "QAS/2206010-001;01;3A0703;舌翼;UK3.5;20;HPA20220800800" // dev
            // let result = "QAS/2310116-001;;BW1002237;鞋身下片外腰;8.5;24;HPA20231201050" // dev
            // this.setTableValue(result) // dev

            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    let result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
                    // console.log(result)
                    this.setTableValue(result)
                }
            })
        },
        setTableValue(code_str) {
            this.code_values = code_str.split(';')
            console.log(this.code_values)
           
            // this.blpart = {
            //     odr_no: this.code_values[0],
            //     sch_no: this.code_values[1],
            //     part_no: this.code_values[2],
            //     // j_sec_no: this.j_sec_nm ? this.blpart.j_sec_no : this.sec_no,
            //     // j_sec_no: this.sec_no,
            //     j_sec_no: this.blpart.j_sec_no,
            //     osize: this.code_values[4],
            //     qty: this.code_values[5],
            //     // j_no: this.blpart.j_no,
            //     // pnl: this.blpart.pnl,
            //     // insertman: this.blpart.insertman,
            //     // pvou_no: this.code_values[6],
            //     // qno: code_str,
            // }

            // this.GetPnlds()
            this.tableData = []
            // this.tableData.push({
            //     label: '单据号',
            //     value: this.code_values[6]
            // })
            this.tableData.push({
                label: '指令单号',
                value: this.code_values[0]
            })
            if(this.code_values[1]) {
                this.tableData.push({
                    label: '指令批号',
                    value: this.code_values[1]
                })
            }
            // this.tableData.push({
            //     label: '部件编号',
            //     value: this.code_values[2]
            // })
            this.tableData.push({
                label: '部件名称',
                value: this.code_values[3]
            })
            this.tableData.push({
                label: '指令SIZE',
                value: this.code_values[4]
            })

            this.blpart.odr_no = this.code_values[0]
            this.blpart.sch_no = this.code_values[1]
            this.blpart.part_no = this.code_values[2]
            this.blpart.osize = this.code_values[4]
            this.blpart.qty = this.code_values[5]
            this.blpart.pvou_no = this.code_values[6]
            this.blpart.qno = code_str
            this.sqty = this.code_values[5]
            // this.tableData.push({
            //     label: '数量',
            //     value: this.code_values[5]
            // })
            // this.tableData.push({
            //     label: '点检员',
            //     value: this.blpart.pnl1
            // })
            
            
        },
        async getProcesses() {
            this.blpart.j_no = ''
            this.processes = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AleProBlpartioUsers/Processes', qs.stringify({
                // dto: this.blpart
                odr_no: this.blpart.odr_no,
                sch_no: this.blpart.sch_no,
                part_no: this.blpart.part_no,
                j_sec_no: this.blpart.j_sec_no,
                osize: this.blpart.osize,
                qty: this.blpart.qty
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.forEach(item => {
                    this.processes.push({
                        label: item.j_nm,
                        value: item.j_no
                    })
                })
                if(this.processes.length === 1) {
                    this.blpart.j_no = this.processes[0].value
                }
                // else {
                //     this.blpart.j_no = ''
                // }
            }
        },
        QtyChange(qty) {
            // console.log('qty', qty)
            if(qty > this.sqty) {
                this.blpart.qty = this.sqty
                this.$message({
                    message: '数量不能超过：' + this.sqty,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetPartSec() {
            this.secs = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AleProBlpartioUsers/GetPartSec1')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                // if(res.data.ResponseData.length > 0) {
                //     res.data.ResponseData.forEach(item => {
                //         this.secs.push({
                //             "value": item.sec_no + ' - ' + item.sec_nm
                //         })
                //     })   
                // }
                res.data.ResponseData.forEach(item => {
                    this.secs.push({
                        label: item.sec_nm,
                        value: item.sec_no
                    })

                    if(item.sec_no === this.sec_no){
                        // this.blpart.j_sec_no = this.sec_no    // 部门取消默认
                        this.GetPartPNL(this.blpart.j_sec_no)
                    }
                })

                // this.getProcesses()
            }
        },
        // querySearch2(queryString, cb) {
        //     var restaurants = this.secs
        //     var results = queryString ? restaurants.filter(this.createFilter2(queryString)) : restaurants
        //     // 调用 callback 返回建议列表的数据
        //     cb(results)
        // },
        // createFilter2(queryString) {
        //     return (restaurant) => {
        //         return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        //     }
        // },
        // SecChange() {
        //     console.log('来了老弟', this.blpart.j_sec_no)
        //     this.GetPartPNL(this.blpart.j_sec_no.split(' - ')[0])
        // },
        JSecChange(sec_no) {
            if(this.blpart.odr_no !== ''){
                this.getProcesses()
            }else {
                this.$message({
                    message: '请先进行扫码',
                    center: true,
                    type: 'error'
                })
                return
            }
            
            this.GetPartPNL(sec_no)
        },
        async GetPartPNL(sec_no) {
            this.blpart.pnl1 = ''
            this.pnls = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AleProBlpartioUsers/GetPartPNL', qs.stringify({
                sec_no
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.forEach(item => {
                        this.pnls.push({
                            "value": item.pnl + ' - ' + item.pname
                        })
                    })   
                }
            }
        },
        querySearch(queryString, cb) {
            var restaurants = this.pnls
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
            // 调用 callback 返回建议列表的数据
            cb(results)
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
            }
        },
        // async GetPnlds() {
        //     const res = await axios.post(conf.api_url + '/gateway/weixinapi/AleProBlpartioUsers/PnlDs', qs.stringify({
        //         dto: this.blpart
        //     }))
        //     console.log(res)
        //     if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
        //         // console.log('res.data.ResponseData[0].pnl_ds', res.data.ResponseData[0].pnl_ds)
        //         if(res.data.ResponseData.length > 0) {
        //             this.blpart.pnl1 = res.data.ResponseData[0].pnl_ds
        //             this.tableData.push({
        //                 label: '点检员',
        //                 value: res.data.ResponseData[0].pname
        //             })
        //         }
        //     }
        // },
        IsSecChange() {
            if(this.is_sec) {
                this.GetPartPNL(this.blpart.j_sec_no)
            }else {
                this.blpart.pnl2 = ''
            }
        },
        async SavePartUser() {
            if(this.tableData.length === 0) {
                this.$message({
                    message: '请先进行扫码再交库',
                    center: true,
                    type: 'error'
                })
                return
            }
            if(!this.blpart.j_no) {
                this.$message({
                    message: '请先选择工序再交库',
                    center: true,
                    type: 'error'
                })
                return
            }
            if(!this.blpart.j_sec_no) {
                this.$message({
                    message: '请先选择部门再交库',
                    center: true,
                    type: 'error'
                })
                return
            }
            // if(!this.blpart.pnl1) {
            //     this.$message({
            //         message: '请先选择点检员再交库',
            //         center: true,
            //         type: 'error'
            //     })
            //     return
            // }
            this.isLoading = true
            this.blpart = {
                odr_no: this.code_values[0],
                sch_no: this.code_values[1],
                part_no: this.code_values[2],
                j_sec_no: this.blpart.j_sec_no.split(' - ')[0],
                osize: this.code_values[4],
                j_no: this.blpart.j_no,
                qty: this.blpart.qty,
                pnl: this.blpart.pnl,
                insertman: this.blpart.pnl,
                pvou_no: this.blpart.pvou_no,
                qno: this.blpart.qno,
                pnl1: this.is_sec ? this.blpart.pnl1.split(' - ')[0] : this.blpart.pnl2
            }
            console.log('dto', this.blpart)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AleProBlpartioUsers/SavePartUserCheck2', qs.stringify({
                dto: this.blpart
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.ResponseData) {
                this.$message({
                    message: res.data.ResponseData,
                    center: true,
                    type: 'error',
                    duration: 2000
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && !res.data.ResponseData){
                this.$message({
                    message: '件资回馈成功！',
                    center: true,
                    type: 'success',
                    duration: 1000
                })
                
                this.GetPartList()
                // this.AddUpdateLog(this.blpart)
                // this.tableData = []
                // this.processes = []
                // this.blpart.j_no = ''
            }else {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error',
                    duration: 1000
                })
            }

            this.isLoading = false
        },
        // AddUpdateLog(data) {
        //     console.log('data', data)
        //     this.tableCheckData.push({
        //         odr_no: data.sch_no ? data.odr_no + ' / ' +  data.sch_no + ' / ' + data.part_nm : data.odr_no + ' / ' +  ' / ' + data.part_nm,
        //         part_nm: this.code_values[3] + ' / ' + data.osize + ' / ' + data.qty,
        //     })
        //     this.activeNames = ['1']
        // },
        // querySearch(queryString, cb) {
        //     var restaurants = this.sec_data
        //     var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
        //     // 调用 callback 返回建议列表的数据
        //     cb(results)
        // },
        // createFilter(queryString) {
        //     return (restaurant) => {
        //         return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        //     }
        // },
        // async getSecData() {
        //     const res = await axios.post(conf.api_url + '/gateway/weixinapi/AleProBlpartioUsers/SecData')
        //     console.log(res)
        //     if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
        //         res.data.ResponseData.map(item => {
        //             this.sec_data.push({
        //                 label: item.sec_no,
        //                 value: item.sec_nm
        //             })
        //         })
        //     }
        // },
        // handleSelect(item) {
        //     // console.log(item, item)
        //     this.blpart.j_sec_no = item.label
        //     // console.log('item.label', item.label)
        // },
        async GetPartList() {
            this.tableCheckData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AleProBlpartioUsers/GetPartList', qs.stringify({
                pnl: this.blpart.pnl,
                date1: this.date1,
                date2: this.date2
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                // let count = 0
                // let sum = 0
                res.data.ResponseData.forEach(item => {
                    // count++
                    // sum+=item.qty
                    this.tableCheckData.push({
                        odr_no: moment(item.iod).format('MM-DD HH:mm') + ' / ' + item.odr_no + ' / ' +  item.sch_no + ' / ' + item.j_nm + ' / ' + item.part_nm + ' / ' + item.osize + ' / ' + item.qty,
                    })
                })

                // this.column_total = ' 【'+count+'次/'+sum+'双】'
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        this.GetSignature() 
        // this.getSecData()
        this.GetPartSec()
        // this.GetPartPNL()
        this.GetPartList()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.blpart.pnl = pnl
        }
        const pname = localStorage.getItem('PNAME')
        if(pname) {
            this.pname = pname
        }
        const sec_no = localStorage.getItem('SEC_NO')
        if(sec_no) {
            this.sec_no = sec_no
        }
        const sec_nm = localStorage.getItem('SEC_NM')
        if(sec_nm) {
            this.sec_nm = sec_nm
        }

        // 测试数据
        // this.blpart.pnl = '68352' // dev
        // this.blpart.pnl = '68352' // dev
        // this.sec_no = 'HQ081A' // dev
        // this.sec_nm = '成控部人工组'
    }
}
</script>

<style scoped>
    .table-col {
        font-weight: bold;
        font-size: 14px;
        color: #F56C6C;
    }
</style>