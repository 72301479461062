<template>
    <div>
        <el-form :model="sampleForm" ref="sampleForm" size="medium">
            <el-form-item prop="keyword">
                <el-input placeholder="指令单号、型号、姓名" v-model="sampleForm.keyword">
                    <el-button size="small" slot="append" icon="el-icon-full-screen" @click="GetJSAPI"></el-button>
                    <el-button size="small" slot="append" icon="el-icon-search" @click="handleClick"></el-button>
                </el-input>
            </el-form-item>
        </el-form>
        <el-tabs type="border-card" :stretch="true" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane name="first">
                <span slot="label"><i class="el-icon-date"></i> 最近借用</span>
                <el-table :data="zj_tableData" :show-header="false" stripe v-loading="zj_table_loading" size="small">
                    <el-table-column prop="name" width="158px">
                        <template slot-scope="scope">
                            <div style="font-size: 14px; font-weight: bold">{{scope.row.sample_no}}</div>
                            <div>{{scope.row.color}}</div>
                            <div>{{scope.row.odr_no}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="txt" align="right">
                        <template slot-scope="scope">
                            <div>
                                <span style="margin-right: 5px">{{scope.row.sec_nm}}</span>
                                <span style="color: #E6A23C; font-weight: bold">{{scope.row.pname}}</span>
                            </div>
                            <div style="color: #F56C6C; font-weight: bold">{{scope.row.date}}</div>
                            <div style="font-size: 13px; color: #409EFF">
                                <a :href="'tel:' + scope.row.tel">
                                    <i class="el-icon-mobile-phone"></i>
                                    {{scope.row.tel}}
                                </a>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane name="second">
                <span slot="label"><i class="el-icon-date"></i> 全部</span>
                <el-table :data="all_tableData" :show-header="false" stripe v-loading="all_table_loading" size="small">
                    <el-table-column prop="name">
                        <template slot-scope="scope">
                            <div style="font-size: 14px; font-weight: bold">{{scope.row.sample_no}}</div>
                            <div>{{scope.row.color}}</div>
                            <div>{{scope.row.odr_no}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="txt" align="right">
                        <template slot-scope="scope">
                            <div>{{scope.row.info}}</div>
                            <div style="font-size: 13px; font-weight: bold; color: #F56C6C">{{scope.row.date}}</div>
                            <div><i v-if="scope.row.tel" class="el-icon-mobile-phone"></i> {{scope.row.tel}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf.js'
import moment from 'moment'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            activeName: 'first',
            sampleForm: {
                keyword: ''
            },
            pnl: '',
            zj_tableData: [],
            all_tableData: [],
            zj_table_loading: false,
            all_table_loading: false
        }
    },
    methods: {
        handleClick() {
            if(this.activeName === 'first') {
                this.GetLastSampleList()
            }else {
                this.GetSampleListAll()
            }
        },
        async GetLastSampleList() {
            this.zj_tableData = []
            this.zj_table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Sample/LastSampleList', qs.stringify({
                keyword: this.sampleForm.keyword
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                res.data.ResponseData.forEach(item => {
                    this.zj_tableData.push({
                        sample_no: item.sampleNo,
                        color: item.color,
                        odr_no: item.odr_no,
                        sec_nm: item.sec_nm,
                        pname: item.pname,
                        date: moment(item.ioTime).format('MM/DD') + ' 借用',
                        tel: item.tel
                    })
                })
            }
            this.zj_table_loading = false
        },
        async GetSampleListAll() {
            this.all_tableData = []
            this.all_table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Sample/SampleListAll', qs.stringify({
                keyword: this.sampleForm.keyword
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                res.data.ResponseData.forEach(item => {
                    this.all_tableData.push({
                        sample_no: item.sampleNo,
                        color: item.color,
                        odr_no: item.odr_no,
                        info: item.isStock == 'N' ? item.sec_nm + ' ' +  item.pname : '',
                        date: item.isStock == 'N' ? moment(item.itime).format('MM/DD') + ' 借用' : '在库',
                        tel: item.isStock == 'N' ? item.tel : ''
                    })
                })
            }
            this.all_table_loading = false
        },
        async GetJSAPI() {
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    // var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    this.$confirm('您确定要借用货号为' + res.resultStr + '的样鞋?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        customClass: 'messageClass',
                    }).then(() => {
                        this.SampleBorrow(res.resultStr)
                    })
                }
            })

            // this.SampleBorrow('112011101-10') // dev
        },
        async SampleBorrow(sampleNo) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Sample/SampleBorrow', qs.stringify({
                sampleNo: sampleNo,
                man: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$message({
                    message: '样鞋借用成功',
                    center: true,
                    type: 'success'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }
        },
    },
    mounted() {
        this.GetSignature()
        this.GetLastSampleList()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            // this.pnl = '43528' // dev
        }
    }
}
</script>