<template>
    <div>
        <el-card v-for="item in list" :key="item.GID" shadow="always" class="box-card">
            <el-row>
                <el-col :span="24" style="text-align: left;font-weight: bold;font-size: 14px">【{{item.GTYPE}}】</el-col>
            </el-row>
            <el-row class="card-row">
                <el-col :span="24" style="text-align: left;font-size: 13px">{{item.GCONTENT}}</el-col>
            </el-row>
            <el-row class="card-row" v-if="item.HAVE_IMAGE !== 0">
                <el-col :span="24" style="text-align: left">
                    <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.DB_ID)">
                        <i class="el-icon-picture-outline"></i> 点击查看
                    </el-link>
                </el-col>
            </el-row>
            <el-row class="card-row" style="font-size: 12px;color: #909399;margin-top: 32px">
                <el-col :span="4" style="text-align: center">
                    <div class="block">
                        <el-avatar :size="30" :src="item.HEADIMGURL"></el-avatar>
                    </div>
                </el-col>
                <el-col :span="9" style="text-align: left;margin-left: 10px">
                    <!-- <div style="margin-bottom: 4px">{{item.PNL}} {{item.PNAME}}</div> -->
                    <div style="margin-bottom: 4px">{{item.PNAME}}【{{ item.PNL }}】</div>
                    <div>
                        <a :href="'tel:' + item.TEL">
                            <i class="el-icon-mobile-phone"></i>{{item.TEL}}
                        </a>
                    </div>
                    <!-- <div>{{item.TEL}}</div> -->
                </el-col>
                <el-col :span="9" style="text-align: right">
                    <div style="margin-bottom: 4px">{{item.GDATE}}</div>
                </el-col>
            </el-row>
        </el-card>
        <el-card v-for="item in rec_list" :key="item.GID" shadow="always" class="box-card">
            <el-row>
                <el-col :span="4" style="text-align: left;">
                            <el-tag
                                v-if="item.RECEIVERMAN === ask_pnl"
                                type="success"
                                size="mini"
                                effect="dark">
                                追问
                            </el-tag>
                            <!-- <el-tag
                                v-if="item.ISEND === 1"
                                style="margin-left: 5px"
                                type="danger"
                                size="mini"
                                effect="dark">
                                完结
                            </el-tag> -->
                        </el-col>
                <el-col :span="20" style="text-align: left">{{item.RECCONTENT}}</el-col>
            </el-row>
            <el-row class="card-row" style="font-size: 12px;color: #909399;margin-top: 32px">
                <el-col :span="4" style="text-align: center">
                    <div class="block">
                        <el-avatar :size="30" :src="item.HEADIMGURL"></el-avatar>
                    </div>
                </el-col>
                <el-col :span="9" style="text-align: left;margin-left: 10px">
                    <!-- <div style="margin-bottom: 4px">{{item.RECEIVERMAN}} {{item.PNAME}}</div> -->
                    <div style="margin-bottom: 4px">{{item.PNAME}}【{{ item.RECEIVERMAN }}】</div>
                </el-col>
                <el-col :span="9" style="text-align: right">
                    <div style="margin-bottom: 4px">{{item.RECTIME}}</div>
                </el-col>
            </el-row>
        </el-card>
        <br>
        <el-form :model="ansForm" :rules="rules" ref="ansForm" label-width="80px" size="medium">
            <el-form-item label="我的回答" prop="recContent">
                <el-input type="textarea" :rows="6" v-model="ansForm.recContent" placeholder="请输入你的回答的内容"></el-input>
            </el-form-item>
            <el-row style="margin-top: 20px;margin-bottom: 30px;">
                <el-col :span="24">
                    <el-button type="primary" style="width: 100%" :loading="isLoading"  
                    @click="submitForm('ansForm')">创建回答</el-button>
                </el-col>
            </el-row>
        </el-form>
        <!-- <el-dialog :visible.sync="imageDialogTableVisible" size="mini" width="90%">
            <span class="dialog-footer">
                <el-image
                    :key="item.DB_ID"
                    v-for="item in imageList"
                    lazy
                    style="width: 100%;"
                    :src="item.path"
                    fit="fill">
                    <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                    </div>
                    <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="mini" @click="imageDialogTableVisible = false">关闭</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import moment from 'moment'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            list: [],
            rec_list: [],
            ansForm: {
                recContent: ''
            },
            isLoading: false,
            gid: '',
            pnl: '',
            ask_pnl: '',
            // imageDialogTableVisible: false,
            imageList: [],
            rules: {
                gContent: [
                    {required: true, message: '请输入你的回答的内容', trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        ShowImages(id) {
            // console.log(id)
            // this.imageDialogTableVisible = true
            this.GetImagesByID(id)
        },
        async GetImagesByID(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        // this.imageList.push({
                        //     id: item.DB_ID,
                        //     path: conf.image_domain + item.FImgPath
                        // })
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                           path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
                console.log('this.imageList', this.imageList)
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid) {
                    console.log(this.ansForm)
                    this.publicAnswer()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async GetQuestion() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlAsks/MyAsk', qs.stringify({
                gid: this.gid
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                // this.list = res.data.ResponseData
                res.data.ResponseData.forEach(item => {
                    item.GDATE = moment(item.GDATE).format('MM-DD HH:mm')
                    this.ask_pnl = item.PNL
                    this.list.push(item)
                })
            }
        },
        async GetRec() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlAskrecss/AskRec', qs.stringify({
                GRID: this.gid
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                // this.rec_list = res.data.ResponseData
                res.data.ResponseData.forEach(item => {
                    item.RECTIME = moment(item.RECTIME).format('MM-DD HH:mm')
                    this.rec_list.push(item)
                })
            }
        },
        async publicAnswer() {
            this.isLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlAskrecss/PublishAnswer', qs.stringify({
                GRID: this.gid,
                RECEIVERMAN: this.pnl,
                RECCONTENT: this.ansForm.recContent
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$message({
                    message: '回答创建' + res.data.Message,
                    center: true,
                    type: 'success'
                })

                this.GetRec()
                this.ansForm.recContent = ''
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
    },
    mounted() {
        this.GetRec()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            // this.pnl = '55129'
            // this.pnl = '49462' // dev
            // this.pnl = '65392' // dev
            // this.pnl = '67276' // dev
        }
        const id = this.$route.params.id
        if(id) {
            this.gid = id
            this.GetQuestion()
        }
    }
}
</script>

<style scoped>
    .box-card{
        margin-top: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 13px;
        /* border-radius: 10px;  */
    } 
    .card-row {
        margin-top: 26px;
    }
</style>