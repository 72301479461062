module.exports = {
    smsInfo: {
        // accessKeyId: 'LTAIg2RI2OXU8Tvu',
        // accessKeySecret: 'fdtNoGo0HHXXg6U5QRmC0VCOSDchNG',
        // signName: '寰球鞋服',
        templateCode: 'SMS_122200033',  // 登录确认验证码
        templateCode2: 'SMS_121912480',  // 忘记密码
        templateCode3: 'SMS_225368706',  // 离职完成通知
    },
    weixinInfo: {
        appid: 'wx2d8707fa61bf19c4', // prod
        // appsecret: 'ec22f5d8a24351129cc61200b83c22e3', // prod
        // appid: 'wxddba0d8927213612', // dev
        // appsecret: 'a73d4c1fed832edf26a2581fe357b5cf', // dev
    },
    // baiduInfo: {
    //     ak: 'K4CmwEymDuxcFPrr1RWK0bMs76ImlRxp'
    // },
    // tokenInfo: {
    //     client_id: 'erpclient',
    //     client_secret: 'anta.123456',
    //     grant_type: 'ERP3.0_AUTHTYPE',
    //     username: '43528',
    //     password: '1223.3321'
    // },
    JSSDKInfo: {
        nonceStr: 'hqxf'
    },
    // server_url: 'http://smallming.free.idcfengye.com', // 内网穿透本地测试  => 127.0.0.1:17563
    // server_url: 'localhost:8080', // dev
    server_url: 'http://wx.qzhqxf.com', // prod
    server_url3: 'http://wx.qzhqxf.com/home3', // prod
    server_url_md: 'http://wx.qzhqxf.com/homeMd', // prod
    server_url_index: 'http://wx.qzhqxf.com/homeIndex', // prod
    // api_url: 'http://localhost:17563', // dev
    api_url: 'http://wx.qzhqxf.com:17563', // prod
    // image_domain: 'http://10.171.128.238:12000', // dev
    image_domain: 'http://minio.qzhqxf.com:12000', // prod
    ekp_url: 'http://ekp.qzhqxf.com:9611/km/review/km_review_main/kmReviewMain.do?method=add&fdTemplateId=17af0f090ef07b0475bc3644f749d132&oauth=wx2ekp',
    ekp_url_safe: 'http://ekp.qzhqxf.com:9611/km/review/km_review_main/kmReviewMain.do?method=add&fdTemplateId=17cfe73cd179e6e74b0e15748bb96cf8&oauth=wx2ekp',
    EKP: {
        email: '',
        meeting: '',
        flow: 'http://ekp.qzhqxf.com:9611/km/review/mobile/#path=0',
        news: '',
        release: 'http://ekp.qzhqxf.com:9611/km /review/mobile/add.jsp?fdTemplateId=16ae792c422c6b8aa975d56473fa00a1',
        leave: 'http://ekp.qzhqxf.com:9611/km/review/mobile/add.jsp?fdTemplateId=16d4d769b3c3917170469e547eda8f9a'
    },
    Andon: {
        endCall: '10.171.128.229/andon/endCall'
    }
}