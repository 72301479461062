<template>
    
   <div>
    <div>
        <!-- <el-row :gutter="15">
            <el-col :span="12"> -->
                <router-link to="/dinnerAdminType">
                    <el-button  size="medium" style="width: 45%" type="success">管理菜品类别</el-button>
                </router-link>
            <!-- </el-col>
            <el-col :span="12"> -->
                <router-link to="/dinnerAdminMenu">
                    <el-button  size="medium" style="width: 45%" type="success">管理菜品明细</el-button>
                </router-link>
            <!-- </el-col>
        </el-row> -->
    </div>
   <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
    <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
    <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
    <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
  </el-tabs>
</div>
  
</div>
   
</template>

<script>
export default {
    data() {
        return {
            activeName: 'second'
        }
    },
    methods: {
        handleClick(tab, event) {
        console.log(tab, event);
      }
    },
    mounted() {

    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>