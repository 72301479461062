<template>
    <div>
        <el-row :gutter="10">
            <el-col :span="12">
                <el-button id="made_cx" :type="made_type_cx" size="small" style="width: 100%" @click="setMadeType">成型</el-button>
            </el-col>
            <el-col :span="12">
                <el-button id="made_bm" :type="made_type_bm" size="small" style="width: 100%" @click="setMadeType">帮面</el-button>
            </el-col>
        </el-row>
        <el-row style="margin-top: 24px;">
            <el-col :span="3" v-for="item in secsData" :key="item.value">
                <el-button :type="item.btn_type" size="small" style="width: 100%;" @click="selection(item)">{{item.label}}</el-button>
            </el-col>
        </el-row>
        <el-form :model="wipForm" ref="wipForm" label-width="0px" size="small" style="padding-top: 24px;">
            <el-form-item prop="keyword">
                <el-input v-model="wipForm.keyword" placeholder="请输入指令单号、型号查询">
                    <el-button slot="append" style="color: #409EFF;" icon="el-icon-search" @click="SubmitForm('wipForm')"></el-button>
                </el-input>
            </el-form-item>
        </el-form>
        <el-table :data="tableData" :show-header="false" stripe v-loading="table_loading" size="mini">
            <el-table-column prop="col1" width="148px">
                <template slot-scope="scope">
                    <div style="color: #606266; font-weight: bold; font-size: 14px">{{scope.row.odr_no}}</div>
                    <div>{{scope.row.artic_no}}</div>
                    <div>{{scope.row.sec_nm}}</div>
                    <div>{{scope.row.color}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="col2" align="right">
                <template slot-scope="scope">
                    <div>派工：{{scope.row.pgqty}}</div>
                    <div>
                        <span style="margin-right: 5px">投入：{{scope.row.iqty}}</span>
                        <span>缴库：{{!scope.row.oqty ? 0 : scope.row.oqty}}</span>
                    </div>
                    <div style="color: #E6A23C; font-weight: bold; font-size: 14px">在制品：{{scope.row.sqty}}</div>
                    <div>
                        <span style="color: #409EFF; font-weight: bold; margin-right: 5px">今日投：{{!scope.row.diqty ? 0 : scope.row.diqty}}</span>
                        <span style="color: #67C23A; font-weight: bold">今日缴：{{!scope.row.doqty ? 0 : scope.row.doqty}}</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import moment from 'moment'
export default {
    data() {
        return {
            made_type_cx: 'primary',
            made_type_bm: 'default',
            wipForm: {
                made_no: '7',
                secs: '',
                keyword: ''
            },
            // rules: {
            //     keyword: [
            //         {required: true, message: '请输入指令单号、型号查询', trigger: 'blur'}
            //     ]
            // },
            tableData: [],
            table_loading: false,
            secsData: [
                {label: '全部', btn_type: 'primary', value: ''},
                {label: 'A线', btn_type: 'default', value: 'A'},
                {label: 'B线', btn_type: 'default', value: 'B'},
                {label: 'C线', btn_type: 'default', value: 'C'},
                {label: 'D线', btn_type: 'default', value: 'D'},
                {label: 'E线', btn_type: 'default', value: 'E'},
                {label: 'F线', btn_type: 'default', value: 'F'},
                {label: 'G线', btn_type: 'default', value: 'G'},
            ]
        }
    },
    methods: {
        setMadeType(id) {
            if(id.target.id === 'made_cx') {
                this.setMadeTypeCX()
            }else {
                this.setMadeTypeBM()
            }
        },
        setMadeTypeCX() {
            this.made_type_cx = 'primary'
            this.made_type_bm = 'default'
            this.wipForm.made_no = '7'
            this.GetWorkInProcessData()
        },
        setMadeTypeBM() {
            this.made_type_cx = 'default'
            this.made_type_bm = 'primary'
            this.wipForm.made_no = '3'
            this.GetWorkInProcessData()
        },
        selection(e) {
            // console.log(e)
            this.secsData.forEach(item => {
                if(item.value === e.value) {
                    item.btn_type = 'primary'
                    this.wipForm.secs = item.value
                    this.GetWorkInProcessData()
                }else {
                    item.btn_type = 'default'
                }
            })
        },
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.wipForm)
                    this.GetWorkInProcessData()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async GetWorkInProcessData() {
            this.tableData = []
            this.table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WorkInProcess/WorkInProcessData', qs.stringify({
                vday: moment().format('YYYY-MM-DD'),
                made_no: this.wipForm.made_no,
                secs: this.wipForm.secs
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                var total_pgqty = 0
                var total_iqty = 0
                var total_oqty = 0 
                var total_sqty = 0
                var total_diqty = 0
                var total_doqty = 0

                var filterData = res.data.ResponseData
                if(this.wipForm.keyword) {
                    filterData = res.data.ResponseData.filter((currentValue, index, arr) => {
                        if(currentValue.odr_no.indexOf(this.wipForm.keyword) > -1 
                            || currentValue.artic_no.indexOf(this.wipForm.keyword) > -1) {
                            return arr
                        }
                    })
                }

                var groupData = this.GroupBy(filterData, ['sec_nm'])
                groupData.map(group => {
                    var group_pgqty = 0
                    var group_iqty = 0
                    var group_oqty = 0 
                    var group_sqty = 0
                    var group_diqty = 0
                    var group_doqty = 0
                    group.data.map(item => {
                        this.tableData.push(item)
                        group_pgqty += item.pgqty
                        group_iqty += item.iqty
                        group_oqty += item.oqty
                        group_sqty += item.sqty
                        group_diqty += item.diqty
                        group_doqty += item.doqty
                    })
                    // 小计
                    this.tableData.push({
                        odr_no: group.key.sec_nm + ' 小计',
                        artic_no: '',
                        sec_nm: '',
                        color: '',
                        pgqty: group_pgqty,
                        iqty: group_iqty,
                        oqty: group_oqty,
                        sqty: group_sqty,
                        diqty: group_diqty,
                        doqty: group_doqty
                    })
                    total_pgqty += group_pgqty
                    total_iqty += group_iqty
                    total_oqty += group_oqty
                    total_sqty += group_sqty
                    total_diqty += group_diqty
                    total_doqty += group_doqty
                })
                // 合计
                if(filterData.length > 0) {
                    this.tableData.push({
                        odr_no: '合计',
                        artic_no: '',
                        sec_nm: '',
                        color: '',
                        pgqty: total_pgqty,
                        iqty: total_iqty,
                        oqty: total_oqty,
                        sqty: total_sqty,
                        diqty: total_diqty,
                        doqty: total_doqty
                    })
                }
            }
            this.table_loading = false
        },
        GroupBy(datas, keys, callBack) {
            const list = datas || []
            const groups = []
            list.forEach(v => {
                const key = {}
                // const data = {}
                keys.forEach(k => {
                    key[k] = v[k]
                })
                let group = groups.find(v => {
                    return v._key === JSON.stringify(key)
                })
                if(!group) {
                    group = {
                        _key: JSON.stringify(key),
                        key: key
                    }
                    groups.push(group)
                }
                if(callBack) {
                    group.data = callBack(group.data, v)
                    group.total = group.total || 0
                    group.total++
                }else {
                    group.data = group.data || []
                    group.data.push(v)
                }
            })
            return groups
        },
    },
    mounted() {
        this.setMadeTypeCX()
    }
}
</script>