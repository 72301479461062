<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;margin-top: -20px;" stretch>
            <el-tab-pane label="扣分一" name="first" lazy>
                <el-row :gutter="10" style="margin-top: 0px">
                    <el-col :span="10">
                        <el-date-picker
                            v-model="checkReportForm.fstart"
                            style="width: 100%;"
                            size="small"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="10">
                        <el-date-picker
                            v-model="checkReportForm.fend"
                            style="width: 100%;"
                            size="small"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="4">
                        <el-button 
                            size="small" 
                            type="danger" 
                            icon="el-icon-refresh" 
                            style="width: 100%;"
                            @click="GetPerson1Points"></el-button>
                    </el-col>
                </el-row>
                <el-table 
                    :data="Person1TableData" 
                    size="mini" 
                    show-summary
                    stripe
                    border
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, height: '32px'}" 
                    :row-style="{align: 'center'}"
                    :cell-style="() => { return 'padding: 0px;margin: 0px;height: 30px;font-size: 11px'}"
                    v-loading="table_loading1" 
                    style="margin-top: 25px">
                    <el-table-column label="序" type="index" min-width="30px" align="center">
                    </el-table-column>
                    <el-table-column prop="姓名" label="姓名" min-width="64px" align="center">
                    </el-table-column>
                    <el-table-column prop="红" label="红" min-width="42px" align="center">
                    </el-table-column>
                    <el-table-column prop="橙" label="橙" min-width="42px" align="center">
                    </el-table-column>
                    <el-table-column prop="黄" label="黄" min-width="42px" align="center">
                    </el-table-column>
                    <el-table-column prop="蓝" label="蓝" min-width="42px" align="center">
                    </el-table-column>
                    <el-table-column prop="扣分" label="扣分" min-width="52px" align="center">
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="完成率一" name="third" lazy>
                <el-row :gutter="10" style="margin-top: 0px">
                    <el-col :span="10">
                        <el-date-picker
                            v-model="checkReportForm.fstart"
                            style="width: 100%;"
                            size="small"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="10">
                        <el-date-picker
                            v-model="checkReportForm.fend"
                            style="width: 100%;"
                            size="small"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="4">
                        <el-button 
                            size="small" 
                            type="danger" 
                            icon="el-icon-refresh" 
                            style="width: 100%;"
                            @click="GetPerson1Rate"></el-button>
                    </el-col>
                </el-row>
                <el-table 
                    :data="Rate1TableData" 
                    size="mini" 
                    max-height="580px"
                    border
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, height: '32px'}" 
                    :row-style="{align: 'center'}"
                    :cell-style="() => { return 'padding: 0px;margin: 0px;height: 30px;font-size: 11px'}"
                    v-loading="table_loading3" 
                    style="margin-top: 25px">
                    <el-table-column label="序" type="index" min-width="30px" align="center">
                    </el-table-column>
                    <el-table-column prop="姓名" label="姓名" min-width="56px" align="center">
                    </el-table-column>
                    <el-table-column prop="待处理" label="待处理" min-width="46px" align="center">
                    </el-table-column>
                    <el-table-column prop="跟进中" label="跟进中" min-width="46px" align="center">
                    </el-table-column>
                    <el-table-column prop="已完成" label="已完成" min-width="46px" align="center">
                    </el-table-column>
                    <el-table-column prop="总计" label="总计" min-width="42px" align="center">
                    </el-table-column>
                    <el-table-column prop="完成率" label="完成率" min-width="50px"  align="center">
                        <template slot-scope="scope">
                            <div :class="scope.row.success === 'Y' ? 'success-cell' : scope.row.error === 'Y' ? 'error-cell' : ''">{{scope.row.完成率}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="扣分二" name="second" lazy>
                <el-row :gutter="10" style="margin-top: 0px">
                    <el-col :span="10">
                        <el-date-picker
                            v-model="checkReportForm.fstart"
                            style="width: 100%;"
                            size="small"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="10">
                        <el-date-picker
                            v-model="checkReportForm.fend"
                            style="width: 100%;"
                            size="small"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="4">
                        <el-button 
                            size="small" 
                            type="danger" 
                            icon="el-icon-refresh" 
                            style="width: 100%;"
                            @click="GetPerson2Points"></el-button>
                    </el-col>
                </el-row>
                <el-table 
                    type="index"
                    show-summary
                    stripe
                    max-height="580px"
                    :data="Person2TableData" 
                    size="mini" 
                    border
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, height: '32px'}" 
                    :row-style="{align: 'center'}"
                    :cell-style="() => { return 'padding: 0px;margin: 0px;height: 30px;font-size: 11px'}"
                    v-loading="table_loading2" 
                    style="margin-top: 25px">
                    <el-table-column label="序" type="index" min-width="30px" align="center">
                    </el-table-column>
                    <el-table-column prop="姓名" label="姓名" min-width="64px" align="center">
                    </el-table-column>
                    <el-table-column prop="红" label="红" min-width="42px" align="center">
                    </el-table-column>
                    <el-table-column prop="橙" label="橙" min-width="42px" align="center">
                    </el-table-column>
                    <el-table-column prop="黄" label="黄" min-width="42px" align="center">
                    </el-table-column>
                    <el-table-column prop="蓝" label="蓝" min-width="42px" align="center">
                    </el-table-column>
                    <el-table-column prop="扣分" label="扣分" min-width="52px" align="center">
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="完成率二" name="forth" lazy>
                <el-row :gutter="10" style="margin-top: 0px">
                    <el-col :span="10">
                        <el-date-picker
                            v-model="checkReportForm.fstart"
                            style="width: 100%;"
                            size="small"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="10">
                        <el-date-picker
                            v-model="checkReportForm.fend"
                            style="width: 100%;"
                            size="small"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="4">
                        <el-button 
                            size="small" 
                            type="danger" 
                            icon="el-icon-refresh" 
                            style="width: 100%;"
                            @click="GetPerson2Rate"></el-button>
                    </el-col>
                </el-row>
                <el-table 
                    max-height="580px"
                    :data="Rate2TableData" 
                    size="mini" 
                    border
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, height: '32px'}" 
                    :row-style="{align: 'center'}"
                    :cell-style="() => { return 'padding: 0px;margin: 0px;height: 30px;font-size: 11px'}"
                    v-loading="table_loading4" 
                    style="margin-top: 25px">
                    <el-table-column label="序" type="index" min-width="30px" align="center">
                    </el-table-column>
                    <el-table-column prop="姓名" label="姓名" min-width="56px" align="center">
                    </el-table-column>
                    <el-table-column prop="待处理" label="待处理" min-width="46px" align="center">
                    </el-table-column>
                    <el-table-column prop="跟进中" label="跟进中" min-width="46px" align="center">
                    </el-table-column>
                    <el-table-column prop="已完成" label="已完成" min-width="46px" align="center">
                    </el-table-column>
                    <el-table-column prop="总计" label="总计" min-width="42px" align="center">
                    </el-table-column>
                    <el-table-column prop="完成率" label="完成率" min-width="50px" align="center">
                        <template slot-scope="scope">
                            <div :class="scope.row.success === 'Y' ? 'success-cell' : scope.row.error === 'Y' ? 'error-cell' : ''">{{scope.row.完成率}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import moment from 'moment'
export default {
    data() {
        return {
            activeName: 'first',
            checkReportForm: {
                fstart: '',
                fend: ''
            },
            Person1TableData: [],
            Person2TableData: [],
            Rate1TableData: [],
            Rate2TableData: [],
            table_loading1: false,
            table_loading2: false,
            table_loading3: false,
            table_loading4: false,
        }
    },
    methods: {
        handleClick(tab) {
            this.tab = tab
            if(tab.index === '0' || !tab.index) {
                this.GetPerson1Points()
            }else if(tab.index === '1'){
                this.GetPerson2Points()
            }else if(tab.index === '2'){
                this.GetPerson1Rate()
            }else if(tab.index === '3'){
                this.GetPerson2Rate()
            }
        },
        async GetPerson1Points() {
            this.table_loading1 = true
            this.Person1TableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetPerson1Points', qs.stringify({
                fstart: this.checkReportForm.fstart,
                fend: this.checkReportForm.fend
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.Person1TableData = res.data.ResponseData
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.table_loading1 = false
        },
        async GetPerson2Points() {
            this.table_loading2 = true
            this.Person2TableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetPerson2Points', qs.stringify({
                fstart: this.checkReportForm.fstart,
                fend: this.checkReportForm.fend
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.Person2TableData = res.data.ResponseData
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.table_loading2 = false
        },
        async GetPerson1Rate() {
            this.table_loading3 = true
            this.Rate1TableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetPerson1Rate', qs.stringify({
                fstart: this.checkReportForm.fstart,
                fend: this.checkReportForm.fend
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                res.data.ResponseData.forEach(item => {
                    item.success = item.完成率 === 100 ? 'Y' : 'N'
                    item.error = item.完成率 < 50 ? 'Y' : 'N'
                    item.完成率 = item.完成率 + '%'
                })
                this.Rate1TableData = res.data.ResponseData
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.table_loading3 = false
        },
        async GetPerson2Rate() {
            this.table_loading4 = true
            this.Rate2TableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetPerson2Rate', qs.stringify({
                fstart: this.checkReportForm.fstart,
                fend: this.checkReportForm.fend
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                res.data.ResponseData.forEach(item => {
                    item.success = item.完成率 === 100 ? 'Y' : 'N'
                    item.error = item.完成率 < 50 ? 'Y' : 'N'
                    item.完成率 = item.完成率 + '%'
                })
                this.Rate2TableData = res.data.ResponseData
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.table_loading4 = false
        }
    },
    mounted() {
        this.checkReportForm.fstart = moment().startOf('month').format('YYYY-MM-DD')
        this.checkReportForm.fend = moment().endOf('month').format('YYYY-MM-DD')
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>

<style scoped>
    .error-cell {
        color: #F56C6C;
        font-weight: 600;
    }
    .success-cell {
        color: #67C23A;
        font-weight: 600;
    }
</style>