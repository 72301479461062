<template>
    <div>
        <el-row :gutter="10" >
            <el-col :span="10">
                <el-select v-model="yc_nm" size="small" @change="select_change" placeholder="选择异常名称">
                    <el-option
                        v-for="item in yc_nms"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        <span style="float: left; font-size: 12px">{{ item.label }}</span>
                        <span style="float: right; color: #F56C6C; font-size: 11px">{{ item.target }}</span>
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="10">
                <el-date-picker
                    v-model="vday"
                    style="width: 100%;"
                    size="small"
                    type="date"
                    align="center"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
            </el-col>
            <el-col :span="4">
                <el-button 
                    size="small" 
                    type="danger" 
                    icon="el-icon-refresh" 
                    style="width: 100%;"
                    @click="cxSubmitForm"></el-button>
            </el-col>
        </el-row>
        <el-table 
            max-height="600"
            :data="cxYcData" 
            size="mini"
            border 
            :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, margin: 0, height: '32px'}" 
            :cell-style="() => {return 'padding: 0px;margin: 0px;height: 30px;fontSize: 11px'}"
            v-loading="cx_table_loading" 
            :row-class-name="tableRowClassName" 
            style="margin-top: 25px">
            <el-table-column prop="sec_nm2" label="线别" min-width="44px" align="center">
            </el-table-column>
            <el-table-column label="当日" align="center">
                <el-table-column prop="day_qtys_yc" :label="qty_col_nm" min-width="46px" align="center">
                </el-table-column>
                <el-table-column prop="day_rate" :label="rete_col_nm" min-width="54px" align="center">
                    <template slot-scope="scope">
                        <div :class="yc_rate_then ? scope.row.day_rate > yc_rate  ? ' error-cell' : 
                        '' : scope.row.day_rate < yc_rate  ? ' error-cell' : '' ">
                            {{scope.row.day_rate > 0 ? scope.row.day_rate + '%' : ''}}</div>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column label="本周" align="center">
                <el-table-column prop="week_qtys_yc" :label="qty_col_nm" min-width="46px" align="center">
                </el-table-column>
                <el-table-column prop="week_rate" :label="rete_col_nm" min-width="54px" align="center">
                    <template slot-scope="scope">
                        <div :class="yc_rate_then ? scope.row.week_rate > yc_rate  ? ' error-cell' : 
                        '' : scope.row.week_rate < yc_rate  ? ' error-cell' : '' ">
                            {{scope.row.week_rate > 0 ? scope.row.week_rate + '%' : ''}}</div>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column label="本月" align="center">
                <el-table-column prop="month_qtys_yc" :label="qty_col_nm" min-width="52px" align="center">
                </el-table-column>
                <el-table-column prop="month_rate" :label="rete_col_nm" align="center">
                    <template slot-scope="scope">
                        <div :class="yc_rate_then ? scope.row.month_rate > yc_rate  ? ' error-cell' : 
                        '' : scope.row.month_rate < yc_rate  ? ' error-cell' : '' ">
                            {{scope.row.month_rate > 0 ? scope.row.month_rate + '%' : ''}}</div>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import moment from 'moment'
export default {
    data() {
        return {
            yc_no: '',
            yc_rate: 15,
            yc_rate_then: true,
            proc_nm: '',
            qty_col_nm: '',
            rete_col_nm: '',
            yc_nms: [
                {label: '开胶', value: 'kj', target: '10%'},
                {label: '胶线', value: 'jx', target: '5%'},
                {label: '清洁度', value: 'qjd', target: '5%'},
                {label: '起皱', value: 'qz', target: '5%'},
                {label: '一次合格率', value: 'hgl', target: '88%'},
                {label: '漏检率', value: 'ljl', target: '5%'}
            ],
            yc_nm: '',
            vday: new moment().format("YYYY-MM-DD"),
            cxYcData: [],
            cx_table_loading: false
        }
    },
    methods: {
        select_change(e) {
            switch(e) {
                case 'kj': 
                    this.yc_no = ''
                    this.yc_rate = 10
                    this.yc_rate_then = true
                    this.proc_nm = 'mes_yc_kj'
                    this.qty_col_nm = '开胶数'
                    this.rete_col_nm = '开胶率'
                    break
                case 'jx':
                    this.yc_no = '724'
                    this.yc_rate = 5
                    this.yc_rate_then = true
                    this.proc_nm = 'mes_yc_model'
                    this.qty_col_nm = '胶线'
                    this.rete_col_nm = '不良率'
                    break
                case 'qjd':
                    this.yc_no = '709'
                    this.yc_rate = 5
                    this.yc_rate_then = true
                    this.proc_nm = 'mes_yc_model'
                    this.qty_col_nm = '清洁度'
                    this.rete_col_nm = '不良率'
                    break
                case 'qz':
                    this.yc_no = '710'
                    this.yc_rate = 5
                    this.yc_rate_then = true
                    this.proc_nm = 'mes_yc_model'
                    this.qty_col_nm = '起皱'
                    this.rete_col_nm = '不良率'
                    break
                case 'hgl':
                    // this.yc_no = '708,709,710,711,712,713,714,715,716,723,724'
                    this.yc_no = ''
                    this.yc_rate = 88
                    this.yc_rate_then = false
                    this.proc_nm = 'mes_yc_hgl'
                    this.qty_col_nm = '不良数'
                    this.rete_col_nm = '合格率'
                    break
                case 'ljl':
                    this.yc_no = '717,718,719,720,721,725,726,727,728,730,733,734,735,736,737,738,739,740,748'
                    this.yc_rate = 5
                    this.yc_rate_then = true
                    this.proc_nm = 'mes_yc_model2'
                    this.qty_col_nm = '漏检数'
                    this.rete_col_nm = '漏检率'
                    break
                default:
                    this.yc_no = ''
                    this.yc_rate = 0
                    this.proc_nm = ''
                    this.qty_col_nm = ''
                    this.rete_col_nm = ''
                    break
            }
        },
        cxSubmitForm() {
            if(this.CustomValidate() && this.CustomValidate2()){
                this.GetCxYcData()
            }
        },
        CustomValidate() {
            if(!this.yc_nm) {
                this.$message({
                    message: '请选择异常名称',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
                return false
            }
            return true
        },
        CustomValidate2() {
            if(!this.vday) {
                this.$message({
                    message: '请选择异常日期',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
                return false
            }
            return true
        },
        async GetCxYcData() {
            this.cx_table_loading = true
            this.cxYcData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/MesYcRate/CxYcData', qs.stringify({
                proc_nm: this.proc_nm,
                vday: this.vday,
                yc_no: this.yc_no
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                var groupData = this.GroupBy(res.data.ResponseData, ['sec_nm1'])
                console.log('groupData', groupData)
                groupData.map(group => {
                    console.log('group', group)
                    var group_day_qtys_input = 0
                    var group_day_qtys_yc = 0
                    var group_week_qtys_input = 0
                    var group_week_qtys_yc = 0
                    var group_month_qtys_input = 0
                    var group_month_qtys_yc = 0
                    group.data.map(item => {
                        console.log('item', item)
                        this.cxYcData.push(item)
                        group_day_qtys_input += item.day_qtys_input
                        group_day_qtys_yc += item.day_qtys_yc
                        group_week_qtys_input += item.week_qtys_input
                        group_week_qtys_yc += item.week_qtys_yc
                        group_month_qtys_input += item.month_qtys_input
                        group_month_qtys_yc += item.month_qtys_yc
                    })
                    console.log('group.key.sec_nm1', group.key.sec_nm1)
                    this.cxYcData.push({
                        sec_nm2: group.key.sec_nm1.substring(2, group.key.sec_nm1.length),
                        day_qtys_input: group_day_qtys_input,
                        day_qtys_yc: group_day_qtys_yc,
                        day_rate: (group_day_qtys_yc/group_day_qtys_input*100).toFixed(1),
                        month_qtys_input: group_month_qtys_input,
                        month_qtys_yc: group_month_qtys_yc,
                        month_rate: (group_month_qtys_yc/group_month_qtys_input*100).toFixed(1),
                        sec_nm: '',
                        sec_nm1: '',
                        sec_no: '',
                        sec_no1: '',
                        week_qtys_input: group_week_qtys_input,
                        week_qtys_yc: group_week_qtys_yc,
                        week_rate: (group_week_qtys_yc/group_week_qtys_input*100).toFixed(1),
                    })
                })
            }

            this.cx_table_loading = false
        },
        GroupBy(datas, keys, callBack) {
            const list = datas || []
            const groups = []
            list.forEach(v => {
                const key = {}
                // const data = {}
                keys.forEach(k => {
                    key[k] = v[k]
                })
                let group = groups.find(v => {
                    return v._key === JSON.stringify(key)
                })
                if(!group) {
                    group = {
                        _key: JSON.stringify(key),
                        key: key
                    }
                    groups.push(group)
                }
                if(callBack) {
                    group.data = callBack(group.data, v)
                    group.total = group.total || 0
                    group.total++
                }else {
                    group.data = group.data || []
                    group.data.push(v)
                }
            })
            return groups
        },
        tableRowClassName({row}) {
            if(row.sec_nm2.indexOf('楼') > -1 || row.sec_nm2.indexOf('线') > -1) {
                return 'error-row'
            }
         
            return ''
        },
    },
    mounted() {
    },
    created() {
    }
}
</script>

<style>
    body {
        margin: 0;
    }
    .total_data{
        font-size: 14px;
        font-weight: bold;
        color: #F56C6C;
    }
    .total_data_bm{
        font-size: 13px;
        font-weight: 600;
        color: #E6A23C;
    }
    .el-table .error-row {
        font-weight: 500;
        color: white;
        background: #66B1FF;
    }
    .error-cell {
        color: #F56C6C;
        font-weight: 700;
    }
</style>