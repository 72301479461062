<template>
    <div>
        <el-page-header @back="goBack" icon="el-icon-arrow-left" title="持续改善列表" class="page_header">
        </el-page-header>
    </div>
</template>

<script>
export default {
    methods: {
        goBack() {
            window.location.href = '/proposalList'
        }
    }
}
</script>

<style scoped>
    .page_header {
            margin-bottom: 30px;
    }
    .el-page-header__content {
        font-size: 13px;
    }
</style>