<template>
    <div>
        <template>
            <!-- <h3>2022年个人汇报</h3>
            <el-carousel height="400px" 
                arrow="always"
                indicator-position="outside"
                :interval="4000"
                direction="horizontal" 
                style="font-size: 14px"
                :autoplay="true">
                <el-carousel-item 
                    v-for="(item, index) in AnnualReporData" :key="index">
                    <h4 style="padding-top: 20px;margin-bottom: 30px;font-size: 16px">{{ item.ytype }}</h4>
                    <div style="text-align: left;text-indent: 2em;margin-bottom: 30px;padding-left: 10px;padding-right: 10px">{{item.yreson}}</div>
                    <div style="margin-bottom: 30px"
                        v-if="item.objecttype === '员工奖惩'">奖金：￥{{item.yreward}}</div>
                    <div style="margin-bottom: 30px"
                        v-if="item.objecttype === '员工价值评鉴'">加分：{{item.yreward}}</div>
                    <div style="margin-bottom: 30px" 
                        v-if="item.objecttype === '活动参与' && item.yreward > 0">名次：{{item.yreward}}</div>
                    <div>日期：{{item.ydate}}</div>
                </el-carousel-item>
            </el-carousel> -->
            <h4 style="color: #409EFF">这是专属于你2022年在寰球的一点回忆</h4>
            <br>
            <el-carousel height="280px" 
                arrow="always"
                indicator-position="outside"
                :interval="5000"
                direction="horizontal" 
                style="font-size: 14px;"
                :autoplay="true">
                <el-carousel-item class="carousel-item">
                    <br>
                    <br>
                    <div>寰球小伙伴： <span>{{pname}}</span>，你好！</div>
                    <div>这是我们在一起的第 <span>{{gl_year}}</span> 年</div>
                    <div>是你入职寰球的第 <span>{{gl_day}}</span> 天</div>
                    <div>那些我们一起经历的时光</div>
                    <div>想在这里一点一点告诉你</div>
                </el-carousel-item>
                <el-carousel-item class="carousel-item">
                    <br>
                    <br>
                    <div>今年你正常出勤 <span>{{w_day}}</span> 天</div>
                    <div>漏打卡 <span>{{yc_num}}</span> 次</div>
                    <div>迟到 <span>{{f_num}}</span> 次</div>
                    <div>年休假 <span>{{h_day}}</span> 天</div>
                    <div>日子虽然匆忙</div>
                    <div>和你一起努力的日子</div>
                    <div>才是我们最美的时光</div>
                </el-carousel-item>
                <el-carousel-item class="carousel-item">
                    <br>
                    <br>
                    <div>这一年</div>
                    <div>你有 <span>{{xzcf}}</span> 次行政处罚</div>
                    <div><span>{{xzjl}}</span> 次行政奖励</div>
                    <div>努力的意义</div>
                    <div>不是让你取得多大的成就</div>
                    <div>而是在平凡的日子里</div>
                    <div>活得比原来的那个自己更好一点！</div>
                </el-carousel-item>
                <el-carousel-item class="carousel-item">
                    <br>
                    <br>
                    <div>这一年</div>
                    <div>你的员工价值评鉴</div>
                    <div>排名最靠前的是第 <span>{{season}}</span> 季度的 <span>{{row_num}}</span> 名</div>
                    <div>赶超了 <span>{{total_num}}</span> 名专业序列 <span>{{gw_sn}}</span> 的寰球小伙伴</div>
                    <div>每一个闪闪发光的日子</div>
                    <div>都值得被记录</div>
                </el-carousel-item>
                <el-carousel-item class="carousel-item">
                    <br>
                    <br>
                    <div v-if="reward">2022年度</div>
                    <div v-if="reward">恭喜你获得“ <span>{{reward}}</span> ”年度评优奖项</div>
                    <div>过去的一年</div>
                    <div>一路走来</div>
                    <div>你的一次次努力</div>
                    <div>一步步成长</div>
                    <div>寰球与你一起见证</div>
                </el-carousel-item> 
            </el-carousel>
        </template>
    </div>
</template>

<script>
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
// import moment from 'moment'
export default {
    data() {
        return {
            // isLoading: false,
            pnl: '',
            AnnualReporData: {},
            reward: '',
            season: '',
            row_num: 0,
            total_num: 0,
            gw_sn: '',
            xzjl: 0,
            xzcf: 0,
            w_day: 0,
            yc_num: 0,
            f_num: 0,
            h_day: 0,
            pname: '',
            gl_year: 0,
            gl_day: 0
        }
    },
    methods: {
        async GetAnnualReportData() {
            this.AnnualReporData = {}
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EhrAnnualReport/AnnualReportData', qs.stringify({
                pnl: this.pnl,
                start_date: '2021-01-01',
                end_date: '2022-01-01'
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData) {
                    // res.data.ResponseData.forEach(item => {
                    //     item.ydate = moment(item.ydate).format('YYYY-MM-DD')
                    //     this.AnnualReporData.push(item)
                    // })
                    this.AnnualReporData = res.data.ResponseData
                    if(this.AnnualReporData.Table5.length > 0) {
                        this.reward = this.AnnualReporData.Table5[0].reward
                    }
                   
                    if(this.AnnualReporData.Table4.length > 0) {
                        this.season = this.AnnualReporData.Table4[0].SEASON
                        this.row_num = this.AnnualReporData.Table4[0].row_num
                        this.total_num = this.AnnualReporData.Table4[0].total_num
                        if(this.AnnualReporData.Table4[0].GW === 'P') {
                            if(this.AnnualReporData.Table4[0].SN < 7) {
                                this.gw_sn = 'P3-P6'
                            }else {
                                this.gw_sn = 'P7-P8'
                            }
                        }else {
                            if(this.AnnualReporData.Table4[0].SN < 4) {
                                this.gw_sn = 'M1-M3'
                            }else {
                                this.gw_sn = 'M4-M9'
                            }
                        }
                    }

                    this.xzjl = this.AnnualReporData.Table3[0].行政奖励
                    this.xzcf = this.AnnualReporData.Table3[0].行政处罚
                    this.w_day = this.AnnualReporData.Table2[0].w_day
                    this.yc_num = this.AnnualReporData.Table2[0].yc_num
                    this.f_num = this.AnnualReporData.Table2[0].f_num
                    this.h_day = this.AnnualReporData.Table2[0].h_day
                    this.pname = this.AnnualReporData.Table1[0].pname
                    this.gl_year = this.AnnualReporData.Table1[0].gl_year
                    this.gl_day = this.AnnualReporData.Table1[0].gl_day
                }
            }
        },
    },
    mounted() {
        this.GetAnnualReportData()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl 
            // this.pnl = '18139' // dev
        }
    }
}
</script>

<style scoped>
  /* .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  } */
  .el-carousel__item:nth-child(2n) {
    /* background-color: #99a9bf; */
    background-color: #EBEEF5;
  }
  .el-carousel__item:nth-child(2n+1) {
    /* background-color: #d3dce6; */
    background-color: #F2F6FC;
  }
  .el-carousel__item div {
    margin-bottom: 13px;
    font-size: 13px;
    color: #409EFF;
    font-weight: 600;
  }
  .el-carousel__item div span{
    font-size: 16px;
    color: #F56C6C;
    font-weight: 700;
  }
</style>