<template>
    <div>
        <el-table :data="tableAssNoData" size="small" :show-header="false">
            <el-table-column prop="label" label="设备详情" width="90px">
            </el-table-column>
            <el-table-column prop="value">
            </el-table-column>
            <el-table-column align="right" width="90px">
                <el-button 
                    size="small" 
                    type="primary" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI">扫码</el-button>
            </el-table-column>
        </el-table>
        <el-table :data="tableData" size="small" :show-header="false">
            <el-table-column prop="label" width="90px">
            </el-table-column>
            <el-table-column prop="value">
            </el-table-column>
        </el-table>
        <el-table v-if="tablesData.length > 0" :data="tablesData" size="small" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="42" prop="ISOK">
            </el-table-column>
            <el-table-column prop="FFIXITEM_NO" width="82px" label="维修项目">
            </el-table-column>
            <el-table-column prop="FFIXITEM_NAME">
            </el-table-column>
        </el-table>
        <el-row v-if="isOther" style="font-size: x-small; color: #606266; margin-top: 10px;margin-bottom: 20px" :gutter="10">
            <el-col :span="6">问题描述</el-col>
            <el-col :span="18">
                <el-input
                    size="small"
                    type="textarea"
                    :rows="3"
                    placeholder="请详细描述问题"
                    v-model="RepairOrder.FDESCRIPTION">
                </el-input>
            </el-col>
        </el-row>
        <el-row v-if="tablesData.length > 0" style="margin-top: 10px;margin-bottom: 20px">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    :loading="isLoading"
                    @click="SaveRepairOrder">快速报修</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            tableAssNoData: [{
                label: '设备编号',
                value: ''
            }],
            tableData: [],
            tablesData: [],
            tableCheckData: [],
            isLoading: false,
            multipleSelection: [],
            isOther: false,
            RepairOrder: {
                FASS_NO: '',
                FASS_NM: '',
                FSEC_NO1: '',
                FSEC_NM1: '',
                FASS_GG: '',
                FSTATUS: 0,
                // FFIX_PNL: '',
                // FFIX_PNAME: '',
                FDESCRIPTION: ''
            }
        }
    },
    methods: {
        handleSelectionChange(val) {
            // console.log('val', val)
            this.multipleSelection = val
            for(let i = 0; i < this.multipleSelection.length; i++){
                if(this.multipleSelection[i].FFIXITEM_NAME === '其他' || this.multipleSelection[i].FFIXITEM_NAME === '其它') {
                    this.isOther = true
                    return
                }
            }

            this.isOther = false
        },
        GetJSAPI() {
            // 测试
            // this.tableAssNoData[0].value = '00150140NA' // dev 0015
            // this.tableAssNoData[0].value = '05210034NA' // dev 0521
             this.tableAssNoData[0].value = '02180001NA'  // dev  0018
             this.GetAssetInfo(this.tableAssNoData[0].value) // dev
            // 
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
                    this.tableAssNoData[0].value = result
                    this.GetAssetInfo(this.tableAssNoData[0].value)
                }
            })
        },
        async GetAssetInfo(ASS_NO) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsAssets/AssetByNo2', qs.stringify({
                ASS_NO
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.tableData = []
                res.data.ResponseData.forEach(item => {
                    this.tableData.push({
                        label: '设备名称',
                        value: item.ASS_NM
                    })
                    this.tableData.push({
                        label: '所在部门',
                        value: item.SEC_NM
                    })
                    this.tableData.push({
                        label: '设备规格',
                        value: item.ASS_GG
                    })
                    this.tableData.push({
                        label: '存放地点',
                        value: item.ADDR
                    })

                    this.GetFixList(item.TYPE_NO)
                    this.RepairOrder.FASS_NO = ASS_NO
                    this.RepairOrder.FASS_NM = item.ASS_NM
                    this.RepairOrder.FASS_GG = item.ASS_GG
                    this.RepairOrder.FSEC_NO1 = item.DEP_ID
                    this.RepairOrder.FSEC_NM1 = item.SEC_NM
                    // this.RepairOrder.FFIX_PNL = item.FPNL
                    // this.RepairOrder.FFIX_PNAME = item.FPNAME
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetFixList(TYPE_NO) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsAsstypefixitemss/FixList', qs.stringify({
                TYPE_NO
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.tablesData = []
                res.data.ResponseData.forEach(item => {
                    this.tablesData.push({
                        FFIXITEM_NO: item.fixItemNo,
                        FFIXITEM_NAME: item.fixItemName,
                        ISOK: false
                    })
                })    
            }
        },
        async SaveRepairOrder() {
            if(this.multipleSelection.length === 0) {
                this.$message({
                    message: '请选择维修项目！',
                    center: true,
                    type: 'error'
                })
                return
            }
            if(this.isOther) {
                if(!this.RepairOrder.FDESCRIPTION) {
                    this.$message({
                        message: '选择其他时，问题描述不能为空！',
                        center: true,
                        type: 'error'
                    })
                    return
                }
            }
            
            const dto = {
                FSUBMIT_PNL: this.pnl,
                FSUBMIT_PNAME: this.pname,
                FASS_NO: this.RepairOrder.FASS_NO,
                FASS_NM: this.RepairOrder.FASS_NM,
                FSEC_NO1: this.RepairOrder.FSEC_NO1,
                FSEC_NM1: this.RepairOrder.FSEC_NM1,
                FASS_GG: this.RepairOrder.FASS_GG,
                // FFIX_PNL: this.RepairOrder.FFIX_PNL,
                // FFIX_PNAME: this.RepairOrder.FFIX_PNAME,
                FSTATUS: 0,
                C_TYPE: 1,
                C_MAN: this.pnl + '-' + this.pname,
                FDESCRIPTION: this.RepairOrder.FDESCRIPTION
            }
            for (let index = 0; index < this.tablesData.length; index++) {
                if(this.multipleSelection.indexOf(this.tablesData[index])> -1) {
                    this.tablesData[index].ISOK = true
                }else{
                    this.tablesData[index].ISOK = false
                }
            }

            const dtos = this.tablesData
            console.log('dtos', dtos)
            this.isLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrRepairOrders/SaveRepairOrder', qs.stringify({
                dto, dtos
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$message({
                    message: '提交成功！',
                    center: true,
                    type: 'success'
                })
                setTimeout(()=>{
                    window.location.href = '/RepairOrderList'
                },1000)
            }else{
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        const id = this.$route.params.id
        if(id) {
            this.tableAssNoData[0].value = id
            this.GetAssetInfo(id)
        }

        this.GetSignature()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            // this.pnl = '63849' //dev 
        }
        const pname = localStorage.getItem('PNAME')
        if(pname) {
            this.pname = pname
        }
    }
}
</script>