<template>
    <div>
        <el-page-header @back="goBack" icon="el-icon-arrow-left" title="我的运动" class="page_header">
        </el-page-header>
    </div>
</template>

<script>
export default {
    methods: {
        goBack() {
            window.location.href = '/sportsList'
        }
    }
}
</script>

<style scoped>
    .page_header {
            margin-bottom: 30px;
    }
    .el-page-header__content {
        font-size: 13px;
    }
</style>