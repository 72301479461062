<template>
    <div>
        <el-form :model="noticeForm" :rules="rules" ref="noticeForm" size="small">
            <el-form-item label="公告标题" prop="FTITLE">
                <el-input type="textarea" :rows="2" v-model="noticeForm.FTITLE" placeholder="请输入公告标题（如：关于**的公告）"></el-input>
            </el-form-item>
            <el-form-item label="公告副标题">
                <!-- <el-input v-model="noticeForm.FSUBTITLE"></el-input> -->
                <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入公告副标题"
                    v-model="noticeForm.FSUBTITLE">
                </el-input>
            </el-form-item>
            <el-form-item label="公告称谓">
                <el-input v-model="noticeForm.FCALL"></el-input>
            </el-form-item>
            <el-form-item label="公告内容" prop="FCONTENT">
                <!-- <el-input v-model="noticeForm.FCONTENT"></el-input> -->
                <el-input
                    type="textarea"
                    :rows="8"
                    placeholder="请输入公告内容"
                    v-model="noticeForm.FCONTENT">
                </el-input>
            </el-form-item>
            <el-form-item label="公告署名"  prop="FSIGN">
                <el-input type="textarea" :rows="2" placeholder="请输入公告署名" v-model="noticeForm.FSIGN"></el-input>
            </el-form-item>
            <el-form-item label="公告日期"  prop="FDATE">
                <el-input v-model="noticeForm.FDATE"></el-input>
            </el-form-item>
            <el-form-item label="是否发布"  prop="FISSHOW">
                  <el-switch
                    v-model="noticeForm.FISSHOW"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="是"
                    inactive-text="否">
                </el-switch>
            </el-form-item>
            <el-row :gutter="20">
                <el-col :span="12" style="margin-top: 20px;margin-bottom: 20px">
                    <el-button 
                        size="small"
                        style="width: 100%;"
                        type="info" 
                        @click="noticeDialogVisible = true">预览</el-button>
                </el-col>
                <el-col :span="12" style="margin-top: 20px;margin-bottom: 20px">
                    <el-button 
                        size="small"
                        style="width: 100%;"
                        type="primary" 
                        :loading="isLoading"
                        @click="submitForm('noticeForm')">提交</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-dialog
            title="关于疫情防控的通知"
            :visible.sync="noticeDialogVisible"
            top="3vh"
            size="mini" 
            width="90%"
            :show-close="false"
            center>
            <template slot="title">
                <span style="font-size: 14px; font-weight: bold" v-html="noticeForm.FTITLE"></span>
            </template>
            <template>
                <div style="font-size: 12px; text-align: right; margin-bottom: 5px"  v-html="noticeForm.FSUBTITLE"></div>
                <div v-if="noticeForm.FCALL" style="font-size: 12px; font-weight: bold; margin-bottom: 5px">{{noticeForm.FCALL}}：</div>
                <div style="font-size: 11.5px; text-indent: 2em; line-height: 16px" v-html="noticeForm.FCONTENT"></div>
                <div style="font-size: 12px; font-weight: bold; text-align: right; margin-top: 20px" v-html="noticeForm.FSIGN"></div>
                <div style="font-size: 12px; font-weight: bold; text-align: right;margin-top: 5px">{{noticeForm.FDATE}}</div>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button @click="noticeDialogVisible = false" size="mini">取 消</el-button>
                <el-button type="primary" @click="noticeDialogVisible = false" size="mini">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
export default {
    data() {
        return {
            noticeForm: {
                FTITLE: '关于疫情防控的通知',
                FSUBTITLE: '',
                FCALL: '各位同仁',
                FCONTENT: '',
                FSIGN: '防疫管控小组',
                FDATE: moment().format('YYYY年M月DD日'),
                DB_ID: '',
                DB_CREATE_USERNO: '',
                FISSHOW: true
            },
            isLoading: false,
            noticeDialogVisible: false,
            rules: {
                FTITLE: [
                    {required: true, message: '请输入公告标题', trigger: 'blur'}
                ],
                FCONTENT: [
                    {required: true, message: '请输入公告内容', trigger: 'blur'}
                ],
                FSIGN: [
                    {required: true, message: '请输入公告署名', trigger: 'blur'}
                ],
                FDATE: [
                    {required: true, message: '请输入公告日期', trigger: 'blur'}
                ],
                FISSHOW: [
                    {required: true, message: '是否发布', trigger: 'change'}
                ]
            }
        }
    },
    methods: {
        TextAreaTo(str) {
            var reg = new RegExp("\n", "g")
            var regSpace = new RegExp(" ", "g")
            str = str.replace(reg, "<br>")
            str = str.replace(regSpace, "&nbsp;")
            return str 
        },
        ToTextArea(str) {
            var reg = new RegExp("<br>", "g")
            var regSpace = new RegExp("&nbsp;", "g")
            str = str.replace(reg, "\n")
            str = str.replace(regSpace, " ")
            // console.log('str', str)
            return str
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.noticeForm)
                    this.SaveNotice()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveNotice() {
            this.isLoading = true
            const dto = {
                FTITLE: this.TextAreaTo(this.noticeForm.FTITLE),
                FSUBTITLE: this.TextAreaTo(this.noticeForm.FSUBTITLE),
                FCALL: this.noticeForm.FCALL,
                FCONTENT: this.TextAreaTo(this.noticeForm.FCONTENT),
                FSIGN: this.TextAreaTo(this.noticeForm.FSIGN),
                FDATE: moment().format('YYYY年M月DD日'),
                DB_ID: this.noticeForm.DB_ID,
                DB_CREATE_USERNO: this.noticeForm.DB_CREATE_USERNO,
                FISSHOW: this.noticeForm.FISSHOW
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TNOTICEs/SaveNotice', qs.stringify({
                dto
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功') {
                this.$message({
                    message: '提交成功',
                    duration: 3000,
                    center: true,
                    type: 'success'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                this.$message({
                    message: '提交失败：' + res.data.Message,
                    duration: 3000,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async GetLastNotice() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TNOTICEs/GetLastNotice')
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    let noticeData = res.data.ResponseData[0]
                    this.noticeForm.FTITLE = noticeData.FTITLE
                    this.noticeForm.FSUBTITLE = noticeData.FSUBTITLE
                    this.noticeForm.FCALL = noticeData.FCALL
                    this.noticeForm.FCONTENT = noticeData.FCONTENT
                    this.noticeForm.FSIGN = noticeData.FSIGN
                    this.noticeForm.FDATE = noticeData.FDATE
                    this.noticeForm.DB_ID = noticeData.DB_ID
                    this.noticeForm.FISSHOW = noticeData.FISSHOW
                }
            }
        },
    },
    mounted() {
        this.GetLastNotice()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.noticeForm.DB_CREATE_USERNO = pnl
        }
    }
}
</script>

<style scoped>
    .el-form-item {
        font-weight: bold;
    }
</style>