<template>
    <div>
        <el-table 
            :data="DinnerTypeData" 
            size="medium" 
            stripe
            border
            :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
            :row-style="{align: 'center'}"
            v-loading="table_loading" >
            <el-table-column prop="FSORT" label="排序" min-width="46px" align="center">
            </el-table-column>
            <el-table-column prop="FTYPE_NO" label="编号" min-width="46px" align="center">
            </el-table-column>
            <el-table-column prop="FTYPE_NM" label="分类名称" align="center">
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="100px" align="center">
               <template slot-scope="scope">
                    <el-link style="font-size: 12px;color: #67C23A" @click="ShowEdit(scope.row)"><i class="el-icon-edit"></i> 编辑</el-link> |
                    <!-- <el-popconfirm :title="'确认删除：' + scope.row.FTYPE_NM + '？'"  :confirm="ShowDel(scope.row.DB_ID)"> -->
                        <el-link slot="reference" style="font-size: 12px;color: #F56C6C" @click="ShowDel(scope.row)"><i class="el-icon-delete"></i> 删除</el-link>
                    <!-- </el-popconfirm> -->
                </template>
            </el-table-column>
        </el-table>
        <el-row style="margin-top: 20px;margin-bottom: 20px">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    @click="ShowAdd">新增菜品分类</el-button>
            </el-col>
        </el-row>
        <el-dialog title="分类信息" :visible.sync="dinnertypeDialogForm" width="90%">
            <el-form :model="dinnerTypeForm" :rules="rules" ref="dinnerTypeForm" label-width="90px" size="medium">
                <el-form-item label="分类编号" prop="ftype_no" style="font-weight: bold">
                    <el-input v-model="dinnerTypeForm.ftype_no" placeholder="请输入分类编号" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="分类名称" prop="ftype_nm" style="font-weight: bold">
                    <el-input v-model="dinnerTypeForm.ftype_nm" placeholder="请输入分类名称" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="分类排序" prop="fsort" style="font-weight: bold">
                    <el-input v-model="dinnerTypeForm.fsort" placeholder="请输入分类排序" size="medium"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <!-- <el-button size="small" @click="doneDialogFormVisible = false">取消</el-button> -->
                <el-button size="medium" type="success" :loading="isLoading" @click="SubmitForm('dinnerTypeForm')">提交</el-button>
            </div>
        </el-dialog>
        <el-dialog style="color: #F56C6C" title="删除确认？" :visible.sync="delDialogVisible" size="mini" width="80%">
            <span class="dialog-footer">
                删除分类名称：【{{dinnerTypeForm.ftype_nm}}】，是否继续?
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="delDialogVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="DelDinnerType(dinnerTypeForm.db_id)">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
export default {
    data() {
        return {
            DinnerTypeData: [],
            table_loading: false,
            isLoading: false,
            dinnertypeDialogForm: false,
            dinnerTypeForm: {
                ftype_no: '',
                ftype_nm: '',
                fsort: 0,
                db_create_userno: '',
                db_id: ''
            },
            delDialogVisible: false,
            rules: {
                ftype_no: [
                    {required: true, message: '请输入分类编号', trigger: 'blur'}
                ],
                ftype_nm: [
                    {required: true, message: '请输入分类名称', trigger: 'blur'}
                ],
                fsort: [
                    {required: true, message: '请输入分类排序', trigger: 'blur'}
                ],
            }
        }
    },
    methods: {
        ShowDel(item) {
            // this.DelDinnerType(DB_ID)
            this.dinnerTypeForm.ftype_nm = item.FTYPE_NM
            this.dinnerTypeForm.db_id = item.DB_ID
            this.delDialogVisible = true
        },
        ShowEdit(item) {
            console.log('item', item)
            this.dinnerTypeForm.ftype_no = item.FTYPE_NO
            this.dinnerTypeForm.ftype_nm = item.FTYPE_NM
            this.dinnerTypeForm.fsort = item.FSORT
            this.dinnerTypeForm.db_id = item.DB_ID
            this.dinnertypeDialogForm = true
        },
        ShowAdd() {
            this.dinnerTypeForm = {}
            this.dinnertypeDialogForm = true
        },
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.waterForm)
                    this.SaveDinnerType()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveDinnerType() {
            this.isLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrderTypes/SaveDinnerType', qs.stringify({
                dto: this.dinnerTypeForm
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                console.log('提交成功:', res.data.ResponseData)
                this.$message({
                    message: '提交成功',
                    center: true,
                    type: 'success'
                })
                this.GetDinnerType()
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '提交失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '提交失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
            this.dinnertypeDialogForm = false
        },
        async GetDinnerType() {
            this.table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrderTypes/GetDinnerType')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.DinnerTypeData = res.data.ResponseData
            }else {
                this.$message({
                    message: '获取数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.table_loading = false
        },
        async DelDinnerType(DB_ID) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrderTypes/DelDinnerType', qs.stringify({
                DB_ID
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                console.log('提交成功:', res.data.ResponseData)
                this.$message({
                    message: '提交成功',
                    center: true,
                    type: 'success'
                })
                this.GetDinnerType()
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '提交失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '提交失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.delDialogVisible = false
        },
    },
    mounted() {
        this.GetDinnerType()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.dinnerTypeForm.db_create_userno = pnl
        }
    }
}
</script>