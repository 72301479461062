import Vue from 'vue'
import VueRouter from 'vue-router'
import SportsClock from '../views/sport/SportsClock.vue'
import SportsList from '../views/sport/SportsList.vue'
import PersonSMS from '../views/person/PersonSMS.vue'
import PersonNewInfo from '../views/person/PersonNewInfo.vue'
import PersonSuccess from '../views/person/PersonSuccess.vue'
import Attendance from '../views/attendance/Attendance.vue'
import AttendanceDetail from '../views/attendance/AttendanceDetail.vue'
import Reward from '../views/reward/Reward.vue'
import Guarantee from '../views/welfare/Guarantee.vue'
import Salary from '../views/salary/Salary.vue'
import SportsStatistics from '../views/sport/SportsStatistics.vue'
import WorkOrderList from '../views/work/WorkOrderList.vue'
import WorkOrder from '../views/work/WorkOrder.vue'
// import Login from '../views/account/Login.vue'
import Register from '../views/account/Register.vue'
// import ChangePassword from '../views/account/ChangePassword.vue'
import DetailInfo from '../views/account/DetailInfo.vue'
import Question from '../views/question/Question.vue'
import QuestionList from '../views/question/QuestionList.vue'
import Answer from '../views/question/Answer.vue'
import Questionnaire from '../views/human_resources/questionnaire/Questionnaire.vue'
// import ForgetPassword from '../views/account/ForgetPassword.vue'
import QuitApply from '../views/quit/QuitApply.vue'
import QuitConfirm from '../views/quit/QuitConfirm.vue'
import Healthy from '../views/healthy/Healthy.vue'
import HealthyAdd from '../views/healthy/HealthyAdd.vue'
import HealthyList from '../views/healthy/HealthyList.vue'
import HealthyAdmin from '../views/healthy/HealthyAdmin.vue'
import HealthyAddAdmin from '../views/healthy/HealthyAddAdmin.vue'
import HealthyPlus from '../views/healthy/HealthyPlus.vue'
import HealthyPlusAdmin from '../views/healthy/HealthyPlusAdmin.vue'
import FaceAuth from '../views/uniubi/FaceAuth.vue'
import BlackList from '../views/information/BlackList.vue'
// import PieceCapitalStatus from '../views/piece/PieceCapitalStatus.vue'
// import InstructionDetail from '../views/production/InstructionDetail.vue'
import WorkInProcess from '../views/production/WorkInProcess.vue'
import SafetyPatrol from '../views/safety/SafetyPatrol.vue'
import EHS from '../views/safety/EHS.vue'
import EHSList from '../views/safety/EHSList.vue'
import PreDispatch from '../views/parts/PreDispatch.vue'
import PreDispatch2 from '../views/parts/PreDispatch2.vue'
import ProcessMatching from '../views/production/ProcessMatching'
import ProcessInProcess from '../views/production/ProcessInProcess'
import conf from '../conf'
import axios from 'axios'
import qs from 'qs'
// import Store from '../store'
import { Message } from 'element-ui'
import Equipment from '../views/computer/Equipment'
import Scenery from '../views/scenery/Scenery'
import HealthyNoticeAdmin from '../views/healthy/HealthyNoticeAdmin'
import Repair from '../views/device/Repair'
import RepairOrder from '../views/device/RepairOrder.vue'
import RepairOrderList from '../views/device/RepairOrderList.vue'
import CheckList from '../views/mes/CheckList.vue'
import ProcessCheck from '../views/mes/ProcessCheck.vue'
import CheckDetail from '../views/mes/CheckDetail'
import MeasureRecord from '../views/mes/MeasureRecord.vue'
import MeasureRecord2 from '../views/mes/MeasureRecord2.vue'
import CheckCreate from '../views/mes/CheckCreate.vue'
import CheckDetail2 from '../views/mes/CheckDetail2.vue'
import FirstConfirm from '../views/mes/FirstConfirm.vue'
import FirstConfirmCreate from '../views/mes/FirstConfirmCreate.vue'
import FirstConfirmDetail from '../views/mes/FirstConfirmDetail.vue'
import WeekMenu from '../views/administration/diningRoom/WeekMenu.vue'
import WeekMenuList from '../views/administration/diningRoom/WeekMenuList.vue'
import WeekMenuImage from '../views/administration/diningRoom/WeekMenuImage.vue'
import AnnualReport from '../views/human_resources/AnnualReport.vue'
import DetailInfoChange from '../views/account/DetailInfoChange.vue'
import ProcessCheck2 from '../views/mes/ProcessCheck2.vue'
import RoomCheckCreate from '../views/administration/dorm/RoomCheckCreate.vue'
import RoomCheckDetail from '../views/administration/dorm/RoomCheckDetail.vue'
import RoomCheckList from '../views/administration/dorm/RoomCheckList.vue'
import WaterElectricity from '../views/administration/dorm/WaterElectricity.vue'
import EhsCheckCreate from '../views/administration/ehs/EhsCheckCreate.vue'
import EhsCheckList from '../views/administration/ehs/EhsCheckList.vue'
import EhsCheckDetail from '../views/administration/ehs/EhsCheckDetail.vue'
import EhsCheckPersonReport from '../views/administration/ehs/EhsCheckPersonReport.vue'
import ProductInventory from '../views/warehouse/ProductInventory.vue'
import EhsCheckCreate2 from '../views/administration/ehs/EhsCheckCreate2.vue'
import ProductInventory2 from '../views/warehouse/ProductInventory2.vue'


// 首页
import Home from '../views/Home.vue'
import Home2 from '../views/Home2.vue'
import Home3 from '../views/Home3.vue'
import HomeMd from '../views/HomeMd.vue'
import HomeIndex from '../views/HomeIndex.vue'
import HomeApps from '../views/HomeApps.vue'
import HomeInfo from '../views/HomeInfo.vue'

// MES
import SecYcRecord from '../views/mes/SecYcRecord.vue'

// 件资 首页
// import PartHome from '../views/piece/PartHome.vue'
import PartHome2 from '../views/piece/PartHome2.vue'
// 件资 查询
import PieceCapital from '../views/piece/PieceCapital.vue'  // 员工件资
import PieceCapitalStatus2 from '../views/piece/PieceCapitalStatus2.vue' // 件资状况
import PieceCapitalLeave from '../views/piece/PieceCapitalLeave.vue' // 离职件资
// 件资 员工电子账单
import PartProcess from '../views/piece/PartProcess.vue' // 工艺车间
import SurfaceProcess from '../views/piece/SurfaceProcess.vue' // 帮面件资
import FormingProcess from '../views/piece/FormingProcess.vue' // 成型件资
import BlankingProcess from '../views/piece/BlankingProcess.vue' // 冲裁件资
import ShoelacesProcess from '../views/piece/ShoelacesProcess.vue'  // 鞋带组
import PackagingProcess from '../views/piece/PackagingProcess.vue'  // 大包装
// 件资 审核
import SurfaceProcessAudit from '../views/piece/SurfaceProcessAudit.vue' // 帮面审核
import FormingProcessAudit from '../views/piece/FormingProcessAudit.vue' // 成型审核
import ShoelacesProcessAudit from '../views/piece/ShoelacesProcessAudit.vue' // 鞋带组审核
import PackagingProcessAudit from '../views/piece/PackagingProcessAudit.vue' // 大包装审核

// 生产 
// import InputOutput2 from '../views/production/InputOutput2.vue' // 投入产出
import InputOutput4 from '../views/production/InputOutput4.vue' // 线别预警
import InputOutput6 from '../views/production/InputOutput6.vue' // 投入产出

// 行政中心
import DeliverWater from '../views/administration/water/DeliverWater.vue'
import DeliverWaterList from '../views/administration/water/DeliverWaterList.vue'

// 餐厅点餐
import DinnerAdmin from '../views/administration/diningRoom/DinnerAdmin.vue'
import DinnerAdminMenu from '../views/administration/diningRoom/DinnerAdminMenu.vue'
import DinnerAdminType from '../views/administration/diningRoom/DinnerAdminType.vue'
import DinnerOrder from '../views/administration/diningRoom/DinnerOrder.vue'
import DinnerOrderList from '../views/administration/diningRoom/DinnerOrderList.vue'
import DinnerAdmin2 from '../views/administration/diningRoom/DinnerAdmin2.vue'

// 人力资源
// 活动报名
// import ActivitySignUp from '../views/human_resources/activity/ActivitySignUp.vue'
// import ActivityList from '../views/human_resources/activity/ActivityList.vue'
// 远程打卡
import RemoteClock from '../views/human_resources/clock/RemoteClock.vue'
// 入职
import EntryNotice from '../views/human_resources/entry/EntryNotice.vue'
// 会议管理
import MeetingList from '../views/human_resources/meeting/MeetingList.vue' // 会议列表
import MeetingQrCode from '../views/human_resources/meeting/MeetingQrCode.vue' // 会议二维码
import MeetingSign from '../views/human_resources/meeting/MeetingSign.vue' // 会议签到
// 福利品告知书
import StopSale from '../views/human_resources/notice/StopSale.vue'
// 限期返岗通知书
import LimitedWork from '../views/human_resources/notice/LimitedWork.vue'
import AndonEndCall from '../views/other/AndonEndCall.vue' // 安东信息
// 离职原因调查表
import QuitQuestionnaire from '../views/human_resources/questionnaire/QuitQuestionnaire.vue'
// 持续改善
import Proposal from '../views/human_resources/proposal/Proposal.vue'  // 新建议
import ProposalList from '../views/human_resources/proposal/ProposalList.vue' // 建议列表
// 提醒
import Birthday from '../views/human_resources/remind/Birthday.vue' // 生日提醒
import Anniversary from '../views/human_resources/remind/Anniversary.vue' // 周年提醒

// 研发技术中心
// 开发样品
import Sample from '../views/research_development/sample/Sample.vue' // 样鞋借用
import SampleManage from '../views/research_development/sample/SampleManage.vue' // 样鞋管理
import SampleCompletion from '../views/research_development/sample/SampleCompletion.vue' // 样品完工
import SampleCompletion2 from '../views/research_development/sample/SampleCompletion2.vue' // 样品完工
import SampleDelivery from '../views/research_development/sample/SampleDelivery.vue' // 样品交样

// 成本中心
// 异常反馈
import FeedBack from '../views/cost_center/feedback/FeedBack.vue' // 异常反馈
import FeedBackList from '../views/cost_center/feedback/FeedBackList.vue'  // 反馈列表
import Handing from '../views/cost_center/feedback/Handing.vue'  // 异常处理
// 设备保养
import Maintain from '../views/cost_center/maintain/Maintain.vue'
import MaintainDetail from '../views/cost_center/maintain/MaintainDetail.vue'
import FirstMaintain from '../views/cost_center/maintain/FirstMaintain.vue'
import FixMaintain from '../views/cost_center/maintain/FixMaintain.vue'
import MaintainList from '../views/cost_center/maintain/MaintainList.vue'

// 设备报修

Vue.use(VueRouter)

const routes = [
{
	path: '/',
	name: 'Home',
	component: Home,
	meta: {
	title: '个人中心'
	}
},
{
	path: '/home2',
	name: 'Home2',
	component: Home2,
	meta: {
	title: '寰球移动办公平台',
	}
},
{
	path: '/sportsclock',
	name: 'SportsClock',
	component: SportsClock,
	meta: {
	title: '运动打卡'
	}
},
{
	path: '/sportsList',
	name: 'SportsList',
	component: SportsList,
	meta: {
	title: '我的运动'
	}
},
{
	path: '/personSMS',
	name: 'PersonSMS',
	component: PersonSMS,
	meta: {
	title: '手机验证'
	}
},
{
	path: '/personNewInfo',
	name: 'PersonNewInfo',
	component: PersonNewInfo,
	meta: {
	title: '新员工资料录入'
	}
},
{
	path: '/personSuccess',
	name: 'PersonSuccess',
	component: PersonSuccess,
	meta: {
	title: '提交成功'
	}
},
{
	path: '/attendance',
	name: 'Attendance',
	component: Attendance,
	meta: {
	title: '我的考勤'
	}
},
{
	path: '/attendanceDetail/:start',
	name: 'AttendanceDetail',
	component: AttendanceDetail,
	props: true,
	meta: {
	title: '考勤明细'
	}
},
{
	path: '/reward',
	name: 'Reward',
	component: Reward,
	meta: {
	title: '奖惩明细'
	}
},
{
	path: '/guarantee',
	name: 'Guarantee',
	component: Guarantee,
	meta: {
	title: '我的保障'
	}
},
{
	path: '/salary',
	name: 'Salary',
	component: Salary,
	meta: {
	title: '我的薪资'
	}
},
{
	path: '/sample',
	name: 'Sample',
	component: Sample,
	meta: {
	title: '样鞋借用'
	}
},
{
	path: '/sportsStatistics',
	name: 'SportsStatistics',
	component: SportsStatistics,
	meta: {
	title: '打卡统计'
	}
},
{
	path: '/workOrderList',
	name: 'WorkOrderList',
	component: WorkOrderList,
	meta: {
	title: '工务维修列表'
	}
},
{
	path: '/workOrder',
	name: 'WorkOrder',
	component: WorkOrder,
	meta: {
	title: '工务维修'
	}
},
{
	path: '/proposal',
	name: 'Proposal',
	component: Proposal,
	meta: {
	title: '新建议'
	}
},
{
	path: '/proposalList',
	name: 'ProposalList',
	component: ProposalList,
	meta: {
	title: '持续改善'
	}
},
// {
//   path: '/login',
//   name: 'Login',
//   component: Login,
//   meta: {
//     title: '登录'
//   }
// },
{
	path: '/register',
	name: 'Register',
	component: Register,
	meta: {
	title: '注册'
	}
},
// {
//   path: '/changePassword',
//   name: 'ChangePassword',
//   component: ChangePassword,
//   meta: {
//     title: '修改密码'
//   }
// },
{
	path: '/detailInfo',
	name: 'DetailInfo',
	component: DetailInfo,
	meta: {
	title: '人事档案'
	}
},
{
	path: '/questionList',
	name: 'QuestionList',
	component: QuestionList,
	meta: {
	title: '问答列表'
	}
},
{
	path: '/question',
	name: 'Question',
	component: Question,
	meta: {
	title: '我要提问'
	}
},
{
	path: '/answer/:id',
	name: 'Answer',
	component: Answer,
	props: true,
	meta: {
	title: '问答明细'
	}
},
{
	path: '/maintain',
	name: 'Maintain',
	component: Maintain,
	meta: {
	title: '设备保养'
	}
},
{
	path: '/maintain/:id',
	name: 'Maintain',
	component: Maintain,
	props: true,
	meta: {
	title: '设备保养'
	}
},
{
	path: '/maintainDetail/:id',
	name: 'MaintainDetail',
	component: MaintainDetail,
	props: true,
	meta: {
	title: '设备保养记录'
	}
},
{
	path: '/firstMaintain/:id/:n',
	name: 'FirstMaintain',
	component: FirstMaintain,
	props: true,
	meta: {
	title: '设备一级保养作业'
	}
},
{
	path: '/questionnaire/:id',
	name: 'Questionnaire',
	component: Questionnaire,
	props: true,
	meta: {
	title: '寰球鞋服调查问卷'
	}
},
// {
//   path: '/jump',
//   name: 'Jump',
//   component: Jump,
//   // props: true,
//   meta: {
//     title: '跳转中'
//   }
// },
// {
//   path: '/forgetPassword',
//   name: 'ForgetPassword',
//   component: ForgetPassword,
//   meta: {
//     title: '忘记密码'
//   }
// },
{
	path: '/quitApply',
	name: 'QuitApply',
	component: QuitApply,
	meta: {
	title: '离职申请'
	}
},
{
	path: '/quitConfirm',
	name: 'QuitConfirm',
	component: QuitConfirm,
	meta: {
	title: '离职确认'
	}
},
{
	path: '/healthy',
	name: 'Healthy',
	component: Healthy,
	meta: {
	title: '健康管理'
	}
},
{
	path: '/healthyAdd',
	name: 'HealthyAdd',
	component: HealthyAdd,
	meta: {
	title: '每日健康提报'
	}
},
{
	path: '/healthyList',
	name: 'HealthyList',
	component: HealthyList,
	meta: {
	title: '健康管理列表'
	}
},
{
	path: '/healthyAdmin',
	name: 'HealthyAdmin',
	component: HealthyAdmin,
	meta: {
	title: '健康提报管理页面'
	}
},
{
	path: '/healthyAddAdmin',
	name: 'HealthyAddAdmin',
	component: HealthyAddAdmin,
	meta: {
	title: '每日健康提报管理页面'
	}
},
{
	path: '/healthyPlus',
	name: 'HealthyPlus',
	component: HealthyPlus,
	meta: {
	title: '2022春节返厂信息提报'
	}
},
{
	path: '/healthyPlusAdmin',
	name: 'HealthyPlusAdmin',
	component: HealthyPlusAdmin,
	meta: {
	title: '2022春节返厂管理页面'
	}
},
{
	path: '/healthyNoticeAdmin',
	name: 'HealthyNoticeAdmin',
	component: HealthyNoticeAdmin,
	meta: {
	title: '健康打卡公告管理'
	}
},
// {
//   path: '/faceAuth/:id',
//   name: 'FaceAuth',
//   component: FaceAuth,
//   props: true,
//   meta: {
//     title: '人脸识别'
//   }
// },
{
	path: '/faceAuth',
	name: 'FaceAuth',
	component: FaceAuth,
	meta: {
	title: '离职申请'
	}
},
{
	path: '/blackList',
	name: 'BlackList',
	component: BlackList,
	meta: {
	title: '黑名单验证',
	id: '904E017F-5498-42CA-8164-75DA15980648'
	}
},
// {
//   path: '/pieceCapitalStatus',
//   name: 'PieceCapitalStatus',
//   component: PieceCapitalStatus,
//   meta: {
//     title: '员工件资状况',
//     id: '96690C61-C8BE-4483-AE10-19B11BA8F752'
//   }
// },

// {
//   path: '/instructionDetail',
//   name: 'InstructionDetail',
//   component: InstructionDetail,
//   meta: {
//     title: '指令详情'
//   }
// },
{
	path: '/workInProcess',
	name: 'WorkInProcess',
	component: WorkInProcess,
	meta: {
	title: '在制明细',
	id: 'C83D1689-3D6E-4714-82AD-104579C71C50'
	}
},
{
	path: '/maintainList',
	name: 'MaintainList',
	component: MaintainList,
	meta: {
	title: '设备保养计划'
	}
},
{
	path: '/sampleCompletion',
	name: 'SampleCompletion',
	component: SampleCompletion,
	meta: {
	title: '样品完工',
	id: '6cd032ee-29d4-4c48-9a23-f6a47ac1f73e'
	}
},
{
	path: '/sampleCompletion2',
	name: 'SampleCompletion2',
	component: SampleCompletion2,
	meta: {
	title: '样品完工',
	}
},
{
	path: '/sampleDelivery',
	name: 'SampleDelivery',
	component: SampleDelivery,
	meta: {
	title: '样品交样',
	id: '5c05c0df-0624-49de-990e-ed7a1a24592e'
	}
},
{
	path: '/safetyPatrol',
	name: 'SafetyPatrol',
	component: SafetyPatrol,
	meta: {
	title: '安全巡查',
	}
},
{
	path: '/EHS',
	name: 'EHS',
	component: EHS,
	meta: {
	title: 'EHS问题管理平台',
	}
},
{
	path: '/EHSList',
	name: 'EHSList',
	component: EHSList,
	meta: {
	title: 'EHS问题管理平台',
	}
},
{
	path: '/preDispatch',
	name: 'PreDispatch',
	component: PreDispatch,
	meta: {
	title: '大冲预派工',
	}
},
{
	path: '/preDispatch2',
	name: 'PreDispatch2',
	component: PreDispatch2,
	meta: {
	title: '部件接收',
	}
},
{
	path: '/processMatching',
	name: 'ProcessMatching',
	component: ProcessMatching,
	meta: {
	title: '工艺配套',
	id: '113849AC-883F-4476-81D2-9A670C7C2A4C'
	}
},
{
	path: '/processInProcess',
	name: 'ProcessInProcess',
	component: ProcessInProcess,
	meta: {
	title: '工艺在制',
	id: 'E42A7E82-7366-4614-82D2-BCE60089EDF9'
	}
},
{
	path: '/equipment',
	name: 'Equipment',
	component: Equipment,
	meta: {
	title: '设备扫码',
	}
},
{
	path: '/scenery',
	name: 'Scenery',
	component: Scenery,
	meta: {
	title: '景观保养',
	}
},
{
	path: '/repair',
	name: 'Repair',
	component: Repair,
	meta: {
	title: '设备维修',
	}
},
{
	path: '/fixMaintain/:id',
	name: 'FixMaintain',
	component: FixMaintain,
	props: true,
	meta: {
	title: '设备维修'
	}
},
{
	path: '/repairOrder/:id',
	name: 'RepairOrder',
	component: RepairOrder,
	props: true,
	meta: {
	title: '设备报修单',
	}
},
{
	path: '/repairOrder',
	name: 'RepairOrder',
	component: RepairOrder,
	meta: {
	title: '设备报修单',
	}
},
{
	path: '/repairOrderList',
	name: 'RepairOrderList',
	component: RepairOrderList,
	meta: {
	title: '设备报修列表',
	}
},
{
	path: '/checkList',
	name: 'CheckList',
	component: CheckList,
	props: true,
	meta: {
	title: '关键工序稽查表列表',
	}
},
{
	path: '/processCheck/:id/:sec/:odr',
	name: 'ProcessCheck',
	component: ProcessCheck,
	meta: {
	title: '关键工序稽查表',
	}
},
{
	path: '/checkDetail/:no',
	name: 'CheckDetail',
	component: CheckDetail,
	props: true,
	meta: {
	title: '关键工序稽查表',
	}
},
{
	path: '/measureRecord/:id/:sec/:odr',
	name: 'MeasureRecord',
	component: MeasureRecord,
	meta: {
	title: '温度时间测量记录表',
	}
},
{
	path: '/measureRecord2/:id/:sec/:odr',
	name: 'MeasureRecord2',
	component: MeasureRecord2,
	meta: {
	title: '温度时间测量记录表2',
	}
},
{
	path: '/checkCreate',
	name: 'CheckCreate',
	component: CheckCreate,
	meta: {
	title: '表格创建',
	// keepAlive: true
	}
},
{
	path: '/checkDetail2/:no',
	name: 'CheckDetail2',
	component: CheckDetail2,
	meta: {
	title: '温度时间测量记录表',
	}
},
{
	path: '/firstConfirm',
	name: 'FirstConfirm',
	component: FirstConfirm,
	meta: {
	title: '首件接收列表',
	id: 'F5F421E4-8370-4AC1-A47D-7496B7CD059B'
	}
},
{
	path: '/firstConfirmCreate',
	name: 'FirstConfirmCreate',
	component: FirstConfirmCreate,
	meta: {
	title: '首件接收',
	}
},
{
	path: '/firstConfirmDetail/:id',
	name: 'FirstConfirmDetail',
	component: FirstConfirmDetail,
	meta: {
	title: '首件接收明细',
	}
},
{
	path: '/weekMenu',
	name: 'WeekMenu',
	component: WeekMenu,
	meta: {
	title: '餐厅周菜谱上传',
	}
},
{
	path: '/weekMenuList',
	name: 'WeekMenuList',
	component: WeekMenuList,
	meta: {
	id: '86A7671C-A834-4532-99B1-C4709C497895',
	title: '餐厅周菜谱列表',
	}
},
{
	path: '/weekMenuImage',
	name: 'WeekMenuImage',
	component: WeekMenuImage,
	meta: {
	title: '餐厅菜谱',
	}
},
// {
//   path: '/activitySignUp/:id',
//   name: 'ActivitySignUp',
//   component: ActivitySignUp,
//   meta: {
//     title: '公司活动报名',
//   }
// },
// {
//   path: '/activityList',
//   name: 'ActivityList',
//   component: ActivityList,
//   meta: {
//     title: '公司活动列表',
//   }
// },
{
	path: '/annualReport',
	name: 'AnnualReport',
	component: AnnualReport,
	meta: {
	title: '个人年度汇报',
	}
},
{
	path: '/DetailInfoChange',
	name: 'DetailInfoChange',
	component: DetailInfoChange,
	meta: {
	title: '个人档案修改申请',
	}
},

{
	path: '/processCheck2/:id/:sec/:odr',
	name: 'ProcessCheck2',
	component: ProcessCheck2,
	meta: {
	id: '5E4E45EB-BD73-4063-880D-A9BBCC42E3C2',
	title: '关键工序稽查表2',
	}
},
{
	path: '/roomCheckCreate',
	name: 'RoomCheckCreate',
	component: RoomCheckCreate,
	meta: {
	id: '57A618B2-B9FA-46A1-9920-340CA6CF5D14',
	title: '宿舍7S日常检查登记',
	}
},
{
	path: '/roomCheckCreate/:id',
	name: 'RoomCheckCreate',
	component: RoomCheckCreate,
	props: true,
	meta: {
	id: '57A618B2-B9FA-46A1-9920-340CA6CF5D14',
	title: '宿舍7S日常检查登记',
	}
},
{
	path: '/roomCheckCreate/:id/:b_no',
	name: 'RoomCheckCreate',
	component: RoomCheckCreate,
	props: true,
	meta: {
	id: '57A618B2-B9FA-46A1-9920-340CA6CF5D14',
	title: '宿舍7S日常检查登记',
	}
},
{
	path: '/roomCheckCreate/:id/:b_no/:f_no',
	name: 'RoomCheckCreate',
	component: RoomCheckCreate,
	props: true,
	meta: {
	id: '57A618B2-B9FA-46A1-9920-340CA6CF5D14',
	title: '宿舍7S日常检查登记',
	}
},
{
	path: '/roomCheckDetail',
	name: 'RoomCheckDetail',
	component: RoomCheckDetail,
	meta: {
	title: '宿舍7S检查结果',
	}
},
{
	path: '/roomCheckList',
	name: 'RoomCheckList',
	component: RoomCheckList,
	meta: {
	id: '57A618B2-B9FA-46A1-9920-340CA6CF5D14',
	title: '宿舍7S日常检查',
	}
},
{
	path: '/roomCheckList/:b_no',
	name: 'RoomCheckList',
	component: RoomCheckList,
	props: true,
	meta: {
	id: '57A618B2-B9FA-46A1-9920-340CA6CF5D14',
	title: '宿舍7S日常检查',
	}
},
{
	path: '/roomCheckList/:b_no/:f_no',
	name: 'RoomCheckList',
	component: RoomCheckList,
	props: true,
	meta: {
	id: '57A618B2-B9FA-46A1-9920-340CA6CF5D14',
	title: '宿舍7S日常检查',
	}
},
{
	path: '/waterElectricity',
	name: 'WaterElectricity',
	component: WaterElectricity,
	meta: {
	title: '我的水电',
	}
},
{
	path: '/ehsCheckCreate',
	name: 'EhsCheckCreate',
	component: EhsCheckCreate,
	meta: {
	id: 'A490A189-2A3E-496B-ABB3-89CD0242AA3D',
	title: 'EHS稽查录入',
	}
},
{
	path: '/ehsCheckList',
	name: 'EhsCheckList',
	component: EhsCheckList,
	meta: {
	id: '781C8491-3297-42D2-A2E0-4B4A40E2FBFB',
	title: 'EHS隐患提报平台',
	}
},
{
	path: '/ehsCheckDetail/:id',
	name: 'EhsCheckDetail',
	component: EhsCheckDetail,
	meta: {
	title: 'EHS稽查结果',
	}
},
{
	path: '/ehsCheckPersonReport',
	name: 'EhsCheckPersonReport',
	component: EhsCheckPersonReport,
	meta: {
	title: 'EHS评分情况',
	}
},
{
	path: '/remoteClock',
	name: 'RemoteClock',
	component: RemoteClock,
	meta: {
	id: '6B192B52-57B8-4925-BB2C-36E6E6513B40',
	title: '远程打卡',
	}
},
{
	path: '/remoteClock/:type',
	name: 'RemoteClock',
	component: RemoteClock,
	meta: {
	title: '签到打卡',
	}
},
{
	path: '/entryNotice',
	name: 'EntryNotice',
	component: EntryNotice,
	meta: {
	title: '寰球新员工入职指引',
	}
},
{
	path: '/meetingList',
	name: 'MeetingList',
	component: MeetingList,
	meta: {
	title: '我的会议',
	}
},
{
	path: '/meetingQrCode/:id',
	name: 'MeetingQrCode',
	component: MeetingQrCode,
	meta: {
	title: '签到二维码',
	}
},
{
	path: '/meetingSign/:id/:ts',
	name: 'MeetingSign',
	component: MeetingSign,
	meta: {
	title: '会议签到',
	}
},
{
	path: '/stopSale',
	name: 'StopSale',
	component: StopSale,
	meta: {
	title: '福利品告知书',
	}
},
{
	path: '/productInventory',
	name: 'ProductInventory',
	component: ProductInventory,
	meta: {
	title: '成品库存动态表',
	}
},
{
	path: '/ehsCheckCreate2',
	name: 'EhsCheckCreate2',
	component: EhsCheckCreate2,
	meta: {
	title: 'EHS隐患提报平台',
	}
},
{
	path: '/ehsCheckCreate2/:id',
	name: 'EhsCheckCreate2',
	component: EhsCheckCreate2,
	meta: {
	title: 'EHS隐患提报平台',
	}
},
{
	path: '/ehsCheckCreate2/:id/:no',
	name: 'EhsCheckCreate2',
	component: EhsCheckCreate2,
	meta: {
	title: 'EHS隐患提报平台',
	}
},
{
	path: '/productInventory2',
	name: 'ProductInventory2',
	component: ProductInventory2,
	meta: {
	title: '成品库存动态表',
	}
},
// {
//   path: '/inputOutput2',
//   name: 'InputOutput2',
//   component: InputOutput2,
//   meta: {
//     title: '线别投入产出',
//     id: 'FB3E380C-5A1A-4DD6-B77B-A602FC42EB5E'
//   }
// },
{
	path: '/inputOutput4',
	name: 'InputOutput4',
	component: InputOutput4,
	meta: {
	title: '线别投入产出预警',
	// id: '0EBFE133-4802-45B3-982F-C5E82DA7EE45'
	}
},
{
	path: '/inputOutput6',
	name: 'InputOutput6',
	component: InputOutput6,
	meta: {
	title: '线别投入产出',
	id: 'FB3E380C-5A1A-4DD6-B77B-A602FC42EB5E'
	}
},
{
	path: '/sampleManage',
	name: 'SampleManage',
	component: SampleManage,
	meta: {
	title: '开发样品鞋管理',
	}
},
// {
// 	path: '/partHome',
// 	name: 'PartHome',
// 	component: PartHome,
// 	meta: {
// 	title: '件资提报',
// 	}
// },
{
	path: '/partHome2',
	name: 'PartHome2',
	component: PartHome2,
	meta: {
	title: '件资提报',
	}
},
{
	path: '/pieceCapital',
	name: 'PieceCapital',
	component: PieceCapital,
	meta: {
	title: '员工件资'
	}
},
{
	path: '/pieceCapitalStatus2',
	name: 'PieceCapitalStatus2',
	component: PieceCapitalStatus2,
	meta: {
	title: '件资状况',
	id: '96690c61-c8be-4483-ae10-19b11ba8f752'
	}
},
{
	path: '/pieceCapitalLeave',
	name: 'PieceCapitalLeave',
	component: PieceCapitalLeave,
	meta: {
	title: '离职件资',
	}
},
{
	path: '/pieceCapitalLeave/:id',
	name: 'PieceCapitalLeave',
	component: PieceCapitalLeave,
	meta: {
	title: '离职件资'
	}
},
{
	path: '/partProcess',
	name: 'PartProcess',
	component: PartProcess,
	meta: {
	title: '工艺车间'
	}
},
{
	path: '/surfaceProcess',
	name: 'SurfaceProcess',
	component: SurfaceProcess,
	meta: {
	title: '帮面件资',
	}
},
{
	path: '/formingProcess',
	name: 'FormingProcess',
	component: FormingProcess,
	meta: {
	title: '成型件资',
	}
},
{
	path: '/blankingProcess',
	name: 'BlankingProcess',
	component: BlankingProcess,
	meta: {
	title: '冲裁件资',
	}
},
{
	path: '/surfaceProcessAudit',
	name: 'SurfaceProcessAudit',
	component: SurfaceProcessAudit,
	meta: {
	title: '帮面审核',
	}
},
{
	path: '/formingProcessAudit',
	name: 'FormingProcessAudit',
	component: FormingProcessAudit,
	meta: {
	title: '成型审核',
	}
},
{
	path: '/andonEndCall/:orgNo/:logId',
	name: 'AndonEndCall',
	component: AndonEndCall,
	meta: {
	title: '针车智能A线安东预警',
	}
},
{
	path: '/LimitedWork/:pnl/:ts',
	name: 'LimitedWork',
	component: LimitedWork,
	meta: {
	title: '限期返岗通知书',
	}
},
{
	path: '/quitQuestionnaire',
	name: 'QuitQuestionnaire',
	component: QuitQuestionnaire,
	meta: {
	title: '离职原因调查表',
	}
},
{
	path: '/secYcRecord',
	name: 'SecYcRecord',
	component: SecYcRecord,
	meta: {
	title: '线别异常记录表',
	id: 'A4A6A794-94B1-4FF0-8662-E42850105C0C'
	}
},
{
	path: '/shoelacesProcess',
	name: 'ShoelacesProcess',
	component: ShoelacesProcess,
	meta: {
	title: '鞋带组',
	}
},
{
	path: '/packagingProcess',
	name: 'PackagingProcess',
	component: PackagingProcess,
	meta: {
	title: '大包装',
	}
},
{
	path: '/shoelacesProcessAudit',
	name: 'ShoelacesProcessAudit',
	component: ShoelacesProcessAudit,
	meta: {
	title: '鞋带组审核',
	}
},
{
	path: '/packagingProcessAudit',
	name: 'PackagingProcessAudit',
	component: PackagingProcessAudit,
	meta: {
	title: '大包装审核',
	}
},
{
	path: '/home3',
	name: 'Home3',
	component: Home3,
	meta: {
	title: '寰球移动办公平台',
	}
},
{
	path: '/homeIndex',
	name: 'HomeIndex',
	component: HomeIndex,
	meta: {
	title: '寰球移动办公平台',
	}
},
{
	path: '/homeApps',
	name: 'HomeApps',
	component: HomeApps,
	meta: {
	title: '应用中心',
	}
},
{
	path: '/homeInfo',
	name: 'HomeInfo',
	component: HomeInfo,
	meta: {
	title: '个人中心',
	}
},
{
	path: '/homeMd',
	name: 'HomeMd',
	component: HomeMd,
	meta: {
	title: '个人中心',
	}
},
{
	path: '/feedBack',
	name: 'FeedBack',
	component: FeedBack,
	meta: {
	title: '异常反馈',
	}
},
{
	path: '/feedBackList',
	name: 'FeedBackList',
	component: FeedBackList,
	meta: {
	title: '异常反馈列表',
	}
},
{
	path: '/handing/:id',
	name: 'Handing',
	component: Handing,
	meta: {
		title: '异常处理',
	}
},
{
	path: '/birthday',
	name: 'Birthday',
	component: Birthday,
	meta: {
		title: '生日快乐',
	}
},
{
	path: '/anniversary/:gl',
	name: 'Anniversary',
	component: Anniversary,
	meta: {
		title: '周年快乐',
	}
},
{
	path: '/deliverWater',
	name: 'DeliverWater',
	component: DeliverWater,
	meta: {
		title: '申购桶装水',
	}
},
{
	path: '/deliverWaterList',
	name: 'DeliverWaterList',
	component: DeliverWaterList,
	meta: {
		title: '送水列表',
	}
},
{
	path: '/dinnerAdmin',
	name: 'DinnerAdmin',
	component: DinnerAdmin,
	meta: {
		title: '订餐管理',
		id: 'FE1879DE-CD0F-47AD-BA1C-E032DC7D333E'
	}
},
{
	path: '/dinnerAdminMenu',
	name: 'DinnerAdminMenu',
	component: DinnerAdminMenu,
	meta: {
		title: '菜品信息',
	}
},
{
	path: '/dinnerAdminType',
	name: 'DinnerAdminType',
	component: DinnerAdminType,
	meta: {
		title: '菜品类别',
	}
},
{
	path: '/dinnerOrder',
	name: 'DinnerOrder',
	component: DinnerOrder,
	meta: {
		title: '餐厅订餐',
	}
},
{
	path: '/dinnerOrderList',
	name: 'DinnerOrderList',
	component: DinnerOrderList,
	meta: {
		title: '订餐列表',
	}
},
{
	path: '/dinnerAdmin2',
	name: 'DinnerAdmin2',
	component: DinnerAdmin2,
	meta: {
		title: '订餐管理',
		// id: 'FE1879DE-CD0F-47AD-BA1C-E032DC7D333E'
	}
},
]

const router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach(async (to, from, next) => { // 菜单权限及时校验
	if(to.meta.id) { // 有设置权限
		const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxMenu/WxMenuAuthProc', qs.stringify({
			fid: to.meta.id,
			pnl: localStorage.getItem('PNL')
		}))
		if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
			if(res.data.ResponseData[0].No === 0) {
				Message.warning({
					message: '您没有【'+ to.meta.title +'】的使用权限！',
					center: true,
				})
			}else {
				if(from.name) {
					const dto = {
						CLICK_FROM: from.name,
						CLICK_TO: to.name,
						CLICK_NM: to.meta.title,
						CLICK_URL: to.fullPath,
						PNL:localStorage.getItem('PNL')
					}
					await axios.post(conf.api_url + '/gateway/weixinapi/WxModulesClick/SetModulesClick', qs.stringify({
						dto
					}))
				}
				next()
			}
		}
	}else {
		if(from.name) {
			const dto = {
				CLICK_FROM: from.name,
				CLICK_TO: to.name,
				CLICK_NM: to.meta.title,
				CLICK_URL: to.fullPath,
				PNL:localStorage.getItem('PNL')
			}
			await axios.post(conf.api_url + '/gateway/weixinapi/WxModulesClick/SetModulesClick', qs.stringify({
				dto
			}))
		}
		next()
	}
})

router.afterEach(async (to, from) => {
	const baseLocation = conf.server_url
	console.log('from', from) // dev
	if (window.__wxjs_is_wkwebview) { // IOS
		if (window.entryUrl == '' || window.entryUrl == undefined) {
			var url = baseLocation + to.fullPath
			window.entryUrl = url
			console.log('IOS', window.entryUrl)
		} else {
			console.log('IOS2', window.entryUrl)
		}
	} else { // 安卓
		window.entryUrl = baseLocation + to.fullPath
	}
})

router.afterEach(() => { 
	document.querySelector("body").setAttribute("style", "overflow: auto !important;") 
})

export default router