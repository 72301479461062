<template>
    <div >
        <h5>{{ title }}</h5>
        <h5 class="name">{{ name }}</h5>
        <div class="content" >
            <div class="content_item" v-for="(content,index) in contents" :key="index">
                <span :style="content_item.style" v-for="(content_item,index_item) in content.content" :key="index_item">
                    {{ content_item.label }}</span>
            </div>
        </div>
        <h5 class="sign">{{ sign }}</h5>
        <el-button round style="width: 100%;margin-top: 30px" type="primary" :disabled="disabled">{{ btn_notice }}（{{ countdown }}）</el-button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            color: {
                color: '#F56C6C'
            },
            title: '关于禁止售卖福利品的告知书',
            name: '全体员工：',
            contents: [{
                content: [{
                    label: '为了杜绝福利品在发放过程中出现售卖的行为，加强对福利品的管理；现将福利品管控相关事宜告知如下：',
                    style: {
                        color: '#606266',
                        marginLeft: '20px'
                    },
                }]
            },{
                content: [{
                    label: '一、发放要求',
                    style: {
                        color: '#606266',
                        fontWeight: 'bold'
                    },
                }]
            },{
                content: [{
                    label: '① 发放现场禁止拍照、拍视频，发朋友圈、抖音等社交媒体平台，一经发现，将永久取消其享受福利品的资格并追究责任。',
                    style: {
                        color: '#606266',
                        marginLeft: '20px'
                    },
                }]
            },{
                content: [{
                    label: '②禁止售卖福利品。禁止对公司发放给员工的福利品进行二次售卖，二次售卖行为属于严重违规行为。',
                    style: {
                        color: '#606266',
                        marginLeft: '20px'
                    },
                }]
            },{
                content: [{
                    label: '二、对有参与二次售卖的人员将依规给予行政处罚并取消福利品发放资格。',
                    style: {
                        color: '#606266',
                        fontWeight: 'bold'
                    },
                },{
                    label: '福利品发放过程中被发现或被举报的参与售卖、倒卖的员工，一经核实，依公司规定与奖惩制度视情节轻重，分别给予大过（私下单双买卖者）或开除（私下多双收购者）等处罚，并永久取消其享受福利品的资格。',
                    style: {
                        color: '#F56C6C'
                    },
                }]
            },{
                content: [{
                    label: '三、设置举报渠道与奖励机制。',
                    style: {
                        color: '#606266',
                        fontWeight: 'bold'
                    },
                },{
                    label: '人力资源部受理对售卖或倒卖福利品行为的举报信息，并对举报人进行保护，同时设置奖励机制。',
                    style: {
                        color: '#F56C6C'
                    },
                }]
            },{
                content: [{
                    label: '特此告知！请全员知悉并遵守！',
                    style: {
                        color: '#606266',
                        marginLeft: '20px'
                    },
                }]
            },{
                content: [{
                    label: '（员工点开此链接，即表示员工本人已阅以上告知内容并承诺遵守以上要求！）',
                    style: {
                        backgroundColor: 'yellow',
                        marginLeft: '20px'
                    },
                }]
            }],
            sign: '人力资源部宣',
            disabled: true,
            btn_notice: '请认真阅读',
            countdown: null // 初始值设置为null或者任意默认值
        }
    },
    mounted() {
        this.startCountdown() // 组件加载完成后开始倒计时
    },
    methods: {
        startCountdown() {
            let seconds = 20 // 定义需要倒计时的秒数
            const timer = setInterval(() => {
                if (seconds > 0) {
                    this.countdown = `${seconds}s` // 更新显示的倒计时文本
                    seconds-- // 每次递减1秒
                } else {
                    clearInterval(timer) // 当倒计时结束时清除定时器
                    this.countdown = '已知悉' // 可选：设置倒计时结束时的文本
                    this.disabled = false
                    // this.btn_notice = '同意，并提交'
                    this.btn_notice = ''
                }
            }, 1000); // 每隔1秒执行一次
        }
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>

<style scoped>
.name {
    text-align: left;
    font-size: 11px;
}
.content {
    text-align: left;
    font-size: 11px;
    line-height: 18px;
}
.content_item{
    margin-top: 10px;
    margin-bottom: 10px;
}
.sign {
    text-align: right;
    font-size: 11px;
}
</style>