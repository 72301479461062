<template>
    <div>
        <el-table 
            :data="tableData" 
            style="width: 100%" 
            size="mini" 
            fit 
            :show-header="false" border 
            :span-method="arraySpanMethod">
            <el-table-column prop="col1">
                <template slot-scope="scope">
                    <div><span class="table-span">{{scope.row.col1.label}}</span>{{scope.row.col1.value}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="col2">
                <template slot-scope="scope">
                    <div><span class="table-span">{{scope.row.col2.label}}</span>{{scope.row.col2.value}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="col3">
                <template slot-scope="scope">
                    <div><span class="table-span">{{scope.row.col3.label}}</span>{{scope.row.col3.value}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="col4">
                <template slot-scope="scope">
                    <div><span class="table-span">{{scope.row.col4.label}}</span>{{scope.row.col4.value}}</div>
                </template>
            </el-table-column>
        </el-table>
        <div><h3>解除劳动关系确认书</h3></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            pname: '',
            sec_nm: '',
            sign_value: null,
            options:{
                isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
                isShowBorder: false, //是否显示边框 [可选]
                canvasHeight: 160,
                canvasWidth: 320,
                bgColor: '#DCDFE6'
            },
            isLoading: false
        }
    },
    methods: {
        initTable() {
            this.tableData.push({
                col1: {
                    label: '泉州寰球鞋服有限公司',
                    value: ''
                },
                col2: {
                    label: '文件编号：',
                    value: 'QR-ZD-72'
                },
                col3: {
                    label: '',
                    value: ''
                },
                col4: {
                    label: '',
                    value: ''
                }
            })
            this.tableData.push({
                col1: {
                    label: '泉州寰球鞋服有限公司',
                    value: ''
                },
                col2: {
                    label: '',
                    value: ''
                },
                col3: {
                    label: '',
                    value: ''
                },
                col4: {
                    label: '启用日期：',
                    value: '2017.06.26'
                }
            })
            this.tableData.push({
                col1: {
                    label: '修订次：',
                    value: 0
                },
                col2: {
                    label: '修订日期：',
                    value: 0
                },
                col3: {
                    label: '保存期限：',
                    value: '2年'
                },
                col4: {
                    label: 'NO：',
                    value: ''
                }
            })
        },
        arraySpanMethod({ rowIndex, columnIndex }) {
            // console.log('row', row)
            // console.log('column', column)
            console.log('rowIndex', rowIndex)
            console.log('columnIndex', columnIndex)

            if (rowIndex === 0 || rowIndex === 1) {
                if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2) {
                    // return [0, 2]
                    return {
                        colspan: 3,
                        rowspan: 2
                    }
                   
                } else {
                    return {
                        colspan: 0,
                        rowspan: 0
                    }
                }
            }
            // if(rowIndex === 0) {
            //     if(columnIndex === 0){
            //         return [2, 3]
            //     }
                
            // }else if(rowIndex === 1) {
            //     return [1, 3]
            // }else {
            //     return [0, 0]
            // }


            // if (columnIndex === 0) {
            //     if (rowIndex === 0) {
            //         return {
            //             rowspan: 1,
            //             colspan: 3
            //         }
            //     } else {
            //         return {
            //             rowspan: 0,
            //             colspan: 0
            //         }
            //     }
            // }
        }
    },
    created() {
        this.initTable()
    }
}
</script>