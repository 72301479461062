<template>
    <div>
        <el-form :model="surfaceForm" ref="surfaceForm" :rules="rules" label-width="70px" size="small">
            <el-form-item label="组别" prop="sec_no" style="font-weight: bold">
                <el-cascader
                    :show-all-levels="false"
                    v-model="surfaceForm.sec_no"
                    clearable
                    @change="SecChange"
                    style="width: 100%"
                    placeholder="请选择组别"
                    :options="options">
                </el-cascader>
            </el-form-item>
            <el-form-item label="日期" prop="iod" style="font-weight: bold">
                <el-date-picker
                    v-model="surfaceForm.iod"
                    @change="GetOdrNoData"
                    :editable = false
                    align="right"
                    type="date"
                    placeholder="选择日期"
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="指令单" prop="odr_no" style="font-weight: bold">
                <el-select v-model="surfaceForm.odr_no" placeholder="请选择指令单" size="small" style="width: 100%;" @change="SetArticQty">
                    <el-option
                        v-for="item in odr_nos"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        <span style="float: left">{{ item.label }}</span>
                        <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span> -->
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="货号" prop="artic_no" style="font-weight: bold">
                <el-input v-model="surfaceForm.artic_no" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="缴库数" prop="qty" style="font-weight: bold">
                <el-input v-model="surfaceForm.qty" :disabled="true"></el-input>
            </el-form-item>
        </el-form>
        <!-- <el-row style="margin-bottom: 10px;">
            <el-col style="text-align: right; color: #F56C6C;font-weight: bold;font-size: 12px;margin-top: 12px" 
                :span="24" >注：双击工序名称，提交个人缴库</el-col>
        </el-row> -->
        <el-skeleton style="width: 100%;text-align: left;margin-bottom: 20px" :rows="10" animated :loading="skeleton_loading">
            <el-table 
                :data="tableData" 
                size="mini" 
                max-height="400px"
                :show-header="false" 
                style="margin-bottom: 20px;font-size: 13px;">
                <el-table-column type="expand" width="15">
                    <template slot-scope="props">
                        <!-- <el-form label-position="left" inline class="demo-table-expand">
                            <el-form-item v-for="item in props.row.children" :key="item.pnl">
                                <span>{{ item.pname }}</span>
                                <span>{{ item.qty }}</span>
                            </el-form-item>
                        </el-form> -->
                        <div>
                            <span v-for="item in props.row.children" :key="item.DB_ID" style="margin-right: 30px">
                                <el-badge :value="item.qty" class="item" v-if="item.pname">
                                    <el-tag size="small" closable @close="ShowDialog2(item, props.row)" :type="item.verify ? 'success' : 'default'"> {{item.pname}} </el-tag>
                                </el-badge>
                            </span>
                            <!-- <span style="margin-right: 5px">
                                <el-badge v-if="scope.row.CHECK_STATE === '待处理'" :value="scope.row.diff_day" class="item" type="warning"></el-badge>
                            </span> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="opera_nm" label="工序名称">
                    <template slot-scope="scope">
                        <div class="table-col">{{scope.row.opera_no}}、{{scope.row.opera_nm}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="fqty" label="操作" width="120" style="padding:0%;">
                    <template slot-scope="scope">
                        <el-input-number 
                            v-model="scope.row.qty" 
                            :min="0" 
                            size="mini"
                            @change="QtyChange(scope.row.opera_nm, scope.row.qty, scope.row.fqty)" 
                            style="width: 100%;color: #F56C6C;font-weight: bold;">
                            <el-button type="info" icon="el-icon-upload2" style="padding-left: 7px;padding-right: 7px;" size="mini"></el-button> 
                    </el-input-number> 
                    </template>
                </el-table-column>
                <el-table-column prop="fqty" label="操作" width="38">
                    <template slot-scope="scope">
                        <el-button type="info" @click="SetQty(scope.row)" icon="el-icon-upload2" style="padding-left: 7px;padding-right: 7px;" size="mini"></el-button> 
                    </template>
                </el-table-column>
            </el-table>
        </el-skeleton>
        <el-row>
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    :loading="isLoading"
                    @click="SaveGjjzIoList">件资提交</el-button>
            </el-col>
        </el-row>
        <el-dialog title="缴库信息" :visible.sync="dialogVisible" width="90%">
            <el-form :model="dynamicSurfaceForm" ref="dynamicSurfaceForm" :rules="rules2" label-width="55px" size="small">
                <!-- <el-form-item label="货号" prop="artic_no" style="font-weight: bold">
                <el-input v-model="dynamicSurfaceForm.artic_no" :disabled="true"></el-input>
            </el-form-item> -->
                <!-- <el-form-item v-for="(person, index) in dynamicSurfaceForm.persons" :key="index" :label="person.label">
                    <el-input v-model="person.value"></el-input>
                </el-form-item> -->
                <el-form-item label="工序" prop="opera_nm" style="font-weight: bold">
                    <el-input v-model="dynamicSurfaceForm.opera_nm" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item v-if="dynamicSurfaceForm.is_sec" label="工号" prop="pnl1" style="font-weight: bold">
                    <el-row>
                        <el-col :span="16"  style="text-align: left;">
                            <el-select v-model="dynamicSurfaceForm.pnl1" filterable placeholder="请选择" clearable style="width: 100%;">
                                <el-option
                                    v-for="item in pnls"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="8" style="text-align: right;">
                            <el-checkbox v-model="dynamicSurfaceForm.is_sec" @change="IsSecChange" style="width: 90%;" label="本组" border></el-checkbox>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item v-if="!dynamicSurfaceForm.is_sec" label="工号" prop="pnl2" style="font-weight: bold">
                    <el-row>
                        <el-col :span="16"  style="text-align: left;">
                            <el-input v-model="dynamicSurfaceForm.pnl2" ></el-input>
                        </el-col>
                        <el-col :span="8" style="text-align: right;">
                            <el-checkbox v-model="dynamicSurfaceForm.is_sec" @change="IsSecChange" style="width: 90%;" label="本组" border></el-checkbox>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="数量" prop="qty" style="font-weight: bold">
                    <el-input-number 
                        v-model="dynamicSurfaceForm.qty" 
                        :min="1" 
                        style="width: 100%;color: #F56C6C;font-weight: bold;">
                    </el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-row :gutter="20" style="margin-bottom: 0px">
                    <el-col :span="12">
                        <el-button style="width: 100%" @click="dialogVisible = false" round>取消</el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-button 
                            style="width: 100%" 
                            :loading="isLoading"
                            type="primary" 
                            @click="SubmitForm('dynamicSurfaceForm')" 
                            round>提交</el-button>
                    </el-col>
                </el-row>
                <!-- <el-button size="mini" @click="dialogVisible = false">取消</el-button>
                <el-button size="mini" type="primary">提交</el-button> -->
            </div>
        </el-dialog>
        <el-dialog title="更新缴库数量" :visible.sync="dialogVisible2" width="90%">
            <el-form :model="dynamicSurfaceForm" ref="dynamicSurfaceForm" :rules="rules2" label-width="55px" size="small">
                <el-form-item label="工序" prop="opera_nm" style="font-weight: bold">
                    <el-input v-model="dynamicSurfaceForm.opera_nm" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item v-if="!dynamicSurfaceForm.is_sec" label="工号" prop="pnl2" style="font-weight: bold">
                    <el-row>
                        <el-col :span="16"  style="text-align: left;">
                            <el-input v-model="dynamicSurfaceForm.pnl2" ></el-input>
                        </el-col>
                        <el-col :span="8" style="text-align: right;">
                            <el-checkbox v-model="dynamicSurfaceForm.is_sec" @change="IsSecChange" style="width: 90%;" label="本组" border></el-checkbox>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="数量" prop="qty" style="font-weight: bold">
                    <el-input-number 
                        v-model="dynamicSurfaceForm.qty" 
                        :min="0" 
                        @change="QtyChange2(dynamicSurfaceForm.opera_nm, dynamicSurfaceForm.qty, dynamicSurfaceForm.fqty)" 
                        style="width: 100%;color: #F56C6C;font-weight: bold;">
                    </el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-row :gutter="20" style="margin-bottom: 0px">
                    <el-col :span="12">
                        <el-button style="width: 100%" @click="dialogVisible2 = false" round>取消</el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-button 
                            style="width: 100%" 
                            :loading="isLoading"
                            type="primary" 
                            @click="SubmitForm2('dynamicSurfaceForm')" 
                            round>提交</el-button>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import moment from 'moment'
import qs from 'qs'
export default {
    data() {
        return {
            // sec_nos: [],
            surfaceForm: {
                sec_no: '',
                iod: '',
                odr_no: '',
                artic_no: '',
                pnl: '',
                qty: '',
                style: ''
            },
            odr_nos: [],
            tableData: [],
            rules: {},
            rules2: {
                // pnl1: [
                //     {required: true, message: '请选择工号', trigger: 'change'}
                // ],
                // pnl2: [
                //     {required: true, message: '请输入工号', trigger: 'change'}
                // ],
                qty: [
                    {required: true, message: '请输入数量', trigger: 'change'}
                ]
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() || time.getTime() < moment().add(-7, 'day')
                },
            },
            dialogVisible: false,
            dialogVisible2: false,
            dynamicSurfaceForm: {
                pnl1: '',
                pnl2: '',
                qty: '',
                is_sec: true,
                opera_no: '',
                opera_nm: '',
                fqty: '',
                DB_ID: '',
                qty_old: ''
                // persons: [{
                //     label: '工号',
                //     value: ''
                // },{
                //     label: '姓名',
                //     value: ''
                // },{
                //     label: '数量',
                //     value: ''
                // }]
            },
            pnls: [],
            value: '',
            options: [],
            isLoading: false,
            skeleton_loading: false
        }
    },
    methods: {
        SetQty(row) {
            console.log('row', row)
            row.qty = this.surfaceForm.qty
            this.QtyChange(row.opera_nm, row.qty, row.fqty)
        },
        SecChange(item) {
            console.log('item', item)
            this.GetOdrNoData()
            this.GetSurfacePNL()
        },
        async GetSecData() {
            this.secs = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/GetSecData',qs.stringify({
                made_no: '3'
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                // res.data.ResponseData.forEach(item => {
                //     this.sec_nos.push({
                //         "value": item.sec_no + ' - ' + item.sec_nm
                //     })
                // })
                
                this.options = this.GroupBy(res.data.ResponseData, 'sec_nm1')
                console.log('options', this.options)
            }
        },
        // querySearch(queryString, cb) {
        //     var restaurants = this.sec_nos
        //     var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
        //     // 调用 callback 返回建议列表的数据
        //     cb(results)
        // },
        // createFilter(queryString) {
        //     return (restaurant) => {
        //         return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        //     }
        // },
        GroupBy(datas, key, callBack) {
            const list = datas || []
            const groups = []
            list.forEach(v => {
                let key = v.sec_no1
                // keys.forEach(k => {
                //     key[k] = v[k]
                // })
                let group = groups.find(v => {
                    return v.value === key
                })
                if(!group) {
                    group = {
                        label: v.sec_nm1,
                        value: v.sec_no1
                    }
                    groups.push(group)
                }
                if(callBack) {
                    group.children = callBack(group.children, v)
                    // group.total = group.total || 0
                    // group.total++
                }else {
                    group.children = group.children || []
                    group.children.push({
                        label: v.sec_nm,
                        value: v.sec_no
                    })
                }
            })
            return groups
        },
        async GetOdrNoData() {
            this.odr_nos = []
            this.surfaceForm.odr_no = ''
            this.tableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/GetOdrNoData',qs.stringify({
                // sec_no: this.surfaceForm.sec_no.split(' - ')[0],
                sec_no: this.surfaceForm.sec_no[1],
                iod: this.surfaceForm.iod,
                made_no: '3',
                pro_no: '03'
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.forEach(item => {
                    this.odr_nos.push({
                        label: item.odr_no,
                        value: item.odr_no,
                        artic_no: item.artic_no,
                        qty: item.qty,
                        style: item.style
                    })
                })
            }

            // this.GetSurfacePNL()
        },
        SetArticQty() {
            if(this.odr_nos) {
                let data = this.odr_nos.find(item => item.value === this.surfaceForm.odr_no )
                this.surfaceForm.artic_no = data.artic_no
                this.surfaceForm.qty = data.qty
                this.surfaceForm.style = data.style

                this.GetOperaData()
                // this.dynamicSurfaceForm.qty = this.surfaceForm.qty
            }
        },
        async GetOperaData() {
            this.skeleton_loading = true
            this.tableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/GetOperaData',qs.stringify({
                style: this.surfaceForm.style,
                odr_no: this.surfaceForm.odr_no,
                iod: this.surfaceForm.iod,
                sec_no: this.surfaceForm.sec_no[1],
                made_no: '3',
                operaclass_no: ''
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.tableData = this.GroupByOperaNo(res.data.ResponseData, 'opera_no')
                console.log('tableData', this.tableData)
            }
            this.skeleton_loading = false
        },
        GroupByOperaNo(datas, key, callBack) {
            const list = datas || []
            const groups = []
            list.forEach(v => {
                let key = v.opera_no
                // keys.forEach(k => {
                //     key[k] = v[k]
                // })
                let group = groups.find(v => {
                    return v.opera_no === key
                })
                if(!group) {
                    group = {
                        // id: v.opera_no,
                        opera_no: v.opera_no,
                        opera_nm: v.opera_nm,
                        fqty: v.fqty,
                        // hasChildren: v.hasChildren > 0 ? true : false
                    }
                    groups.push(group)
                }
                if(callBack) {
                    group.children = callBack(group.children, v)
                    // group.total = group.total || 0
                    // group.total++
                }else {
                    group.children = group.children || []
                    if(v.qty > 0) {
                        group.children.push({
                            // opera_no: v.opera_no,
                            // opera_nm: v.pnl + ' - ' + v.iod,
                            // fqty: v.qty,
                            pname: v.pname,
                            qty: v.qty,
                            pnl: v.pnl,
                            DB_ID: v.DB_ID,
                            verify: v.verify
                        })
                    }
                }
            })
            return groups
        },
        async GetSurfacePNL() {
            this.pnls = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/GetSurfacePNL',qs.stringify({
                // sec_no: this.dynamicSurfaceForm.is_sec ? this.surfaceForm.sec_no.split(' - ')[0] : '',
                sec_no: this.dynamicSurfaceForm.is_sec ? this.surfaceForm.sec_no[1] : '',
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.forEach(item => {
                    this.pnls.push({
                        value: item.pnl,
                        label: item.pname + ' - ' + item.pnl,
                        sec_no: item.sec_no
                    })
                })
            }
        },
        IsSecChange() {
            // this.dynamicSurfaceForm.is_sec = false
            if(this.dynamicSurfaceForm.is_sec) {
                this.GetSurfacePNL()
            }else {
                this.dynamicSurfaceForm.pnl2 = ''
            }
            
            // return
        },
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    this.SaveGjjzIo()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveGjjzIo() {
            this.isLoading = true
            const dto = {
                OPERA_NO: this.dynamicSurfaceForm.opera_no,
                PNL: this.dynamicSurfaceForm.is_sec ? this.dynamicSurfaceForm.pnl1 : this.dynamicSurfaceForm.pnl2,
                ODR_NO: this.surfaceForm.odr_no,
                STYLE: this.surfaceForm.style,
                QTY: this.dynamicSurfaceForm.qty,
                SEC_NO: this.surfaceForm.sec_no[1],
                DB_CREATE_USERNO: this.surfaceForm.pnl
            }
            console.log('dto', dto)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/SaveGjjzIo', qs.stringify({
                dto
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: '提交缴库成功',
                    center: true,
                    type: 'success'
                })

                this.dialogVisible = false 
                this.GetOperaData()
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '提交缴库失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '提交缴库失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        QtyChange(opera_nm, qty, fqty) {
            // console.log('qty', qty)
            // console.log('fqty', fqty)
            // console.log('this.surfaceForm.qty', this.surfaceForm.qty)
            if((qty + fqty) > this.surfaceForm.qty) {
                this.$message({
                    message: opera_nm + '，可缴库数：' + (this.surfaceForm.qty - fqty),
                    center: true,
                    type: 'error'
                })
            }
        },
        async SaveGjjzIoList() {
            const dtos = []
            this.isLoading = true
            this.tableData.forEach(item => {
                if(item.qty) {
                    dtos.push({
                        OPERA_NO: item.opera_no,
                        PNL: this.surfaceForm.pnl,
                        ODR_NO: this.surfaceForm.odr_no,
                        STYLE: this.surfaceForm.style,
                        QTY: item.qty,
                        SEC_NO: this.surfaceForm.sec_no[1],
                        DB_CREATE_USERNO: this.surfaceForm.pnl,
                        IOD: this.surfaceForm.iod,
                        MADE_NO: '3',
                        PERACLASS_NO: ''
                    })
                }
            })
            
            console.log('dtos', dtos)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/SaveGjjzIoList', qs.stringify({
                dtos
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: '提交缴库成功',
                    center: true,
                    type: 'success'
                })

                this.dialogVisible = false 
                this.GetOperaData()
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '提交缴库失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '提交缴库失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        ShowDialog2(item, row) {
            // 件资管理员
            if(this.surfaceForm.pnl !=='62153' && this.surfaceForm.pnl !=='62188') {
                if(item.verify) {
                    this.$message({
                        message: '该缴库已审核，非法操作！' ,
                        center: true,
                        type: 'error'
                    })
                    return 
                }
                if(item.pnl !== this.surfaceForm.pnl) {
                    this.$message({
                        message: '缴库更新失败，非法操作！' ,
                        center: true,
                        type: 'error'
                    })
                    return 
                }
            }

            console.log('item', item)
            console.log('row', row)
            this.dialogVisible2 = true
            this.dynamicSurfaceForm.opera_nm = row.opera_nm
            this.dynamicSurfaceForm.opera_no = row.opera_no
            this.dynamicSurfaceForm.qty = item.qty
            this.dynamicSurfaceForm.fqty = row.fqty
            this.dynamicSurfaceForm.DB_ID = item.DB_ID
            this.dynamicSurfaceForm.qty_old = item.qty
        },
        QtyChange2(opera_nm, qty, fqty) {
            // console.log('qty', qty)
            // console.log('fqty', fqty)
            // console.log('opera_nm', opera_nm)
            // console.log('this.surfaceForm.qty', this.surfaceForm.qty)
            this.dynamicSurfaceForm.diff_qty = qty - this.dynamicSurfaceForm.qty_old
            // console.log('this.dynamicSurfaceForm.diff_qty', this.dynamicSurfaceForm.diff_qty)
            if((qty + fqty) > this.surfaceForm.qty) {
                this.$message({
                    message: opera_nm + '，可缴库数：' + (this.surfaceForm.qty - fqty - this.dynamicSurfaceForm.diff_qty),
                    center: true,
                    type: 'error'
                })
            }
        },
        SubmitForm2(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    this.UpdateGjjzIo()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async UpdateGjjzIo() {
            const dto = {
                DB_ID: this.dynamicSurfaceForm.DB_ID,
                QTY: this.dynamicSurfaceForm.qty,
                SEC_NO: this.surfaceForm.sec_no[1],
                OPERA_NO: this.dynamicSurfaceForm.opera_no,
                IOD: this.surfaceForm.iod,
                ODR_NO: this.surfaceForm.odr_no,
                DIFF_QTY: this.dynamicSurfaceForm.diff_qty
            }
            console.log('dto', dto)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/UpdateGjjzIo', qs.stringify({
                dto
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: '缴库更新成功',
                    center: true,
                    type: 'success'
                })

                this.dialogVisible2 = false 
                this.GetOperaData()
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '缴库更新失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '缴库更新失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        this.GetSecData()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.surfaceForm.pnl = pnl
        }

        // admin
        if(this.surfaceForm.pnl === '55129' || this.surfaceForm.pnl === '43528' || this.surfaceForm.pnl === '68817') {
            this.surfaceForm.pnl = '5669'
        }

        // dev
        // this.surfaceForm.sec_no='HQ92G1'
        // this.surfaceForm.iod = '2023-07-14'
        // this.surfaceForm.odr_no='QAS/2304039-009'
        // this.surfaceForm.pnl = '62188'
    }
}
</script>