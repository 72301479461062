<template>
    <div>
        <el-tabs type="card" :stretch="true" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane name="first">
                <span slot="label">
                    <i style="color: #F56C6C; font-weight: bolder" class="el-icon-warning-outline"></i> 
                    待保养 (<span style="color: #F56C6C; font-weight: bolder">{{unCheck_count}}</span>)
                </span>
                <el-table :data="unCheck_tableData" :show-header="false" v-loading="unCheck_table_loading" size="mini">
                    <el-table-column prop="col1">
                        <template slot-scope="scope">
                            <div>{{getCheckType(scope.row.checktype)}}</div>
                            <div style="font-size: 11px">{{scope.row.ass_no}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="col2">
                        <template slot-scope="scope">
                            <div style="font-size: 11px">{{scope.row.checkname}}</div>
                            <div>{{scope.row.ass_nm}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="col3" >
                        <template slot-scope="scope">
                            <div>
                                <el-button type="text" size="mini" @click="GOTOMaintain">保养 
                                    <i style="font-weight: bolder" class="el-icon-right"></i>
                                </el-button>
                            </div>
                            <div>{{scope.row.plan_time1}} - {{scope.row.plan_time2}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane name="second">
                <span slot="label">
                    <i style="color: #67C23A; font-weight: bolder" class="el-icon-circle-check"></i>
                    已保养(<span style="color: #67C23A; font-weight: bolder">{{check_count}}</span>)
                </span>
                 <el-table :data="check_tableData" :show-header="false" v-loading="check_table_loading" size="mini">
                    <el-table-column prop="col1">
                        <template slot-scope="scope">
                            <div>{{getCheckType(scope.row.checktype)}}</div>
                            <div style="font-size: 11px">{{scope.row.ass_no}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="col2">
                        <template slot-scope="scope">
                            <div>{{scope.row.checkname}}</div>
                            <div>{{scope.row.ass_nm}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="col3" >
                        <template slot-scope="scope">
                            <div>{{scope.row.check_man}}</div>
                            <div>{{scope.row.check_time}}</div>
                            <!-- <div>
                                <span style="color: #67C23A; font-weight: bolder">
                                    已保养 <i style="color: #67C23A; font-weight: bolder" class="el-icon-check"></i>
                                </span>
                            </div> -->
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import moment from 'moment'
export default {
    data() {
        return {
            sec_no: '',
            activeName: 'first',
            check_count: 0,
            unCheck_count: 0,
            check_tableData: [],
            unCheck_tableData: [],
            check_table_loading: false,
            unCheck_table_loading: false,
            CheckType: [
                {label: '0', value: '一级保养'},
                {label: '1', value: '二级保养'},
                {label: '2', value: '三级保养'},
            ],
        }
    },
    methods: {
         getCheckType(CHECKTYPE) {
            let type = ''
            this.CheckType.map(item => {
                if(item.label === CHECKTYPE.toString()) {
                    type = item.value
                }
            })
            return type
        },
        GOTOMaintain() {
            window.location.href = '/maintain'
        },
        handleClick(tab) {
            if(tab.index == '0' || !tab.index) {
                this.GetAssUnCheckPlanData()
            }else{
                this.GetAssCheckPlanData()
            }
        },
        async GetAssUnCheckPlanData() {
            this.unCheck_tableData = []
            this.unCheck_table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsAssetcheckplans/AssCheckPlan', qs.stringify({
                sec_no: this.sec_no,
                isCheck: '0'
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.unCheck_count = res.data.ResponseData.length
                res.data.ResponseData.forEach(item => {
                    item.plan_time1 = moment(item.plan_time1).format('MM/DD')
                    item.plan_time2 = moment(item.plan_time2).format('MM/DD')
                })
                this.unCheck_tableData = res.data.ResponseData
            }
            this.unCheck_table_loading = false
        },
        async GetAssCheckPlanData() {
            this.check_tableData = []
            this.check_table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsAssetcheckplans/AssCheckPlan', qs.stringify({
                sec_no: this.sec_no,
                isCheck: '1'
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.check_count = res.data.ResponseData.length
                res.data.ResponseData.forEach(item => {
                    item.check_time = moment(item.check_time).format('MM/DD HH:mm')
                })
                this.check_tableData = res.data.ResponseData
            }
            this.check_table_loading = false
        },
    },
    mounted() {
        this.GetAssUnCheckPlanData()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
        }
        const sec_no = localStorage.getItem('SEC_NO')
        if(sec_no) {
            this.sec_no = sec_no
        }
    }
}
</script>

<style scoped>
    body {
        margin: 0;
        padding: 0;
    }
</style>