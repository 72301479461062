<template>
    <div>
        <el-row style="margin-bottom: 20px;">
            <el-col :span="24">
                <router-link to="/proposal">
                    <el-button style="width: 100%" type="primary">新建议</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-card shadow="always" v-for="item in suggest_list" :key="item.DB_ID" class="box-card" v-loading="loading">
            <el-row>
                <el-col :span="6" style="text-align: left">建议类别</el-col>
                <el-col :span="14" style="text-align: right">{{item.GTYPE}}</el-col>
                <el-col :span="4" style="text-align: right">
                    <el-tag
                        v-if="item.STATU === '已提交待回复' && item.ISEND !== 1"
                        style="margin-left: 5px"
                        type="danger"
                        size="mini"
                        effect="dark">
                        {{ item.STATU === '已提交待回复' ? '待回复' : '' }}
                    </el-tag>
                    <el-tag
                        v-if="item.STATU === '已回复' && item.ISEND !== 1"
                        style="margin-left: 5px"
                        type="danger"
                        size="mini"
                        effect="dark">
                        {{ item.STATU === '已回复' ? '已回复' : '' }}
                    </el-tag>
                    <el-tag
                        v-if="item.ISEND === 1"
                        style="margin-left: 5px"
                        type="danger"
                        size="mini"
                        effect="dark">
                        完结
                    </el-tag>
                </el-col>
            </el-row>
            <el-divider/>
             <el-row>
                <el-col :span="6" style="text-align: left">建议内容</el-col>
                <el-col :span="18" style="text-align: right">{{item.GCONTENT}}</el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="6" style="text-align: left">建议日期</el-col>
                <el-col :span="18" style="text-align: right">{{item.GDATE}}</el-col>
            </el-row>
            <el-divider v-if="item.RECREMARK" />
            <el-row v-if="item.RECREMARK">
                <el-col :span="6" style="text-align: left">回复内容</el-col>
                <el-col :span="18" style="text-align: right">{{item.RECREMARK}}</el-col>
            </el-row>
            <el-divider v-if="item.RECTIME" />
            <el-row v-if="item.RECTIME">
                <el-col :span="6" style="text-align: left">回复日期</el-col>
                <el-col :span="18" style="text-align: right">{{item.RECTIME}}</el-col>
            </el-row>
        </el-card>
        <el-empty v-if="suggest_list.length === 0" description="没有数据"></el-empty>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
export default {
    data() {
        return {
            suggest_list: [],
            pnl: '',
            loading: false
        }
    },
    methods: {
        async getProsalListData() {
            this.loading = true
            this.suggest_list = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlSuggests/ProposalList', qs.stringify({
                pnl: this.pnl,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.suggest_list = res.data.ResponseData
            }else {
                this.$message({
                    message: '获取我的建议数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.loading = false
        },
    },
    mounted() {
        this.getProsalListData()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
        }
    }
}
</script>

<style scoped>
    .box-card{
        margin-top: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 13px;
        /* border-radius: 10px;  */
    } 
    .col-danger {
        color: #F56C6C;
        font-weight: bold;
    }
    .col-success {
        color: #67C23A;
        font-weight: bold;
    }
    .col-primary {
        color: #409EFF;
        font-weight: bold;
    }
</style>