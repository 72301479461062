<template>
    <div>
        <el-row v-for="(item, index) in dinnerImageData" :key="index" style="margin-bottom: 30px">
            <el-col :span="24" >
                <el-card :body-style="{ padding: '0px' }">
                    <el-image 
                        @click="ShowImages(item.DB_ID)"
                        style="width: 100%;"
                        :src="item.FImgPath">
                    </el-image>
                    <!-- <el-image 
                        style="width: 100%;"
                        :src="item.FImgPath" 
                        :preview-src-list="item.preview_list">
                    </el-image> -->
                    <div style="padding: 14px;">
                        <div style="margin-bottom: 10px;font-size: 16px;color: #909399;font-weight: bolder">{{ item.ftype_nm }}餐厅</div>
                        <div style="font-size: 14px;font-weight: bolder;color: #409EFF">{{ item.fdate }}</div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
         <!-- <div class="page">
            <div class="demo"> -->
                <!-- <el-image id="demoImg" width="100px" :src=url class="demo-img" /> -->
            <!-- </div>
        </div> -->
    </div>
</template>

<script>
import conf from '../../../conf.js'
import axios from 'axios'
import moment from 'moment'
// import Hammer from 'hammerjs'
// import { setTimeout } from 'timers'
import wx from 'weixin-js-sdk'
import qs from 'qs'
export default {
    data() {
        return {
            dinnerImageData: [], 
            imageList: []
            // config: {},
            // id: null,
            // mc: null,
            // timer: false,
            // translateX: 0,
            // translateY: 0,
            // scale: 1,
            // firstTouch: true,
            // relateX: 0,
            // relateY: 0,
            // oldX: 0,
            // oldY: 0,
            // oldScale: 1,
            // url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        }
    },
    methods: {
        async GetDinnerImage() {
            this.dinnerImageData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxDinnerWeekMenus/DinnerImage')
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        // var preview_list = []
                        // preview_list.push(conf.image_domain + item.FImgPath)
                        this.dinnerImageData.push({
                            DB_ID: item.DB_ID,
                            FImgPath: conf.image_domain + item.FImgPath,
                            ftype_nm: item.ftype_nm,
                            // preview_list: preview_list,
                            fdate: moment(item.fstart).format('MM月DD日') + ' - ' + moment(item.fend).format('MM月DD日')
                        })
                    })
                }
            }
        },
        async ShowImages(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                            path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
            }
        },
        // previewImage() {
        //     wx.previewImage({
        //         current: path, // 当前显示图片的 http 链接
        //         urls: [path] // 需要预览的图片 http 链接列表
        //     })
        // },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
        
        // picInit () {
        //     this.id = document.getElementById('demoImg')
        //     this.mc = new Hammer(this.id)
        //     this.relateX = (document.body.clientWidth - this.id.offsetWidth) / 2
        //     this.relateY = (document.body.clientHeight - this.id.offsetHeight) / 2

        //     this.mc.add(new Hammer.Pan({
        //         direction: Hammer.DIRECTION_ALL,
        //         threshold: 0,
        //         pointers: 0
        //     }))
        //     this.mc.add(new Hammer.Pinch({
        //         threshold: 0
        //     })).recognizeWith(this.mc.get('pan'))

        //     this.mc.on('hammer.input', this.isFinal)
        //     this.mc.on('panstart panmove', this.onPan)
        //     this.mc.on('pinchstart pinchmove', this.onPinch)

        //     this.setPosition()
        // },
        // isFinal (ev) {
        //     if (ev.isFinal) {
        //         this.oldX = this.translateX
        //         this.oldY = this.translateY
        //         this.oldScale = this.scale
        //     }
        // },
        // // 初始化图片位置及缩放
        // setPosition () {
        //     this.selfPosition({
        //         translateX: this.relateX,
        //         translateY: this.relateY,
        //         scale: this.scale
        //     })
        // },
        // // 单点触发 - 拖拉
        // onPan (ev) {
        //     // console.log(this.firstTouch)
        //     if (this.firstTouch) {
        //         this.oldX = this.relateX
        //         this.oldY = this.relateY
        //     }
        //     // console.log(this.oldX)
        //     // console.log(this.oldY)
        //     this.translateX = this.oldX + ev.deltaX
        //     this.translateY = this.oldY + ev.deltaY
        //     const position = {
        //         translateX: this.translateX,
        //         translateY: this.translateY,
        //         scale: this.scale
        //     }
        //     this.selfPosition(position)
        //     this.firstTouch = false
        // },
        // // 多点触发 - 缩放
        // onPinch (ev) {
        //     this.scale = this.oldScale * ev.scale
        //     this.selfPosition({
        //         translateX: this.translateX,
        //         translateY: this.translateY,
        //         scale: this.scale
        //     })
        //     // this.selfPosition(this.position)
        // },
        // selfPosition (pos) {
        //     return this.picAnimate()(() => this.tempPos(pos))
        // },
        // tempPos (pos) {
        // let style = [
        //     `translate3d(${pos.translateX}px, ${pos.translateY}px, 0)`,
        //     `scale(${pos.scale}, ${pos.scale})`
        //     // `scale(${pos.scale > 1.2 ? 1.2 : pos.scale}, ${pos.scale > 1.2 ? 1.2 : pos.scale})`
        // ]
        // style = style.join(' ')
        //     this.id.style.transform = style
        // },
        // picAnimate () {
        //     return window[Hammer.prefixed(window, 'requestAnimationFrame')] || function (callback) {
        //         setTimeout(callback, 1000 / 60)
        //     }
        // }
    },
    mounted() {
        // this.$nextTick(() => {
        //     this.picInit()
        // })

        this.GetSignature()
    },
    created() {
        this.GetDinnerImage()
    }
}
</script>

<style lang="less" scoped>
// .page {
//   width: 375px;
//   height: 100vh;
//   background-size: 100% 100%;
// }
// .demo {
//   position: absolute;
// }
// .demo-img {
//   width: 260px;
//   height: 260px;
//   display: block;
//   overflow: hidden;
// }
</style>