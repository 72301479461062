<template>
    <div>
        <el-alert
            type="warning"
            show-icon>
            <template slot='title'>
                <div class="iconSize" style="text-align: left;">注意：由于数据采集周期限制，宿舍水电费用需等水电核算之后，最终结果以行政部发布为准。</div>
            </template>
        </el-alert>
        <el-form :model="weForm" :rules="rules" ref="weForm" label-width="80px">
            <el-date-picker
                v-model="weForm.yymm"
                size="medium"
                type="month"
                @change="GetWaterElectricity"
                align="center"
                style="width: 100%;"
                placeholder="请选择水电月份">
            </el-date-picker>
        </el-form>
        <el-alert
            style="margin-top: 20px"
            type="success"
            show-icon>
            <template slot='title'>
                <div class="iconSize" style="text-align: left;">个人应扣水电金额 =（（宿舍水电度数 / 宿舍人数）- 补贴水电度数）* 水电单价</div>
            </template>
        </el-alert>
        <el-table :data="tableData" stripe size="medium" :show-header="false" v-loading="loading" >
            <el-table-column prop="label" width="200px" label-class-name="label-class">
            </el-table-column>
            <el-table-column prop="value" align="right">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf.js'
import moment from 'moment'
export default{
    data() {
        return {
            weForm: {
                yymm: moment().startOf("month").subtract(1, 'months').format('YYYY-MM'),
                pnl: ''
            },
            tableData: [],
            loading: false,
            rules: {
                yymm: [
                    {required: true, message: '请选择水电月份', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        async GetWaterElectricity() {
            this.loading = true
            this.tableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WaterElectricity/GetWaterElectricity', qs.stringify({
                yymm: moment(this.weForm.yymm).format("YYYY-MM"),
                pnl: this.weForm.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                res.data.ResponseData.forEach(item => {
                    this.tableData.push({
                        label: '本月冷水数（宿舍）',
                        value: item.sqty + ' 吨'
                    })
                    this.tableData.push({
                        label: '本月电表1数（宿舍）',
                        value: item.dqty + ' 度'
                    })
                    this.tableData.push({
                        label: '电费补贴度数 / 金额',
                        value: '30度 / ' + item.smoneydf + ' 元'
                    })
                    this.tableData.push({
                        label: '水费补贴度数 / 金额',
                        value: '2吨 / ' + item.smoneysd + ' 元'
                    })
                    this.tableData.push({
                        label: '电费单价',
                        value: item.dprice + ' 元 / 度'
                    })
                    this.tableData.push({
                        label: '水费单价',
                        value: item.sprice + ' 元 / 吨'
                    })
                    this.tableData.push({
                        label: '冷水费', 
                        value: item.pnl_smoney1 + ' 元'
                    })
                    this.tableData.push({
                        label: '电费',
                        value: item.pnl_smoney2 + ' 元'
                    })
                    this.tableData.push({
                        label: '水电费',
                        value: item.pnl_smoney + ' 元'
                    })
                })
            }
            this.loading = false
        }
    },
    mounted() {
        this.GetWaterElectricity()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.weForm.pnl = pnl
            // this.weForm.pnl = '40062'
        }
    }
}
</script>