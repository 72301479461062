<template>
    <div>
        <SportHeader />
        <el-form :model="sportsForm" :rules="rules" ref="sportsForm" label-width="90px" size="medium">
            <!-- <el-descriptions class="margin-top" :column="1" size="medium" border>
                <el-descriptions-item>
                    <template slot="label">
                    <i class="el-icon-user"></i>
                        个人信息
                    </template>
                    {{sportsForm.sec_nm}} - {{sportsForm.nm}}
                    </el-descriptions-item>
            </el-descriptions> -->
            <el-form-item label="员工工号" prop="no" style="font-weight: bold">
                <el-input v-model="sportsForm.no" placeholder="请输入员工工号" size="medium" @blur="getPersonByPnl"></el-input>
            </el-form-item>
            <el-form-item label="员工姓名" prop="nm" style="font-weight: bold">
                <el-input v-model="sportsForm.nm" placeholder="请输入员工姓名" size="medium" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="所在部门" prop="sec_nm" style="font-weight: bold">
                <el-input v-model="sportsForm.sec_nm" placeholder="请输入所在部门" size="medium" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="运动日期" prop="date" style="margin-top: 20px;font-weight: bold">
                <el-date-picker
                    v-model="sportsForm.date"
                    size="medium" 
                    align="center"
                    type="date"
                    style="width: 100%"
                    placeholder="选择日期(可补录前7天)">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="运动类型" prop="type" style="font-weight: bold">
                <el-select 
                    v-model="sportsForm.type" 
                    placeholder="请选择运动类型" 
                    size="medium" 
                    @change="typeChange" 
                    style="width: 100%">
                    <el-option
                        v-for="item in types"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="运动项目" prop="sport" style="font-weight: bold">
                <el-select 
                    v-model="sportsForm.sport" 
                    placeholder="请选择运动项目" 
                    size="medium" 
                    @change="sportChange"
                    style="width: 100%">
                    <el-option
                        v-for="item in sports"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="其他项目" prop="other_sport" style="font-weight: bold" v-if="this.sportsForm.isOtherSport">
                <el-input v-model="sportsForm.other_sport" placeholder="请输入其他运动项目" size="medium"></el-input>
            </el-form-item>
            <el-form-item label="开始时间" prop="start" style="font-weight: bold" v-if="this.sportsForm.isTimeType">
                <el-time-picker
                    v-model="sportsForm.start"
                    style="width: 100%"
                    size="medium"
                    value-format="HH:mm"
                    @change="minuteChange"
                    placeholder="选择运动开始时间">
                </el-time-picker>
            </el-form-item>
             <el-form-item label="结束时间" prop="end" style="font-weight: bold" v-if="this.sportsForm.isTimeType">
                <el-time-picker
                    v-model="sportsForm.end"
                    style="width: 100%"
                    size="medium"
                    value-format="HH:mm"
                    @change="minuteChange"
                    placeholder="选择运动结束时间">
                </el-time-picker>
            </el-form-item>
            <el-alert
                type="success"
                style="margin-bottom: 20px;"
                v-if="this.sportsForm.isShowMinute"
                show-icon>
                <template slot='title'>
                    <div class="iconSize">运动时长：{{ this.sportsForm.sport_minute }} 分钟</div>
                </template>
            </el-alert>
            <el-form-item label="运动距离" prop="km" style="font-weight: bold" v-if="!this.sportsForm.isTimeType">
                <!-- <el-input v-model="sportsForm.km" placeholder="请输入运动距离(km)" size="medium"></el-input> -->
                <el-input-number 
                    size="medium" 
                    :min="0"
                    :precision="2"
                    style="width: 85%;" 
                    placeholder="请输入运动距离" 
                    v-model="sportsForm.km"></el-input-number> KM
            </el-form-item>
            <el-form-item label="运动凭证" style="font-weight: bold">
                <el-upload 
                    action='' 
                    ref="upload"
                    :on-change="getFile" 
                    :limit="3" 
                    list-type="picture"
                    :auto-upload="false" 
                    :on-exceed="handleExceed"
                    style="width: 100%;" >
                    <el-button size="medium" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>

                <!-- <el-upload 
                    action='' 
                    ref="upload"
                    :on-change="getFile" 
                    :limit="1" 
                    list-type="picture" 
                    :auto-upload="false" 
                    style="width: 100%;" >
                    <el-button size="medium"  type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload> -->

                <!-- <el-upload
                    action="#"
                    :limit="1"
                    :on-exceed="handleExceed"
                    accept="image/jpeg,image/jpg,image/png"
                    :before-upload="beforeAvatarUpload"
                    list-type="picture-card"
                    :auto-upload="false">
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                    <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url" alt=""
                    > -->
                    <!-- <span class="el-upload-list__item-actions">
                        <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                        >
                        <i class="el-icon-zoom-in"></i>
                        </span>
                        <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                        >
                        <i class="el-icon-download"></i>
                        </span>
                        <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                        >
                        <i class="el-icon-delete"></i>
                        </span>
                    </span> -->
                    <!-- </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>-->
            </el-form-item> 
            <el-row style="margin-top: 10px;margin-bottom: 20px">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="sportsForm.isLoading"
                        @click="submitForm('sportsForm')">提交打卡</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import conf from '../../conf.js'
import { compressImage } from '../../plugin/compress.js'
import SportHeader from '../../components/SportHeader'
export default {
    components: {
        SportHeader
    },
    data(){
        return {
            sportsForm: {
                no: '',
                nm: '',
                sec_no: '',
                sec_nm: '',
                type: '',
                date: '',
                sport: '',
                km: '',
                isTimeType: true,
                start: '',
                end: '',
                image1: null,
                isOtherSport: false,
                other_sport: '',
                sport_minute: '',
                isShowMinute: false,
                isLoading: false,
                fileList: [],
                imageList: [],
            },
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            imageUrl: '',
            types: [
                {
                    label: '时间类',
                    value: '时间类'
                },{
                    lable: '距离类',
                    value: '距离类'
                }
            ],
            sports: [],
            rules: {
                no: [
                    {required: true, message: '请输入员工工号', trigger: 'blur'}
                ],
                nm: [
                    {required: true, message: '请输入员工姓名', trigger: 'change'}
                ],
                sec_nm: [
                    {required: true, message: '请输入所在部门', trigger: 'change'}
                ],
                date: [
                    {required: true, message: '请选择日期', trigger: 'change'}
                ],
                type: [
                    {required: true, message: '请选择运动类型', trigger: 'change'}
                ],
                sport: [
                    {required: true, message: '请选择运动项目', trigger: 'change'}
                ],
                image1: [
                    {required: true, message: '请选择一张图片', trigger: 'change'}
                ]
            },
        }
    },
    methods: {
        sportChange(item) {
            if(item === '其他') {
                this.sportsForm.isOtherSport = true
            }else {
                this.sportsForm.isOtherSport = false
            }
        },
        typeChange(item) {
            if(item === '距离类'){
                this.sportsForm.isTimeType = false
                this.sportsForm.sport = ''
                this.sportsForm.isShowMinute = false
                this.sports = [
                    {
                        label: '跑步',
                        value: '跑步'
                    },
                    {
                        label: '健走',
                        value: '健走'
                    },
                    {
                        label: '骑行',
                        value: '骑行'
                    },
                    {
                        label: '登山',
                        value: '登山'
                    },
                    {
                        lable: '其他',
                        value: '其他'
                    },
                ]
            }else{
                if(this.sportsForm.start && this.sportsForm.end) {
                    this.sportsForm.isShowMinute = true
                }
                this.sportsForm.isTimeType = true
                this.sportsForm.sport = ''
                this.sports = [
                    {
                        lable: '羽毛球',
                        value: '羽毛球'
                    },
                    {
                        lable: '台球',
                        value: '台球'
                    },
                    {
                        lable: '兵乓球',
                        value: '兵乓球'
                    },
                    {
                        lable: '跳绳',
                        value: '跳绳'
                    },
                    {
                        lable: '瑜伽',
                        value: '瑜伽'
                    },
                    {
                        lable: '跳舞',
                        value: '跳舞'
                    },
                    {
                        lable: '健身',
                        value: '健身'
                    },
                    {
                        lable: '游泳',
                        value: '游泳'
                    },
                    {
                        lable: '其他',
                        value: '其他'
                    },
                ]
            }
        },
        // handleAvatarSuccess(res, file) {
        //     this.imageUrl = URL.createObjectURL(file.raw);
        // },
        // beforeAvatarUpload(file) {
        //     const isJPG = file.raw.type === 'image/jpeg'
        //     const isPNG = file.raw.type === 'image/png'
        //     const isLt2M = file.size / 1024 / 1024 < 3;
        //     if (!isJPG && !isPNG) {
        //         this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
        //     }
        //     if (!isLt2M) {
        //         this.$message.error('上传凭证图片大小不能超过 3MB!')
        //     }
        //     return isLt2M && (isJPG || isPNG)
        // },
        // handleExceed() {
        //     this.$message({
        //         message: '只能上传一张照片',
        //         center: true
        //     })
        // },
        // getBase64(file) {
        //     return new Promise(function(resolve, reject) {
        //         let reader = new FileReader();
        //         let imgResult = "";
        //         reader.readAsDataURL(file);
        //         reader.onload = function() {
        //             imgResult = reader.result;
        //         }
        //         reader.onerror = function(error) {
        //             reject(error);
        //         }
        //         reader.onloadend = function() {
        //             resolve(imgResult);
        //         }
        //     })
        // },
        // getFile(file) {
        //     if(!this.beforeAvatarUpload(file)) {
        //         this.$refs.upload.handleRemove(file);
        //         return
        //     }
        //     this.getBase64(file.raw).then(res => {
        //         console.log(res)
        //         this.sportsForm.image1 = res
        //     })
        // },
        getFile(file, fileList) {
            console.log('file', file)
            console.log('fileList', fileList)
            this.sportsForm.fileList = []
            this.sportsForm.imageList = []
            fileList.forEach(item => {
                this.sportsForm.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.sportsForm.imageList.push(result)
                })
            })
        },
        handleRemove(file, fileList) {
            console.log('file', file)
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.sportsForm.fileList = []
            this.sportsForm.imageList = []
            fileList.forEach(item => {
                this.sportsForm.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.sportsForm.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        },
        minuteChange() {
            if(this.sportsForm.start && this.sportsForm.end) {
                this.sportsForm.sport_minute = this.minuteDiff(this.sportsForm.start, this.sportsForm.end)
                this.sportsForm.isShowMinute = true
            }else{
                this.sportsForm.isShowMinute = false
            }
        },
        minuteDiff(start, end) {
            const mstart = moment(start, 'hh:mm')
            const mend = moment(end, 'hh:mm')
            return mend.diff(mstart, 'minute')
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CustomValidate()){
                    console.log(this.sportsForm)
                    this.SaveSportsClock()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        CustomValidate() {
            // if(!this.sportsForm.image1) {
            //     this.$message({
            //         message: '请上传运动凭证',
            //         center: true,
            //         type: 'warning'
            //     })
            //     return false
            // }
            if(this.sportsForm.fileList.length === 0) {
                this.$message({
                    message: '请上传运动凭证',
                    center: true,
                    type: 'warning'
                })
                return false
            }
            if(this.sportsForm.isOtherSport) {
                if(!this.sportsForm.other_sport) {
                    this.$message({
                        message: '请输入其他运动项目',
                        center: true,
                        type: 'warning'
                    })
                    return false
                }
            }
            // 运动日期验证
            var date = moment(this.sportsForm.date, 'YYYY-MM-DD')
            var now = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')
            if(date.diff(now, 'days') > 0) {
                this.$message({
                    message: '运动日期不能大于当前日期',
                    center: true,
                    type: 'warning'
                })
                return false
            }
            if(now.diff(date, 'days') > 7) {
                this.$message({
                    message: '运动日期只能补录7天',
                    center: true,
                    type: 'warning'
                })
                return false
            }

            // 运动时长限制
            // if(this.sportsForm.start && this.sportsForm.end) {
            //     const start=moment(this.sportsForm.start, 'hh:mm')
            //     const end=moment(this.sportsForm.end, 'hh:mm')
            //     const sport_minute=end.diff(start,'minute')
            //     if(sport_minute < 20) {
            //         this.$message({
            //             message: '运动时长少于20分钟',
            //             center: true,
            //             type: 'error'
            //         })
            //         return false
            //     }
            // }

            return true
        },
        async SaveSportsClock() {
            this.sportsForm.isLoading = true
            // axios.defaults.baseURL = '/api'
            // axios.defaults.headers.post['Content-Type'] = 'application/json'
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')
            const dto = {
                SPORT_NO: this.sportsForm.no,
                SPORT_NM: this.sportsForm.nm,
                SPORT_SEC_NO: this.sportsForm.sec_no,
                SPORT_SEC_NM: this.sportsForm.sec_nm,
                SPORT_DATE: moment(this.sportsForm.date).format('YYYY-MM-DD'),
                SPORT_TYPE: this.sportsForm.type,
                SPORT_MINUTE: this.sportsForm.sport_minute,
                SPORT_JOB: this.sportsForm.isOtherSport ? this.sportsForm.other_sport : this.sportsForm.sport,
                SPORT_KM: this.sportsForm.km,
                SPORT_START: this.sportsForm.isTimeType ? moment(this.sportsForm.date).format('YYYY-MM-DD') + ' ' + this.sportsForm.start : null,
                SPORT_END: this.sportsForm.isTimeType ? moment(this.sportsForm.date).format('YYYY-MM-DD') + ' ' + this.sportsForm.end : null,
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrSports/SaveSport', qs.stringify({
                // SPORT_NO: this.sportsForm.no,
                // SPORT_NM: this.sportsForm.nm,
                // SPORT_SEC_NO: this.sportsForm.sec_no,
                // SPORT_SEC_NM: this.sportsForm.sec_nm,
                // SPORT_DATE: moment(this.sportsForm.date).format('YYYY-MM-DD'),
                // SPORT_TYPE: this.sportsForm.type,
                // SPORT_JOB: this.sportsForm.isOtherSport ? this.sportsForm.other_sport : this.sportsForm.sport,
                // SPORT_KM: this.sportsForm.km,
                // SPORT_START: this.sportsForm.isTimeType ? moment(this.sportsForm.date).format('YYYY-MM-DD') + ' ' + this.sportsForm.start : null,
                // SPORT_END: this.sportsForm.isTimeType ? moment(this.sportsForm.date).format('YYYY-MM-DD') + ' ' + this.sportsForm.end : null,
                // IMAGE1: encodeURIComponent(this.sportsForm.image1)

                // IMAGE1: this.sportsForm.image1 

                dto,
                IMAGE1: encodeURIComponent(this.sportsForm.imageList[0]),
                IMAGE2: encodeURIComponent(this.sportsForm.imageList[1]),
                IMAGE3: encodeURIComponent(this.sportsForm.imageList[2]),
                FILENAME1: this.sportsForm.fileList[0],
                FILENAME2: this.sportsForm.fileList[1],
                FILENAME3: this.sportsForm.fileList[2]
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                console.log('打卡提交成功:', res.data.ResponseData)
                this.$message({
                    message: '打卡提交成功',
                    center: true,
                    type: 'success'
                })

                localStorage.setItem('PNL', res.data.ResponseData.SPORT_NO)
                localStorage.setItem('PNAME', res.data.ResponseData.SPORT_NM)
                localStorage.setItem('SEC_NO', res.data.ResponseData.SPORT_SEC_NO)
                localStorage.setItem('SEC_NM', res.data.ResponseData.SPORT_SEC_NM)

                setTimeout(function(){
                    window.location.href = '/sportsList'
                },1000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '打卡提交失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                this.$message({
                    message: '打卡提交失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.sportsForm.isLoading = false
        },
        async getPersonByPnl() {
            // axios.defaults.baseURL = '/api'
            // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SportsClock/PersonByPnl', qs.stringify({
                pnl: this.sportsForm.no
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.sportsForm.nm = res.data.ResponseData[0].pname
                this.sportsForm.sec_no = res.data.ResponseData[0].sec_no
                this.sportsForm.sec_nm = res.data.ResponseData[0].sec_nm
            }
        }
    },
    mounted() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.sportsForm.no = pnl
        }
        const pname = localStorage.getItem('PNAME')
        if(pname){
            this.sportsForm.nm = pname
        }
        const sport_sec_no = localStorage.getItem('SEC_NO')
        if(sport_sec_no){
            this.sportsForm.sec_no = sport_sec_no
        }
        const sport_sec_nm = localStorage.getItem('SEC_NM')
        if(sport_sec_nm){
            this.sportsForm.sec_nm = sport_sec_nm
        }
    }
}
</script>