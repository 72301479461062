import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const config = {
    state: {
        openid: '',
        access_token: '',
        menu_auth: [],
        on_job: true,
        check_info: {
            sec_no: '',
            odr_no: '',
            fid: ''
        },
        roomCheck_info: {
            b_no: '',
            f_no: ''
        }
    },
    getters: {
        getOpenId(state) {
            return state.openid
        },
        getAccessToken(state) {
            return state.access_token
        },
        getMenuAuth(state) {
            return state.menu_auth
        },
        getOnJob(state) {
            return state.on_job
        },
        getCheckInfo(state) {
            return state.check_info
        },
        getRoomCheckInfo(state) {
            return state.roomCheck_info
        }
    },
    mutations: {
        setOpenId(state, openid) {
            state.openid = openid
        },
        setAccessToken(state, access_token) {
            state.access_token = access_token
        },
        setMenuAuth(state, menu_auth) {
            state.menu_auth = menu_auth
        },
        setOnJob(state, on_job) {
            state.on_job = on_job
        },
        setCheckInfo(state, check_info) {
            state.check_info = check_info
        },
        setRoomCheckInfo(state, roomCheck_info) {
            state.roomCheck_info = roomCheck_info
        }
    },
    actions: {

    },
    modules: {
        
    }
}

export default new Vuex.Store(config)
