<template>
    <div>
        <el-card class="box-card">
            <el-row>
                <el-col :span="6" style="text-align: center">
                    <div class="block" @click="GetDetailInfo">
                        <el-avatar :size="60" :src="squareUrl"></el-avatar>
                    </div>
                </el-col>
                <el-col :span="14" style="text-align: left;padding-left: 20px">
                    <div class="demo-basic--circle" style="margin-top: 10px">
                        <div style="font-size: 15px" @click="GetDetailInfo">{{pnl}} - {{pname}}</div>
                        <div style="font-weight: bold;">
                            <el-link style="font-weight: bold;font-size: 18px" href="/detailInfo">{{this.nickname}}</el-link>
                        </div>
                    </div>
                </el-col>
                <!-- <el-col :span="4" style="padding-top: 8px;">
                    <el-button size="medium" icon="el-icon-user" @click="GetDetailInfo" circle></el-button>
                </el-col> -->
                <el-col :span="4" style="padding-top: 10px;">
                    <el-button size="medium" icon="el-icon-user" @click="GetDetailInfo" circle></el-button>
                    <!-- <el-button size="medium" icon="el-icon-close" @click="Logout" circle></el-button> -->
                </el-col>
            </el-row>
            <el-row :gutter="5" style="margin-top: 25px;">
                <el-col :span="7">工龄<br><span style="font-weight: bolder;">{{this.work_age}}</span></el-col>
                <el-col :span="10">职务<br><span style="font-weight: bolder;">{{this.zw_nm}}</span></el-col>
                <el-col :span="7">部门<br><span style="font-weight: bolder;">{{this.sec_nm}}</span></el-col>
            </el-row>
        </el-card>
        <div v-if="on_job">
            <el-row :gutter="20" style="margin-top: 15px;">
                <el-col :span="6">
                    <router-link to="/attendance">
                    <el-card class="card" border>
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/考勤.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">我的考勤</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/salary">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/回款查询.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">我的薪资</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/reward">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/商标转让.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">我的奖惩</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/guarantee">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/任务管控.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">我的保障</span>
                    </router-link> 
                </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 25px;">
                <el-col :span="6">
                    <router-link to="/proposal">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/部门会签.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">持续改善</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/questionList">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/站内信.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">我要提问</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/workOrderList">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/维修申请.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">工务维修</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/ehsCheckList">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/EHS.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">EHS</span>
                    </router-link> 
                </el-col>
                <!-- <el-col :span="6">
                    <router-link to="/healthyList">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/工作汇报.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">健康管理</span>
                    </router-link> 
                </el-col> -->
                <!-- <el-col :span="6">
                    <router-link to="/faceAuth">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/人事流程.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">离职申请</span>
                    </router-link> 
                </el-col> -->
            </el-row>
            <el-row :gutter="20" style="margin-top: 25px;">
                <!-- <el-col :span="6">
                    <router-link to="/changePassword">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/签到签退.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">修改密码</span>
                    </router-link> 
                </el-col> -->
                <el-col :span="6">
                    <router-link to="/sample">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/工作交接.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">样鞋借用</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/maintain">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/资产报修.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">设备保养</span>
                    </router-link> 
                </el-col>
            
                <el-col :span="6">
                    <router-link to="/inputOutput6">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/变更申请.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">投入产出</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/workInProcess">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/订单发货.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">在制明细</span>
                    </router-link> 
                </el-col>
            </el-row>
            <!-- <el-row :gutter="20" style="margin-top: 25px;">
                <el-col :span="6">
                    <router-link to="/pieceCapital">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/质量协同.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">员工件资</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/pieceCapitalStatus2">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/质量反馈.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">件资状况</span>
                    </router-link> 
                </el-col>
           
            </el-row> -->
            <!-- <el-row :gutter="20" style="margin-top: 25px;">
                <el-col :span="6">
                    <router-link to="/sampleCompletion">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/待接收.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">样品完工</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/sampleDelivery">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/工作汇报.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">样品交样</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/safetyPatrol">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/维修确认.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">安全巡查</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/EHSList">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/未开放.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">EHS系统</span>
                    </router-link> 
                </el-col>
            </el-row> -->
            <el-row :gutter="20" style="margin-top: 25px;">
                <el-col :span="6">
                    <router-link to="/preDispatch2">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/任务下达.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">部件接收</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/repairOrderList">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/资产报修.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">设备报修</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/checkList">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/资产报修.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">生产巡检</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/firstConfirm">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/维修确认.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">首件接收</span>
                    </router-link> 
                </el-col>
                <!-- <el-col :span="6">
                    <router-link to="/processMatching">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/自定义流程.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">工艺配套</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/processInProcess">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/代料申请.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">工艺在制</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/guarantee">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/任务管控.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">我的保障</span>
                    </router-link> 
                </el-col> -->
            </el-row>
             <!-- <el-row :gutter="20" style="margin-top: 25px; margin-bottom: 10px">
                <el-col :span="6">
                    <router-link to="/scenery">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/未开放.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">景观保养</span>
                    </router-link> 
                </el-col>
            </el-row> -->
            <el-row :gutter="20" style="margin-top: 25px; margin-bottom: 10px">
                <el-col :span="6">
                    <router-link to="/weekMenuList">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/用餐补助.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">餐厅菜谱</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/remoteClock">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/签到签退.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">远程打卡</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/roomCheckList">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/工厂检验.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">宿舍7S</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/waterElectricity">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/未开放.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">我的水电</span>
                    </router-link> 
                </el-col>
            </el-row>
            <!-- <el-row :gutter="20" style="margin-top: 25px; margin-bottom: 10px">
         
                <el-col :span="6">
                    <router-link to="/partProcess">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/用车申请.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">工艺车间</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/surfaceProcess">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/用车申请.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">帮面件资</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/surfaceProcessAudit">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/用车申请.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">帮面审核</span>
                    </router-link> 
                </el-col>
           </el-row> -->
           <el-row :gutter="20" style="margin-top: 25px; margin-bottom: 10px">
                <el-col :span="6">
                   <router-link to="/meetingList">
                   <el-card class="card">
                       <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/用车申请.png')" alt=""></el-image>
                   </el-card>
                   <span class="span" style="font-size: 13px">我的会议</span>
                   </router-link> 
                </el-col>
                <!-- <el-col :span="6">
                   <router-link to="/pieceCapitalLeave">
                   <el-card class="card">
                       <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/用车申请.png')" alt=""></el-image>
                   </el-card>
                   <span class="span" style="font-size: 13px">离职件资</span>
                   </router-link> 
                </el-col> -->
                <el-col :span="6">
                   <router-link to="/productInventory2">
                   <el-card class="card">
                       <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/用车申请.png')" alt=""></el-image>
                   </el-card>
                   <span class="span" style="font-size: 13px">成品库存</span>
                   </router-link> 
                </el-col>
                <el-col :span="6">
                   <router-link to="/inputOutput4">
                        <el-card class="card">
                            <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/用车申请.png')" alt=""></el-image>
                        </el-card>
                        <span class="span" style="font-size: 13px">线别预警</span>
                   </router-link> 
                </el-col>
                <el-col :span="6">
                   <router-link to="/secYcRecord">
                        <el-badge is-dot class="item">
                            <el-card class="card">
                                <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/用车申请.png')" alt=""></el-image>
                            </el-card>
                            <span class="span" style="font-size: 13px">线别异常</span>
                        </el-badge>
                   </router-link> 
                </el-col>
           </el-row>
           <el-row :gutter="20" style="margin-top: 25px; margin-bottom: 10px">
                <el-col :span="6">
                   <router-link to="/sampleManage">
                        <el-card class="card">
                            <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/用车申请.png')" alt=""></el-image>
                        </el-card>
                        <span class="span" style="font-size: 13px">样鞋管理</span>
                   </router-link> 
                </el-col>
            
                <el-col :span="6">
                   <router-link to="/sampleCompletion2">
                        <el-badge is-dot class="item">
                            <el-card class="card">
                                <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/用车申请.png')" alt=""></el-image>
                            </el-card>
                            <span class="span" style="font-size: 13px">样品完工</span>
                        </el-badge>
                   </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/equipment">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/维修确认.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">电脑车</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/blackList">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/领导会签.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">黑名单</span>
                    </router-link> 
                </el-col>
           </el-row>
            <!-- <el-row :gutter="20" style="margin-top: 25px; margin-bottom: 10px">
                <el-col :span="6">
                    <router-link to="/sampleCompletion">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/待接收.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">样品完工</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/sampleDelivery">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/工作汇报.png')" alt=""></el-image>
                    </el-card>
                    <span class="span">样品交样</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/activityList">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/维修确认.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">活动报名</span>
                    </router-link> 
                </el-col>
                <el-col :span="6">
                    <router-link to="/remoteClock">
                    <el-card class="card">
                        <el-image lazy class="image" fit="cover" :src="require('@/assets/menu/维修确认.png')" alt=""></el-image>
                    </el-card>
                    <span class="span" style="font-size: 13px">远程打卡</span>
                    </router-link> 
                </el-col>
            </el-row> -->
        </div>
        <el-result icon="error" title="您访问的资源不存在" subTitle="请点击关闭按钮，退出该页面；如果是返厂员工，请点击注册按钮，重新注册" v-if="!on_job">
            <template slot="extra">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-button type="primary" style="width: 100%" size="medium" @click="PageClose">关闭</el-button>
                    </el-col>
                    <!-- <el-col :span="12"> -->
                        <!-- <router-link to="/register"> -->
                            <!-- <el-button type="primary" style="width: 100%" size="medium" @click="Logout">注册</el-button> -->
                        <!-- </router-link> -->
                    <!-- </el-col> -->
                </el-row>
            </template>
        </el-result>
        <el-alert
            @click="toICP"
            class="icp" 
            title="闽ICP备18021135号"
            type="info"
            :closable="false"
            style="margin-top: 30px"
            center>
            <template slot='title'>
                <el-link @click="toICP" class="icp" :underline="false">
                    闽ICP备18021135号
                </el-link>
            </template>
        </el-alert>    
    </div>
</template>

<script>
import conf from '../conf'
import qs from 'qs'
import axios from 'axios'
import wx from 'weixin-js-sdk'
export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
            squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
            nickname: '',
            pnl: '',
            sec_nm: '',
            zw_nm: '',
            work_age: '',
            on_job: true,
            pname: ''
        }
    },
    methods: {
        async getOpenid(code) {
            // const res = await axios.get(conf.api_url + `/gateway/weixinapi/TokenAuthorize/OpenID?code=${code}`)
            // const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/OpenIDPost', qs.stringify({
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/AccessToken', qs.stringify({
                code: code
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                localStorage.setItem('openid', res.data.ResponseData.OPENID)
                localStorage.setItem('access_token', res.data.ResponseData.ACCESS_TOKEN)
                // this.$store.commit('setOpenId', res.data.ResponseData.OPENID)
                // this.$store.commit('setAccessToken', res.data.ResponseData.ACCESS_TOKEN)
                window.location.replace(conf.server_url)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async getAccessCode() {
            const state = ''
            // const state = conf.api_url + '/gateway/weixinapi/TokenAuthorize/WeiXinAuth'
            const scope = 'snsapi_userinfo'
            // const redirectUrl = encodeURIComponent(conf.server_url + '/gateway/weixinapi/TokenAuthorize/WeiXinAuth')
            const redirectUrl = encodeURIComponent(conf.server_url)
            // const redirectUrl = encodeURIComponent(conf.server_url + '/jump') // http://wx.qzhqxf.com/jump
            // console.log('redirectUrl:', redirectUrl)
            const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${conf.weixinInfo.appid}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
            // console.log('url:' + url)
            // window.location.href = url
            window.location.replace(url)
        },
        async getUserInfo(openid) {
            // const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/CheckExpires', qs.stringify({
            //     openid: openid,
            // }))
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/UserInfo', qs.stringify({
                access_token: localStorage.getItem('access_token'),
                // access_token: this.$store.getters.getAccessToken,
                openid: openid
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.squareUrl = res.data.ResponseData.HEADIMGURL
                this.nickname = res.data.ResponseData.NICKNAME
                localStorage.setItem('nickname', this.nickname)
                localStorage.setItem('headimgurl', this.squareUrl)
                this.CheckFirstLogin(openid)
            }else if(res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                localStorage.setItem('openid', '')
                // this.$store.commit('setOpenId', '')
                this.getAccessCode()
            }
        },
        async CheckFirstLogin(openid) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxUsers/CheckOpenid', qs.stringify({
                openid
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                window.location.href = '/register'
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 0) {
                // 用openid取员工信息
                localStorage.setItem('PNL', res.data.ResponseData[0].PNL)
                localStorage.setItem('PNAME', res.data.ResponseData[0].PNAME)
                localStorage.setItem('PHONE', res.data.ResponseData[0].PHONE)
                localStorage.setItem('SEC_NO', res.data.ResponseData[0].SECNO)
                localStorage.setItem('SEC_NM', res.data.ResponseData[0].SECNM)
            }
        },
        GetDetailInfo() {
            window.location.href = '/detailInfo'
        },
        Logout() {
            localStorage.setItem('PNL', '')
            localStorage.setItem('PNAME', '')
            localStorage.setItem('PHONE', '')
            localStorage.setItem('SEC_NO', '')
            localStorage.setItem('SEC_NM', '')
            // localStorage.setItem('openid', '')
            // localStorage.setItem('access_token', '')
            window.location.href = '/register'
            // this.$store.commit('setOpenId', '')
            // this.$store.commit('setAccessToken', '')
        },
        async getPersonByPnl() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsPersons/PersonInfo', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.sec_nm = res.data.ResponseData[0].SEC_NM
                this.work_age = '约 ' + res.data.ResponseData[0].WORK_AGE + ' 年'
                this.zw_nm = res.data.ResponseData[0].ZW_NM
                // this.$store.commit('setOnJob', !res.data.ResponseData[0].DDATE ? true : false)
                this.on_job = !res.data.ResponseData[0].DDATE ? true : false
                if(this.pnl === '1653' || this.pnl === '7735') { // 屏蔽
                    this.on_job = false
                }
            }
        },
        async getMenuAuth() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxMenu/WxMenuAuth', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.$store.commit('setMenuAuth', res.data.ResponseData)
            }
        },
        PageClose() {
            wx.closeWindow()
        },
        toICP() {
            window.location.href = 'https://beian.miit.gov.cn/#/Integrated/index'
        }
    },
    created() {
        // console.log('this is Home') // dev
        // console.log('this.$route.query.openid', this.$route.query.openid) // dev
        
        if(!this.$route.query.openid){ // dev -----
            // 非跳转
            const openid = localStorage.getItem('openid') 
            // const openid = this.$store.getters.getOpenId
            if(!openid) {
                if(this.$route.query.code) {
                    this.getOpenid(this.$route.query.code)
                    // 2021-07-08 测试可以，部署不行
                    // const url = `${conf.api_url}/gateway/weixinapi/TokenAuthorize/WeiXinAuthHistory?code=${this.$route.query.code}`
                    // const url = `http://www.qzhqxf.com:17563/gateway/weixinapi/TokenAuthorize/WeiXinAuthHistory?code=${this.$route.query.code}`
                    // console.log('url:' + url)
                    // window.location.replace(url)
                }else {
                    this.getAccessCode()
                }
            }else{
                this.getUserInfo(openid)
            }
        }else{ // 跳转 传 openid
            localStorage.setItem('openid', this.$route.query.openid)
            // this.$store.commit('setOpenId', this.$route.query.openid)
            this.getUserInfo(this.$route.query.openid)
        } // dev ----------------
    },
    mounted() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            this.getPersonByPnl()
            // this.getMenuAuth() // 菜单权限
        }
        const pname = localStorage.getItem('PNAME')
        if(pname){
            this.pname = pname
        }
    }
}
</script>

<style scoped>
    a:link { 
        text-decoration: none;
        color: #606266;
    }
    a:visited {
        text-decoration: none;
        color: #606266;
    }
    a:active { 
        text-decoration: blink;
        color: #606266;
    }
    .box-card {
        margin: 0px;
        padding: 0px 0px 5px 0px;
        background-color: #B3D8FF;
    }
    .card {
        border: none;
        margin-bottom: 5px;
        border-radius: 10px;
    }
    .card_gray {
        /* 图片变灰 */  
        background-color: #E4E7ED;
    }
    .image {
        margin: -20px;
        /* border-radius: 5px */
    }
    .image_gray {
        /* 图片变灰 */
        -webkit-filter: grayscale(1); 
        filter: gray; /* IE6-9 */    
        filter: grayscale(1); /* W3C */  
    }
    .span {
        font-size: 14px; 
        font-weight: 600;
    }
    .span_gray {
        /* 图片变灰 */
        color: #E4E7ED;  
    }
    .icp {
        font-size: 12px;
        color: #606266;
    }
</style>