<template>
    <div>
        <el-card shadow="always" v-loading="data_count_loading">
            <el-row :gutter="20" style="padding-bottom: 10px; padding-top: 5px">
                <el-col :span="6" v-for="item in TagDatas" :key="item.tag_type">
                    <el-badge :value="item.badge_value === '0' ? null : item.badge_value" class="item"> 
                        <el-tag
                            :type="item.tag_type"
                            @click="tag_click(item)"
                            :effect="item.tag_effect">
                            {{item.label}}
                        </el-tag>
                    </el-badge>
                </el-col>
            </el-row>
        </el-card>
        <div id="card_detail">
            <el-empty v-if="work_list.length === 0" description="没有数据"></el-empty>
            <el-card :id="item.DB_ID" shadow="always" v-for="(item, index) in work_list" :key="index" class="box-card" v-loading="loading">
                <!-- <el-row>
                    <el-col :span="12" style="text-align: left">单据编号</el-col>
                    <el-col :span="12" style="text-align: right">{{item.FBILLNO}}</el-col>
                </el-row>
                <el-divider/> -->
                <el-row>
                    <el-col :span="6" style="text-align: left">单据状态</el-col>
                    <el-col :span="18" :class="getClass(item.FQUESTATUS)" style="text-align: right">
                        {{getStatus(item.FQUESTATUS)}}</el-col>
                </el-row>
                <el-divider/>
                <el-row>
                    <el-col :span="6" style="text-align: left">问题描述</el-col>
                    <el-col :span="18" style="text-align: right">{{item.FQUEDESCRIPTION}}</el-col>
                </el-row>
                <el-divider/>
                <el-row>
                    <el-col :span="6" style="text-align: left">维修区域</el-col>
                    <el-col :span="18" style="text-align: right">{{item.FQUEADDRESS}}
                        {{item.BILL_TYPE === 'Dorm' ? '，宿舍：' + item.DORMCODE : ''}}</el-col>
                </el-row>
                <el-divider/>
                <el-row>
                    <el-col :span="6" style="text-align: left">单据日期</el-col>
                    <el-col :span="18" style="text-align: right">{{item.FSUBMITTIME}}</el-col>
                </el-row>
                <el-divider/>
                <el-row>
                    <el-col :span="6" style="text-align: left">联系信息</el-col>
                    <el-col :span="18" style="text-align: right">{{item.FSUBMITNAME}}，
                        <a :href="'tel:' + item.FQUEMOBILE">
                            <i class="el-icon-mobile-phone"></i>{{item.FQUEMOBILE}}
                        </a>
                    </el-col>
                </el-row>
                <el-divider v-if="item.HAVE_IMAGE !== 0" />
                <el-row v-if="item.HAVE_IMAGE !== 0">
                    <el-col :span="6" style="text-align: left">问题图片</el-col>
                    <el-col :span="18" style="text-align: right">
                        <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.ID)">
                            <i class="el-icon-picture-outline"></i> 点击查看
                        </el-link>
                    </el-col>
                </el-row>
                <el-divider v-if="item.FEXPECTTIME !== null" />
                <el-row v-if="item.FEXPECTTIME !== null">
                    <el-col :span="6" style="text-align: left">预估时间</el-col>
                    <el-col :span="18" style="text-align: right;">{{item.FEXPECTTIME}}</el-col>
                </el-row>
                <el-divider v-if="item.FSERVICEPS !== null" />
                <el-row v-if="item.FSERVICEPS !== null">
                    <el-col :span="6" style="text-align: left">工务评价</el-col>
                    <el-col :span="18" style="text-align: right; font-weight: bolder">
                        <el-rate
                            v-model="item.FSERVICEPS"
                            disabled
                            show-score
                            text-color="#ff9900">
                        </el-rate>
                    </el-col>
                </el-row>
                <el-divider v-if="isManager && item.FQUESTATUS === 0"/>
                <el-row :gutter="20" v-if="isManager && item.FQUESTATUS === 0">
                    <el-col :span="12">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="success" 
                            @click="ToForward(item)">单据转办</el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="primary" 
                            @click="updateStatus(item.DB_ID, 1)">单据确认</el-button>
                    </el-col>
                </el-row>
                <el-divider v-if="isManager && item.FQUESTATUS === 1 && item.FEXPECTTIME === null"/>
                <el-row :gutter="10" v-if="isManager && item.FQUESTATUS === 1 && item.FEXPECTTIME === null">
                    <el-col :span="12">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="primary" 
                            @click="AddExpectDiolog(item.DB_ID)">预计完成时间</el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="success" 
                            @click="doneExpectDiolog(item.DB_ID)">单据完成</el-button>
                    </el-col>
                </el-row>
                <el-divider v-if="isManager && item.FQUESTATUS === 1 && item.FEXPECTTIME !== null"/>
                <el-row v-if="isManager && item.FQUESTATUS === 1 && item.FEXPECTTIME !== null">
                    <el-col :span="24">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="success" 
                            @click="updateStatus(item.DB_ID, 2)">单据完成</el-button>
                    </el-col>
                </el-row>
                <!-- <el-row v-if="isManager">
                    <el-col :span="12">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            :type="item.FQUESTATUS < 1 ? 'primary' : 'info'" 
                            :disabled="item.FQUESTATUS < 1 ? false: true"
                            @click="updateStatus(item, 1)">单据确认</el-button>
                    </el-col>
                    <el-col :span="12" style="text-align: right">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            :type="item.FQUESTATUS < 2 ? 'success' : 'info'" 
                            :disabled="item.FQUESTATUS < 2 ? false: true"
                            @click="updateStatus(item, 2)">单据完成</el-button>
                    </el-col>
                </el-row> -->
                <el-divider v-if="item.FQUESTATUS === 2 && item.FSUBMITUCODE === pnl ? true : false"/>
                <el-row v-if="item.FQUESTATUS === 2 && item.FSUBMITUCODE === pnl ? true : false">
                    <el-button 
                        size="small" 
                        style="width: 100%" 
                        type="warning" 
                        @click="publicEvaluateDiolog(item.DB_ID)">发表评价</el-button>
                </el-row>
            </el-card>
        </div>
        <el-row style="margin-top: 20px;margin-bottom: 20px;" v-if="isManager">
            <el-col :span="24">
                <router-link to="/workOrder">
                    <el-button style="width: 100%" type="success">添加工务维修</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-row style="margin-top: 20px;margin-bottom: 20px;" v-if="!isManager">
            <el-col :span="24">
                <router-link to="/workOrder">
                    <el-button style="width: 100%" type="primary">添加工务维修</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-dialog title="预计维修完成时间" :visible.sync="finishDialogFormVisible" width="80%">
            <el-form :model="expectForm" size="mini" label-width="40px">
                <el-form-item label="日期">
                    <el-date-picker
                        size="mini"
                        align="center"
                        style="width: 100%"
                        v-model="expectForm.expect_date"
                        type="date"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="时间">
                    <el-time-select
                        size="mini"
                        style="width: 100%"
                        v-model="expectForm.expect_time"
                        :picker-options="{
                            start: '08:00',
                            step: '00:30',
                            end: '17:30'
                        }"
                        placeholder="选择时间">
                    </el-time-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="finishDialogFormVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="AddExpect">提交</el-button>
            </div>
        </el-dialog>
        <el-dialog title="单据完成" :visible.sync="doneDialogFormVisible" width="80%">
            <el-form :model="doneForm" ref="doneForm" :rules="rules" size="mini" label-width="55px">
                <el-form-item label="备注" prop="done_reason">
                    <el-input
                        type="textarea"
                        :rows="3"
                        placeholder="无法及时修复，需列明原因"
                        v-model="doneForm.done_reason">
                    </el-input>
                </el-form-item>
                <el-form-item label="凭证" prop="fileList">
                    <el-upload 
                        action='' 
                        ref="upload"
                        multiple
                        :on-change="getFile" 
                        :limit="3" 
                        :on-remove="handleRemove"
                        list-type="picture"
                        :auto-upload="false" 
                        :accept="'image/*'"
                        :on-exceed="handleExceed"
                        style="width: 100%;text-align: left;" >
                        <el-button size="small" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="doneDialogFormVisible = false">取消</el-button>
                <el-button size="mini" type="success" @click="SubmitForm('doneForm')">提交</el-button>
            </div>
        </el-dialog>
        <el-dialog title="我的评价" :visible.sync="dialogFormVisible" width="80%">
            <el-form :model="serviceForm" size="mini" label-width="70px">
                <el-form-item label="工单评分">
                    <el-rate v-model="serviceForm.rate" show-text></el-rate>
                </el-form-item>
                <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="您的评价将有助于我们持续改善"
                    maxlength="50"
                    show-word-limit
                    v-model="serviceForm.rate_text">
                </el-input>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="publicEvaluate">提交</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="imageDialogTableVisible" size="mini" width="90%">
            <span class="dialog-footer">
                <el-image
                    :key="item.DB_ID"
                    v-for="item in imageList"
                    lazy
                    style="width: 100%;"
                    :src="item.path"
                    fit="fill">
                    <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                    </div>
                    <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="mini" @click="imageDialogTableVisible = false">关闭</el-button>
            </span>
        </el-dialog>
        <el-dialog title="工务转办" :visible.sync="forwardDialogFormVisible" width="90%">
            <el-form :model="forwardForm" size="small" label-width="70px">
                <el-form-item label="转办人员" prop="forward_pnl" style="text-align: left;font-weight: bold">
                    <el-select v-model="forwardForm.forward_pnl" placeholder="请选择转办人员" 
                        size="small" style="width: 100%">
                        <el-option v-for="item in forwardForm.persons" :key="item.value" :value="item.value" :label="item.label">
                            <span style="float: left">{{ item.label }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="forwardDialogFormVisible = false">取消</el-button>
                <el-button size="mini" type="success" @click="forward">转办</el-button>
            </div>
        </el-dialog>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import moment from 'moment'
import { compressImage } from '../../plugin/compress.js'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            work_list: [],
            TagDatas: [
                {badge_value: '0', tag_type: 'primary', tag_effect: 'dark', label: '待确认', type: '0'},
                {badge_value: '0', tag_type: 'success', tag_effect: 'plain', label: '待完成', type: '1'},
                {badge_value: '0', tag_type: 'warning', tag_effect: 'plain', label: '待评价', type: '2'},
                {badge_value: '0', tag_type: 'danger', tag_effect: 'plain', label: '已完成', type: '4'}
            ],
            QueStatus: [
                {label: '0', status: '未确认'},
                {label: '1', status: '已确认'},
                {label: '2', status: '已完成'},
                {label: '3', status: '未完成'},
                {label: '4', status: '已评价'},
                {label: '-1', status: '已取消'}
            ],
            pnl: '',
            isManager: false,
            colclass: 'col-info',
            dialogFormVisible: false,
            finishDialogFormVisible: false,
            serviceForm: {
                rate: 5,
                rate_text: '',
            },
            expectForm: {
                expect_date: moment().format('YYYY-MM-DD'),
                expect_time: ''
            },
            currentDB_ID:'',
            data_count_loading: false,
            loading: false,
            imageDialogTableVisible: false,
            imageList: [],
            doneDialogFormVisible: false,
            doneForm: {
                done_reason: '',
                fileList: [],
                imageList: []
            },
            forwardDialogFormVisible: false,
            forwardForm: {
                item: {},
                forward_pnl: '',
                persons: [{
                    label: '王向东',
                    value: '3578'
                },{
                    label: '潘清峰',
                    value: '1179'
                },{
                    label: '尤志辉',
                    value: '51964'
                },{
                    label: '严沾明',
                    value: '6106'
                },{
                    label: '吴伟航',
                    value: '38474'
                }]
            },
            rules:{
                fileList: [
                    {required: true, message: '请上传完成凭证', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CustomValidate()){
                    this.doneExpect()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        CustomValidate() {
            if(this.doneForm.fileList.length === 0) {
                this.$message({
                    message: '请上传完成凭证',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
                return false
            }
            return true
        },
        ShowImages(id) {
            this.GetImagesByID(id)
        },
        async GetImagesByID(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        // this.imageList.push({
                        //     id: item.DB_ID,
                        //     path: conf.image_domain + item.FImgPath
                        // })
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                           path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
                console.log('this.imageList', this.imageList)
            }
        },
        async GetDataCounts() {
            this.data_count_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CSWorkorders/WorkOrderListCounts', qs.stringify({
                pnl: this.isManager ? '' : this.pnl,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                res.data.ResponseData.map(item => {
                    this.TagDatas.forEach(el => {
                        if(el.type === item.FQueStatus.toString()) {
                            el.badge_value = item.FQueStatusCount === 0 ? null : item.FQueStatusCount
                        }
                    })
                })
            }else {
                this.$message({
                    message: '获取工务维修数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.data_count_loading = false
        },
        tag_click(item) {
            this.TagDatas.forEach(el => {
                if(item.tag_type === el.tag_type) {
                    el.tag_effect = 'dark'
                    this.getDataByType(el.type)
                } else {
                    el.tag_effect = 'plain'
                }
            })
        },
        async getDataByType(type) {
            this.loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CSWorkorders/WorkOrderList', qs.stringify({
                pnl: this.isManager ? '' : this.pnl,
                type: type
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.work_list = res.data.ResponseData
            }else {
                this.$message({
                    message: '获取工务维修数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.loading = false
        },
        getStatus(FQUESTATUS) {
            let status = ''
            this.QueStatus.map(item => {
                if(item.label === FQUESTATUS.toString()) {
                    status = item.status
                }
            })
            return status
        },
        getClass(FQUESTATUS) {
            if(FQUESTATUS === 0) {
                return this.colclass = 'col-danger'
            }else if(FQUESTATUS === 2) {
                return this.colclass = 'col-success'
            }else if(FQUESTATUS === 1){
                return this.colclass = 'col-primary'
            }
        },
        async updateStatus(DB_ID, FQUESTATUS) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CSWorkorders/UpdateStatus', qs.stringify({
                DB_ID, 
                FSUBMITUCODE: this.pnl, 
                FQUESTATUS,
                FCANCELREASON: this.doneForm.done_reason
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.$message({
                    message: FQUESTATUS === 1 ? '维修单已确定！' : '维修单已完成！',
                    center: true,
                    type: 'success'
                })
                this.work_list.forEach(el => {
                    if(el.DB_ID === DB_ID) {
                        el.FQUESTATUS = FQUESTATUS
                    }
                })
            }
        },
        AddExpectDiolog(DB_ID) {
            this.finishDialogFormVisible = true
            this.currentDB_ID = DB_ID
            this.expectForm.expect_time = ''
        },
        doneExpectDiolog(DB_ID) {
            this.doneDialogFormVisible = true
            this.currentDB_ID = DB_ID
            this.doneForm.done_reason = ''
        },
        async AddExpect() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CSWorkorders/UpdateStatus', qs.stringify({
                DB_ID: this.currentDB_ID, 
                // FBILLNO: this.currentBillNo, 
                FQUESTATUS: 1,
                FSUBMITUCODE: this.pnl, 
                FEXPECTTIME: this.expectForm.expect_date + ' ' + this.expectForm.expect_time
            }))  
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.finishDialogFormVisible = false
                this.$message({
                    message: '预估时间成功！',
                    center: true,
                    type: 'success'
                })
                this.work_list.forEach(el => {
                    // if(el.FBILLNO === this.currentBillNo) {
                    //     el.FEXPECTTIME = this.expectForm.expect_date + ' ' + this.expectForm.expect_time + ':00'
                    // }
                    if(el.DB_ID === this.currentDB_ID) {
                        el.FEXPECTTIME = this.expectForm.expect_date + ' ' + this.expectForm.expect_time + ':00'
                    }
                })
            }
        },
        doneExpect() {
            this.updateStatus(this.currentDB_ID, 2)
            this.doneDialogFormVisible = false
        },
        publicEvaluateDiolog(DB_ID) {
            this.dialogFormVisible = true
            this.currentDB_ID = DB_ID
        },
        async publicEvaluate() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CSWorkorders/UpdateStatus', qs.stringify({
                DB_ID: this.currentDB_ID, 
                // FBILLNO: this.currentBillNo, 
                FSUBMITUCODE: this.pnl, 
                FQUESTATUS: 4,
                FSERVICEPS: this.serviceForm.rate,
                FSERVICEPSNOTE: this.serviceForm.rate_text
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.dialogFormVisible = false
                this.$message({
                    message: '评价发表成功！',
                    center: true,
                    type: 'success'
                })
                // this.work_list.forEach(el => {
                //     if(el.FBILLNO === this.currentBillNo) {
                //         el.FQUESTATUS = 4
                //         el.FSERVICEPS = this.serviceForm.rate
                //     }
                // })
                this.work_list.forEach(el => {
                    if(el.DB_ID === this.currentDB_ID) {
                        el.FQUESTATUS = 4
                        el.FSERVICEPS = this.serviceForm.rate
                    }
                })
            }
        },
        ToForward(item) {
            this.forwardForm.item = item
            this.forwardDialogFormVisible = true
        },
        async forward() {
            this.forwardForm.item.FORWARD_PNL = this.forwardForm.forward_pnl
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CSWorkorders/Forward', qs.stringify({
                dto: this.forwardForm.item
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$message({
                    message: '转办成功！',
                    center: true,
                    type: 'success'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.forwardDialogFormVisible = false
        },
        async GetAdmin() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CSWorkorders/WorkOrderAdmins')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.map(item => {
                    if(this.pnl === item.user_no) {
                        // console.log(item)
                        this.isManager = true
                    }
                })
            }

            this.GetDataCounts()
            this.getDataByType('0')
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
        getFile(file, fileList) {
            this.doneForm.fileList = []
            this.doneForm.imageList = []
            fileList.forEach(item => {
                this.doneForm.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.doneForm.imageList.push(result)
                })
                // console.log('this.doneForm.imageList', this.doneForm.imageList)
            })
        },
        handleRemove(file, fileList) {
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.doneForm.fileList = []
            this.doneForm.imageList = []
            fileList.forEach(item => {
                this.doneForm.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.doneForm.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        },
    },
    updated() {
        if(window.location.hash) {
            document.querySelector(window.location.hash).scrollIntoView(true)
        }
    },
    mounted() {
        this.GetAdmin() 
        this.GetSignature()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
            // this.pnl = '65486' // dev
            // this.pnl = '38432' // dev
        }
    }
}
</script>

<style scoped>
    .box-card{
        margin-top: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 13px;
        /* border-radius: 10px;  */
    } 
    .col-danger {
        color: #F56C6C;
        font-weight: bold;
    }
    .col-success {
        color: #67C23A;
        font-weight: bold;
    }
    .col-primary {
        color: #409EFF;
        font-weight: bold;
    }
</style>