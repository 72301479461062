<template>
    <div>
        <el-card shadow="always" v-loading="data_count_loading">
            <el-row :gutter="10" style="padding-bottom: 10px;padding-top: 5px">
                <el-col :span="6" v-for="item in TagDatas" :key="item.tag_type">
                    <el-badge :value="item.badge_value === '0' ? null : item.badge_value" class="item"> 
                        <el-tag
                            style="width: 100%"
                            :type="item.tag_type"
                            @click="tag_click(item)"
                            :effect="item.tag_effect">
                            {{item.label}}
                        </el-tag>
                    </el-badge>
                </el-col>
            </el-row>
        </el-card>
        <div id="card_detail">
            <el-empty v-if="repairList.length === 0" description="没有数据"></el-empty>
            <el-card :id="item.DB_ID" shadow="always" v-for="(item, index) in repairList" :key="index" class="box-card" v-loading="loading">
                <!-- <el-row> -->
                    <!-- <el-col :span="6" style="text-align: left">单据状态</el-col> -->
                    <!-- <el-col :span="18" :class="getClass(item.FQUESTATUS)" style="text-align: right">
                        {{getStatus(item.FQUESTATUS)}}</el-col> -->
                <!-- </el-row> -->
                <!-- <el-divider/> -->
                <el-row>
                    <el-col :span="6" style="text-align: left">设备信息</el-col>
                    <el-col :span="18" style="text-align: right">{{item.FASS_NO}} - {{item.FASS_NM}} <br> {{item.FASS_GG}}，{{ item.ADDR }}</el-col>
                </el-row>
                <el-divider/>
                <el-row>
                    <el-col :span="6" style="text-align: left">维修项目</el-col>
                    <el-col :span="18" style="text-align: right">
                        {{item.FFIXITEM_NAME}}
                    </el-col>
                </el-row>
                <el-divider/>
                <el-row>
                    <el-col :span="6" style="text-align: left">单据日期</el-col>
                    <el-col :span="18" style="text-align: right">{{item.FSUBMIT_TIME}}</el-col>
                </el-row>
                <el-divider/>
                <el-row>
                    <el-col :span="6" style="text-align: left">联系信息</el-col>
                    <el-col :span="18" style="text-align: right">{{item.FSUBMIT_PNAME}},
                        <a :href="'tel:' + item.PHONE">
                            <i class="el-icon-mobile-phone"></i>{{item.PHONE}}
                        </a>
                    </el-col>
                </el-row>
                <el-divider/>
                <el-row>
                    <el-col :span="6" style="text-align: left">问题描述</el-col>
                    <el-col :span="18" style="text-align: right">
                        {{item.SECNM}}，{{item.FDESCRIPTION}}
                    </el-col>
                </el-row>
                <el-divider v-if="item.HAVE_IMAGE !== 0" />
                <el-row v-if="item.HAVE_IMAGE !== 0">
                    <el-col :span="6" style="text-align: left">配件信息</el-col>
                    <el-col :span="18" style="text-align: right">
                        <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.DB_ID)">
                            <i class="el-icon-picture-outline"></i> {{item.FPART_NM}}
                        </el-link>
                    </el-col>
                </el-row>
                <el-divider v-if="isManager && item.FSTATUS === 0"/>
                <el-row v-if="isManager && item.FSTATUS === 0">
                    <el-col :span="24">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="primary" 
                            @click="updateStatus(item, 1)">开始维修</el-button>
                    </el-col>
                </el-row>
                <el-divider v-if="isManager && item.FSTATUS === 1"/>
                <el-row v-if="isManager && item.FSTATUS === 1">
                    <el-col :span="24">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="success" 
                            :disabled="item.DB_CREATE_USERNO === 'ESOP'"
                            @click="showDialog(item)">维修完成</el-button>
                    </el-col>
                </el-row>
                <el-divider v-if="item.FSTATUS === 2 && item.FSUBMIT_PNL === pnl ? true : false"/>
                <el-row v-if="item.FSTATUS === 2 && item.FSUBMIT_PNL === pnl ? true : false">
                    <el-button 
                        size="small" 
                        style="width: 100%" 
                        type="warning" 
                        @click="publicEvaluateDiolog(item)">发表评价</el-button>
                </el-row>
                <!-- <el-divider v-if="isManager && item.FQUESTATUS === 1 && item.FEXPECTTIME === null"/>
                <el-row :gutter="10" v-if="isManager && item.FQUESTATUS === 1 && item.FEXPECTTIME === null">
                    <el-col :span="12">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="primary" 
                            @click="AddExpectDiolog(item.DB_ID)">预计完成时间</el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="success" 
                            @click="updateStatus(item, 2)">单据完成</el-button>
                    </el-col>
                </el-row> -->
                <!-- <el-divider v-if="isManager && item.FQUESTATUS === 1 && item.FEXPECTTIME !== null"/>
                <el-row v-if="isManager && item.FQUESTATUS === 1 && item.FEXPECTTIME !== null">
                    <el-col :span="24">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="success" 
                            @click="updateStatus(item, 2)">单据完成</el-button>
                    </el-col>
                </el-row>
                <el-divider v-if="item.FQUESTATUS === 2 && item.FSUBMITUCODE === pnl ? true : false"/>
                <el-row v-if="item.FQUESTATUS === 2 && item.FSUBMITUCODE === pnl ? true : false">
                    <el-button 
                        size="small" 
                        style="width: 100%" 
                        type="warning" 
                        @click="publicEvaluateDiolog(item.DB_ID)">发表评价</el-button>
                </el-row> -->
            </el-card>
        </div>
        <el-row style="margin-top: 20px;margin-bottom: 20px;">
            <el-col :span="24">
                <router-link to="/repairOrder">
                    <el-button style="width: 100%" type="primary">设备报修</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-dialog title="维修完成" :visible.sync="doneDialogFormVisible" width="80%">
            <el-form :model="doneForm" ref="doneForm" size="mini" style="font-size: 10px;" label-width="70px">
                <el-form-item label="配件更换" prop="is_part">
                    <el-row>
                        <el-col :span="4">
                            <el-checkbox v-model="doneForm.is_part"></el-checkbox>
                        </el-col>
                        <el-col :span="19" style="text-align: right; color: #F56C6C;font-weight: bold;font-size: 12px"> 
                            无需更换配件，可直接提交
                        </el-col> 
                    </el-row>
                </el-form-item>
                <el-form-item v-if="doneForm.is_part" label="配件名称" prop="part_nm">
                    <el-input
                        type="text"
                        placeholder="请输入配件名称"
                        v-model="doneForm.part_nm">
                    </el-input>
                </el-form-item>
                <el-form-item v-if="doneForm.is_part" label="配件图片" prop="fileList">
                    <el-upload 
                        action='' 
                        ref="upload"
                        multiple
                        :on-change="getFile" 
                        :limit="3" 
                        :on-remove="handleRemove"
                        list-type="picture"
                        :auto-upload="false" 
                        :accept="'image/*'"
                        :on-exceed="handleExceed"
                        style="width: 100%;text-align: left;" >
                        <el-button size="small" type="info">上传图片<i class="el-icon-upload el-icon--right"></i></el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="doneDialogFormVisible = false">取消</el-button>
                <el-button size="mini" type="success" @click="doneExpect">提交</el-button>
            </div>
        </el-dialog>
        <el-dialog title="我的评价" :visible.sync="rateDialogFormVisible" width="80%">
            <el-form :model="rateForm" size="mini" label-width="70px">
                <el-form-item label="工单评分">
                    <el-rate v-model="rateForm.rate_score" :max=3 :texts="rateForm.rate_texts" show-text></el-rate>
                </el-form-item>
                <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="您的评价将有助于我们持续改善"
                    maxlength="50"
                    show-word-limit
                    v-model="rateForm.rate_note">
                </el-input>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="rateDialogFormVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="publicRate">提交</el-button>
            </div>
        </el-dialog>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import { compressImage } from '../../plugin/compress.js'
import moment from 'moment'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            data_count_loading: false,
            TagDatas: [
                {badge_value: '0', tag_type: 'primary', tag_effect: 'dark', label: '待维修', type: '0'},
                {badge_value: '0', tag_type: 'danger', tag_effect: 'plain', label: '维修中', type: '1'},
                {badge_value: '0', tag_type: 'warning', tag_effect: 'plain', label: '待评价', type: '2'},
                {badge_value: '0', tag_type: 'success', tag_effect: 'plain', label: '已完成', type: '3'}
            ],
            repairList: [],
            loading: false,
            isManager: false,
            pnl: '',
            doneDialogFormVisible: false,
            imageList: [],
            doneForm: {
                is_part: false,
                part_nm: '',
                fileList: [],
                imageList: []
            },
            rateDialogFormVisible: false,
            rateForm: {
                rate_score: null,
                rate_note: '',
                rate_texts: ['不满意','一般','满意'],
            }
        }
    },
    methods: {
        publicEvaluateDiolog(item) {
            this.rateDialogFormVisible = true
            this.item = item
        },
        publicRate() {
            if(!this.rateForm.rate_score) {
                this.$message({
                    message: '请评价',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
                return
            }

            this.updateStatus(this.item, 3)
            this.rateDialogFormVisible = false
        },
        ShowImages(id) {
            this.GetImagesByID(id)
        },
        async GetImagesByID(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                           path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
                console.log('this.imageList', this.imageList)
            }
        },
        showDialog(item) {
            this.doneDialogFormVisible = true
            this.item = item
        },
        doneExpect() {
            if(this.doneForm.is_part) {
                if(!this.doneForm.part_nm) {
                    this.$message({
                        message: '请输入配件名称',
                        center: true,
                        type: 'error',
                        duration: 1000
                    })
                    return
                }
                if(this.doneForm.fileList.length === 0) {
                    this.$message({
                        message: '请上传配件图片',
                        center: true,
                        type: 'error',
                        duration: 1000
                    })
                    return
                }
            }

            this.updateStatus(this.item, 2)
            this.doneDialogFormVisible = false
        },
        async GetDataCounts() {
            this.data_count_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrRepairOrders/RepairOrderListCounts', qs.stringify({
                pnl: this.pnl,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                res.data.ResponseData.map(item => {
                    this.TagDatas.forEach(el => {
                        if(el.type === item.FSTATUS.toString()) {
                            el.badge_value = item.FSTATUSCOUNT === 0 ? null : item.FSTATUSCOUNT
                        }
                    })
                })
            }else {
                this.$message({
                    message: '获取设备报修数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }

            this.data_count_loading = false
        },
        tag_click(item) {
            this.TagDatas.forEach(el => {
                if(item.tag_type === el.tag_type) {
                    el.tag_effect = 'dark'
                    this.getDataByType(el.type)
                } else {
                    el.tag_effect = 'plain'
                }
            })
        },
        async getDataByType(type) {
            this.loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrRepairOrders/RepairOrderList', qs.stringify({
                pnl: this.pnl,
                type: type
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.repairList = res.data.ResponseData
                this.repairList.forEach(item => {
                    item.FSUBMIT_TIME = moment(item.FSUBMIT_TIME).format('YYYY-MM-DD HH:mm')
                    item.FFIXITEM_NAME = ''
                    item.DETAIL_LIST.forEach(item1 => {
                        item.FFIXITEM_NAME += item1.FFIXITEM_NO + ' - ' + item1.FFIXITEM_NAME + '，'
                    })
                    item.FFIXITEM_NAME = item.FFIXITEM_NAME.substring(0, item.FFIXITEM_NAME.length - 1)
                })
                // console.log('this.repairList', this.repairList)
            }else {
                this.$message({
                    message: '获取工务维修数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.loading = false
        },
        async updateStatus(item, FSTATUS) {
            item.FSTATUS = FSTATUS
            if(item.FSTATUS == 1) {
                item.FFIXING_PNL = this.pnl
                console.log('维修中')
            }else if(item.FSTATUS == 2){
                item.FFINISH_PNL = this.pnl
                console.log('维修完成')
            }else if(item.FSTATUS == 3){
                console.log('评论完成')
            }
            
            const dto = item
            dto.FPART_NM = this.doneForm.part_nm
            dto.FRATE_SCORE = this.rateForm.rate_score
            dto.FRATE_NOTE = this.rateForm.rate_note
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrRepairOrders/UpdateStatus', qs.stringify({
                dto,
                IMAGE1: encodeURIComponent(this.doneForm.imageList[0]),
                IMAGE2: encodeURIComponent(this.doneForm.imageList[1]),
                IMAGE3: encodeURIComponent(this.doneForm.imageList[2]),
                FILENAME1: this.doneForm.fileList[0],
                FILENAME2: this.doneForm.fileList[1],
                FILENAME3: this.doneForm.fileList[2]
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                let msg = ''
                if(FSTATUS === 1) {
                    msg = '报修单开始维修！'
                }else if(FSTATUS === 2) {
                    msg = '报修单已完成！'
                }else if(FSTATUS === 3) {
                    msg = '评价已完成！'
                }
                this.$message({
                    message: msg,
                    center: true,
                    type: 'success'
                })

                this.GetDataCounts()
                this.getDataByType('0')
            }
        },
        async GetAdmin() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrRepairOrders/RepairOrderAdmins')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.map(item => {
                    if(this.pnl === item.FPNL) {
                        this.isManager = true
                    }
                })

                console.log('this.isManager', this.isManager)
            }
        },
        getFile(file, fileList) {
            this.doneForm.fileList = []
            this.doneForm.imageList = []
            fileList.forEach(item => {
                this.doneForm.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.doneForm.imageList.push(result)
                })
                // console.log('this.doneForm.imageList', this.doneForm.imageList)
            })
        },
        handleRemove(file, fileList) {
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.doneForm.fileList = []
            this.doneForm.imageList = []
            fileList.forEach(item => {
                this.doneForm.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.doneForm.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        },
    },
    updated() {
        if(window.location.hash) {
            document.querySelector(window.location.hash).scrollIntoView(true)
        }
    },
    mounted() {
        this.GetAdmin()
        this.GetDataCounts()
        this.getDataByType('0')
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
            // this.pnl = '68586' // dev
            // this.pnl = '55129' // dev
            //  this.pnl = '0582' // dev
        }
    }
}
</script>

<style scoped>
      .box-card{
        margin-top: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 13px;
        /* border-radius: 10px;  */
    } 
</style>