<template>
    <div>
        <el-form :model="plusAdmin" ref="plusAdmin" size="small" style="margin-bottom: 10px">
            <el-row :gutter="10">
                <el-col :span="18">
                    <el-form-item label="" prop="keyid">
                        <el-input v-model="plusAdmin.keyid" placeholder="请输入工号、姓名、部门、主部门" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-button 
                        size="small" 
                        type="primary" 
                        icon="el-icon-full-screen" 
                        style="width: 100%;"
                        @click="CheckKeyID">扫码</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-table 
            max-height="580"
            :data="healthyPlusData" 
            :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
            size="mini" 
            :row-class-name="tableRowClassName" 
            v-loading="table_loading" 
            style="font-size: 11px;"
            border>
            <el-table-column prop="pnl" label="工号" min-width="50px" align="center">
            </el-table-column>
            <el-table-column prop="pname" label="姓名" min-width="54px" align="center">
            </el-table-column>
            <el-table-column prop="sec_nm" label="部门" min-width="88px" align="center">
            </el-table-column>
            <el-table-column prop="sec_nm" label="截图" min-width="50px" align="center">
                <template slot-scope="scope">
                    <el-link style="font-size: 11px;" @click="ShowImages(scope.row.DB_ID)">点击查看</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="dialogTableVisible" size="mini" width="90%">
            <span class="dialog-footer">
                <el-image
                    :key="item.DB_ID"
                    v-for="item in imageList"
                    lazy
                    style="width: 50%;"
                    :src="item.path"
                    fit="fill">
                </el-image>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="mini" @click="dialogTableVisible = false">关闭</el-button>
            </span>
        </el-dialog>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import wx from 'weixin-js-sdk'
// import moment from 'moment'
export default {
    data() {
        return {
            plusAdmin: {
                keyid: ''
            },
            pnl: '',
            healthyPlusData: [],
            table_loading: false,
            dialogTableVisible: false,
            imageList: []
        }
    },
    methods: {
        ShowImages(id) {
            console.log(id)
            this.dialogTableVisible = true
            this.GetImagesByID(id)
        },
        async GetImagesByID(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        this.imageList.push({
                            id: item.DB_ID,
                            path: conf.image_domain + item.FImgPath
                        })
                    })
                }
            }
        },
        CheckKeyID() {
            if(!this.plusAdmin.keyid) {
                this.GetJSAPI()
            }else {
                this.GetHealtyPlus(this.plusAdmin.keyid)
            }
        },
        GetJSAPI() {
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
                    this.GetHealtyPlus(result)
                }
            })
        },
        async GetHealtyPlus(keyid) {
            this.healthyPlusData = []
            this.table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrHealthyPluss/GetHealtyPlus', qs.stringify({
                keyid
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.healthyPlusData = res.data.ResponseData
                }
            }
            this.table_loading = false
        },
        tableRowClassName({row}) {
            // console.log('row', row)
            if(row.state === '1') {
                return 'warning-row'
            }
          
            return ''
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        this.GetSignature()
        this.GetHealtyPlus()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>

<style>
    .el-table .warning-row {
        font-weight: 500;
        color: white;
        background-color: #F56C6C;
    }
</style>