<template>
    <div>
        <el-card v-for="item in feedBackList" :key="item.DB_ID" shadow="always" class="box-card">
            <el-row>
                <el-col :span="24" style="text-align: left;font-weight: bold;font-size: 14px">【{{item.FTYPE}}】</el-col>
            </el-row>
            <el-row class="card-row">
                <el-col :span="24" style="text-align: left;font-size: 13px">{{item.FREMARK}}</el-col>
            </el-row>
            <el-row class="card-row" v-if="item.HAVE_IMAGE !== 0">
                <el-col :span="24" style="text-align: left">
                    <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.DB_ID)">
                        <i class="el-icon-picture-outline"></i> 点击查看
                    </el-link>
                </el-col>
            </el-row>
            <el-row class="card-row" style="font-size: 12px;color: #909399;margin-top: 32px">
                <el-col :span="4" style="text-align: center">
                    <div class="block">
                        <el-avatar :size="30" :src="item.HEADIMGURL"></el-avatar>
                    </div>
                </el-col>
                <el-col :span="9" style="text-align: left;margin-left: 10px">
                    <div style="margin-bottom: 4px">{{item.PNL}} {{item.PNAME}}</div>
                    <div>{{item.TEL}}</div>
                </el-col>
                <el-col :span="9" style="text-align: right">
                    <div style="margin-bottom: 4px">{{item.FDATE}}</div>
                </el-col>
            </el-row>
        </el-card>
        <el-card v-for="item in handingList" :key="item.DB_ID" shadow="always" class="box-card">
            <el-row>
                <el-col :span="24" style="text-align: left">{{item.FREMARK}}</el-col>
            </el-row>
            <el-row class="card-row" style="font-size: 12px;color: #909399;margin-top: 32px">
                <el-col :span="4" style="text-align: center">
                    <div class="block">
                        <el-avatar :size="30" :src="item.HEADIMGURL"></el-avatar>
                    </div>
                </el-col>
                <el-col :span="9" style="text-align: left;margin-left: 10px">
                    <div style="margin-bottom: 4px">{{item.PNL}} {{item.PNAME}}</div>
                </el-col>
                <el-col :span="9" style="text-align: right">
                    <div style="margin-bottom: 4px">{{item.FDATE}}</div>
                </el-col>
            </el-row>
        </el-card>
        <br>
        <el-form :model="handForm" :rules="rules" ref="handForm" label-width="80px" size="medium">
            <el-form-item label="异常回复" prop="fremark">
                <el-input type="textarea" :rows="6" v-model="handForm.fremark" placeholder="请输入你的回答的内容"></el-input>
            </el-form-item>
            <el-row style="margin-top:20px">
                <el-col :span="24">
                    <el-button type="primary" style="width: 100%" :loading="isLoading"  
                    @click="submitForm('handForm')">创建回复</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            handForm: {
                pnl: '',
                fid: '',
                fremark: ''
            },
            imageList: [],
            feedBackList: [],
            handingList: [],
            isLoading: false,
            rules: {
                fremark: [
                    {required: true, message: '请输入你的回答的内容', trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        ShowImages(id) {
            this.GetImagesByID(id)
        },
        async GetImagesByID(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                           path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
                console.log('this.imageList', this.imageList)
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid) {
                    console.log(this.handForm)
                    this.handing()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async GetFeedBack() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxFeedbacks/GetFeedback', qs.stringify({
                fid: this.handForm.fid
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.feedBackList = res.data.ResponseData.list
                this.handingList = res.data.ResponseData.lists
            }
            console.log('this.feedBackList', this.feedBackList)
            console.log('this.handingList', this.handingList)
        },
        async handing() {
            this.isLoading = true
            const dto = {
                FID: this.handForm.fid,
                PNL: this.handForm.pnl,
                FREMARK: this.handForm.fremark
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxFeedbackss/Handing', qs.stringify({
                dto
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$message({
                    message: '回复创建' + res.data.Message,
                    center: true,
                    type: 'success'
                })

                this.GetFeedBack()
                this.handForm.fremark = ''
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
    },
    mounted() {
        this.GetFeedBack()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.handForm.pnl = pnl
            // this.handForm.pnl = '55129'
        }
        const id = this.$route.params.id
        if(id) {
            this.handForm.fid = id
        }
    }
}
</script>

<style scoped>
    .box-card{
        margin-top: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 13px;
        /* border-radius: 10px;  */
    } 
    .card-row {
        margin-top: 26px;
    }
</style>