<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;margin-top: -20px;" stretch>
            <el-tab-pane label="成型" name="first" lazy>
                <el-form 
                    :model="cxInOutForm" 
                    :rules="cxRules" 
                    ref="cxInOutForm" 
                    label-width="60px" 
                    size="small" 
                    style="margin-top: 10px">
                    <el-row :gutter="10">
                        <el-col :span="14">
                            <el-form-item label="成型" prop="vday" style="font-weight: bold;">
                                <el-date-picker
                                    v-model="cxInOutForm.vday"
                                    style="width: 100%;"
                                    size="small"
                                    type="date"
                                    align="center"
                                    @change="cxDateChange"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-button-group>
                                <el-button v-for="(item, index) in cx_group_btn" :key="index" size="small" :type="item.btn_type" @click="cxBtnClick(item)">{{ item.label }}</el-button>
                            </el-button-group>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table 
                    v-if="!cxIsShow"
                    max-height="580"
                    :data="cxTableDynamicData" 
                    size="mini"
                    border 
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, margin: 0, height: '32px'}" 
                    :cell-style="() => {return 'padding: 0px;margin: 0px;height: 30px;fontSize: 11px'}"
                    v-loading="cx_table_dynamic_loading" 
                    :row-class-name="tableRowClassName2" 
                    style="margin-top: 15px">
                    <el-table-column prop="sec_nm" label="部门" align="center">
                    </el-table-column>
                    <el-table-column prop="period" label="时段" min-width="80px" align="center">
                    </el-table-column>
                    <el-table-column prop="mbqty" label="日标" min-width="48px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqty" label="日投" min-width="48px" align="center">
                        <template slot-scope="scope">
                            <div :class="scope.row.iqty < scope.row.mbqty ? ' error-cell' : ''">{{scope.row.iqty}}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="diffqty" label="日差" min-width="48px"  align="center">
                    </el-table-column> -->
                    <el-table-column prop="diffqty" label="日达成率" min-width="64px"  align="center">
                        <template slot-scope="scope">
                            {{scope.row.diffqty > 0 ? scope.row.diffqty + '%' : ''}}
                        </template>
                    </el-table-column>
                </el-table>
                <v-chart v-if="cxIsShow" class="chart" :option="option" :loading="cxLoading" autoresize />
            </el-tab-pane>
            <el-tab-pane label="帮面" name="second" lazy>
                <el-form :model="bmInOutForm" :rules="bmRules" ref="bmInOutForm" label-width="60px" size="small" style="margin-top: 10px">
                    <el-row :gutter="10">
                        <el-col :span="14">
                            <el-form-item label="帮面" prop="vday" style="font-weight: bold">
                                <el-date-picker
                                    v-model="bmInOutForm.vday"
                                    style="width: 100%"
                                    size="small"
                                    type="date"
                                    align="center"
                                    @change="bmDateChange"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择件资日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                                <el-button-group>
                                <el-button v-for="(item, index) in bm_group_btn" :key="index" size="small" :type="item.btn_type" @click="bmBtnClick(item)">{{ item.label }}</el-button>
                            </el-button-group>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table 
                    v-if="!bmIsShow"
                    max-height="580"
                    :data="bmTableDynamicData" 
                    size="mini" 
                    border 
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, margin: 0, height: '32px'}" 
                    :cell-style="() => {return 'padding: 0px;margin: 0px;height: 30px;fontSize: 11px'}"
                    :row-class-name="tableRowClassName2" 
                    v-loading="bm_table_dynamic_loading" 
                    style="margin-top: 15px">
                    <el-table-column prop="sec_nm" label="部门" align="center">
                    </el-table-column>
                    <el-table-column prop="period" label="时段" min-width="80px" align="center">
                    </el-table-column>
                    <el-table-column prop="mbqty" label="日标" min-width="48px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqty" label="日投" min-width="48px" align="center">
                        <template slot-scope="scope">
                            <div :class="scope.row.iqty < scope.row.mbqty ? ' error-cell' : ''">{{scope.row.iqty}}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="diffqty" label="日差" min-width="48px"  align="center">
                    </el-table-column> -->
                    <el-table-column prop="diffqty" label="日达成率" min-width="64px"  align="center">
                        <template slot-scope="scope">
                            {{scope.row.diffqty > 0 ? scope.row.diffqty + '%' : ''}}
                        </template>
                    </el-table-column>
                </el-table>
                <v-chart v-if="bmIsShow" class="chart" :option="option" :loading="bmLoading" autoresize />
            </el-tab-pane>
        </el-tabs>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import moment from 'moment'
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { BarChart } from 'echarts/charts'
import { TitleComponent, GridComponent, TooltipComponent, LegendComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
use([
    TitleComponent,
    GridComponent,
    TooltipComponent,
    LegendComponent,
    BarChart,
    CanvasRenderer
])
export default {
    components: {
        'v-chart': VChart
    },
    data() {
        return {
            cxInOutForm: {
                vday: new moment().format("YYYY-MM-DD"),
            },
            bmInOutForm: {
                vday: new moment().format("YYYY-MM-DD"),
            },
            cx_group_btn: [{
                btn_type: 'primary',
                label: '详情',
                value: '详情',
            },{
                btn_type: 'default',
                label: '分析',
                value: '分析',
            }],
            bm_group_btn: [{
                btn_type: 'primary',
                label: '详情',
                value: '详情',
            },{
                btn_type: 'default',
                label: '分析',
                value: '分析',
            }],
            echartsData: [],
            yAxis_data: [],
            // series_data: [],
            // mb_data: [],
            // i_data: [],
            option: {
                // title: {
                //     text: '时产量预警'
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    left: '5%'
                },
                grid: {
                    left: '0%',
                    right: '4%',
                    bottom: '0%',
                    // top: '22%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                },
                yAxis: {
                    type: 'category',
                    data: []
                },
                series: []
            },
            cxRules: {
                vday: [
                    {required: true, message: '请选择日期', trigger: 'change'}
                ]
            },
            bmRules: {
                vday: [
                    {required: true, message: '请选择日期', trigger: 'change'}
                ]
            },
            tab: null,
            activeName: 'first',
            cxTableDynamicData: [],
            cx_table_dynamic_loading: false,
            bmTableDynamicData: [],
            bm_table_dynamic_loading: false,
            cxIsShow: false,
            bmIsShow: false,
            cxLoading: false,
            bmLoading: false
        }
    },
    methods: {
        handleClick(tab) {
            this.tab = tab
            if(tab.index === '0' || !tab.index) {
                this.GetCXDynamicData()
            }else if(tab.index === '1'){
                this.GetBMDynamicData()
            }
        },
        cxDateChange() {
            this.GetCXDynamicData()
        },
        bmDateChange() {
            this.GetBMDynamicData()
        },
        cxBtnClick(e) {
            this.cx_group_btn.forEach(item => {
                if(item.label === e.label) {
                    item.btn_type = 'primary'
                    if(e.label === '详情') {
                        this.GetCXDynamicData()
                        this.cxIsShow = false
                    }else {
                        this.cxIsShow = true
                    }
                }else {
                    item.btn_type = 'default'
                }
            })
        },
        bmBtnClick(e) {
            this.bm_group_btn.forEach(item => {
                if(item.label === e.label) {
                    item.btn_type = 'primary'
                    if(e.label === '详情') {
                        this.GetBMDynamicData()
                        this.bmIsShow = false
                    }else {
                        console.log('来了老弟')
                        this.bmIsShow = true
                    }
                }else {
                    item.btn_type = 'default'
                }
            })
        },
        async GetCXDynamicData() {
            this.cxTableDynamicData = []
            this.cx_table_dynamic_loading = true
            this.cxLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/InputOutput/InputOutputDynamicTotal', qs.stringify({
                made_no: '7',
                vday: this.cxInOutForm.vday,
                flag: 1
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.yAxis_data = []
                let mb_data = []
                let i_data = []
                this.series_data = []
                res.data.ResponseData.map(item => {
                    var sec_nm = this.SecFormat2(this.SecFormat3(this.SecFormat1(item.sec_nm)))
                    if(this.yAxis_data.indexOf(sec_nm) < 0) {
                        this.yAxis_data.push(sec_nm)
                    }

                    mb_data.push(item.mbqty)
                    i_data.push(item.iqty)
                })

                this.series_data.push({
                    name: '目标产量',
                    type: 'bar',
                    label: {
                        show: true,
                        position: 'right',
                        fontSize: 9
                    },
                    data: mb_data
                })    
                this.series_data.push({
                    name: '实际投入',
                    type: 'bar',
                    label: {
                        show: true,
                        position: 'right',
                        fontSize: 9
                    },
                    data: i_data
                })       

                console.log('this.yAxis_data', this.yAxis_data)
                this.option.yAxis.data = this.yAxis_data

                console.log('this.series_data', this.series_data)
                this.option.series = this.series_data

                var groupData = this.GroupBy(res.data.ResponseData, ['sec_nm1'])
                console.log('groupData', groupData)
                groupData.map(group => {
                    var group_iqty = 0
                    var group_mbqty = 0
                    var group_diffqty = 0
                    var group_count = 0
                    group.data.map(item => {
                        item.diffqty = parseFloat(item.diffqty.toFixed(1))
                        item.sec_nm = this.SecFormat2(item.sec_nm)
                        if(item.sec_nm1 === '工厂') {
                            if(item.iqty > 0) {
                                this.cxTableDynamicData.push(item)
                            }
                        }else {
                            this.cxTableDynamicData.push(item)
                        }

                        group_iqty += item.iqty
                        group_mbqty += item.mbqty
                        group_diffqty += item.diffqty
                        group_count ++
                    })
                    // 小计
                    if(group_iqty > 0 ) {
                        let sec_nm1 = group.key.sec_nm1.indexOf('成型') > -1 ? group.key.sec_nm1.substring(2, group.key.sec_nm1.length) : group.key.sec_nm1
                        console.log('group_diffqty', group_diffqty)
                        this.cxTableDynamicData.push({
                            sec_nm: sec_nm1 + '合计',
                            iqty: group_iqty,
                            mbqty: group_mbqty,
                            diffqty: (group_diffqty/group_count).toFixed(1),
                        })
                    }
                })
            }

            this.cx_table_dynamic_loading = false
            this.cxLoading = false
        },
        SecFormat1(sec_nm) {
            if(sec_nm.indexOf('成型') === -1) {
                return sec_nm
            }
            else {
                sec_nm = sec_nm.replace('成型', '')
                return this.SecFormat1(sec_nm)
            }
        },
        SecFormat2(sec_nm) {
            if(sec_nm.indexOf('(') === -1) {
                return sec_nm
            }
            else {
                let index1 = sec_nm.indexOf('(')
                let index2 = sec_nm.indexOf(')')
                let sec_nm1 = sec_nm.substring(0, index1)
                let sec_nm2 = sec_nm.substring(index2 + 1, sec_nm.length)
                return this.SecFormat2(sec_nm1 + sec_nm2)
            }
        },
        SecFormat3(sec_nm) {
            if(sec_nm.indexOf('线') === -1) {
                return sec_nm
            }
            else {
                sec_nm = sec_nm.replace('线', '')
                return this.SecFormat3(sec_nm)
            }
        },
        SecFormat4(sec_nm) {
            if(sec_nm.indexOf('组') === -1) {
                return sec_nm
            }
            else {
                sec_nm = sec_nm.replace('组', '')
                return this.SecFormat4(sec_nm)
            }
        },
        SecFormat5(sec_nm) {
            if(sec_nm.indexOf(')-帮面') === -1) {
                return sec_nm
            }
            else {
                sec_nm = sec_nm.replace(')-帮面', '')
                return this.SecFormat5(sec_nm)
            }
        },
        SecFormat6(sec_nm) {
            if(sec_nm.indexOf('环成(') === -1) {
                return sec_nm
            }
            else {
                sec_nm = sec_nm.replace('环成(', '')
                return this.SecFormat6(sec_nm)
            }
        },
        async GetBMDynamicData() {
            this.bmTableDynamicData = []
            this.bm_table_dynamic_loading = true
            this.bmLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/InputOutput/InputOutputDynamicTotal', qs.stringify({
                made_no: '3',
                vday: this.bmInOutForm.vday,
                flag: 1
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // var echartsSecData = this.GroupBy(res.data.ResponseData, ['sec_nm'])
                // console.log('echartsSecData', echartsSecData)
                // this.yAxis_data = []
                // echartsSecData.map(group => {
                //     var sec_nm = this.SecFormat6(this.SecFormat5(this.SecFormat4(group.key.sec_nm)))
                //     if(this.yAxis_data.indexOf(sec_nm) < 0) {
                //         this.yAxis_data.push(sec_nm)
                //     }
                // })

                // 
                
                this.yAxis_data = []
                let mb_data = []
                let i_data = []
                this.series_data = []
                res.data.ResponseData.map(item => {
                    var sec_nm = this.SecFormat6(this.SecFormat5(this.SecFormat4(item.sec_nm)))
                    if(this.yAxis_data.indexOf(sec_nm) < 0) {
                        this.yAxis_data.push(sec_nm)
                    }

                    mb_data.push(item.mbqty)
                    i_data.push(item.iqty)
                })

                this.series_data.push({
                    name: '目标产量',
                    type: 'bar',
                    label: {
                        show: true,
                        position: 'right',
                        fontSize: 9
                    },
                    data: mb_data
                })    
                this.series_data.push({
                    name: '实际投入',
                    type: 'bar',
                    label: {
                        show: true,
                        position: 'right',
                        fontSize: 9
                    },
                    data: i_data
                })       

                console.log('this.yAxis_data', this.yAxis_data)
                this.option.yAxis.data = this.yAxis_data

                console.log('this.series_data', this.series_data)
                this.option.series = this.series_data
                var groupData = this.GroupBy(res.data.ResponseData, ['sec_nm1'])
                // console.log('groupData', groupData)
                groupData.map(group => {
                    var group_iqty = 0
                    var group_mbqty = 0
                    var group_diffqty = 0
                    var group_count = 0
                    group.data.map(item => {
                        item.diffqty = parseFloat(item.diffqty.toFixed(1))
                        var index = item.sec_nm.indexOf('原') 
                        if(index > -1) {
                            item.sec_nm = item.sec_nm.substring(0, index - 1)
                        }
                        index = item.sec_nm.indexOf('-')
                        if(index > -1){
                            item.sec_nm = item.sec_nm.substring(0, index)
                        }

                        if(item.sec_nm1 === '工厂') {
                            if(item.iqty > 0) {
                                this.bmTableDynamicData.push(item)
                            }
                        }else {
                            this.bmTableDynamicData.push(item)
                        }

                        group_iqty += item.iqty
                        group_mbqty += item.mbqty > 0 ? item.mbqty : 0
                        group_diffqty += item.diffqty
                        group_count ++
                    })
                    // 小计
                    if(group_iqty > 0) {
                        let sec_nm1 = group.key.sec_nm1.indexOf('帮面') > -1 ? group.key.sec_nm1.substring(2, group.key.sec_nm1.length) : group.key.sec_nm1
                        this.bmTableDynamicData.push({
                            sec_nm: sec_nm1 + '合计',
                            iqty: group_iqty,
                            mbqty: group_mbqty,
                            diffqty: (group_diffqty/group_count).toFixed(1),
                        })
                    }
                })
            }

            this.bm_table_dynamic_loading = false
            this.bmLoading = false
        },
        GroupBy(datas, keys, callBack) {
            const list = datas || []
            const groups = []
            list.forEach(v => {
                const key = {}
                // const data = {}
                keys.forEach(k => {
                    key[k] = v[k]
                })
                let group = groups.find(v => {
                    return v._key === JSON.stringify(key)
                })
                if(!group) {
                    group = {
                        _key: JSON.stringify(key),
                        key: key
                    }
                    groups.push(group)
                }
                if(callBack) {
                    group.data = callBack(group.data, v)
                    group.total = group.total || 0
                    group.total++
                }else {
                    group.data = group.data || []
                    group.data.push(v)
                }
            })
            return groups
        },
        tableRowClassName({row}) {
            // console.log('row', row)
            // console.log('rowIndex', rowIndex)
            if(row.sec_nm.indexOf('合计') > -1) {
                return 'error-row'
            }
            // if (rowIndex === 1) {
            //     return 'warning-row'
            // } else if (rowIndex === 3) {
            //     return 'success-row'
            // }
            return ''
        },
        tableRowClassName2({row}) {
            if(row.sec_nm.indexOf('合计') > -1) {
                // console.log('row', row)
                return 'error-row'
            }
            return ''
        },
    },
    mounted() {
        this.GetCXDynamicData()
    }
}
</script>

<style>
    body {
        margin: 0;
    }
    .total_data{
        font-size: 14px;
        font-weight: bold;
        color: #F56C6C;
    }
    .total_data_bm{
        font-size: 13px;
        font-weight: 600;
        color: #E6A23C;
    }
    /* .el-table .warning-row {
        background: oldlace;
    }
    .el-table .success-row {
        background: #f0f9eb;66B1FF;79BBFF
    } */
    .el-table .error-row {
        font-weight: 500;
        color: white;
        background: #66B1FF;
    }
    .error-cell {
        color: #F56C6C;
        font-weight: 700;
    }
    .chart {
        height: 80vh;
        width: 98%;
        /* padding-bottom: -50px; */
        /* margin-top: 10px; */
    }
</style>