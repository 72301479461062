<template>
    <div>
        <el-row :gutter="15">
            <el-col :span="12">
                <el-button plain size="small" style="width: 100%" type="primary" @click="copyText">复制页面URL</el-button>
            </el-col>
            <el-col :span="12">
                <el-button plain size="small" style="width: 100%" type="success" @click="downImage">下载二维码</el-button>
            </el-col>
        </el-row>
        <el-result :title="title" :subTitle="subTitle">
            <template slot="icon">
                <canvas id="QRCode_header" style="width: 280px;height: 280px;"></canvas>
            </template>
            <template slot="extra">
                <!-- <el-statistic
                    ref="statistic"
                    @finish="hilarity"
                    format="HH:mm:ss"
                    :value="deadline4"
                    title="距离会议开始：" 
                    time-indices>
                </el-statistic> -->
                
                <el-button 
                    v-if="pnl === meet_pnl && meet_status === 0"
                    style="width: 100%;"
                    type="primary" 
                    size="medium"
                    :loading="isLoading"
                    @click="MeetingStart">会议开始</el-button>
                <el-button 
                    v-if="pnl === meet_pnl && meet_status === 1"
                    size="medium"
                    :loading="isLoading"
                    style="width: 100%;"
                    type="success" 
                    @click="MeetingEnd">会议结束</el-button>    
            </template>
        </el-result>
        <el-dialog :visible.sync="canfirmDialogVisible" size="mini" width="90%">
            <span class="dialog-footer" style="font-weight: 550;color: #409EFF;">
                <span style="color: #F56C6C;font-size: medium;">会议是否{{meet_status===0?'开始':'结束'}}？</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="canfirmDialogVisible=false">取消</el-button>
                <el-button type="primary" size="mini" @click="meet_status === 0 ? UpdateMeeting(1) : ChooseImage()">确认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import QRCode from 'qrcode'
import moment from 'moment'
import wx from 'weixin-js-sdk'
// import { compressImage } from '../../../plugin/compress.js'
export default {
    data() {
        return {
            db_id: '',
            title: '',
            subTitle: '',
            qr_url: '',
            refresh_time: 10,
            deadline4: moment().format('x'),
            isLoading: false,
            meet_pnl: '',
            meet_status: 0,
            IMAGE1: '',
            FILENAME1: '',
            canfirmDialogVisible: false,
        }
    },
    methods: {
        async downImage() {
            try{
                await QRCode.toDataURL('http://wx.qzhqxf.com/MeetingQrCode/' + this.db_id, (err, url) => {
                    console.log('err', err)
                    console.log('url', url)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download',  this.title +'.png')
                    document.body.appendChild(link)
                    link.click()
                    console.log("下载成功")
                })
            } catch(err) {
                console.error("下载失败", err)
            }
        },
        async copyText() {
            try {
                const domain = 'http://10.171.128.231:9815/MeetingQrCode/'
                const textToCopy = domain + this.db_id
                await navigator.clipboard.writeText(textToCopy)
                console.log("复制成功", textToCopy)
            } catch (err) {
                console.error("复制失败", err)
            }
        },
        hilarity() {
            // this.$notify({
            //     title: "提示",
            //     message: "会议时间已到！",
            //     duration: new Date("2023-05-06"),
            // })
            console.log('hilarity')
        },
        MeetingStart() {
            // this.$confirm('会议是否开始？', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning',
            //     center: true,
            // }).then(() => {
            //     this.UpdateMeeting(1)
            // }).catch(() => {
            //     console.log('cancel')
            // })
            this.canfirmDialogVisible = true
        },
        MeetingEnd() {
            // this.$confirm('会议是否结束？', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消'
            // }).then(() => {
            //     this.ChooseImage()
            // })
            this.canfirmDialogVisible = true
        },
        async UpdateMeeting(STATUS) {
            this.canfirmDialogVisible = false
            this.isLoading = true
            const dto = {
                DB_ID: this.db_id,
                MEET_STATUS: STATUS
            }
            const IMAGE1 = STATUS === 1 ? '' : encodeURIComponent(this.IMAGE1)
            const FILENAME1 = STATUS === 1 ? '' : this.FILENAME1
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqPersonMeetings/UpdateMeeting', qs.stringify({
                dto,
                IMAGE1: IMAGE1,
                FILENAME1: FILENAME1
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: STATUS === 1 ? '会议开始！' : '会议结束！',
                    center: true,
                    type: 'success'
                })
                this.meet_status = STATUS
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: (STATUS === 1 ? '会议开始：' : '会议结束：') + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: (STATUS === 1 ? '会议开始：' : '会议结束：') + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async ChooseImage() {
            this.canfirmDialogVisible = false
            wx.chooseImage({
                count: 1, // 默认9
                sizeType: ['compressed'], // sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                sourceType: ['camera'], // sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                success: res => {
                    var localIds = res.localIds // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                    this.GetLocalImage(localIds[0])
                }
            })
        },
        async GetLocalImage(localId) {
            wx.getLocalImgData({
                localId: localId, // 图片的localID
                success: res => {
                    var localData = res.localData // localData是图片的base64数据，可以用img标签显示
                    this.IMAGE1 = localData
                    this.FILENAME1 = this.SubStringBase64(localData)
                    this.UpdateMeeting(2)
                }
            })
        },
        SubStringBase64(base64) {
            var start = base64.indexOf('/')
            var end = base64.indexOf(';')
            var time = new Date()
            var timestamp = Date.parse(time)
            return timestamp + '.' + (end === -1 ? 'jpg' : base64.substring(start + 1, end))
        },
        async getQRCodeData() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqPersonMeetings/getQRCodeData', qs.stringify({
                DB_ID: this.db_id
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.title = res.data.ResponseData.MEET_NAME
                this.subTitle = '会议时间：' + moment(res.data.ResponseData.MEET_SDATE).format('HH:mm') + ' - ' 
                + moment(res.data.ResponseData.MEET_EDATE).format('HH:mm') + ' \n '
                + ' 会议时长：' + moment(res.data.ResponseData.MEET_EDATE).diff(moment(res.data.ResponseData.MEET_SDATE),'minutes') + '分钟'
                this.deadline4 = moment(res.data.ResponseData.MEET_SDATE).format('x')
                this.refresh_time = res.data.ResponseData.MEET_REFRESH_TIME
                this.meet_pnl = res.data.ResponseData.MEET_PNL
                this.meet_status = res.data.ResponseData.MEET_STATUS
                this.setQRCode()
            }
            if(this.refresh_time > 0) {
                let interval = this.refresh_time * 1000
                setInterval(() => {
                    this.setQRCode()
                    console.log(moment().format('X'))
                    console.log(moment().format('x'))
                    console.log(moment().format('mm:ss'))
                }, interval)
            }
        },
        setQRCode() {
            let time_stamp = moment().format('x')
            this.qr_url = 'http://wx.qzhqxf.com/MeetingSign/' + this.db_id + '/' + time_stamp
            let opts = {
                errorCorrectionLevel: 'H',
                type: 'image/png',
                quality: 0.3,
                margin: 0,
                width: 280,
                height: 280,
                color: {
                    dark: '#333333',
                    light: '#fff'
                }
            }

            let msg = document.getElementById("QRCode_header")
            QRCode.toCanvas(msg, this.qr_url, opts, error => {
                if(error) {
                    console.log('qrcode_error', error)
                }
            })
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['chooseImage'], // 必填，需要使用的JS接口列表
                })
            }
        },
    },
    mounted() {
        this.db_id = this.$route.params.id
        if(this.db_id) {
            this.getQRCodeData()
        }

        this.GetSignature()
    },
    created() {
        // 需加权限限制
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }

        // this.pnl = '64013'  // dev
    }
}
</script>