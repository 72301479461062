<template>
    <div>
        <el-skeleton style="width: 100%;margin-top: 30px;text-align: left;" :rows="6" animated :loading="loading">
            <el-row v-if="!loading">
                <el-col :span="24" v-if="isSuccess">
                    <el-result icon="success" title="签到成功" :subTitle="subTitle">
                    <template slot="extra">
                        <router-link to="/meetingList">
                            <el-button style="width: 100%" type="primary">返回</el-button>
                        </router-link>
                    </template>
                    </el-result>
                </el-col>
                <el-col :span="24" v-if="!isSuccess">
                    <el-result icon="error" title="签到失败" :subTitle="subTitle">
                    <template slot="extra">
                        <router-link to="/meetingList">
                            <el-button style="width: 100%" type="primary">返回</el-button>
                        </router-link>
                    </template>
                    </el-result>
                </el-col>
            </el-row>
        </el-skeleton>
    </div>
</template>

<script>
import conf from '../../../conf.js'
import axios from 'axios'
import qs from 'qs'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            loading: false,
            isSuccess: false,
            subTitle: '',
            HqPersonMeetingsDTO: {
                MEET_ID: '',
                MEET_SIGN_PNL: '',
                MEET_SIGN_POSITION: '',
                MEET_SIGN_LAT: '',
                MEET_SIGN_LNG: '',
                MEET_SIGN_DESCRIPTION: '',
                MEET_TIME_STAMP: ''
            }
        }
    },
    methods: {
        async SaveMeetingSign() {
            const dto = {
                MEET_ID: this.HqPersonMeetingsDTO.MEET_ID,
                MEET_SIGN_PNL: this.HqPersonMeetingsDTO.MEET_SIGN_PNL,
                MEET_SIGN_POSITION: this.HqPersonMeetingsDTO.MEET_SIGN_POSITION,
                MEET_SIGN_LAT: this.HqPersonMeetingsDTO.MEET_SIGN_LAT,
                MEET_SIGN_LNG: this.HqPersonMeetingsDTO.MEET_SIGN_LNG,
                MEET_SIGN_DESCRIPTION: this.HqPersonMeetingsDTO.MEET_SIGN_DESCRIPTION,
                MEET_TIME_STAMP: this.HqPersonMeetingsDTO.MEET_TIME_STAMP,
                // MEET_DIFF_TIME: 15
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqPersonMeetings/SaveMeetingSign', qs.stringify({
                dto,
            }))

            console.log(res)
            this.loading = false
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.isSuccess = true
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.subTitle = res.data.Message
                this.isSuccess = false
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1){
                this.subTitle = res.data.Message
                this.isSuccess = false
            }
        },
        async GetLocation() {
            wx.getLocation({
                type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: res => {
                    console.log(res)
                    this.GetBaiduLocation(res.latitude, res.longitude)
                }
            })
        },
        async GetBaiduLocation(lat, lng) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/BaiduLocation/BaiduLocationName', qs.stringify({
                lat: lat, // dev
                lng: lng // dev
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功') {
                if(res.data.ResponseData.status === 0) {
                    let data = res.data.ResponseData.result
                    this.HqPersonMeetingsDTO.MEET_SIGN_LAT = data.location.lat
                    this.HqPersonMeetingsDTO.MEET_SIGN_LNG = data.location.lng
                    this.HqPersonMeetingsDTO.MEET_SIGN_DESCRIPTION = data.sematic_description
                    this.HqPersonMeetingsDTO.MEET_SIGN_POSITION = data.pois[0].addr
                }

                setTimeout(() => {
                    this.SaveMeetingSign()
                }, 2000)
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['openLocation','getLocation'], // 必填，需要使用的JS接口列表
                })
                
                // dev 测试注释则这里
                setTimeout(() => {
                    this.GetLocation()
                }, 500)
            }
        },
    },
    mounted() {
        this.HqPersonMeetingsDTO.MEET_ID = this.$route.params.id
        this.HqPersonMeetingsDTO.MEET_TIME_STAMP = this.$route.params.ts
        console.log('this.HqPersonMeetingsDTO.MEET_TIME_STAMP', this.HqPersonMeetingsDTO.MEET_TIME_STAMP)
        if(this.HqPersonMeetingsDTO.MEET_ID && this.HqPersonMeetingsDTO.MEET_TIME_STAMP) {
            this.loading = true
            this.GetSignature()
            // 测试开启这里
            // this.GetLocation()  // dev
            // this.SaveMeetingSign()  // dev
        }
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.HqPersonMeetingsDTO.MEET_SIGN_PNL = pnl
        }
    }
}
</script>