<template>
    <div>
        <el-form :model="RoomCheckForm" ref="RoomCheckForm" :rules="rules" label-width="80px" size="small">
            <el-form-item label="房间号" prop="room_no" style="font-weight: bold">
                <el-row :gutter="10">
                    <el-col :span="9">
                        <el-input 
                            v-model="RoomCheckForm.room_no" 
                            style="font-weight: bold"> 
                        </el-input>
                    </el-col>
                    <el-col :span="7">
                        <el-button 
                            @click="SearchRoomNo"
                            style="width: 100%;"
                            type="primary">查询
                        </el-button>
                    </el-col> 
                    <el-col :span="7">
                        <el-checkbox v-model="is_all" @change="SetRoomAll" style="width: 100%;" label="全员" border size="small"></el-checkbox>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item v-if="!is_all" label="床位号" prop="bed_no" style="font-weight: bold">
                <el-input disabled v-model="RoomCheckForm.bed_no" style="font-weight: bold"></el-input>
            </el-form-item>
            <el-form-item v-if="!is_all" label="责任人" prop="pname" style="font-weight: bold">
                <el-input disabled v-model="RoomCheckForm.pname" style="font-weight: bold"></el-input>
            </el-form-item>
            <el-form-item label="文明标兵" prop="is_good" style="font-weight: bold">
                <el-switch
                    v-model="is_good"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="是"
                    inactive-text="否">
                </el-switch>
            </el-form-item>
            <el-row v-if="!is_good" style="margin-bottom: 20px;">
                <el-table
                    ref="multipleTable"
                    height="280px"
                    size="small"
                    tooltip-effect="dark"
                    v-loading="tableLoading2" 
                    @selection-change="handleSelectionChange"
                    :data="ResultTypeLists"
                    style="width: 100%;">
                    <el-table-column type="selection" width="55" prop="ISOK">
                    </el-table-column>
                    <el-table-column label="检查结果" prop="RESULT_NAME">
                    </el-table-column>
                </el-table>
            </el-row>
            <!-- <el-row v-for="(item, index) in checkImageDatas" :key="index" style="margin-bottom: 15px">
                <el-col :span="24" >
                    <el-card :body-style="{ padding: '0px' }">
                        <el-image 
                            @click="previewImage(item.FImgPath)"
                            style="width: 50%;"
                            :src="item.FImgPath">
                        </el-image>
                    </el-card>
                </el-col>
            </el-row> -->
            <el-form-item label="检查图片" prop="fileList" style="font-weight: bold;">
                <el-row :gutter="20">
                    <el-col :span="11">
                        <el-button 
                            v-if="RoomCheckForm.is_review" 
                            size="medium" 
                            type="success" 
                            @click="ShowImages">初查图片 <i class="el-icon-picture-outline"></i></el-button>
                    </el-col>
                    <el-col :span="11">
                        <el-upload 
                            action='' 
                            ref="upload"
                            :on-change="getFile" 
                            :limit="3" 
                            :on-remove="handleRemove"
                            list-type="picture"
                            :auto-upload="false" 
                            :accept="'image/*'"
                            :on-exceed="handleExceed"
                            style="width: 100%;" >
                            <el-button size="medium" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                        </el-upload>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="微信提醒" style="font-weight: bold;">
                <el-switch
                    v-model="is_send"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="开启"
                    inactive-text="关闭">
                </el-switch>
            </el-form-item>
            <el-form-item label="是否复查" prop="is_review">
                <el-checkbox disabled v-model="RoomCheckForm.is_review">复查</el-checkbox>
            </el-form-item>
            <el-row v-if="this.RoomCheckForm.is_review" style="margin-top: 10px;margin-bottom: 20px">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="isLoading"
                        @click="SubmitForm('RoomCheckForm')">提交检查问题</el-button>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-if="!this.RoomCheckForm.is_review" style="margin-top: 10px;margin-bottom: 20px">
                <el-col :span="12">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="isLoading"
                        @click="SubmitForm('RoomCheckForm')">提交检查问题</el-button>
                </el-col>
                <el-col :span="12">
                    <el-button 
                        style="width: 100%;"
                        type="success" 
                        :loading="isLoading"
                        @click="SubmitForm2('RoomCheckForm')">提交并新增</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-dialog title="选择床位信息" :visible.sync="dialogVisible" width="90%">
            <el-table
                height="260px"
                size="small"
                tooltip-effect="dark"
                v-loading="tableLoading" 
                @row-dblclick="SetRoomInfo"
                :data="roomInfoList"
                :row-class-name="tableRowClassName"
                style="width: 100%;margin-bottom: 20px;">
                <el-table-column label="房间号" prop="room_no" min-width="60px">
                </el-table-column>
                <el-table-column label="小房间" prop="room_no1" min-width="70px">
                </el-table-column>
                <el-table-column v-if="false" label="床位" prop="bed_no" min-width="45px">
                </el-table-column>
                <el-table-column label="床位" prop="bed_no_new" min-width="45px">
                </el-table-column>
                <el-table-column label="工号" prop="pnl" min-width="55px">
                </el-table-column>
                <el-table-column label="姓名" prop="pname">
                </el-table-column>
            </el-table>
            <span style="text-align: right; color: #F56C6C;font-weight: bold;margin-bottom: 30px;">注：有背景色为值日生，双击对应信息，选择床位</span>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf.js'
import { compressImage } from '../../../plugin/compress'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            pnl: '',
            isLoading: false,
            RoomCheckForm: {
                room_no: '',
                room_no1: '',
                bed_no: '',
                pnl: '',
                pname: '',
                result: '',     
                fileList: [],
                imageList: [],
                is_review: false,
                fid: ''
            },
            is_good: false,
            is_send: true,
            is_all: false,
            dialogVisible: false,
            tableLoading: false,
            tableLoading2: false,
            roomInfoList: [],
            ResultTypeList: [],
            ResultTypeLists: [],
            multipleSelection: [],
            checkImageDatas: [],
            building_no: '',
            floor_no: '',
            rules: {
                room_no: [
                    {required: true, message: '请输入房间号', trigger: 'blur'}
                ],
                bed_no: [
                    {required: true, message: '请输入床位号', trigger: 'change'}
                ],
                pname: [
                    {required: true, message: '请输入姓名', trigger: 'change'}
                ],
                fileList: [
                    {required: true, message: '请上传违规图片', trigger: 'change'}
                ]
            }
        }
    },
    methods: {
        async ShowImages() {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id: this.RoomCheckForm.fid
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                            path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
            }
        },
        // previewImage(path) {
        //     wx.previewImage({
        //         current: path, // 当前显示图片的 http 链接
        //         urls: [path] // 需要预览的图片 http 链接列表
        //     })
        // },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
        // async GetCheckRoomImage(DB_ID) {
        //     this.checkImageDatas = []
        //     const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/GetCheckRoomImage', qs.stringify({
        //         DB_ID
        //     }))
        //     console.log(res)
        //     if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
        //         console.log(res.data.ResponseData)
        //         if(res.data.ResponseData.length > 0) {
        //             res.data.ResponseData.map(item => {
        //                 var preview_list = []
        //                 preview_list.push(conf.image_domain + item.FImgPath)
        //                 this.checkImageDatas.push({
        //                     // DB_ID: item.DB_ID,
        //                     FImgPath: conf.image_domain + item.FImgPath,
        //                     preview_list: preview_list
        //                 })
        //             })
        //         }
        //     }
        // },
        tableRowClassName({row, rowIndex}) {
            if (row.is_duty === 'Y') {
                console.log('rowIndex', rowIndex)
                return 'warning-row'
            } 
            return ''
        },
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CustomValidate() && this.CustomValidate2()){
                    this.SaveRoomCheck(false)
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        SubmitForm2(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CustomValidate() && this.CustomValidate2()){
                    this.SaveRoomCheck(true)
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        CustomValidate() {
            if(this.multipleSelection.length === 0 && !this.is_good) {
                this.$message({
                    message: '请选择检查结果！',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
                return false
            }
            return true
        },
        CustomValidate2() {
            if(this.RoomCheckForm.fileList.length === 0) {
                this.$message({
                    message: '请上传违规图片',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
                return false
            }
            return true
        },
        async SaveRoomCheck(isNew) {
            this.isLoading = true
            let check_result = ''
            if(this.is_good) {
                if(this.RoomCheckForm.is_review) {
                    check_result = '文明标兵'
                }else {
                    check_result = '口头表扬'
                }
            }else {
                if(this.RoomCheckForm.is_review) {
                    check_result = '警告'
                }else {
                    check_result = '口头警告'
                }
            }

            const dto = {
                ROOM_NO: this.RoomCheckForm.room_no,
                ROOM_NO1: this.RoomCheckForm.room_no1,
                BED_NO: this.RoomCheckForm.bed_no,
                PNL: this.RoomCheckForm.pnl,
                PNAME: this.RoomCheckForm.pname,
                DB_CREATE_USERNO: this.pnl,
                IS_SEND: this.is_send,
                IS_REVIEW: this.RoomCheckForm.is_review ? 'Y' : 'N',
                FID: this.RoomCheckForm.fid,
                CHECK_RESULT: check_result,
                IS_ALL: this.is_all
            }
           
            const dtos = this.multipleSelection
            console.log('dtos', dtos)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/SaveCheckRoom', qs.stringify({
                dto,
                dtos,
                IMAGE1: encodeURIComponent(this.RoomCheckForm.imageList[0]),
                IMAGE2: encodeURIComponent(this.RoomCheckForm.imageList[1]),
                IMAGE3: encodeURIComponent(this.RoomCheckForm.imageList[2]),
                FILENAME1: this.RoomCheckForm.fileList[0],
                FILENAME2: this.RoomCheckForm.fileList[1],
                FILENAME3: this.RoomCheckForm.fileList[2]
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: '提交检查问题成功',
                    center: true,
                    type: 'success'
                })
                setTimeout(()=>{
                    if(isNew) {
                        window.location.href = '/roomCheckCreate'
                    } else {
                        if(this.building_no) {
                            window.location.href = '/roomCheckList/' + this.building_no
                        }else if(this.building_no  && this.floor_no) {
                            window.location.href = '/roomCheckList/' + this.building_no + '/' + this.floor_no
                        }else {
                            window.location.href = '/roomCheckList'
                        }
                    }
                }, 1000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '提交检查问题失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '提交检查问题失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async SetRoomAll() {
            if(!this.RoomCheckForm.room_no) {
                this.$message({
                    message: '请输入房间号！',
                    center: true,
                    type: 'error',
                    duration: 1000
                })

                this.is_all = false
                console.log('this.is_all', this.is_all)
                return
            }
            
            this.CheckRoomNo()
        },
        async CheckRoomNo() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/CheckRoomNo', qs.stringify({
                room_no: this.RoomCheckForm.room_no
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                if(res.data.ResponseData.length === 0){
                    this.is_all = false
                    this.$message({
                        message: '该房间号有误！',
                        center: true,
                        type: 'error',
                        duration: 1000
                    })
                }
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '提交检查问题失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '提交检查问题失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        SearchRoomNo() {
            if(!this.RoomCheckForm.room_no) {
                this.$message({
                    message: '请输入房间号！',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
            }else {
                this.dialogVisible = true
                this.GetRoomInfo()
            }
        },
        async GetRoomInfo() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/GetRoomInfoDutyBed', qs.stringify({
                room_no: this.RoomCheckForm.room_no
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.roomInfoList = res.data.ResponseData
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
            // console.log('val', val)
        },
        SetRoomInfo(row) {
            this.RoomCheckForm.room_no = row.room_no
            this.RoomCheckForm.room_no1 = row.room_no1
            this.RoomCheckForm.bed_no = row.bed_no
            this.RoomCheckForm.pnl = row.pnl
            this.RoomCheckForm.pname = row.pname
            this.dialogVisible = false
        },
        getFile(file, fileList) {
            console.log('file', file)
            console.log('fileList', fileList)
            this.RoomCheckForm.fileList = []
            this.RoomCheckForm.imageList = []
            fileList.forEach(item => {
                this.RoomCheckForm.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.RoomCheckForm.imageList.push(result)
                })
                console.log('this.RoomCheckForm.imageList', this.RoomCheckForm.imageList)
            })
        },
        handleRemove(file, fileList) {
            console.log('file', file)
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.RoomCheckForm.fileList = []
            this.RoomCheckForm.imageList = []
            fileList.forEach(item => {
                this.RoomCheckForm.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.RoomCheckForm.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        },
        async GetResultType () {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/GetResultType')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.ResultTypeList = res.data.ResponseData
            }
        },
        async GetResultTypes (fid) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/GetResultTypes', qs.stringify({
                fid
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.ResultTypeLists = []
                res.data.ResponseData.forEach(item => {
                    this.ResultTypeLists.push({
                        RESULT_NO: item.RESULT_NO,
                        RESULT_NAME: item.RESULT_NAME,
                        FID: item.FID,
                        ISOK: false
                    })
                })    
                // console.log('this.ResultTypeLists', this.ResultTypeLists)
            }
        },
        async GetCheckRoom(db_id) {
            // this.check_rooms = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/GetCheckRoom', qs.stringify({
                db_id
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                let item = res.data.ResponseData[0]
                this.RoomCheckForm.pnl = item.PNL
                this.RoomCheckForm.pname = item.PNAME
                this.RoomCheckForm.room_no = item.ROOM_NO
                this.RoomCheckForm.room_no1 = item.ROOM_NO1
                this.RoomCheckForm.bed_no = item.BED_NO
                this.RoomCheckForm.is_review = true
                this.is_good = item.CHECK_RESULT.indexOf('警告') > 0 ? false : true
                item.entitys.forEach(el => {
                    this.ResultTypeLists.forEach(els => {
                        if(el.RESULT_NO === els.RESULT_NO) {
                            this.$refs.multipleTable.toggleRowSelection(els)
                        }
                    })
                })
                
                // this.GetCheckRoomImage(db_id)
            }
        },
    },
    mounted() {
        console.log('this.$route.params', this.$route.params)
        this.RoomCheckForm.fid = this.$route.params.id
        if(this.RoomCheckForm.fid) {
            this.GetCheckRoom(this.RoomCheckForm.fid)
            this.GetSignature()
        }

        this.building_no = this.$route.params.b_no
        this.floor_no = this.$route.params.f_no
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }

        this.GetResultTypes()
    }
}
</script>