<template>
    <div>
        <!-- <el-alert
            style="font-size: 20px"
            title="寰球健康公告"
            type="error"
            :description=error_alert>
        </el-alert> -->
        <el-alert type="error" center :closable="false" style="margin-bottom: 10px">
            <template slot='title'>
                <el-link icon="el-icon-thumb" @click="switchChange2">
                    <div style="text-decoration: underline; color: #409EFF;font-size: bold">新冠疫情管控地图</div>
                </el-link>
            </template>
        </el-alert>
        <el-alert type="success" center :closable="false">
            <template slot='title'>
                <el-link icon="el-icon-thumb" @click="switchChange">
                    <div style="text-decoration: underline; color: #409EFF;font-size: bold">新冠疫情最新消息(泉州本地宝)</div>
                </el-link>
            </template>
        </el-alert>
        <el-row style="margin-bottom: 20px;">
            <el-col :span="24">
                <router-link to="/healthyAdd">
                    <el-button style="width: 100%" type="primary">健康打卡</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-row v-if="pnl === '4235' || pnl === '0165' || pnl === '0270' || pnl === '55129'" style="margin-bottom: 20px;">
            <el-col :span="24">
                <router-link to="/healthyNoticeAdmin">
                    <el-button style="width: 100%" type="info">健康打卡公告管理</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-row v-if="authority > 0" style="margin-bottom: 20px;">
            <el-col :span="24">
                <router-link to="/healthyAddAdmin">
                    <el-button style="width: 100%" type="success">健康打卡管理页面</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-row v-if="pnl === '4235' || pnl === '0165' || pnl === '55129'" style="margin-bottom: 20px;">
            <el-col :span="24">
                <el-button style="width: 100%" type="danger" :loading="isLoading"
                    @click="checkSendMessage">提醒打卡推送（{{unNum}}）</el-button>
            </el-col>
        </el-row>
        <el-row v-if="pnl === '55129'" style="margin-bottom: 20px;">
            <el-col :span="24">
                <router-link to="/healthyPlusAdmin">
                    <el-button style="width: 100%" type="warning">春节返厂管理页面</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-dialog :visible.sync="dialogTableVisible" size="mini" width="90%">
            <span slot="title" class="dialog-footer">
                <div style="text-align: left;font-size: 16px;color: #F56C6C;font-weight: bold">确定全员推送健康打卡提醒?</div>
                <div v-if="is_show">请稍后，正在发送...</div>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogTableVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="sendMessage">确定</el-button>
            </span>
        </el-dialog>
        <el-card :id="item.DB_ID" shadow="always" v-for="(item, index) in healthy_list" :key="index" class="box-card">
            <el-row>
                <el-col :span="5" style="text-align: left">个人信息</el-col>
                <el-col :span="19" style="text-align: right">
                    <!-- <el-tag
                        v-if="!item.ISGOOUT"
                        type="success"
                        size="mini"
                        effect="dark">
                        无外出
                    </el-tag> -->
                    <el-tag
                        v-if="!item.IS_HOT"
                        type="success"
                        size="mini"
                        effect="dark">
                        无发热
                    </el-tag>
                    <el-tag
                        v-if="item.IS_HOT"
                        type="danger"
                        size="mini"
                        effect="dark">
                        发热
                    </el-tag>
                    <el-tag
                        style="margin-left: 5px"
                        v-if="item.AGENT_TIME"
                        type="success"
                        size="mini"
                        effect="dark">
                        疫苗*{{item.AGENT_TIME}}
                    </el-tag>
                    <!-- <el-tag
                        style="margin-left: 5px"
                        v-if="item.HESUAN === '阴性'"
                        type="success"
                        size="mini"
                        effect="dark">
                        核酸{{item.HESUAN}}
                    </el-tag>
                    <el-tag
                        style="margin-left: 5px"
                        v-if="item.HESUAN === '阳性'"
                        type="danger"
                        size="mini"
                        effect="dark">
                        核酸{{item.HESUAN}}
                    </el-tag> -->
                    <el-tag
                        style="margin-left: 5px"
                        v-if="item.HEALTH_CODE === 0"
                        type="success"
                        size="mini"
                        effect="dark">
                        绿码
                    </el-tag>
                    <el-tag
                        style="margin-left: 5px"
                        v-if="item.HEALTH_CODE === 1"
                        type="warning"
                        size="mini"
                        effect="dark">
                        黄码
                    </el-tag>
                    <el-tag
                        style="margin-left: 5px"
                        v-if="item.HEALTH_CODE === 2"
                        type="danger"
                        size="mini"
                        effect="dark">
                        红码
                    </el-tag>
                    <el-tag
                        style="margin-left: 5px"
                        v-if="!item.TRAVEL_CODE"
                        type="success"
                        size="mini"
                        effect="dark">
                        不带*
                    </el-tag>
                    <el-tag
                        style="margin-left: 5px"
                        v-if="item.TRAVEL_CODE"
                        type="danger"
                        size="mini"
                        effect="dark">
                        带*
                    </el-tag>
                </el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="5" style="text-align: left">当前位置</el-col>
                <el-col :span="19" style="text-align: right">{{item.HABITATION}}</el-col>
            </el-row>
            <el-divider/>
            <!-- <el-row v-if="item.ISGOOUT">
                <el-col :span="5" style="text-align: left">外出信息</el-col>
                <el-col :span="19" style="text-align: right">{{item.OUTADDRESS1}} - {{getTraffic(item.OUTTRAFFIC1)}}</el-col>
            </el-row>
            <el-divider v-if="item.ISGOOUT" /> -->
            <el-row>
                <el-col :span="5" style="text-align: left">入闽信息</el-col>
                <!-- <el-col :span="19" v-if="!item.IS_BACK" style="text-align: right">预计返厂日期：{{item.PLANBACK_DATE}}</el-col>
                <el-col :span="19" v-if="item.IS_BACK" style="text-align: right">{{getTraffic(item.VEHICLE)}}</el-col> -->
                <el-col :span="19" v-if="!item.IS_BACK" style="text-align: right">{{item.INFO}}</el-col>
            </el-row>
            <!-- <el-divider/>
            <el-row>
                <el-col :span="7" style="text-align: left">紧急联系信息</el-col>
                <el-col :span="17" style="text-align: right">{{item.URGENT_CONTACTS}}，{{item.URGENT_PHONE}}</el-col>
            </el-row> -->
            <el-divider/>
            <el-row>
                <el-col :span="5" style="text-align: left">核酸信息</el-col>
                <el-col :span="19" style="text-align: right">
                    {{item.CHECK_DATE}}
                    <el-tag
                        style="margin-left: 5px"
                        v-if="item.CHECK_RESULT === 0 && item.CHECK_DATE !== null"
                        type="success"
                        size="mini"
                        effect="dark">
                        阴性
                    </el-tag>
                    <el-tag
                        style="margin-left: 5px"
                        v-if="item.CHECK_RESULT === 1 && item.CHECK_DATE !== null"
                        type="danger"
                        size="mini"
                        effect="dark">
                        阳性
                    </el-tag>
                    <el-tag
                        style="margin-left: 5px"
                        v-if="item.CHECK_RESULT1 === 2 && item.CHECK_DATE !== null"
                        type="warning"
                        size="mini"
                        effect="dark">
                        未知
                    </el-tag>
                    {{item.CHECK_DATE2}}
                    <el-tag
                        style="margin-left: 5px"
                        v-if="item.CHECK_RESULT2 === 0 && item.CHECK_DATE2 !== null"
                        type="success"
                        size="mini"
                        effect="dark">
                        阴性
                    </el-tag>
                    <el-tag
                        style="margin-left: 5px"
                        v-if="item.CHECK_RESULT2 === 1 && item.CHECK_DATE2 !== null"
                        type="danger"
                        size="mini"
                        effect="dark">
                        阳性
                    </el-tag>
                    <el-tag
                        style="margin-left: 5px"
                        v-if="item.CHECK_RESULT2 === 2 && item.CHECK_DATE2 !== null"
                        type="warning"
                        size="mini"
                        effect="dark">
                        未知
                    </el-tag>
                    {{item.CHECK_DATE3}}
                    <el-tag
                        style="margin-left: 5px;margin-top: 5px"
                        v-if="item.CHECK_RESULT3 === 0 && item.CHECK_DATE3 !== null"
                        type="success"
                        size="mini"
                        effect="dark">
                        阴性
                    </el-tag>
                    <el-tag
                        style="margin-left: 5px;margin-top: 5px"
                        v-if="item.CHECK_RESULT3 === 1 && item.CHECK_DATE3 !== null"
                        type="danger"
                        size="mini"
                        effect="dark">
                        阳性
                    </el-tag>
                    <el-tag
                        style="margin-left: 5px;margin-top: 5px"
                        v-if="item.CHECK_RESULT3 === 2 && item.CHECK_DATE3 !== null"
                        type="warning"
                        size="mini"
                        effect="dark">
                        未知
                    </el-tag>
                </el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="5" style="text-align: left">提报日期</el-col>
                <el-col :span="19" style="text-align: right">{{item.HDATE}}</el-col>
            </el-row>
        </el-card>
        <el-empty v-if="healthy_list.length === 0" description="没有数据"></el-empty>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import moment from 'moment'
export default {
    data() {
        return {
            pnl: '',
            authority: '',
            healthy_list: [],
            activeNames: ['1'],
            traffic_options: [{
                value: '1',
                label: '飞机'
            },{
                value: '2',
                label: '动车/高铁'
            },{
                value: '3',
                label: '火车'
            },{
                value: '4',
                label: '自驾/坐私家车'
            },{
                value: '5',
                label: '电动车、摩托车等'
            }],
            unNum: 0,
            isLoading: false,
            is_show: false,
            dialogTableVisible: false,
            error_alert: '，你好！由于你目前处在中高风险地区，依疫情防控的要求，所以请你暂时不要返厂。请与你的直属干部保持联系，'
                + '需要接到公司的电话通知方能返厂。请你持续关注 “泉州本地宝”微信公众号，及时查询其中有关返泉的最新管控政策并提前做好准备，'
                + '以免你在出行过程中受阻。谢谢配合！温馨提醒：少出门，少集聚；勤洗手，勤通风；戴口罩，讲卫生；有症状，早就医；不恐慌，不传谣。'
        }
    },
    methods: {
        checkSendMessage() {
            this.dialogTableVisible = true
        },
        async sendMessage() {
            this.is_show = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlhealthys/SendUnHealthyMessage', qs.stringify({
                pnl: this.pnl
            }))
            
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                
                this.$message({
                    message: '健康打卡提醒推送：' + res.data.Message,
                    center: true,
                    type: 'success'
                })
            }else {
                this.$message({
                    message: '健康打卡提醒推送：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }

            this.is_show = false
            this.dialogTableVisible = false
        },
        getTraffic(id) {
            let label = ''
            this.traffic_options.map(item => {
                if(item.value === id) {
                    label = item.label
                }
            })
            return label
        },
        switchChange() {
            window.location.href = 'http://m.qz.bendibao.com/news/xinxingfeiyan'
        },
        switchChange2() {
            window.location.href = 'https://map.wap.qq.com/online/h5-theme-map/Index.html?configId=448&enter_from=share'
        },
        getClass(TP) {
            if(TP > 37.3) {
                return this.colclass = 'col-danger'
            }else {
                return this.colclass = 'col-success'
            }
        },
        async GetHealthyList() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlhealthys/HealthyList', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.healthy_list = []
                res.data.ResponseData.map(item => {
                    item.PLANBACK_DATE = moment(item.PLANBACK_DATE).format('YYYY-MM-DD')
                    item.HDATE = moment(item.HDATE).format('YYYY-MM-DD')
                    item.ARRIVE_DATE = moment(item.ARRIVE_DATE).format('YYYY-MM-DD')
                    item.INFO = this.getTraffic(item.VEHICLE) + '，' + item.ARRIVE_DATE
                   
                    // if(item.CHECK_DATE2) {
                    //     item.CHECK_TIME = item.CHECK_DATE2
                    // }else {
                    //     if(item.CHECK_DATE) {
                    //         item.CHECK_TIME = item.CHECK_DATE
                    //     }
                    // }

                    if(item.CHECK_DATE) {
                        item.CHECK_DATE =  moment(item.CHECK_DATE).format('YYYY-MM-DD')
                    }
                    if(item.CHECK_DATE2) {
                        item.CHECK_DATE2 =  moment(item.CHECK_DATE2).format('YYYY-MM-DD')
                    }
                    if(item.CHECK_DATE3) {
                        item.CHECK_DATE3 =  moment(item.CHECK_DATE3).format('YYYY-MM-DD')
                    }
                    
                    // if(item.CHECK_DATE2) {
                    //     item.HESUAN = item.CHECK_RESULT2 ? '阳性' : '阴性'
                    // }else {
                    //     if(item.CHECK_DATE) {
                    //         item.HESUAN = item.CHECK_RESULT ? '阳性' : '阴性'
                    //     }
                    // }

                    this.healthy_list.push(item)
                })
            }else {
                this.$message({
                    message: '获取健康数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetTotalUnCount() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlhealthys/GetTotalUnCount')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                console.log(res.data.ResponseData)
                this.unNum = res.data.ResponseData[0].Column1
            }
        },
        async GetAdmin() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlhealthys/GetHealthyAuthority', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.authority = res.data.ResponseData
            }
        }
    },
    mounted() {
        // if(this.pnl) {
        //     const loading = this.$loading({
        //         lock: true,
        //         text: '拼命加载中',
        //         spinner: 'el-icon-loading',
        //         background: 'rgba(0, 0, 0, 0.7)'
        //     })

        //     this.GetAdmin() 
        //     this.GetHealthyList()
        //     loading.close()
        // }
        
        this.GetAdmin() 
        this.GetTotalUnCount()
        this.GetHealthyList()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
            // this.pnl = '49462' // dev
            // this.pnl = '4235' // dev
            // this.pnl = '0165' // dev
            // this.pnl = '51528' // dev
        }
    }
}
</script>

<style scoped>
    /* body{
        margin: 0;
        padding: 0;
    } */
    .box-card{
        margin-top: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 13px;
        /* border-radius: 10px; */
    }
    .col-danger {
        color: #F56C6C;
        font-weight: bold;
    }
    .col-success {
        color: #67C23A;
        font-weight: bold;
    }
    .col-primary {
        color: #409EFF;
        font-weight: bold;
    }
</style>