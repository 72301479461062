<template>
    <div>
        <el-form :model="salaryForm" :rules="rules" ref="salaryForm" label-width="80px" size="small">
            <el-form-item label="薪资月份" prop="yymm">
                <el-date-picker
                    v-model="salaryForm.yymm"
                    placeholder="请选择薪资月份"
                    size="medium"
                    type="month"
                    style="width: 100%"
                    @change="GetSalaryList">
                </el-date-picker>
            </el-form-item>
        </el-form>
        <el-empty v-if="!isShow" description="没有数据"></el-empty>
        <el-alert type="error" v-if="isShow" show-icon>
            <template slot='title'>
                <div class="iconSize">以下为您查询月份的薪资明细，若有疑问请在发薪日前3天内反馈给您的直接主管或通过内线电话314向财务部反馈，若无反馈则视为您对薪资无异议！</div>
            </template>
        </el-alert>
        <el-descriptions :column="4" v-loading="total_loading" size="small" direction="vertical" border v-if="isShow" style="margin-bottom: 20px">
            <el-descriptions-item>
                <template slot="label">
                    等级
                </template>
                    <div class="total_data" style="color: #E6A23C">{{dj}}</div>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    应发
                </template>
                    <div class="total_data" style="color: #409EFF">{{yf}}</div>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    应扣
                </template>
                    <div class="total_data" style="color: #F56C6C">{{yk}}</div>
            </el-descriptions-item>
             <el-descriptions-item>
                <template slot="label">
                    实发
                </template>
                    <div class="total_data" style="color: #67C23A">{{sf}}</div>
            </el-descriptions-item>
        </el-descriptions>
        <el-collapse v-if="isShow" v-model="activeNames" @change="handleChange">
            <el-collapse-item title="应发项目" name="1">
                <el-table
                    stripe
                    border
                    v-loading="yf_table_loading"
                    :show-header="false"
                    size="small"
                    :data="yf_tableData"
                    style="width: 100%;margin-bottom: -26px">
                    <el-table-column prop="sf_nm">
                    </el-table-column>
                    <el-table-column prop="amt" align="right" :formatter="formatter">
                    </el-table-column>
                </el-table>
            </el-collapse-item>
            <el-collapse-item title="应扣项目" name="2">
                <el-table
                    stripe
                    border
                    v-loading="yk_table_loading"
                    :show-header="false"
                    size="small"
                    :data="yk_tableData"
                    style="width: 100%;margin-bottom: -26px">
                    <el-table-column prop="sf_nm">
                    </el-table-column>
                    <el-table-column prop="amt" align="right" :formatter="formatter">
                    </el-table-column>
                </el-table>
            </el-collapse-item>
            <el-collapse-item title="考勤明细" name="3">
                <el-table
                    border
                    stripe
                    size="small"
                    :show-header="false"
                    :data="kq_tableData"
                    style="width: 100%;margin-bottom: -26px">
                    <el-table-column prop="name">
                    </el-table-column>
                    <el-table-column align="right" prop="num">
                    </el-table-column>
                </el-table>
            </el-collapse-item>
        </el-collapse>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import moment from 'moment'
export default {
    data() {
        return {
            salaryForm: {
                yymm: ''
            },
            isShow: false,
            pnl: '',
            activeNames: ['0'],
            yf_tableData: [],
            yk_tableData: [],
            kq_tableData: [],
            yf: 0,
            yk: 0,
            sf: 0,
            dj: 0,
            rules: {
                yymm: [
                    {required: true, message: '请选择薪资月份', trigger: 'change'}
                ]
            },
            yf_table_loading: false,
            yk_table_loading: false,
            total_loading: false
        }
    },
    methods: {
        formatter(row) {
            if(row.amt) {
                return row.amt.toFixed(2)
            }
        },
        async GetSalaryList() {
            if(moment(this.salaryForm.yymm).format('YYYY-MM') === 'Invalid date') {
                return
            }
            this.total_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Salary/SalaryList', qs.stringify({
                date: moment(this.salaryForm.yymm).format('YYYY-MM-DD'),
                pnl: this.pnl,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                // this.isShow = true
                this.isShow = res.data.ResponseData.length > 0 ? true : false
                this.kq_tableData = []
                this.yf = 0
                this.yk = 0
                this.sf = 0
                this.dj = 0
                res.data.ResponseData.forEach(item => {
                    this.yf = item.iamt
                    this.yk = item.oamt
                    this.sf = item.samt
                    this.dj = item.sdj_no

                    this.kq_tableData.push({
                        name: '工作天数',
                        num: item.w_day
                    })
                    this.kq_tableData.push({
                        name: '请假天数',
                        num: item.vh_day
                    })
                    this.kq_tableData.push({
                        name: '请假时间（时）',
                        num: item.h_time
                    })
                    this.kq_tableData.push({
                        name: '公休天数',
                        num: item.x_day
                    })
                    this.kq_tableData.push({
                        name: '旷工天数',
                        num: item.k_day
                    })
                    // this.kq_tableData.push({
                    //     name: '加班天数',
                    //     num: item.days1
                    // })
                    // this.kq_tableData.push({
                    //     name: '加班旷工',
                    //     num: item.oamt21
                    // })
                    // this.kq_tableData.push({
                    //     name: '加班请假',
                    //     num: item.oamt21
                    // })
                    this.kq_tableData.push({
                        name: '平时加班（时）',
                        num: item.j_time1
                    })
                    this.kq_tableData.push({
                        name: '周六加班（时）',
                        num: item.j_time2
                    })
                    this.kq_tableData.push({
                        name: '节日加班（时）',
                        num: item.j_time3
                    })
                    this.kq_tableData.push({
                        name: '特殊加班（时）',
                        num: item.j_time4
                    })
                    this.kq_tableData.push({
                        name: '周天加班（时）',
                        num: item.j_time5
                    })
                    this.kq_tableData.push({
                        name: '迟到次数',
                        num: item.f_num
                    })
                    this.kq_tableData.push({
                        name: '迟到时间（分）',
                        num: item.f_time
                    })
                    this.kq_tableData.push({
                        name: '早退次数',
                        num: item.b_num
                    })
                    this.kq_tableData.push({
                        name: '早退时间（分）',
                        num: item.b_time
                    })
                    // this.kq_tableData.push({
                    //     name: '打卡异常次数',
                    //     num: item.oamt21
                    // })
                    this.kq_tableData.push({
                        name: '旷工时间（时）',
                        num: item.k_time
                    })
                    this.kq_tableData.push({
                        name: '正班缺勤',
                        num: item.days
                    })
                    this.kq_tableData.push({
                        name: '加班缺勤',
                        num: item.days1
                    })
                    // this.kq_tableData.push({
                    //     name: '有薪假（正班）',
                    //     num: item.days1
                    // })
                    // this.kq_tableData.push({
                    //     name: '有薪假（加班）',
                    //     num: item.days1
                    // })
                    this.kq_tableData.push({
                        name: '是否全勤',
                        num: item.isqq === 'Y' ? '是' : '否'
                    })
                })
                this.activeNames = ['0']
            }
            this.total_loading = false
        },
        handleChange(val) {
            if(val[val.length - 1] === '1') {
                this.GetYFSalary()
            }else if(val[val.length - 1] === '2') {
                this.GetYKSalary()
            }
        },
        async GetYFSalary() {
            this.yf_table_loading = true
            this.yf_tableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Salary/GetSalaryList', qs.stringify({
                date: moment(this.salaryForm.yymm).format('YYYY-MM'),
                pnl: this.pnl,
                type: '1'
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.yf_tableData = res.data.ResponseData
            }
            this.yf_table_loading = false
        },
        async GetYKSalary() {
            this.yk_table_loading = true
            this.yk_tableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Salary/GetSalaryList', qs.stringify({
                date: moment(this.salaryForm.yymm).format('YYYY-MM'),
                pnl: this.pnl,
                type: '2'
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.yk_tableData = res.data.ResponseData
            }
            this.yk_table_loading = false
        },
    },
    mounted() {
        this.GetSalaryList()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
            // this.pnl = '0325'
            // this.pnl = '56433'
            // this.pnl = '58698'
            // this.pnl = '6106' // dev
            // this.pnl = '0165' // dev
            // this.pnl = '20914' // dev
            // this.pnl = '57820' // dev
            // this.pnl = '55427' // dev
            // this.pnl = '19301' // dev
            // this.pnl = '25112' // dev
            // this.pnl = '24373' // dev
            // this.pnl = '0030' // dev
            // this.pnl = '5987'
            // this.pnl = '49187' // dev
        }
    }
}
</script>

<style scoped>
    .total_data{
        font-size: 16px;
        font-weight: bold;
    }
</style>