<template>
    <div >
        <h4>{{ title }}</h4>
        <h5 class="name">{{ name }}</h5>
        <div class="content" >
            <div class="content_item" v-for="(content,index) in contents" :key="index">
                <span :style="content_item.style" v-for="(content_item,index_item) in content.content" :key="index_item">
                    {{ content_item.label }}</span>
            </div>
        </div>
        <div class="sign">
            <div>{{ sign_comp }}</div>
            <div style="margin-right: 30px">{{ sign_sec }}</div>
            <div style="margin-right: 20px">{{ sign_date }}</div>
        </div>
        <el-button round style="width: 100%;margin-top: 30px" type="primary" :disabled="disabled">{{ btn_notice }}（{{ countdown }}）</el-button>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import moment from 'moment'
export default {
    data() {
        return {
            color: {
                color: '#F56C6C'
            },
            title: '限期返岗通知书',
            name: '尊敬的XXX女士/先生：',
            contents: [],
            content: [],
            // contents: [{
            //     content: [{
            //         label: '您于'+moment(this.ts).add(-3, 'days').format('YYYY年M月D日')+'起，未办理准假手续，擅自离岗长达3日，期间我司已电话通知您本人返岗，但您至今未返岗。',
            //         style: {
            //             color: '#606266',
            //             // fontWeight: 'bold',
            //             marginLeft: '20px'
            //         },
            //     },{
            //         label: '现通知您于'+moment(this.ts).add(1, 'days').format('YYYY年M月D日')+'前到公司报到返岗，逾期未报到返岗的，根据国家法律和公司相关制度，我司视您本人自动离职，将与您正式解除劳动关系。',
            //         style: {
            //             color: '#F56C6C'
            //         },
            //     }]
            // },{
            //     content: [{
            //         label: '特此通知！',
            //         style: {
            //             color: '#606266',
            //             marginLeft: '20px'
            //         },
            //     }]
            // }],
            sign_comp: '泉州寰球鞋服有限公司',
            sign_sec: '人力资源部',
            sign_date: '',
            disabled: true,
            btn_notice: '请认真阅读',
            countdown: null, // 初始值设置为null或者任意默认值
            pnl: '',
            ts: '',
        }
    },
    mounted() {
        this.sign_date = moment.unix(this.ts).format('YYYY年M月D日')
        let content1 = {
            label: '您于'+moment.unix(this.ts).add(-3, 'days').format('YYYY年M月D日')+'起，未办理准假手续，擅自离岗长达3日，期间我司已电话通知您本人返岗，但您至今未返岗。',
            style: {
                color: '#606266',
                marginLeft: '20px'
            },
        }
        let content2 = {
            label: '现通知您于'+moment.unix(this.ts).add(1, 'days').format('YYYY年M月D日')+'前到公司报到返岗，逾期未报到返岗的，根据国家法律和公司相关制度，我司视您本人自动离职，将与您正式解除劳动关系。',
            style: {
                color: '#F56C6C'
            },
        }
        let content3 = {
            label: '特此通知！',
            style: {
                color: '#606266',
                marginLeft: '20px'
            },
        }

        this.content.push(content1)
        this.content.push(content2)
        const contents = {
            content: this.content
        }

        this.contents.push(contents)
        const contents2 = {
            content: [content3]
        }

        this.contents.push(contents2)
        this.getPersonInfo()
        this.startCountdown() // 组件加载完成后开始倒计时
    },
    methods: {
        startCountdown() {
            let seconds = 20 // 定义需要倒计时的秒数
            const timer = setInterval(() => {
                if (seconds > 0) {
                    this.countdown = `${seconds}s` // 更新显示的倒计时文本
                    seconds-- // 每次递减1秒
                } else {
                    clearInterval(timer) // 当倒计时结束时清除定时器
                    this.countdown = '已知悉' // 可选：设置倒计时结束时的文本
                    // this.disabled = false
                    // this.btn_notice = '同意，并提交'
                    this.btn_notice = ''
                }
            }, 1000); // 每隔1秒执行一次
        },
        async getPersonInfo() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsPersons/DetailInfo', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                let pname = res.data.ResponseData[0].pname
                let gender_nm = res.data.ResponseData[0].gender === '1' ? '先生' : '女士'
                this.name = '尊敬的' + pname + gender_nm + '：'
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        }
    },
    created() {
        const pnl = this.$route.params.pnl
        if(pnl) {
            this.pnl = pnl
        }
        const ts = this.$route.params.ts
        if(ts) {
            this.ts = ts
        }
    }
}
</script>

<style scoped>
.name {
    text-align: left;
    font-size: 12px;
}
.content {
    text-align: left;
    font-size: 12px;
    /* line-height: 18px; */
    line-height: 28px;
}
.content_item{
    margin-top: 10px;
    margin-bottom: 10px;
}
.sign {
    text-align: right;
    font-size: 12px;
    font-weight: bold;
    margin-top: 28px;
}
.sign div{
    margin-bottom: 10px;
}
</style>