<template>
    <div>
        <el-row style="margin-bottom: 20px;">
            <el-col :span="24">
                <router-link to="/feedback">
                    <el-button style="width: 100%" type="primary">异常反馈</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-card shadow="always" v-for="item in feedBackList" :key="item.DB_ID" class="box-card" v-loading="loading">
            <el-row>
                <el-col :span="6" style="text-align: left">异常类别</el-col>
                <el-col :span="14" style="text-align: right">{{item.entity.FTYPE}}</el-col>
                <el-col :span="4" style="text-align: right">
                    <el-tag
                        v-if="item.entity.FSTATUS === '已提交待回复'"
                        style="margin-left: 5px"
                        type="danger"
                        size="mini"
                        effect="dark">
                        {{ item.entity.FSTATUS === '已提交待回复' ? '待回复' : '' }}
                    </el-tag>
                    <el-tag
                        v-if="item.entity.FSTATUS === '采购已回复'"
                        style="margin-left: 5px"
                        type="danger"
                        size="mini"
                        effect="dark">
                        {{ item.entity.FSTATUS === '采购已回复' ? '已回复' : '' }}
                    </el-tag>
                    <!-- <el-tag
                        v-if="item.ISEND === 1"
                        style="margin-left: 5px"
                        type="danger"
                        size="mini"
                        effect="dark">
                        完结
                    </el-tag> -->
                </el-col>
            </el-row>
            <el-divider/>
             <el-row>
                <el-col :span="6" style="text-align: left">异常内容</el-col>
                <el-col :span="18" style="text-align: right">{{item.entity.FREMARK}}</el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="6" style="text-align: left">异常日期</el-col>
                <el-col :span="18" style="text-align: right">{{item.entity.FDATE}}</el-col>
            </el-row>
            <el-divider v-if="item.entity.HAVE_IMAGE !== 0" />
                <el-row v-if="item.entity.HAVE_IMAGE !== 0">
                    <el-col :span="6" style="text-align: left">问题图片</el-col>
                    <el-col :span="18" style="text-align: right">
                        <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.entity.DB_ID)">
                            <i class="el-icon-picture-outline"></i> 点击查看
                        </el-link>
                    </el-col>
                </el-row>
            <el-divider v-if="item.lists.length > 0" />
            <el-row  v-for="items1 in item.lists" :key="items1.FID" >
                <el-col v-if="items1.FREMARK" :span="6" style="text-align: left">回复内容</el-col>
                <el-col v-if="items1.FREMARK" :span="18" style="text-align: right">{{items1.FREMARK}}</el-col>
            </el-row>
            <el-divider v-if="isManager && item.entity.FSTATUS === '已提交待回复'"/>
                <el-row v-if="isManager && item.entity.FSTATUS === '已提交待回复'">
                    <el-col :span="24">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="success" 
                            @click="Handing(item.entity.FID)">单据回复</el-button>
                    </el-col>
                </el-row>
            <!-- <el-divider v-if="item.lists.length > 0" />
            <el-row v-for="items2 in item.lists" :key="items2.DB_ID" >
                <el-col :span="6" style="text-align: left">回复日期</el-col>
                <el-col :span="18" style="text-align: right">{{items2.FDATE}}</el-col>
            </el-row> -->
        </el-card>
        <el-empty v-if="feedBackList.length === 0" description="没有数据"></el-empty>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            loading: false,
            feedBackList: [],
            // feedBackLists: [],
            pnl: '',
            imageList: [],
            isManager: false,
        }
    },
    methods: {
        Handing(fid) {
            window.location.href = '/handing/' + fid
        },
        ShowImages(id) {
            this.GetImagesByID(id)
        },
        async GetImagesByID(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        // this.imageList.push({
                        //     id: item.DB_ID,
                        //     path: conf.image_domain + item.FImgPath
                        // })
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                           path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
                console.log('this.imageList', this.imageList)
            }
        },
        async GetFeedBackList() {
            this.loading = true
            this.feedBackList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxFeedbacks/GetFeedbackList', qs.stringify({
                pnl: this.pnl,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.feedBackList = res.data.ResponseData
                // this.feedBackLists = res.data.ResponseData.lists
                console.log(' this.feedBackList',  this.feedBackList)
            }else {
                this.$message({
                    message: '获取异常反馈数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.loading = false
        },
        async GetAdmin() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxFeedbacks/GetFeedbackAdmins', qs.stringify({
                pnl: this.pnl,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.map(item => {
                    if(this.pnl === item.pnl) {
                        console.log('item', item)
                        this.isManager = true
                        return
                    }
                })
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
    },
    mounted() {
        this.GetAdmin() 
        this.GetSignature()
        this.GetFeedBackList()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            // this.pnl = '55129' // dev
            // this.pnl = '28111' // dev
        }
    }
}
</script>

<style scoped>
    .box-card{
        margin-top: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 13px;
        /* border-radius: 10px;  */
    } 
    .col-danger {
        color: #F56C6C;
        font-weight: bold;
    }
    .col-success {
        color: #67C23A;
        font-weight: bold;
    }
    .col-primary {
        color: #409EFF;
        font-weight: bold;
    }
</style>