<template>
    <div>
        <el-alert v-if="is_pnl" type="error" effect="light" style="margin-bottom: 30px;">
            <template slot='title'>
                <div class="iconSize" style="text-align: left;">注意：您的登录已过期，请刷新！</div>
            </template>
        </el-alert>
        <el-row style="margin-bottom: 25px;">
            <el-col :span="24">
                <el-button 
                    size="medu" 
                    type="primary" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI">扫码签到</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            is_pnl: false,
            pnl: '',
            meeting_list: []
        }
    },
    methods: {
        async GetMeetingList() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqPersonMeetings/GetMeetingList', qs.stringify({
                pnl: this.pnl,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.meeting_list = res.data.ResponseData
            }
        },
        GetJSAPI() {
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
                    window.location.href = result
                }
            })
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'], // 必填，需要使用的JS接口列表
                })
            }
        },
    },
    mounted() {
        this.GetSignature()
        if(!this.pnl) {
            this.is_pnl = true
        }

        this.GetMeetingList()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>