<template>
    <div>
        <el-form :model="firstConfirmCreateForm" ref="firstConfirmCreateForm" :rules="rules" label-width="80px" size="small">
            <el-form-item label="制程" prop="made_no" style="font-weight: bold">
                <el-select 
                    @change="MadeChange"
                    v-model="firstConfirmCreateForm.made_no" 
                    placeholder="请选择制程" 
                    style="width: 100%">
                    <el-option
                        v-for="item in made_nos"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item 
                v-if="firstConfirmCreateForm.made_no === '7' || firstConfirmCreateForm.made_no === '3'" 
                label="主部门" 
                prop="sec_no1" 
                style="font-weight: bold">
                <el-select 
                    @change="MainSecChange"
                    v-model="firstConfirmCreateForm.sec_no1" 
                    placeholder="请选择主部门" 
                    style="width: 100%">
                    <el-option
                        v-for="item in sec_no1s"
                        :key="item.sec_no1"
                        :label="item.sec_nm1"
                        :value="item.sec_no1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="部门" prop="sec_no" style="font-weight: bold">
                <el-select 
                    v-model="firstConfirmCreateForm.sec_no" 
                    placeholder="请选择部门" 
                    style="width: 100%">
                    <el-option
                        v-for="item in sec_nos"
                        :key="item.sec_no"
                        :label="item.sec_nm"
                        :value="item.sec_no">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="指令号" prop="odr_no" style="font-weight: bold">
                <el-row>
                    <el-col :span="19">
                        <el-input 
                            disabled
                            @focus="OdrNoFocus"
                            v-model="firstConfirmCreateForm.odr_no" 
                            style="font-weight: bold"> 
                        </el-input>
                    </el-col>
                    <el-col :span="5">
                        <el-button 
                            @click="dialogVisible = true"
                            style="margin-left: 10px;width: 85%;"
                            icon="el-icon-more"
                            type="primary">
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="尺码" prop="osize" style="font-weight: bold">
                <el-select 
                    v-model="firstConfirmCreateForm.osize" 
                    placeholder="请选择尺码" 
                    style="width: 100%">
                    <el-option
                        v-for="item in osizes"
                        :key="item.osize"
                        :label="item.osize"
                        :value="item.osize">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="数量" prop="qty" style="font-weight: bold">
                <el-input-number v-model="firstConfirmCreateForm.qty" :min="1" style="width: 100%"></el-input-number>
            </el-form-item>
            <el-table
                :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
                ref="multipleTable2"
                height="360px"
                size="mini"
                tooltip-effect="dark"
                @selection-change="handleSelectionChange2"
                :data="firstTables"
                v-loading="tableLoading2" 
                style="width: 100%;margin-bottom: 20px">
                <el-table-column type="selection" width="45">
                </el-table-column>
                <el-table-column label="外观 | 辅料 | 包装信息" prop="FieldCaption">
                </el-table-column>
            </el-table>
            <el-form-item label="确认结果" prop="result" style="font-weight: bold">
                <el-select 
                    v-model="firstConfirmCreateForm.result" 
                    placeholder="请选择确认结果" 
                    style="width: 100%">
                    <el-option
                        v-for="item in results"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="remark" style="font-weight: bold">
                <el-input v-model="firstConfirmCreateForm.remark" type="textarea" :rows="3" placeholder="请输入备注"></el-input>
            </el-form-item>
            <el-row style="margin-top: 10px;margin-bottom: 10px">
                <el-col :span="24">
                    <el-button 
                        :loading="isLoading"
                        style="width: 100%;"
                        type="primary" 
                        @click="SubmitForm('firstConfirmCreateForm')">提交</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-dialog title="选择指令号" :visible.sync="dialogVisible" width="90%">
            <el-row style="margin-bottom: 15px">
                <el-col :span="18">
                    <el-input 
                        size="mini"
                        placeholder="输入指令号、货号"
                        v-model="key" 
                        style="font-weight: bold"> 
                    </el-input>
                </el-col>
                <el-col :span="6">
                    <el-button 
                        size="mini"
                        @click="GetOdrNosByKey"
                        style="margin-left: 10px;width: 90%;"
                        icon="el-icon-search"
                        type="primary">搜索
                    </el-button>
                </el-col>
            </el-row>
            <el-table
                :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
                ref="multipleTable"
                height="280px"
                size="mini"
                tooltip-effect="dark"
                @selection-change="handleSelectionChange"
                :data="odrNoTables"
                v-loading="tableLoading" 
                style="width: 100%;">
                <el-table-column type="selection" min-width="45px">
                </el-table-column>
                <el-table-column label="指令号" prop="odr_no" min-width="110px">
                </el-table-column>
                <el-table-column label="货号" prop="artic_no">
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="SetOdrNo">提交</el-button>
            </div>
        </el-dialog>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
export default {
    data() {
        return {
            firstConfirmCreateForm: {
                pnl: '',
                made_no: '',
                sec_no1: '',
                sec_no: '',
                odr_no: '',
                osize: '',
                qty: 1,
                result: '',
                remark: ''
            },
            made_nos: [{ 
                value: '3',
                label: '帮面'
            },{ 
                value: '6',
                label: '贴合'
            },{ 
                value: '7',
                label: '成型'
            }], 
            sec_no1s: [],
            sec_nos: [],
            odr_nos: [],
            osizes: [],
            results: [{
                value: '1',
                label: '可大量生产'
            },{
                value: '2',
                label: '改善后可以生产'
            },{
                value: '3',
                label: '重做首件'
            }],
            odrNoTables: [],
            dialogVisible: false,
            tableLoading: false,
            key: '',
            multipleSelection: [],
            firstTables: [],
            tableLoading2: false,
            multipleSelection2: [],
            isLoading: false,
            rules: {
                sec_no: [
                    {required: true, message: '请选择部门', trigger: 'change'}
                ],
                odr_no: [
                    {required: true, message: '输入指令号', trigger: 'blur'}
                ],
                osize: [
                    {required: true, message: '请选择尺码', trigger: 'change'}
                ],
                qty: [
                    {required: true, message: '请输入', trigger: 'blur'}
                ],
                result: [
                    {required: true, message: '请选择确认结果', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CustomValid()){
                    this.SaveFirstConfirmData()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        CustomValid() {
            if(this.multipleSelection2.length === 0) {
                this.$message({
                    message: '请选择 外观 | 辅料 | 包装信息',
                    center: true,
                    type: 'error'
                })
                return false
            }
            return true
        },
        async SaveFirstConfirmData() {
            console.log('multipleSelection', this.multipleSelection2)
            this.isLoading = true
            let dtos = []
            let AleMesFirstDTO = {
                FLAG: this.firstConfirmCreateForm.made_no,
                OSIZE: this.firstConfirmCreateForm.osize,
                SEC_NO: this.firstConfirmCreateForm.sec_no,
                ODR_NO: this.firstConfirmCreateForm.odr_no,
                PNL: this.firstConfirmCreateForm.pnl,
                QTY: this.firstConfirmCreateForm.qty,
                RESULT: this.firstConfirmCreateForm.result,
                REMARK: this.firstConfirmCreateForm.remark
            }

            this.firstTables.map(item => {
                AleMesFirstDTO[item.FieldName] = 0
            })

            console.log('AleMesFirstDTO', AleMesFirstDTO)
            this.multipleSelection2.map(item => {
                AleMesFirstDTO[item.FieldName] = 1
            })

            dtos.push(AleMesFirstDTO)
            console.log('dtos', dtos)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/FirstConfirm/SaveFirstConfirm', qs.stringify({
                dtos
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: '保存成功！',
                    center: true,
                    type: 'success',
                    duration: 2000
                })
                window.location.href = '/firstConfirm'
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        MadeChange(made_no) {
            this.firstConfirmCreateForm.sec_no = ''
            this.firstConfirmCreateForm.sec_no1 = ''
            if(made_no === '7' || made_no === '3') {
                this.GetMainSecByMadeNo(made_no)
                this.sec_nos = []
            }else{
                this.GetSecByMadeNo(made_no)
            }

            let form_name = ''
            if(made_no === '3') {
                form_name = 'Frm_First_BM'
            }else if(made_no === '6') {
                form_name = 'Frm_First_TH'
            }else if(made_no === '7') {
                form_name = 'Frm_First_CX'
            }
            this.GetFirstFidldCaptions(form_name)
        },
        async GetMainSecByMadeNo(made_no) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/MainSecByMadeNo', qs.stringify({
                made_no
            }))
            console.log(res)
            this.sec_no1s = []
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.sec_no1s = res.data.ResponseData
                }
            }
        },
        async GetSecByMadeNo(made_no) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/SecByMadeNo', qs.stringify({
                made_no
            }))
            console.log(res)
            this.sec_nos = []
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.sec_nos = res.data.ResponseData
                }
            }
        },
        MainSecChange(sec_no1) {
            this.GetSecByMainSec(sec_no1)
        },
        async GetSecByMainSec(sec_no1) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/SecByMainSec', qs.stringify({
                sec_no1
            }))
            console.log(res)
            this.sec_nos = []
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.sec_nos = res.data.ResponseData
                }
            }
        },
        OdrNoFocus() {
            this.dialogVisible = true
        },
        async GetOdrNosByKey() {
            this.tableLoading = true
            this.odrNoTables = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/FirstConfirm/OdrNosByKey', qs.stringify({
                key: this.key
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.odrNoTables = res.data.ResponseData
                }
            }
            this.tableLoading = false
        },
        handleSelectionChange(val) {
            if(val.length > 1) {
                this.$refs.multipleTable.clearSelection()
                this.$refs.multipleTable.toggleRowSelection(val[val.length - 1])
                this.multipleSelection = []
                this.multipleSelection.push(val[val.length - 1])
            }
            else{
                this.multipleSelection = val
            }
        },
        SetOdrNo() {
            if(this.multipleSelection.length !== 1) {
                this.$message({
                    message: '请选择指令号！',
                    center: true,
                    type: 'error',
                    duration: 2000
                })
            }else {
                console.log('this.multipleSelection', this.multipleSelection)
                this.firstConfirmCreateForm.odr_no = this.multipleSelection[0].odr_no
                this.dialogVisible = false
                // 设置尺码
                this.GetOSizes()
            }
        },
        async GetOSizes() {
            this.osizes = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/FirstConfirm/OSizes', qs.stringify({
                odr_no: this.firstConfirmCreateForm.odr_no
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.osizes = res.data.ResponseData
                }
            }
        },
        async GetFirstFidldCaptions(made_no) {
            this.tableLoading2 = true
            this.firstTables = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/FirstConfirm/FirstFidldCaptions', qs.stringify({
                made_no
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.firstTables = res.data.ResponseData
                }
            }
            this.tableLoading2 = false
        },
        handleSelectionChange2(val) {
            this.multipleSelection2 = val
        },
    },
    mounted() {
        
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.firstConfirmCreateForm.pnl = pnl
        }
    }
}
</script>