<template>
    <div> 
        <el-form :model="healthyForm" :rules="rules" ref="healthyForm" label-width="90px" size="meduim">
            <el-descriptions class="margin-top" :column="1" size="meduim" border>
                <el-descriptions-item>
                    <template slot="label">
                    <i class="el-icon-user"></i>
                        个人信息
                    </template>
                    {{sec_nm}} - {{healthyForm.pname}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-date"></i>
                        提报日期
                    </template>
                    {{hdate}}
                </el-descriptions-item>
            </el-descriptions>
            <!-- <el-form-item label="个人信息" style="text-align: left;">
                {{sec_nm}} - {{pname}}  -->
                <!-- {{healthyForm.hdate}} -->
            <!-- </el-form-item>
            <el-form-item label="提报日期" style="text-align: left;">
                {{hdate}}
            </el-form-item> -->
            <!-- <el-form-item label="今日体温" prop="tp" style="text-align: left;margin-top: 20px;font-weight: bold">
                <el-input-number 
                    size="medium" 
                    :min="36"
                    :step="0.5"
                    :max="41"
                    :precision="2"
                    style="width: 85%;" 
                    placeholder="请输入今日体温" 
                    v-model="healthyForm.tp"></el-input-number> ℃
            </el-form-item> -->
            <el-form-item label="是否发热" prop="is_hot" style="text-align: left;margin-top: 20px;font-weight: bold">
                <!-- <el-select 
                    v-model="healthyForm.is_hot" 
                    size="medium" 
                    style="width: 100%">
                    <el-option
                        v-for="item in hot_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select> -->
                <!-- <el-switch
                    v-model="healthyForm.is_hot"
                    active-color="#ff4949"
                    inactive-color="#13ce66"
                    active-text="37.3°以上/咳嗽等症状"
                    inactive-text="否">
                </el-switch> -->
                <el-radio v-model="healthyForm.is_hot" :label="false">否</el-radio>
                <el-radio v-model="healthyForm.is_hot" :label="true">37.3°以上/咳嗽等症状</el-radio>
            </el-form-item>
            <el-form-item label="当前位置" prop="habitation" style="font-weight: bold">
                <el-input v-model="healthyForm.habitation" disabled> 
                    <el-button 
                        icon="el-icon-location-outline"
                        slot="append"
                        size="small" 
                        type="primary" 
                        @click="GetJSAPI"></el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="是否跨县市外出" prop="isgoout" style="font-weight: bold" label-width="132px">
                <el-switch
                    v-model="healthyForm.isgoout"
                    active-color="#ff4949"
                    inactive-color="#13ce66"
                    active-text="是"
                    inactive-text="否">
                </el-switch>
            </el-form-item> 
            <el-form-item v-if="healthyForm.isgoout" label="外出地点" size="small" label-width="110px">
                <el-input v-model="healthyForm.outAddress1" placeholder="请输入外出地点"></el-input>
            </el-form-item>
            <el-form-item v-if="healthyForm.isgoout" label="交通工具" size="small" label-width="110px">
                <!-- <el-input v-model="healthyForm.outAddress1" placeholder="请输入外出地点"></el-input> -->
                <el-select 
                    v-model="healthyForm.outTraffic1" 
                    placeholder="请选择交通工具" 
                    size="small" 
                    style="width: 100%">
                    <el-option
                        v-for="item in traffic_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item v-if="healthyForm.isgoout" label="外出地二" size="small" label-width="110px">
                <el-input v-model="healthyForm.outAddress2" placeholder="请输入外出地点"></el-input>
            </el-form-item>
            <el-form-item v-if="healthyForm.isgoout" label="外出地三" size="small" label-width="110px">
                <el-input v-model="healthyForm.outAddress3" placeholder="请输入外出地点"></el-input>
            </el-form-item>
            <el-form-item v-if="healthyForm.isgoout" label="外出地四" size="small" label-width="110px">
                <el-input v-model="healthyForm.outAddress4" placeholder="请输入外出地点"></el-input>
            </el-form-item> -->
            <el-form-item label="是否返厂" prop="is_back" style="font-weight: bold">
                <!-- <el-switch
                    v-model="healthyForm.is_back"
                    active-color="#ff4949"
                    inactive-color="#13ce66"
                    active-text="不确定"
                    inactive-text="确定">
                </el-switch> -->
                <el-switch
                    v-model="healthyForm.is_back"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="确定"
                    inactive-text="不确定">
                </el-switch>
            </el-form-item> 
            <el-form-item v-if="healthyForm.is_back" label="返厂方式" size="small" label-width="110px">
                <!-- <el-input v-model="healthyForm.Vehicle" placeholder="请输入返厂交通方式" ></el-input> -->
                <el-select 
                    @change="backType"
                    v-model="healthyForm.Vehicle" 
                    placeholder="请选择返厂方式" 
                    size="small" 
                    style="width: 100%">
                    <el-option
                        v-for="item in traffic_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="!other && healthyForm.is_back" size="small" label="班次" label-width="110px">
                <el-input v-model="healthyForm.flight_no" placeholder="请输入班次号"></el-input>
            </el-form-item>
            <el-form-item v-if="other && healthyForm.is_back" size="small" label-width="40px">
                <el-input v-model="healthyForm.other_Vehicle" placeholder="请输入其他返厂交通方式"></el-input>
            </el-form-item>
            <el-form-item v-if="healthyForm.is_back" label="是否经过中/高风险地区" size="small" label-width="196px">
                <el-switch
                    v-model="healthyForm.is_pass"
                    active-color="#ff4949"
                    inactive-color="#13ce66"
                    active-text="是"
                    inactive-text="否">
                </el-switch>
                <!-- <el-input v-model="healthyForm.VehicleRe" placeholder="请输入返厂交通详情"></el-input> -->
            </el-form-item>
            <el-form-item v-if="healthyForm.is_back" label="出发日期" label-width="110px">
                <el-date-picker
                    v-model="healthyForm.setout_date"
                    size="small"
                    align="center"
                    type="date"
                    style="width: 100%"
                    format="yyyy-MM-dd"
                    placeholder="请选择出发日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item v-if="healthyForm.is_back" label="到厂日期" label-width="110px">
                <el-date-picker
                    v-model="healthyForm.arrive_date"
                    size="small"
                    align="center"
                    type="date"
                    style="width: 100%"
                    format="yyyy-MM-dd"
                    placeholder="请选择到厂日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item v-if="!healthyForm.is_back" label="预计返厂日期" prop="planback_date" label-width="140px">
                <el-date-picker
                    v-model="healthyForm.planback_date"
                    size="small"
                    align="center"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择预计返厂日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="紧急联系人" prop="urgent_contacts" style="font-weight: bold" label-width="105px">
                <el-input v-model="healthyForm.urgent_contacts" placeholder="请输入紧急联系人"></el-input>
            </el-form-item>
            <el-form-item label="紧急联系电话" prop="urgent_phone"  style="font-weight: bold" label-width="120px">
                <el-input v-model="healthyForm.urgent_phone" placeholder="请输入紧急电话"></el-input>
            </el-form-item>
            <el-form-item label="是否核酸检测" style="font-weight: bold" size="small" label-width="120px">
                <el-switch
                    v-model="healthyForm.is_check"
                    active-color="#ff4949"
                    inactive-color="#13ce66"
                    active-text="是"
                    inactive-text="否">
                </el-switch>
            </el-form-item>
            <el-form-item v-if="healthyForm.is_check" label="核酸报告时间" style="font-weight: bold" label-width="120px">
                <el-date-picker
                    v-model="healthyForm.check_date"
                    align="center"
                    type="datetime"
                    style="width: 100%"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="请选择核酸报告日期">
                </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="备注">
                 <el-input
                    type="textarea"
                    :rows="2"
                    placeholder=""
                    v-model="healthyForm.hreamrk">
                </el-input>
            </el-form-item> -->
            <!-- <el-form-item label="上传凭证" style="font-weight: bold">
                <el-upload 
                    action='' 
                    ref="upload"
                    :on-change="getFile" 
                    :on-remove="handleRemove"
                    :limit="1" 
                    list-type="picture" 
                    :auto-upload="false" 
                    style="width: 100%;">
                    <el-button size="medium" type="info">健康码截图<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item> -->
            <el-row style="margin-top: 10px;margin-bottom: 20px">
                <el-col :span="3" style="padding: 20px 0">
                    <el-checkbox v-model="is_check"></el-checkbox>
                </el-col>
                <el-col :span="21" style="font-size: 10px;color: #F56C6C;text-indent: 2em;text-align: left;">
                    为防控疫情，本人郑重承诺健康打卡相关个人信息的填报真实、完整、有效。如因信息有误或缺失，本人自愿承担因此造成的全部法律责任。本人同意健康打卡信息提交公司用于管理及疫情监测目的使用，同意健康打卡信息提交至所在辖区政府疫情防控部门做疫情防控统筹管理目的使用。本人承诺遵守并配合公司及疫情防控部门开展各项疫情防控措施。
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" style="margin-top: 10px;margin-bottom: 10px">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="isLoading"
                        @click="submitForm('healthyForm')">提交打卡</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import moment from 'moment'
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
import wx from 'weixin-js-sdk'
export default {
    data(){
        return {
            healthyForm: {
                pnl: '',
                pname: '',
                // tp: 0,
                is_hot: false,
                habitation: '',
                isgoout: false,
                outAddress1: '',
                outTraffic1: '',
                // outAddress2: '',
                // outAddress3: '',
                // outAddress4: '',
                Vehicle: '',
                other_Vehicle: '',
                flight_no: '',
                // VehicleRe: '',
                // hreamrk: '',
                is_back: false,
                arrive_date: '',
                setout_date: '',
                planback_date: '2022-02-08',
                is_pass: false,
                // back_type: '', 
                urgent_contacts: '',
                urgent_phone: '',
                is_check: false,
                check_date: ''
            },
            other: false,
            // other2: false,
            hot_options: [{
                value: 'Y',
                label: '是 -（37.3°以上）/咳嗽等症状'
            },{
                value: 'N',
                label: '否'
            }],
            traffic_options: [{
                value: '1',
                label: '飞机'
            },{
                value: '2',
                label: '动车/高铁'
            },{
                value: '3',
                label: '火车'
            },{
                value: '4',
                label: '自驾/坐私家车'
            },{
                value: '5',
                label: '其他'
            }],
            is_check: false,
            // image1: null,
            // filename1: '',
            isLoading: false,
            // pname: '',
            // sec_no: '',
            sec_nm: '',
            hdate: moment().format('YYYY-MM-DD a'),
            rules: {
                is_hot: [
                    {required: true, message: '请选择是否发热', trigger: 'change'}
                ],
                habitation: [
                    {required: true, message: '请输入当前位置', trigger: 'blur'}
                ],
                isgoout: [
                    {required: true, message: '请选择是否外出', trigger: 'change'}
                ],
                is_back: [
                    {required: true, message: '请选择是否返厂', trigger: 'change'}
                ],
                // planback_date: [
                //     {required: true, message: '请选择预计返厂日期', trigger: 'change'}
                // ],
                urgent_contacts: [
                    {required: true, message: '请输入紧急联系人姓名', trigger: 'blur'}
                ],
                urgent_phone: [
                    {required: true, message: '请输入紧急联系电话', trigger: 'blur'}
                ],
                // image1: [
                //     {required: true, message: '请上传健康码截图', trigger: 'change'}
                // ]
            }
        }
    },
    methods: {
        backType(id) {
            this.other = id === '4' ||  id === '5' ? true : false
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CustomValidate()){
                    console.log(this.healthyForm)
                    this.SaveHealthy()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        CustomValidate() {
            // if(!this.image1) {
            //     this.$message({
            //         message: '请上传健康码截图',
            //         center: true,
            //         type: 'error'
            //     })
            //     return false
            // }
            if(!this.is_check) {
                this.$message({
                    message: '请勾选本人承诺！',
                    center: true,
                    type: 'error'
                })
                return false
            }
            return true
        },
        async SaveHealthy() {
            this.isLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlhealthys/SaveHealthy', qs.stringify({
                dto: this.healthyForm,
                // IMAGE1: encodeURIComponent(this.image1),
                // FILENAME1: this.filename1,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功') {
                this.$message({
                    message: '提交成功',
                    center: true,
                    type: 'success'
                })
                setTimeout(function(){
                    window.location.href = '/healthyList'
                },2000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                this.$message({
                    message: '提交失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async GetJSAPI() {
            this.GetLocation()
            // wx.openLocation({
            //     latitude: 0, // 纬度，浮点数，范围为90 ~ -90
            //     longitude: this.local.longitude, // 经度，浮点数，范围为180 ~ -180。
            //     name: '', // 位置名
            //     address: '', // 地址详情说明
            //     scale: 25, // 地图缩放级别,整型值,范围从1~28。默认为最大
            //     infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
            // })
        },
        async GetLocation() {
            wx.getLocation({
                type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: res => {
                    // var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                    // var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                    // var speed = res.speed; // 速度，以米/每秒计
                    // var accuracy = res.accuracy; // 位置精度
                    console.log(res)
                    this.GetBaiduLocation(res.latitude, res.longitude)
                }
            })
        },
        async GetBaiduLocation(lat, lng) {
            console.log(lat)
            console.log(lng)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/BaiduLocation/BaiduLocationName', qs.stringify({
                lat: lat, // dev
                lng: lng // dev
                // lat: 24.87924575805664, // dev
                // lng: 118.57785034179688 // dev
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功') {
                if(res.data.ResponseData.message) {
                    this.$message({
                        message: res.data.ResponseData.message,
                        center: true,
                        type: 'success'
                    })
                }
                if(res.data.ResponseData.status === 0) {
                    this.healthyForm.habitation = res.data.ResponseData.result.formatted_address
                }
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['openLocation', 'getLocation'] // 必填，需要使用的JS接口列表
                })
            }
        },
        // handleRemove() {
        //     this.image1 = null
        //     this.filename1 = ''
        // },
        // getFile(file, fileList) {
        //     if(!this.beforeAvatarUpload(file)) {
        //         this.$refs.upload.handleRemove(file);
        //         return
        //     }
        //     this.getBase64(file.raw).then(res => {
        //         console.log(res)
        //         this.image1 = res
        //     })
        //     this.filename1 = fileList[0].name
        // },
        // beforeAvatarUpload(file) {
        //     const isJPG = file.raw.type === 'image/jpeg'
        //     const isPNG = file.raw.type === 'image/png'
        //     const isLt2M = file.size / 1024 / 1024 < 3;
        //     if (!isJPG && !isPNG) {
        //         this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
        //     }
        //     if (!isLt2M) {
        //         this.$message.error('上传凭证图片大小不能超过 3MB!')
        //     }
        //     return isLt2M && (isJPG || isPNG)
        // },
        // getBase64(file) {
        //     return new Promise(function(resolve, reject) {
        //         let reader = new FileReader();
        //         let imgResult = "";
        //         reader.readAsDataURL(file);
        //         reader.onload = function() {
        //             imgResult = reader.result;
        //         }
        //         reader.onerror = function(error) {
        //             reject(error);
        //         }
        //         reader.onloadend = function() {
        //             resolve(imgResult);
        //         }
        //     })
        // },
        async GetListHealthyData() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlhealthys/GetLastHealthyList', qs.stringify({
                pnl: this.healthyForm.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    let healthyData = res.data.ResponseData[0]
                    this.healthyForm.is_hot = healthyData.is_hot
                    // this.healthyForm.habitation = healthyData.habitation
                    this.healthyForm.isgoout = healthyData.isgoout
                    this.healthyForm.outAddress1 = healthyData.outAddress1
                    this.healthyForm.outTraffic1 = healthyData.outtraffic1
                    // this.healthyForm.outAddress2 = healthyData.outAddress2
                    // this.healthyForm.outAddress3 = healthyData.outAddress3
                    // this.healthyForm.outAddress4 = healthyData.outAddress4
                    this.healthyForm.is_back = healthyData.is_back
                    this.healthyForm.setout_date = moment(healthyData.setout_date).format('YYYY-MM-DD'), 
                    this.healthyForm.arrive_date = moment(healthyData.arrive_date).format('YYYY-MM-DD'), 
                    this.healthyForm.planback_date = moment(healthyData.planback_date).format('YYYY-MM-DD'), 
                    this.healthyForm.Vehicle = healthyData.Vehicle
                    this.healthyForm.flight_no = healthyData.flight_no
                    this.healthyForm.other_Vehicle = healthyData.other_Vehicle
                    if(healthyData.other_Vehicle) {
                        this.other = true
                    }

                    this.healthyForm.is_pass = healthyData.is_pass
                    this.healthyForm.urgent_contacts = healthyData.urgent_contacts
                    this.healthyForm.urgent_phone =  healthyData.urgent_phone
                    this.healthyForm.is_check = healthyData.is_check
                    this.healthyForm.check_date = healthyData.check_date
                }
            }
        }
    },
    mounted() {
        this.GetSignature()
        // this.GetBaiduLocation(24.87924575805664, 118.57785034179688) // dev
        this.GetListHealthyData()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.healthyForm.pnl = pnl
            // this.healthyForm.pnl = '0165'  // dev
            // this.healthyForm.pnl = '56558'  // dev
            // this.healthyForm.pnl = '53134'  // dev
            // this.healthyForm.pnl = '53442'  // dev
            // this.healthyForm.pnl = '18139'  // dev
        }
        const pname = localStorage.getItem('PNAME')
        if(pname){
            this.healthyForm.pname = pname
        }
        const sec_nm = localStorage.getItem('SEC_NM')
        if(sec_nm){
            this.sec_nm = sec_nm
        }
    }
}
</script>