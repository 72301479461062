<template>
    <div>
        <el-image :src="require('@/assets/images/birthday.jpeg')" lazy></el-image>
        <!-- <wechat-share style="margin-top: 20px"></wechat-share> -->
    </div>
</template>

<script>
// import WechatShare from '../../../components/WechatShare.vue'
export default {
    // components: {
    //     WechatShare
    // }
}
</script>