<template>
    <div v-if="footer_menu.page3 && on_job">
        <el-card class="mylist-bar">
            <el-row>
                <el-col :span="18" style="text-align: left;">
                    <div @click="getDetailInfo" >
                        <span style="font-size: 18px;font-weight: bold;margin-left: 10px;">{{pname}} - {{ pnl }}</span> 
                        <span>
                            <el-image fit="contain" :src="require('@/assets/icon/iconPark20/vip_vip-one.svg')" alt=""></el-image>
                        </span>
                        <span style="font-size: 11px;color:#E6A23C;">{{work_age}}</span> 
                        <!-- <span style="font-size: 18px;font-weight: bold;margin-left: 20px;">13800000000</span>  -->
                        <!-- <span style="font-size: 11px;">_{{ pnl }}</span>  -->
                    </div>
                    <div style="padding-top: 4px;padding-left: 10px;">
                        <span style="font-size: 13px;">{{sec_nm}}</span> 
                    </div>
                    <div style="padding-top: 4px;padding-left: 10px;">
                        <span style="font-size: 13px;">{{gw_sn}}</span> 
                    </div>
                </el-col>
                <el-col :span="6" style="text-align: center;">
                    <div @click="getDetailInfo">
                        <el-avatar style="border-radius: 8px;" :size="60" :src="squareUrl"></el-avatar>
                        <!-- <el-image style="width: 56px;height: 70px;border-radius: 8px;" fit="cover" :src="squareUrl"></el-image> -->
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <div style="margin-top: 115px">
            <el-card v-for="item in mylist_menus" :key="item.id" style="margin: 15px 0px;padding: 4px 0px;border-radius: 8px">
                <router-link :to="item.link_url">
                <el-row>
                    <el-col :span="2" style="text-align: left;">
                        <el-image style="width: 20px;height: 20px;" fit="cover" :src="item.icon_url"></el-image>
                    </el-col>
                    <el-col :span="20" style="text-align: left;padding-left: 10px;font-size: 15px">
                        <div>{{ item.label }}</div>
                    </el-col>
                    <el-col :span="2" style="text-align: right;">
                        <i class="el-icon-arrow-right"></i>
                    </el-col>
                </el-row>
            </router-link>
            </el-card>
        </div>
        <el-alert type="info" :closable="false" center>
            <template slot='title'>
                <el-link @click="toICP" class="icp" :underline="false">
                    闽ICP备18021135号
                </el-link>
            </template>
        </el-alert>    
        <el-card class="footer">
            <el-row type="flex" class="row-bg" justify="space-between">
                <el-col :span="6">
                    <div style="margin-bottom: -3px;" @click="select_menu(1)">
                        <el-image fit="cover" :src="footer_menu.image_src1" alt=""></el-image>
                    </div>
                    <div :style="footer_menu.style1" @click="select_menu(1)">首页</div>
                </el-col>
                <el-col :span="6">
                    <div style="margin-bottom: -3px;" @click="select_menu(2)">
                        <el-image fit="cover" :src="footer_menu.image_src2" alt=""></el-image>
                    </div>
                    <div :style="footer_menu.style2" @click="select_menu(2)">应用</div>
                </el-col>
                <el-col :span="6">
                    <div style="margin-bottom: -3px;" @click="select_menu(3)">
                        <el-image fit="cover" :src="footer_menu.image_src3" alt=""></el-image>
                    </div>
                    <div :style="footer_menu.style3" @click="select_menu(3)">我的</div>
                </el-col>
            </el-row>
         
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            on_job: false,
            pnl: '',
            pname: '',
            squareUrl: '',
            // nickname: '',
            sec_nm: '',
            gw_sn: '',
            // zw_nm: '',
            work_age: 0,
            footer_menu: {
                page1: false,
                image_src1: require('@/assets/icon/iconPark24/606266/首页_home.svg'),
                style1: {
                    color: '#606266',
                },
                page2: false,
                image_src2: require('@/assets/icon/iconPark24/606266/全部_all-application.svg'),
                style2: {
                    color: '#606266',
                },
                page3: true,
                image_src3: require('@/assets/icon/iconPark24/用户_user.svg'),
                style3: {
                    color: '#409EFF',
                }
            },
            mylist_menus: [{
                link_url: '/attendance',
                icon_url: require('@/assets/icon/md/我的考勤.png'),
                label: '考勤',
            },{
                link_url: '/salary',
                icon_url: require('@/assets/icon/md/我的薪资.png'),
                label: '薪资',
            },{
                link_url: '/reward',
                icon_url: require('@/assets/icon/md/我的奖惩.png'),
                label: '奖惩',
            },{
                link_url: '/guarantee',
                icon_url: require('@/assets/icon/md/我的保障.png'),
                label: '保障',
            },{
                link_url: '/waterElectricity',
                icon_url: require('@/assets/icon/md/我的水电.png'),
                label: '水电',
            }]
        }
    },
    methods: {
        select_menu(id) {
            if(id === 1) {
                window.location.href = '/homeIndex'
            }else if(id === 2) {
                window.location.href = '/homeApps'
            }else if(id === 3) {
                window.location.href = '/homeInfo'
            }
        },
        getDetailInfo() {
            window.location.href = '/detailInfo'
        },
        toICP() {
            window.location.href = 'https://beian.miit.gov.cn/#/Integrated/index'
        },
    },
    mounted() {
        this.$route.meta.title = '个人中心'
        window.document.title = '个人中心'
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
        }
        const pname = localStorage.getItem('PNAME')
        if(pname) {
            this.pname = pname
        }
        const sec_nm = localStorage.getItem('SEC_NM')
        if(sec_nm) {
            this.sec_nm = sec_nm
        }
        const gw_sn = localStorage.getItem('GW_SN')
        if(gw_sn) {
            this.gw_sn = gw_sn
        }
        const work_age = localStorage.getItem('WORK_AGE')
        if(work_age) {
            this.work_age = work_age
        }
        const squareUrl = localStorage.getItem('headimgurl')
        if(squareUrl) {
            this.squareUrl = squareUrl
        }
        const on_job = localStorage.getItem('ON_JOB')
        if(on_job) {
            this.on_job = on_job === 'Y' ? true : false
        }


        // this.pnl = '66135' // dev
        // this.on_job = true  // dev
    }
}
</script>

<style scoped>
    a:link { 
        text-decoration: none;
        color: #606266;
    }
    a:visited {
        text-decoration: none;
        color: #606266;
    }
    a:active { 
        text-decoration: blink;
        color: #606266;
    }
    .app-icon {
        width: 40px;
        height: 40px;
        margin-bottom: 4px;
        margin-top: 20px;
    }
    .app-span {
        font-size: 12px; 
        /* font-weight: 550; */
    }
    .box-card {
        padding: 0px 0px 15px 0px;
        margin-bottom: 5px;
        border-radius: 8px;
        /* margin: 15px -10px 5px -10px; */
    }
    .box-card h4 {
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 0px 10px 20px;
        margin: -20px -20px 10px -20px;
        background-color: rgb(217, 236, 255);
    }
    .footer {
        position: fixed; /* 使用fixed定位 */
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding: 0px 0px 15px 0px;
        font-size: 11px;
    }
    /** mylist */
    .mylist-bar {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        /* background-color: #B3D8FF; */
        background-color: rgb(160, 207, 255);
        margin: 0;
        padding: 10px 0px 10px 10px;
    }
    .box-card2 {
        margin: 0px;
        /* padding: 5px 0px 5px 0px; */
        /* background-color: #C6E2FF; */
        background-color: white;
        border-radius: 8px;
    }
</style>