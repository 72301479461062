<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%" stretch>
            <el-tab-pane label="我的" name="first" lazy>
                <el-card v-for="item in my_list" :key="item.GID" shadow="always" class="box-card">
                    <el-row>
                        <el-col :span="17" style="text-align: left;font-weight: bold;font-size: 14px">【{{item.GTYPE}}】</el-col>
                        <el-col :span="7" style="text-align: right;">
                            <el-tag
                                v-if="item.ISCMT"
                                type="warning"
                                size="mini"
                                effect="dark">
                                常见
                            </el-tag>
                            <el-tag
                                v-if="item.ISEND === 1"
                                style="margin-left: 5px"
                                type="danger"
                                size="mini"
                                effect="dark">
                                完结
                            </el-tag>
                        </el-col>
                    </el-row>
                    <el-row class="card-row">
                        <el-col :span="24" style="text-align: left">{{item.GCONTENT}}</el-col>
                    </el-row>
                    <el-row class="card-row" v-if="item.HAVE_IMAGE !== 0">
                        <el-col :span="24" style="text-align: left">
                            <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.DB_ID)">
                                <i class="el-icon-picture-outline"></i> 点击查看
                            </el-link>
                        </el-col>
                    </el-row>
                    <el-row class="card-row" style="font-size: 12px;color: #909399;margin-top: 32px">
                        <el-col :span="4" style="text-align: center">
                            <div class="block">
                                <el-avatar :size="30" :src="item.HEADIMGURL"></el-avatar>
                            </div>
                        </el-col>
                        <el-col :span="9" style="text-align: left;margin-left: 10px">
                            <div>{{item.PNAME}}【{{ item.PNL }}】</div>
                            <div>{{item.SEC_NM}}</div>
                        </el-col>
                        <el-col :span="9" style="text-align: right">
                            <div>{{item.GDATE}}</div>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 32px">
                        <el-col :span="7" style="text-align: left;margin-right: 20px;">
                            <div>
                                <el-badge :value="item.GZNUM" class="item">
                                    <el-button size="mini" disabled type="success" icon="el-icon-document-add" @click="AddFollow(item.GID)">关注</el-button>
                                </el-badge>
                            </div>
                        </el-col>
                        <el-col :span="7" style="text-align: left">
                            <div>
                                <el-badge :value="item.HDNUM" class="item">
                                    <el-button size="mini" type="primary" icon="el-icon-chat-dot-round" @click="ToAnswer(item.GID)">回答</el-button>
                                </el-badge>
                            </div>
                        </el-col>
                        <!-- <el-col :span="7" style="text-align: right">
                            <div>
                                <el-badge :value="item.HDNUM" class="item">
                                    <el-button size="mini" type="warning" icon="el-icon-right" @click="ToAnswer(item.GID)">催答</el-button>
                                </el-badge>
                            </div>
                        </el-col> -->
                    </el-row>
                </el-card>
                <el-empty v-if="my_list.length === 0" description="没有数据"></el-empty>
            </el-tab-pane>
            <el-tab-pane label="最新" name="second" lazy>
                <el-card :id="item.GID" v-for="item in last_list" :key="item.GID" shadow="always" class="box-card">
                    <el-row>
                        <el-col :span="18" style="text-align: left;font-weight: bold;font-size: 14px">【{{item.GTYPE}}】</el-col>
                        <el-col :span="6" style="text-align: right;">
                            <el-tag
                                v-if="item.ISCMT"
                                type="warning"
                                size="mini"
                                effect="dark">
                                常见
                            </el-tag>
                            <el-tag
                                v-if="item.ISEND === 1"
                                type="danger"
                                size="mini"
                                effect="dark">
                                完结
                            </el-tag>
                            <el-tag
                                v-if="item.ISSHOW === 'N' && item.PNL !== pnl"
                                type="success"
                                size="mini"
                                effect="dark">
                                仅可见
                            </el-tag>
                        </el-col>
                    </el-row>
                    <el-row class="card-row">
                        <el-col :span="24" style="text-align: left;font-size: 13px">{{item.GCONTENT}}</el-col>
                    </el-row>
                    <el-row class="card-row" v-if="item.HAVE_IMAGE !== 0">
                        <el-col :span="24" style="text-align: left">
                            <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.DB_ID)">
                                <i class="el-icon-picture-outline"></i> 点击查看
                            </el-link>
                        </el-col>
                    </el-row>
                    <el-row class="card-row" style="font-size: 12px;color: #909399;margin-top: 32px">
                        <el-col :span="4" style="text-align: center">
                            <div class="block">
                                <el-avatar :size="30" :src="item.HEADIMGURL"></el-avatar>
                            </div>
                        </el-col>
                        <!-- <el-col :span="9" style="text-align: left;margin-left: 10px">
                            <div style="margin-bottom: 4px">{{item.PNL}} {{item.PNAME}}</div>
                            <div>{{item.TEL}}</div>
                        </el-col>
                        <el-col :span="9" style="text-align: right">
                            <div style="margin-bottom: 4px">{{item.GDATE}}</div>
                        </el-col> -->
                        <el-col :span="9" style="text-align: left;margin-left: 10px">
                            <div>{{item.PNAME}}【{{ item.PNL }}】</div>
                            <div>{{item.SEC_NM}}</div>
                        </el-col>
                        <el-col :span="9" style="text-align: right">
                            <div>{{item.GDATE}}</div>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 32px">
                        <el-col :span="8" style="text-align: left;">
                            <div>
                                <el-badge :value="item.GZNUM" class="item">
                                    <el-button size="mini" type="success" icon="el-icon-document-add" @click="AddFollow(item.GID)">关注</el-button>
                                </el-badge>
                            </div>
                        </el-col>
                        <el-col :span="8" style="text-align: left;">
                            <div>
                                <el-badge :value="item.HDNUM" class="item">
                                    <el-button size="mini" type="primary" icon="el-icon-chat-dot-round" @click="ToAnswer(item.GID)">回答</el-button>
                                </el-badge>
                            </div>
                        </el-col>
                        <el-col :span="8" style="text-align: left" v-if="pnl === '0165' || pnl === '0563' || pnl === '65392'">
                            <div>
                               <el-button size="mini" type="warning" icon="el-icon-edit-outline" @click="ToForward(item)">转办</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
                <el-empty v-if="last_list.length === 0" description="没有数据"></el-empty>
            </el-tab-pane>
            <el-tab-pane label="常见" name="third" lazy>
                <el-card v-for="item in com_list" :key="item.GID" shadow="always" class="box-card">
                    <el-row>
                        <el-col :span="24" style="text-align: left;font-weight: bold;font-size: 14px">【{{item.GTYPE}}】</el-col>
                    </el-row>
                    <el-row class="card-row">
                        <el-col :span="24" style="text-align: left">{{item.GCONTENT}}</el-col>
                    </el-row>
                    <el-row class="card-row" v-if="item.HAVE_IMAGE !== 0">
                        <el-col :span="24" style="text-align: left">
                            <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.DB_ID)">
                                <i class="el-icon-picture-outline"></i> 点击查看
                            </el-link>
                        </el-col>
                    </el-row>
                    <el-row class="card-row" style="font-size: 12px;color: #909399;margin-top: 32px">
                        <el-col :span="4" style="text-align: center">
                            <div class="block">
                                <el-avatar :size="30" :src="item.HEADIMGURL"></el-avatar>
                            </div>
                        </el-col>
                        <el-col :span="9" style="text-align: left;margin-left: 10px">
                            <div>{{item.PNAME}}【{{ item.PNL }}】</div>
                            <div>{{item.SEC_NM}}</div>
                        </el-col>
                        <el-col :span="9" style="text-align: right">
                            <div>{{item.GDATE}}</div>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 32px">
                        <el-col :span="7" style="text-align: left;margin-right: 20px;">
                            <div>
                                <el-badge :value="item.GZNUM" class="item">
                                    <el-button size="mini" type="success" icon="el-icon-document-add" @click="AddFollow(item.GID)">关注</el-button>
                                </el-badge>
                            </div>
                        </el-col>
                        <el-col :span="7" style="text-align: left">
                            <div>
                                <el-badge :value="item.HDNUM" class="item">
                                    <el-button size="mini" type="primary" icon="el-icon-chat-dot-round" @click="ToAnswer(item.GID)">回答</el-button>
                                </el-badge>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
                <el-empty v-if="com_list.length === 0" description="没有数据"></el-empty>
            </el-tab-pane>
            <el-tab-pane label="关注" name="fourth" lazy>
                <el-card v-for="item in follow_list" :key="item.GID" shadow="always" class="box-card">
                    <el-row>
                        <el-col :span="24" style="text-align: left;font-weight: bold;font-size: 14px">【{{item.GTYPE}}】</el-col>
                    </el-row>
                    <el-row class="card-row">
                        <el-col :span="24" style="text-align: left">{{item.GCONTENT}}</el-col>
                    </el-row>
                    <el-row class="card-row" v-if="item.HAVE_IMAGE !== 0">
                        <el-col :span="24" style="text-align: left">
                            <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.DB_ID)">
                                <i class="el-icon-picture-outline"></i> 点击查看
                            </el-link>
                        </el-col>
                    </el-row>
                    <el-row class="card-row" style="font-size: 12px;color: #909399;margin-top: 32px">
                        <el-col :span="4" style="text-align: center">
                            <div class="block">
                                <el-avatar :size="30" :src="item.HEADIMGURL"></el-avatar>
                            </div>
                        </el-col>
                        <el-col :span="9" style="text-align: left;margin-left: 10px">
                            <div>{{item.PNAME}}【{{ item.PNL }}】</div>
                            <div>{{item.SEC_NM}}</div>
                        </el-col>
                        <el-col :span="9" style="text-align: right">
                            <div>{{item.GDATE}}</div>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 32px">
                        <el-col :span="7" style="text-align: left;margin-right: 20px;">
                            <div>
                                <el-badge :value="item.GZNUM" class="item">
                                    <el-button size="mini" type="success" icon="el-icon-document-add" @click="AddFollow(item.GID)">关注</el-button>
                                </el-badge>
                            </div>
                        </el-col>
                        <el-col :span="7" style="text-align: left">
                            <div>
                                <el-badge :value="item.HDNUM" class="item">
                                    <el-button size="mini" type="primary" icon="el-icon-chat-dot-round" @click="ToAnswer(item.GID)">回答</el-button>
                                </el-badge>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
                <el-empty v-if="follow_list.length === 0" description="没有数据"></el-empty>
            </el-tab-pane>
        </el-tabs>
        <el-row style="margin-top: 20px;margin-bottom: 20px">
            <el-col :span="24">
                <router-link to="/question">
                    <el-button style="width: 100%" type="primary">我要提问</el-button>
                </router-link>
            </el-col>
        </el-row>
        <!-- <el-dialog :visible.sync="imageDialogTableVisible" size="mini" width="90%">
            <span class="dialog-footer">
                <el-image
                    :key="item.DB_ID"
                    v-for="item in imageList"
                    lazy
                    style="width: 100%;"
                    :src="item.path"
                    fit="fill">
                    <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                    </div>
                    <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="mini" @click="imageDialogTableVisible = false">关闭</el-button>
            </span>
        </el-dialog> -->
        <el-dialog title="提问转发" :visible.sync="forwardDialogFormVisible" width="90%">
            <el-form :model="forwardForm" size="small" label-width="70px">
                <el-form-item label="问题类别" prop="gType" style="text-align: left;font-weight: bold">
                    <el-select v-model="forwardForm.gType" placeholder="请选择问题类别" 
                        size="small" style="width: 100%">
                        <el-option v-for="item in forwardForm.type_list" :key="item.typeName" :value="item.typeName"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="forwardDialogFormVisible = false">取消</el-button>
                <el-button size="mini" type="success" @click="forward">提交</el-button>
            </div>
        </el-dialog>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import wx from 'weixin-js-sdk'
import moment from 'moment'
export default {
    data() {
        return {
            pnl: '',
            my_list: [],
            last_list: [],
            com_list: [],
            follow_list: [],
            tab: null,
            activeName: 'first',
            // imageDialogTableVisible: false,
            forwardDialogFormVisible: false,
            imageList: [],
            forwardForm: {
                gType: '',
                type_list: [],
            },
            current_item: []
        }
    },
    methods: {
        ShowImages(id) {
            // console.log(id)
            // this.imageDialogTableVisible = true
            this.GetImagesByID(id)
        },
        async GetImagesByID(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        // this.imageList.push({
                        //     id: item.DB_ID,
                        //     path: conf.image_domain + item.FImgPath
                        // })
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                           path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
                console.log('this.imageList', this.imageList)
            }
        },
        async AddFollow(id) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlAskgzss/AddFollow', qs.stringify({
                gid: id,
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$notify({
                    title: '',
                    message: '关注成功！',
                    duration: 2000,
                    type: 'success',
                    position: 'bottom-right' 
                })
                this.handleClick(this.tab)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$notify({
                    title: '',
                    message: '您已关注过此问题！',
                    duration: 2000,
                    type: 'info',
                    position: 'bottom-right' 
                })
            }
        },
        ToAnswer(id) {
            window.location.href = '/answer/' + id
        },
        ToForward(item) {
            console.log('item', item)
            this.getQuestionTpye()
            this.current_item = item
            this.forwardDialogFormVisible = true
        },
        async forward() {
            this.current_item.GTYPE = this.forwardForm.gType
            console.log('this.current_item', this.current_item)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlAsks/Forward', qs.stringify({
                dto: this.current_item
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$message({
                    message: '提交成功！',
                    center: true,
                    type: 'success'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.forwardDialogFormVisible = false
        },
        async getQuestionTpye() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlAsks/QuestionType')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.forwardForm.type_list = res.data.ResponseData
            }
        },
        handleClick(tab) {
            this.tab = tab
            if(tab.index === '0' || !tab.index) {
                this.GetMyAskList()
            }else if(tab.index === '1'){
                this.GetLastAskList()
            }else if(tab.index === '2'){
                this.GetCommonAskList()
            }else if(tab.index === '3'){
                this.GetFollowAskList()
            }
        },
        async GetMyAskList() {
            this.my_list = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlAsks/MyAskList', qs.stringify({
                PNL: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                // this.my_list = res.data.ResponseData
                res.data.ResponseData.forEach(item => {
                    item.GDATE = moment(item.GDATE).format('MM-DD HH:mm')
                    this.my_list.push(item)
                })
            }
        },
        async GetLastAskList() {
            this.last_list = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlAsks/LastAskList')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                // this.last_list = res.data.ResponseData
                res.data.ResponseData.forEach(item => {
                    item.GDATE = moment(item.GDATE).format('MM-DD HH:mm')
                    if(item.ISSHOW === 'N' && item.PNL !== this.pnl && this.pnl !== '65392' && this.pnl !== '0165' && this.pnl !== '0563') {
                        console.log(item)
                    }else {
                        this.last_list.push(item)
                    }
                })
            }
        },
        async GetCommonAskList() {
            this.com_list = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlAsks/CommonAskList')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                // this.com_list = res.data.ResponseData
                res.data.ResponseData.forEach(item => {
                    item.GDATE = moment(item.GDATE).format('MM-DD HH:mm')
                    // this.com_list.push(item)
                    if(item.ISSHOW === 'N' && item.PNL !== this.pnl) {
                        console.log(item)
                    }else {
                        this.com_list.push(item)
                    }
                })
            }
        },
        async GetFollowAskList() {
            this.follow_list = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlAsks/FollowAskList', qs.stringify({
                PNL: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                // this.follow_list = res.data.ResponseData
                res.data.ResponseData.forEach(item => {
                    item.GDATE = moment(item.GDATE).format('MM-DD HH:mm')
                    // this.follow_list.push(item)
                    if(item.ISSHOW === 'N' && item.PNL !== this.pnl) {
                        console.log(item)
                    }else {
                        this.follow_list.push(item)
                    }
                })
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
    }, 
    mounted() {
        this.GetMyAskList()
        this.GetSignature()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            // this.pnl = '64013' // dev
            // this.pnl = '65392' // dev
            // this.pnl = '55129' // dev
            // this.pnl = '57867' // dev
            // this.pnl = '56953' // dev
            // this.pnl = '59391' // dev
            // this.pnl = '0563' // dev
        }
    }
}
</script>

<style scoped>
    /* .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both
    } */
    .box-card{
        margin-top: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 13px;
        /* border-radius: 10px;  */
    } 
    .card-row {
        margin-top: 26px;
    }
</style>