<template>
    <div>
        <el-table :data="tableData" stripe size="small" :show-header="false">
            <el-table-column prop="date" width="63">
                <template slot-scope="scope">
                    <div style="font-weight: bolder; font-size: 14px">{{scope.row.day}}</div>
                    <div>{{scope.row.week}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="txt" align="right">
                <template slot-scope="scope">
                    <div>{{scope.row.time}}</div>
                    <div>{{scope.row.txt}}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import moment from 'moment'
moment.locale('zh-cn')
export default {
    data() {
        return {
            pnl: '',
            start: '',
            tableData: []
        }
    },
    methods: {
        async getStageAttendanceDetail() {
            const res  = await axios.post(conf.api_url + '/gateway/weixinapi/Attendance/AttendanceDetail', qs.stringify({
                // start: this.start,
                // end: moment(this.start).endOf('month').format("YYYY-MM-DD"),
                yymm: moment(this.start).format('YYYY-MM'),
                pnl: this.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                res.data.ResponseData.forEach(item => {
                    let times = ''
                    if(item.itime1) {
                        times += item.itime1 + ','
                    }
                    if(item.itime2) {
                        times += item.itime2 + ','
                    }
                    if(item.itime3) {
                        times += item.itime3 + ','
                    }
                    if(item.otime1) {
                        times += item.otime1 + ','
                    }
                    if(item.otime2) {
                        times += item.otime2 + ','
                    }
                    if(item.otime3) {
                        times += item.otime3 + ','
                    }
                    times = times.substring(0, times.length - 1)
                    let txts = ''
                    if(item.w_day) {
                        txts += '【工作】 ' + item.w_day + '天'
                    }
                    // let j_time = 0
                    // if(item.j_time1) {
                    //     j_time += item.j_time1
                    // }
                    // if(item.j_time2) {
                    //     j_time += item.j_time2
                    // }
                    // if(item.j_time3) {
                    //     j_time += item.j_time3
                    // }
                    // if(item.j_time4) {
                    //     j_time += item.j_time4
                    // }
                    if(item.j_time > 0) {
                        txts += ' 【加班】 ' + item.j_time + '分钟'
                    }
                    if(item.x_day) {
                        txts += ' 【公休】 ' + item.x_day + '天'
                    }
                    if(item.k_day) {
                        txts += ' 【旷工】 ' + item.k_day + '天'
                    }
                    if(item.h_day) {
                        txts += ' 【请假】 ' + item.h_day + '天'
                    }
                    if(item.h_time) {
                        txts += ' 【请假】 ' + item.h_time + '分钟'
                    }
                    this.tableData.push({
                        txt: txts,
                        day: moment(item.cdate).format('MM/DD'),
                        week: moment(item.cdate).format('dddd'),
                        time: times
                    })
                })
            }
        },
        
    },
    mounted() {
        this.getStageAttendanceDetail()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
            // this.pnl = 11206 // dev
            // this.pnl = '19301' // dev
            // this.pnl = '18139' // dev
            // this.pnl = '60104'
        }
        const start = this.$route.params.start
        if(start) {
            this.start = start
        }
    }
}
</script>