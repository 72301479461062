<template>
    <div>
        <el-form :model="workForm" :rules="rules" ref="workForm" label-width="90px" size="medium">
            <el-form-item label="员工工号" prop="pnl" style="font-weight: bold">
                <el-input v-model="workForm.pnl" placeholder="请输入员工工号" size="medium" @blur="getPersonByPnl"></el-input>
            </el-form-item>
            <el-form-item label="员工姓名" prop="nm" style="font-weight: bold">
                <el-input v-model="workForm.nm" size="medium" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="所在部门" prop="sec_nm" style="font-weight: bold">
                <el-input v-model="workForm.sec_nm" size="medium" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone" style="font-weight: bold">
                <el-input v-model="workForm.phone" placeholder="请输入手机号码" size="medium"></el-input>
            </el-form-item>
            <el-form-item label="维修类别" prop="type" style="font-weight: bold">
                <el-radio v-model="workForm.type" label="Dorm"  @change="typeChange">宿舍</el-radio>
                <el-radio v-model="workForm.type" label="WorkShop" @change="typeChange">车间</el-radio>
            </el-form-item>
            <el-form-item prop="typeItem" style="font-weight: bold">
                <!-- <el-radio-group v-model="workForm.typeItem">
                    <el-radio-button label="1">门窗</el-radio-button>
                    <el-radio-button label="2">水电</el-radio-button>
                    <el-radio-button label="3">其他</el-radio-button>
                </el-radio-group> -->
                <el-select 
                    v-model="workForm.typeItem" 
                    placeholder="请选择维修类别" 
                    size="medium" 
                    @change="typeChange" 
                    style="width: 100%">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="宿舍信息" prop="dorm_no" v-if="this.workForm.isDorm" style="font-weight: bold">
                <el-row :gutter="10">
                    <el-col :span="10">
                        <el-select v-model="workForm.building_no" filterable placeholder="请选择" clearable style="width: 100%">
                    <el-option
                        v-for="item in building_nos"
                        :key="item.building_no"
                        :label="item.building_no"
                        :value="item.building_no">
                    </el-option>
                </el-select>
                    </el-col>
                    <el-col :span="12">
                        <el-input v-model="workForm.dorm_no" placeholder="请输入房间号" size="medium"></el-input>
                    </el-col>
                </el-row>
               
                
            </el-form-item>
            <el-form-item label="问题描述" prop="desc" style="font-weight: bold">
                <el-autocomplete
                    style="width: 100%;"
                    type="textarea"
                    :rows="2"
                    popper-class="my-autocomplete"
                    v-model="workForm.desc"
                    :fetch-suggestions="querySearch"
                    placeholder="请详细描述问题"
                    @select="handleSelect">
                    <!-- <i
                        class="el-icon-edit el-input__icon"
                        slot="suffix"
                        @click="handleIconClick">
                    </i> -->
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.value }}</div>
                        <!-- <span class="addr">{{ item.address }}</span> -->
                    </template>
                </el-autocomplete>
            </el-form-item>
            <el-form-item label="维修区域" prop="addr" style="font-weight: bold">
                <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入维修区域（如：卫生间,床位,洗衣池等）"
                    v-model="workForm.addr">
                </el-input>
            </el-form-item>
            <el-form-item label="问题图片" prop="image1" style="font-weight: bold">
                <el-upload 
                    action='' 
                    ref="upload"
                    :on-change="getFile" 
                    :limit="3" 
                    list-type="picture"
                    :auto-upload="false" 
                    :on-exceed="handleExceed"
                    style="width: 100%;" >
                    <el-button size="medium" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item>
            <el-row style="margin-top: 10px;margin-bottom: 20px">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="workForm.isLoading"
                        @click="submitForm('workForm')">提交工务维修单</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf.js'
import { compressImage } from '../../plugin/compress.js'
export default {
    data() {
        return {
            restaurants: [],
            state: '',
            building_nos: [],
            workForm: {
                pnl: '',
                nm: '',
                sec_no: '',
                sec_nm: '',
                phone: '',
                type: '',
                typeItem: '',
                isDorm: false,
                dorm_no: '',
                desc: '',
                addr: '',
                // image1: null,
                // imageList: [],
                isLoading: false,
                fileList: [],
                imageList: [],
                building_no: ''
            },
            options: [{
                    value: '1',
                    label: '水'
                }, {
                    value: '2',
                    label: '电'
                }, {
                    value: '3',
                    label: '门窗'
                }, {
                    value: '4',
                    label: '泥水粉刷'
                },  {
                    value: '6',
                    label: '金属'
                }, {
                    value: '8',
                    label: '空调'
                }, {
                    value: '9',
                    label: '消防类'
                }, {
                    value: '10',
                    label: '信息网络技术'
                }],
            rules: {
                pnl: [
                    {required: true, message: '请输入员工工号', trigger: 'blur'}
                ],
                nm: [
                    {required: true, message: '请输入员工姓名', trigger: 'change'}
                ],
                sec_nm: [
                    {required: true, message: '请输入所在部门', trigger: 'change'}
                ],
                phone: [
                    {required: true, message: '请输入联系电话', trigger: 'change'}
                ],
                type: [
                    {required: true, message: '请选择维修类型', trigger: 'change'}
                ],
                typeItem: [
                    {required: true, message: '请选择维修类型', trigger: 'change'}
                ],
                dorm_no: [
                    {required: true, message: '请输入宿舍信息', trigger: 'blur'}
                ],
                desc: [
                    {required: true, message: '请输入问题描述', trigger: 'change'}
                ],
                addr: [
                    {required: true, message: '请输入维修区域', trigger: 'change'}
                ],
                // image1: [
                //     {required: true, message: '请选择一张图片', trigger: 'change'}
                // ]
            },
        }
    },
    methods: {
        async GetBuildingNo() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/GetBuildingNo')
            console.log(res)
            this.building_nos = []
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.building_nos = res.data.ResponseData
            }
        },
        getTypeName(typeItem) {
            let name = ''
            this.options.map(item => {
                if(item.value === typeItem) {
                    name = item.label
                }
            })
            return name
        },
        typeChange() {
            // console.log(this.workForm.type)
            if(this.workForm.type == 'Dorm') {
                this.workForm.isDorm = true
            }else{
                this.workForm.isDorm = false
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.workForm)
                    this.SaveWorkOrder()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveWorkOrder() {
            this.workForm.isLoading = true
            const dto = {
                FQUEDESCRIPTION: this.workForm.desc,
                FSUBMITUCODE: this.workForm.pnl,
                FSUBMITUDEPT: this.workForm.sec_no,
                FSUBMITUDEPTNAME: this.workForm.sec_nm,
                FQUEADDRESS: this.workForm.addr,
                FQUEMOBILE: this.workForm.phone,
                BILL_TYPE: this.workForm.type,
                FSUBMITNAME: this.workForm.nm,
                WORKTYPE: this.getTypeName(this.workForm.typeItem),
                FQUETYPE: this.workForm.typeItem,
                DORMCODE: this.workForm.dorm_no,
                BUILDING_NO: this.workForm.building_no
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CSWorkorders/SaveWorkOrder', qs.stringify({
                dto,
                IMAGE1: encodeURIComponent(this.workForm.imageList[0]),
                IMAGE2: encodeURIComponent(this.workForm.imageList[1]),
                IMAGE3: encodeURIComponent(this.workForm.imageList[2]),
                FILENAME1: this.workForm.fileList[0],
                FILENAME2: this.workForm.fileList[1],
                FILENAME3: this.workForm.fileList[2]
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                console.log('工务维修单提交成功:', res.data.ResponseData)
                this.$message({
                    message: '维修单提交成功',
                    center: true,
                    type: 'success'
                })

                localStorage.setItem('PNL', res.data.ResponseData.FSUBMITUCODE)
                localStorage.setItem('PNAME', res.data.ResponseData.FSUBMITNAME)
                localStorage.setItem('SEC_NO', res.data.ResponseData.FSUBMITUDEPT)
                localStorage.setItem('SEC_NM', res.data.ResponseData.FSUBMITUDEPTNAME)
                localStorage.setItem('PHONE', res.data.ResponseData.FQUEMOBILE)

                setTimeout(function(){
                    window.location.href = '/workOrderList'
                },2000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '维修单提交失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '维修单提交失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.workForm.isLoading = false
        },
        async getPersonByPnl() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SportsClock/PersonByPnl', qs.stringify({
                pnl: this.workForm.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.workForm.nm = res.data.ResponseData[0].pname
                this.workForm.sec_no = res.data.ResponseData[0].sec_no
                this.workForm.sec_nm = res.data.ResponseData[0].sec_nm
                this.workForm.phone = res.data.ResponseData[0].tel
            }
        },
        getFile(file, fileList) {
            // if(!this.beforeAvatarUpload(file)) {
            //     this.$refs.upload.handleRemove(file)
            //     return
            // }

            // // this.getBase64(file.raw).then(res => {
            // //     console.log(res)
            //     // this.workForm.image1 = res
            // // })

            // this.workForm.imageList = []
            // fileList.forEach(item => {
            //     this.getBase64(item.raw).then(res => {
            //         // console.log(res)
            //         this.workForm.imageList.push(res)
            //     })
            // })

            console.log('file', file)
            console.log('fileList', fileList)
            this.workForm.fileList = []
            this.workForm.imageList = []
            fileList.forEach(item => {
                this.workForm.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.workForm.imageList.push(result)
                })
                console.log('this.workForm.imageList', this.workForm.imageList)
            })
        },
        handleRemove(file, fileList) {
            console.log('file', file)
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.workForm.fileList = []
            this.workForm.imageList = []
            fileList.forEach(item => {
                this.workForm.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.workForm.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        },
        loadAll() {
            return [
                { "value": "卫生间水龙头漏水"},
                { "value": "卫生间灯坏了"},
                { "value": "宿舍灯坏了"},
                { "value": "宿舍空调不制冷"}
            ]
        },
        handleSelect(item) {
            console.log(item)
        },
        handleIconClick(ev) {
            console.log(ev)
        },
        querySearch(queryString, cb) {
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
            // 调用 callback 返回建议列表的数据
            cb(results)
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
            }
        },
    },
    mounted() {
        this.GetBuildingNo()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.workForm.pnl = pnl
            // this.workForm.pnl = '38474' // dev
            // this.workForm.pnl = '55486' // dev
            // this.workForm.pnl = '0332' // dev
        }
        const pname = localStorage.getItem('PNAME')
        if(pname){
            this.workForm.nm = pname
            // this.workForm.nm = '李莹' // dev
        }
        const sec_no = localStorage.getItem('SEC_NO')
        if(sec_no){
            this.workForm.sec_no = sec_no
        }
        const sec_nm = localStorage.getItem('SEC_NM')
        if(sec_nm){
            this.workForm.sec_nm = sec_nm
            // this.workForm.sec_nm = '硬件组' // dev
        }
        const phone = localStorage.getItem('PHONE')
        if(phone){
            this.workForm.phone = phone
        }

        this.restaurants = this.loadAll()
    }
}
</script>

<style lang="less" scoped>
    .my-autocomplete {
        li {
            line-height: normal;
            padding: 7px;
            .name {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
</style>