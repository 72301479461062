<template>
    <div>
        <el-card :id="item.DB_ID" shadow="always" v-for="(item, index) in dinnerOrderList" :key="index" class="box-card" v-loading="data_loading">
            <el-row>
                <el-col :span="6" style="text-align: left">用餐时间</el-col>
                <el-col :span="18" style="text-align: right">{{item.DINNER_DATE}} {{item.DINNER_TIME}}</el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="6" style="text-align: left">菜品信息</el-col>
                <el-col :span="18" style="text-align: right">
                    
                </el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="6" style="text-align: left">菜品信息</el-col>
                <el-col :span="18" style="text-align: right">
                    共 {{ item.DINNER_NUM }} 件，￥{{ item.DINNER_MONEY }}
                </el-col>
            </el-row>
        </el-card>
        <el-row style="margin-top: 20px;margin-bottom: 20px;">
            <el-col :span="24">
                <router-link to="/dinnerOrder">
                    <el-button style="width: 100%" type="primary">我要订餐</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import conf from '../../../conf'
// import wx from 'weixin-js-sdk'
// import { compressImage } from '../../../plugin/compress.js'
export default {
    data() {
        return {
            dinnerOrderList: [],
            data_loading: false
        }
    },
    methods: {
        async GetDinnerOrder() {
            this.data_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrders/GetDinnerOrder', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                res.data.ResponseData.forEach(item => {
                    item.DINNER_DATE = moment(item.DINNER_DATEA).format('YYYY-MM-DD')
                    this.dinnerOrderList.push(item)
                });
                this.dinnerOrderList = res.data.ResponseData
            }else {
                this.$message({
                    message: '获取数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.data_loading = false
        },
    },
    mounted() {
        this.GetDinnerOrder()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>