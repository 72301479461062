<template>
    <div>
        <el-table :data="barCodeData" size="mini" :show-header="false">
            <el-table-column prop="label" label="样品单号" width="90px">
            </el-table-column>
            <el-table-column prop="value">
            </el-table-column>
            <el-table-column align="right" width="90px">
                <el-button 
                    size="mini" 
                    type="primary" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI">扫码</el-button>
            </el-table-column>
        </el-table>
        <el-table 
            max-height="580"
            :data="sampleData" 
            size="mini"
            border 
            :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, margin: 0, height: '32px'}" 
            :cell-style="() => {return 'padding: 0px;margin: 0px;height: 30px;fontSize: 11px'}"
            v-loading="table_loading" 
            style="margin-top: 5px">
            <el-table-column prop="yvou_no" label="单号" min-width="48px" align="center">
            </el-table-column>
            <el-table-column prop="scus_nm" label="品牌" min-width="46px" align="center">
            </el-table-column>
            <el-table-column prop="stype" label="类型" min-width="46px" align="center">
            </el-table-column>
            <el-table-column prop="sstyle" label="货号" min-width="56px" align="center">    
            </el-table-column>
            <el-table-column prop="sqty" label="完工数" min-width="46px" align="center">
            </el-table-column>
            <el-table-column prop="sqtyl" label="完工左" min-width="46px" align="center">
            </el-table-column>
            <el-table-column prop="sqtyr" label="完工右" min-width="46px" align="center">
            </el-table-column>
        </el-table>
        <el-row style="margin-top: 10px;">
            <el-col :span="24">
                <el-button 
                    size="small"
                    style="width: 100%;"
                    type="primary" 
                    :loading="isLoading"
                    @click="SetSampleIO()">样品完工</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            barCodeData: [{
                label: '样品单号',
                value: ''
            }],
            sampleData: [],
            table_loading: false,
            isLoading: false
        }
    },
    methods: {
        GetJSAPI() {
            // 测试
            // this.barCodeData[0].value = 'YQ2406000091' // dev
            // this.barCodeData[0].value = 'YQ2403000009' // dev
            // this.barCodeData[0].value = 'YQ2409000069' // dev
            // this.GetSampleIOInfo() // dev
            
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    this.barCodeData[0].value = result
                    this.GetSampleIOInfo(this.barCodeData[0].value) 
                }
            })
        },
        async GetSampleIOInfo() {
            this.sampleData = []
            this.table_loading = true 
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AleKfSampleio/GetSampleIOInfo', qs.stringify({
                bar_code: this.barCodeData[0].value
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.forEach(item => {
                    item.yvou_no = item.yvou_no.substring(12, 15)
                    this.sampleData.push(item)
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.table_loading = false 
        },
        async SetSampleIO() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AleKfSampleio/SetSampleIO', qs.stringify({
                pnl: this.pnl,
                bar_code: this.barCodeData[0].value,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$message({
                    message: '样品完工成功！',
                    center: true,
                    type: 'success'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        this.GetSignature()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            // this.pnl = '64145'   // dev
        }
    }
 }
</script>