<template>
    <div>
        <el-form :model="weekMenuForm" ref="weekMenuForm" :rules="rules" label-width="80px" size="medium">
            <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;margin-top: -20px;" stretch>
            <el-tab-pane label="日菜谱" name="first" lazy>
                <!-- <el-form :model="pieceForm" :rules="rules" ref="pieceForm" label-width="80px" size="small"> -->
                    <el-form-item label="菜谱日期" prop="fdate">
                            <el-date-picker
                                v-model="weekMenuForm.fdate"
                                align="center"
                                placeholder="请选择菜谱日期"
                                size="small"
                                style="width: 100%;"
                                >
                            </el-date-picker>
                        <!-- <el-col :span="8">
                            <el-button type="primary" @click="submitForm('pieceForm')" icon="el-icon-refresh">刷新</el-button>
                        </el-col> -->
                    </el-form-item>
                <!-- </el-form> -->
            </el-tab-pane>
            <el-tab-pane label="周菜谱" name="second" lazy>
                <el-form-item label="本周日期" prop="fweek" style="font-weight: bold">
                <el-date-picker
                    align="center"
                    style="width: 100%"
                    firstDayOfWeek="1"
                    @change="WeekChange"
                    v-model="weekMenuForm.fweek"
                    type="week"
                    format="yyyy 年，第 WW 周"
                    placeholder="请选择菜谱生效周">
                </el-date-picker>
            </el-form-item>
            <el-form-item v-if="fdate" prop="fdate">
                <el-input v-model="fdate" disabled></el-input>
            </el-form-item>
            </el-tab-pane>
        </el-tabs>
            <el-form-item label="餐厅类型" prop="ftype" style="font-weight: bold">
                <el-radio v-model="weekMenuForm.ftype" label="1" @change="typeChange">管理餐厅</el-radio>
                <el-radio v-model="weekMenuForm.ftype" label="2" @change="typeChange">员工餐厅</el-radio>
                <el-radio v-model="weekMenuForm.ftype" label="3" @change="typeChange">明档窗口</el-radio>
            </el-form-item>
            <el-form-item label="菜谱图片" prop="image1" style="font-weight: bold">
                <el-upload 
                    action='' 
                    ref="upload"
                    :on-change="getFile" 
                    :limit="5" 
                    list-type="picture"
                    :auto-upload="false" 
                    :on-exceed="handleExceed"
                    style="width: 100%;" >
                    <el-button size="medium" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item>
            <el-row style="margin-top: 10px;margin-bottom: 20px">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="isLoading"
                        @click="submitForm('weekMenuForm')">提交菜谱</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-dialog style="color: #F56C6C" title="删除确认？" :visible.sync="saveDialogVisible" size="mini" width="90%">
            <span class="dialog-footer">
                已存在【{{weekMenuForm.ftype_nm}}】餐厅，【{{weekMenuForm.fstart}}】- 【{{weekMenuForm.fend}}】的菜谱，是否覆盖?
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="saveDialogVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="SaveWeekMenu">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../../conf.js'
import qs from 'qs'
import moment from 'moment'
import { compressImage } from '../../../plugin/compress.js'
export default {
    data() {
        return {
            weekMenuForm: {
                ftype: '',
                fstart: '',
                fend: '',
                pnl: '',
                ftype_nm: '',
                fweek: '',
                fdate: moment().format('YYYY-MM-DD'),
                fcategory: 1
            },
            fdate: '',
            fileList: [],
            imageList: [],
            isLoading: false,
            saveDialogVisible: false,
            activeName: 'first',
            rules: {
                // fweek: [
                //     {required: true, message: '请选择菜谱生效周', trigger: 'change'}
                // ],
                ftype: [
                    {required: true, message: '请选择餐厅类型', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        handleClick(tab) {
            if(tab.index === '0' || !tab.index) {
                this.weekMenuForm.fcategory = 1
                console.log('this.weekMenuForm.fcategory', this.weekMenuForm.fcategory)
            }else if(tab.index === '1') {
                this.weekMenuForm.fcategory = 2
                console.log('this.weekMenuForm.fcategory', this.weekMenuForm.fcategory)
            }
        },
        WeekChange(val) {
            console.log('val', moment(val).format('YYYY-MM-DD'))
            this.weekMenuForm.fstart = moment(val).format('YYYY-MM-DD')
            this.weekMenuForm.fend = moment(this.weekMenuForm.fstart).add(5, 'days').format('YYYY-MM-DD')
            this.fdate =  moment(this.weekMenuForm.fstart).format('MM月DD日') + ' - ' 
                + moment(this.weekMenuForm.fend).format('MM月DD日')
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                console.log('valid', valid)
                if(valid  && this.CustomValidate() && this.CustomValidate2()){
                    console.log(this.weekMenuForm)
                    this.SaveWeekMenu()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        CustomValidate() {
            if(!this.weekMenuForm.fdate && this.weekMenuForm.fcategory === 1) {
                this.$message({
                    message: '请选择菜谱日期',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
                return false
            }
            if(!this.weekMenuForm.fweek && this.weekMenuForm.fcategory === 2 && this.GetDinnerWeekMenu()) {
                this.$message({
                    message: '请选择本周日期',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
                return false
            }
            return true
        },
        CustomValidate2() {
            if(this.fileList.length === 0) {
                this.$message({
                    message: '请上传菜谱图片',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
                return false
            }
            return true
        },
        async GetDinnerWeekMenu() {
            if(!this.weekMenuForm.fstart || !this.weekMenuForm.fend){
                return
            }

            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxDinnerWeekMenus/DinnerWeekMenu', qs.stringify({
                type: this.weekMenuForm.ftype,
                start: this.weekMenuForm.fstart,
                end: this.weekMenuForm.fend
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.saveDialogVisible = true
                    return false
                }
                return true
            }
        },
        async SaveWeekMenu() {
            this.saveDialogVisible = false
            this.isLoading = true

            // let fd = new FormData()
            // fd.append('file', this.imageList[0].raw)
            // console.log(this.imageList[0].raw)
            // const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxMaterial/CreateMaterial', 
            //     fd
            // )

            const dto = {
                FTYPE: this.weekMenuForm.ftype,
                FSTART: this.weekMenuForm.fstart,
                FEND: this.weekMenuForm.fend,
                PNL: this.weekMenuForm.pnl,
                FTYPE_NM: this.weekMenuForm.ftype_nm,
                FDATE: this.weekMenuForm.fdate,
                FCATEGORY: this.weekMenuForm.fcategory
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxDinnerWeekMenus/SaveDinnerWeekMenu', qs.stringify({
                dto,
                IMAGE1: encodeURIComponent(this.imageList[0]),
                FILENAME1: this.fileList[0],
                IMAGE2: encodeURIComponent(this.imageList[1]),
                FILENAME2: this.fileList[1],
                IMAGE3: encodeURIComponent(this.imageList[2]),
                FILENAME3: this.fileList[2],
                IMAGE4: encodeURIComponent(this.imageList[3]),
                FILENAME4: this.fileList[3],
                IMAGE5: encodeURIComponent(this.imageList[4]),
                FILENAME5: this.fileList[4],
            }))

            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: '菜谱提交成功',
                    center: true,
                    type: 'success'
                })
                setTimeout(function(){
                    window.location.href = '/weekMenuList'
                },2000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '菜谱提交失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '菜谱提交失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        typeChange() {
            if(this.weekMenuForm.ftype == '1') {
                this.weekMenuForm.ftype_nm = '管理'
            }else if(this.weekMenuForm.ftype == '2'){
                this.weekMenuForm.ftype_nm = '员工'
            }else if(this.weekMenuForm.ftype == '3'){
                this.weekMenuForm.ftype_nm = '明档'
            }
        },
        getFile(file, fileList) {
            console.log('file', file)
            console.log('fileList', fileList)
            this.fileList = []
            this.imageList = []

            fileList.forEach(item => {
                this.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }

                // // 不压缩
                // this.imageList.push(item.raw)
                // console.log('imageList', this.imageList)

                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 5) {
                    quality = 0.7
                }else if(size > 5) {
                    quality = 0.5
                }
                compressImage(item.url, {
                    width: 780, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.imageList.push(result)
                })
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许上五张照片',
                type: 'warning',
                center: true
            })
        },
    },
    mounted() {
        // let weekOfDay = parseInt(moment().format('d'))
        // console.log('weekOfDay', weekOfDay)
        // this.weekMenuForm.fstart = moment().subtract(weekOfDay, 'days').format('YYYY-MM-DD')
        // this.weekMenuForm.fend = moment().add(7 - weekOfDay - 1, 'days').format('YYYY-MM-DD')
        // this.fdate =  moment(this.weekMenuForm.fstart).format('MM月DD日') + ' - ' 
        //     + moment(this.weekMenuForm.fend).format('MM月DD日')
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.weekMenuForm.pnl = pnl
        }
    }
}
</script>