<template>
    <div>
        <el-row style="margin-bottom: 10px" :gutter="10">
            <el-col :span="19">
                <el-descriptions :column="2" size="small" border>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            部门
                        </template>
                        {{ sec_nm }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-user"></i>
                            姓名
                        </template>
                        {{ pname }}
                    </el-descriptions-item>
                </el-descriptions>
            </el-col>
            <el-col :span="5">
                <el-button 
                    size="small" 
                    type="danger" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI">扫码</el-button>
            </el-col>
        </el-row>
        <el-table :data="tableData" size="small" :show-header="false" style="margin-bottom: 20px">
            <el-table-column prop="label" width="90px">
            </el-table-column>
            <el-table-column prop="value" align="right" class-name="table_col">
                <template slot-scope="scope">
                    <div class="table-col">{{scope.row.value}}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-row style="margin-bottom: 20px" :gutter="5">
            <el-col :span="4" style="margin-top: 5px;font-size: 13px;font-weight: bold;text-align: right;">数量：</el-col>
            <el-col :span="20" style="font-size: 13px;text-align: left;font-weight: bold">
                <el-input-number 
                    size="small" 
                    v-model="shoelaces.qty" 
                    @change="QtyChange" 
                    :min="1" 
                    style="width: 100%;color: #F56C6C;font-weight: bold;"></el-input-number>
            </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px" :gutter="5">
            <el-col :span="4" style="margin-top: 5px;font-size: 13px;font-weight: bold;text-align: right;">部门：</el-col>
            <el-col :span="20" style="text-align: left">
                <el-select v-model="shoelaces.sec_no" placeholder="请选择部门" size="small" style="width: 100%;">
                    <el-option
                        v-for="item in secs"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        <span style="float: left">{{ item.label }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px" :gutter="5">
            <el-col :span="4" style="margin-top: 5px;font-size: 13px;font-weight: bold;text-align: right;">工序：</el-col>
            <el-col :span="20" style="text-align: left;">
                <el-select v-model="shoelaces.opera_no" placeholder="请选择工序" size="small" style="width: 100%;">
                    <el-option
                        v-for="item in processes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        <span style="float: left">{{ item.label }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row style="margin-bottom: 10px">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    size="medium"
                    :loading="isLoading"
                    @click="SaveShoelacesUser">确认交库</el-button>
            </el-col>
        </el-row>
        <el-collapse v-model="activeNames">
            <el-collapse-item name="1">
                <template slot="title">
                    <div style="font-weight: bold">上传记录</div>
                </template>
                <el-row :gutter="10" style="margin: 0px">
                    <el-col :span="12">
                        <el-date-picker
                            size="small"
                            v-model="date1"
                            align="right"
                            type="date"
                            @change="dateChange"
                            placeholder="选择日期"
                            style="width: 100%"
                            value-format="yyyy-MM-dd"
                            >
                        </el-date-picker>
                    </el-col>
                    <el-col :span="12">
                        <el-date-picker
                            size="small"
                            v-model="date2"
                            align="right"
                            type="date"
                            @change="dateChange"
                            placeholder="选择日期"
                            style="width: 100%"
                            value-format="yyyy-MM-dd"
                            >
                        </el-date-picker>
                    </el-col>
                </el-row>
                <el-table :data="tableCheckData" size="small" style="width: 100%">
                    <el-table-column prop="odr_no" :label="column_label+column_total">
                    </el-table-column>
                </el-table>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import wx from 'weixin-js-sdk'
import moment from 'moment'
export default {
    data() {
        return {
            pname: '',
            sec_nm: '',
            shoelaces: {
                odr_no: '',
                sec_no: 'HQ096C',
                qty: '',
                pnl: '',
                opera_no: '703-1',
                lno: ''
            },
            tableData: [],
            isLoading: false,
            secs: [{
                value: 'HQ096C',
                label: '鞋面中转仓'
            }],
            processes: [{
                value: '703-1',
                label: '穿鞋带'
            },{
                value: '703-2',
                label: '穿鞋扣'
            }],
            tableCheckData: [],
            activeNames: ['0'],
            date1: moment().format('YYYY-MM-DD'),
            date2: moment().format('YYYY-MM-DD'),
            column_label: '日期 / 指令号 / 型体 / 工序 / 数量 ',
            column_total: ''
        }
    },
    methods: {
        GetJSAPI() {
            // 测试数据
            // let result = "QAS/2108006-001,F52W921101EWT,7.5,24,133219,01" // dev
            // let result = "QAS/2401057-014,812431104-6,9,8,1293737," // dev
            //  let result = "QAF/C2403084-003,475413,UK7.5,10,1329793,03" // dev
            // let result = "QAS/2401087-002,475413,UK7.5,24,1327936,03" // dev
            // this.setTableValue(result) // dev
            
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    let result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
                    // console.log(result)
                    this.setTableValue(result)
                }
            })
        },
        setTableValue(code_str) {
            this.code_values = code_str.split(',')
            console.log(this.code_values)
            this.tableData = []
            this.tableData.push({
                label: '指令单号',
                value: this.code_values[0]
            })
            if(this.code_values[5]) {
                this.tableData.push({
                    label: '指令批号',
                    value: this.code_values[5]
                })
            }
            this.tableData.push({
                label: '工厂型号',
                value: this.code_values[1]
            })
            this.tableData.push({
                label: '指令SIZE',
                value: this.code_values[2]
            })
            this.tableData.push({
                label: '指令数量',
                value: this.code_values[3]
            })

            this.shoelaces.odr_no = this.code_values[0]
            this.shoelaces.qty = this.code_values[3]
            this.sqty = this.code_values[3]
            this.shoelaces.lno = this.code_values[4]
        },
        QtyChange(qty) {
            if(qty > this.sqty) {
                this.shoelaces.qty = this.sqty
                this.$message({
                    message: '数量不能超过：' + this.sqty,
                    center: true,
                    type: 'error'
                })
            }
        },
        async SaveShoelacesUser() {
            if(this.tableData.length === 0) {
                this.$message({
                    message: '请先进行扫码再交库',
                    center: true,
                    type: 'error'
                })
                return
            }
          
            this.isLoading = true
            const dto = {
                MADE_NO: '7',
                OPERA_NO: this.shoelaces.opera_no,
                PNL: this.shoelaces.pnl,
                ODR_NO: this.shoelaces.odr_no,
                QTY: this.shoelaces.qty,
                SEC_NO: this.shoelaces.sec_no.split(' - ')[0],
                DB_CREATE_USERNO: this.shoelaces.pnl,
                LNO: this.shoelaces.lno,
                SQTY: this.sqty
            }
            console.log('dto', this.shoelaces)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/SaveGjjzIoShoe', qs.stringify({
                dto
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.ResponseData) {
                this.$message({
                    message: '提交缴库成功',
                    center: true,
                    type: 'success',
                    duration: 1000
                })
                this.GetShoelacesList()
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && !res.data.ResponseData){
                this.$message({
                    message: res.data.ResponseData,
                    center: true,
                    type: 'error',
                    duration: 2000
                })
            }else {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }

            this.isLoading = false
        },
        dateChange() {
            if(this.date1 && this.date2) {
                this.GetShoelacesList()
            }
        },
        async GetShoelacesList() {
            this.tableCheckData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/GetGjjzIoList', qs.stringify({
                pnl: this.shoelaces.pnl,
                date1: this.date1,
                date2: this.date2
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                let count = 0
                let sum = 0
                res.data.ResponseData.forEach(item => {
                    count++
                    sum+=item.qty
                    this.tableCheckData.push({
                        odr_no: moment(item.iod).format('MM-DD') + ' / ' + item.odr_no + ' / ' + item.style + '/' + item.j_nm + ' / ' + item.qty,
                    })
                })

                this.column_total = ' 【'+count+'次/'+sum+'双】'
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        this.GetSignature() 
        this.GetShoelacesList()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.shoelaces.pnl = pnl
        }
        const pname = localStorage.getItem('PNAME')
        if(pname) {
            this.pname = pname
        }
        const sec_nm = localStorage.getItem('SEC_NM')
        if(sec_nm) {
            this.sec_nm = sec_nm
        }

        // 测试
        // this.shoelaces.pnl = '13200'
    }
}
</script>

<style scoped>
    .table-col {
        font-weight: bold;
        font-size: 14px;
        color: #F56C6C;
    }
</style>