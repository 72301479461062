<template>
    <div>
        <!-- <el-alert
            style="font-size: 20px"
            title="寰球健康公告"
            type="error"
            :description=error_alert>
        </el-alert> -->
        <el-form v-if="!isFinish" :model="healthyPlus" :rules="rules" ref="healthyPlus" size="small">
            <el-form-item label="工号" prop="pnl">
                <el-input v-model="healthyPlus.pnl" placeholder="请输入工号" @blur="GetLastHealthyData"></el-input>
            </el-form-item>
            <el-form-item label="姓名" prop="pname">
                <el-input v-model="healthyPlus.pname" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="部门" prop="sec_nm">
                <el-input v-model="healthyPlus.sec_nm" placeholder="请输入部门"></el-input>
            </el-form-item>
            <el-form-item label="请输入宿舍房号或外宿地址" prop="room_nm">
                <el-input v-model="healthyPlus.room_nm" placeholder="请输入宿舍房号或外宿地址"></el-input>
            </el-form-item>
            <el-form-item label="春节是否返乡（是否回老家）" prop="is_home">
                <el-radio-group v-model="healthyPlus.is_home">
                    <el-radio label="1">返乡</el-radio>
                    <el-radio label="2">留在工作地</el-radio>
                    <el-radio label="3">去其他地区</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="返厂出发所在地区" prop="habitation">
                <el-input v-model="healthyPlus.habitation" disabled> 
                    <el-button 
                        icon="el-icon-location-outline"
                        slot="append"
                        size="small" 
                        type="primary" 
                        @click="GetJSAPI">
                    </el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="出发时间" prop="setout_date">
                <el-date-picker
                    v-model="healthyPlus.setout_date"
                    size="small"
                    align="center"
                    type="date"
                    style="width: 100%"
                    placeholder="请选择出发时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="出行交通方式" prop="Vehicle">
                <el-radio-group v-model="healthyPlus.Vehicle" class="radio-content">
                    <el-radio label="1" class="radio-content-item">飞机</el-radio>
                    <el-radio label="2" class="radio-content-item">火车</el-radio>
                    <el-radio label="3" class="radio-content-item">大巴</el-radio>
                    <el-radio label="4" class="radio-content-item">自行驾车</el-radio><br>
                    <el-radio label="5" class="radio-content-item">其他交通方式</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="途径省市" prop="outAddress1">
                <el-row>
                    <el-col :span="24" class="form-item-label">1、自驾的同事填写高速路或者途径的服务区以及车牌 2、乘火车的同事填写车次、出发站名和到达站名 3、乘坐飞机的同事填写航班号</el-col>
                </el-row>
                <el-input v-model="healthyPlus.outAddress1" placeholder="请输入途径省市"></el-input>
            </el-form-item>
            <el-form-item label="公司管控类别选择" prop="control_type">
                <el-radio-group v-model="healthyPlus.control_type" class="radio-content">
                    <el-radio label="1" class="radio-content-item">A 高风险地区旅居史人员：【集中隔离14天】</el-radio>
                    <el-radio label="2" class="radio-content-item">B 中风险地区旅居史人员：【居家观察14天】</el-radio>
                    <el-radio label="3" class="radio-content-item">C 带星号的中高风险地区旅居史人员：【居家健康监测14天】</el-radio>
                    <el-radio label="4" class="radio-content-item">D 省外低风险地区旅居史返厂人员：【亮健康码、行程码通行】</el-radio>
                    <el-radio label="5" class="radio-content-item">E 省内但有省外低风险旅居史人员：【亮健康码、行程码通行】</el-radio>
                    <el-radio label="6" class="radio-content-item">F 省内返厂人员：【亮健康码、行程码通行】</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="请上传48小时核酸结果截图">
                <el-upload 
                    action='' 
                    ref="upload1"
                    :on-change="getFile1" 
                    :on-remove="handleRemove1"
                    :limit="1" 
                    list-type="picture" 
                    :auto-upload="false" 
                    style="width: 100%;">
                    <el-button size="small" type="info">核酸结果截图<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="请上传行程码截图" prop="image2">
                <el-upload 
                    action='' 
                    ref="upload2"
                    :on-change="getFile2" 
                    :on-remove="handleRemove2"
                    :limit="1" 
                    list-type="picture" 
                    :auto-upload="false" 
                    style="width: 100%;">
                    <el-button size="small" type="info">行程码截图<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="请上传健康码截图" prop="image3">
                <el-upload 
                    action='' 
                    ref="upload3"
                    :on-change="getFile3" 
                    :on-remove="handleRemove3"
                    :limit="1" 
                    list-type="picture" 
                    :auto-upload="false" 
                    style="width: 100%;">
                    <el-button size="small" type="info">健康码截图<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="其他备注">
                <el-row>
                    <el-col :span="24" class="form-item-label">如果你有其他涉及的疫情事宜需要反映，请在此处说明</el-col>
                </el-row>
                <el-input type="textarea" :rows="2" v-model="healthyPlus.remark" placeholder="请输入备注信息"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="24" style="margin-top: 20px;margin-bottom: 20px">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="isLoading"
                        @click="submitForm('healthyPlus')">提交</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-result v-if="isSuccess" icon="success" title="成功提示" subTitle="您可以正常返厂！">
            <template slot="extra">
                <el-descriptions style="width: 100%;" class="margin-top" :column="1" size="small" border>
                    <el-descriptions-item>
                        <template slot="label">
                        <i class="el-icon-user"></i>
                            个人信息
                        </template>
                        {{healthyPlus.sec_nm}} - {{healthyPlus.pname}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-date"></i>
                            提报日期
                        </template>
                        {{hdate}}
                    </el-descriptions-item>
                </el-descriptions>
                <div style="margin-top: 40px;font-weight: bold">请手机截图保存本页面！</div>
            </template>
        </el-result>
        <el-result v-if="isError" icon="error" title="错误提示" subTitle="您暂时无法返厂！">
            <template slot="extra">
               <el-table 
                    :show-header="false"
                    :data="tableData" 
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
                    size="mini" 
                    v-loading="table_loading" 
                    style="font-size: 11px;width: 100%;"
                    border>
                    <el-table-column prop="field1" min-width="70px" align="center">
                    </el-table-column>
                    <el-table-column prop="field2" min-width="180px" align="center">
                    </el-table-column>
                    <el-table-column prop="field3" min-width="70px" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" v-if="scope.row.field3 === 0" type="success" icon="el-icon-check"></el-button>
                            <el-button size="mini" v-if="scope.row.field3 > 0" type="danger" icon="el-icon-close"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </el-result>
    </div>
</template>

<script>
import moment from 'moment'
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
import wx from 'weixin-js-sdk'
import { compressImage } from '../../plugin/compress.js'
export default {
    data() {
        return {
            healthyPlus: {
                pnl: '',
                pname: '',
                sec_nm: '',
                room_nm: '',
                is_home: '',
                habitation: '',
                setout_date: '',
                Vehicle: '',
                outAddress1: '',
                control_type: '',
                remark: '',
                image1: '',
                filename1: '',
                image2: '',
                filename2: '',
                image3: '',
                filename3: '',
            },
            hdate: moment().format('YYYY-MM-DD HH:mm'),
            isFinish: false,
            isSuccess: false,
            isError: false,
            isLoading: false,
            tableData: [],
            table_loading: false,
            rules: {
                pnl: [
                    {required: true, message: '请输入工号', trigger: 'blur'}
                ],
                pname: [
                    {required: true, message: '请输入姓名', trigger: 'blur'}
                ],
                sec_nm: [
                    {required: true, message: '请输入部门', trigger: 'blur'}
                ],
                room_nm: [
                    {required: true, message: '请输入宿舍房号或外宿地址', trigger: 'blur'}
                ],
                is_home: [
                    {required: true, message: '请选择春节是否返乡', trigger: 'change'}
                ],
                habitation: [
                    {required: true, message: '请输入返厂出发所在地区', trigger: 'blur'}
                ],
                setout_date: [
                    {required: true, message: '请选择出发时间', trigger: 'change'}
                ],
                Vehicle: [
                    {required: true, message: '请选择出行交通方式', trigger: 'change'}
                ],
                outAddress1: [
                    {required: true, message: '请输入途径省市', trigger: 'blur'}
                ],
                control_type: [
                    {required: true, message: '请选择公司管控类别', trigger: 'change'}
                ],
                image2: [
                    {required: true, message: '请上传行程码截图', trigger: 'change'}
                ],
                image3: [
                    {required: true, message: '请上传健康码截图', trigger: 'change'}
                ]
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.healthyPlus)
                    this.SaveHealthyPlus()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveHealthyPlus() {
            this.isLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrHealthyPluss/SaveHealthyPlus', qs.stringify({
                dto: this.healthyPlus,
                IMAGE1: encodeURIComponent(this.healthyPlus.image1),
                FILENAME1: this.healthyPlus.filename1,
                IMAGE2: encodeURIComponent(this.healthyPlus.image2),
                FILENAME2: this.healthyPlus.filename2,
                IMAGE3: encodeURIComponent(this.healthyPlus.image3),
                FILENAME3: this.healthyPlus.filename3,
            }))

            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功') {
                this.$message({
                    message: '提交成功',
                    center: true,
                    type: 'success'
                })

                this.isFinish = true
                this.isSuccess = true
                this.isError = false
                this.tableData = []
                res.data.ResponseData.ResponseData.map(item => {
                    if(item.Column3 > 0) {
                        this.isSuccess = false
                        this.isError = true
                    }

                    this.tableData.push({
                        field1: item.Column1,
                        field2: item.Column2,
                        field3: item.Column3
                    })
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                this.$message({
                    message: '提交失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })

                this.isFinish = false
                this.isSuccess = false
                this.isError = false
            }

            this.isLoading = false
        },
        handleRemove1() {
            this.healthyPlus.image1 = null
            this.healthyPlus.filename1 = ''
        },
        getFile1(file, fileList) {
            if(!this.beforeAvatarUpload(file)) {
                this.$refs.upload1.handleRemove1(file);
                return
            }
            // this.getBase64(file.raw).then(res => {
            //     console.log(res)
            //     this.healthyPlus.image1 = res
            // })
            let quality = 1
            let size = this.getSize(file)
            if(size > 1 && size < 2) {
                quality = 0.5
            }else if(size > 2) {
                quality = 0.2
            }

            compressImage(file.url, {
                width: 390, // 压缩后图片的宽
                // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                }).then(result => { // result即为压缩后的结果
                this.healthyPlus.image1 = result
            })
            this.healthyPlus.filename1 = fileList[0].name
        },
       
        handleRemove2() {
            this.healthyPlus.image2 = null
            this.healthyPlus.filename2 = ''
        },
        getFile2(file, fileList) {
            if(!this.beforeAvatarUpload(file)) {
                this.$refs.upload2.handleRemove2(file);
                return
            }
            // this.getBase64(file.raw).then(res => {
            //     console.log(res)
            //     this.healthyPlus.image2 = res
            // })
            let quality = 1
            let size = this.getSize(file)
            if(size > 1 && size < 2) {
                quality = 0.5
            }else if(size > 2) {
                quality = 0.2
            }

            compressImage(file.url, {
                width: 390, // 压缩后图片的宽
                // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                }).then(result => { // result即为压缩后的结果
                this.healthyPlus.image2 = result
            })

            this.healthyPlus.filename2 = fileList[0].name
        },
     
        handleRemove3() {
            this.healthyPlus.image3 = null
            this.healthyPlus.filename3 = ''
        },
        getFile3(file, fileList) {
            if(!this.beforeAvatarUpload(file)) {
                this.$refs.upload3.handleRemove3(file);
                return
            }
            // this.getBase64(file.raw).then(res => {
            //     console.log(res)
            //     this.healthyPlus.image3 = res
            // })

            let quality = 1
            let size = this.getSize(file)
            if(size > 1 && size < 2) {
                quality = 0.5
            }else if(size > 2) {
                quality = 0.2
            }

            compressImage(file.url, {
                width: 390, // 压缩后图片的宽
                // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                }).then(result => { // result即为压缩后的结果
                // this.ehsForm.imageList.push(result)
                this.healthyPlus.image3 = result
            })

            this.healthyPlus.filename3 = fileList[0].name
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 3;
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 3MB!')
            }
            return isLt2M && (isJPG || isPNG)
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        async GetJSAPI() {
            this.GetLocation()
        },
        async GetLocation() {
            wx.getLocation({
                type: 'gcj02',
                success: res => {
                    console.log(res)
                    this.GetBaiduLocation(res.latitude, res.longitude)
                }
            })
        },
        async GetBaiduLocation(lat, lng) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/BaiduLocation/BaiduLocationName', qs.stringify({
                lat: lat, 
                lng: lng 
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功') {
                if(res.data.ResponseData.message) {
                    this.$message({
                        message: res.data.ResponseData.message,
                        center: true,
                        type: 'success'
                    })
                }
                if(res.data.ResponseData.status === 0) {
                    this.healthyPlus.habitation = res.data.ResponseData.result.formatted_address
                }
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['openLocation', 'getLocation'] // 必填，需要使用的JS接口列表
                })
            }
        },
        async GetLastHealthyData() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrHealthyPluss/GetLastHealthy', qs.stringify({
                pnl: this.healthyPlus.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    let healthyData = res.data.ResponseData[0]
                    this.healthyPlus.pname = healthyData.pname
                    this.healthyPlus.sec_nm = healthyData.sec_nm
                    this.healthyPlus.outAddress1 = healthyData.outAddress1
                    // this.healthyPlus.setout_date = moment(healthyData.setout_date).format('YYYY-MM-DD'), 
                    this.healthyPlus.Vehicle = healthyData.Vehicle
                }
            }
        }
    },
    mounted() {
        this.GetSignature()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.healthyPlus.pnl = pnl
            this.GetLastHealthyData()
        }
    }
}
</script>

<style scoped>
    .el-form-item {
        font-weight: bold;
    }
    .radio-content{
        margin-top: 5px;
        padding-top: 5px;
           padding-left: 10px;
        margin-left: 10px;
        text-align: left;
    }
    .radio-content-item{
        margin-top: 10px;
        margin-bottom: 10px;
        display: inline-block;
    }
    .form-item-label{
        font-weight: normal;
        color: #909399;
        font-size: 13px;
        text-align: left;
        line-height: 22px;
        margin-bottom: 5px;
    }
</style>