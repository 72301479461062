<template>
    <div>
        <el-form :model="ehsForm" ref="ehsForm" :rules="rules" label-width="80px" size="small">
            <el-form-item label="类别" prop="check_type" style="font-weight: bold">
                <!-- <el-select v-model="ehsForm.check_type" placeholder="请选择" style="width: 100%">
                    <el-option
                        v-for="item in check_types"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                </el-select> -->
                <el-row >
                    <el-col :span="6" v-for="(item, index) in check_types" :key="index">
                        <el-button :type="item.btn_type" size="small" style="width: 90%;font-weight: bold; font-size: 12px" @click="selection(item)">{{item.label}}</el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="稽查组长" prop="check_leader" style="font-weight: bold">
                <el-row>
                    <el-col :span="8" v-for="(item, index) in check_leaders" :key="index">
                        <el-button :type="item.btn_type" size="small" style="width: 90%;font-weight: bold; margin-top: 8px; font-size: 13px" @click="selection3(item)">{{item.label}}</el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="区域" prop="district" style="font-weight: bold">
                <el-select v-model="ehsForm.district_id" filterable placeholder="请选择" @change="CheckDistrictChange" clearable style="width: 100%">
                    <el-option
                        v-for="item in districts"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="责任人二" prop="person2" style="font-weight: bold">
                <el-select v-model="ehsForm.person2" filterable placeholder="请选择" @change="CheckPersonsChange" clearable style="width: 100%">
                    <el-option
                        v-for="item in person2s"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="责任人一" prop="person1" style="font-weight: bold">
                <el-select v-model="ehsForm.person1" filterable placeholder="请选择" @change="CheckPersonChange" clearable style="width: 100%">
                    <el-option
                        v-for="item in person1s"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="图片" prop="fileList" style="font-weight: bold;">
                <el-upload 
                    action='' 
                    ref="upload"
                    :on-change="getFile" 
                    :limit="3" 
                    :on-remove="handleRemove"
                    list-type="picture"
                    :auto-upload="false" 
                    :accept="'image/*'"
                    :on-exceed="handleExceed"
                    style="width: 100%;" >
                    <el-button size="medium" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="问题描述" prop="check_describe" style="font-weight: bold">
                <el-autocomplete
                    style="width: 100%;"
                    type="textarea"
                    :rows="3"
                    popper-class="my-autocomplete"
                    v-model="ehsForm.check_describe"
                    :fetch-suggestions="querySearch"
                    placeholder="请详细描述问题">
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.value }}</div>
                    </template>
                </el-autocomplete>
            </el-form-item>
            <el-form-item label="一类" prop="danger_type1" style="font-weight: bold">
                <el-select v-model="ehsForm.danger_type1" 
                    filterable 
                    placeholder="请选择" 
                    @change="GetCheckTypes" 
                    clearable 
                    style="width: 100%">
                    <el-option
                        v-for="item in danger_type1s"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="二类" prop="danger_type2" style="font-weight: bold">
                <el-select v-model="ehsForm.danger_type2" filterable placeholder="请选择" clearable style="width: 100%">
                    <el-option
                        v-for="item in danger_type2s"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="等级" prop="danger_type2" style="font-weight: bold">
                <el-row :gutter="10">
                    <!-- <el-col :span="3" style="font-weight: bold">等级</el-col> -->
                    <el-col :span="5" v-for="(item, index) in danger_levels" :key="index">
                        <el-tooltip effect="dark" :content="item.tooltip" placement="top">
                            <el-button :type="item.btn_type" 
                                size="small" 
                                :plain="item.plain"
                                style="width: 100%;font-weight: bold; font-size: 13px" 
                                @click="selection2(item)">{{item.label}}</el-button>
                        </el-tooltip>
                    </el-col>
                </el-row>
            </el-form-item>
            <!-- <el-row style="margin-top: 10px;margin-bottom: 20px">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="isLoading"
                        @click="SubmitForm('ehsForm')">提交稽查</el-button>
                </el-col>
            </el-row> -->
            <el-row :gutter="20" style="margin-top: 10px;margin-bottom: 20px">
                <el-col :span="12">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="isLoading"
                        @click="SubmitForm('ehsForm')">提交稽查问题</el-button>
                </el-col>
                <el-col :span="12">
                    <el-button 
                        style="width: 100%;"
                        type="success" 
                        :loading="isLoading"
                        @click="SubmitForm2('ehsForm')">提交并新增</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf.js'
import { compressImage } from '../../../plugin/compress.js'
export default{
    data() {
        return {
            pnl: '',
            isLoading: false,
            // check_types: ['日检','月检A','月检B'],
            check_types: [{
                btn_type: 'primary',
                label: '日检',
                value: '日检',
                fid: '01'
            },{
                btn_type: 'default',
                label: '月检A',
                value: '月检A组',
                fid: '02'
            },{
                btn_type: 'default',
                label: '月检B',
                value: '月检B组',
                fid: '03'
            },{
                btn_type: 'default',
                label: '节前',
                value: '节前检查',
                fid: '04'
            // },{
            //     btn_type: 'default',
            //     label: '节前',
            //     value: '节前检查',
            //     fid: '04'
            // }
            // ,{
            //     btn_type: 'default',
            //     label: '专项',
            //     value: '专项检查',
            //     fid: '05'
            // },{
            //     btn_type: 'default',
            //     label: '高管',
            //     value: '高管检查',
            //     fid: '06'
            // },{
            //     btn_type: 'default',
            //     label: '其它',
            //     value: '其它检查',
            //     fid: '07'
            }],
            person1s: [],
            person2s: [],
            districts: [],
            fileList: [],
            imageList: [],
            danger_type1s: [],
            danger_type2s: [],
            danger_levels: [{
                btn_type: 'danger',
                label: '红',
                value: '红',
                tooltip: '严重 (签发隐患单)',
                plain: true,
                points: 5.0
            },{
                btn_type: 'warning',
                label: '橙',
                value: '橙',
                tooltip: '高危 (重要,需外部支持改善)',
                plain: true,
                points: 2.0
            },{
                btn_type: 'yellow',
                label: '黄',
                value: '黄',
                tooltip: '中等 (一般,需跨部门支持改善)',
                plain: true,
                points: 1.5
            },{
                btn_type: 'primary',
                label: '蓝',
                value: '蓝',
                tooltip: '低等 (轻微,立即可以改善的)',
                plain: true,
                points: 1.0
            }],
            restaurants: [ 
                { "value": "1-安装不规范"},
                { "value": "2-管道水表等设施损坏"},
                { "value": "3-漏水,浪费用水"},
                { "value": "4-违规用消防水"},
                { "value": "5-泄漏,违规排放"},
                { "value": "6-水沟,井盖,下水道问题"},
                { "value": "7-污水管理问题"},
                { "value": "8-桶装水未封口，水机坏"}],
            ehsForm: {
                check_type: '日检',
                check_leader: '胡小君',
                person1: '',
                person2: '',
                district: '',
                check_describe: '',
                danger_type1: '',
                danger_type2: '',
                danger_level: '',
                check_points: 0,
                pnl1: '',
                pnl2: '',
                district_id: ''
            },
            check_leaders: [{
                btn_type: 'primary',
                label: '胡小君',
                value: '61558',
            }],
            rules: {
                check_type: [
                    {required: true, message: '请点击类别', trigger: 'change'}
                ],
                check_leader: [
                    {required: true, message: '请点击稽查组长', trigger: 'change'}
                ],
                person1: [
                    {required: true, message: '请输入第一责任人', trigger: 'change'}
                ],
                person2: [
                    {required: true, message: '请输入第二责任人', trigger: 'change'}
                ],
                district: [
                    {required: true, message: '请输入区域', trigger: 'change'}
                ],
                check_describe: [
                    {required: true, message: '请输入问题描述', trigger: 'change'}
                ],
                danger_type1: [
                    {required: true, message: '请输入一类', trigger: 'change'}
                ],
                // danger_type2: [
                //     {required: true, message: '请输入二类', trigger: 'change'}
                // ],
                danger_level: [
                    {required: true, message: '请输入二类', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        selection(e) {
            this.check_types.forEach(item => {
                if(item.label === e.label) {
                    item.btn_type = 'primary'
                    this.ehsForm.check_type = item.value
                    this.GetCheckLeader(item.fid)
                }else {
                    item.btn_type = 'default'
                }
            })
        },
        async GetCheckLeader(fid) {
            this.check_leaders = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetCheckLeader', qs.stringify({
                fid
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                res.data.ResponseData.forEach(item => {
                    this.check_leaders.push({
                        label: item.PNAME,
                        value: item.PNL,
                        btn_type: 'default',
                    })
                })
            }
        },
        selection3(e) {
            this.check_leaders.forEach(item => {
                if(item.label === e.label) {
                    item.btn_type = 'primary'
                    this.ehsForm.check_leader = item.label
                }else {
                    item.btn_type = 'default'
                }
            })
        },
        selection2(e) {
            this.danger_levels.forEach(item => {
                if(item.label === e.label) {
                    item.plain = false
                    this.ehsForm.danger_level = item.value
                    this.ehsForm.check_points = item.points
                }else {
                    item.plain = true
                }
            })
        },
        querySearch(queryString, cb) {
            var restaurants = this.restaurants
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
            // 调用 callback 返回建议列表的数据
            cb(results)
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
            }
        },
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    this.SaveEhsCheck(false)
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        SubmitForm2(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    this.SaveEhsCheck(true)
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveEhsCheck(isNew) {
            this.isLoading = true
            const dto = {
                CHECK_TYPE: this.ehsForm.check_type,
                PERSON1: this.ehsForm.person1,
                PERSON2: this.ehsForm.person2,
                DISTRICT: this.ehsForm.district,
                CHECK_DESCRIBE: this.ehsForm.check_describe,
                DANGER_TYPE1: this.ehsForm.danger_type1,
                DANGER_TYPE2: this.ehsForm.danger_type2,
                DANGER_LEVEL: this.ehsForm.danger_level,
                DB_CREATE_USERNO: this.pnl,
                CHECK_POINTS: this.ehsForm.check_points,
                CHECK_LEADER: this.ehsForm.check_leader,
                PNL1: this.ehsForm.pnl1,
                PNL2: this.ehsForm.pnl2,
                DISTRICT_ID: this.ehsForm.district_id
            }
            console.log('dto', dto)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/SaveEhsCheck', qs.stringify({
                dto,
                IMAGE1: encodeURIComponent(this.imageList[0]),
                IMAGE2: encodeURIComponent(this.imageList[1]),
                IMAGE3: encodeURIComponent(this.imageList[2]),
                FILENAME1: this.fileList[0],
                FILENAME2: this.fileList[1],
                FILENAME3: this.fileList[2]
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: '提交稽查问题成功',
                    center: true,
                    type: 'success'
                })
                setTimeout(function(){
                    if(isNew) {
                        window.location.href = '/ehsCheckCreate'
                    } else {
                        window.location.href = '/ehsCheckList'
                    }
                }, 1000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '提交检查问题失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '提交检查问题失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async GetCheckPerson() {
            this.person1s = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetCheckPerson')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                res.data.ResponseData.forEach(item => {
                    this.person1s.push({
                        label: item.PCODE + ' - ' + item.PNAME,
                        value: item.DB_ID,
                        pnl: item.PNL,
                        pname: item.PNAME
                    })
                })
            }
        },
        async GetCheckPersons(PFID) {
            // console.log('PFID', PFID)
            // if(PFID) {
            //     this.ehsForm.person1 = this.person1s.find(item => item.value == PFID).pname
            //     this.ehsForm.pnl1 = this.person1s.find(item => item.value == PFID).pnl
            // }
            this.person2s = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetCheckPersons', qs.stringify({
                PFID
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                res.data.ResponseData.forEach(item => {
                    this.person2s.push({
                        label: item.PCODE + ' - ' + item.PNAME,
                        value: item.DB_ID,
                        pnl: item.PNL,
                        pname: item.PNAME,
                        pfid: item.PFID
                    })
                })
            }
        },
        async GetCheckDistrict(PFID) {
            // console.log('PFID', PFID)
            // if(PFID) {
            //     this.ehsForm.person2 = this.person2s.find(item => item.value == PFID).pname
            //     this.ehsForm.pnl2 = this.person2s.find(item => item.value == PFID).pnl
            // }
            this.districts = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetCheckDistrict', qs.stringify({
                PFID
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                res.data.ResponseData.forEach(item => {
                    this.districts.push({
                        label: item.PCODE + ' - ' + item.DISTRICT,
                        value: item.DB_ID,
                        district: item.DISTRICT,
                        pfid: item.PFID
                    })
                })
            }
        },
        CheckDistrictChange(id) {
            // console.log('id', id)
            const DISTRICT = this.districts.find(item => item.value == id)
            // console.log('DISTRICT', DISTRICT)
            this.ehsForm.district = DISTRICT.district
            this.ehsForm.district_id = DISTRICT.value
            const PERSON2S = this.person2s.filter(item => item.value == DISTRICT.pfid)
            console.log('PERSON2S', PERSON2S)
            this.ehsForm.person2 = ''
            this.ehsForm.person1 = ''
            if(PERSON2S.length === 1) {
                this.ehsForm.person2 = PERSON2S[0].value
                this.CheckPersonsChange(PERSON2S[0].value) 
            }
           
            // let pfid = this.districts.find(item => item.value == id).pfid
            // // this.person2s = this.person2s.find(item => item.value == pfid)
            // let person = this.person2s.find(item => item.value == pfid)
            // console.log('person', person)
            // this.ehsForm.person2 = person.value 
            // this.ehsForm.person1 = this.person1s.find(item => item.value ==  person.pfid).value 
        },
        CheckPersonsChange(id) {
            console.log('id', id)
            const PERSON2 = this.person2s.find(item => item.value == id)
            this.ehsForm.person2 = PERSON2.pname
            this.ehsForm.pnl2 = PERSON2.pnl
            const PERSON1S = this.person1s.filter(item => item.value == PERSON2.pfid)
            console.log('PERSON1S', PERSON1S)
            this.ehsForm.person1 = ''
            if(PERSON1S.length === 1) {
                this.ehsForm.person1 = PERSON1S[0].pname
                this.ehsForm.pnl1 = PERSON1S[0].pnl
            }
        },
        CheckPersonChange(id) {
            console.log('id', id)
        },
        async GetCheckType() {
            this.danger_type1s = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetCheckType')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                res.data.ResponseData.forEach(item => {
                    this.danger_type1s.push({
                        label: item.DANGER_TYPE,
                        value: item.DB_ID
                    })
                })
            }
        },
        async GetCheckTypes(FPID) {
            console.log('FPID', FPID)
            if(FPID) {
                this.ehsForm.danger_type1 = this.danger_type1s.find(item => item.value == FPID).label
            }

            this.danger_type2s = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetCheckTypes', qs.stringify({
                FPID
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                res.data.ResponseData.forEach(item => {
                    this.danger_type2s.push({
                        label: item.DANGER_TYPE,
                        value: item.DANGER_TYPE
                    })
                })
            }
        },
        getFile(file, fileList) {
            console.log('file', file)
            console.log('fileList', fileList)
            this.fileList = []
            this.imageList = []
            fileList.forEach(item => {
                this.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.imageList.push(result)
                })
                console.log('this.imageList', this.imageList)
            })
        },
        handleRemove(file, fileList) {
            console.log('file', file)
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.fileList = []
            this.imageList = []
            fileList.forEach(item => {
                this.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        },
    },
    mounted() {
        this.GetCheckPerson()
        this.GetCheckPersons()
        this.GetCheckDistrict()
        this.GetCheckType()
        this.GetCheckTypes()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>

<style lang="less" scoped>
    .my-autocomplete {
        li {
            line-height: normal;
            padding: 7px;
            .name {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    .el-button--yellow.is-plain  {
        background: lightyellow;
        border-color: yellow;
        color: #C0C4CC;
    }
    .el-button--yellow:focus, .el-button--yellow:hover {
        background: yellow;
        border-color: yellow;
        color:  #606266;
    }
</style>