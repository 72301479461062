<template>
    <div>
        <el-table :data="EquipmentData" size="small" :show-header="false">
            <el-table-column prop="label" width="80px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column>
            <el-table-column align="right" width="90px">
                <el-button 
                    size="small" 
                    type="primary" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI1">扫码</el-button>
            </el-table-column>
        </el-table>
        <el-row style="margin-top: 20px;margin-bottom: 20px;" v-if="this.EquipmentData[0].value">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    size="medium"
                    :loading="isLoading"
                    :disabled="!this.pnl"
                    @click="SaveEquipment">提交设备码</el-button>
            </el-col>
        </el-row>
        <el-table :data="PartNoData" size="small" :show-header="false">
            <el-table-column prop="label" width="80px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column>
            <el-table-column align="right" width="90px">
                <el-button 
                    size="small" 
                    type="primary" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI2">扫码</el-button>
            </el-table-column>
        </el-table>
        <el-table :data="PartData" size="small" :show-header="false">
            <el-table-column prop="label" width="80px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-form 
            :model="equipForm" 
            :rules="rules" 
            ref="equipForm" 
            size="small" 
            v-if="this.PartNoData[0].value" 
            style="margin-top: 20px;font-weight: bold" 
            label-width="90px">
            <el-form-item label="每双数量" prop="unitnum">
                <!-- <el-input v-model="equipForm.unitnum"></el-input> -->
                <el-input-number 
                    size="small" 
                    :min="0"
                    :step="1"
                    :precision="0"
                    style="width: 100%;" 
                    placeholder="请输入每双数量" 
                    v-model="equipForm.unitnum">
                </el-input-number>
            </el-form-item>
        </el-form>
        <el-row style="margin-top: 20px;" v-if="this.PartNoData[0].value">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    size="medium"
                    :loading="isLoading2"
                    :disabled="!this.pnl"
                    @click="SavePart">提交部件码</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            pnl: '', 
            EquipmentData: [{
                label: '设备码',
                value: ''
            }],
            isLoading: false,
            PartNoData: [{
                label: '部件码',
                value: ''
            }],
            PartData: [],
            isLoading2: false,
            equipForm: {
                unitnum: ''
            },
            rules: {
                unitnum: [
                    {required: true, message: '请输入每双数量', trigger: 'blur'}
                ],
            }
        }
    },
    methods: {
        GetJSAPI1() {
            // this.EquipmentData[0].value = '123456789' // dev
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
                    this.EquipmentData[0].value = result
                }
            })
        },
        GetJSAPI2() {
            // const code_str = 'QAS/2108005-009;;BW-B101;鞋头里;5.5;51;HPA20210902006'
            // this.getCodeData(code_str) // dev
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
                    this.getCodeData(result)
                }
            })
        },
        getCodeData(code_str) {
            let code_values = code_str.split(';')
            console.log(code_values)
            this.PartNoData[0].value = code_values[2]
            this.setPartData(code_values)
        },
        setPartData(code_values) {
            this.PartData.push({
                label: '部件名称',
                value: code_values[3]
            },{
                label: '指令单号',
                value: code_values[0]
            },{
                label: '尺码',
                value: code_values[4]
            },{
                label: '数量',
                value: code_values[5]
            })
            console.log('this.PartData', this.PartData)
        },
        async SaveEquipment() {
            this.isLoading = true
            const dtos = {
                DeviceId: this.EquipmentData[0].value,
                UserId: this.pnl
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/DevHeavy/DeviceQrCode', qs.stringify({
                dtos
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: '设备码：' + this.EquipmentData[0].value + ' 提交成功！',
                    center: true,
                    type: 'success'
                })
                this.EquipmentData[0].value = ''
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }

            this.isLoading = false
        },
        async SavePart() {
            this.isLoading2 = true
            const dtos = {
                PartsCode: this.PartNoData[0].value,
                UserId: this.pnl,
                odr_no: this.PartData[1].value,
                worksnum: this.PartData[3].value,
                unitnum: this.equipForm.unitnum
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/DevHeavy/PartsQrCode', qs.stringify({
                dtos
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: '部件码：' + this.PartNoData[0].value + ' 提交成功！',
                    center: true,
                    type: 'success'
                })
                this.PartNoData[0].value = ''
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }

            this.isLoading2 = false
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        this.GetSignature()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>
