<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;" stretch>
            <el-tab-pane name="first" lazy>
                <span slot="label">未提报(<span style="font-size: 12px;color: #F56C6C;font-weight: bold">{{unNum}}</span>)</span>
                <el-form 
                    :model="unHealtyhForm" 
                    ref="unHealtyhForm" 
                    label-width="60px" 
                    size="small" 
                    style="margin-top: 10px">
                    <el-row :gutter="10">
                        <el-col :span="18">
                            <el-form-item label="日期" prop="date" style="font-weight: bold;">
                                <el-date-picker
                                    v-model="unHealtyhForm.date"
                                    style="width: 100%;"
                                    size="small"
                                    type="date"
                                    align="center"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-button 
                                size="small" 
                                type="danger" 
                                icon="el-icon-refresh" 
                                style="width: 100%;"
                                @click="unSubmitForm('unHealtyhForm')">刷新</el-button>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table 
                    max-height="580"
                    :data="unTableData" 
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
                    size="mini" 
                    v-loading="table_loading" 
                    style="font-size: 11px;margin-top: 15px;"
                    border>
                    <el-table-column prop="pnl" label="工号" min-width="48px" align="center">
                    </el-table-column>
                    <el-table-column prop="pname" label="姓名" min-width="50px" align="center">
                    </el-table-column>
                    <el-table-column prop="sec_nm" label="部门" min-width="82px" align="center">
                    </el-table-column>
                    <el-table-column prop="tel" label="手机" min-width="80px" align="center">
                        <template slot-scope="scope">
                        <a :href="'tel:' + scope.row.tel">
                            <i class="el-icon-mobile-phone"></i>{{scope.row.tel}}
                        </a>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import moment from 'moment'
export default {
    data() {
        return {
            pnl: '',
            authority: '',
            activeName: 'first',
            table_loading: false,
            unTableData: [],
            unHealtyhForm: {
                date: new moment().format('YYYY-MM-DD')
            },
            hotNum: 0,
            unNum: 0
        }
    },
    methods: {
        handleClick(tab) {
            this.tab = tab
            if(tab.index === '0' || !tab.index) {
               this.GetDayUnHealthy()
            }
        },
        unSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.unHealtyhForm)
                    this.GetTotalData()
                    this.GetDayUnHealthy()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async GetDayUnHealthy() {
            this.unTableData = []
            this.table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlhealthys/GetDayUnHealthySecList', qs.stringify({
                pnl: this.pnl,
                authority: this.authority,
                date: this.unHealtyhForm.date
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.map(item => {
                    item.hdate = moment(item.hdate).format('YYYY-MM-DD')
                    this.unTableData.push(item)
                })
            }
            this.table_loading = false
        },
        async GetAdmin() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlhealthys/GetHealthyAuthority', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.authority = res.data.ResponseData
                this.GetTotalData()
            }
        },
        async GetTotalData() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlhealthys/GetTotalDataSec', qs.stringify({
                pnl: this.pnl,
                date: this.unHealtyhForm.date
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                console.log(res.data.ResponseData)
                // this.hotNum = res.data.ResponseData[0].Column1
                this.unNum = res.data.ResponseData[0].Column1
            }
        }
    },
    async mounted() {
        // await this.GetAdmin()
        this.GetTotalData()
        this.GetDayUnHealthy()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
            // this.pnl = '38432' // dev
            // this.pnl = '25196' // dev
            //  this.pnl = '55082' // dev
            // this.pnl = '0165' // dev
            // this.pnl = '0018' // dev
            // this.pnl = '58697' // dev
        }
    }
}
</script>