<template>
    <div>
        <el-card shadow="always" v-for="(item, index) in rewardList" :key="index" class="box-card">
            <el-row>
                <el-col :span="6" style="text-align: left">奖惩日期</el-col>
                <el-col :span="18" style="text-align: right">{{item.rpdate}}</el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="6" style="text-align: left">奖惩类型</el-col>
                <el-col :span="18" style="text-align: right">{{item.repu_nm}}</el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="6" style="text-align: left">奖惩金额</el-col>
                <el-col :span="18" style="text-align: right">{{item.rp_money}} 元</el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="6" style="text-align: left">奖惩原因</el-col>
                <el-col :span="18" style="text-align: right">{{item.repu_sen}}</el-col>
            </el-row>
        </el-card>
        <el-empty v-if="rewardList.length === 0" description="没有数据"></el-empty>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import moment from 'moment'
export default {
    data() {
        return {
            pnl: '',
            rewardList: []
        }
    },
    methods: {
        async getRewardList() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Reward/RewardList', qs.stringify({
                start: '',
                end: moment().format('YYYY-MM-DD'),
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.forEach(item => {
                    this.rewardList.push({
                        rpdate: moment(item.rpdate).format('YYYY-MM-DD'),
                        repu_nm: item.repu_nm,
                        rp_money: item.rp_money,
                        repu_sen: item.repu_sen
                    })
                })
            }
        }
    },
    mounted() {
        this.getRewardList()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
            // this.pnl = '58464' // dev
        }
    }
}
</script>

<style scoped>
    .box-card{
        margin-bottom: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 13px;
    }
</style>