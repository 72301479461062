<template>
    <div>
        <el-tabs 
            tab-position="left" 
            type="border-card" 
            style="height: 800px;border: none;position: absolute;left: 0;top: 0;width: 100%;">
            <el-tab-pane 
                v-for="(type, type_index) in dinnerOrders.dinnerOrderTypes" 
                :label="type.FTYPE_NM" 
                :name="type.FTYPE_NO" 
                :key="type_index">
                <el-row 
                    v-for="(menu, menu_index) in dinnerOrders.dinnerOrderMenus.filter(item=>item.FTYPE_NO === type.FTYPE_NO)" 
                    :key="menu_index" 
                    style="text-align: left;margin-bottom: 30px;">
                    <el-col :span="8">
                        <div>
                            <el-avatar style="border-radius: 8px;" :size="70" :src="menu.FIMGPATH"></el-avatar>
                        </div>
                    </el-col>
                    <el-col :span="16">
                        <div>
                            <span>{{ menu.FNAME }}</span> 
                        </div>
                        <div style="padding-top: 4px;">
                            <span style="font-size: 13px;">月售：{{ menu.FSALESM }}</span> 
                        </div>
                        <div style="padding-top: 4px;">
                            <span style="font-size: 13px;">￥{{ menu.FPRICE }}</span> 
                            <span style="font-size: 13px;text-align: right;padding-left: 40px;">
                                <el-input-number 
                                    size="mini" 
                                    :min="0"
                                    :precision="0"
                                    :max="2"
                                    style="width: 90px;" 
                                    @change="numberChange(menu)"
                                    v-model="menu.FNUM">
                                </el-input-number>
                            </span> 
                        </div>
                    </el-col>
                </el-row>
            </el-tab-pane>
        </el-tabs>
        <el-card style=" position: fixed;left: 0;bottom: 0;width: 100%;text-align: center;padding: 0px 0px 20px 0px">
            <el-row type="flex" class="row-bg" justify="space-between" >
                <el-col :span="3">
                    <el-badge :value="total_num" class="item">
                        <el-button type="danger" size="small" icon="el-icon-shopping-bag-2" circle @click="dinnerOrderDialogForm = true"></el-button>
                    </el-badge>
                </el-col>
                <el-col :span="9" style="text-align: left;font-weight: bold;">
                    ￥{{ total_money }}
                </el-col>
                <el-col :span="6">
                    <el-button round style="width: 100%" type="danger" size="small" @click="dinnerOrderDialogForm = true">去结算</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-dialog title="订餐信息" :visible.sync="dinnerOrderDialogForm" width="92%">
            <el-table 
                :data="DinnerOrderData" 
                size="small" 
                stripe
                border
                style="margin-bottom: 20px;"
                :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
                :row-style="{align: 'center'}"
                v-loading="table_loading">
                <el-table-column prop="FSORT" label="图片" min-width="50" align="center">
                </el-table-column>
                <el-table-column prop="FNAME" label="菜品名称" align="center">
                </el-table-column>
                <!-- <el-table-column prop="FPRICE" label="单价" min-width="40" align="center">
                </el-table-column> -->
                <el-table-column prop="FNUM" label="数量" min-width="40" align="center">
                </el-table-column>
                <el-table-column prop="DINNER_MONEY" label="金额" min-width="40" align="center">
                </el-table-column>
            </el-table>
            <el-form :model="dinnerOrderForm" :rules="rules" ref="dinnerOrderForm" label-width="86px" size="small">
                <el-form-item label="用餐时间" prop="dinner_date" style="font-weight: bold">
                    <el-date-picker
                        v-model="dinnerOrderForm.dinner_date"
                        style="width: 58%;"
                        size="small"
                        type="date"
                        align="center"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                    <el-time-select
                        size="small"
                        v-model="dinnerOrderForm.dinner_time"
                        :picker-options="{
                            start: '11:30',
                            step: '00:15',
                            end: '12:00'}"
                        style="width: 42%;"
                        placeholder="选择时间">
                    </el-time-select>
                </el-form-item>
                <el-form-item label="用餐人数" prop="dinner_people" style="font-weight: bold">
                    <el-input v-model="dinnerOrderForm.dinner_people" placeholder="请输入用餐人数" size="small"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="dinner_remark" style="font-weight: bold">
                    <el-input
                        size="small"
                        type="textarea"
                        :rows="2"
                        placeholder="请输入备注"
                        v-model="dinnerOrderForm.dinner_remark">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                合计 ￥{{ total_money }}
                <el-button size="medium" type="primary" :loading="isLoading" @click="SaveDinnerOrder">提交订单</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import conf from '../../../conf'
export default {
    data() {
        return {
            // dinnerTypes: [],
            // drawer: false,
            dinnerOrderDialogForm: false,
            DinnerOrderData: [],
            table_loading: false,
            isLoading: false,
            dinnerOrders: {},
            // dinner_order: {
            //     dinner_num: 0
            // },
            total_num: 0,
            total_money: 0,
            dinnerOrderForm: {
                dinner_time: '12:00',
                dinner_people: '',
                dinner_date:  moment().add('day',1).format('YYYY-MM-DD'),
                dinner_remark: ''
            },
            rules: {
                dinner_date: [
                    {required: true, message: '请选择用餐时间', trigger: 'change'}
                ],
                dinner_time: [
                    {required: true, message: '请选择用餐时段', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        numberChange(item) {
            console.log(item)
            this.total_num = 0
            this.total_money = 0
            this.DinnerOrderData = []
            this.dinnerOrders.dinnerOrderMenus.forEach(menu => {
                if(menu.FNUM > 0) {
                    menu.DINNER_MONEY = menu.FPRICE * menu.FNUM
                    this.DinnerOrderData.push(menu)
                    this.total_num += menu.FNUM
                    this.total_money += (menu.FPRICE * menu.FNUM)
                }
            })       
        },
        // handleClick(tab) {
        //     this.GetDinnerMenuByType(tab.name)
        // },
        // async GetDinnerMenuByType(ftype_no) {
        //     const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrderMenus/GetDinnerMenuByType', qs.stringify({
        //         ftype_no
        //     }))
        //     console.log(res)
        //     if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
        //         this.DinnerMenuData = res.data.ResponseData
        //         console.log('this.DinnerMenuData', this.DinnerMenuData)
        //     }else {
        //         this.$message({
        //             message: '获取数据失败：' + res.data.Message,
        //             center: true,
        //             type: 'error'
        //         })
        //     }
        // },
        // async GetDinnerType() {
        //     const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrderTypes/GetDinnerType')
        //     console.log(res)
        //     if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
        //         this.dinnerTypes = res.data.ResponseData 
        //     }else {
        //         this.$message({
        //             message: '获取数据失败：' + res.data.Message,
        //             center: true,
        //             type: 'error'
        //         })
        //     }
        // },
        async SaveDinnerOrder() {
            this.isLoading = true
            var dtos = []
            this.DinnerOrderData.forEach(item => {
                dtos.push({
                    PNL: this.pnl,
                    DINNER_ID: item.DB_ID,
                    DINNER_NUM: item.FNUM,
                    DINNER_TIME: this.dinnerOrderForm.dinner_time,
                    DINNER_DATE: this.dinnerOrderForm.dinner_date,
                    DINNER_PEOPLE: this.dinnerOrderForm.dinner_people,
                    DINNER_MONEY: item.FNUM * item.FPRICE,
                    DINNER_REMARK: this.dinnerOrderForm.dinner_remark,
                })
            })
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrders/SaveDinnerOrder', qs.stringify({
                dtos: dtos,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                console.log('提交成功:', res.data.ResponseData)
                this.$message({
                    message: '提交成功',
                    center: true,
                    type: 'success'
                })
                setTimeout(function(){
                    window.location.href = '/dinnerOrderList'
                },2000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '提交失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '提交失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
            this.dinnerOrderDialogForm = false
        },
        async GetDinnerOrderData() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrderMenus/GetDinnerOrderData')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.dinnerOrders = res.data.ResponseData 
                console.log('this.dinnerOrders', this.dinnerOrders)
                this.dinnerOrders.dinnerOrderMenus.forEach(item => {
                    if(item.FIMGPATH) {
                        item.FIMGPATH = conf.image_domain + item.FIMGPATH
                    }
                })   
            }else {
                this.$message({
                    message: '获取数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        // this.GetDinnerType()
        this.GetDinnerOrderData()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>