<template>
    <div>
        <el-image fit="fill" :src="require('@/assets/images/anniversary.jpeg')" lazy></el-image>
        <div class="ann">{{ gl }}</div>
        <!-- <wechat-share style="margin-top: 20px"></wechat-share> -->
    </div>
</template>

<script>
// import WechatShare from '../../../components/WechatShare.vue'
export default {
    // components: {
    //     WechatShare
    // },
    data() {
        return {
            gl: 0
        }
    },
    created() {
        this.gl = this.$route.params.gl
    }
}
</script>

<style scoped>
    .ann{
        position:absolute;
        left: 53%;
        /* top: 13.4%; */
        top: 15vh;
        z-index: 1;
        font-size: 24px;
        /* color: #F56C6C; */
        color: red;
        font-weight: bold;
    }
</style>