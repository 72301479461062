<template>
    <div>
        <el-table :data="simpleNoData" size="small" :show-header="false">
            <el-table-column prop="label" label="设备详情" width="80px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column>
            <el-table-column align="right" width="90px">
                <el-button 
                    size="small" 
                    type="primary" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI">扫码</el-button>
            </el-table-column>
        </el-table>
        <el-table :data="simpleData" size="small" :show-header="false">
            <el-table-column prop="label" width="80px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-table style="margin-top: 20px" :data="wg_data" size="mini" border v-if="wg_data.length > 0">
            <el-table-column prop="index" label="序号">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.code}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="qtyl" label="左脚" >
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #67C23A">{{scope.row.qtyl}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="qtyr" label="右脚" >
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #409EFF">{{scope.row.qtyr}}</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" >
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="danger"
                        @click="handleDelete(scope.$index, scope.row)">删</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="dialogTableVisible" size="mini" width="90%">
            <span slot="title" class="dialog-footer">
                <div style="font-weight: bold;text-align: left;font-size: 16px;">样品单号不一致</div>
                <div style="text-align: left;font-size: 14px;margin-top: 20px;color: #F56C6C;font-weight: bold">是否先提交 {{this.simpleNoData[0].value}} 的完工记录?</div>
            </span>
            <el-table :data="wg_data">
                <el-table-column property="code" label="序号"></el-table-column>
                <el-table-column property="qtyl" label="左脚"></el-table-column>
                <el-table-column property="qtyr" label="右脚"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogTableVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="submitForm('SCForm')">确定</el-button>
            </span>
        </el-dialog>
        <el-form :model="SCForm" :rules="rules" ref="SCForm" label-width="90px" size="mini" style="margin-top: 20px;" v-if="this.simpleNoData[0].value">
            <el-form-item label="完工类型" prop="type">
                <el-select v-model="SCForm.type" size="small" placeholder="请选择完工类型" align style="width: 100%;">
                    <el-option
                        v-for="item in types"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否合格" prop="ifHg">
                <el-switch
                    style="text-align: left"
                    v-model="SCForm.ifHg"
                    active-color="#ff4949"
                    inactive-color="#13ce66"
                    active-value="true"
                    active-text="不合格"
                    inactive-text="合格"
                    inactive-value="false">
                </el-switch> 
            </el-form-item>
            <el-form-item label="预计完工">
                <el-date-picker
                    v-model="SCForm.yjwcdate"
                    size="small"
                    type="date"
                    style="width: 100%"
                    placeholder="请选择预计完工日期">
                </el-date-picker>
            </el-form-item >
            <el-row style="margin-top: 20px;" v-if="this.simpleNoData[0].value">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        size="medium"
                        :loading="isLoading"
                        :disabled="wg_data.length <= 0"
                        @click="submitForm('SCForm')">提交完工</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-collapse v-model="activeNames" style="margin-top: 30px;">
            <el-collapse-item name="1">
                <template slot="title">
                    <div style="font-weight: bold">上传记录</div>
                </template>
                <el-table :data="simplesData" stripe size="small" style="width: 100%">
                    <el-table-column prop="iodate" label="完工时间" width="85px" align="left">
                        <template slot-scope="scope">
                            <div style="font-weight: bold;color: #909399">{{scope.row.iodate}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="typeName" label="类型" width="60px" align="left">
                    </el-table-column>
                    <el-table-column label="双数 / 左脚数 / 右脚数" align="right">
                        <template slot-scope="scope">
                            <div style="font-size: 13px;font-weight: bold;">
                                <span style="color: #E6A23C">{{scope.row.qty}}</span>
                                <span style="color: #409EFF">{{scope.row.qtyl}}</span>
                                <span style="color: #67C23A">{{scope.row.qtyr}}</span> 
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../../conf.js'
import qs from 'qs'
import wx from 'weixin-js-sdk'
import moment from 'moment'
export default {
    data() {
        return {
            pnl: '',
            SCForm: {
                ifHg: true,
                type: 6,
                qty: 0.0,
                qtyl: 0,
                qtyr: 0,
                iqty: 0.0,
                iqtyl: 0,
                iqtyr: 0,
                sqty: 0.0,
                yjwcdate: ''
            },
            simpleNoData: [{
                label: '样品单号',
                value: ''
            }],
            simpleData: [],
            simplesData:[{
                label: '完工类型',
                value: ''
            }],
            wg_data: [],
            types: [],
            isLoading: false,
            activeNames: ['0'],
            disabled: true,
            rules: {
                type: [
                    {required: true, message: '请选择完工类型', trigger: 'change'}
                ],
                ifHg: [
                    {required: true, message: '请选择是否合格', trigger: 'change'}
                ]
            },
            dialogTableVisible: false,
            DB_ID: '',
            // index: 0 // dev
        }
    },
    methods: {
        qtyComputed() {
            this.SCForm.qtyl = this.SCForm.qty
            this.SCForm.qtyr = this.SCForm.qty
            this.ValidQty()
        },
        qtylrComputed() {
            this.SCForm.qty = (this.SCForm.qtyl + this.SCForm.qtyr) / 2
            this.ValidQty()
        },
        ValidQty() {
            console.log('this.simpleData', this.simpleData)
            let iqtyl = this.SCForm.iqtyl + this.SCForm.qtyl
            let iqtyr = this.SCForm.iqtyr + this.SCForm.qtyr
            this.simpleData[3].value = this.SCForm.iqty + this.SCForm.qty + '（左：' + iqtyl + '，右：' + iqtyr + '）'
        },
        GetJSAPI() {
            // 测试数据
            // 单号;序号;左;右
            // 'HDS202110000580-001;1;0'
            // console.log('this.index',  this.index)
            // if(this.index === 0 ) {
            //     let result = 'HDS202110000075-001;1;0' // dev
            //     this.GetCodeData(result) // dev
            //     this.index ++ // dev
            // } else if(this.index === 1){
            //     let result = 'HDS202110000075-002;0;1' // dev
            //     this.GetCodeData(result) // dev
            //     this.index ++ // dev
            // }else {
            //     let result = 'HDS202110000075-002;0;1' // dev
            //     this.GetCodeData(result) // dev
            // }

            // let result = 'HDS202212000406' // dev
            // this.GetCodeData(result) // dev
            
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
                    this.GetCodeData(result)
                }
            })
        },
        async GetCodeData(result) {
            let sample_no = result.split('-')[0]
            let qrcode_data = result.split('-')[1].split(';')
            // console.log('sample_no', sample_no)
            // console.log('this.qrcode_data', qrcode_data)
            if(!this.simpleNoData[0].value) {
                this.simpleNoData[0].value = sample_no
                // console.log('this.simpleNoData[0].value', this.simpleNoData[0].value)
                await this.GetSampleData(this.simpleNoData[0].value)  
                this.GetSamplesData(this.simpleNoData[0].value) 
            } 
            // else if(this.simpleNoData[0].value !== sample_no){
            //     // 提示样品单号不同，是否先提交当前样品
            //     this.dialogTableVisible = true
            // }

            this.SetWGData(sample_no, qrcode_data)
        },
        SetWGData(sample_no, qrcode_data) {
            // console.log('qrcode_data', qrcode_data)

            // let res = this.wg_data.findIndex(item => {
            //     return item.no === 
            // })

            if(this.wg_data.findIndex((item) => {
                return item.no !== sample_no
            }) > -1) {
                // 提示样品单号不同，是否先提交当前样品
                this.dialogTableVisible = true
                return
            }

            if(this.wg_data.findIndex((item) => {
                // console.log('item.code', item.code)
                // console.log('qrcode_data[0]', qrcode_data[0])
                return item.code == qrcode_data[0]
            }) > -1) {
                this.$message({
                    message: sample_no + '-' + qrcode_data[0] + ' 已扫描！',
                    center: true,
                    type: 'success',
                    duration: 800
                })
                // console.log('来了老弟')
            }else {
                this.wg_data.push({
                    no: sample_no,
                    code: qrcode_data[0],
                    qtyl: qrcode_data[1],
                    qtyr: qrcode_data[2],
                    qty: (parseInt(qrcode_data[1]) + parseInt(qrcode_data[2])) / 2
                })
            }

            // if(this.wg_data.findIndex((item, index) => {
            //     console.log('index', index)
            //     console.log('item', item)
            //     if(item.code === qrcode_data[0] && sample_no === item.no) {
            //         this.$message({
            //             message: this.simpleNoData[0].value + '-' + item.code + ' 已扫描！',
            //             center: true,
            //             type: 'success'
            //         })
            //         return true
            //     }
            // })) {
            //     this.wg_data.push({
            //         no: sample_no,
            //         code: qrcode_data[0],
            //         qtyl: qrcode_data[1],
            //         qtyr: qrcode_data[2],
            //         qty: (parseInt(qrcode_data[1]) + parseInt(qrcode_data[2])) / 2
            //     })
            // }

            // if(this.wg_data.length > 0) {
            //     this.wg_data.findIndex((item, index) => {
            //         console.log('index', index)
            //         // console.log('item', item)
            //         if(item.code !== qrcode_data[1]) {
            //             this.wg_data.push({
            //                 code: qrcode_data[1],
            //                 qtyl: qrcode_data[2],
            //                 qtyr: qrcode_data[3],
            //             })
            //         }
            //     })
            // }else {
            //     this.wg_data.push({
            //         code: qrcode_data[1],
            //         qtyl: qrcode_data[2],
            //         qtyr: qrcode_data[3],
            //     })
            // }

            this.WGQtyComputed()
        },
        WGQtyComputed() {
            let qtyl = 0
            let qtyr = 0
            let qty = 0
            this.wg_data.forEach(item => {
                qtyl += parseInt(item.qtyl)
                qtyr += parseInt(item.qtyr)
                qty += (parseInt(item.qtyl) + parseInt(item.qtyr)) / 2
            })

            this.SCForm.qtyl = qtyl
            this.SCForm.qtyr = qtyr
            this.SCForm.qty = qty

            // this.SCForm.iqty += (this.SCForm.qtyl + this.SCForm.qtyr) / 2
            // this.SCForm.iqtyl += this.SCForm.qtyl
            // this.SCForm.iqtyr += this.SCForm.qtyr
            this.qtylrComputed()
        },
        handleDelete(index, row) {
            console.log('index', index)
            console.log('row', row)
            this.wg_data.splice(index, 1)
            this.WGQtyComputed()
        },
        async GetSampleData(yvou_no) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SampleCompletion/SampleCompletion', qs.stringify({
                yvou_no: yvou_no
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.simpleData = []
                res.data.ResponseData.map(item => {
                    this.simpleData.push({
                        label: '样品款式',
                        value: item.sstyle
                    })
                    this.simpleData.push({
                        label: '样品类型',
                        value: item.stype
                    })
                    this.simpleData.push({
                        label: '样品品牌',
                        value: item.scus_nm
                    })
                    this.simpleData.push({
                        label: '完工双数',
                        value: item.iqty + '（左：' + item.iqtyl + '，右：' + item.iqtyr + '）'
                    })

                    this.SCForm.iqty = item.iqty // 完工双数
                    this.SCForm.iqtyl = item.iqtyl // 完工左
                    this.SCForm.iqtyr = item.iqtyr // 完工右
                    this.SCForm.sqty = item.sqty // 下单双数
                    this.DB_ID = item.DB_ID
                    // console.log('this.simpleData', this.simpleData)
                })
            }
        },
        async GetSamplesData(yvou_no) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SampleCompletion/SampleCompletionRecord', qs.stringify({
                yvou_no
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.simplesData = []
                res.data.ResponseData.map(item => {
                    this.simplesData.push({
                        iodate: moment(item.iodate).format('YYYY-MM-DD'),
                        typeName: item.typeName,
                        // qty: item.qty + '（' + item.qtyl + ' / ' + item.qtyr + '）',
                        qty: item.qty + ' (双)，',
                        qtyl: '左：' + (!item.qtyl ? 0 : item.qtyl) + '，',
                        qtyr: '右：' + (!item.qtyr ? 0 : item.qtyr) 
                    })
                })
            }
        },
        async GetSampleType() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SampleCompletion/SampleType')
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.types = []
                res.data.ResponseData.map(item => {
                    this.types.push({
                        label: item.typeName,
                        value: item.typeIndex
                    })
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK'){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CustomValidate()){
                    console.log(this.SCForm)
                    this.SaveSample()
                }else{
                    console.log(valid.message)
                    return false
                }
                this.dialogTableVisible = false
            })
        },
        async SaveSample() {
            // console.log('this.SCForm.iqty + this.SCForm.qty', this.SCForm.iqty + this.SCForm.qty)
            // console.log('this.SCForm.iqty', this.SCForm.iqty)
            const SamplesDTO = {
                YVOU_NO: this.simpleNoData[0].value, // 单号
                DB_ID: this.DB_ID,
                // IQTY: this.SCForm.iqty + this.SCForm.qty, // 完工双数
                // IQTYL: this.SCForm.iqtyl + this.SCForm.qtyl, // 完工左
                // IQTYR: this.SCForm.iqtyr + this.SCForm.qtyr, // 完工右
                YJWCDATE: moment(this.yjwcdate).format('YYYY-MM-DD')
            }
            let SampleioList = []
            let SampleqrcodeList = []
            this.wg_data.map(item => {
                const AleKfSampleioDTO = {
                    IFHG: this.SCForm.ifHg ? 'Y' : 'N',
                    TYPEINDEX: this.SCForm.type,
                    YVOU_NO: this.simpleNoData[0].value
                }
                const AleKfSampleqrcodeDTO = {
                    YVOU_NO: this.simpleNoData[0].value,
                    QRCODE: this.simpleNoData[0].value + '-' + item.code,
                    QTYL: item.qtyl,
                    QTYR: item.qtyr,
                }
                SampleioList.push(
                    AleKfSampleioDTO
                )
                SampleqrcodeList.push(
                    AleKfSampleqrcodeDTO
                )
            })
            // console.log({
            //     use_pnl: this.pnl,
            //     SamplesDTO,
            //     SampleioList,
            //     SampleqrcodeList     
            // })
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SampleCompletion/SaveWGJL', qs.stringify({
                use_pnl: this.pnl,
                SamplesDTO,
                SampleioList,
                SampleqrcodeList     
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: '单号：' + this.simpleNoData[0].value + '保存成功！',
                    center: true,
                    type: 'success'
                })
                this.GetSamplesData(this.simpleNoData[0].value)
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        CustomValidate() {
            if(!this.SCForm.yjwcdate) {
                if(this.SCForm.sqty > this.SCForm.iqty + this.SCForm.qty) {
                    this.$message({
                        message: '完工双数小于下单双数，请选择预计完工日期',
                        center: true,
                        type: 'warning'
                    })
                    return false
                }
            }
            // console.log('this.SCForm.iqty', this.SCForm.iqty)
            // console.log('this.SCForm.qty', this.SCForm.qty)
            // console.log('this.SCForm.sqty', this.SCForm.sqty)
            if(this.SCForm.sqty < this.SCForm.iqty + this.SCForm.qty) {
                 this.$message({
                    message: '完工双数大于下单双数，请修改完工数据',
                    center: true,
                    type: 'error'
                })
                return false
            }
            return true
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        this.GetSignature() // prod
        this.GetSampleType()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>
