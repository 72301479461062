<template>
    <div>
        <div v-if="footer_menu.page1 && on_job">
            <!-- <div style="font-size: small;font-weight: bold;text-align: left;margin-bottom: 10px;">你好，Small</div> -->
            <!-- <el-carousel :interval="5000" arrow="always" height="190px">
                <el-carousel-item v-for="item in navs" :key="item.id">
                    <el-image fit="cover" :src=item.src alt=""></el-image>
                </el-carousel-item>
            </el-carousel> -->
            <el-image class="home-bar" fit="fit" :src="require('@/assets/images/hq2.png')" alt=""></el-image>
            
            <el-carousel height="40px" direction="vertical" style="margin: 195px 0px 0px 0px">
                <el-carousel-item v-for="item in ekp_notices" :key="item.title">
                    <el-alert class="carousel-alert" :closable="false">
                        <template slot='title'>
                            <span style="margin-right: 10px;"> 
                                <el-tag type="danger" size="mini" effect="dark">[公告]</el-tag></span>
                            <span>
                                <el-link @click="toEKP(item.url)" :underline="false">
                                    {{ item.title }}
                                </el-link>
                            </span>
                        </template>
                    </el-alert>
                </el-carousel-item>
            </el-carousel>

            <el-card class="box-card">
                <h4>常用应用</h4>
                <el-row :gutter="30">
                    <el-col :span="6" v-for="common in common_apps" :key="common.label">
                        <router-link :to="common.link_url">
                            <div>
                            <el-image class="app-icon" fit="cover" :src="common.icon_url"></el-image>
                            </div>
                        <span class="app-span">{{ common.label }}</span>
                        </router-link> 
                    </el-col>
                </el-row>
            </el-card>
        </div>
        <div v-if="footer_menu.page2 && on_job" style="margin:-20px 0px 70px 0px;">
            <el-card class="box-card" v-for="app in apps" :key="app.type_title">
                <h4>{{ app.type_title }}</h4>
                <el-row :gutter="30">
                    <el-col :span="6" v-for="item in app.type_app" :key="item.label">
                        <router-link :to="item.link_url">
                            <div>
                            <el-image class="app-icon" fit="cover" :src="item.icon_url"></el-image>
                            </div>
                        <span class="app-span">{{ item.label }}</span>
                        </router-link> 
                    </el-col>
                </el-row>
            </el-card>
        </div>
        <div v-if="footer_menu.page3 && on_job">
            <el-card class="mylist-bar">
                <el-row>
                    <el-col :span="20" style="text-align: left;">
                        <div>
                            <span style="font-size: 18px;font-weight: bold;"> {{pname}} </span> 
                            <span>
                                <el-image fit="contain" :src="require('@/assets/icon/iconPark20/vip_vip-one.svg')" alt=""></el-image>
                            </span>
                            <span style="font-size: 11px;color:#E6A23C;">{{work_age}}</span> 
                            <!-- <span style="font-size: 11px;">_{{ pnl }}</span>  -->
                        </div>
                        <div style="padding-top: 5px;">
                            <span style="font-size: 13px;">{{sec_nm}}-{{gw_sn}}</span> 
                        </div>
                    </el-col>
                    <el-col :span="4" style="text-align: right;">
                        <div>
                            <el-avatar style="border-radius: 8px;" :size="50" :src="squareUrl"></el-avatar>
                        </div>
                    </el-col>
                </el-row>
            </el-card>

            <div style="margin-top: 85px">
                <el-card v-for="item in mylist_menus" :key="item.id" style="margin: 15px 0px;padding: 4px 0px;border-radius: 8px">
                    <router-link :to="item.link_url">
                    <el-row>
                        <el-col :span="2" style="text-align: left;">
                            <el-image style="width: 20px;height: 20px;" fit="cover" :src="item.icon_url"></el-image>
                        </el-col>
                        <el-col :span="20" style="text-align: left;padding-left: 10px;font-size: 15px">
                            <div>{{ item.label }}</div>
                        </el-col>
                        <el-col :span="2" style="text-align: right;">
                            <i class="el-icon-arrow-right"></i>
                        </el-col>
                    </el-row>
                </router-link>
                </el-card>
            </div>

            <el-alert type="info" :closable="false" center>
				<template slot='title'>
					<el-link @click="toICP" class="icp" :underline="false">
						闽ICP备18021135号
					</el-link>
				</template>
			</el-alert>    
        </div>
        <el-card class="footer">
            <el-row type="flex" class="row-bg" justify="space-between">
                <el-col :span="6">
                    <div style="margin-bottom: -3px;" @click="select_menu(1)">
                        <el-image fit="cover" :src="footer_menu.image_src1" alt=""></el-image>
                    </div>
                    <div :style="footer_menu.style1" @click="select_menu(1)">首页</div>
                </el-col>
                <el-col :span="6">
                    <div style="margin-bottom: -3px;" @click="select_menu(2)">
                        <el-image fit="cover" :src="footer_menu.image_src2" alt=""></el-image>
                    </div>
                    <div :style="footer_menu.style2" @click="select_menu(2)">应用</div>
                </el-col>
                <el-col :span="6">
                    <div style="margin-bottom: -3px;" @click="select_menu(3)">
                        <el-image fit="cover" :src="footer_menu.image_src3" alt=""></el-image>
                    </div>
                    <div :style="footer_menu.style3" @click="select_menu(3)">我的</div>
                </el-col>
            </el-row>
         
        </el-card>
        <el-result icon="error" title="您访问的资源不存在" subTitle="请点击关闭按钮，退出该页面；如果是返厂员工，请点击注册按钮，重新注册" v-if="!on_job">
            <template slot="extra">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-button type="primary" style="width: 100%" size="medium" @click="PageClose">关闭</el-button>
                    </el-col>
                </el-row>
            </template>
        </el-result>
    </div>
</template>

<script>
import conf from '../conf'
import qs from 'qs'
import axios from 'axios'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            on_job: true,
            pnl: '',
            pname: '',
            squareUrl: '',
            nickname: '',
            sec_nm: '',
            gw_sn: '',
            zw_nm: '',
            work_age: 0,
            footer_menu: {
                page1: true,
                image_src1: require('@/assets/icon/iconPark24/首页_home.svg'),
                style1: {
                    color: '#409EFF',
                },
                page2: false,
                image_src2: require('@/assets/icon/iconPark24/606266/全部_all-application.svg'),
                style2: {
                    color: '#606266',
                },
                page3: false,
                image_src3: require('@/assets/icon/iconPark24/606266/用户_use.svg'),
                style3: {
                    color: '#606266',
                }
            },
            // navs: [{
            //     id: 1,
            //     src: require('@/assets/images/v2_sd5kwd.png')
            // },{
            //     id: 2,
            //     src: require('@/assets/images/v2_sd5kwd.png')
            // },{
            //     id: 3,
            //     src: require('@/assets/images/v2_sd5kwd.png')
            // }],
            ekp_notices: [{
                title: '本周末职能部门上班安排（4月6日）',
                name: '张思婷',
                date: '04-03',
                url: 'http://ekp.qzhqxf.com:9611/sys/news/sys_news_main/sysNewsMain.do?method=view&fdId=18ea2b5f25239c9835310ec4fa6a953e',
            },{
                title: '工艺车间组织结构图更新公告',
                name: '张立萍',
                date: '04-02',
                url: 'http://ekp.qzhqxf.com:9611/sys/news/sys_news_main/sysNewsMain.do?method=view&fdId=18e9dac29b16114477029544b8cbdb9e',
            },{
                title: '截止2024年4月可享受年休假人员名单公告',
                name: '张思婷',
                date: '04-03',
                url: 'http://ekp.qzhqxf.com:9611/sys/news/sys_news_main/sysNewsMain.do?method=view&fdId=18e9d800e87858de0e7847a46b5ab64f',
            }],
            common_apps: [{
                link_url: '/weekMenuImage',
                icon_url: require('@/assets/icon/iconPark48/煮饭_cook.svg'),
                label: '餐厅菜谱'
            },{
                link_url: '/workOrderList',
                icon_url: require('@/assets/icon/iconPark48/工具_tool.svg'),
                label: '工务维修'
            },{
                link_url: '/ehsCheckList',
                icon_url: require('@/assets/menu/EHS.png'),
                label: 'EHS'
            },{
                link_url: '/maintain',
                icon_url: require('@/assets/icon/iconPark48/清除_clear.svg'),
                label: '设备保养'
            },{
                link_url: '/inputOutput6',
                icon_url: require('@/assets/icon/iconPark48/双向数据互传_transfer-data.svg'),
                label: '投入产出'
            },{
                link_url: '/workInProcess',
                icon_url: require('@/assets/icon/iconPark48/圆环_ring.svg'),
                label: '在制明细'
            },{
                link_url: '/checkList',
                icon_url: require('@/assets/icon/iconPark48/检查列表_checklist.svg'),
                label: '生产巡检'
            },{
                link_url: '/secYcRecord',
                icon_url: require('@/assets/icon/iconPark48/流水线_assembly-line.svg'),
                label: '线别异常'
            },{
                link_url: '/repairOrderList',
                icon_url: require('@/assets/icon/iconPark48/设备_devices.svg'),
                label: '设备报修'
            },{
                link_url: '/questionList',
                icon_url: require('@/assets/icon/iconPark48/帮助中心_helpcenter.svg'),
                label: '我要提问'
            },{
                link_url: '/sample',
                icon_url: require('@/assets/icon/iconPark48/检查列表_checklist.svg'),
                label: '样鞋借用'
            },{
                link_url: '/remoteClock',
                icon_url: require('@/assets/icon/iconPark48/指纹_fingerprint.svg'),
                label: '远程打卡'
            }],
            apps: [{
                type_title: '人力行政',
                type_app: [{
                    link_url: '/proposal',
                    icon_url: require('@/assets/icon/iconPark48/线上办公室_online-meeting.svg'),
                    label: '持续改善'
                },{
                    link_url: '/questionList',
                    icon_url: require('@/assets/icon/iconPark48/帮助中心_helpcenter.svg'),
                    label: '我要提问'
                },{
                    link_url: '/sportsclock',
                    icon_url: require('@/assets/icon/iconPark48/运动_sport.svg'),
                    label: '运动打卡'
                },{
                    link_url: '/blackList',
                    icon_url: require('@/assets/icon/iconPark48/详情列表_view-list.svg'),
                    label: '黑名单'
                },{
                    link_url: '/workOrderList',
                    icon_url: require('@/assets/icon/iconPark48/工具_tool.svg'),
                    label: '工务维修'
                },{
                    link_url: '/weekMenuImage',
                    icon_url: require('@/assets/icon/iconPark48/煮饭_cook.svg'),
                    label: '餐厅菜谱'
                },{
                    link_url: '/ehsCheckList',
                    icon_url: require('@/assets/menu/EHS.png'),
                    label: 'EHS'
                },{
                    link_url: '/roomCheckList',
                    icon_url: require('@/assets/icon/iconPark48/单人床_single-bed.svg'),
                    label: '宿舍7S'
                },{
                    link_url: '/remoteClock',
                    icon_url: require('@/assets/icon/iconPark48/指纹_fingerprint.svg'),
                    label: '远程打卡'
                }]
            },{
                type_title: '制造MES',
                type_app: [{
                    link_url: '/inputOutput6',
                    icon_url: require('@/assets/icon/iconPark48/双向数据互传_transfer-data.svg'),
                    label: '投入产出'
                },{
                    link_url: '/workInProcess',
                    icon_url: require('@/assets/icon/iconPark48/圆环_ring.svg'),
                    label: '在制明细'
                },{
                    link_url: '/preDispatch2',
                    icon_url: require('@/assets/icon/iconPark48/内部传输_internal-transmission.svg'),
                    label: '部件接收'
                },{
                    link_url: '/equipment',
                    icon_url: require('@/assets/icon/iconPark48/显示器_monitor-one.svg'),
                    label: '电脑车'
                },{
                    link_url: '/productInventory2',
                    icon_url: require('@/assets/icon/iconPark48/广告产品_ad-product.svg'),
                    label: '成品库存'
                },{
                    link_url: '/checkList',
                    icon_url: require('@/assets/icon/iconPark48/检查列表_checklist.svg'),
                    label: '生产巡检'
                },{
                    link_url: '/workInProcess',
                    icon_url: require('@/assets/icon/iconPark48/成功文件1_file-success-one.svg'),
                    label: '首件确认'
                },{
                    link_url: '/inputOutput4',
                    icon_url: require('@/assets/icon/iconPark48/流水线_assembly-line.svg'),
                    label: '线别预警'
                },{
                    link_url: '/secYcRecord',
                    icon_url: require('@/assets/icon/iconPark48/圆环_ring.svg'),
                    label: '线别异常'
                }]
            },{
                type_title: '件资提报',
                type_app: [{
                    link_url: '/pieceCapital',
                    icon_url: require('@/assets/icon/iconPark48/消费_consume.svg'),
                    label: '员工件资'
                },{
                    link_url: '/pieceCapitalStatus2',
                    icon_url: require('@/assets/icon/iconPark48/货币流通_currency.svg'),
                    label: '件资状况'
                },{
                    link_url: '/pieceCapitalLeave',
                    icon_url: require('@/assets/icon/iconPark48/支出_expenses-one.svg'),
                    label: '离职件资'
                },{
                    link_url: '/partProcess',
                    icon_url: require('@/assets/icon/iconPark48/机器人2_robot-two.svg'),
                    label: '工艺车间'
                },{
                    link_url: '/surfaceProcess',
                    icon_url: require('@/assets/icon/iconPark48/纹理_texture.svg'),
                    label: '帮面件资'
                },{
                    link_url: '/formingProcess',
                    icon_url: require('@/assets/icon/iconPark48/平头节点_node-square.svg'),
                    label: '成型件资'
                },{
                    link_url: '/blankingProcess',
                    icon_url: require('@/assets/icon/iconPark48/裁切_cutting.svg'),
                    label: '冲裁件资'
                },{
                    link_url: '/shoelacesProcess',
                    icon_url: require('@/assets/icon/iconPark48/支出_expenses-one.svg'),
                    label: '鞋带组'
                },{
                    link_url: '/packagingProcess',
                    icon_url: require('@/assets/icon/iconPark48/支出_expenses-one.svg'),
                    label: '大包装'
                },{
                    link_url: '/surfaceProcessAudit',
                    icon_url: require('@/assets/icon/iconPark48/审核_audit.svg'),
                    label: '帮面审核'
                },{
                    link_url: '/formingProcessAudit',
                    icon_url: require('@/assets/icon/iconPark48/审核_audit.svg'),
                    label: '成型审核'
                },{
                    link_url: '/ShoelacesProcessAudit',
                    icon_url: require('@/assets/icon/iconPark48/支出_expenses-one.svg'),
                    label: '鞋带组审核'
                },{
                    link_url: '/packagingProcessAudit',
                    icon_url: require('@/assets/icon/iconPark48/支出_expenses-one.svg'),
                    label: '大包装审核'
                }]
            },{
                type_title: '开发样品',
                type_app: [{
                    link_url: '/sample',
                    icon_url: require('@/assets/icon/iconPark48/清除_clear.svg'),
                    label: '样鞋借用'
                },{
                    link_url: '/sampleManage',
                    icon_url: require('@/assets/icon/iconPark48/设备_devices.svg'),
                    label: '样鞋管理'
                },{
                    link_url: '/sampleCompletion2',
                    icon_url: require('@/assets/icon/iconPark48/设备_devices.svg'),
                    label: '样品完工'
                }]
            },{
                type_title: '固定资产',
                type_app: [{
                    link_url: '/maintain',
                    icon_url: require('@/assets/icon/iconPark48/清除_clear.svg'),
                    label: '设备保养'
                },{
                    link_url: '/repairOrderList',
                    icon_url: require('@/assets/icon/iconPark48/设备_devices.svg'),
                    label: '设备报修'
                }]
            }],
            mylist_menus: [{
                link_url: '/attendance',
                icon_url: require('@/assets/icon/iconPark24/计划_plan.svg'),
                label: '考勤',
            },{
                link_url: '/salary',
                icon_url: require('@/assets/icon/iconPark24/钞票_paper-money.svg'),
                label: '薪资',
            },{
                link_url: '/reward',
                icon_url: require('@/assets/icon/iconPark24/奖杯_trophy.svg'),
                label: '奖惩',
            },{
                link_url: '/guarantee',
                icon_url: require('@/assets/icon/iconPark24/保护_protect.svg'),
                label: '保障',
            },{
                link_url: '/waterElectricity',
                icon_url: require('@/assets/icon/iconPark24/水电费_water-rate-two.svg'),
                label: '水电',
            }]
        }
    },
    methods: {
        select_menu(id) {
            this.$route.meta.title = '寰球移动办公'
            window.document.title = '寰球移动办公'
            this.footer_menu.page1 = false
            this.footer_menu.image_src1 = require('@/assets/icon/iconPark24/606266/首页_home.svg')
            this.footer_menu.style1 = {
                color: '#606266',
            }
            this.footer_menu.page2 = false
            this.footer_menu.image_src2 = require('@/assets/icon/iconPark24/606266/全部_all-application.svg')
            this.footer_menu.style2 = {
                color: '#606266',
            }
            this.footer_menu.page3 = false
            this.footer_menu.image_src3 = require('@/assets/icon/iconPark24/606266/用户_use.svg')
            this.footer_menu.style3 = {
                color: '#606266',
            }

            if(id === 1) {
                this.$route.meta.title = '寰球移动办公'
                window.document.title = '寰球移动办公'
                this.footer_menu.page1 = true
                this.footer_menu.image_src1 = require('@/assets/icon/iconPark24/首页_home.svg')
                this.footer_menu.style1 = {
                    color: '#409EFF',
                }
            }else if(id === 2) {
                this.$route.meta.title = '应用中心'
                window.document.title = '应用中心'
                this.footer_menu.page2 = true
                this.footer_menu.image_src2 = require('@/assets/icon/iconPark24/全部_all-application.svg')
                this.footer_menu.style2 = {
                    color: '#409EFF',
                }
            }else if(id === 3) {
                this.$route.meta.title = '个人中心'
                window.document.title = '个人中心'
                this.footer_menu.page3 = true
                this.footer_menu.image_src3 = require('@/assets/icon/iconPark24/用户_user.svg')
                this.footer_menu.style3 = {
                    color: '#409EFF',
                }
            }
        },
        async getOpenid(code) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/AccessToken', qs.stringify({
                code
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                localStorage.setItem('openid', res.data.ResponseData.OPENID)
                localStorage.setItem('access_token', res.data.ResponseData.ACCESS_TOKEN)
                window.location.replace(conf.server_url3)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async getAccessCode() {
            const state = ''
            const scope = 'snsapi_userinfo'
            const redirectUrl = encodeURIComponent(conf.server_url3)
            const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${conf.weixinInfo.appid}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
            window.location.replace(url)
        },
        async getUserInfo(openid) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/UserInfo', qs.stringify({
                access_token: localStorage.getItem('access_token'),
                openid
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.squareUrl = res.data.ResponseData.HEADIMGURL
                this.nickname = res.data.ResponseData.NICKNAME
                localStorage.setItem('nickname', this.nickname)
                localStorage.setItem('headimgurl', this.squareUrl)
                this.CheckFirstLogin(openid)
            }else {
            // }else if(res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                localStorage.setItem('openid', '')
                this.getAccessCode()
            }
        },
        async CheckFirstLogin(openid) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxUsers/CheckOpenid', qs.stringify({
                openid
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                window.location.href = '/register'
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 0) {
                // 用openid取员工信息
                this.pnl = res.data.ResponseData[0].PNL
                this.pname = res.data.ResponseData[0].PNAME
                this.gw_sn = res.data.ResponseData[0].GW_SN
                this.sec_nm = res.data.ResponseData[0].SECNM
                this.work_age = res.data.ResponseData[0].WORK_AGE
                this.zw_nm = res.data.ResponseData[0].ZW_NM

                localStorage.setItem('PNL', this.pnl)
                localStorage.setItem('PNAME', this.pname)
                localStorage.setItem('PHONE', res.data.ResponseData[0].PHONE)
                localStorage.setItem('SEC_NO', res.data.ResponseData[0].SECNO)
                localStorage.setItem('SEC_NM', this.sec_nm)

                this.on_job = !res.data.ResponseData[0].DDATE ? true : false
                if(this.pnl === '1653' || this.pnl === '7735') { // 屏蔽
                    this.on_job = false
                }
            }
        },
        toICP() {
            window.location.href = 'https://beian.miit.gov.cn/#/Integrated/index'
        },
        PageClose() {
            wx.closeWindow()
        },
    },
    created() {
        /** dev */
        if(!this.$route.query.openid){
            // 非跳转
            const openid = localStorage.getItem('openid') 
            if(!openid) {
                if(this.$route.query.code) {
                    this.getOpenid(this.$route.query.code)
                }else {
                    this.getAccessCode()
                }
            }else{
                this.getUserInfo(openid)
            }
        }else{ // 跳转 传 openid
            localStorage.setItem('openid', this.$route.query.openid)
            this.getUserInfo(this.$route.query.openid)
        }
        /** dev */
    }
}
</script>

<style scoped>
    a:link { 
        text-decoration: none;
        color: #606266;
    }
    a:visited {
        text-decoration: none;
        color: #606266;
    }
    a:active { 
        text-decoration: blink;
        color: #606266;
    }
    .app-icon {
        width: 30px;
        height: 30px;
        margin-bottom: 4px;
        margin-top: 20px;
    }
    .app-span {
        font-size: 12px; 
        /* font-weight: 550; */
    }
    .box-card {
        padding: 0px 0px 20px 0px;
        margin-bottom: 5px;
        border-radius: 8px;
        
    }
    .box-card h4 {
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 0px 10px 20px;
        margin: -20px -20px 10px -20px;
        background-color: rgb(217, 236, 255);
    }
    .footer {
        position: fixed; /* 使用fixed定位 */
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding: 0px 0px 15px 0px;
        font-size: 11px;
    }
    .span {
        font-size: 11px; 
        font-weight: 550;
    }
    /** home */
    .home-bar {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 200px;
    }
    .carousel-alert {
        background-color: rgb(225, 243, 216);
        padding: 10px 0px;
        border-radius: 8px;
    }
    /** mylist */
    .mylist-bar {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        /* background-color: #B3D8FF; */
        background-color: rgb(160, 207, 255);
        margin: 0;
        padding: 8px 0px;
    }
    .box-card2 {
        margin: 0px;
        /* padding: 5px 0px 5px 0px; */
        /* background-color: #C6E2FF; */
        background-color: white;
        border-radius: 8px;
    }
</style>