<template>
    <div>
        <!-- <el-row :gutter="15">
            <el-col :span="12">
                <router-link to="/dinnerAdminType">
                    <el-button plain size="medium" style="width: 100%" type="primary">管理菜品类别</el-button>
                </router-link>
            </el-col>
            <el-col :span="12">
                <router-link to="/dinnerAdminMenu">
                    <el-button plain size="medium" style="width: 100%" type="primary">管理菜品明细</el-button>
                </router-link>
            </el-col>
        </el-row> -->
        <div style="margin-bottom: 30px;">
            <el-tabs v-model="activeName">
                <el-tab-pane label="每日订餐明细" name="first">
                    <span slot="label"><i class="el-icon-date"></i> 每日订餐明细</span>
                    <el-date-picker
                        v-model="dinner_date1"
                        @change="DateChange1"
                        style="width: 100%;margin-bottom: 15px;"
                        size="small"
                        type="date"
                        align="center"
                        value-format="yyyy-MM-dd"
                        placeholder="选择用餐日期">
                    </el-date-picker> 
                    <el-table 
                        :data="dinnerOrderData1" 
                        size="mini" 
                        max-height="580px"
                        border
                        :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, height: '32px'}" 
                        :row-style="{align: 'center'}"
                        :cell-style="() => { return 'padding: 0px;margin: 0px;height: 30px;font-size: 11px'}"
                        v-loading="table_loading1">
                        <!-- <el-table-column label="序" type="index" min-width="30px" align="center">
                        </el-table-column> -->
                        <el-table-column prop="PNAME" label="订餐人" min-width="40px" align="center">
                        </el-table-column>
                        <el-table-column prop="DINNER_NM" label="菜名" align="center">
                        </el-table-column>
                        <el-table-column prop="DINNER_TIME" label="时段" min-width="40px" align="center">
                        </el-table-column>
                        <el-table-column prop="DINNER_PEOPLE" label="人数" min-width="32px" align="center">
                        </el-table-column>
                        <el-table-column prop="DINNER_MONEY" label="金额" min-width="32px" align="center">
                        </el-table-column>
                        <!-- <el-table-column prop="完成率" label="备注" min-width="50px"  align="center">
                        </el-table-column> -->
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="用餐时段份数" name="second">
                    <span slot="label"><i class="el-icon-date"></i> 用餐时段份数</span>
                    <el-date-picker
                        v-model="dinner_date2"
                        @change="DateChange2"
                        style="width: 100%;margin-bottom: 15px;"
                        size="small"
                        type="date"
                        align="center"
                        value-format="yyyy-MM-dd"
                        placeholder="选择用餐日期">
                    </el-date-picker> 
                    <el-table 
                        :data="dinnerOrderData2" 
                        size="mini" 
                        max-height="580px"
                        border
                        :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, height: '32px'}" 
                        :row-style="{align: 'center'}"
                        :cell-style="() => { return 'padding: 0px;margin: 0px;height: 30px;font-size: 11px'}"
                        v-loading="table_loading2">
                        <el-table-column prop="FNAME" label="菜品" align="center">
                        </el-table-column>
                        <el-table-column prop="" label="用餐时间（份数）" align="center">
                            <el-table-column prop="11:30" label="11:30" align="center">
                            </el-table-column>
                            <el-table-column prop="11:45" label="11:45" align="center">
                            </el-table-column>
                            <el-table-column prop="12:00" label="12:00" align="center">
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div>
            <el-row :gutter="15">
                <el-col :span="12">
                    <router-link to="/dinnerAdminType">
                        <el-button  size="medium" style="width: 100%" type="success">管理菜品类别</el-button>
                    </router-link>
                </el-col>
                <el-col :span="12">
                    <router-link to="/dinnerAdminMenu">
                        <el-button  size="medium" style="width: 100%" type="success">管理菜品明细</el-button>
                    </router-link>
                </el-col>
            </el-row>
        </div>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import conf from '../../../conf'
export default {
    data() {
        return {
            activeName: 'first',
            dinnerOrderData1: [],
            dinnerOrderData2: [],
            dinner_date1: moment().format('YYYY-MM-DD'),
            dinner_date2: moment().format('YYYY-MM-DD'),
            table_loading1: false,
            table_loading2: false
        }
    },
    methods: {
        DateChange1() {
            this.GetDinnerOrderDetail()
        },
        async GetDinnerOrderDetail() {
            this.dinnerOrderData1 = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrders/GetDinnerOrderDetail', qs.stringify({
                dinner_date: this.dinner_date1,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.dinnerOrderData1 = res.data.ResponseData 
            }else {
                this.$message({
                    message: '获取数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        DateChange2() {
            this.GetDinnerOrderTimeNum()
        },
        async GetDinnerOrderTimeNum() {
            this.dinnerOrderData2 = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrders/GetDinnerOrderTimeNum2', qs.stringify({
                dinner_date: this.dinner_date2,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.dinnerOrderData2 = res.data.ResponseData 
            }else {
                this.$message({
                    message: '获取数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        this.DateChange1()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>