<template>
    <div>
        <el-row :gutter="15">
            <el-col :span="8" v-for="(item, index) in cusData" :key="index">
                <el-badge :value="item.badge_value === '0' ? null : item.badge_value" class="item" style="width: 100%"> 
                    <el-tag
                        size="medium"
                        style="width: 100%;font-weight: bold; font-size: 12px; margin-bottom: 15px"
                        
                        @click="selection(item)"
                        :effect="item.tag_effect">
                        {{item.label}}
                    </el-tag>
                </el-badge>
                <!-- <el-button :type="item.btn_type" size="small" style="width: 100%;font-weight: bold; margin-bottom: 8px; font-size: 11px" @click="selection(item)">{{item.label}}</el-button> -->
            </el-col>
        </el-row>
        <!-- <el-form :model="piForm" ref="piForm" label-width="0px" size="small" style="padding-top: 24px;">
            <el-form-item prop="keyword">
                <el-input v-model="wipForm.keyword" placeholder="请输入指令单号、型号查询">
                    <el-button slot="append" style="color: #409EFF;" icon="el-icon-search" @click="SubmitForm('piForm')"></el-button>
                </el-input>
            </el-form-item>
        </el-form> -->
        <el-table :data="tableData" :show-header="false" stripe v-loading="table_loading" size="mini">
            <el-table-column prop="col1" width="148px">
                <template slot-scope="scope">
                    <div style="color: #606266; font-weight: bold; font-size: 14px">{{scope.row.odr_no}}</div>
                    <div>{{scope.row.artic_no1}}</div>
                    <div>{{scope.row.sec_nm}}</div>
                    <!-- <div>{{scope.row.day_odate}}</div> -->
                </template>
            </el-table-column>
            <el-table-column prop="col2" align="right">
                <template slot-scope="scope">
                    <div> 
                        <span> 总数：{{scope.row.atqty}}</span>
                        <!-- <span> 行：{{scope.row.UEPOS}}</span> -->
                    </div>  
                    <div>
                        <span style="color: #409EFF">确认：{{scope.row.day_oqty}}</span>
                        <span style="margin-left: 5px;color: #67C23A">今出：{{scope.row.toqty}}</span>
                        <span style="margin-left: 5px;color: #F56C6C;">差：{{scope.row.diffoqty}}</span>
                    </div> 
                    <!-- <div>
                        <span style="margin-right: 5px">入：{{scope.row.iqty}}</span>
                        <span>今入：{{scope.row.tiqty}}</span>
                        <span>欠：{{scope.row.i_dqty}}</span>
                    </div>
                    <div>
                        <span style="margin-right: 5px">出：{{scope.row.oqty}}</span>
                        <span>今出：{{scope.row.toqty}}</span>
                        <span>欠：{{scope.row.o_dqty}}</span>
                    </div>
                    <div>
                        <span style="margin-right: 5px">确认：{{scope.row.day_oqty}}</span>
                        <span>差：{{scope.row.diffoqty}}</span>
                        <span>库存：{{scope.row.sqty}}</span>
                    </div> -->
                    <div style="color: #E6A23C; font-weight: bold; font-size: 14px">库存：{{scope.row.sqty}}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
export default {
    data() {
        return {
            cus_no: '',
            cus_nm: '',
            odr_no: '',
            keyword: '',
            tableData: [],
            cusData: [],
            table_loading: false,
        }
    },
    methods: {
        selection(e) {
            this.cusData.forEach(item => {
                if(item.label === e.label) {
                    // item.btn_type = item.btn_type === 'primary' ? 'default' : 'primary'
                    item.tag_effect = item.tag_effect === 'dark' ? 'plain' : 'dark'
                    this.cus_no = ''
                    if(item.tag_effect === 'dark') {
                        this.cus_no = item.value
                    }
                    
                    this.GetProexpStoodrData()
                }else {
                    // item.btn_type = 'default'
                    item.tag_effect = 'plain'
                }
            })
        },
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.wipForm)
                    this.GetProexpStoodrData()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async GetProexpStoodrCusData() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/ProexpStoodr/GetProexpStoodrCusData', qs.stringify({
                cus_no: this.cus_no
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // this.cusData = res.data.ResponseData
                res.data.ResponseData.forEach(item => {
                    this.cusData.push({
                        label: item.cus_par,
                        value: item.cus_no,
                        badge_value: item.cus_count,
                        // btn_type: 'default',
                        tag_effect: 'plain'
                    })
                })
                
                console.log('this.cusData', this.cusData)
            }
        },
        async GetProexpStoodrData() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/ProexpStoodr/GetProexpStoodrData', qs.stringify({
                cus_no: this.cus_no
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.tableData = res.data.ResponseData
              
            }
        }
    },
    mounted() {
        this.GetProexpStoodrCusData()
        this.GetProexpStoodrData()
    },
    created() {
        
    }
}
</script>