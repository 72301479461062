<template>
    <div>
        <el-descriptions style="margin-bottom: 20px" :column="1" size="small" border>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-warning-outline"></i> 工号
                </template>
            {{clockForm.pnl}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-user"></i> 姓名
                </template>
            {{clockForm.pname}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-tickets"></i> 部门
                </template>
            {{clockForm.sec_nm}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-mobile-phone"></i> 手机
                </template>
            {{clockForm.phone}}
            </el-descriptions-item>
        </el-descriptions>
        <el-form :model="clockForm" :rules="rules" ref="clockForm" label-width="80px" size="small">
            <el-form-item label="微信提醒" prop="isClose" style="text-align: left;font-weight: bold" v-if="clockForm.isShow">
                <el-switch
                    size="small"
                    @change="ConfirmStatus"
                    v-model="clockForm.isClose"
                    active-color="#ff4949"
                    inactive-color="#13ce66"
                    active-text="关闭"
                    inactive-text="开启">
                </el-switch>
            </el-form-item>
            <el-form-item label="打卡定位" prop="rcposition" style="font-weight: bold" v-if="!this.isSign">
                <el-row>
                    <el-col :span="19">
                        <el-input v-model="clockForm.rcposition" disabled style="font-weight: bold"> </el-input>
                    </el-col>
                    <el-col :span="5">
                        <el-button 
                            size="small"
                            :loading="isLoading"
                            icon="el-icon-location-outline"
                            type="primary" 
                            @click="GetJSAPI">
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="图片" prop="fileList2" style="font-weight: bold;" v-if="isOther">
                <el-upload 
                    action='' 
                    ref="upload"
                    multiple
                    :on-change="getFile" 
                    :limit="1" 
                    :on-remove="handleRemove"
                    list-type="picture"
                    :auto-upload="false" 
                    :accept="'image/*'"
                    :on-exceed="handleExceed"
                    style="width: 100%;" >
                    <el-button type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="验证码" prop="phoneCode" style="font-weight: bold;" v-if="isOther">
                 <el-input v-model="phoneCode" placeholder="请输入验证码" :disabled="!CAPTCHA">
                    <el-button type="primary" slot="append" :loading="isLoading4" @click="getCode">获取验证码</el-button>
                </el-input>
            </el-form-item>
            <el-row style="margin-top: 20px" v-if="isOther">
                <el-col :span="24">
                    <el-button 
                        size="small"
                        style="width: 100%;"
                        :loading="isLoading5"
                        type="primary" 
                        @click="SubmitClockOther">提交</el-button>
                </el-col>
            </el-row>
            <el-alert
                style="margin-top: 20px"
                type="success"
                v-if="isShowSuccess"
                show-icon>
                <template slot='title'>
                    <div class="iconSize">验证码已发送，请在手机查收！</div>
                </template>
            </el-alert>
            <el-result v-if="isSuccess" icon="success" title="打卡成功">
            </el-result>
            <el-result v-if="isError" icon="error" title="打卡失败" subTitle="请换个角度再来一次！">
                <template slot="extra">
                    {{error_info}}
                </template>
            </el-result>
            <el-row style="padding-bottom: 10px" v-if="isError">
                <el-col :span="24">
                    <el-button 
                        size="small"
                        style="width: 100%;"
                        type="danger" 
                        @click="ClockOther">打卡申诉（方式二）</el-button>
                </el-col>
            </el-row>
            <el-row style="margin-top: 20px" v-if="!isOther">
                <el-col :span="24">
                    <el-button 
                        size="small"
                        :loading="isLoading2"
                        style="width: 100%;"
                        type="primary" 
                        @click="submitForm('clockForm')">拍照打卡</el-button>
                </el-col>
            </el-row>
            <el-dialog :visible.sync="canfirmDialogVisible" size="mini" width="90%">
                <span class="dialog-footer" style="font-weight: 550;color: #409EFF;">
                    <span style="color: #F56C6C;">您将{{clockForm.isClose?'关闭':'开启'}}微信推送提醒服务，是否确认？</span>
                </span>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="UnUpdateStatus">取消</el-button>
                    <el-button type="primary" :loading="isLoading3" size="mini" @click="UpdateStatus">确认</el-button>
                </span>
            </el-dialog>
        </el-form>
        <el-collapse v-model="activeNames" style="margin-top: 30px">
            <el-collapse-item  title="打卡记录" name="1" >
                <el-table :data="tableData" size="small" :show-header="false" label="" style="margin-bottom: -30px">
                    <el-table-column prop="name">
                        <template slot-scope="scope">
                            <div>时间：{{scope.row.rcdate}}
                                <el-link style="font-size: 13px;color: #409EFF; margin-left: 10px" @click="ShowImages(scope.row.id)"><i class="el-icon-picture-outline"></i> 查看</el-link>
                                <span style="margin-left: 30px;">
                                    <el-tag
                                        v-if="scope.row.rcisvalid"
                                        type="success"
                                        size="mini"
                                        effect="dark">
                                        已生效
                                    </el-tag> 
                                </span></div>
                            <div>地点：{{scope.row.rcposition}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-collapse-item>
        </el-collapse>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import conf from '../../../conf.js'
import axios from 'axios'
import qs from 'qs'
import wx from 'weixin-js-sdk'
import { compressImage } from '../../../plugin/compress.js'
export default {
    data() {
        return {
            clockForm: {
                pnl: '',
                pname: '',
                sec_nm: '',
                rcposition: '',
                rcreason: '',
                lat: '',
                lng: '',
                province: '',
                city: '',
                district: '',
                town: '',
                street: '',
                pois_addr: '',
                sematic_description: '',
                isClose: false,
                isShow: false,
                phone: '',
                rctype: 1
            },
            IMAGE1: '',
            FILENAME1: '',
            isLoading: false,
            isLoading2: false,
            isLoading3: false,
            isSuccess: false,
            isError: false,
            error_info: '',
            activeNames: '0',
            tableData: [],
            imageList: [],
            canfirmDialogVisible: false,
            isOther: false,
            fileList2: [],
            imageList2: [],
            phoneCode: '',
            CAPTCHA: '',
            isLoading4: false,
            isShowSuccess: false,
            isLoading5: false,
            isSign: false,
            rules: {
                rcposition: [
                    {required: true, message: '请获取当前位置信息', trigger: 'blur'}
                ],
            }
        }
    },
    methods: {
        SubmitClockOther() {
            if(this.CustomValidate3() && this.CustomValidate2() && this.CheckCode()){
                this.SaveRemoteClock()
            }
        },
        CheckCode() {
            if(this.CAPTCHA === this.phoneCode) {
                return true
            }else{
                this.$message({
                    message: '验证码错误，请重新获取',
                    center: true,
                    type: 'error'
                })
                return false
            }
        },
        CustomValidate2() {
            if(this.fileList2.length === 0) {
                this.$message({
                    message: '请上传图片',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
                return false
            }
            return true
        },
        CustomValidate3() {
            if(this.clockForm.rcposition.length === 0) {
                this.$message({
                    message: '请点击定位按钮',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
                return false
            }
            return true
        },
        ClockOther() {
            this.isError = false
            this.isOther = true
        },
        ConfirmStatus() {
            this.canfirmDialogVisible = true
        },
        UnUpdateStatus() {
            this.clockForm.isClose = !this.clockForm.isClose
            this.canfirmDialogVisible = false
        },
        UpdateStatus() {
            this.ConfirmUpdateStatus()
        },
        ShowImages(id) {
            // console.log('id', id)
            this.GetImagesByID(id)
        },
        async GetImagesByID(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                           path
                        )
                    })
                    
                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
            }
        },
        async GetJSAPI() {
            this.GetLocation()
        },
        async GetLocation() {
            wx.getLocation({
                type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: res => {
                    console.log(res)
                    this.GetBaiduLocation(res.latitude, res.longitude)
                }
            })
        },
        async GetBaiduLocation(lat, lng) {
            this.isLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/BaiduLocation/BaiduLocationName', qs.stringify({
                lat: lat, // dev
                lng: lng // dev
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功') {
                if(res.data.ResponseData.message) {
                    this.$message({
                        message: res.data.ResponseData.message,
                        center: true,
                        type: 'success'
                    })
                }
                if(res.data.ResponseData.status === 0) {
                    let data = res.data.ResponseData.result
                    // this.clockForm.rcreason = ''  // 打卡理由
                    this.clockForm.lat = data.location.lat
                    this.clockForm.lng = data.location.lng
                    this.clockForm.province = data.addressComponent.province
                    this.clockForm.city = data.addressComponent.city
                    this.clockForm.district = data.addressComponent.district
                    this.clockForm.town = data.addressComponent.town
                    this.clockForm.street = data.addressComponent.street
                    this.clockForm.pois_addr = data.pois[0].addr
                    this.clockForm.sematic_description = data.sematic_description
                    this.clockForm.rcposition = this.clockForm.pois_addr
                    this.isLoading = false
                }
            }
        },
        async ChooseImage() {
            wx.chooseImage({
                count: 1, // 默认9
                sizeType: ['compressed'], // sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                sourceType: ['camera'], // sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                success: res => {
                    var localIds = res.localIds // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                    this.GetLocalImage(localIds[0])
                }
            })
        },
        async GetLocalImage(localId) {
            wx.getLocalImgData({
                localId: localId, // 图片的localID
                success: res => {
                    var localData = res.localData // localData是图片的base64数据，可以用img标签显示
                    this.IMAGE1 = localData
                    this.FILENAME1 = this.SubStringBase64(localData)
                    this.FaceCompare()
                }
            })
        },
        async FaceCompare() {
            const loading = this.$loading({
                lock: true,
                text: '识别中，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/UniUbi/FaceRecognition', qs.stringify({
                pnl: this.clockForm.pnl,
                IMAGE1: encodeURIComponent(this.IMAGE1)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                if(res.data.ResponseData.DATA.score > 0.8) { // 泛宇接口 下调识别率
                    loading.close()
                    this.SaveRemoteClock()
                }else {
                    this.error_info ='失败1：' + res.data.Message
                    this.isError = true
                    this.isSuccess = false
                }
            }else {
                this.error_info = '失败2：' + res.data.Message
                this.isError = true
                this.isSuccess = false
            }
            loading.close()
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.clockForm)
                    this.ChooseImage()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveRemoteClock() {
            this.isLoading2 = true
            const dto = {
                PNL: this.clockForm.pnl,
                PNAME: this.clockForm.pname,
                SEC_NM: this.clockForm.sec_nm,
                RCPOSITION: this.clockForm.rcposition,
                RCREASON: this.clockForm.rcreason,
                IMAGE1: this.isOther ? encodeURIComponent(this.imageList2[0]) : encodeURIComponent(this.IMAGE1),
                LAT: this.clockForm.lat,
                LNG: this.clockForm.lng,
                PROVINCE: this.clockForm.province,
                CITY: this.clockForm.city,
                DISTRICT: this.clockForm.district,
                TOWN: this.clockForm.town,
                STREET: this.clockForm.street,
                POIS_ADDR: this.clockForm.pois_addr,
                SEMATIC_DESCRIPTION: this.clockForm.sematic_description,
                FILENAME1: this.isOther ? this.fileList2[0] : this.FILENAME1,
                ISOTHER:  this.isOther ? 'Y' : 'N',
                RCTYPE: this.isOther ? '2' : this.clockForm.rctype,
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxRemoteClocks/SaveRemoteClock', qs.stringify({
                dto,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.isSuccess = true
                this.isOther = false
                this.isError = false
                this.GetRemoteClockList()
                this.activeNames = '1'
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.error_info ='保存1：' + res.data.Message
                this.isSuccess = false
                this.isOther = false
                this.isError = true
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                this.error_info ='保存2：' + res.data.Message
                this.isSuccess = false
                this.isOther = false
                this.isError = true
            }
            this.isLoading2 = false
        },
        SubStringBase64(base64) {
            var start = base64.indexOf('/')
            var end = base64.indexOf(';')
            var time = new Date()
            var timestamp = Date.parse(time)
            return timestamp + '.' + (end === -1 ? 'jpg' : base64.substring(start + 1, end))
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage','openLocation','getLocation','chooseImage'], // 必填，需要使用的JS接口列表
                })
            }
        },
        async GetRemoteClockList() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxRemoteClocks/GetRemoteClockList', qs.stringify({
                PNL: this.clockForm.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.tableData = []
                res.data.ResponseData.forEach(item => {
                    this.tableData.push({
                        rcdate: item.RCDATE,
                        rcposition: item.RCPOSITION,
                        rcisvalid: item.RCISVALID,
                        id: item.DB_ID
                    })
                })    
                
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }
        },
        async CheckCloseByPnl() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxRemoteEkps/CheckCloseByPnl', qs.stringify({
                PNL: this.clockForm.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                if(res.data.ResponseData.length > 0){
                    this.clockForm.isShow = true
                    if(res.data.ResponseData[0].IS_CLOSE === 'Y') {
                        console.log('res.data.ResponseData.IS_CLOSE', res.data.ResponseData[0].IS_CLOSE)
                        this.clockForm.isClose = true
                    }else{
                        this.clockForm.isClose = false
                    }
                }else{
                    this.clockForm.isShow = false
                }
            }
        },
        async ConfirmUpdateStatus() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxRemoteEkps/UpdateStatus', qs.stringify({
                PNL: this.clockForm.pnl,
                ISCLOSE: this.clockForm.isClose ? 'Y' : 'N'
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.canfirmDialogVisible = false
                var temp = this.clockForm.isClose ? '关闭' : '开启'
                this.$message({
                    message: '微信推送提醒服务：' + temp +'成功',
                    center: true,
                    type: 'success'
                })
            }
        },
        handleRemove(file, fileList) {
            console.log('file', file)
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.fileList2 = []
            this.imageList2 = []
            fileList.forEach(item => {
                this.fileList2.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.imageList2.push(res)
                })
            })
        },
        handleExceed() {
            this.$message({
                message: '只需上传一张图片',
                type: 'warning',
                center: true
            })
        },
        getFile(file, fileList) {
            console.log('file', file)
            // console.log('fileList', fileList)
            this.fileList2 = []
            this.imageList2 = []
            fileList.forEach(item => {
                this.fileList2.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    if(this.imageList2.indexOf(result) === -1){
                        this.imageList2.push(result)
                    }
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        async getCode() {
            if(!this.clockForm.phone) {
                this.$message({
                    message: '请重新登录',
                    center: true,
                    type: 'error'
                })
                return
            }
            this.isLoading4 = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AliSendSMS/SendCaptcha', qs.stringify({
                phoneNumber: this.clockForm.phone,
                templateCode: conf.smsInfo.templateCode,
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK' && res.data.ResponseData.Code !== 'OK') {
                this.$message({
                    message: '验证码获取失败：' + this.clockForm.phone + ' 不是有效电话号码',
                    center: true,
                    type: 'success'
                })
            }else if(res.data.Code === 0  && res.status === 200 && res.statusText === 'OK' && res.data.ResponseData.Code === 'OK') {
                this.isShowSuccess = true
                this.CAPTCHA = res.data.ResponseData.CAPTCHA
                console.log('this.CAPTCHA', this.CAPTCHA)
            }
            this.isLoading4 = false
        },
    },
    mounted() {
        const type = this.$route.params.type
        if(type) {
            this.isSign = true
            this.clockForm.rcposition = '寰球鞋服工业园签到处'
            this.clockForm.rctype = 3
        }

        this.GetSignature()
        this.CheckCloseByPnl()
        this.GetRemoteClockList()
        // this.isError = true  // dev
        // this.clockForm.isShow = true // dev
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.clockForm.pnl = pnl
            // this.clockForm.pnl = '66135' // dev
        }
        const pname = localStorage.getItem('PNAME')
        if(pname){
            this.clockForm.pname = pname
        }
        const sec_nm = localStorage.getItem('SEC_NM')
        if(sec_nm){
            this.clockForm.sec_nm = sec_nm
        }
        const phone = localStorage.getItem('PHONE')
        if(phone){
            this.clockForm.phone = phone
        }
    }
}
</script>