<template>
    <div>
        <el-form :model="dinnerTypeForm" ref="dinnerTypeForm" label-width="90px" size="medium">
            <el-form-item label="菜品类别" prop="ftype_no" style="font-weight: bold">
                <el-select 
                    v-model="dinnerTypeForm.ftype_no" 
                    placeholder="请选择菜品类别" 
                    size="medium" 
                    @change="TypeChange"
                    style="width: 100%">
                    <el-option
                        v-for="item in dinnerTypes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <el-table 
            :data="DinnerMenuData" 
            size="small" 
            stripe
            border
            :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
            :row-style="{align: 'center'}"
            v-loading="table_loading">
            <el-table-column prop="FSORT" label="排序" min-width="42" align="center">
            </el-table-column>
            <!-- <el-table-column prop="FNO" label="编号" min-width="58" align="center">
            </el-table-column> -->
            <el-table-column prop="FNAME" label="菜品名称" align="center">
            </el-table-column>
            <el-table-column prop="FPRICE" label="单价" min-width="42" align="center">
            </el-table-column>
            <el-table-column prop="FTYPE_NM" label="类别" min-width="52" align="center">
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="100px" align="center">
               <template slot-scope="scope">
                    <el-link style="font-size: 12px;color: #67C23A" @click="ShowEdit(scope.row)"><i class="el-icon-edit"></i> 编辑</el-link> |
                    <el-link style="font-size: 12px;color: #F56C6C" @click="ShowDel(scope.row)"><i class="el-icon-delete"></i> 删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-row style="margin-top: 20px;margin-bottom: 20px">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    @click="ShowAdd">新增菜品</el-button>
            </el-col>
        </el-row>
        <el-dialog title="菜品信息" :visible.sync="dinnerMenuDialogForm" width="90%">
            <el-form :model="dinnerMenuForm" :rules="rules" ref="dinnerMenuForm" label-width="90px" size="medium">
                <el-form-item label="菜品类别" prop="ftype_no" style="font-weight: bold">
                    <el-select 
                        v-model="dinnerMenuForm.ftype_no" 
                        placeholder="请选择菜品类别" 
                        size="medium" 
                        style="width: 100%">
                        <el-option
                            v-for="item in dinnerTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="菜品编号" prop="fno" style="font-weight: bold">
                    <el-input v-model="dinnerMenuForm.fno" placeholder="请输入菜品编号" size="medium"></el-input>
                </el-form-item> -->
                <el-form-item label="菜品名称" prop="fname" style="font-weight: bold">
                    <el-input v-model="dinnerMenuForm.fname" placeholder="请输入菜品名称" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="菜品单价" prop="fprice" style="font-weight: bold">
                    <el-input v-model="dinnerMenuForm.fprice" placeholder="请输入菜品单价" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="菜品排序" prop="fsort" style="font-weight: bold">
                    <el-input v-model="dinnerMenuForm.fsort" placeholder="请输入菜品排序" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="菜品图片" prop="" style="font-weight: bold">
                    <el-upload 
                        action='' 
                        ref="upload"
                        multiple
                        :on-change="getFile" 
                        :limit="1" 
                        :on-remove="handleRemove"
                        list-type="picture"
                        :auto-upload="false" 
                        :accept="'image/*'"
                        :on-exceed="handleExceed"
                        style="width: 100%;" >
                        <el-button size="small" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <!-- <el-button size="small" @click="doneDialogFormVisible = false">取消</el-button> -->
                <el-button size="medium" type="success" :loading="isLoading" @click="SubmitForm('dinnerMenuForm')">提交</el-button>
            </div>
        </el-dialog>
        <el-dialog style="color: #F56C6C" title="删除确认？" :visible.sync="delDialogVisible" size="mini" width="80%">
            <span class="dialog-footer">
                删除菜品名称：【{{dinnerMenuForm.fname}}】，是否继续?
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="delDialogVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="DelDinnerOrder(dinnerMenuForm.db_id)">确定</el-button>
            </span>
        </el-dialog>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import { compressImage } from '../../../plugin/compress.js'
export default {
    data() {
        return {
            dinnerTypeForm: {
                ftype_no: ''
            },
            DinnerMenuData: [],
            table_loading: false,
            dinnerMenuDialogForm: false,
            delDialogVisible: false,
            isLoading: false,
            dinnerTypes: [],
            dinnerMenuForm: {
                fname: '',
                fprice: 0,
                ftype_no: '',
                fsort: 0,
                fno: '',
                db_create_userno: '',
                // fimgpath: ''
            },
            fileList: [],
            imageList: [],
            rules: {
                ftype_no: [
                    {required: true, message: '请选择菜品类别', trigger: 'change'}
                ],
                fno: [
                    {required: true, message: '请输入菜品编号', trigger: 'blur'}
                ],
                fname: [
                    {required: true, message: '请输入菜品名称', trigger: 'blur'}
                ],
                fprice: [
                    {required: true, message: '请输入菜品单价', trigger: 'blur'}
                ],
                // fsort: [
                //     {required: true, message: '请输入菜品排序', trigger: 'blur'}
                // ],
            }
        }
    },
    methods: {
        TypeChange() {
            this.GetDinnerMenuByType()
        },
        ShowDel(item) {
            this.dinnerMenuForm.fname = item.FNAME
            this.dinnerMenuForm.db_id = item.DB_ID
            this.delDialogVisible = true
        },
        ShowEdit(item) {
            console.log('item', item)
            this.dinnerMenuForm.fname = item.FNAME
            this.dinnerMenuForm.fprice = item.FPRICE
            this.dinnerMenuForm.ftype_no = item.FTYPE_NO
            this.dinnerMenuForm.fsort = item.FSORT
            this.dinnerMenuForm.db_id = item.DB_ID
            // this.dinnerMenuForm.fimgpath = conf.image_domain + item.FIMGPATH
            this.dinnerMenuDialogForm = true
        },
        ShowAdd() {
            this.dinnerMenuForm = {}
            this.dinnerMenuDialogForm = true
        },
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.waterForm)
                    this.SaveDinnerMenu()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveDinnerMenu() {
            this.isLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrderMenus/SaveDinnerMenu', qs.stringify({
                dto: this.dinnerMenuForm,
                IMAGE1: encodeURIComponent(this.imageList[0]),
                FILENAME1: this.fileList[0],
                IMAGE2: encodeURIComponent(this.imageList[1]),
                FILENAME2: this.fileList[1],
                IMAGE3: encodeURIComponent(this.imageList[2]),
                FILENAME3: this.fileList[2],
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                console.log('提交成功:', res.data.ResponseData)
                this.$message({
                    message: '提交成功',
                    center: true,
                    type: 'success'
                })
                this.GetDinnerMenuByType()
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '提交失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '提交失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
            this.dinnerMenuDialogForm = false
        },
        async GetDinnerMenuByType() {
            this.table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrderMenus/GetDinnerMenuByType', qs.stringify({
                FTYPE_NO: this.dinnerTypeForm.ftype_no
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.DinnerMenuData = res.data.ResponseData
            }else {
                this.$message({
                    message: '获取数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.table_loading = false
        },
        async GetDinnerType() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrderTypes/GetDinnerType')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.dinnerTypes.push({
                    label: '全部',
                    value: ''  
                })
                res.data.ResponseData.forEach(item => {
                    this.dinnerTypes.push({
                        label: item.FTYPE_NM,
                        value: item.FTYPE_NO  
                    })
                })
            }else {
                this.$message({
                    message: '获取数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async DelDinnerOrder(DB_ID) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDinnerOrderMenus/DelDinnerMenu', qs.stringify({
                DB_ID
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                console.log('提交成功:', res.data.ResponseData)
                this.$message({
                    message: '提交成功',
                    center: true,
                    type: 'success'
                })
                this.GetDinnerMenuByType()
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '提交失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '提交失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.delDialogVisible = false
        },
        getFile(file, fileList) {
            this.fileList = []
            this.imageList = []
            fileList.forEach(item => {
                this.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.imageList.push(result)
                })
                // console.log('this.imageList', this.imageList)
            })
        },
        handleRemove(file, fileList) {
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.fileList = []
            this.imageList = []
            fileList.forEach(item => {
                this.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        }
    },
    mounted() {
        this.GetDinnerMenuByType()
        this.GetDinnerType()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.dinnerMenuForm.db_create_userno = pnl
        }
    }
}
</script>