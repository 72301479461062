<template>
    <div>
        <el-form :model="firstConfirmForm" size="small">
            <el-form-item prop="key" style="font-weight: bold">
                <el-row :gutter="15">
                    <el-col :span="19">
                        <el-input style="margin-left: 5px" v-model="firstConfirmForm.key" placeholder="请输入货号、指令号"></el-input>
                    </el-col>
                    <el-col :span="5">
                        <el-button style="width: 100%" type="primary" @click="Search">搜索</el-button>
                    </el-col>
                </el-row>
            </el-form-item>
        </el-form>
        <el-table 
            :data="tableData" 
            :show-header="false" 
            stripe 
            v-loading="isLoading" 
            size="small" 
            @row-click="ShowDetail"
            @row-dblclick="ShowDialog">
            <el-table-column prop="name" width="30px">
                <template slot-scope="scope">
                    <div style="font-size: 14px;font-weight: bold">
                    <i v-if="scope.row.receive" style="color: #67C23A;font-weight: bold;font-size: 20px" class="el-icon-check"></i></div>
                </template>
            </el-table-column>
            <el-table-column prop="name" width="170px">
                <template slot-scope="scope">
                    <div style="font-size: 14px;font-weight: bold">{{scope.row.artic_no}} 
                    </div>
                    <div>
                        <span>{{scope.row.color}}</span>，
                        <span style="font-size: 13px;font-weight: bold;color: #409EFF;">{{scope.row.osize}}</span>，
                        <span style="font-size: 13px;font-weight: bold;color: #F56C6C;">{{scope.row.qty}}</span>
                    </div>
                    <div>{{scope.row.odr_no}}</div>
                </template>
            </el-table-column>
            <el-table-column align="left">
                <template slot-scope="scope">
                    <div style="font-size: 12px;color: #E6A23C;">{{scope.row.remark}}</div>
                </template>
            </el-table-column>
        </el-table>
         <el-row style="margin-top: 20px;margin-bottom: 20px;">
            <el-col :span="24">
                <router-link to="/firstConfirmCreate">
                    <el-button style="width: 100%" type="primary">添加首件确认信息</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-dialog title="是否首件接收？" :visible.sync="dialogVisible" width="90%">
            <el-table :data="tableDataDetail" size="mini" :show-header="false">
                <el-table-column prop="label" width="90px">
                </el-table-column>
                <el-table-column prop="value">
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="UpdateFirstConfirm">接收</el-button>
            </div>
        </el-dialog>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
export default {
    data() {
        return {
            firstConfirmForm: {
                key: ''
            },
            tableData: [],
            isLoading: false,
            pnl: '',
            dialogVisible: false,
            tableDataDetail: [],
            isLoadingDetail: false,
            id: ''
        }
    },
    methods: {
        ShowDialog(row) { 
            if(row.receive) {
                this.$message({
                    message: '首件已接收！',
                    center: true,
                    type: 'error',
                })
                return
            } 

            this.dialogVisible = true
            this.isLoadingDetail = true
            this.tableDataDetail = []
            this.tableDataDetail.push({
                    label: '日期',
                    value: row.iod
                },{
                    label: '指令号',
                    value: row.odr_no
                },{
                    label: '货号',
                    value: row.artic_no
                },{
                    label: '颜色',
                    value: row.color
                },{
                    label: '尺码',
                    value: row.osize
                },{
                    label: '数量',
                    value: row.qty
                },{
                    label: '备注',
                    value: row.remark
            })
            this.isLoadingDetail = false
            this.id = row.DB_ID
        },
        ShowDetail(row) {
            console.log(row)
            // window.location.href = '/FirstConfirmDetail/' + row.DB_ID
        },
        Search() {
            this.GetFirstConfirmList()
        },
        async GetFirstConfirmList() {
            this.isLoading = true
            this.tableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/FirstConfirm/FirstConfirmList', qs.stringify({
                pnl: this.pnl,
                key: this.firstConfirmForm.key
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.tableData = res.data.ResponseData
                }
            }
            this.isLoading = false
        },
        async UpdateFirstConfirm() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/FirstConfirm/UpdateFirstConfirm', qs.stringify({
                id: this.id,
                pnl: this.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                    this.$message({
                        message: '首件接收成功！',
                        center: true,
                        type: 'success',
                    })
                    // window.location.href = '/firstConfirm'
                    this.dialogVisible = false
                    this.GetFirstConfirmList()
                }else if(res.data.Code === 1) {
                    this.$message({
                        message: res.data.Message,
                        center: true,
                        type: 'error'
                    })
                }
            }
        }
    },
    mounted() {
        this.GetFirstConfirmList()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
            // this.pnl = '25001' // dev
        }
    }
}
</script>