<template>
    <div>
        <el-form :model="checkCreateForm" ref="checkCreateForm" :rules="rules" label-width="70px" size="small">
            <el-form-item label="制程" prop="made_no" style="font-weight: bold">
                <el-select 
                    @change="MadeChange"
                    v-model="checkCreateForm.made_no" 
                    placeholder="请选择制程" 
                    style="width: 100%">
                    <el-option
                        v-for="item in made_nos"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item 
                v-if="checkCreateForm.made_no === '7' || checkCreateForm.made_no === '3' || checkCreateForm.made_no === 'A'" 
                label="主部门" 
                prop="sec_no1" 
                style="font-weight: bold">
                <el-select 
                    @change="MainSecChange"
                    v-model="checkCreateForm.sec_no1" 
                    placeholder="请选择主部门" 
                    style="width: 100%">
                    <el-option
                        v-for="item in sec_no1s"
                        :key="item.sec_no1"
                        :label="item.sec_nm1"
                        :value="item.sec_no1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="部门" prop="sec_no" style="font-weight: bold">
                <el-select 
                    v-model="checkCreateForm.sec_no" 
                    placeholder="请选择部门" 
                    style="width: 100%">
                    <el-option
                        v-for="item in sec_nos"
                        :key="item.sec_no"
                        :label="item.sec_nm"
                        :value="item.sec_no">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="指令号" prop="odr_no" style="font-weight: bold">
                <el-row>
                    <el-col :span="19">
                        <el-input 
                            disabled
                            v-model="checkCreateForm.odr_no" 
                            style="font-weight: bold"> 
                        </el-input>
                    </el-col>
                    <el-col :span="5">
                        <el-button 
                            @click="dialogVisible = true"
                            style="margin-left: 10px;width: 90%;"
                            icon="el-icon-more"
                            type="primary">
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="表格" prop="fid" style="font-weight: bold">
                <el-select 
                    v-model="checkCreateForm.fid" 
                    placeholder="请选择表格" 
                    @change="TableChange"
                    style="width: 100%">
                    <el-option
                        v-for="item in fids"
                        :key="item.fid"
                        :label="item.fname"
                        :value="item.fid">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-row style="margin-top: 10px;margin-bottom: 10px">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        @click="SubmitForm('checkCreateForm')">打开表格</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-dialog title="选择指令号" :visible.sync="dialogVisible" width="90%">
            <!-- <el-input v-model="search" minlength="4" size="small" style="font-weight: bold" placeholder="输入指令号、货号"/> -->
            <el-row style="margin-bottom: 15px">
                <el-col :span="18">
                    <el-input 
                        minlength="4"
                        size="small"
                        placeholder="输入指令号、货号"
                        v-model="search" 
                        style="font-weight: bold"> 
                    </el-input>
                </el-col>
                <el-col :span="6">
                    <el-button 
                        size="small"
                        @click="GetOdrNosByKey"
                        style="margin-left: 10px;width: 90%;"
                        icon="el-icon-search"
                        type="primary">搜索
                    </el-button>
                </el-col>
            </el-row>
            <el-table
                ref="multipleTable"
                height="300px"
                size="mini"
                tooltip-effect="dark"
                v-loading="tableLoading" 
                @selection-change="handleSelectionChange"
                :data="this.odr_nos.filter(data => !search || data.odr_no.toLowerCase().includes(search.toLowerCase()) 
                || data.artic_no.toLowerCase().includes(search.toLowerCase()))"
                style="width: 100%;margin-top: 10px">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column label="指令号" prop="odr_no">
                </el-table-column>
                <el-table-column label="货号" prop="artic_no">
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="SetOdrNo">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
export default {
    data() {
        return {
            made_nos: [{ 
                value: '1',
                label: '冲裁'
            },{ 
                value: '3',
                label: '帮面'
            },{ 
                value: '6',
                label: '贴合'
            },{ 
                value: '7',
                label: '成型'
            },{ 
                value: 'A',
                label: '工艺'
            }], 
            checkCreateForm: {
                made_no: '',
                sec_no1: '',
                sec_no: '',
                odr_no: '',
                fid: '',
                type: 0,
                pnl: ''
            },
            sec_no1s: [],
            sec_nos: [],
            odr_nos: [],
            fids: [],
            dialogVisible: false,
            search: '',
            tableLoading: false,
            multipleSelection: [],
            rules: {
                sec_no: [
                    {required: true, message: '请选择部门', trigger: 'change'}
                ],
                odr_no: [
                    {required: true, message: '输入指令号', trigger: 'blur'}
                ],
                fid: [
                    {required: true, message: '请选择表格', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    this.ToCreateTable()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        ToCreateTable() {
            localStorage.setItem('check_info.sec_no', this.checkCreateForm.sec_no)
            localStorage.setItem('check_info.odr_no', this.checkCreateForm.odr_no)
            // localStorage.setItem('check_info.fid', this.checkCreateForm.fid)

            console.log('odr_no', encodeURIComponent(this.checkCreateForm.odr_no))
            let odrno = encodeURIComponent(this.checkCreateForm.odr_no)

            console.log('this.checkCreateForm.type', this.checkCreateForm.type)
            if(this.checkCreateForm.type == 1) {
                window.location.href = '/processCheck/' + this.checkCreateForm.fid + '/' + this.checkCreateForm.sec_no + '/' + odrno
            }else if(this.checkCreateForm.type == 2) {
                window.location.href = '/MeasureRecord/' + this.checkCreateForm.fid + '/' + this.checkCreateForm.sec_no + '/' + odrno
            }else if(this.checkCreateForm.type == 3) {
                window.location.href = '/MeasureRecord2/' + this.checkCreateForm.fid + '/' + this.checkCreateForm.sec_no + '/' + odrno
            }
        },
        handleSelectionChange(val) {
            if(val.length > 1) {
                this.$refs.multipleTable.clearSelection()
                this.$refs.multipleTable.toggleRowSelection(val[val.length - 1])
                this.multipleSelection = []
                this.multipleSelection.push(val[val.length - 1])
            }
            else{
                this.multipleSelection = val
            }
        },
        SetOdrNo() {
            if(this.multipleSelection.length !== 1) {
                this.$message({
                    message: '请选择指令号！',
                    center: true,
                    type: 'error',
                    duration: 2000
                })
            }else {
                console.log('this.multipleSelection', this.multipleSelection)
                this.checkCreateForm.odr_no = this.multipleSelection[0].odr_no
                this.dialogVisible = false
            }
        },
        MadeChange(made_no) {
            this.checkCreateForm.sec_no = ''
            this.checkCreateForm.sec_no1 = ''
            this.checkCreateForm.fid = ''
            if(made_no === '7' || made_no === '3' || made_no === 'A') {
                this.GetMainSecByMadeNo(made_no)
                this.sec_nos = []
            }else{
                this.GetSecByMadeNo(made_no)
            }
            this.GetCheckFlowByMadeNo(made_no)
        },
        async GetMainSecByMadeNo(made_no) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/MainSecByMadeNo', qs.stringify({
                made_no
            }))
            console.log(res)
            this.sec_no1s = []
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.sec_no1s = res.data.ResponseData
                }
            }
        },
        async GetSecByMadeNo(made_no) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/SecByMadeNo', qs.stringify({
                made_no
            }))
            console.log(res)
            this.sec_nos = []
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.sec_nos = res.data.ResponseData
                }
            }
        },
        MainSecChange(sec_no1) {
            this.GetSecByMainSec(sec_no1)
        },
        async GetSecByMainSec(sec_no1) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/SecByMainSec', qs.stringify({
                sec_no1
            }))
            console.log(res)
            this.sec_nos = []
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.sec_nos = res.data.ResponseData
                }
            }
        },
        async GetOdrNos() {
            this.odr_nos = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/OdrNos')
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.odr_nos = res.data.ResponseData
                }
            }
        },
        async GetOdrNosByKey() {
            this.tableLoading = true
            this.odr_nos = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/OdrNosByKey',qs.stringify({
                key: this.search
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.odr_nos = res.data.ResponseData
                }else if(res.data.ResponseData.length === 0) {
                    this.$message({
                        message: '关键字：[ ' + this.search + ' ] 没有搜索到数据',
                        center: true,
                        type: 'warning'
                    })
                } 
            }
            if(res.data.Code == 1) {
                    this.$message({
                        message: res.data.Message,
                        center: true,
                        type: 'error'
                    })
                }
            this.tableLoading = false
        },
        async GetCheckFlowByMadeNo(made_no) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/CheckFlowByMadeNo', qs.stringify({
                made_no,
                pnl: this.checkCreateForm.pnl
            }))
            console.log(res)
            this.fids = []
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.fids = res.data.ResponseData
                }
            }
        },
        TableChange(val) {
            console.log('val', val)
            this.checkCreateForm.type = this.fids.filter(item=> item.fid === val)[0].ftype
            console.log(this.checkCreateForm.type)
        }
    },
    mounted() {
        this.GetOdrNos()
        this.GetCheckFlowByMadeNo()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.checkCreateForm.pnl = pnl
            // this.checkCreateForm.pnl = '39577' // dev
            // this.checkCreateForm.pnl = '56831' // dev
        }

        // const fid = localStorage.getItem('check_info.fid')
        // if(fid) {
        //     this.checkCreateForm.fid = fid
        // }
        const sec_no = localStorage.getItem('check_info.sec_no')
        if(sec_no) {
            this.checkCreateForm.sec_no = sec_no
        }
        const odr_no = localStorage.getItem('check_info.odr_no')
        if(odr_no) {
            this.checkCreateForm.odr_no = odr_no
        }
    },
    // updated() {
    //     // console.log('来了老弟')
    //     const fid = localStorage.getItem('check_info.fid')
    //     if(fid) {
    //         this.checkCreateForm.fid = fid
    //     }
    //     const sec_no = localStorage.getItem('check_info.sec_no')
    //     if(sec_no) {
    //         this.checkCreateForm.sec_no = sec_no
    //     }
    //     const odr_no = localStorage.getItem('check_info.odr_no')
    //     if(fid) {
    //         this.checkCreateForm.odr_no = odr_no
    //     }
    // }
}
</script>