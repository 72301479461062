<template>
    <div>
        <el-row style="margin-bottom: 20px">
            <el-col :span="24">
                <router-link to="/sportsClock">
                    <el-button style="width: 100%" type="primary">运动打卡</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px">
            <el-col :span="24">
                <router-link to="/sportsStatistics">
                    <el-button style="width: 100%" type="success">打卡统计</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-card shadow="always" v-for="item in sport_list" :key="item.DB_ID" class="box-card" v-loading="loading">
            <el-row>
                <el-col :span="12" style="text-align: left">运动日期</el-col>
                <el-col :span="12" style="text-align: right">{{item.SPORT_DATE}}</el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="12" style="text-align: left">运动类型</el-col>
                <el-col :span="12" style="text-align: right">{{item.SPORT_TYPE}}</el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="12" style="text-align: left">{{item.SPORT_TYPE === '时间类' ? '运动时长' : '运动距离'}}</el-col>
                <el-col :span="12" style="text-align: right">{{item.SPORT_TYPE === '时间类' ? minuteDiff(item.SPORT_START, item.SPORT_END) + ' 分钟' : item.SPORT_KM + ' 公里'}}</el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="12" style="text-align: left">运动项目</el-col>
                <el-col :span="12" style="text-align: right">{{item.SPORT_JOB}}</el-col>
            </el-row>
            <el-divider v-if="item.HAVE_IMAGE !== 0" />
            <el-row v-if="item.HAVE_IMAGE !== 0">
                <el-col :span="12" style="text-align: left">运动图片</el-col>
                <el-col :span="12" style="text-align: right">
                    <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.DB_ID)">
                        <i class="el-icon-picture-outline"></i> 点击查看
                    </el-link>
                </el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="24" style="text-align: right">
                    <el-link type="danger" style="font-weight: bold;font-size: 13px" @click="delSport(item.DB_ID)">删除</el-link>
                </el-col>
            </el-row>
        </el-card>
        <el-dialog :visible.sync="imageDialogTableVisible" size="mini" width="90%">
            <span class="dialog-footer">
                <el-image
                    :key="item.DB_ID"
                    v-for="item in imageList"
                    lazy
                    style="width: 100%;"
                    :src="item.path"
                    fit="fill">
                    <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                    </div>
                    <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="mini" @click="imageDialogTableVisible = false">关闭</el-button>
            </span>
        </el-dialog>
        <el-empty v-if="sport_list.length === 0" description="没有数据"></el-empty>
        <br><br><br>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import conf from '../../conf'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            sport_list: [],
            sport_no: '',
            imageDialogTableVisible: false,
            imageList: [],
            loading: false
        }
    },
    methods: {
        ShowImages(id) {
            // console.log(id)
            // this.imageDialogTableVisible = true
            this.GetImagesByID(id)
        },
        async GetImagesByID(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        // this.imageList.push({
                        //     id: item.DB_ID,
                        //     path: conf.image_domain + item.FImgPath
                        // })
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                           path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
                console.log('this.imageList', this.imageList)
            }
        },
        minuteDiff(start, end) {
            start = moment(start).format('hh:mm:ss')
            end = moment(end).format('hh:mm:ss')
            const mstart = moment(start, 'hh:mm')
            const mend = moment(end, 'hh:mm')
            return mend.diff(mstart, 'minute')
        },
        async getSportsListData() {
            this.loading = true
            // axios.defaults.baseURL = '/api'
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrSports/SportList', qs.stringify({
                no: this.sport_no,
                type: 1
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                res.data.ResponseData.forEach(item => {
                    item.SPORT_DATE = moment(item.SPORT_DATE).format('YYYY-MM-DD')
                })
                this.sport_list = res.data.ResponseData
            }
            this.loading = false
        },
        async delSport(id) {
            this.$confirm('确定删除该记录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                customClass: 'messageClass',
            }).then(async () => {
                console.log('id', id)
                const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrSports/DelSport', qs.stringify({
                    id: id
                }))
                console.log(res)
                if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                    console.log('打卡删除成功:', res.data.ResponseData)
                    this.getSportsListData()
                    this.$message({
                        message: '打卡删除成功',
                        center: true,
                        type: 'success'
                    })
                }
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // })          
            })
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
    },
    mounted() {
        // this.sport_no = localStorage.getItem('PNL')
        // if(this.sport_no) {
        //     const loading = this.$loading({
        //         lock: true,
        //         text: '拼命加载中',
        //         spinner: 'el-icon-loading',
        //         background: 'rgba(0, 0, 0, 0.7)'
        //     })
        //     this.getSportsListData()
        //     loading.close()
        // }
        this.getSportsListData()
        this.GetSignature()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.sport_no = pnl
            // this.sport_no = '56099' // dev
        }
    },
}
</script>

<style scoped>
    .box-card{
        margin-top: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 13px;
        /* border-radius: 10px; */
    }
   
</style>