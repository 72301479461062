<template>
    <div>
        <div>{{debug}}</div>
        <el-form :model="processCheckForm" ref="processCheckForm" size="mini" label-position="left">
            <div v-for="(subject, index) in processCheckForm.subject_list" :key="index" >
                <div class="subject">{{subject.fsortno}}、{{subject.fname}}</div>
                <div v-for="(content, index) in processCheckForm.content_list" :key="index" class="content">
                    <el-form-item
                        :label="content.title" 
                        v-if="subject.fid === content.fpid" 
                        style="display: inline-block;font-weight: bold;text-align: left;">
                        <br>
                        <el-radio v-model="content.answer" label="1">正常</el-radio>
                        <el-radio v-model="content.answer" label="2">异常</el-radio>
                        <el-radio v-model="content.answer" label="3">无此项</el-radio>
                        <el-radio v-model="content.answer" label="4">复查OK</el-radio>
                    </el-form-item>
                    <el-form-item v-if="subject.fid === content.fpid">
                        <el-input v-model="content.remark" type="textarea" autosize placeholder="请输入备注"></el-input>
                    </el-form-item>
                    <el-form-item prop="image1" v-if="subject.fid === content.fpid">
                        <el-upload 
                            ref="upload"
                            :fid="content.fid"
                            :fpid="content.fpid"
                            action=""
                            :limit="3" 
                            list-type="picture"
                            :file-list="content.IMAGELIST"
                            :auto-upload="false" 
                            :on-exceed="handleExceed"
                            style="width: 100%;" >
                            <el-button style="margin-right: 20px" size="mini" slot="trigger" type="primary">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                            <el-button size="mini"  @click="GetJSAPI(content.IMAGELIST)" type="success">拍照上传<i class="el-icon-picture-outline el-icon--right"></i></el-button>
                        </el-upload>
                    </el-form-item>
                    <!-- <el-form-item v-if="subject.fid === content.fpid">
                        <el-button size="mini"  @click="GetJSAPI" type="primary">拍照上传<i class="el-icon-picture-outline el-icon--right"></i></el-button>
                    </el-form-item> -->
                </div>
            </div>
            <el-row style="margin-top: 20px;margin-bottom: 60px">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="processCheckForm.isLoading"
                        @click="SubmitFormList('processCheckForm')">提交</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
import wx from 'weixin-js-sdk'
import { compressImage } from '../../plugin/compress.js'
export default {
    data() {
        return {
            processCheckForm: {
                subject_list: [],
                content_list: [],
                isLoading: false,
                fid: '',
                sec_no: '',
                odr_no: '',
                pnl: '',
                // fileList: [],
                // imageList: [],
            },
            debug: 'debug'
        }
    },
    methods: {
        setCompressImage(file) {
            // console.log('file', file)
            if(!this.beforeAvatarUpload(file)) {
                return
            }

            let quality = 1
            let size = this.getSize(file)
            if(size > 1 && size < 2) {
                quality = 0.5
            }else if(size > 2) {
                quality = 0.2
            }

            return quality
            // compressImage(file.url, {
            //     width: 390, // 压缩后图片的宽
            //     // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
            //     quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
            //     }).then(result => { // result即为压缩后的结果
            //     // this.setModel(item.name, result, this.$refs.upload)
               
            //     console.log('result', result)
            // })
        },
        // getFile(file, fileList) {
        //     console.log('this.$refs', this.$refs)
        //     console.log('file', file)
        //     console.log('fileList', fileList)
        //     this.processCheckForm.fileList = []
        //     this.processCheckForm.imageList = []
        //     fileList.forEach(item => {
        //         this.processCheckForm.fileList.push(item.name)
        //         console.log('this.processCheckForm.fileList', this.processCheckForm.fileList)
        //         if(!this.beforeAvatarUpload(item)) {
        //             return
        //         }
        //         let quality = 1
        //         let size = this.getSize(item)
        //         if(size > 1 && size < 2) {
        //             quality = 0.5
        //         }else if(size > 2) {
        //             quality = 0.2
        //         }
        //         compressImage(item.url, {
        //             width: 390, // 压缩后图片的宽
        //             // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
        //             quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
        //             }).then(result => { // result即为压缩后的结果
        //             this.processCheckForm.imageList.push(result)
        //             console.log('this.processCheckForm.imageList', this.processCheckForm.imageList)
        //             // this.setModel(item.name, result, this.$refs.upload)
        //         })
        //     })
        // },
        // setModel(fileList, imageList, upload) {
        //     console.log('fileList', fileList)
        //     console.log('imageList', imageList)
        //     console.log('upload', upload)
        //     console.log('this.processCheckForm.content_list', this.processCheckForm.content_list)
        //     upload.map(el=> {
        //         if(el.uploadFiles.length > 0) {
        //             this.processCheckForm.content_list.forEach(item => {
        //                 if(item.fid === el.$attrs.fid) {
        //                     let IMAGELIST = []
        //                     IMAGELIST.push({
        //                         IMAGE: imageList,
        //                         FILENAME: fileList
        //                     })
        //                     item.IMAGELIST = IMAGELIST
        //                 }
        //             })
        //         }
        //     })
        // },
        // handleRemove(file, fileList) {
        //     console.log('this.$refs', this.$refs)
        //     console.log('file', file)
        //     console.log('fileList', fileList)
        //     let index = fileList.indexOf(file)
        //     if(index > -1) {
        //         fileList.splice(index, 1)
        //     }

        //     this.processCheckForm.fileList = []
        //     this.processCheckForm.imageList = []
        //     fileList.forEach(item => {
        //         this.processCheckForm.fileList.push(item.name)
        //         this.getBase64(item.raw).then(res => {
        //             this.processCheckForm.imageList.push(res)
        //         })
        //     })
        // },
        // getBase64(file) {
        //     return new Promise(function(resolve, reject) {
        //         let reader = new FileReader();
        //         let imgResult = "";
        //         reader.readAsDataURL(file);
        //         reader.onload = function() {
        //             imgResult = reader.result;
        //         }
        //         reader.onerror = function(error) {
        //             reject(error);
        //         }
        //         reader.onloadend = function() {
        //             resolve(imgResult);
        //         }
        //     })
        // },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            // const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            // if (!isLt2M) {
            //     this.$message.error('上传凭证图片大小不能超过 10MB!')
            // }

            // return isLt2M && (isJPG || isPNG)
            return isJPG || isPNG
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        },
        SubmitFormList(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CustomValid(this.processCheckForm.content_list)){
                    // console.log('this.processCheckForm.content_list', this.processCheckForm.content_list)
                    this.SaveCheckList()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        CustomValid(list) {
            let isValid = true
            list.forEach(item => {
                if(!item.answer && isValid) {
                    isValid = false
                    this.$message({
                        message: item.title + ' 必须填写！',
                        center: true,
                        type: 'error',
                        duration: 2000
                    })
                    return isValid
                }
            })    
            return isValid
        },
        SaveCheckList() {
            console.log('this.$refs', this.$refs)
            this.processCheckForm.isLoading = true
            // this.$refs.upload.map(el=> {
            //     if(el.uploadFiles.length > 0) {
            //         this.processCheckForm.content_list.forEach(item => {
            //             if(item.fid === el.$attrs.fid) {
            //                 // item.IMAGELIST = []
            //                 el.uploadFiles.map(file => {
            //                     // var temp = {}
            //                     // compressImage(file.url, {
            //                     //     width: 390, 
            //                     //     quality: this.setCompressImage(file)
            //                     //     }).then(result => { 
            //                     //         // temp = result
            //                     //     // let ImageModelDTO = {
            //                     //     //     FIMAGE: encodeURIComponent(result),
            //                     //     //     // FIMAGE: file.name,
            //                     //     //     FNAME: file.name
            //                     //     // }

            //                     //     item.IMAGELIST[0].FIMAGE = encodeURIComponent( resolve (result))
            //                     //     item.IMAGELIST[0].FNAME = file.name

            //                     //     console.log('item.IMAGELIST', item.IMAGELIST)
            //                     //     // item.IMAGELIST.push(ImageModelDTO)

            //                     //     // let IMAGELIST = []
            //                     //     // let ImageModelDTO = {
            //                     //     //     IMAGE: '',
            //                     //     //     FILENAME: ''
            //                     //     // }
            //                     //     // IMAGELIST.push(ImageModelDTO)

            //                     // })
            //                     // console.log('temp', temp)



            //                         let ImageModelDTO = {
            //                             FIMAGE: '6546456456456456456456',
            //                             FNAME: file.name
            //                         }
            //                     item.IMAGELIST.push(ImageModelDTO) 

            //                 })
            //                 // item.IMAGELIST = IMAGELIST
            //             }
            //         })
            //     }
            // })

           

            console.log('this.processCheckForm.content_list', this.processCheckForm.content_list)
            let dtos = []
            this.processCheckForm.content_list.map(item => {
                // if(item.IMAGELIST.length > 0) {
                    // console.log('来了老弟')
                    // let IMAGELIST = []
                    // item.IMAGELIST.map(el => {
                    //     let ImageModelDTO = {
                    //         IMAGE: el.IMAGE,
                    //         FILENAME: el.FILENAME
                    //     }
                    //     console.log('ImageModelDTO', ImageModelDTO)
                    //     IMAGELIST.push(ImageModelDTO)
                    // })
                    // console.log('IMAGELIST', IMAGELIST)
                    // let AleCheckresultDTO = {
                    //     CHECKFLOWID: this.processCheckForm.fid,
                    //     CHECKFLOWSID: item.fpid,
                    //     CHECKFLOWSSID: item.fid,
                    //     CHECKVALUE: item.answer === '1' ? '无异常' : '异常',
                    //     REMARKS: item.remark,
                    //     SEC_NO: this.processCheckForm.sec_no,
                    //     ODR_NO: this.processCheckForm.odr_no,
                    //     PNL: this.processCheckForm.pnl,
                    //     IMAGELIST: IMAGELIST
                    // }
                    // dtos.push(AleCheckresultDTO)
                // }
                // else {
                    // console.log('item.IMAGELIST', item.IMAGELIST)
                    let AleCheckresultDTO = {
                        CHECKFLOWID: this.processCheckForm.fid,
                        CHECKFLOWSID: item.fpid,
                        CHECKFLOWSSID: item.fid,
                        // CHECKVALUE: item.answer === '1' ? '无异常' : '异常',
                        CHECKVALUE: this.GetCheckValue(item.answer),
                        REMARKS: item.remark,
                        SEC_NO: this.processCheckForm.sec_no,
                        ODR_NO: this.processCheckForm.odr_no,
                        PNL: this.processCheckForm.pnl,
                        // IMAGELIST: item.IMAGELIST
                        IMAGELIST: this.GetImageList(item)
                    }
                    // console.log('AleCheckresultDTO', AleCheckresultDTO)
                    dtos.push(AleCheckresultDTO)
                // }
            })

            console.log('dtos', dtos)
            setTimeout(() => {
                this.PostSaveCheckList(dtos)
            }, 3000)
            // const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/SaveCheckListDataWithImages', qs.stringify({
            //     dtos
            // }))
            // console.log(res)
            // if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
            //     this.$message({
            //         message: '保存成功！',
            //         center: true,
            //         type: 'success',
            //         duration: 2000
            //     })
            //     window.location.href = '/checkList'
            // }else if(res.data.Code === 1) {
            //     this.$message({
            //         message: res.data.Message,
            //         center: true,
            //         type: 'error'
            //     })
            // }
            // this.processCheckForm.isLoading = false
        },
        GetImageList(item) {
            let IMAGELIST = []
            this.$refs.upload.map(el => {
                if(el.uploadFiles.length > 0) {
                    if(item.fid === el.$attrs.fid) {
                        el.uploadFiles.map(file => {
                            // 图片压缩
                            compressImage(file.url, {
                                width: 390, 
                                quality: this.setCompressImage(file)
                            }).then(async result => { 
                                let ImageModelDTO = {
                                    FIMAGE: encodeURIComponent(await result),
                                    FNAME: file.name
                                }
                                IMAGELIST.push(ImageModelDTO) 
                            })
                            // 图片未压缩
                            // let ImageModelDTO = {
                            //     FIMAGE: '',
                            //     FNAME: ''
                            // }
                            
                            
                            // const p1 = this.getBase64(file.raw).then(res => {
                            //     ImageModelDTO.FIMAGE = encodeURIComponent(res)
                            // })

                            // console.log('p1', p1)
                            // const p = Promise.allSettled([p1])
                            // console.log('p', p)
                            // Promise.allSettled([p1])

                            // ImageModelDTO.FNAME = file.name
                            // IMAGELIST.push(ImageModelDTO) 

                            
                        })
                    }
                }
            })

            return IMAGELIST
        },
        async PostSaveCheckList(dtos) {
            console.log('dtos', dtos)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/SaveCheckListDataWithImages', qs.stringify({
                dtos
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: '保存成功！',
                    center: true,
                    type: 'success',
                    duration: 2000
                })
                window.location.href = '/checkList'
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.processCheckForm.isLoading = false
        },
        GetCheckValue(val) {
            let check_value = ''
            if(val === '1') {
                check_value = '正常'
            }else if(val === '2') {
                check_value = '异常'
            }else if(val === '3') {
                check_value = '无此项'
            }else if(val === '4') {
                check_value = '复查OK'
            }
            return check_value
        },
        async GetCheckListData() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/CheckListData', qs.stringify({
                id: this.processCheckForm.fid
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.Table1.length > 0) {
                    this.processCheckForm.subject_list = res.data.ResponseData.Table1
                }
                if(res.data.ResponseData.Table2.length > 0) {
                    res.data.ResponseData.Table2.forEach(item => {
                        item.title = item.fsortno + '.' + item.fname
                    })
                    this.processCheckForm.content_list = res.data.ResponseData.Table2
                }

                this.processCheckForm.content_list.forEach(item => {
                    // let IMAGELIST = []
                    // let ImageModelDTO = {
                    //     FIMAGE: '123',
                    //     FNAME: '321'
                    // }
                    // IMAGELIST.push(ImageModelDTO)
                    // item.IMAGELIST = IMAGELIST
                    item.IMAGELIST = []
                })
                console.log('this.processCheckForm.content_list', this.processCheckForm.content_list)
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['chooseImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
        async GetJSAPI(imageList) {
            this.ChooseImage(imageList)
            this.debug = '1'
        },
        async ChooseImage(imageList) {
            wx.chooseImage({
                count: 1, // 默认9
                sizeType: ['compressed'], // sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                sourceType: ['camera'], // sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                success: res => {
                    var localIds = res.localIds // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                    this.GetLocalImage(localIds[0])
                    imageList.push({
                        name: '1',
                        url: localIds[0]
                    })
                    this.debug = '2'
                }
            })
        },
        async GetLocalImage(localId) {
            wx.getLocalImgData({
                localId: localId, // 图片的localID
                success: res => {
                    var localData = res.localData // localData是图片的base64数据，可以用img标签显示
                    // 测试图片大小
                    let size = this.getSize(localData)
                    this.$message({
                        message: size + ' M',
                        center: true,
                        type: 'error'
                    })
                    this.debug = '3'
                }
            })
        },
    },
    mounted() {
        const id = this.$route.params.id
        if(id) {
            this.processCheckForm.fid = id
            this.GetCheckListData()
        }
        const sec_no = this.$route.params.sec
        if(sec_no) {
            this.processCheckForm.sec_no = sec_no
        }
        const odr_no = this.$route.params.odr
        if(odr_no) {
            this.processCheckForm.odr_no = decodeURIComponent(odr_no)
        }
        this.GetSignature()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.processCheckForm.pnl = pnl
        }
    }
}
</script>

<style scoped>
.subject{
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
    color: #409EFF;
}
.content{
    text-align: left;
    /* font-size: 14px; */
    word-wrap: break-word;
}
</style>