<template>
    <div>
        <!-- <el-alert
            type="error"
            v-if="!canBorrow"
            show-icon>
            <template slot='title'>
                <div class="iconSize">没有访问权限！</div>
            </template>
        </el-alert> -->
        <!-- <div v-if="canBorrow"> -->
        <el-table :data="barCodeData" size="small" :show-header="false">
            <el-table-column prop="label" label="样品编号" width="90px">
            </el-table-column>
            <el-table-column prop="value">
            </el-table-column>
            <el-table-column align="right" width="90px">
                <el-button 
                    size="small" 
                    type="primary" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI">扫码</el-button>
            </el-table-column>
        </el-table>
        <el-table :data="sampleData" size="small" :show-header="false" v-loading="table_loading" >
            <el-table-column prop="label" width="90px">
            </el-table-column>
            <el-table-column prop="value">
            </el-table-column>
        </el-table>
        <el-row style="margin-top: 10px;margin-bottom: 20px" v-if="canBorrow">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    :loading="isLoading"
                    @click="ConfirmBorrowReturn(0)">样品借用</el-button>
            </el-col>
        </el-row>
        <el-row style="margin-top: 10px;margin-bottom: 20px" v-if="canReturn">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    :loading="isLoading"
                    @click="ConfirmBorrowReturn(1)">样品归还</el-button>
            </el-col>
        </el-row>
        <el-dialog :visible.sync="borrowDialogVisible" size="mini" width="90%">
            <span class="dialog-footer" style="font-weight: 550;color: #409EFF;">
                您确认{{ dialogStr }}样品【 <span style="color: #F56C6C;">{{ barCodeData[0].value }}</span> 】？
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="borrowDialogVisible = false">关闭</el-button>
                <el-button type="primary" plain :loading="isLoading2" size="mini" @click="SampleBorrowReturn(3)" v-if="canBorrow && isdev">借出（不归还）</el-button>
                <el-button type="primary" :loading="isLoading2" size="mini" @click="SampleBorrowReturn(2)" v-if="canBorrow">借出（需归还）</el-button>
                <el-button type="primary" :loading="isLoading2" size="mini" @click="SampleBorrowReturn(1)" v-if="canReturn">归还</el-button>
            </span>
        </el-dialog>
        <!-- </div> -->
        <el-collapse v-model="activeNames">
            <el-collapse-item name="1">
                <template slot="title">
                    <div style="font-weight: bold;font-size: 15px">样品借出归还记录</div>
                </template>
                <el-row :gutter="10" style="margin: 0px">
                    <el-col :span="12">
                        <el-date-picker
                            size="small"
                            v-model="date1"
                            align="right"
                            type="date"
                            @change="dateChange"
                            placeholder="选择日期"
                            style="width: 100%"
                            value-format="yyyy-MM-dd"
                            >
                        </el-date-picker>
                    </el-col>
                    <el-col :span="12">
                        <el-date-picker
                            size="small"
                            v-model="date2"
                            align="right"
                            type="date"
                            @change="dateChange"
                            placeholder="选择日期"
                            style="width: 100%"
                            value-format="yyyy-MM-dd"
                            >
                        </el-date-picker>
                    </el-col>
                </el-row>
                <el-table :data="sampleShoesIoData" size="small" style="width: 100%;margin-top: 5px"
                :header-cell-style="{fontWeight: '550', color: '#409EFF', fontSize: '13px'}" >
                    <el-table-column prop="odr_no" label="日期 / 借还类型 / 货号 / 条形码 / 工号 / 姓名 / 电话 / 部门">
                    </el-table-column>
                </el-table>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>
<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import wx from 'weixin-js-sdk'
import moment from 'moment'
export default {
    data() {
        return {
            barCodeData: [{
                label: '样品单号',
                value: ''
            }],
            sampleData: [],
            canBorrow: false,
            canReturn: false,
            isLoading: false,
            borrowDialogVisible: false,
            isLoading2: false,
            dialogStr: '借出',
            isdev: false,
            isborrow: false,
            table_loading: false,
            activeNames: ['0'],
            date1: moment().format('YYYY-MM-DD'),
            date2: moment().format('YYYY-MM-DD'),
            sampleShoesIoData: [],
            ioClass: ''
        }
    },
    methods: {
        dateChange() {
            if(this.date1 && this.date2) {
                this.SampleShoesList()
            }
        },
        async SampleShoesList() {
            this.sampleShoesIoData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AleKfSamples/SampleShoesList', qs.stringify({
                pnl: this.pnl,
                ioClass: this.ioClass,
                date1: this.date1,
                date2: this.date2 
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.forEach(item => {
                    this.sampleShoesIoData.push({
                        odr_no: moment(item.iod).format('MM-DD HH:mm') + ' / ' + item.class_name + ' / ' +  item.sstyle + ' / ' 
                        + item.bar_code + ' / ' + item.pnl + ' / ' + item.pname + ' / ' + item.tel + ' / ' + item.sec_nm,
                        // part_nm: item.part_nm + ' / ' + item.osize + ' / ' + item.qty,
                    })
                })
            }
        },
        ConfirmBorrowReturn(id) {
            this.dialogStr = id === 1 ? '归还' : '借出'
            this.borrowDialogVisible = true
        },
        async SampleBorrowReturn(status) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AleKfSamples/SampleBorrowReturn', qs.stringify({
                pnl: this.pnl,
                bar_code: this.barCodeData[0].value,
                status
            }))
            console.log(res)
            this.borrowDialogVisible = false
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$message({
                    message: '样品借用成功',
                    center: true,
                    type: 'success'
                })
                //
                this.GetBorrowRight() 
                this.GetSampleInfo(this.barCodeData[0].value) 
                //
                this.SampleShoesList()
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        GetJSAPI() {
            // 测试
            // this.barCodeData[0].value = 'HQ20240100018' // dev
            // this.barCodeData[0].value = 'HQ20240100019' // dev
            // this.barCodeData[0].value = 'HQ20240100018' // dev
            // this.table_loading = true // dev
            // this.GetBorrowRight() // dev
            // this.GetSampleInfo(this.barCodeData[0].value) // dev
            // this.table_loading = false // dev
            
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    this.barCodeData[0].value = result
                    this.table_loading = true 
                    this.GetBorrowRight() 
                    this.GetSampleInfo(this.barCodeData[0].value) 
                    this.table_loading = false 
                }
            })
        },
        async GetSampleInfo() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AleKfSamples/GetSampleInfo', qs.stringify({
                bar_code: this.barCodeData[0].value
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.isdev = this.pnl === res.data.ResponseData[0].devMan ? true : false
                console.log('this.isdev', this.isdev)
                this.canReturn = res.data.ResponseData[0].status === '2' && this.isdev ? true : false
                console.log('this.canReturn', this.canReturn)
                this.canBorrow = res.data.ResponseData[0].status === '1' && this.isborrow ? true : false
                console.log('this.canBorrow', this.canBorrow)
                this.sampleData = []
                res.data.ResponseData.forEach(item => {
                    this.sampleData.push({
                        label: '款式',
                        value: item.sstyle
                    })
                    this.sampleData.push({
                        label: '颜色',
                        value: item.scolor
                    })
                    this.sampleData.push({
                        label: '样品类型',
                        value: item.stype
                    })
                    this.sampleData.push({
                        label: '品牌',
                        value: item.scus_nm
                    })
                    this.sampleData.push({
                        label: '样品SIZE',
                        value: item.ssize
                    })
                    this.sampleData.push({
                        label: '样鞋状态',
                        value: item.status_name
                    })
                    if(item.status === '2' || item.status === '3'){
                        this.sampleData.push({
                            label: '借用人',
                            value: item.jy_username
                        })
                        this.sampleData.push({
                            label: '电话',
                            value: item.tel
                        })
                    }
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetBorrowRight() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AleKfSamples/GetBorrowRight', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                if(res.data.ResponseData.length > 0) {
                    this.isborrow = true
                }
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }else {
                this.canBorrow = false
            }
        }
    },
    mounted() {
        this.GetSignature()
        this.SampleShoesList()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            // this.pnl = '55129' // dev
            // this.pnl = '47975' // dev
            // this.pnl = '55129' // dev
            this.GetBorrowRight()
        }
    }
}
</script>