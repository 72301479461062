<template>
    <div>
        <el-table
            :data="tableData"
            size="medium"
            style="width: 100%">
            <el-table-column type="expand">
            <template slot-scope="props">
                <el-form label-position="left" inline class="demo-table-expand">
                    <el-form-item label="个人缴费比例">
                        <span>{{ props.row.pnl_pay * 100 }}%</span>
                    </el-form-item>
                    <el-form-item label="个人缴费金额">
                        <span>{{ props.row.pnl_amt }}</span>
                    </el-form-item>
                    <el-form-item label="投保日">
                        <span>{{ props.row.b_date }}</span>
                    </el-form-item>
                </el-form>
            </template>
            </el-table-column>
            <el-table-column label="编号" prop="safe_no"></el-table-column>
            <el-table-column label="保障项目" prop="safe_nm"></el-table-column>
            <el-table-column label="缴费基数" prop="safe_amt"></el-table-column>
        </el-table>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import moment from 'moment'
export default {
    data() {
        return {
            pnl: '',
            tableData: []
        }
    },
    methods: {
        async GetGuaranteeList() {
            this.tableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Welfare/GuaranteeList', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                res.data.ResponseData.forEach(item => {
                    if(item.safe_amt) {
                        this.tableData.push({
                            safe_no: item.safe_no,
                            safe_nm: item.safe_nm,
                            safe_amt: item.safe_amt === null ? '' : '￥' + item.safe_amt,
                            pnl_pay: item.pnl_pay,
                            pnl_amt: item.pnl_amt === null ? '' : '￥' + item.pnl_amt,
                            b_date: moment(item.b_date).format('YYYY-MM-DD'),
                        })
                    }
                })
            }
        }
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
        }
        this.GetGuaranteeList()
    }
}
</script>

<style>
    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 100px;
        color: #99a9bf;
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 100%;
    }
</style>