<template>
    <div>
        <el-form :model="InfoChangeForm" ref="InfoChangeForm" :rules="rules" size="small" label-width="100px">
            <el-form-item prop="ads3" label="现居住地" style="font-weight: bold">
                <el-input v-model="InfoChangeForm.ads3"></el-input>
            </el-form-item>
            <el-form-item prop="post_no" label="邮政编码" style="font-weight: bold">
                <el-input v-model="InfoChangeForm.post_no"></el-input>
            </el-form-item>
            <el-form-item prop="email" label="EMAIL" style="font-weight: bold">
                <el-input v-model="InfoChangeForm.email"></el-input>
            </el-form-item>
            <el-form-item prop="tel" label="联系电话" style="font-weight: bold">
                <el-input v-model="InfoChangeForm.tel"></el-input>
            </el-form-item>
            <el-form-item prop="notifyMan" label="紧急联系人" style="font-weight: bold">
                <el-input v-model="InfoChangeForm.notifyMan"></el-input>
            </el-form-item>
            <el-form-item prop="notifyCW" label="联系人称谓" style="font-weight: bold">
                <el-input v-model="InfoChangeForm.notifyCW"></el-input>
            </el-form-item>
            <el-form-item prop="notifyTel" label="联系人电话" style="font-weight: bold">
                <el-input v-model="InfoChangeForm.notifyTel"></el-input>
            </el-form-item>
            <el-form-item prop="jsr" label="介绍人" style="font-weight: bold">
                <el-input v-model="InfoChangeForm.jsr"></el-input>
            </el-form-item>
        </el-form>
        <el-row>
            <el-col :span="24" style="margin-top: 10px;margin-bottom: 20px">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    :loading="isLoading"
                    @click="submitForm('InfoChangeForm')">提交修改申请</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
// import moment from 'moment'
export default {
    data() {
        return {
            DetailInfoOld: [],
            InfoChangeForm: {
                ads3: '',
                post_no: '',
                tel: '',
                email: '',
                notifyMan: '',
                notifyCW: '',
                notifyTel: '',
                jsr: '',
                pnl: ''
            },
            isLoading: false,
            rules: {
                tel: [
                    {required: true, message: '联系电话必须填写', trigger: 'blur'}
                ],
                notifyMan: [
                    {required: true, message: '紧急联系人必须填写', trigger: 'blur'}
                ],
                notifyCW: [
                    {required: true, message: '紧急联系人称谓必须填写', trigger: 'blur'}
                ],
                notifyTel: [
                    {required: true, message: '紧急联系人电话必须填写', trigger: 'blur'}
                ],
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid ){
                    console.log(this.InfoChangeForm)
                    this.SaveDetailInfo()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveDetailInfo() {
            this.isLoading = true
            console.log('this.DetailInfoOld_2', this.DetailInfoOld)
            const dto = {
                ADS3: this.DetailInfoOld[0].ADS3,
                POST_NO: this.DetailInfoOld[0].POST_NO,
                TEL: this.DetailInfoOld[0].TEL,
                EMAIL: this.DetailInfoOld[0].EMAIL,
                NOTIFYMAN: this.DetailInfoOld[0].NOTIFYMAN,
                NOTIFYCW: this.DetailInfoOld[0].NOTIFYCW,
                NOTIFYTEL: this.DetailInfoOld[0].NOTIFYTEL,
                JSR: this.DetailInfoOld[0].JSR,
                ADS3_NEW: this.InfoChangeForm.ads3,
                POST_NO_NEW: this.InfoChangeForm.post_no,
                TEL_NEW: this.InfoChangeForm.tel,
                EMAIL_NEW: this.InfoChangeForm.email,
                NOTIFYMAN_NEW: this.InfoChangeForm.notifyMan,
                NOTIFYCW_NEW: this.InfoChangeForm.notifyCW,
                NOTIFYTEL_NEW: this.InfoChangeForm.notifyTel,
                JSR_NEW: this.InfoChangeForm.jsr,
                PNL: this.InfoChangeForm.pnl
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EhrPersonBaseChanges/SaveDetailInfoChange', qs.stringify({
                dto,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: '档案修改申请成功',
                    center: true,
                    type: 'success'
                })
                setTimeout(function(){
                    window.location.href = '/detailInfo'
                },2000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '档案修改申请失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '档案修改申请失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async GetDetailInfo() {
            this.DetailInfoOld = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsPersons/DetailInfo', qs.stringify({
                pnl: this.InfoChangeForm.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                if(res.data.ResponseData.length > 0) {
                    this.InfoChangeForm = res.data.ResponseData[0]
                    res.data.ResponseData.map(item => {
                        this.DetailInfoOld.push({
                            ADS3: item.ads3,
                            POST_NO: item.post_no,
                            TEL: item.tel,
                            EMAIL: item.email,
                            NOTIFYMAN: item.notifyMan,
                            NOTIFYCW: item.notifyCW,
                            NOTIFYTEL: item.notifyTel,
                            JSR: item.jsr,
                        })
                    })
                }
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        }
    },
    mounted() {
        this.GetDetailInfo()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.InfoChangeForm.pnl = pnl
        }
    }
}
</script>