<template>
    <div>
        <el-table :data="cutNoData" size="mini" :show-header="false" style="margin-bottom: 20px">
            <el-table-column prop="label" label="单号" width="80px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column>
            <el-table-column align="right" width="90px">
                <el-button 
                    size="small" 
                    type="primary" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI">扫码</el-button>
            </el-table-column>
        </el-table>
        <el-table :data="tableData" size="mini" border style="margin-bottom: 20px">
             <!-- <el-table-column prop="label" width="80px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column> -->
            <el-table-column prop="part_no" label="部件编号" width="100px">
                <template slot-scope="scope">
                    <div>{{scope.row.part_no}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="part_nm" label="部件名称">
                <template slot-scope="scope">
                    <div>{{scope.row.part_nm}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="qty" label="数量" width="70px">
                <template slot-scope="scope">
                    <div>{{scope.row.qty}}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="dialogTableVisible" size="mini" width="90%" :show-close="false">
            <span>
                <div style="font-weight: bold;text-align: center;font-size: 14px;color: #F56C6C;">该预派工信息已提交，是否更新派工日期?</div>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogTableVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="SavePreDispatch">确定</el-button>
            </span>
        </el-dialog>
        <el-form :model="dispatchForm" :rules="rules" ref="dispatchForm" label-width="80px" size="small" v-if="this.cutNoData[0].value">
            <el-form-item label="派工部门" style="font-weight: bold" prop="sec_no">
                <!-- <el-input v-model="dispatchForm.sec_nm" placeholder="请输入所在部门名称"></el-input> -->
                <el-select 
                    v-model="dispatchForm.sec_no" 
                    placeholder="请选择派工部门" 
                    size="medium" 
                    style="width: 100%">
                    <el-option
                        v-for="item in PerDispatchSecs"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="派工日期" style="font-weight: bold" prop="pre_date">
                <el-date-picker
                    v-model="dispatchForm.pre_date"
                    size="medium"
                    type="date"
                    style="width: 100%"
                    placeholder="选择提报日期">
                </el-date-picker>
            </el-form-item>
            <el-row style="margin-top: 20px;">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        size="medium"
                        :loading="isLoading"
                        :disabled="tableData.length <= 0"
                        @click="submitForm('dispatchForm')">提交预派工</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-collapse v-model="activeNames" style="margin-top: 30px;">
            <el-collapse-item name="1">
                <template slot="title">
                    <div style="font-weight: bold">上传记录</div>
                </template>
                <el-table :data="preDispatchData" size="mini" style="width: 100%">
                    <el-table-column prop="csend_no" label="派工单号" width="130px">
                    </el-table-column>
                    <el-table-column prop="odr_no" label="指令单号">
                    </el-table-column>
                    <el-table-column prop="qty" label="总数量" width="70px">
                    </el-table-column>
                </el-table>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import moment from 'moment'
import qs from 'qs'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            cutNoData:[{
                label: '单号',
                value: ''
            }],
            tableData:[],
            preDispatchData: [],
            activeNames: ['0'],
            dispatchForm: {
                // pnl: '', 
                pname: '',
                sec_no: '',
                // sec_nm: '',
                pre_date: moment().format('YYYY-MM-DD'),
            },
            isLoading: false,
            dialogTableVisible: false,
            PerDispatchSecs: [],
            rules: {
                sec_no: [
                    {required: true, message: '请选择部门名称', trigger: 'blur'}
                ],
                pre_date: [
                    {required: true, message: '请选择提报提起', trigger: 'change'}
                ]
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && !this.CheckPreDispatch()){
                    console.log(this.dispatchForm)
                    // this.dialogTableVisible = false
                    // this.SavePreDispatch()
                }else{
                    // this.dialogTableVisible = true
                    console.log(valid.message)
                    return false
                }
            })
        },
        async CheckPreDispatch() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PerDispatch/PerDispatchCount', qs.stringify({
                csend_no: this.cutNoData[0].value, 
                // odr_no: this.code_values[0], 
                // sch_no: this.code_values[1],
                // part_no: this.code_values[2]
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log('res.data.ResponseData[0].Column1', res.data.ResponseData[0].Column1)
                if(res.data.ResponseData[0].Column1 > 0) {
                    this.dialogTableVisible = true
                } else {
                    this.SavePreDispatch()
                }
            }else if(res.data.Code === 1) {
                this.$message({
                    message: '派单提交：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async SavePreDispatch() {
            this.dialogTableVisible = false
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PerDispatch/SavePerDispatch', qs.stringify({
                pre_date: this.dispatchForm.pre_date,
                sec_no: this.dispatchForm.sec_no, 
                csend_no: this.cutNoData[0].value, 
                pre_man: this.dispatchForm.pname
                // odr_no: this.code_values[0], 
                // sch_no: this.code_values[1],
                // part_no: this.code_values[2]
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: '派单提交:' + res.data.Message,
                    center: true,
                    type: 'success'
                })

                // setTimeout(function(){
                //     window.location.href = '/preDispatch'
                // },1000)

                this.getDispatchData()
                this.tableData = []
                this.cutNoData[0].value = ''
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        GetJSAPI() {
            // 测试数据
            // let result = "QAS/2103010-001;02;BW-A005-CV;前鞋眼片内;6.5;58;HPA20210413742" // dev
            // this.setTableValue(result) // dev
            // this.cutNoData[0].value = 'HPA20200815449' // dev

            // this.cutNoData[0].value = 'HPA20210100563' // dev
            // this.cutNoData[0].value = 'HPA20211114393' // dev
            // this.getPreDispatchData()
            // this.getDispatchData()

            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    // this.setTableValue(result)
                    this.cutNoData[0].value = result 
                    this.getPreDispatchData()
                    this.getDispatchData()
                }
            })
        },
        // setTableValue(code_str) {
        //     this.code_values = code_str.split(';')
        //     console.log(this.code_values)
        //     this.cutNoData[0].value = this.code_values[6]
        //     this.tableData = []
        //     this.tableData.push({
        //         label: '指令单号',
        //         value: this.code_values[0]
        //     })
        //     this.tableData.push({
        //         label: '指令批号',
        //         value: this.code_values[1]
        //     })
        //     // this.tableData.push({
        //     //     label: '部件编号',
        //     //     value: this.code_values[2]
        //     // })
        //     this.tableData.push({
        //         label: '部件名称',
        //         value: this.code_values[3]
        //     })
        //     this.tableData.push({
        //         label: '指令SIZE',
        //         value: this.code_values[4]
        //     })
        //     this.tableData.push({
        //         label: '数量',
        //         value: this.code_values[5]
        //     })
        // },
        async getPreDispatchData() {
            this.tableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PerDispatch/GetPerDispatch', qs.stringify({
                csend_no: this.cutNoData[0].value, 
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // this.preDispatchData = []
                // res.data.ResponseData.map(item => {
                //     this.preDispatchData.push({
                //         odr_no: item.odr_no,
                //         sch_no: item.sch_no,
                //         part_no: item.part_no
                //     })
                // })
                // this.activeNames = ['1']

                res.data.ResponseData.map(item => {
                    this.tableData.push({
                        part_no: item.part_no,
                        part_nm: item.part_nm,
                        qty: item.qty
                    })
                })
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async getDispatchData() {
            this.preDispatchData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PerDispatch/GetDispatch', qs.stringify({
                pre_man: this.dispatchForm.pname
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                res.data.ResponseData.map(item => {
                    this.preDispatchData.push({
                        csend_no: item.csend_no,
                        odr_no: item.odr_no,
                        qty: item.qty
                    })
                })
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetPerDispatchSecs() {
            this.PerDispatchSecs = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PerDispatch/GetPerDispatchSecs')
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                res.data.ResponseData.forEach(item => {
                    this.PerDispatchSecs.push({
                        label: item.sec_nm,
                        value: item.sec_no
                    })
                })
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        this.GetSignature() // prod
        this.GetPerDispatchSecs()
    },
    created() {
        const pname = localStorage.getItem('PNAME')
        if(pname) {
            this.dispatchForm.pname = pname
        }
        // const pnl = localStorage.getItem('PNL')
        // if(pnl) {
        //     this.dispatchForm.pnl = pnl
        // }
        // const sec_no = localStorage.getItem('SEC_NO')
        // if(sec_no) {
        //     this.dispatchForm.sec_no = sec_no
        // }
        // const sec_nm = localStorage.getItem('SEC_NM')
        // if(sec_nm) {
        //     this.dispatchForm.sec_nm = sec_nm
        // }

        // 测试数据
        // this.dispatchForm.pnl = '10397' // dev
    }
}
</script>