<template>
    <div>
        <!-- <el-table :data="tableData" style="width: 100%" size="mini" fit :show-header="false">
            <el-table-column label="泉州寰球鞋服有限公司">
                <el-table-column label="泉州寰球鞋服有限公司">
                <el-table-column label="修订次"></el-table-column>
                <el-table-column label="修订日期"></el-table-column>
                <el-table-column label="保存期限"></el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column label="文件编号">
                <el-table-column label="启用日期">
                    <el-table-column label="NO:"></el-table-column>
                </el-table-column>
            </el-table-column>
        </el-table> -->
        <!-- <el-row>
            <el-col :span= "18" class="col-border">
                泉州寰球鞋服有限公司
            </el-col>
            <el-col :span="6" class="col-border">
                文件编号
            </el-col>
        </el-row> -->

        <el-table 
            :data="tableData" 
            style="width: 100%" 
            size="mini" 
            fit 
            :show-header="false" border 
            :span-method="arraySpanMethod">
            <el-table-column prop="col1">
                <template slot-scope="scope">
                    <div><span class="table-span">{{scope.row.col1.label}}</span>{{scope.row.col1.value}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="col2">
                <template slot-scope="scope">
                    <div><span class="table-span">{{scope.row.col2.label}}</span>{{scope.row.col2.value}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="col3">
                <template slot-scope="scope">
                    <div><span class="table-span">{{scope.row.col3.label}}</span>{{scope.row.col3.value}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="col4">
                <template slot-scope="scope">
                    <div><span class="table-span">{{scope.row.col4.label}}</span>{{scope.row.col4.value}}</div>
                </template>
            </el-table-column>
        </el-table>
        <div><h3>请求解除劳动关系通知单</h3></div>
        <div class="table-border">
            <div class="table-content">本人姓名<span class="content-underline"> {{pname}} </span>，现为<span class="content-underline"> {{sec_nm}} </span>部门员工，申请与泉州寰球鞋服有限公司解除劳动合同关系，请公司人力资源部给予发放【解除劳动关系合同确认书】，办理离职相关手续。</div>
            <div>
                <el-row class="content-row" :gutter="10">
                    <el-col :span="5">
                        申请人：<br>日期：
                    </el-col>
                    <el-col :span="10">
                        <img v-if="sign_value" class="view-image" :src="sign_value" width="100%" height="50">
                    </el-col>
                    <el-col :span="9">
                        收件人：<br>日期：2021-07-28
                    </el-col>
                </el-row>
            </div>
        </div>
        <el-row style="margin-top: 10px;">
            <el-col :span="24">
                <sign-canvas class="sign-canvas" ref="SignCanvas" :options="options" v-model="sign_value"/>
            </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top: 20px;">
            <el-col :span="12">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    size="small"
                    @click="canvasClear">清空签名</el-button>
            </el-col>
            <el-col :span="12">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    size="small"
                    :loading="isLoading"
                    @click="saveAsImg">保存签名</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import SignCanvas from 'sign-canvas'
export default {
    components: {
        SignCanvas
    },
    data() {
        return {
            tableData: [],
            pname: '',
            sec_nm: '',
            sign_value: null,
            options:{
                isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
                isShowBorder: false, //是否显示边框 [可选]
                canvasHeight: 160,
                canvasWidth: 320,
                bgColor: '#DCDFE6'
            },
            isLoading: false
        }
    },
    methods: {
        initTable() {
            this.tableData.push({
                col1: {
                    label: '泉州寰球鞋服有限公司',
                    value: ''
                },
                col2: {
                    label: '文件编号：',
                    value: 'QR-ZD-72'
                },
                col3: {
                    label: '',
                    value: ''
                },
                col4: {
                    label: '',
                    value: ''
                }
            })
            this.tableData.push({
                col1: {
                    label: '泉州寰球鞋服有限公司',
                    value: ''
                },
                col2: {
                    label: '启用日期：',
                    value: '2017.06.26'
                },
                col3: {
                    label: '',
                    value: ''
                },
                col4: {
                    label: '',
                    value: ''
                }
            })
            this.tableData.push({
                col1: {
                    label: '修订次：',
                    value: 0
                },
                col2: {
                    label: '修订日期：',
                    value: 0
                },
                col3: {
                    label: '保存期限：',
                    value: '2年'
                },
                col4: {
                    label: 'NO：',
                    value: ''
                }
            })
        },
        arraySpanMethod({ rowIndex, columnIndex }) {
            // console.log('row', row)
            // console.log('column', column)
            // console.log('rowIndex', rowIndex)
            // console.log('columnIndex', columnIndex)
            if (rowIndex === 0 || rowIndex === 1) {
                if (columnIndex === 0) {
                    return [1, 3]
                } else if (columnIndex === 3){
                    return [0, 0]
                }
            }
        },
        canvasClear(){
            this.$refs.SignCanvas.canvasClear()
        },
        saveAsImg(){
            const img = this.$refs.SignCanvas.saveAsImg()
            alert(`image 的base64：${img}`)
        },
    },
    created() {
        const pname = localStorage.getItem('PNAME')
        if(pname) {
            this.pname = pname
        }
        const sec_nm = localStorage.getItem('SEC_NM')
        if(sec_nm) {
            this.sec_nm = sec_nm
        }

        this.initTable()
    }
}
</script>

<style scoped>
    .table-border {
        border: solid 1px #EBEEF5;
        padding: 30px 5px 30px 5px;
    }
    .table-content {
        font-size: 13px;
        font-weight: bold;
        text-align: left;
        line-height: 23px;
        text-indent: 26px;
    }
    .content-underline {
        border-bottom: solid 1px;
    }
    .content-row {
        margin-top: 20px;
        font-size: 13px;
        font-weight: bold;
        text-align: left;
        line-height: 23px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .col-border {
        border: solid 1px;
    }
    .table-span {
        font-weight: 900;
        font-size: 13px;
    }
    .sign-canvas{
        display: block;
        /* margin: 0 auto; */
        /* height: 200px; */
    }
    .view-image{
        display: block;
        /* margin: 20px auto; */
    }
</style>