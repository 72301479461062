<template>
    <div>
        <el-form :model="codeForm" :rules="rules" ref="codeForm" label-width="80px">
            <el-form-item label="手机号码" prop="phoneNumber">
                <el-input v-model="codeForm.phoneNumber" placeholder="请输入手机号码">
                </el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="phoneCode">
                <el-input v-model="codeForm.phoneCode" placeholder="请输入验证码">
                    <el-button slot="append" :loading="codeForm.isLoading" @click="getCode">获取验证码</el-button>
                </el-input>
            </el-form-item>
            <el-alert
                type="success"
                v-if="codeForm.isShowSuccess"
                show-icon>
                <template slot='title'>
                    <div class="iconSize">验证码已发送，请在手机查收！</div>
                </template>
            </el-alert>
            <br>
            <el-row>
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        @click="submitForm('codeForm')">登录</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
// import sendSMS from '../../plugin/alisms'
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
export default {
    data() {
        return {
            codeForm: {
                phoneNumber: '',
                phoneCode: '',
                isLoading: false,
                isShowSuccess: false
            },
            CAPTCHA: '',
            rules: {
                phoneNumber: [
                    {required: true, message: '请输入手机号码', trigger: 'blur'}
                ],
                phoneCode: [
                    {required: true, message: '请输入验证码', trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.codeForm)
                    this.CheckCode()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async getTokenByOpenId(openid) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxTokens/TokenByOpenId', qs.stringify({
                openid: openid,
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                const token = res.data.ResponseData
                console.log('token', token)
                return token
            }
        },
        async getAccessToken() {
            this.getAccessCode()
        },
        async getAccessCode() {
            const state = ''
            const scope = 'snsapi_userinfo'
            const redirectUrl = encodeURIComponent(conf.server_url + '/gateway/weixinapi/TokenAuthorize/WeiXinAuth')
            console.log('redirectUrl:', redirectUrl)
            const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${conf.weixinInfo.appid}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
            console.log('url:' + url)
            window.location.href = url
        },
        async checkAccessToken(access_token, openid) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/CheckToken', qs.stringify({
                access_token: access_token,
                openid: openid,
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK' 
                && res.data.ResponseData.errcode === 0 && res.data.ResponseData.errmsg === 'ok') {
                return true
            }else {
                return false
            }
        },
        async getUserInfo(access_token, openid) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/UserInfo', qs.stringify({
                access_token: access_token,
                openid: openid,
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
            }
        },
        async getCode() {
            if(!this.codeForm.phoneNumber) {
                this.$message({
                    message: '请输入手机号码',
                    center: true,
                    type: 'error'
                })
                return
            }
            this.codeForm.isLoading = true
            // const res = await sendSMS(this.codeForm.phoneNumber)
            // console.log(res)
            // var params = {
            //     "code": "1234"
            // }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PersonNewInfo/SendSMS', qs.stringify({
                accessKeyId: conf.smsInfo.accessKeyId,
                accessSecret: conf.smsInfo.accessKeySecret,
                phoneNumber: this.codeForm.phoneNumber,
                signName: conf.smsInfo.signName,
                templateCode: conf.smsInfo.templateCode,
                // code: JSON.stringify(params)
            }))
            console.log(res)
            this.codeForm.isLoading = false
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK' && res.data.ResponseData.Code !== 'OK') {
                this.$message({
                    message: '验证码获取失败：' + this.codeForm.phoneNumber + ' 不是有效电话号码',
                    center: true,
                    type: 'success'
                })
            }else if(res.data.Code === 0  && res.status === 200 && res.statusText === 'OK' && res.data.ResponseData.Code === 'OK') {
                this.codeForm.isShowSuccess = true
                localStorage.setItem('PHONE', this.codeForm.phoneNumber)
                this.CAPTCHA = res.data.ResponseData.CAPTCHA
                console.log('this.CAPTCHA', this.CAPTCHA)
            }
        },
        CheckCode() {
            if(this.CAPTCHA === this.codeForm.phoneCode) {
                this.$message({
                    message: '登录成功！',
                    center: true,
                    type: 'success'
                })
                setTimeout(function(){
                    window.location.href = '/personNewInfo'
                },1000)
            }
        }
    },
    async created() {
        // const openid = localStorage.getItem('openid')
        // if(openid) {
        //     const token = await this.getTokenByOpenId(openid)
        //     if(token) {
        //         // 检查access_token是否有效
        //         if(this.checkAccessToken(token.access_token, openid)) {
        //             this.getUserInfo(token.access_token, openid)
        //         }else{ // access_token 无效
        //             // access_token 是否过期

        //             // 过期刷新

        //             //重新获取 access_token
        //             this.getAccessToken()
        //         }
        //     }
        // }else {
        //     this.getAccessToken()
        // }
    }
}
</script>