<template>
    <div> 
        <el-row :gutter="20" style="margin-bottom: 20px">
            <el-col :span="12">
                <wx-open-launch-weapp
                    id="launch-btn"
                    username="gh_52a64f13516d">
                    <script type="text/wxtag-template">
                        <style>.btn { margin-bottom: 20px }</style>
                        <el-button class="btn" style="width: 100%;" type="default" size="small">健康码</button>
                    </script>
                </wx-open-launch-weapp>
            </el-col>
            <el-col :span="12">
                <wx-open-launch-weapp
                    id="launch-btn"
                    username="gh_0ed5d82fd775">
                    <script type="text/wxtag-template">
                        <style>.btn { margin-bottom: 20px }</style>
                        <el-button class="btn" style="width: 100%;" type="default" size="small">行程码</button>
                    </script>
                </wx-open-launch-weapp>
            </el-col>
        </el-row>
        <el-form :model="healthyForm" :rules="rules" ref="healthyForm" label-width="80px" size="small">
            <el-descriptions class="margin-top" :column="1" size="small" border>
                <el-descriptions-item>
                    <template slot="label">
                    <i class="el-icon-user"></i>
                        个人信息
                    </template>
                    {{sec_nm}} - {{healthyForm.pname}}
                    </el-descriptions-item>
                    <!-- <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-date"></i>
                        提报日期
                    </template>
                    {{hdate}}
                </el-descriptions-item> -->
            </el-descriptions>
            <el-form-item label="提报日期" prop="hdate" style="margin-top: 20px;font-weight: bold">
                <el-date-picker
                    disabled
                    v-model="healthyForm.hdate"
                    align="center"
                    type="date"
                    style="width: 100%"
                    format="yyyy-MM-dd"
                    placeholder="请选择提报日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="是否发热" prop="is_hot" style="text-align: left;font-weight: bold">
                <!-- <el-radio v-model="healthyForm.is_hot" :label="false">否</el-radio>
                <el-radio v-model="healthyForm.is_hot" :label="true">37.3°以上/咳嗽等症状</el-radio> -->
                <el-switch
                    v-model="healthyForm.is_hot"
                    active-color="#ff4949"
                    inactive-color="#13ce66"
                    active-text="是"
                    inactive-text="否">
                </el-switch>
            <!-- </el-form-item>
            <el-form-item> -->
                <el-checkbox-group v-if="healthyForm.is_hot" v-model="healthyForm.ten_symptoms" style="display: block; margin-top: 10px">
                    <el-checkbox label="发热" class="check-content-item"></el-checkbox>
                    <el-checkbox label="干咳" class="check-content-item"></el-checkbox>
                    <el-checkbox label="乏力" class="check-content-item"></el-checkbox>
                    <el-checkbox label="嗅觉味觉减退" class="check-content-item"></el-checkbox>
                    <el-checkbox label="鼻塞" class="check-content-item"></el-checkbox>
                    <el-checkbox label="流涕" class="check-content-item"></el-checkbox>
                    <el-checkbox label="咽痛" class="check-content-item"></el-checkbox>
                    <el-checkbox label="结膜炎" class="check-content-item"></el-checkbox>
                    <el-checkbox label="肌痛和腹泻" class="check-content-item"></el-checkbox>
                    <el-checkbox label="是否是密切接触者或者次密切接触者" class="check-content-item"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="当前位置" prop="habitation" style="font-weight: bold">
                <el-row>
                    <el-col :span="19">
                        <el-input v-model="healthyForm.habitation" disabled style="font-weight: bold"> </el-input>
                    </el-col>
                    <el-col :span="5">
                        <el-button 
                            :loading="isLoading2"
                            icon="el-icon-location-outline"
                            type="primary" 
                            @click="GetJSAPI">
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="2月27日至今是否有如下地址旅居史" prop="sojourn_history" style="font-weight: bold" label-width="90px">
                <el-checkbox-group v-model="healthyForm.sojourn_history" @change="handleCheckedCitiesChange" style="display: block;">
                    <el-checkbox label="滨海酒店" class="check-content-item"></el-checkbox>
                    <el-checkbox label="丰泽新村" class="check-content-item"></el-checkbox>
                    <el-checkbox label="坪山路255号理工学院" class="check-content-item"></el-checkbox>
                    <el-checkbox label="池店金福小区" class="check-content-item"></el-checkbox>
                    <el-checkbox label="鲤城区兴贤路166号" class="check-content-item"></el-checkbox>
                    <el-checkbox label="丰泽区东美工业区" class="check-content-item"></el-checkbox>
                    <el-checkbox label="鲤城区德泰路锦绣江南小区" class="check-content-item"></el-checkbox>
                    <el-checkbox label="以上皆无" class="check-content-item"></el-checkbox>
                </el-checkbox-group>
                <!-- <el-checkbox-group v-model="healthyForm.sojourn_history" style="display: block;">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                </el-checkbox-group> -->
            </el-form-item>
            <el-form-item label="共同居住者" prop="living_together" style="font-weight: bold" label-width="94px">
                <el-checkbox-group v-model="healthyForm.living_together" @change="handleCheckedCitiesChange2" style="display: block;">
                    <el-checkbox label="去过中高风险地区" class="check-content-item"></el-checkbox>
                    <el-checkbox label="有境外旅居史" class="check-content-item"></el-checkbox>
                    <el-checkbox label="从事进口冷链食品" class="check-content-item"></el-checkbox>
                    <el-checkbox label="接触进口快递人员" class="check-content-item"></el-checkbox>
                    <el-checkbox label="以上皆无" class="check-content-item"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="健康码状态" prop="health_code" style="font-weight: bold" label-width="94px">
                <el-radio-group v-model="healthyForm.health_code" >
                    <el-radio :label="0">绿码</el-radio>
                    <el-radio :label="1">黄码</el-radio>
                    <el-radio :label="2">红码</el-radio>
                </el-radio-group> 
            </el-form-item>
            <el-form-item label="行程码状态" prop="travel_code" style="font-weight: bold" label-width="94px"> 
                <el-radio v-model="healthyForm.travel_code" :label="true">带 *</el-radio>
                <el-radio v-model="healthyForm.travel_code" :label="false">不带 *</el-radio>
            </el-form-item>
            <el-form-item label="入闽日期" prop="arrive_date" style="font-weight: bold">
                <el-row>
                    <el-col :span="19">
                        <el-date-picker
                            v-model="healthyForm.arrive_date"
                            align="center"
                            type="date"
                            style="width: 100%"
                            format="yyyy-MM-dd"
                            placeholder="请选择入闽日期">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="5">
                        <el-button 
                            icon="el-icon-s-release"
                            type="primary" 
                            @click="healthyForm.arrive_date = ''">
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="入闽交通方式" prop="Vehicle" label-width="108px" style="font-weight: bold">
                <el-select 
                    v-model="healthyForm.Vehicle" 
                    placeholder="请选择入闽交通方式" 
                    style="width: 100%">
                    <el-option
                        v-for="item in traffic_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item v-if="other" label-width="40px">
                <el-input v-model="healthyForm.other_Vehicle" placeholder="请输入其他交通方式"></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="是否跨县市外出" prop="isgoout" style="font-weight: bold" label-width="132px">
                <el-switch
                    v-model="healthyForm.isgoout"
                    active-color="#ff4949"
                    inactive-color="#13ce66"
                    active-text="是"
                    inactive-text="否">
                </el-switch>
            </el-form-item> 
            <el-form-item v-if="healthyForm.isgoout" label="外出地点" size="small" label-width="110px">
                <el-input v-model="healthyForm.outAddress1" placeholder="请输入外出地点"></el-input>
            </el-form-item>
            <el-form-item v-if="healthyForm.isgoout" label="交通工具" size="small" label-width="110px">
                <el-select 
                    v-model="healthyForm.outTraffic1" 
                    placeholder="请选择交通工具" 
                    size="small" 
                    style="width: 100%">
                    <el-option
                        v-for="item in traffic_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="是否返厂" prop="is_back" style="font-weight: bold">
                <el-switch
                    v-model="healthyForm.is_back"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="确定"
                    inactive-text="不确定">
                </el-switch>
            </el-form-item> 
            <el-form-item v-if="healthyForm.is_back" label="返厂方式" size="small" label-width="110px">
                <el-select 
                    @change="backType"
                    v-model="healthyForm.Vehicle" 
                    placeholder="请选择返厂方式" 
                    size="small" 
                    style="width: 100%">
                    <el-option
                        v-for="item in traffic_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item> -->
            <!-- <el-form-item v-if="!other && healthyForm.is_back" size="small" label="班次" label-width="110px">
                <el-input v-model="healthyForm.flight_no" placeholder="请输入班次号"></el-input>
            </el-form-item>
            <el-form-item v-if="other && healthyForm.is_back" size="small" label-width="40px">
                <el-input v-model="healthyForm.other_Vehicle" placeholder="请输入其他返厂交通方式"></el-input>
            </el-form-item>
            <el-form-item v-if="healthyForm.is_back" label="是否经过中/高风险地区" size="small" label-width="196px">
                <el-switch
                    v-model="healthyForm.is_pass"
                    active-color="#ff4949"
                    inactive-color="#13ce66"
                    active-text="是"
                    inactive-text="否">
                </el-switch>
            </el-form-item>
            <el-form-item v-if="healthyForm.is_back" label="出发日期" label-width="110px">
                <el-date-picker
                    v-model="healthyForm.setout_date"
                    size="small"
                    align="center"
                    type="date"
                    style="width: 100%"
                    format="yyyy-MM-dd"
                    placeholder="请选择出发日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item v-if="healthyForm.is_back" label="到厂日期" label-width="110px">
                <el-date-picker
                    v-model="healthyForm.arrive_date"
                    size="small"
                    align="center"
                    type="date"
                    style="width: 100%"
                    format="yyyy-MM-dd"
                    placeholder="请选择到厂日期">
                </el-date-picker>
            </el-form-item> -->
            <!-- <el-form-item v-if="!healthyForm.is_back" label="预计返厂日期" prop="planback_date" label-width="140px">
                <el-date-picker
                    v-model="healthyForm.planback_date"
                    size="small"
                    align="center"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择预计返厂日期">
                </el-date-picker>
            </el-form-item> -->
            <!-- <el-form-item label="紧急联系人" prop="urgent_contacts" style="font-weight: bold" label-width="100px">
                <el-input v-model="healthyForm.urgent_contacts" placeholder="请输入紧急联系人"></el-input>
            </el-form-item>
            <el-form-item label="紧急联系电话" prop="urgent_phone"  style="font-weight: bold" label-width="108px">
                <el-input v-model="healthyForm.urgent_phone" placeholder="请输入紧急电话"></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="是否核酸检测" style="font-weight: bold" size="small" label-width="120px">
                <el-switch
                    v-model="healthyForm.is_check"
                    active-color="#ff4949"
                    inactive-color="#13ce66"
                    active-text="是"
                    inactive-text="否">
                </el-switch>
            </el-form-item> -->
            <!-- <el-form-item v-if="healthyForm.is_check" label="核酸检测时间" style="font-weight: bold" label-width="120px"> -->
            <!-- <el-form-item label="是否核酸检测" style="font-weight: bold" label-width="110px">
                <el-switch
                    v-model="healthyForm.is_check"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="是"
                    inactive-text="否">
                </el-switch>
            </el-form-item>    -->
            <el-form-item label="3月13日至今核酸次数" prop="check_time" style="font-weight: bold" label-width="160px">
                <el-input-number 
                    size="small" 
                    :min="0"
                    :step="1"
                    :precision="0"
                    style="width: 100%;" 
                    placeholder="请输入核酸次数" 
                    v-model="healthyForm.check_time">
                </el-input-number>
            </el-form-item>
            <el-form-item label="最近核酸一" prop="check_date" style="font-weight: bold" label-width="94px">
                <el-row>
                    <el-col :span="19">
                         <el-date-picker
                            v-model="healthyForm.check_date"
                            align="center"
                            type="date"
                            style="width: 100%"
                            format="yyyy-MM-dd"
                            placeholder="请选择核酸检测时间">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="5">
                        <el-button 
                            icon="el-icon-s-release"
                            type="primary" 
                            @click="healthyForm.check_date = ''">
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="检测情况一" prop="check_result" style="font-weight: bold" label-width="94px">
                <!-- <el-radio v-model="healthyForm.check_result" :label="false">阴性</el-radio>
                <el-radio v-model="healthyForm.check_result" :label="true">阳性</el-radio> -->
                <el-radio-group v-model="healthyForm.check_result" >
                    <el-radio :label="0">阴性</el-radio>
                    <el-radio :label="1">阳性</el-radio>
                    <el-radio :label="2">未知</el-radio>
                </el-radio-group> 
            </el-form-item>
            <el-form-item label="最近核酸二" style="font-weight: bold" label-width="94px">
                <el-row>
                    <el-col :span="19">
                        <el-date-picker
                            v-model="healthyForm.check_date2"
                            align="center"
                            type="date"
                            style="width: 100%"
                            format="yyyy-MM-dd"
                            placeholder="请选择第二次核酸检测时间">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="5">
                        <el-button 
                            icon="el-icon-s-release"
                            type="primary" 
                            @click="healthyForm.check_date2 = ''">
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="检测情况二" style="font-weight: bold" label-width="94px"> 
                <!-- <el-radio v-model="healthyForm.check_result2" :label="false">阴性</el-radio>
                <el-radio v-model="healthyForm.check_result2" :label="true">阳性</el-radio> -->
                <el-radio-group v-model="healthyForm.check_result2" >
                    <el-radio :label="0">阴性</el-radio>
                    <el-radio :label="1">阳性</el-radio>
                    <el-radio :label="2">未知</el-radio>
                </el-radio-group> 
            </el-form-item>
             <el-form-item label="最近核酸三" style="font-weight: bold" label-width="94px">
                <el-row>
                    <el-col :span="19">
                        <el-date-picker
                            v-model="healthyForm.check_date3"
                            align="center"
                            type="date"
                            style="width: 100%"
                            format="yyyy-MM-dd"
                            placeholder="请选择第三次核酸检测时间">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="5">
                        <el-button 
                            icon="el-icon-s-release"
                            type="primary" 
                            @click="healthyForm.check_date3 = ''">
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="检测情况三" style="font-weight: bold" label-width="94px"> 
                <el-radio-group v-model="healthyForm.check_result3" >
                    <el-radio :label="0">阴性</el-radio>
                    <el-radio :label="1">阳性</el-radio>
                    <el-radio :label="2">未知</el-radio>
                </el-radio-group> 
            </el-form-item>
            <el-form-item label="新冠疫苗剂次" prop="agent_time" style="font-weight: bold" label-width="108px">
                <el-select v-model="healthyForm.agent_time" placeholder="请选择" style="width: 100%">
                    <el-option
                        v-for="item in vaccines"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-row style="margin-top: 10px;margin-bottom: 20px">
                <el-col :span="3" style="padding: 20px 0">
                    <el-checkbox v-model="is_check"></el-checkbox>
                </el-col>
                <el-col :span="21" style="font-size: 10.5px;color: #F56C6C;text-indent: 2em;text-align: left;line-height: 16px;">
                    为防控疫情，本人郑重承诺健康打卡相关个人信息的填报真实、完整、有效。如因信息有误或缺失，本人自愿承担因此造成的全部法律责任。本人同意健康打卡信息提交公司用于管理及疫情监测目的使用，同意健康打卡信息提交至所在辖区政府疫情防控部门做疫情防控统筹管理目的使用。本人承诺遵守并配合公司及疫情防控部门开展各项疫情防控措施。
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" style="margin-top: 10px;margin-bottom: 20px">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="isLoading"
                        @click="submitForm('healthyForm')">提交打卡</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-dialog :visible.sync="dialogTableVisible" size="mini" width="90%">
            <span slot="title" class="dialog-footer">
                <div style="font-weight: bold;text-align: left;font-size: 16px;">{{healthyForm.pname}}，您 {{healthyForm.hdate}} 已提交健康打卡</div>
                <div style="text-align: left;font-size: 14px;color: #F56C6C;margin-top: 10px;font-weight: bold">是否覆盖之前数据?</div>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogTableVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="SaveHealthy()">确定</el-button>
            </span>
        </el-dialog>
        <!-- <HealthyNotice :visible.sync="noticeDialogVisible" /> -->
        <el-dialog
            title="关于疫情防控的通知"
            :visible.sync="noticeDialogVisible"
            top="3vh"
            size="mini" 
            width="90%"
            :show-close="false"
            center>
            <template slot="title">
                <span style="font-size: 14px; font-weight: bold" v-html="noticeForm.FTITLE"></span>
            </template>
            <template>
                <div style="font-size: 12px; text-align: right; margin-bottom: 5px"  v-html="noticeForm.FSUBTITLE"></div>
                <div v-if="noticeForm.FCALL" style="font-size: 12px; font-weight: bold; margin-bottom: 5px">{{noticeForm.FCALL}}：</div>
                <div style="font-size: 11.5px; text-indent: 2em; line-height: 16px" v-html="noticeForm.FCONTENT"></div>
                <div style="font-size: 12px; font-weight: bold; text-align: right; margin-top: 10px" v-html="noticeForm.FSIGN"></div>
                <div style="font-size: 12px; font-weight: bold; text-align: right;margin-top: 5px">{{noticeForm.FDATE}}</div>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button @click="noticeDialogVisible = false" size="mini">取 消</el-button>
                <el-button type="primary" @click="noticeDialogVisible = false" size="mini">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
import wx from 'weixin-js-sdk'
// import HealthyNotice from '../../components/HealthyNotice'
export default {
    // components: {
    //     HealthyNotice
    // },
    data(){
        return {
            healthyForm: {
                pnl: '',
                pname: '',
                hdate: moment().format('YYYY-MM-DD'),
                is_hot: false,
                habitation: '',
                // habitation: '测试地址', // dev
                // isgoout: false,
                // outAddress1: '',
                // outTraffic1: '',
                Vehicle: '',
                // other_Vehicle: '',
                // flight_no: '',
                // is_back: false,
                arrive_date: '',
                // setout_date: '',
                // planback_date: '2022-02-08',
                // is_pass: false,
                // urgent_contacts: '',
                // urgent_phone: '',
                // is_check: false,
                check_date: '',
                check_result: false,
                check_date2: '',
                check_result2: false,
                check_date3: '',
                check_result3: '',
                agent_time: '',
                living_together: [],
                sojourn_history: [],
                ten_symptoms: [],
                travel_code: false,
                health_code: 0,
                val: false,
                check_time: '',
                lat: '',
                lng: '',
                province: '',
                city: '',
                district: '',
                town: '',
                street: '',
                pois_addr: '',
                sematic_description: ''
            },
            vaccines: [{
                value: 0,
                label: '0'
            },{
                value: 1,
                label: '1'
            },{
                value: 2,
                label: '2'
            },{
                value: 3,
                label: '3'
            }],
            // other: false,
            traffic_options: [{
                value: '1',
                label: '飞机'
            },{
                value: '2',
                label: '动车/高铁'
            },{
                value: '3',
                label: '火车'
            },{
                value: '4',
                label: '自驾/坐私家车'
            },{
                value: '5',
                label: '电动车、摩托车等'
            }],
            // symptomList: [],
            is_check: false,
            isLoading: false,
            isLoading2: false,
            sec_nm: '',
            dialogTableVisible: false,
            noticeDialogVisible: false,
            // hdate: moment().format('YYYY-MM-DD a'),
            rules: {
                hdate: [
                    {required: true, message: '请选择提报日期', trigger: 'change'}
                ],
                is_hot: [
                    {required: true, message: '请选择是否发热', trigger: 'change'}
                ],
                habitation: [
                    {required: true, message: '请输入当前位置', trigger: 'blur'}
                ],
                sojourn_history: [
                    {required: true, message: '请选择旅居史情况', trigger: 'change'}
                ],
                living_together: [
                    {required: true, message: '请选择共同居住情况', trigger: 'change'}
                ],
                travel_code: [
                    {required: true, message: '请选择行程码是否带 *', trigger: 'change'}
                ],
                health_code: [
                    {required: true, message: '请选择健康码情况', trigger: 'change'}
                ],
                // isgoout: [
                //     {required: true, message: '请选择是否外出', trigger: 'change'}
                // ],
                // is_back: [
                //     {required: true, message: '请选择是否返厂', trigger: 'change'}
                // ],
                // urgent_contacts: [
                //     {required: true, message: '请输入紧急联系人姓名', trigger: 'blur'}
                // ],
                // urgent_phone: [
                //     {required: true, message: '请输入紧急联系电话', trigger: 'blur'}
                // ],
                arrive_date: [
                    {required: true, message: '请选择入闽日期', trigger: 'change'}
                ],
                Vehicle: [
                    {required: true, message: '请选择入闽交通方式', trigger: 'change'}
                ],
                // check_date: [
                //     {required: true, message: '请选择核酸检测日期', trigger: 'change'}
                // ],
                // check_result:  [
                //     {required: true, message: '请选择核酸检测情况', trigger: 'change'}
                // ],
                agent_time:  [
                    {required: true, message: '请选择新冠疫苗剂次', trigger: 'change' }
                ],
                check_time:  [
                    { required: true, message: '请输入最近核酸次数', trigger: 'blur' }
                ],
            },
            noticeForm: {
                FTITLE: '',
                FSUBTITLE: '',
                FCALL: '',
                FCONTENT: '',
                FSIGN: '',
                FDATE: '',
                DB_ID: '',
                DB_CREATE_USERNO: '',
                FISSHOW: false
            },
        }
    },
    methods: {
        // ToTextArea(str) {
        //     // var reg = new RegExp("<br>", "g")
        //     var regSpace = new RegExp("&nbsp;", "g")
        //     // str = str.replace(reg, "\n")
        //     str = str.replace(regSpace, " ")
        //     // console.log('str', str)
        //     return str
        // },
        // backType(id) {
        //     this.other = id === '4' ||  id === '5' ? true : false
        // },
        handleCheckedCitiesChange(value) {
            // console.log('value', value)
            // console.log( this.healthyForm.sojourn_history)
            // if(value !== '以上皆无') {
            //     let index = this.healthyForm.sojourn_history.indexOf('以上皆无')
            //     if(index === 0) {
            //         this.healthyForm.sojourn_history.splice(index, 1)
            //     }else if(index > 0) {
            //         this.healthyForm.sojourn_history = []
            //         this.healthyForm.sojourn_history.push('以上皆无')
            //     }
            // }
            let index = this.healthyForm.sojourn_history.indexOf('以上皆无')
            if(index === 0) {
                if(value.length > 1) {
                    this.healthyForm.sojourn_history.splice(index, 1)
                }
            }else if(index > 0) {
                this.healthyForm.sojourn_history = []
                this.healthyForm.sojourn_history.push('以上皆无')
            }
        },
        handleCheckedCitiesChange2(value) {
            let index = this.healthyForm.living_together.indexOf('以上皆无')
            if(index === 0) {
                if(value.length > 1) {
                    this.healthyForm.living_together.splice(index, 1)
                }
            }else if(index > 0) {
                this.healthyForm.living_together = []
                this.healthyForm.living_together.push('以上皆无')
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CustomValidate()){
                    console.log(this.healthyForm)
                    this.CheckHealthy()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        CustomValidate() {
            if(this.healthyForm.check_time === 0) {
                this.$message({
                    message: '请输入核酸次数！',
                    center: true,
                    duration: 1500,
                    type: 'error'
                })
                return false
            }

            if(!this.is_check) {
                this.$message({
                    message: '请勾选本人承诺！',
                    center: true,
                    type: 'error'
                })
                return false
            }

            return true
        },
        async SaveHealthy() {
            this.dialogTableVisible = false
            this.isLoading = true
            const dto = {
                pnl: this.healthyForm.pnl,
                pname: this.healthyForm.pname,
                hdate: this.healthyForm.hdate,
                habitation: this.healthyForm.habitation,
                Vehicle: this.healthyForm.Vehicle,
                // other_Vehicle: this.healthyForm.other_Vehicle,
                is_check: this.healthyForm.is_check,
                is_hot: this.healthyForm.is_hot,
                arrive_date: this.healthyForm.arrive_date,
                check_date: this.healthyForm.check_date,
                check_result: this.healthyForm.check_result,
                check_date2: this.healthyForm.check_date2,
                check_result2: this.healthyForm.check_result2,
                check_date3: this.healthyForm.check_date3,
                check_result3: this.healthyForm.check_result3,
                agent_time: this.healthyForm.agent_time,
                living_together: this.healthyForm.living_together.toString(),
                sojourn_history: this.healthyForm.sojourn_history.toString(),
                ten_symptoms: this.healthyForm.ten_symptoms.toString(),
                travel_code: this.healthyForm.travel_code,
                health_code: this.healthyForm.health_code,
                check_time: this.healthyForm.check_time,
                lat: this.healthyForm.lat,
                lng: this.healthyForm.lng,
                province: this.healthyForm.province,
                city: this.healthyForm.city,
                district: this.healthyForm.district,
                town: this.healthyForm.town,
                street: this.healthyForm.street,
                pois_addr: this.healthyForm.pois_addr,
                sematic_description: this.healthyForm.sematic_description
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlhealthys/SaveHealthy', qs.stringify({
                dto: dto
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功') {
                this.$message({
                    message: '提交成功',
                    center: true,
                    type: 'success'
                })
                setTimeout(function(){
                    window.location.href = '/healthyList'
                },2000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                this.$message({
                    message: '提交失败：' + res.data.Message,
                    duration: 3000,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async GetJSAPI() {
            this.GetLocation()
        },
        async GetLocation() {
            wx.getLocation({
                type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: res => {
                    console.log(res)
                    this.GetBaiduLocation(res.latitude, res.longitude)
                }
            })
        },
        async GetBaiduLocation(lat, lng) {
            this.isLoading2 = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/BaiduLocation/BaiduLocationName', qs.stringify({
                lat: lat, // dev
                lng: lng // dev
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功') {
                if(res.data.ResponseData.message) {
                    this.$message({
                        message: res.data.ResponseData.message,
                        center: true,
                        type: 'success'
                    })
                }
                if(res.data.ResponseData.status === 0) {
                    let data = res.data.ResponseData.result
                    // this.healthyForm.habitation = data.formatted_address
                    this.healthyForm.lat = data.location.lat
                    this.healthyForm.lng = data.location.lng
                    this.healthyForm.province = data.addressComponent.province
                    this.healthyForm.city = data.addressComponent.city
                    this.healthyForm.district = data.addressComponent.district
                    this.healthyForm.town = data.addressComponent.town
                    this.healthyForm.street = data.addressComponent.street
                    this.healthyForm.pois_addr = data.pois[0].addr
                    this.healthyForm.sematic_description = data.sematic_description
                    this.healthyForm.habitation = this.healthyForm.pois_addr
                    this.isLoading2 = false
                }
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['openLocation', 'getLocation'], // 必填，需要使用的JS接口列表
                    openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
                })
            }
        },
        async GetListHealthyData() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlhealthys/GetLastHealthyList', qs.stringify({
                pnl: this.healthyForm.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    let healthyData = res.data.ResponseData[0]
                    this.healthyForm.is_hot = healthyData.is_hot
                    this.healthyForm.arrive_date = moment(healthyData.arrive_date).format('YYYY-MM-DD'), 
                    this.healthyForm.Vehicle = healthyData.Vehicle
                    // this.healthyForm.other_Vehicle = healthyData.other_Vehicle
                    // if(healthyData.other_Vehicle) {
                    //     this.other = true
                    // }

                    this.healthyForm.check_date = moment(healthyData.check_date).format('YYYY-MM-DD')
                    this.healthyForm.check_result = healthyData.check_result
                    this.healthyForm.check_date2 = moment(healthyData.check_date2).format('YYYY-MM-DD')
                    this.healthyForm.check_result2 = healthyData.check_result2
                    this.healthyForm.check_date3 = moment(healthyData.check_date3).format('YYYY-MM-DD')
                    this.healthyForm.check_result3 = healthyData.check_result3
                    this.healthyForm.agent_time = healthyData.agent_time
                    this.healthyForm.check_time = healthyData.check_time
                    this.healthyForm.travel_code = healthyData.travel_code
                    this.healthyForm.health_code = healthyData.health_code
                    if(healthyData.sojourn_history) {
                        if(healthyData.sojourn_history.length > 0) {
                            if(healthyData.sojourn_history.indexOf(',') > -1) {
                                this.healthyForm.sojourn_history = healthyData.sojourn_history.split(',')
                            }else {
                                var temp = []
                                temp.push(healthyData.sojourn_history)
                                this.healthyForm.sojourn_history = temp
                            }
                        }
                    }

                    if(healthyData.living_together) {
                        if(healthyData.living_together.length > 0) {
                            if(healthyData.living_together.indexOf(',') > -1) {
                                this.healthyForm.living_together = healthyData.living_together.split(',')
                            }else {
                                var temp3 = []
                                temp3.push(healthyData.living_together)
                                this.healthyForm.living_together = temp3
                            }
                        }
                    }

                    if(healthyData.ten_symptoms) {
                        if(healthyData.ten_symptoms.length > 0) {
                            if(healthyData.ten_symptoms.indexOf(',') > -1) {
                                this.healthyForm.ten_symptoms = healthyData.ten_symptoms.split(',')
                            }else {
                                var temp2 = []
                                temp2.push(healthyData.ten_symptoms)
                                this.healthyForm.ten_symptoms = temp2
                            }
                        }
                    }
                }
            }
        },
        async CheckHealthy() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AlePnlhealthys/CheckHealthy', qs.stringify({
                pnl: this.healthyForm.pnl,
                hdate: this.healthyForm.hdate
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                if(res.data.ResponseData.length > 0) {
                    this.dialogTableVisible = true
                }else {
                    this.SaveHealthy()
                }
            }
        },
        async ShowNotice() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TNOTICEs/GetLastNotice')
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    let noticeData = res.data.ResponseData[0]
                    this.noticeForm.FTITLE = noticeData.FTITLE
                    this.noticeForm.FSUBTITLE = noticeData.FSUBTITLE
                    this.noticeForm.FCALL = noticeData.FCALL
                    this.noticeForm.FCONTENT = noticeData.FCONTENT
                    this.noticeForm.FSIGN = noticeData.FSIGN
                    this.noticeForm.FDATE = noticeData.FDATE
                    this.noticeForm.DB_ID = noticeData.DB_ID
                    this.noticeForm.FISSHOW = noticeData.FISSHOW
                }
            }

            this.noticeDialogVisible = this.noticeForm.FISSHOW ? true : false
        }
    },
    mounted() {
        this.GetSignature()
        // this.GetBaiduLocation(24.87924575805664, 118.57785034179688) // dev
        this.GetListHealthyData()
        this.ShowNotice()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.healthyForm.pnl = pnl
            //  this.healthyForm.pnl = '1009' // dev
            // this.healthyForm.pnl = '0165'  // dev
            // this.healthyForm.pnl = '56558'  // dev
            // this.healthyForm.pnl = '53134'  // dev
            // this.healthyForm.pnl = '53442'  // dev
            // this.healthyForm.pnl = '18139'  // dev
        }
        const pname = localStorage.getItem('PNAME')
        if(pname){
            this.healthyForm.pname = pname
        }
        const sec_nm = localStorage.getItem('SEC_NM')
        if(sec_nm){
            this.sec_nm = sec_nm
        }
    }
}
</script>

<style scoped>
    .check-content-item{
        display: block;
        text-align: left;
    }
</style>