<template>
    <div>
        <SportHeader />
        <el-row>
            <!-- <el-button-group style="width: 100%">
                <el-button style="width: 25%" size="small" id="0" @click="getWeekData">周</el-button>
                <el-button style="width: 25%" size="small" id="1" @click="getMonthData">月</el-button>
                <el-button style="width: 25%" size="small" id="2" @click="getYearData">年</el-button>
                <el-button style="width: 25%" size="small" id="3" @click="getAllData">总</el-button>
            </el-button-group> -->
            <el-col :span="6" v-for="item in totalData" :key="item.value">
                <el-button :type="item.btn_type" size="small" style="width: 100%;" @click="selection(item)">{{item.label}}</el-button>
            </el-col>
        </el-row>
        <v-chart class="chart" :option="option" autoresize />
        <el-table :data="tableData" style="width: 100%" size="small">
            <el-table-column prop="name">
            </el-table-column>
            <el-table-column prop="value">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { use } from 'echarts/core'
// 手动引入 ECharts 各模块来减小打包体积

import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import { TitleComponent, GridComponent, TooltipComponent, LegendComponent } from 'echarts/components'
use([
    CanvasRenderer,
    BarChart,
    TitleComponent,
    GridComponent,
    TooltipComponent,
    LegendComponent
])
import VChart from 'vue-echarts'
import SportHeader from '../../components/SportHeader'
export default {
    components: {
        VChart,
        SportHeader
    },
    data() {
        return {
            sport_no: '',
            tableData: [],
            totalData: [
                {label: '周', btn_type: 'primary', value: 'getWeekData', id: '0' },
                {label: '月', btn_type: 'default', value: 'getMonthData', id: '1'},
                {label: '年', btn_type: 'default', value: 'getYearData', id: '2'},
                {label: '总', btn_type: 'default', value: 'getAllData', id: '3'},
            ],
            option: {
                xAxis: {
                    type: 'category',
                    data: [],
                    // axisLabel: {
                    //     interval: '4'
                    // }
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: true
                    },
                    axisTick: {
                        show: true
                    },
                    minInterval: 1,
                    name: '公里'
                },
                series: [{
                    data: [],
                    color: '#67C23A',
                    type: 'bar',
                    barWidth: '80%',
                    label: {
                        show: true,
                        position: 'top'
                    },
                    axisTick: {
                        show: false
                    }
                }]
            }
        }
    },
    methods: {
        selection(e) {
            // console.log(e)
            this.totalData.forEach(item => {
                if(item.value === e.value) {
                    item.btn_type = 'primary'
                    // console.log('item.value', item.value)
                    if(item.value === 'getWeekData') {
                        this.getWeekData(item)
                    }else if(item.value === 'getMonthData') {
                        this.getMonthData(item)
                    }else if(item.value === 'getYearData') {
                        this.getYearData(item)
                    }else if(item.value === 'getAllData') {
                        this.getAllData(item)
                    }
                }else {
                    item.btn_type = 'default'
                }
            })
        },
        getWeekData(item) {
            var start = moment().startOf('week').format('YYYY-MM-DD')
            var end = moment().endOf('week').format('YYYY-MM-DD')
            // this.getDataModel(start, end, item.target.id)
            this.getDataModel(start, end, item.id)
        },
        getMonthData(item) {
            var start = moment().startOf('month').format('YYYY-MM-DD')
            var end = moment().endOf('month').format('YYYY-MM-DD')
            // this.getDataModel(start, end, item.target.id)
            this.getDataModel(start, end, item.id)
        },
        getYearData(item) {
            var start = moment().startOf('year').format('YYYY-MM-DD')
            var end = moment().endOf('year').format('YYYY-MM-DD')
            // this.getDataModel(start, end, item.target.id)
             this.getDataModel(start, end, item.id)
        },
        getAllData(item) {
            var start = '2021-03-04'
            var end = moment().format('YYYY-MM-DD')
            // this.getDataModel(start, end, item.target.id)
            this.getDataModel(start, end, item.id)
        },
        getDataModel(start, end, type) {
            this.getStatisticsData(start, end, type)
            this.getTotalData(start, end)
        },
        async getStatisticsData(start, end, type) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrSports/GetChartsData', qs.stringify({
                start: start,
                end: end,
                type: type,
                no: this.sport_no
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.option.xAxis.data = res.data.ResponseData.charts_date
                this.option.series[0].data = res.data.ResponseData.charts_data
                // if(type == 1) {
                //     this.option.xAxis.axisLabel.interval = 4
                // } else {
                //     this.option.xAxis.axisLabel.interval = 0
                // }
            }
        },
        async getTotalData(start, end) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrSports/GetTotalData', qs.stringify({
                start: start,
                end: end,
                no: this.sport_no
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.tableData = []
                res.data.ResponseData.forEach(item => {
                    this.tableData.push({
                        name: item.SPORT_TYPE == '距离类' ? '运动距离' : '运动时长',
                        value: (item.SPORT_TYPE == '距离类' ? item.TOTAL_KM : item.TOTAL_MINUTE) + (item.SPORT_TYPE == '距离类' ? ' 公里' : ' 分钟')
                    })
                    this.tableData.push({
                        name: '运动次数',
                        value: item.SPORT_NUM + ' 次'
                    })
                })
            }
        }
    },
    mounted() {
        // this.sport_no = localStorage.getItem('NO')
        // const sport_no = localStorage.getItem('NO')
        // if(sport_no){
        //     this.sport_no = sport_no
        // }
        var start = moment().week(moment().week()).startOf('week').format('YYYY-MM-DD')
        var end = moment().week(moment().week()).endOf('week').format('YYYY-MM-DD')
        this.getStatisticsData(start, end, 0)
        // this.getTotalData(start, end)
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.sport_no = pnl
            // this.sport_no = '32417' // dev
        }
    }
}
</script>

<style scoped>
    .chart {
        height: 320px;
        margin-bottom: -40px;
        /* padding-bottom: -50px; */
        /* margin-top: 10px; */
    }
</style>