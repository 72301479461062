<template>
    <div>
        <el-table 
            stripe 
            :data="tableData" 
            v-loading="table_loading" 
            style="width: 100%;font-size: 12px;margin-bottom: 30px" 
            size="small" 
            :show-header="false">
            <el-table-column width="110">
                <template slot-scope="scope">
                    <div style="color: #606266; font-weight: bold;">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value" align="right">
                <template slot-scope="scope">
                    <div>{{scope.row.value }} 
                        <i style="color: #E6A23C;font-weight: bold;" 
                        v-if="scope.row.label === '生日类型'" 
                        @click="ShowEditDialog()"
                        class="el-icon-edit"> 申请修改</i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-row style="margin-bottom: 60px">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="danger" 
                    @click="BaseChange">档案修改申请</el-button>
            </el-col>
        </el-row>
        <el-dialog style="color: #F56C6C;font-weight: bold" 
            title="是否申请修改生日信息？" 
            :visible.sync="dialogVisible" 
            size="mini" 
            width="90%">
            <div style="color: #F56C6C;margin-bottom: 30px;font-size: 13px">
                生日信息的准确性将影响每月举办的生日庆生活动与生日小礼品发放，请认真填写！
            </div>
            <el-form :model="infoForm" ref="infoForm" :rules="rules" label-width="80px" size="mini">
                <el-form-item label="生日类型" prop="isxl" style="font-weight: bold">
                    <el-radio v-model="infoForm.isxl" label="1">新历</el-radio>
                    <el-radio v-model="infoForm.isxl" label="2">农历</el-radio>
                </el-form-item>
                <el-form-item label="生日 (月)" prop="birthmm" style="font-weight: bold">
                    <!-- <el-select 
                        @change="TypeChange"
                        v-model="infoForm.ftype" 
                        placeholder="请选择月份" 
                        style="width: 100%">
                        <el-option
                            v-for="item in types"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                    <el-date-picker
                        style="width: 100%"
                        v-model="infoForm.birthmm"
                        format="M"
                        type="month"
                        placeholder="选择生日所在月">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="生日 (日)" prop="birthdd" style="font-weight: bold">
                    <el-date-picker
                        style="width: 100%"
                        v-model="infoForm.birthdd"
                        format="d"
                        type="date"
                        placeholder="选择生日所在日">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
                <el-button size="mini" :loading="isLoading" type="primary" @click="submitForm('infoForm')">确 定</el-button>
            </span>
        </el-dialog>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import moment from 'moment'
export default {
    data() {
        return {
            pnl: '',
            tableData: [],
            table_loading: false,
            dialogVisible: false,
            infoForm: {
                isxl: '',
                birthmm: '',
                birthdd: ''
            },
            isLoading: false,
            rules: {
                isxl: [
                    {required: true, message: '请选择生日类型', trigger: 'change'}
                ],
                birthmm: [
                    {required: true, message: '选择生日所在月', trigger: 'change'}
                ],
                birthdd: [
                    {required: true, message: '选择生日所在日', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        BaseChange() {
            window.location.href = '/detailInfoChange'
        },
        ShowEditDialog() {
            this.dialogVisible = true
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.infoForm)
                    this.SaveDetailInfo()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveDetailInfo() {
            this.isLoading = true
            let type = this.tableData.filter(item => item.label === '生日类型')[0].value
            const isxl = type === '新历' ? '1' : '2'
            const birthmm = this.tableData.filter(item => item.label === '生日所在月')[0].value
            const birthdd = this.tableData.filter(item => item.label === '生日所在日')[0].value
            const dto = {
                PNL: this.pnl,
                ISXL: isxl,
                BIRTHMM: birthmm,
                BIRTHDD: birthdd,
                ISXL_NEW: this.infoForm.isxl,
                BIRTHMM_NEW: moment(this.infoForm.birthmm).format('M'),
                BIRTHDD_NEW: moment(this.infoForm.birthdd).format('D'),
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EhrPersonBirthdayChanges/SaveDetailInfo', qs.stringify({
                dto,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: '生日修改申请成功',
                    center: true,
                    type: 'success'
                })
                this.dialogVisible = false
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '生日修改申请失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '生日修改申请失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async GetDetailInfo() {
            this.tableData = []
            this.table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsPersons/DetailInfo', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.forEach(item => {
                    // this.tableData.push({
                    //     label: '曾用名',
                    //     value: item.CYM
                    // })
                    this.tableData.push({
                        label: '所在部门',
                        value: item.sec_nm1
                    })
                    this.tableData.push({
                        label: '身份证号',
                        value: item.pid
                    })
                    this.tableData.push({
                        label: '身份证有效期',
                        value: moment(item.pidendtime).format('YYYY-MM-DD')
                    })
                    // this.tableData.push({
                    //     label: '考勤卡号',
                    //     value: item.ic_no
                    // })
                    this.tableData.push({
                        label: '性别',
                        value: item.gender === '1' ? '男' : '女' 
                    })
                    this.tableData.push({
                        label: '出生日期',
                        value: moment(item.bdate).format('YYYY-MM-DD')
                    })
                    this.tableData.push({
                        label: '婚姻状况',
                        value: item.marry === '2' ? '已婚' : '未婚'
                    })
                    this.tableData.push({
                        label: '入职日期',
                        value: moment(item.idate).format('YYYY-MM-DD')
                    })
                    this.tableData.push({
                        label: '学历',
                        value: item.edc_nm
                    })
                    this.tableData.push({
                        label: '民族',
                        value: item.nat_nm
                    })
                    this.tableData.push({
                        label: '职务',
                        value: item.zw_nm
                    })
                    this.tableData.push({
                        label: '工资类别',
                        value: item.imark_nm
                    })
                    this.tableData.push({
                        label: '工种',
                        value: item.gz_nm
                    })
                    this.tableData.push({
                        label: '户籍',
                        value: item.ads1
                    })
                    this.tableData.push({
                        label: '住址',
                        value: item.ads2
                    })
                    this.tableData.push({
                        label: '现居地',
                        value: item.ads3
                    })
                    this.tableData.push({
                        label: '邮编',
                        value: item.post_no
                    })
                    this.tableData.push({
                        label: '联系电话',
                        value: item.tel
                    })
                    this.tableData.push({
                        label: 'Email',
                        value: item.email
                    })
                    this.tableData.push({
                        label: '试用期始',
                        value: moment(item.tdate1).format('YYYY-MM-DD')
                    })
                    this.tableData.push({
                        label: '试用期止',
                        value: moment(item.tdate2).format('YYYY-MM-DD')
                    })
                    this.tableData.push({
                        label: '合同期始',
                        value: moment(item.cdate1).format('YYYY-MM-DD')
                    })
                    this.tableData.push({
                        label: '合同期止',
                        value: item.cdate2 ? moment(item.cdate2).format('YYYY-MM-DD') : ''
                    })
                    this.tableData.push({
                        label: '宿舍房号',
                        value: item.room_no
                    })
                    this.tableData.push({
                        label: '宿舍床位号',
                        value: item.bed_no
                    })
                    this.tableData.push({
                        label: '生日类型',
                        value: item.isxl === 1 ? '新历' : '农历'
                    })
                    this.tableData.push({
                        label: '生日所在月',
                        value: item.birthmm
                    })
                    this.tableData.push({
                        label: '生日所在日',
                        value: item.birthdd
                    })
                    this.tableData.push({
                        label: '紧急联系人',
                        value: item.notifyMan
                    })
                    this.tableData.push({
                        label: '紧急联系人称谓',
                        value: item.notifyCW
                    })
                    this.tableData.push({
                        label: '紧急联系人电话',
                        value: item.notifyTel
                    })
                    this.tableData.push({
                        label: '入职性质',
                        value: item.ent_nm
                    })
                    this.tableData.push({
                        label: '入职介绍人',
                        value: item.jsr
                    })
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.table_loading = false
        }
    },
    mounted() {
        this.GetDetailInfo()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>