<template>
    <div>
        <el-descriptions :column="2" size="medium" border style="margin-bottom: 20px;">
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-tickets"></i>
                    部门
                </template>
                {{ sec_nm }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-user"></i>
                    姓名
                </template>
                {{ pname }}
            </el-descriptions-item>
        </el-descriptions>
        <el-form :model="quitForm" :rules="rules" ref="quitForm" label-width="90px" size="medium">
            <el-form-item label="离职原因" prop="fcode" style="font-weight: bold">
                <el-cascader
                    :show-all-levels="false"
                    v-model="quitForm.fcode"
                    clearable
                    style="width: 100%"
                    placeholder="请选择离职原因"
                    :options="quitForm.options">
                </el-cascader>
            </el-form-item>
            <el-form-item label="具体描述" prop="record" style="font-weight: bold">
                <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="请填写具体描述"
                        maxlength="100"
                        show-word-limit
                        v-model="quitForm.record">
                    </el-input>
            </el-form-item>
            <el-row style="margin-top: 10px;margin-bottom: 20px">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="isLoading"
                        @click="submitForm('quitForm')">提交调查表</el-button>
                </el-col>
            </el-row>
        </el-form>    
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
export default {
    data() {
        return {
            pnl: '',
            quitForm: {
                options: [],
                fcode: '',
                record: ''
            },
            isLoading: false,
            rules: {
                fcode: [
                    {required: true, message: '请选择离职原因', trigger: 'change'}
                ],
                record: [
                    {required: true, message: '请填写具体描述', trigger: 'blur'}
                ],
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.quitForm)
                    this.SaveDimissData()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveDimissData() {
            console.log('this.quitForm.fcode', this.quitForm.fcode)
            this.isLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsDimissreasons/SaveDimissData', qs.stringify({
                pnl: this.pnl,
                fcode: this.quitForm.fcode[1],
                record: this.quitForm.record
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: '调查表提交成功',
                    center: true,
                    type: 'success'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '调查表提交失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '调查表提交失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async GetDimissData() {
            this.quitForm.options = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsDimissreasons/GetDimissData')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.quitForm.options = this.GroupBy(res.data.ResponseData, 'FPCODE')
                console.log('options', this.quitForm.options)
            }
        },
        GroupBy(datas, key, callBack) {
            const list = datas || []
            const groups = []
            list.forEach(v => {
                let key = v.FPCODE
                let group = groups.find(v => {
                    return v.value === key
                })
                if(!group) {
                    group = {
                        label: v.FPNAME,
                        value: v.FPCODE
                    }
                    groups.push(group)
                }
                if(callBack) {
                    group.children = callBack(group.children, v)
                }else {
                    group.children = group.children || []
                    group.children.push({
                        label: v.FNAME,
                        value: v.FCODE
                    })
                }
            })
            return groups
        },
        
    },
    mounted() {
        this.GetDimissData()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
        }
        const pname = localStorage.getItem('PNAME')
        if(pname) {
            this.pname = pname
        }
        const sec_nm = localStorage.getItem('SEC_NM')
        if(sec_nm) {
            this.sec_nm = sec_nm
        }
    }
}
</script>