<template>
    <div>
        <div class="tree_container">
            <h3>寰球新员工入职指引</h3>
            <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick">

            </el-tree>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: [{
                label: '企业文化须知',
                children: [{
                    label: '企业精神：诚信、创新、团结、严谨、务实。'
                },{
                    label: '企业使命：创造型制造，成就利益攸关者价值。'
                },{
                    label: '企业愿景：永续寰球制造之卓越，引领鞋业制造之先锋。'
                },{
                    label: '核心价值观：践行“创新求变、专注务实、诚信感恩”准则，传承“诚•勤•韧”精神。'
                }],
            },{
                label: '考勤须知',
                children: [{
                    label: '考勤方式：入职当天录入人脸后，根据个人上下班时间及打卡次数，完成考勤工作。'
                },{
                    label: '考勤刷卡有效时间：打卡班次前半小时内。'
                },{
                    label: '考勤情况查询：寰球移动办公平台——个人平台——考勤明细三四天查询一次即可，每月初及时核对上个月考勤。'
                },{
                    label: '请假单办理：纸质申请——领导签字——交单位助理线上办理——办理后保安处查询无误检查放行。'
                }],
            },{
                label: '门禁须知',
                children: [{
                    label: '佩戴厂牌、穿着制服进入厂区，每日门禁处稽查，未按要求佩戴将被登记。'
                },{
                    label: '严禁穿拖鞋、高跟鞋、背心等进入厂区。吸烟室配备点烟器，严禁携带打火机、火种进入厂区，禁止在吸烟室外地方吸烟。'
                },{
                    label: '醉酒员工无法进入生产厂区。'
                },{
                    label: '所有人员工作时间出厂，须事先征得主管同意，知会助理提前线上申请相应的流程，经过生活区时将厂牌出示保安，并说明中途离开原因，以便查核相应流程。（如：《人员及物品放行条》《请假单》《签卡单》等）回来后需到门岗保安处确认回厂时间，若未确认回厂时间的，视为缺勤，不得补漏刷卡。'
                }],
            },{
                label: '住宿须知',
                children: [{
                    label: '办理时间为：上午11:00-12:30，下午17:00-19:30'
                },{
                    label: '住宿办理：带厂牌、行李根据办理时间到宿管值班室办理。'
                },{
                    label: '退宿办理：个人物品收拾好、卫生清洁干净，带一名舍友根据办理时间到宿管值班室签 《宿舍物品放行条》 ，到保安处检查放行。'
                },{
                    label: '入住公司宿舍员工需严格遵守《员工宿舍管理规定》并遵守门禁管理：'
                },{
                    label: '周一至周五、周日晚上：00:30前：'
                },{
                    label: '周六晚上、国家法定节假日：1:00前'
                },{
                    label: '特殊情况需提前报备。'
                }],
            },{
                label: '就餐须知',
                children: [{
                    label: '员工餐厅：组长（含）以下人员可在员工餐厅免费用餐。'
                },{
                    label: '就餐时间段'
                },{
                    label: '早餐：06:40——07:55'
                },{
                    label: '晚餐：根据各部门、各车间下班时间（18:30-19:20）'
                },{
                    label: '节假日：早餐7:00-7:55，中餐11:00-12:00，晚餐17:30-18:30 '
                },{
                    label: '管理餐厅：组长（含）以上人员，采用一脸通充值消费方式管理餐厅消费。'
                },{
                    label: '充值方法：下载企业微信——微信登录——寰球行政身份进入——右下角：工作台——一脸通充值即可。'
                },{
                    label: '※根据个人食量，合理盛汤和米饭，杜绝浪费！'
                }],
            },{
                label: '保密须知',
                children: [{
                    label: '①在公司范围内，未经授权不能随意拍照或将照片发布到网络平台上。（例如：微信、QQ等）。'
                },{
                    label: '②未经授权，不能将公司文件携带出去或通过网络平台外发，须严格保守公司秘密。'
                },{
                    label: '③未经授权，不要带领不相关的人员进入公司的办公区域、生产区域等场所。'
                }],
            },{
                label: '寰球生活/休闲场所开放时间',
                children: [{
                    label: '①公司超市开放时间'
                },{
                    label: '工作日：上午:07:00-08:30'
                },{
                    label: '中午:10:30-13:30'
                },{
                    label: '晚上:16:30-23:00'
                },{
                    label: '节假日：上午：10:30-13:30，下午：16:30-22:00'
                },{
                    label: '②职工图书馆：周一至周六19:00-22:00'
                },{
                    label: '③职工运动中心：周一至周六18:30-22:00，周日：15:00-22:00'
                },{
                    label: '④舞蹈室：不定期开门，免费舞蹈老师教学，具体时间HR通知。'
                }],
            }],
            defaultProps: {
                children: 'children',
                label: 'label'
            }
        }
    },
    methods: {
        handleNodeClick(data) {
            console.log(data);
        }
    },
    created() {

    }
}
</script>

<style scoped>
    ::v-deep .el-tree-node__label {
        font-size: 15px;
        font-weight: bold;
        margin-top: 8px;
        margin-bottom: 8px
        /* white-space: normal; */
        /* text-align: left; */
    }
    ::v-deep .el-tree-node__children .el-tree-node__label {
        font-size: 13px;
        font-weight: normal;
        white-space: normal;
        text-align: left;
        /* padding-top: 5px; */
    }
</style>