<template>
    <div>
        <el-form :model="weekMenuForm" ref="weekMenuForm" label-width="60px" size="small">
            <el-form-item label="餐厅" prop="ftype" style="font-weight: bold">
                <el-row>
                    <el-col :span="18">
                        <el-select 
                            @change="TypeChange"
                            v-model="weekMenuForm.ftype" 
                            placeholder="请选择餐厅" 
                            style="width: 100%">
                            <el-option
                                v-for="item in types"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="5" style="margin-left: 10px">
                        <router-link to="/weekMenu">
                            <el-button style="width: 90%" type="primary">新增</el-button>
                        </router-link>
                    </el-col>
                </el-row>
            </el-form-item>
        </el-form>
        <el-table :data="weekMenuTable"   
            :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
            size="small" 
            v-loading="isLoading" 
            border>
            <el-table-column prop="FTYPE_NM" label="类别" min-width="50px" align="center">
                <template slot-scope="scope">
                    <div>{{scope.row.FTYPE_NM}}</div>
                </template>
            </el-table-column>
            <el-table-column label="菜谱有效日期" min-width="140px" align="center">
                <template slot-scope="scope">
                    <div>{{scope.row.DATE}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="100px" align="center">
               <template slot-scope="scope">
                    <el-link style="font-size: 12px;color: #67C23A" @click="ShowImages(scope.row.DB_ID)"><i class="el-icon-picture-outline"></i> 查看</el-link> |
                    <el-link style="font-size: 12px;color: #F56C6C" @click="ShowDelWeekMenu(scope.row)"><i class="el-icon-delete"></i> 删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <!-- <el-dialog :visible.sync="dialogTableVisible" size="mini" width="90%">
            <span class="dialog-footer">
                <el-image
                    :key="item.DB_ID"
                    v-for="item in imageList"
                    lazy
                    style="width: 100%;"
                    :src="item.path"
                    fit="fill">
                </el-image>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="mini" @click="dialogTableVisible = false">关闭</el-button>
            </span>
        </el-dialog> -->
        <el-dialog style="color: #F56C6C" title="删除确认？" :visible.sync="delDialogVisible" size="mini" width="90%">
            <span class="dialog-footer">
                删除【{{model.FTYPE_NM}}】餐厅，【{{model.FDATE}}】的菜谱，是否继续?
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="delDialogVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="DelWeekMenu">确 定</el-button>
            </span>
        </el-dialog>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import conf from '../../../conf.js'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            weekMenuForm: {
                ftype: '',
                pnl: '',
                ftype_nm: ''
            },
            model: {},
            types: [{
                label: '管理',
                value: 1
            },{
                label: '员工',
                value: 2
            },{
                label: '明档',
                value: 3
            }],
            weekMenuTable: [],
            isLoading: false,
            // dialogTableVisible: false,
            imageList: [],
            delDialogVisible: false
        }
    },
    methods: {
        TypeChange() {
            this.GetDinnerWeekMenu()
        },
        ShowImages(id) {
            // console.log(id)
            // this.dialogTableVisible = true
            this.GetImagesByID(id)
        },
        async GetImagesByID(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        // this.imageList.push({
                        //     id: item.DB_ID,
                        //     path: conf.image_domain + item.FImgPath
                        // })
                        let path = conf.image_domain + item.FImgPath
                        // console.log('path', path)
                        this.imageList.push(
                           path
                        )
                    })
                    
                    // console.log('this.imageList', this.imageList)
                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
            }
        },
        ShowDelWeekMenu(model) {
            console.log('model', model)
            this.model = model
            this.delDialogVisible = true
        },
        async DelWeekMenu() {
            this.delDialogVisible = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxDinnerWeekMenus/DelDinnerWeekMenu', qs.stringify({
                DB_ID: this.model.DB_ID,
                MEDIA_ID: this.model.MEDIA_ID
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: '删除成功！',
                    center: true,
                    type: 'success',
                    duration: 2000
                })
                this.delDialogVisible = false
                setTimeout(function(){
                    window.location.href = '/weekMenuList'
                },2000)
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.delDialogVisible = false
        },
        async GetDinnerWeekMenu() {
            this.isLoading = true
            this.weekMenuTable = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxDinnerWeekMenus/DinnerWeekMenu', qs.stringify({
                type: this.weekMenuForm.ftype
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                res.data.ResponseData.forEach(item => {
                    item.DATE = moment(item.FSTART).format('MM月DD日') + ' - ' + moment(item.FEND).format('MM月DD日')
                })
                this.weekMenuTable = res.data.ResponseData
                console.log('this.weekMenuTable', this.weekMenuTable)
            }
            this.isLoading = false
        },
        // async GetDinnerImage() {
        //     this.dinnerImageData = []
        //     const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxDinnerWeekMenus/DinnerImage')
        //     console.log(res)
        //     if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
        //         console.log(res.data.ResponseData)
        //         if(res.data.ResponseData.length > 0) {
        //             res.data.ResponseData.map(item => {
        //                 var preview_list = []
        //                 preview_list.push(conf.image_domain + item.FImgPath)
        //                 this.dinnerImageData.push({
        //                     DB_ID: item.DB_ID,
        //                     FImgPath: conf.image_domain + item.FImgPath,
        //                     ftype_nm: item.ftype_nm,
        //                     preview_list: preview_list,
        //                     fdate: moment(item.fstart).format('MM月DD日') + ' - ' + moment(item.fend).format('MM月DD日')
        //                 })
        //             })
        //         }
        //     }
        // },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
    },
    mounted() {
        this.GetDinnerWeekMenu()
        // this.GetDinnerImage()
        this.GetSignature()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.weekMenuForm.pnl = pnl 
        }
    }
}
</script>