<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;margin-top: -20px;" stretch>
            <el-tab-pane label="成型" name="first" lazy>
                <el-form 
                    :model="cxInOutForm" 
                    :rules="cxRules" 
                    ref="cxInOutForm" 
                    label-width="60px" 
                    size="small" 
                    style="margin-top: 10px">
                    <el-row :gutter="10">
                        <el-col :span="18">
                            <el-form-item label="成型" prop="vday" style="font-weight: bold;">
                                <el-date-picker
                                    v-model="cxInOutForm.vday"
                                    style="width: 100%;"
                                    size="small"
                                    type="date"
                                    align="center"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-button 
                                size="small" 
                                type="danger" 
                                icon="el-icon-refresh" 
                                style="width: 100%;"
                                @click="cxSubmitForm('cxInOutForm')">刷新</el-button>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table 
                    :data="cxTotalTableData" 
                    size="mini" 
                    border
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, margin: 0, height: '32px'}" 
                    :row-style="{color: '#F56C6C', fontWeight: 600, align: 'center'}"
                    :cell-style="() => {return 'padding: 0px;margin: 0px;height: 30px;fontSize: 11px'}"
                    style="margin-top: 15px"
                    v-loading="cx_total_table_loading" >
                    <el-table-column prop="sec_nm" label="部门" min-width="46px" align="center">
                    </el-table-column>
                    <el-table-column prop="mbqty" label="日标" min-width="54px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqty" label="日投" min-width="54px" align="center">
                    </el-table-column>
                    <el-table-column prop="diffqty" label="日达成" min-width="60px" align="center">    
                        <template slot-scope="scope">
                            {{scope.row.diffqty > 0 ? scope.row.diffqty + '%' : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="oqty" label="日产" min-width="54px" align="center">
                    </el-table-column>
                    <el-table-column prop="mbqtys" label="月标" min-width="62px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqtys" label="月投" min-width="62px" align="center">
                    </el-table-column>
                    <el-table-column prop="diffqtys" label="月达成" min-width="60px" align="center">    
                        <template slot-scope="scope">
                            {{scope.row.diffqtys > 0 ? scope.row.diffqtys + '%' : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="oqtys" label="月产" min-width="62px" align="center">
                    </el-table-column>
                    <el-table-column prop="zdiffqty" label="日在制" min-width="60px" align="center">
                    </el-table-column>
                    <el-table-column prop="zdiffqtys" label="月在制" min-width="60px" align="center">
                    </el-table-column>
                </el-table>
                <el-table 
                    max-height="580"
                    :data="cxTableDynamicData" 
                    size="mini"
                    border 
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, margin: 0, height: '32px'}" 
                    :cell-style="() => {return 'padding: 0px;margin: 0px;height: 30px;fontSize: 11px'}"
                    v-loading="cx_table_dynamic_loading" 
                    :row-class-name="tableRowClassName2" 
                    style="margin-top: 15px">
                    <el-table-column prop="sec_nm" label="部门" min-width="74px" align="center">
                    </el-table-column>
                    <el-table-column prop="mbqty" label="日标" min-width="48px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqty" label="日投" min-width="48px" align="center">
                        <template slot-scope="scope">
                            <div :class="scope.row.iqty < scope.row.mbqty ? ' error-cell' : ''">{{scope.row.iqty}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="diffqty" label="日达成" min-width="60px" align="center">    
                        <template slot-scope="scope">
                            {{scope.row.diffqty > 0 ? scope.row.diffqty + '%' : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="oqty" label="日产" min-width="48px" align="center">
                    </el-table-column>
                    <el-table-column prop="mbqtys" label="月标" min-width="56px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqtys" label="月投" min-width="56px" align="center">
                        <template slot-scope="scope">
                            <div :class="scope.row.iqtys < scope.row.mbqtys ? ' error-cell' : ''">{{scope.row.iqtys}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="diffqtys" label="月达成" min-width="60px" align="center">
                        <template slot-scope="scope">
                            {{scope.row.diffqtys > 0 ? scope.row.diffqtys + '%' : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="oqtys" label="月产" min-width="56px" align="center">
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="帮面" name="second" lazy>
                <el-form :model="bmInOutForm" :rules="bmRules" ref="bmInOutForm" label-width="60px" size="small" style="margin-top: 10px">
                    <el-row :gutter="10">
                        <el-col :span="18">
                            <el-form-item label="帮面" prop="vday" style="font-weight: bold">
                                <el-date-picker
                                    v-model="bmInOutForm.vday"
                                    style="width: 100%"
                                    size="small"
                                    type="date"
                                    align="center"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择件资日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-button 
                                size="small" 
                                type="danger" 
                                icon="el-icon-refresh" 
                                style="width: 100%;"
                                @click="bmSubmitForm('bmInOutForm')">刷新</el-button>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table 
                    :data="bmTotalTableData" 
                    size="mini" 
                    border
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, margin: 0, height: '32px'}" 
                    :row-style="{color: '#F56C6C', fontWeight: 600, align: 'center'}"
                    :cell-style="() => {return 'padding: 0px;margin: 0px;height: 30px;fontSize: 11px'}"
                    style="margin-top: 15px"
                    v-loading="bm_total_table_loading">
                    <el-table-column prop="sec_nm" label="部门" min-width="45px" align="center">
                    </el-table-column>
                    <el-table-column prop="mbqty" label="日标" min-width="54px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqty" label="日投" min-width="52px" align="center">
                    </el-table-column>
                    <el-table-column prop="diffqty" label="日达成" min-width="60px" align="center">    
                        <template slot-scope="scope">
                            {{scope.row.diffqty > 0 ? scope.row.diffqty + '%' : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="oqty" label="日产" min-width="58px" align="center">
                    </el-table-column>
                    <el-table-column prop="mbqtys" label="月标" min-width="62px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqtys" label="月投" min-width="62px" align="center">
                    </el-table-column>
                    <el-table-column prop="diffqtys" label="月达成" min-width="60px" align="center">    
                        <template slot-scope="scope">
                            {{scope.row.diffqtys > 0 ? scope.row.diffqtys + '%' : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="oqtys" label="月产" min-width="62px" align="center">
                    </el-table-column>
                    <el-table-column prop="zdiffqty" label="日在制" min-width="60px" align="center">
                    </el-table-column>
                    <el-table-column prop="zdiffqtys" label="月在制" min-width="60px" align="center">
                    </el-table-column>
                </el-table>
                <el-table 
                    max-height="580"
                    :data="bmTableDynamicData" 
                    size="mini" 
                    border 
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, margin: 0, height: '32px'}" 
                    :cell-style="() => {return 'padding: 0px;margin: 0px;height: 30px;fontSize: 11px'}"
                    :row-class-name="tableRowClassName2" 
                    v-loading="bm_table_dynamic_loading" 
                    style="margin-top: 15px">
                    <el-table-column prop="sec_nm" label="部门" fixed min-width="78px" align="center">
                    </el-table-column>
                    <el-table-column prop="mbqty" label="日标" min-width="48px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqty" label="日投" min-width="48px" align="center">
                        <template slot-scope="scope">
                            <div :class="scope.row.iqty < scope.row.mbqty ? ' error-cell' : ''">{{scope.row.iqty}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="diffqty" label="日达成" min-width="60px" align="center">
                        <template slot-scope="scope">
                            {{scope.row.diffqty > 0 ? scope.row.diffqty + '%' : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="oqty" label="日产" min-width="48px" align="center">
                    </el-table-column>
                    <el-table-column prop="mbqtys" label="月标" min-width="56px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqtys" label="月投" min-width="62px" align="center">
                        <template slot-scope="scope">
                            <div :class="scope.row.iqtys < scope.row.mbqtys ? ' error-cell' : ''">{{scope.row.iqtys}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="diffqtys" label="月达成" min-width="60px" align="center">
                        <template slot-scope="scope">
                            {{scope.row.diffqtys > 0 ? scope.row.diffqtys + '%' : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="oqtys" label="月产" min-width="62px" align="center">
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="大底" name="third" lazy>
                <el-form :model="ddInOutForm" :rules="ddRules" ref="ddInOutForm" label-width="60px" size="small" style="margin-top: 10px">
                    <el-row :gutter="10">
                        <el-col :span="18">
                            <el-form-item label="大底" prop="vday" style="font-weight: bold">
                                <el-date-picker
                                    v-model="ddInOutForm.vday"
                                    style="width: 100%"
                                    size="small"
                                    type="date"
                                    align="center"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择件资日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-button 
                                size="small" 
                                type="danger" 
                                icon="el-icon-refresh" 
                                style="width: 100%;"
                                @click="ddSubmitForm('ddInOutForm')">刷新</el-button>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table 
                    :data="ddTotalTableData" 
                    size="mini" 
                    border
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, margin: 0, height: '32px'}" 
                    :row-style="{color: '#F56C6C', fontWeight: 600, align: 'center'}"
                    :cell-style="() => {return 'padding: 0px;margin: 0px;height: 30px;fontSize: 11px'}"
                    style="margin-top: 15px"
                    v-loading="dd_total_table_loading">
                    <el-table-column prop="sec_nm" label="部门" min-width="80px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqty_dd" label="日入库" min-width="54px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqty_mm" label="月入库" min-width="54px" align="center">
                    </el-table-column>
                    <el-table-column prop="oqty_mm" label="月出库" min-width="54px" align="center">    
                    </el-table-column>
                    <!-- <el-table-column prop="sqty" label="库存" min-width="54px" align="center">
                    </el-table-column> -->
                </el-table>
                <el-table 
                    max-height="580"
                    :data="ddTableDynamicData" 
                    size="mini" 
                    :span-method="objectSpanMethod"
                    border 
                    :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, margin: 0, height: '32px'}" 
                    :cell-style="() => {return 'padding: 0px;margin: 0px;height: 30px;fontSize: 11px'}"
                    v-loading="dd_table_dynamic_loading" 
                    style="margin-top: 15px">
                    <el-table-column prop="style" label="工厂型体" fixed min-width="100px" align="center">
                    </el-table-column>
                    <el-table-column prop="artic_no" label="工厂型号" min-width="120px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqty_dd" label="日入库" min-width="48px" align="center">
                    </el-table-column>
                    <el-table-column prop="iqty_mm" label="月入库" min-width="52px" align="center">
                    </el-table-column>
                    <el-table-column prop="oqty_mm" label="月出库" min-width="52px" align="center">
                    </el-table-column>
                    <el-table-column prop="sqty" label="库存" min-width="58px" align="center">
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import moment from 'moment'
export default {
    data() {
        return {
            cxInOutForm: {
                vday: new moment().format("YYYY-MM-DD"),
            },
            bmInOutForm: {
                vday: new moment().format("YYYY-MM-DD"),
            },
            ddInOutForm: {
                vday: new moment().format("YYYY-MM-DD"),
            },
            cxRules: {
                vday: [
                    {required: true, message: '请选择日期', trigger: 'change'}
                ]
            },
            bmRules: {
                vday: [
                    {required: true, message: '请选择日期', trigger: 'change'}
                ]
            },
            ddRules: {
                vday: [
                    {required: true, message: '请选择日期', trigger: 'change'}
                ]
            },
         
            tab: null,
            activeName: 'first',
            bmTotalTableData: [],
            bm_total_table_loading: false,
            cxTotalTableData: [],
            cx_total_table_loading: false,
            ddTotalTableData: [],
            dd_total_table_loading: false,
            cxTableDynamicData: [],
            cx_table_dynamic_loading: false,
            bmTableDynamicData: [],
            bm_table_dynamic_loading: false,
            ddTableDynamicData: [],
            dd_table_dynamic_loading: false,
            mergeObj: {},
            mergeArr: ['style']
        }
    },
    methods: {
        handleClick(tab) {
            this.tab = tab
            if(tab.index === '0' || !tab.index) {
                this.GetCXDynamicData()
            }else if(tab.index === '1') {
                this.GetBMDynamicData()
            }else if(tab.index === '2') {
                this.GetDynamicTotalDD()
                this.GetDDDynamicData()
            }
        },
        cxSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.cxInOutForm)
                    // this.cx_table_loading = true
                    // setTimeout(()=>{
                        // this.getCXData()
                        this.GetCXDynamicData()
                    //     this.cx_table_loading = false
                    // },1000)
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async GetCXDynamicData() {
            this.cxTableDynamicData = []
            this.cx_table_dynamic_loading = true
            this.cxTotalTableData = []
            this.cx_total_table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/InputOutput/InputOutputDynamicData', qs.stringify({
                made_no: '7',
                vday: this.cxInOutForm.vday,
                flag: 0
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                let total_iqty = 0
                let total_iqty1 = 0
                let total_iqty2 = 0
                let total_oqty = 0
                let total_oqty1 = 0
                let total_oqty2 = 0
                let total_iqtys = 0
                let total_iqtys1 = 0
                let total_iqtys2 = 0
                let total_oqtys = 0
                let total_oqtys1 = 0
                let total_oqtys2 = 0
                let total_mbqty = 0
                let total_mbqty1 = 0
                let total_mbqty2 = 0
                let total_mbqtys = 0
                let total_mbqtys1 = 0
                let total_mbqtys2 = 0
                let total_diffqty = 0
                let total_diffqty1 = 0
                let total_diffqty2 = 0
                let total_diffqtys = 0
                let total_diffqtys1 = 0
                let total_diffqtys2 = 0
                let total_zdiffqty = 0
                let total_zdiffqty1 = 0
                let total_zdiffqty2 = 0
                let total_zdiffqtys = 0
                let total_zdiffqtys1 = 0
                let total_zdiffqtys2 = 0
                var groupData = this.GroupBy(res.data.ResponseData, ['sec_nm1'])
                console.log('groupData', groupData)
                groupData.map(group => {
                    var group_iqty = 0
                    var group_iqty1 = 0
                    var group_iqty2 = 0
                    var group_oqty = 0 
                    var group_oqty1 = 0 
                    var group_oqty2 = 0 
                    var group_iqtys = 0
                    var group_iqtys1 = 0
                    var group_iqtys2 = 0
                    var group_oqtys = 0
                    var group_oqtys1 = 0
                    var group_oqtys2 = 0
                    var group_mbqty = 0
                    var group_mbqty1 = 0
                    var group_mbqty2 = 0
                    var group_mbqtys = 0
                    var group_mbqtys1 = 0
                    var group_mbqtys2 = 0
                    var group_diffqty = 0
                    var group_diffqty1 = 0
                    var group_diffqty2 = 0
                    var group_diffqtys = 0
                    var group_diffqtys1 = 0
                    var group_diffqtys2 = 0
                    var group_zdiffqty = 0
                    var group_zdiffqty1 = 0
                    var group_zdiffqty2 = 0
                    var group_zdiffqtys = 0
                    var group_zdiffqtys1 = 0
                    var group_zdiffqtys2 = 0
                    var group_count = 0
                    group.data.map(item => {
                        // item.zqtys = item.oqtys - item.iqtys
                        item.sec_nm = this.SecFormat2(item.sec_nm)
                        if(item.sec_nm1 === '工厂') {
                            if(item.iqty > 0 || item.oqty > 0 || item.iqtys > 0 || item.oqtys > 0) {
                                this.cxTableDynamicData.push(item)
                                // console.log('item', item)
                            }
                        }else {
                            this.cxTableDynamicData.push(item)
                        }

                        group_count ++
                        group_iqty += item.iqty
                        if(item.sec_nm1 === '工厂') {
                            group_iqty2 += item.iqty
                        }else {
                            group_iqty1 += item.iqty
                        }

                        group_oqty += item.oqty
                        if(item.sec_nm1 === '工厂') {
                            group_oqty2 += item.oqty
                        }else {
                            group_oqty1 += item.oqty
                        }

                        group_iqtys += item.iqtys
                        if(item.sec_nm1 === '工厂') {
                            group_iqtys2 += item.iqtys
                        }else {
                            group_iqtys1 += item.iqtys
                        }

                        group_oqtys += item.oqtys
                        if(item.sec_nm1 === '工厂') {
                            group_oqtys2 += item.oqtys
                        }else {
                            group_oqtys1 += item.oqtys
                        }

                        group_mbqty += item.mbqty
                        if(item.sec_nm1 === '工厂') {
                            group_mbqty2 += item.mbqty
                        }else {
                            group_mbqty1 += item.mbqty
                        }

                        group_mbqtys += item.mbqtys
                        if(item.sec_nm1 === '工厂') {
                            group_mbqtys2 += item.mbqtys
                        }else {
                            group_mbqtys1 += item.mbqtys
                        }
                        
                        group_diffqty += item.diffqty
                        if(item.sec_nm1 === '工厂') {
                            group_diffqty2 += item.diffqty
                        }else {
                            group_diffqty1 += item.diffqty
                        }

                        group_diffqtys += item.diffqtys
                        if(item.sec_nm1 === '工厂') {
                            group_diffqtys2 += item.diffqtys
                        }else {
                            group_diffqtys1 += item.diffqtys
                        }

                        group_zdiffqty += item.zdiffqty
                        if(item.sec_nm1 === '工厂') {
                            group_zdiffqty2 += item.zdiffqty
                        }else {
                            group_zdiffqty1 += item.zdiffqty
                        }

                        group_zdiffqtys += item.zdiffqtys
                        if(item.sec_nm1 === '工厂') {
                            group_zdiffqtys2 += item.zdiffqtys
                        }else {
                            group_zdiffqtys1 += item.zdiffqtys
                        }
                    })
                    // 小计
                    if(group_iqty > 0 || group_oqty > 0 || group_iqtys > 0 || group_oqtys > 0) {
                        let sec_nm1 = group.key.sec_nm1.indexOf('成型') > -1 ? group.key.sec_nm1.substring(2, group.key.sec_nm1.length) : group.key.sec_nm1
                        this.cxTableDynamicData.push({
                            // sec_nm: group.key.sec_nm1 + '合计',
                            sec_nm: sec_nm1 + '合计',
                            iqty: group_iqty,
                            mbqty: group_mbqty,
                            oqty: group_oqty,
                            diffqty: (group_diffqty/group_count).toFixed(1),
                            zdiffqty: group_zdiffqty,
                            iqtys: group_iqtys,
                            mbqtys: group_mbqtys,
                            oqtys: group_oqtys,
                            diffqtys: (group_diffqtys/group_count).toFixed(1),
                            zdiffqtys: group_zdiffqtys
                        })
                        // console.log('this.cxTableDynamicData', this.cxTableDynamicData)
                    }

                    total_iqty += group_iqty
                    total_iqty1 += group_iqty1
                    total_iqty2 += group_iqty2
                    total_oqty += group_oqty
                    total_oqty1 += group_oqty1
                    total_oqty2 += group_oqty2
                    total_iqtys += group_iqtys
                    total_iqtys1 += group_iqtys1
                    total_iqtys2 += group_iqtys2
                    total_oqtys += group_oqtys
                    total_oqtys1 += group_oqtys1
                    total_oqtys2 += group_oqtys2
                    total_mbqty += group_mbqty
                    total_mbqty1 += group_mbqty1
                    total_mbqty2 += group_mbqty2
                    total_mbqtys += group_mbqtys
                    total_mbqtys1 += group_mbqtys1
                    total_mbqtys2 += group_mbqtys2
                    total_diffqty += group_diffqty
                    total_diffqty1 += group_diffqty1
                    total_diffqty2 += group_diffqty2
                    total_diffqtys += group_diffqtys
                    total_diffqtys1 += group_diffqtys1
                    total_diffqtys2 += group_diffqtys2
                    total_zdiffqty += group_zdiffqty
                    total_zdiffqty1 += group_zdiffqty1
                    total_zdiffqty2 += group_zdiffqty2
                    total_zdiffqtys += group_zdiffqtys
                    total_zdiffqtys1 += group_zdiffqtys1
                    total_zdiffqtys2 += group_zdiffqtys2
                })

                console.log(total_diffqty)
                console.log(total_diffqty1)
                console.log(total_diffqty2)
                console.log(total_diffqtys)
                console.log(total_diffqtys1)
                console.log(total_diffqtys2)

                this.cxTotalTableData.push({
                    sec_nm: '成一',
                    mbqty: total_mbqty1,
                    iqty: total_iqty1,
                    oqty: total_oqty1,
                    diffqty: (total_iqty1/total_mbqty1*100).toFixed(1),
                    mbqtys: total_mbqtys1,
                    iqtys: total_iqtys1,
                    oqtys: total_oqtys1,
                    diffqtys:  (total_iqtys1/total_mbqtys1*100).toFixed(1),
                    zdiffqty: total_zdiffqty1,
                    zdiffqtys: total_zdiffqtys1,
                })
                this.cxTotalTableData.push({
                    sec_nm: '成二',
                    mbqty: total_mbqty2,
                    iqty: total_iqty2,
                    oqty: total_oqty2,
                    diffqty: (total_iqty2/total_mbqty2*100).toFixed(1),
                    mbqtys: total_mbqtys2,
                    iqtys: total_iqtys2,
                    oqtys: total_oqtys2,
                    diffqtys: (total_iqtys2/total_mbqtys2*100).toFixed(1),
                    zdiffqty: total_zdiffqty2,
                    zdiffqtys: total_zdiffqtys2,
                })
                this.cxTotalTableData.push({
                    sec_nm: '合计',
                    mbqty: total_mbqty,
                    iqty: total_iqty,
                    oqty: total_oqty,
                    diffqty: (total_iqty/total_mbqty*100).toFixed(1),
                    mbqtys: total_mbqtys,
                    iqtys: total_iqtys,
                    oqtys: total_oqtys,
                    diffqtys: (total_iqtys/total_mbqtys*100).toFixed(1),
                    zdiffqty: total_zdiffqty,
                    zdiffqtys: total_zdiffqtys,
                })
            }

            this.cx_table_dynamic_loading = false
            this.cx_total_table_loading = false
        },
        SecFormat1(sec_nm) {
            if(sec_nm.indexOf('成型') === -1) {
                return sec_nm
            }
            else {
                sec_nm = sec_nm.replace('成型', '')
                return this.SecFormat1(sec_nm)
            }
        },
        SecFormat2(sec_nm) {
            if(sec_nm.indexOf('(') === -1) {
                return sec_nm
            }
            else {
                let index1 = sec_nm.indexOf('(')
                let index2 = sec_nm.indexOf(')')
                let sec_nm1 = sec_nm.substring(0, index1)
                let sec_nm2 = sec_nm.substring(index2 + 1, sec_nm.length)
                return this.SecFormat2(sec_nm1 + sec_nm2)
            }
        },
        bmSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.bmInOutForm)
                    // this.bm_table_loading = true
                    // setTimeout(()=>{
                        this.GetBMDynamicData()
                        // this.bm_table_loading = false
                    // },1000)
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async GetBMDynamicData() {
            this.bmTableDynamicData = []
            this.bm_table_dynamic_loading = true
            this.bmTotalTableData = []
            this.bm_total_table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/InputOutput/InputOutputDynamicData', qs.stringify({
                made_no: '3',
                vday: this.bmInOutForm.vday,
                flag: 0
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                let total_iqty = 0
                let total_iqty1 = 0
                let total_iqty2 = 0
                let total_oqty = 0
                let total_oqty1 = 0
                let total_oqty2 = 0
                let total_iqtys = 0
                let total_iqtys1 = 0
                let total_iqtys2 = 0
                let total_oqtys = 0
                let total_oqtys1 = 0
                let total_oqtys2 = 0
                let total_mbqty = 0
                let total_mbqty1 = 0
                let total_mbqty2 = 0
                let total_mbqtys = 0
                let total_mbqtys1 = 0
                let total_mbqtys2 = 0
                let total_diffqty = 0
                let total_diffqty1 = 0
                let total_diffqty2 = 0
                let total_diffqtys = 0
                let total_diffqtys1 = 0
                let total_diffqtys2 = 0
                let total_zdiffqty = 0
                let total_zdiffqty1 = 0
                let total_zdiffqty2 = 0
                let total_zdiffqtys = 0
                let total_zdiffqtys1 = 0
                let total_zdiffqtys2 = 0
                var groupData = this.GroupBy(res.data.ResponseData, ['sec_nm1'])
                // console.log('groupData', groupData)
                groupData.map(group => {
                    var group_iqty = 0
                    var group_iqty1 = 0
                    var group_iqty2 = 0
                    var group_oqty = 0 
                    var group_oqty1 = 0 
                    var group_oqty2 = 0 
                    var group_iqtys = 0
                    var group_iqtys1 = 0
                    var group_iqtys2 = 0
                    var group_oqtys = 0
                    var group_oqtys1 = 0
                    var group_oqtys2 = 0
                    var group_mbqty = 0
                    var group_mbqty1 = 0
                    var group_mbqty2 = 0
                    var group_mbqtys = 0
                    var group_mbqtys1 = 0
                    var group_mbqtys2 = 0
                    var group_diffqty = 0
                    var group_diffqty1 = 0
                    var group_diffqty2 = 0
                    var group_diffqtys = 0
                    var group_diffqtys1 = 0
                    var group_diffqtys2 = 0
                    var group_zdiffqty = 0
                    var group_zdiffqty1 = 0
                    var group_zdiffqty2 = 0
                    var group_zdiffqtys = 0
                    var group_zdiffqtys1 = 0
                    var group_zdiffqtys2 = 0
                    var group_count = 0
                    group.data.map(item => {
                        var index = item.sec_nm.indexOf('原') 
                        if(index > -1) {
                            item.sec_nm = item.sec_nm.substring(0, index - 1)
                        }
                        index = item.sec_nm.indexOf('-')
                        if(index > -1){
                            item.sec_nm = item.sec_nm.substring(0, index)
                        }

                        item.zqtys = item.iqtys - item.oqtys
                        if(item.sec_nm1 === '工厂') {
                            if(item.iqty > 0 || item.oqty > 0 || item.iqtys > 0 || item.oqtys > 0) {
                                this.bmTableDynamicData.push(item)
                            }
                        }else {
                            this.bmTableDynamicData.push(item)
                        }

                        group_count ++
                        group_iqty += item.iqty
                        if(item.sec_nm1 === '工厂') {
                            group_iqty2 += item.iqty
                        }else {
                            group_iqty1 += item.iqty
                        }

                        group_oqty += item.oqty
                        if(item.sec_nm1 === '工厂') {
                            group_oqty2 += item.oqty
                        }else {
                            group_oqty1 += item.oqty
                        }

                        group_iqtys += item.iqtys
                        if(item.sec_nm1 === '工厂') {
                            group_iqtys2 += item.iqtys
                        }else {
                            group_iqtys1 += item.iqtys
                        }

                        group_oqtys += item.oqtys
                        if(item.sec_nm1 === '工厂') {
                            group_oqtys2 += item.oqtys
                        }else {
                            group_oqtys1 += item.oqtys
                        }

                        group_mbqty += item.mbqty
                        if(item.sec_nm1 === '工厂') {
                            group_mbqty2 += item.mbqty
                        }else {
                            group_mbqty1 += item.mbqty
                        }

                        group_mbqtys += item.mbqtys
                        if(item.sec_nm1 === '工厂') {
                            group_mbqtys2 += item.mbqtys
                        }else {
                            group_mbqtys1 += item.mbqtys
                        }
                        
                        group_diffqty += item.diffqty
                        if(item.sec_nm1 === '工厂') {
                            group_diffqty2 += item.diffqty
                        }else {
                            group_diffqty1 += item.diffqty
                        }

                        group_diffqtys += item.diffqtys
                        if(item.sec_nm1 === '工厂') {
                            group_diffqtys2 += item.diffqtys
                        }else {
                            group_diffqtys1 += item.diffqtys
                        }

                        group_zdiffqty += item.zdiffqty
                        if(item.sec_nm1 === '工厂') {
                            group_zdiffqty2 += item.zdiffqty
                        }else {
                            group_zdiffqty1 += item.zdiffqty
                        }

                        group_zdiffqtys += item.zdiffqtys
                        if(item.sec_nm1 === '工厂') {
                            group_zdiffqtys2 += item.zdiffqtys
                        }else {
                            group_zdiffqtys1 += item.zdiffqtys
                        }
                    })
                    // 小计
                    if(group_iqty > 0 || group_oqty > 0 || group_iqtys > 0 || group_oqtys > 0) {
                        let sec_nm1 = group.key.sec_nm1.indexOf('帮面') > -1 ? group.key.sec_nm1.substring(2, group.key.sec_nm1.length) : group.key.sec_nm1
                        this.bmTableDynamicData.push({
                            sec_nm: sec_nm1 + '合计',
                            iqty: group_iqty,
                            mbqty: group_mbqty,
                            oqty: group_oqty,
                            diffqty: (group_diffqty/group_count).toFixed(1),
                            zdiffqty: group_zdiffqty,
                            iqtys: group_iqtys,
                            mbqtys: group_mbqtys,
                            oqtys: group_oqtys,
                            diffqtys: (group_diffqtys/group_count).toFixed(1),
                            zdiffqtys: group_zdiffqtys
                        })
                    }

                    total_iqty += group_iqty
                    total_iqty1 += group_iqty1
                    total_iqty2 += group_iqty2
                    total_oqty += group_oqty
                    total_oqty1 += group_oqty1
                    total_oqty2 += group_oqty2
                    total_iqtys += group_iqtys
                    total_iqtys1 += group_iqtys1
                    total_iqtys2 += group_iqtys2
                    total_oqtys += group_oqtys
                    total_oqtys1 += group_oqtys1
                    total_oqtys2 += group_oqtys2
                    total_mbqty += group_mbqty
                    total_mbqty1 += group_mbqty1
                    total_mbqty2 += group_mbqty2
                    total_mbqtys += group_mbqtys
                    total_mbqtys1 += group_mbqtys1
                    total_mbqtys2 += group_mbqtys2
                    total_diffqty += group_diffqty
                    total_diffqty1 += group_diffqty1
                    total_diffqty2 += group_diffqty2
                    total_diffqtys += group_diffqtys
                    total_diffqtys1 += group_diffqtys1
                    total_diffqtys2 += group_diffqtys2
                    total_zdiffqty += group_zdiffqty
                    total_zdiffqty1 += group_zdiffqty1
                    total_zdiffqty2 += group_zdiffqty2
                    total_zdiffqtys += group_zdiffqtys
                    total_zdiffqtys1 += group_zdiffqtys1
                    total_zdiffqtys2 += group_zdiffqtys2
                })

                console.log(total_diffqty)
                console.log(total_diffqty1)
                console.log(total_diffqty2)
                console.log(total_diffqtys)
                console.log(total_diffqtys1)
                console.log(total_diffqtys2)

                this.bmTotalTableData.push({
                    sec_nm: '帮一',
                    mbqty: total_mbqty1,
                    iqty: total_iqty1,
                    oqty: total_oqty1,
                    diffqty: (total_iqty1/total_mbqty1*100).toFixed(1),
                    mbqtys: total_mbqtys1,
                    iqtys: total_iqtys1,
                    oqtys: total_oqtys1,
                    diffqtys:  (total_iqtys1/total_mbqtys1*100).toFixed(1),
                    zdiffqty: total_zdiffqty1,
                    zdiffqtys: total_zdiffqtys1,
                })
                this.bmTotalTableData.push({
                    sec_nm: '帮二',
                    mbqty: total_mbqty2,
                    iqty: total_iqty2,
                    oqty: total_oqty2,
                    diffqty: (total_iqty2/total_mbqty2*100).toFixed(1),
                    mbqtys: total_mbqtys2,
                    iqtys: total_iqtys2,
                    oqtys: total_oqtys2,
                    diffqtys: (total_iqtys2/total_mbqtys2*100).toFixed(1),
                    zdiffqty: total_zdiffqty2,
                    zdiffqtys: total_zdiffqtys2,
                })
                this.bmTotalTableData.push({
                    sec_nm: '合计',
                    mbqty: total_mbqty,
                    iqty: total_iqty,
                    oqty: total_oqty,
                    diffqty: (total_iqty/total_mbqty*100).toFixed(1),
                    mbqtys: total_mbqtys,
                    iqtys: total_iqtys,
                    oqtys: total_oqtys,
                    diffqtys: (total_iqtys/total_mbqtys*100).toFixed(1),
                    zdiffqty: total_zdiffqty,
                    zdiffqtys: total_zdiffqtys,
                })
            }

            this.bm_table_dynamic_loading = false
            this.bm_total_table_loading = false
        },
        ddSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.ddInOutForm)
                    this.GetDynamicTotalDD()
                    this.GetDDDynamicData()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async GetDynamicTotalDD() {
            this.ddTotalTableData = []
            this.dd_total_table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/InputOutput/GetInputOutputDynamicTotalDD', qs.stringify({
                vday: this.ddInOutForm.vday,
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                res.data.ResponseData.forEach(item => {
                    this.ddTotalTableData.push(item)
                })
            }

            this.dd_total_table_loading = false
        },
        async GetDDDynamicData() {
            this.ddTableDynamicData = []
            this.dd_table_dynamic_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/InputOutput/GetInputOutputDynamicDataDD', qs.stringify({
                vday: this.bmInOutForm.vday,
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.getSpanArr(res.data.ResponseData)

                // let total_iqty = 0
                // let total_iqty1 = 0
                // let total_iqty2 = 0
                // let total_oqty = 0
                // let total_oqty1 = 0
                // let total_oqty2 = 0
                // let total_iqtys = 0
                // let total_iqtys1 = 0
                // let total_iqtys2 = 0
                // let total_oqtys = 0
                // let total_oqtys1 = 0
                // let total_oqtys2 = 0
                // let total_mbqty = 0
                // let total_mbqty1 = 0
                // let total_mbqty2 = 0
                // let total_mbqtys = 0
                // let total_mbqtys1 = 0
                // let total_mbqtys2 = 0
                // let total_diffqty = 0
                // let total_diffqty1 = 0
                // let total_diffqty2 = 0
                // let total_diffqtys = 0
                // let total_diffqtys1 = 0
                // let total_diffqtys2 = 0
                // let total_zdiffqty = 0
                // let total_zdiffqty1 = 0
                // let total_zdiffqty2 = 0
                // let total_zdiffqtys = 0
                // let total_zdiffqtys1 = 0
                // let total_zdiffqtys2 = 0
                var groupData = this.GroupBy(res.data.ResponseData, ['style'])
                console.log('groupData', groupData)
                groupData.map(group => {
                    // var group_iqty = 0
                    // var group_iqty1 = 0
                    // var group_iqty2 = 0
                    // var group_oqty = 0 
                    // var group_oqty1 = 0 
                    // var group_oqty2 = 0 
                    // var group_iqtys = 0
                    // var group_iqtys1 = 0
                    // var group_iqtys2 = 0
                    // var group_oqtys = 0
                    // var group_oqtys1 = 0
                    // var group_oqtys2 = 0
                    // var group_mbqty = 0
                    // var group_mbqty1 = 0
                    // var group_mbqty2 = 0
                    // var group_mbqtys = 0
                    // var group_mbqtys1 = 0
                    // var group_mbqtys2 = 0
                    // var group_diffqty = 0
                    // var group_diffqty1 = 0
                    // var group_diffqty2 = 0
                    // var group_diffqtys = 0
                    // var group_diffqtys1 = 00
                    // var group_diffqtys2 = 0
                    // var group_zdiffqty = 0
                    // var group_zdiffqty1 = 0
                    // var group_zdiffqty2 = 0
                    // var group_zdiffqtys = 0
                    // var group_zdiffqtys1 = 0
                    // var group_zdiffqtys2 = 0
                    // var group_count = 0

                    // var group_iqty_dd = 0
                    // var group_iqty_mm = 0
                    // var group_oqty_mm = 0
                    // var group_sqty = 0

                    group.data.map(item => {
                        // var index = item.sec_nm.indexOf('原') 
                        // if(index > -1) {
                        //     item.sec_nm = item.sec_nm.substring(0, index - 1)
                        // }
                        // index = item.sec_nm.indexOf('-')
                        // if(index > -1){
                        //     item.sec_nm = item.sec_nm.substring(0, index)
                        // }

                        // item.zqtys = item.iqtys - item.oqtys
                        // if(item.sec_nm1 === '工厂') {
                        //     if(item.iqty > 0 || item.oqty > 0 || item.iqtys > 0 || item.oqtys > 0) {
                        //         this.bmTableDynamicData.push(item)
                        //     }
                        // }else {
                        //     this.bmTableDynamicData.push(item)
                        // }

                        this.ddTableDynamicData.push(item)

                        // group_count ++
                        // group_iqty_dd += item.iqty_dd    
                        // group_iqty_mm += item.iqty_mm    
                        // group_oqty_mm += item.oqty_mm    
                        // group_sqty += item.sqty

                        // group_iqty += item.iqty
                        // if(item.sec_nm1 === '工厂') {
                        //     group_iqty2 += item.iqty
                        // }else {
                        //     group_iqty1 += item.iqty
                        // }

                        // group_oqty += item.oqty
                        // if(item.sec_nm1 === '工厂') {
                        //     group_oqty2 += item.oqty
                        // }else {
                        //     group_oqty1 += item.oqty
                        // }

                        // group_iqtys += item.iqtys
                        // if(item.sec_nm1 === '工厂') {
                        //     group_iqtys2 += item.iqtys
                        // }else {
                        //     group_iqtys1 += item.iqtys
                        // }

                        // group_oqtys += item.oqtys
                        // if(item.sec_nm1 === '工厂') {
                        //     group_oqtys2 += item.oqtys
                        // }else {
                        //     group_oqtys1 += item.oqtys
                        // }

                        // group_mbqty += item.mbqty
                        // if(item.sec_nm1 === '工厂') {
                        //     group_mbqty2 += item.mbqty
                        // }else {
                        //     group_mbqty1 += item.mbqty
                        // }

                        // group_mbqtys += item.mbqtys
                        // if(item.sec_nm1 === '工厂') {
                        //     group_mbqtys2 += item.mbqtys
                        // }else {
                        //     group_mbqtys1 += item.mbqtys
                        // }
                        
                        // group_diffqty += item.diffqty
                        // if(item.sec_nm1 === '工厂') {
                        //     group_diffqty2 += item.diffqty
                        // }else {
                        //     group_diffqty1 += item.diffqty
                        // }

                        // group_diffqtys += item.diffqtys
                        // if(item.sec_nm1 === '工厂') {
                        //     group_diffqtys2 += item.diffqtys
                        // }else {
                        //     group_diffqtys1 += item.diffqtys
                        // }

                        // group_zdiffqty += item.zdiffqty
                        // if(item.sec_nm1 === '工厂') {
                        //     group_zdiffqty2 += item.zdiffqty
                        // }else {
                        //     group_zdiffqty1 += item.zdiffqty
                        // }

                        // group_zdiffqtys += item.zdiffqtys
                        // if(item.sec_nm1 === '工厂') {
                        //     group_zdiffqtys2 += item.zdiffqtys
                        // }else {
                        //     group_zdiffqtys1 += item.zdiffqtys
                        // }
                    })
                    // 小计
                    // if(group_iqty > 0 || group_oqty > 0 || group_iqtys > 0 || group_oqtys > 0) {
                    //     let sec_nm1 = group.key.sec_nm1.indexOf('帮面') > -1 ? group.key.sec_nm1.substring(2, group.key.sec_nm1.length) : group.key.sec_nm1
                    //     this.ddTableDynamicData.push({
                    //         sec_nm: sec_nm1 + '合计',
                    //         iqty: group_iqty,
                    //         mbqty: group_mbqty,
                    //         oqty: group_oqty,
                    //         diffqty: (group_diffqty/group_count).toFixed(1),
                    //         zdiffqty: group_zdiffqty,
                    //         iqtys: group_iqtys,
                    //         mbqtys: group_mbqtys,
                    //         oqtys: group_oqtys,
                    //         diffqtys: (group_diffqtys/group_count).toFixed(1),
                    //         zdiffqtys: group_zdiffqtys
                    //     })
                    // }

                    // total_iqty += group_iqty
                    // total_iqty1 += group_iqty1
                    // total_iqty2 += group_iqty2
                    // total_oqty += group_oqty
                    // total_oqty1 += group_oqty1
                    // total_oqty2 += group_oqty2
                    // total_iqtys += group_iqtys
                    // total_iqtys1 += group_iqtys1
                    // total_iqtys2 += group_iqtys2
                    // total_oqtys += group_oqtys
                    // total_oqtys1 += group_oqtys1
                    // total_oqtys2 += group_oqtys2
                    // total_mbqty += group_mbqty
                    // total_mbqty1 += group_mbqty1
                    // total_mbqty2 += group_mbqty2
                    // total_mbqtys += group_mbqtys
                    // total_mbqtys1 += group_mbqtys1
                    // total_mbqtys2 += group_mbqtys2
                    // total_diffqty += group_diffqty
                    // total_diffqty1 += group_diffqty1
                    // total_diffqty2 += group_diffqty2
                    // total_diffqtys += group_diffqtys
                    // total_diffqtys1 += group_diffqtys1
                    // total_diffqtys2 += group_diffqtys2
                    // total_zdiffqty += group_zdiffqty
                    // total_zdiffqty1 += group_zdiffqty1
                    // total_zdiffqty2 += group_zdiffqty2
                    // total_zdiffqtys += group_zdiffqtys
                    // total_zdiffqtys1 += group_zdiffqtys1
                    // total_zdiffqtys2 += group_zdiffqtys2


                // console.log(group_iqty_dd)
                // console.log(group_iqty_mm)
                // console.log(group_oqty_mm)
                // console.log(group_sqty)

                })

                // console.log(this.GetDDDynamicData())
              

                // console.log(total_diffqty)
                // console.log(total_diffqty1)
                // console.log(total_diffqty2)
                // console.log(total_diffqtys)
                // console.log(total_diffqtys1)
                // console.log(total_diffqtys2)

                // this.bmTotalTableData.push({
                //     sec_nm: '帮一',
                //     mbqty: total_mbqty1,
                //     iqty: total_iqty1,
                //     oqty: total_oqty1,
                //     diffqty: (total_iqty1/total_mbqty1*100).toFixed(1),
                //     mbqtys: total_mbqtys1,
                //     iqtys: total_iqtys1,
                //     oqtys: total_oqtys1,
                //     diffqtys:  (total_iqtys1/total_mbqtys1*100).toFixed(1),
                //     zdiffqty: total_zdiffqty1,
                //     zdiffqtys: total_zdiffqtys1,
                // })
                // this.bmTotalTableData.push({
                //     sec_nm: '帮二',
                //     mbqty: total_mbqty2,
                //     iqty: total_iqty2,
                //     oqty: total_oqty2,
                //     diffqty: (total_iqty2/total_mbqty2*100).toFixed(1),
                //     mbqtys: total_mbqtys2,
                //     iqtys: total_iqtys2,
                //     oqtys: total_oqtys2,
                //     diffqtys: (total_iqtys2/total_mbqtys2*100).toFixed(1),
                //     zdiffqty: total_zdiffqty2,
                //     zdiffqtys: total_zdiffqtys2,
                // })
                // this.bmTotalTableData.push({
                //     sec_nm: '合计',
                //     mbqty: total_mbqty,
                //     iqty: total_iqty,
                //     oqty: total_oqty,
                //     diffqty: (total_iqty/total_mbqty*100).toFixed(1),
                //     mbqtys: total_mbqtys,
                //     iqtys: total_iqtys,
                //     oqtys: total_oqtys,
                //     diffqtys: (total_iqtys/total_mbqtys*100).toFixed(1),
                //     zdiffqty: total_zdiffqty,
                //     zdiffqtys: total_zdiffqtys,
                // })
            }

            this.dd_table_dynamic_loading = false
            // this.dd_total_table_loading = false
        },
        GroupBy(datas, keys, callBack) {
            const list = datas || []
            const groups = []
            list.forEach(v => {
                const key = {}
                // const data = {}
                keys.forEach(k => {
                    key[k] = v[k]
                })
                let group = groups.find(v => {
                    return v._key === JSON.stringify(key)
                })
                if(!group) {
                    group = {
                        _key: JSON.stringify(key),
                        key: key
                    }
                    groups.push(group)
                }
                if(callBack) {
                    group.data = callBack(group.data, v)
                    group.total = group.total || 0
                    group.total++
                }else {
                    group.data = group.data || []
                    group.data.push(v)
                }
            })
            return groups
        },
        tableRowClassName({row}) {
            // console.log('row', row)
            // console.log('rowIndex', rowIndex)
            if(row.sec_nm.indexOf('合计') > -1) {
                return 'error-row'
            }
            // if (rowIndex === 1) {
            //     return 'warning-row'
            // } else if (rowIndex === 3) {
            //     return 'success-row'
            // }
            return ''
        },
        tableRowClassName2({row}) {
            if(row.sec_nm.indexOf('合计') > -1) {
                // console.log('row', row)
                return 'error-row'
            }
            return ''
        },
        // objectSpanMethod方法
        // 默认接受四个值 { 当前行的值, 当前列的值, 行的下标, 列的下标 }
        objectSpanMethod({ row, column, rowIndex }) {
            console.log('row', row)
            // console.log('columnIndex', columnIndex)
            // 判断列的属性
            if(this.mergeArr.indexOf(column.property) !== -1) { 
                // 判断其值是不是为0 
                if(this.mergeObj[column.property][rowIndex]) { 
                    return [this.mergeObj[column.property][rowIndex], 1]
                } else {
                    // 如果为0则为需要合并的行
                    return [0, 0] 
                }
            }
        },
        // getSpanArr方法
        getSpanArr(data) {
            this.mergeArr.forEach((key) => {
                let count = 0 // 用来记录需要合并行的起始位置
                this.mergeObj[key] = [] // 记录每一列的合并信息
                // console.log('index1', index1)
                data.forEach((item, index) => {
                    // index == 0表示数据为第一行，直接 push 一个 1
                    if(index === 0) {
                        this.mergeObj[key].push(1) 
                    } else {
                        // 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
                        if(item[key] === data[index - 1][key]) { 
                            this.mergeObj[key][count] += 1
                            this.mergeObj[key].push(0)
                        } else {
                            // 如果当前行和上一行其值不相等 
                            count = index // 记录当前位置 
                            this.mergeObj[key].push(1) // 重新push 一个 1
                        }
                    }
                })
            })
        }
    },
    mounted() {
        this.GetCXDynamicData()
    }
}
</script>

<style>
    body {
        margin: 0;
    }
    .total_data{
        font-size: 14px;
        font-weight: bold;
        color: #F56C6C;
    }
    .total_data_bm{
        font-size: 13px;
        font-weight: 600;
        color: #E6A23C;
    }
    .el-table .error-row {
        font-weight: 500;
        color: white;
        background: #66B1FF;
    }
    .error-cell {
        color: #F56C6C;
        font-weight: 700;
    }
</style>