<template>
    <div>
        <el-row :gutter="15" style="margin-bottom: 25px;">
            <el-col :span="12">
                <router-link to="/ehsCheckPersonReport">
                    <el-button plain size="small" style="width: 100%" type="danger">管理扣分报表</el-button>
                </router-link>
            </el-col>
            <el-col :span="12">
                <router-link to="/ehsCheckCreate2">
                    <el-button plain size="small" style="width: 100%" type="primary">新增EHS稽查</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-row style="margin-bottom: 10px;">
            <el-col :span="10">
                <el-button 
                    @click="drawer = true" 
                    round style="width: 100%" 
                    plain 
                    icon="el-icon-search" 
                    type="info" 
                    size="small">搜索</el-button>
            </el-col>
            <el-col :span="12" style="text-align: right; color: #F56C6C;font-weight: bold;font-size: 12px;margin-top: 10px">
                注：双击稽查明细，提交整改
            </el-col>
            <el-col :span="2" style="margin-top: 5px;text-align: right;">
                <el-popover
                    placement="left-start"
                    width="260"
                    trigger="click">
                    <el-table :data="RemarkData" size="mini">
                        <el-table-column width="50" property="title" label="颜色">
                            <template slot-scope="scope">
                                <div :style="scope.row.title.style">{{scope.row.title.label}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column width="210" property="remark" label="说明"></el-table-column>
                    </el-table>
                    <i slot="reference" class="el-icon-question"></i>
                    <!-- <el-button slot="reference">click 激活</el-button> -->
                </el-popover>
            </el-col>
        </el-row>
        <el-drawer
            :visible.sync="drawer"
            :direction="direction"
            size="300px"
            :with-header="false">
            <div style="margin: 20px;">   
                <el-row :gutter="10" style="margin-bottom: 25px;">
                    <el-col :span="12">
                        <el-date-picker
                            v-model="fstart"
                            style="width: 100%;"
                            size="small"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            @change="date_change1"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="12">
                        <el-date-picker
                            v-model="fend"
                            style="width: 100%;"
                            size="small"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            @change="date_change2"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-col>
                </el-row>
                <el-row :gutter="10" style="margin-bottom: 25px">
                    <el-col :span="3" style="font-weight: bold;margin-top: 3px">类别</el-col>
                    <el-col :span="21">
                        <el-select @change="type_change" size="small" v-model="check_type" filterable placeholder="请选择" clearable style="width: 100%">
                            <el-option
                                v-for="item in check_types"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row :gutter="10" style="margin-bottom: 25px">
                    <el-col :span="4" style="font-weight: bold;margin-top: 3px">责任人</el-col>
                    <el-col :span="20">
                        <el-cascader
                            size="small"
                            :show-all-levels="false"
                            v-model="pnl2"
                            clearable
                            @change="pnl2_change"
                            style="width: 100%"
                            placeholder="请选择责任人"
                            :options="options">
                        </el-cascader>
                    </el-col>
                </el-row>
                <el-row :gutter="10" style="margin-bottom: 25px">
                    <el-col :span="3" style="font-weight: bold;margin-top: 3px">区域</el-col>
                    <!-- <el-col :span="6" v-for="item in TagDistrictDatas" :key="item.tag_type" style="margin-right: 10px">
                        <el-badge :value="item.badge_value === '0' ? null : item.badge_value" class="item" style="width: 100%"> 
                            <el-tag
                                size="medium"
                                style="width: 100%;font-weight: bold; font-size: 14px"
                                :type="item.tag_type"
                                @click="tag_district_click(item)"
                                :effect="item.tag_effect">
                                {{item.label}}
                            </el-tag>
                        </el-badge>
                    </el-col> -->
                    <el-col :span="21">
                        <el-select @change="district_change" size="small" v-model="district_id" filterable placeholder="请选择" clearable style="width: 100%">
                            <el-option
                                v-for="item in DistrictDatas"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row :gutter="10" style="margin-bottom: 25px">
                    <el-col :span="3" style="font-weight: bold;margin-top: 3px">状态</el-col>
                    <el-col :span="6" v-for="item in TagDatas" :key="item.tag_type" style="margin-right: 10px">
                        <el-badge :value="item.badge_value === '0' ? null : item.badge_value" class="item" style="width: 100%"> 
                            <el-tag
                                size="medium"
                                style="width: 100%;font-weight: bold; font-size: 14px"
                                :type="item.tag_type"
                                @click="tag_click(item)"
                                :effect="item.tag_effect">
                                {{item.label}}
                            </el-tag>
                        </el-badge>
                    </el-col>
                </el-row>
                <el-row :gutter="10" style="margin-top:20px">
                    <el-col :span="12">
                        <el-button style="width: 100%" size="small" @click="drawer = false">取消</el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-button style="width: 100%" size="small" type="primary" @click="GetEhsCheckList">提交</el-button>
                    </el-col>
                </el-row>
            </div>     
        </el-drawer>
        <el-table :data="check_lists" 
            :show-header="false" 
            stripe 
            v-loading="table_loading" 
            size="mini" 
            @row-dblclick="RoomCheckReview">
            <el-table-column prop="col1">
                <template slot-scope="scope">
                    <div>
                        <span style="margin-right: 5px">
                            <el-tag v-if="scope.row.CHECK_STATE === '待处理'" size="mini" effect="dark" type="danger">{{scope.row.CHECK_STATE}} </el-tag>
                            <el-tag v-if="scope.row.CHECK_STATE === '跟进中'" size="mini" effect="dark" type="primary">{{scope.row.CHECK_STATE}} </el-tag>
                            <el-tag v-if="scope.row.CHECK_STATE === '已完成'" size="mini" effect="dark" type="success">{{scope.row.CHECK_STATE}} </el-tag>
                        </span>
                        <span style="margin-right: 5px">
                            <el-badge v-if="scope.row.CHECK_STATE === '待处理'" :value="scope.row.diff_day" class="item" type="warning"></el-badge>
                        </span>
                        <span style="color: #606266; font-weight: bold; font-size: 14px">{{scope.row.DISTRICT}}</span>,
                        <span style="color: #606266; font-weight: bold; font-size: 14px">{{scope.row.PERSON2}}</span>,
                        <span style="color: #606266; font-weight: bold; font-size: 14px">{{scope.row.DANGER_TYPE1}}</span>,
                        <span style="color: #606266; font-weight: bold; font-size: 14px">{{scope.row.DANGER_TYPE2}}</span>
                    </div>
                    <div>
                        <span style="margin-right: 5px" v-if="scope.row.have_image !== 0">
                            <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(scope.row.DB_ID)">
                                <i class="el-icon-picture-outline"></i> 图片
                            </el-link>
                        </span>
                        <span style="font-size: 11px;color: #606266">{{scope.row.CHECK_DESCRIBE}}</span>
                    </div>
                    <div>
                        <span style="margin-right: 5px;" v-if="scope.row.have_image2 !== 0">
                            <el-link style="font-weight: bold;font-size: 13px;color: #67C23A" @click="ShowImages(scope.row.HANDLE_ID)">
                                <i class="el-icon-picture-outline"></i> 整改图片
                            </el-link>
                        </span>
                        <span style="font-size: 11px;">{{scope.row.HANDLE_EXPLAIN}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="col2" align="right" width="40px">
                <template slot-scope="scope">
                    <div style="font-weight: bold; font-size: 13px;">
                        <el-tag v-if="scope.row.DANGER_LEVEL === '红'" size="mini"  type="danger">
                            {{scope.row.DANGER_LEVEL}}
                        </el-tag>
                        <el-tag v-if="scope.row.DANGER_LEVEL === '橙'" size="mini"  type="warning">
                            {{scope.row.DANGER_LEVEL}}
                        </el-tag>
                        <el-tag v-if="scope.row.DANGER_LEVEL === '黄'" size="mini" style="color: gold" color="lightyellow">
                            {{scope.row.DANGER_LEVEL}}
                        </el-tag>
                        <el-tag v-if="scope.row.DANGER_LEVEL === '蓝'" size="mini"  type="">
                            {{scope.row.DANGER_LEVEL}}
                        </el-tag>
                    </div>
                    <div style="font-weight: bold; font-size: 13px">
                        <el-tag
                            size="mini"
                            type="danger"
                           >
                            {{scope.row.CHECK_POINTS}}
                        </el-tag>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import moment from 'moment'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            check_lists: [],
            table_loading: false,
            fstart: '',
            fend: '',
            pnl: '',
            fstate: '',
            drawer: false,
            direction: 'ttb',
            district_id: '',
            check_type: '',
            TagDatas: [
                {badge_value: '0', tag_type: 'danger', tag_effect: 'plain', label: '待处理'},
                {badge_value: '0', tag_type: 'primary', tag_effect: 'plain', label: '跟进中'},
                {badge_value: '0', tag_type: 'success', tag_effect: 'plain', label: '已完成'},
            ],
            RemarkData: [
                {title: {label: '红色', style: {backgroundColor: '#F56C6C'}}, remark: '隐患通知书，重要、重大隐患'},
                {title: {label: '橙色', style: {backgroundColor: '#E6A23C'}}, remark: '需要厂外资源改善的隐患'},
                {title: {label: '黄色', style: {backgroundColor: 'yellow'}}, remark: '需要跨部门支持改善的隐患'},
                {title: {label: '蓝色', style: {backgroundColor: '#409EFF'}}, remark: '本单位可以立即改善的隐患'},
            ],
            TagDistrictDatas: [],
            options: [],
            pnl2: '',
            check_types: [{
                label: '日检',
                value: '日检',
                fid: '01'
            },{
                label: '月检A',
                value: '月检A',
                fid: '02'
            },{
                label: '月检B',
                value: '月检B',
                fid: '03'
            },{
                label: '季检',
                value: '季检',
                fid: '04'
            },{
                label: '年检',
                value: '年检',
                fid: '05'
            },{
                label: '值班检',
                value: '值班检',
                fid: '06'
            },{
                label: '节前检',
                value: '节前检',
                fid: '07'
            },{
                label: '高管检',
                value: '高管检',
                fid: '08'
            },{
                label: '客户检',
                value: '客户检',
                fid: '09'
            },{
                label: '政府检',
                value: '政府检',
                fid: '10'
            }],
            DistrictDatas: []
        }
    },
    methods: {
        date_change1() {
            this.GetPersonCounts()
            this.GetDistrictDatas()
            this.GetDataCounts()
        },
        date_change2() {
            this.GetPersonCounts()
            this.GetDistrictDatas()
            this.GetDataCounts()
        },
        type_change() {
            // this.GetPersonCounts()
            // this.GetDistrictDatas()
            // this.GetDataCounts()
        },
        pnl2_change() {
            this.GetDistrictDatas()
            this.GetDataCounts()
        },
        district_change() {
            this.GetDataCounts()
        },
        tag_click(item) {
            // console.log('item', item)
            this.TagDatas.forEach(el => {
                if(item.tag_type === el.tag_type) {
                    el.tag_effect = 'dark'
                    this.fstate = el.label
                } else {
                    el.tag_effect = 'plain'
                }
            })
            // this.GetDistrictCounts()
        },
        tag_district_click(item) {
            // console.log('item', item)
            this.TagDistrictDatas.forEach(el => {
                if(item.tag_type === el.tag_type) {
                    el.tag_effect = el.tag_effect === 'plain' ? 'dark' : 'plain'
                    if(el.tag_effect === 'dark'){
                        this.district_id = el.type
                    }else {
                        this.district_id = ''
                    }
                } 
            })

            this.GetDataCounts()
        },
        async GetDataCounts() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetEhsListCounts', qs.stringify({
                fstart: this.fstart,
                fend: this.fend,
                check_type: this.check_type,
                pnl2: this.pnl2[1],
                district_id: this.district_id,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                res.data.ResponseData.map(item => {
                    this.TagDatas.forEach(el => {
                        if(el.label === item.CHECK_STATE) {
                            el.badge_value = item.STATE_COUNT === 0 ? null : item.STATE_COUNT
                        }
                    })
                })
            }else {
                this.$message({
                    message: '获取EHS数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        // async GetDistrictCounts(){
        //     const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetDistrictCounts', qs.stringify({
        //         fstart: this.fstart,
        //         fend: this.fend,
        //         pnl: this.pnl,
        //         fstate: this.fstate
        //     }))
        //     console.log(res)
        //     this.TagDistrictDatas = []
        //     if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
        //         res.data.ResponseData.map(item => {
        //             this.TagDistrictDatas.push({
        //                 badge_value: item.DISTRICT_COUNT,
        //                 tag_type: 'primary',
        //                 tag_effect: item.DISTRICT_ID === this.district_id ? 'dark' : 'plain',
        //                 label: item.DISTRICT,
        //                 type: item.DISTRICT_ID
        //             })
        //         })
        //         console.log('this.TagDistrictDatas',  this.TagDistrictDatas)
        //     }else {
        //         this.$message({
        //             message: '获取EHS数据失败：' + res.data.Message,
        //             center: true,
        //             type: 'error'
        //         })
        //     }
        // },
        async GetDistrictDatas(){
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetDistrictDatas', qs.stringify({
                fstart: this.fstart,
                fend: this.fend,
                check_type: this.check_type,
                pnl2: this.pnl2[1],
                fstate: this.fstate
            }))
            console.log(res)
            this.DistrictDatas = []
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                res.data.ResponseData.map(item => {
                    this.DistrictDatas.push({
                        value: item.DISTRICT_ID,
                        label: item.PCODE + '-' + item.DISTRICT,
                    })
                })
                console.log('this.DistrictDatas',  this.DistrictDatas)
            }else {
                this.$message({
                    message: '获取EHS数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetEhsCheckList() {
            this.drawer = false
            this.table_loading = true
            this.check_lists = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetEhsCheckList', qs.stringify({
                fstart: this.fstart,
                fend: this.fend,
                ftype: this.check_type,
                pnl: this.pnl,
                fstate: this.fstate,
                district_id: this.district_id,
                pnl2: this.pnl2[1]
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.check_lists = res.data.ResponseData
            }
            
            this.table_loading = false
        },
        RoomCheckReview(row) {
            console.log('row', row)
            if((row.is_pass === 1 || row.DB_CREATE_USERNO === this.pnl) && (row.DANGER_LEVEL === '蓝' || row.DANGER_LEVEL === '黄')) {
                window.location.href = '/ehsCheckDetail/' + row.DB_ID
            }else {
                this.$message({
                    message: '请正确选择待处理问题！',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
            }
        },
        async ShowImages(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                            path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
            }
        },
        async GetPersonCounts() {
            this.options = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetCheckPersonData')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.options = this.GroupBy(res.data.ResponseData, 'PNAME1')
                console.log('options', this.options)
            }
        },
        GroupBy(datas, key, callBack) {
            const list = datas || []
            const groups = []
            list.forEach(v => {
                let key = v.PNL1
                // keys.forEach(k => {
                //     key[k] = v[k]
                // })
                let group = groups.find(v => {
                    return v.value === key
                })
                if(!group) {
                    group = {
                        label: v.PNAME1,
                        value: v.PNL1
                    }
                    groups.push(group)
                }
                if(callBack) {
                    group.children = callBack(group.children, v)
                    // group.total = group.total || 0
                    // group.total++
                }else {
                    group.children = group.children || []
                    group.children.push({
                        label: v.PNAME2,
                        value: v.PNL2
                    })
                }
            })
            return groups
        },
    },
    mounted() {
        this.fstart = moment().startOf('month').format('YYYY-MM-DD')
        // this.fstart = '2023-05-01'
        this.fend = moment().endOf('month').format('YYYY-MM-DD')
        this.GetDataCounts()
        // this.GetDistrictCounts()
        this.GetDistrictDatas()
        this.GetPersonCounts()
        this.GetEhsCheckList()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
            // this.pnl = '68677'
            // this.pnl = '61558'
            // this.pnl = '50283'
            // this.pnl = '58250'

            // this.pnl = '0332' // dev
            // this.pnl = '62532' // dev
            // this.pnl = '62145' // dev
            // this.pnl = '64181' // dev
        }
    }
}
</script>