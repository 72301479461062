<template>
    <div>
        <div v-if="on_job">
            <el-card class="box-card" shadow="never">
                <h4>查询</h4>
                <el-row :gutter="30">
                    <el-col :span="6">
                        <router-link to="/pieceCapital">
                            <div>
                            <el-image class="app-icon" fit="contain" :src="require('@/assets/icon/md/员工件资.png')"></el-image>
                            </div>
                        <span class="app-span">员工件资</span>
                        </router-link> 
                    </el-col>
                    <el-col :span="6">
                        <router-link to="/pieceCapitalStatus2">
                            <div>
                            <el-image class="app-icon" fit="contain" :src="require('@/assets/icon/md/件资状况.png')"></el-image>
                            </div>
                        <span class="app-span">件资状况</span>
                        </router-link> 
                    </el-col>
                    <el-col :span="6">
                        <router-link to="/pieceCapitalLeave">
                            <div>
                            <el-image class="app-icon" fit="contain" :src="require('@/assets/icon/md/离职件资.png')"></el-image>
                            </div>
                        <span class="app-span">离职件资</span>
                        </router-link> 
                    </el-col>
                </el-row>
            </el-card>
            <el-card class="box-card" shadow="never">
                <h4>员工电子账单</h4>
                <el-row :gutter="30">
                    <el-col :span="6">
                        <router-link to="/partProcess">
                            <div>
                            <el-image class="app-icon" fit="contain" :src="require('@/assets/icon/md/工艺车间.png')"></el-image>
                            </div>
                        <span class="app-span">工艺车间</span>
                        </router-link> 
                    </el-col>
                    <el-col :span="6">
                        <router-link to="/surfaceProcess">
                            <div>
                            <el-image class="app-icon" fit="contain" :src="require('@/assets/icon/md/帮面件资.png')"></el-image>
                            </div>
                        <span class="app-span">帮面件资</span>
                        </router-link> 
                    </el-col>
                    <el-col :span="6">
                        <router-link to="/formingProcess">
                            <div>
                            <el-image class="app-icon" fit="contain" :src="require('@/assets/icon/md/成型件资.png')"></el-image>
                            </div>
                        <span class="app-span">成型件资</span>
                        </router-link> 
                    </el-col>
                    <el-col :span="6">
                        <router-link to="/blankingProcess">
                            <div>
                            <el-image class="app-icon" fit="contain" :src="require('@/assets/icon/md/冲裁件资.png')"></el-image>
                            </div>
                        <span class="app-span">冲裁件资</span>
                        </router-link> 
                    </el-col>
                </el-row>
                <el-row :gutter="30">
                    <el-col :span="6">
                        <router-link to="/shoelacesProcess">
                            <div>
                            <el-image class="app-icon" fit="contain" :src="require('@/assets/icon/md/鞋带组.png')"></el-image>
                            </div>
                        <span class="app-span">鞋带组</span>
                        </router-link> 
                    </el-col>
                    <el-col :span="6">
                        <router-link to="/packagingProcess">
                            <div>
                            <el-image class="app-icon" fit="contain" :src="require('@/assets/icon/md/大包装.png')"></el-image>
                            </div>
                        <span class="app-span">大包装</span>
                        </router-link> 
                    </el-col>
                </el-row>    
            </el-card>
            <el-card class="box-card" shadow="never">
                <h4>审核</h4>
                <el-row :gutter="30">
                    <el-col :span="6">
                        <router-link to="/surfaceProcessAudit">
                            <div>
                            <el-image class="app-icon" fit="contain" :src="require('@/assets/icon/md/帮面审核.png')"></el-image>
                            </div>
                        <span class="app-span">帮面审核</span>
                        </router-link> 
                    </el-col>
                    <el-col :span="6">
                        <router-link to="/formingProcessAudit">
                            <div>
                            <el-image class="app-icon" fit="contain" :src="require('@/assets/icon/md/成型审核.png')"></el-image>
                            </div>
                        <span class="app-span">成型审核</span>
                        </router-link> 
                    </el-col>
                    <el-col :span="6">
                        <router-link to="/ShoelacesProcessAudit">
                            <div>
                            <el-image class="app-icon" fit="contain" :src="require('@/assets/icon/md/鞋带组审核.png')"></el-image>
                            </div>
                        <span class="app-span">鞋带组审核</span>
                        </router-link> 
                    </el-col>
                    <el-col :span="6">
                        <router-link to="/packagingProcessAudit">
                            <div>
                            <el-image class="app-icon" fit="contain" :src="require('@/assets/icon/md/大包装审核.png')"></el-image>
                            </div>
                        <span class="app-span">大包装审核</span>
                        </router-link> 
                    </el-col>
                </el-row>
            </el-card>
        </div>
        <el-result 
            icon="error" 
            title="您访问的资源不存在"  
            v-if="!on_job">
            <template slot="extra">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-button type="primary" style="width: 100%" size="medium" @click="PageClose">关闭</el-button>
                    </el-col>
                </el-row>
            </template>
        </el-result>
    </div>
</template>

<script>
import conf from '../../conf'
import qs from 'qs'
import axios from 'axios'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            on_job: true,
            pnl: ''
        }
    },
    methods: {
        async CheckFirstLogin(pnl) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxUsers/CheckPNL', qs.stringify({
                pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.on_job = false
                // window.location.href = '/homeIndex'
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 0) {
                this.on_job = !res.data.ResponseData[0].DDATE ? true : false
            }
        },
        PageClose() {
            wx.closeWindow()
        },
    },
    mounted() {
        this.CheckFirstLogin(this.pnl)
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            // this.pnl = '66389' // dev
        }
    }
}
</script>

<style scoped>
    a:link { 
        text-decoration: none;
        color: #606266;
    }
    a:visited {
        text-decoration: none;
        color: #606266;
    }
    a:active { 
        text-decoration: blink;
        color: #606266;
    }
    .app-icon {
        width: 40px;
        height: 40px;
        margin-bottom: 4px;
        margin-top: 20px;
    }
    .app-span {
        font-size: 12px; 
        /* font-weight: 550; */
    }
    .box-card {
        padding: 0px 0px 15px 0px;
        margin-bottom: 5px;
        border-radius: 8px;
        /* margin: 15px -10px 5px -10px; */
    }
    .box-card h4 {
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 0px 10px 20px;
        margin: -20px -20px 10px -20px;
        background-color: rgb(217, 236, 255);
    }
</style>