<template>
    <div>
        <el-table :data="repairNoData" size="small" :show-header="false">
            <el-table-column prop="label" width="90px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column>
            <el-table-column align="right" width="90px">
                <el-button 
                    size="small" 
                    type="primary" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI">扫码</el-button>
            </el-table-column>
        </el-table>
        <el-table v-if="!isShow" :data="repairData" size="small" :show-header="false">
            <el-table-column prop="label" width="90px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-form 
            :model="repairForm" 
            :rules="rules" 
            ref="repairForm" 
            size="small" 
            v-if="isShow" 
            style="margin-top: 20px;font-weight: bold" 
            label-width="90px">
            <el-form-item label="开始日期" prop="START_TIME">
                <el-date-picker
                    disabled
                    v-model="repairForm.START_TIME"
                    align="center"
                    type="date"
                    style="width: 100%"
                    format="yyyy-MM-dd"
                    placeholder="请选择开始日期">
                </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="结束日期" prop="END_TIME">
                <el-date-picker
                    v-model="repairForm.END_TIME"
                    align="center"
                    type="date"
                    style="width: 100%"
                    format="yyyy-MM-dd"
                    placeholder="请选择结束日期">
                </el-date-picker>
            </el-form-item> -->
        </el-form>
        <el-row v-if="repairForm.DEVICE_ID" style="margin-top: 10px">
            <el-col :span="24" style="margin-top: 10px;margin-bottom: 10px">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    :loading="isLoading"
                    @click="SaveRepair">提交</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import wx from 'weixin-js-sdk'
import moment from 'moment'
export default {
    data() {
        return {
            repairNoData: [{
                label: '设备编码',
                value: ''
            }],
            repairData: [],
            repairForm: {
               DEVICE_ID: '',
               USERID: '',
               START_TIME: moment().format('YYYY-MM-DD')
            },
            isLoading: false,
            isShow: false,
            rules: {
                START_TIME: [
                    {required: true, message: '请选择开始日期', trigger: 'change'}
                ],
                // END_TIME: [
                //     {required: true, message: '请选择结束日期', trigger: 'change'}
                // ],
            }
        }
    },
    methods: {
        GetJSAPI() {
            // 测试
            // this.repairForm.DEVICE_ID = '00150115NA' // dev
            // this.repairForm.DEVICE_ID = '00150161NA666' // dev
            // this.GetHeavystaskInfo(this.repairForm.DEVICE_ID) // dev
            
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    this.repairForm.DEVICE_ID = result
                    this.GetHeavystaskInfo(this.repairForm.DEVICE_ID)
                }
            })
        },
        async GetHeavystaskInfo() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/DevHeavyrepairs/GetHeavystaskInfo', qs.stringify({
                deviceId: this.repairForm.DEVICE_ID
            }))
            console.log(res)
            if(res.data.ResponseData === null) {
                this.isShow = true
                this.repairNoData[0].value = this.repairForm.DEVICE_ID
            }else if(res.data.ResponseData !== null && res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.repairData = []
                const data = res.data.ResponseData  
                this.repairNoData[0].value = data.DEVICE_ID
                this.repairData.push({
                    label: '开始日期',
                    value: data.START_TIME
                })
                this.repairData.push({
                    label: '结束日期',
                    value: data.END_TIME
                })
                this.repairData.push({
                    label: '设备状态',
                    value: data.REPAIRSTATE === 2 ? '维修完成' : '维修中'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async SaveRepair() {
            this.isLoading = true
            const dtos = {
                DEVICE_ID: this.repairForm.DEVICE_ID,
                USERID: this.repairForm.USERID, 
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/DevHeavyrepairs/DeviceRepair', qs.stringify({
                dtos
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功') {
                this.$message({
                    message: '提交' + res.data.Message,
                    duration: 2000,
                    center: true,
                    type: 'success'
                })

                this.isShow = false,
                this.repairForm.DEVICE_ID = ''
                this.repairNoData[0].value = ''
                this.repairData = []
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                this.$message({
                    message: '提交' + res.data.Message,
                    duration: 2000,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        this.GetSignature()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.repairForm.USERID = pnl
        }
    }
}
</script>