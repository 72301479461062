<template>
    <div>
        <el-table :data="sceneryNoData" size="small" :show-header="false">
            <el-table-column prop="label" width="90px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column>
            <el-table-column align="right" width="90px">
                <el-button 
                    size="small" 
                    type="primary" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI">扫码</el-button>
            </el-table-column>
        </el-table>
        <el-table :data="sceneryData" size="small" :show-header="false">
            <el-table-column prop="label" width="110px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-form v-if="isAdmin && sceneryNoData[0].value" 
            :model="sceneryForm" 
            :rules="rules" 
            ref="sceneryForm" 
            style="margin-top: 20px" 
            label-width="80px" 
            size="small">
            <el-form-item label="维护日期" style="font-weight: bold" prop="MTAINDATE">
                {{sceneryForm.MTAINDATE}}
            </el-form-item>
            <el-form-item label="维护事项" style="font-weight: bold" prop="MTAINMATTER">
                <el-input
                    placeholder="请输入维护事项"
                    v-model="sceneryForm.MTAINMATTER">
                </el-input>
            </el-form-item>
            <el-form-item label="维护结论" style="font-weight: bold" prop="MATTERSATE">
                <el-select 
                    v-model="sceneryForm.MATTERSATE" 
                    placeholder="请选择维护结论" 
                    @change="selectChange"
                    style="width: 100%">
                    <el-option
                        v-for="item in MATTERSATE_OPTIONS"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="sceneryForm.MATTERSATE === '0'" style="font-weight: bold" label="异常描述">
                <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入异常描述"
                    v-model="sceneryForm.MATTERDESCRIBE">
                </el-input>
            </el-form-item>
            <el-row>
                <el-col :span="24" style="margin-top: 10px;margin-bottom: 10px">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="isLoading"
                        @click="submitForm('sceneryForm')">提交景观保养信息</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import wx from 'weixin-js-sdk'
import moment from 'moment'
export default {
    data() {
        return {
            sceneryNoData: [{
                label: '植物编码',
                value: ''
            }],
            sceneryData: [],
            isAdmin: false,
            isLoading: false,
            sceneryForm: {
                MATTERSATE: '',
                MATTERSATENAME: '',
                MTAINMATTER: '',
                MTAINDATE: moment().format('YYYY-MM-DD'),
                MATTERDESCRIBE: '',
                MATERIALID: '',
                BOTANYCODE: ''
            },
            MATTERSATE_OPTIONS: [{
                value: '0',
                label: '异常'
            },{
                value: '1',
                label: '正常'
            },{
                value: '2',
                label: '消亡'
            }],
            rules: {
                MTAINMATTER: [
                    {required: true, message: '请输入维护事项', trigger: 'blur'}
                ],
                MATTERSATE:  [
                    {required: true, message: '请选择维护结论', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        selectChange(id) {
            this.MATTERSATE_OPTIONS.forEach(item => {
                if(id === item.value) {
                    this.sceneryForm.MATTERSATENAME = item.label
                }
            })
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.sceneryForm)
                    this.SaveSecnery()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveSecnery() {
            this.isLoading = true
            const dtos = {
                MATTERSATE: this.sceneryForm.MATTERSATE,
                MATTERSATENAME: this.sceneryForm.MATTERSATENAME, 
                MTAINMATTER: this.sceneryForm.MTAINMATTER,
                MTAINDATE: this.sceneryForm.MTAINDATE,
                MATTERDESCRIBE: this.sceneryForm.MATTERDESCRIBE,
                DB_CREATE_USERNO: this.pnl,
                MATERIALID: this.sceneryForm.MATERIALID,
                BOTANYCODE: this.sceneryForm.BOTANYCODE,
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SceMaterial/SceMaterialMtainInserts', qs.stringify({
                dtos
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功') {
                this.$message({
                    message: '提交' + res.data.Message,
                    duration: 2000,
                    center: true,
                    type: 'success'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                this.$message({
                    message: '提交' + res.data.Message,
                    duration: 2000,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        GetJSAPI() {
            // 测试
            // this.sceneryForm.MATERIALID = 'f37934c57a414bffba7db6917f955011' // dev
            // this.GetSceneryInfo(this.sceneryForm.MATERIALID) // dev
            
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    this.sceneryForm.MATERIALID = result
                    this.GetSceneryInfo(this.sceneryForm.MATERIALID)
                }
            })
        },
        async GetSceneryInfo(code) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SceMaterial/GetSceMaterialMtain', qs.stringify({
                DB_ID: code
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.sceneryData = []
                const data = res.data.ResponseData 
                this.sceneryNoData[0].value = data.BOTANYCODE
                this.sceneryData.push({
                    label: '植物名称',
                    value: data.BOTANYDESCRIBE
                })
                this.sceneryData.push({
                    label: '种植/购买日期',
                    value: data.BOTANYYEARS
                })
                this.sceneryData.push({
                    label: '习性',
                    value: data.BOTANYHABIT
                })
                this.sceneryData.push({
                    label: '养护注意事项',
                    value: data.MATTERBECAREFUL
                })
                this.sceneryData.push({
                    label: '存放区域编码',
                    value: data.REGIONCODE
                })
                this.sceneryData.push({
                    label: '存放区域名称',
                    value: data.REGIONNAME
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetSceneryRight() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SceMaterial/GetSceMaterialSet', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.isAdmin = res.data.ResponseData === '0' ? true : false
                // this.isAdmin = true // dev
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        this.GetSignature()
        this.GetSceneryRight()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
        }
    }
}
</script>