<template>
    <div>
        <FeedBackHeader />
        <el-form :model="feedForm" :rules="rules" ref="feedForm" label-width="80px" size="medium">
            <el-form-item label="异常类别" prop="ftypes" style="text-align: left;">
                <el-checkbox-group 
                    v-model="feedForm.ftypes"
                    :max="1">
                    <el-checkbox v-for="anomal in anomals" :label="anomal" :key="anomal">{{anomal}}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="异常说明" prop="fremark">
                <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请详细描述你的异常说明"
                    v-model="feedForm.fremark">
                </el-input>
            </el-form-item>
            <el-form-item label="异常图片" prop="image1">
                <el-upload 
                    action='' 
                    ref="upload"
                    :on-change="getFile" 
                    :limit="3" 
                    list-type="picture"
                    :auto-upload="false" 
                    :on-exceed="handleExceed"
                    style="width: 100%;" >
                    <el-button size="medium" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item>
            <el-row style="margin-top: 20px;">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="feedForm.isLoading"
                        @click="submitForm('feedForm')">提交</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf.js'
import { compressImage } from '../../../plugin/compress.js'
import FeedBackHeader from '../../../components/FeedBackHeader.vue'
export default {
    components: {
        FeedBackHeader
    },
    data() {
        return {
            feedForm: {
                pnl: '',
                isLoading: false,
                fileList: [],
                imageList: [],
                ftype: '',
                fremark: '',
                ftypes: [],
                pname: '',
                sec_nm: ''
            },
            anomals: ['用品','模具','胶药水','其他'],
            rules: {
                ftypes: [
                    {required: true, message: '请选择异常类别', trigger: 'change'}
                ],
                fremark: [
                    {required: true, message: '请输入异常说明', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.feedForm)
                    this.SaveFeedBack()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveFeedBack() {
            this.feedForm.isLoading = true
            console.log()
            const dto = {
                FTYPE: this.feedForm.ftypes[0],
                FREMARK: this.feedForm.fremark,
                PNL: this.feedForm.pnl,
                PNAME: this.feedForm.pname,
                SEC_NM: this.feedForm.sec_nm
            }
            console.log('dto', dto)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxFeedbacks/SaveFeedBackModel', qs.stringify({
                dto,
                IMAGE1: encodeURIComponent(this.feedForm.imageList[0]),
                IMAGE2: encodeURIComponent(this.feedForm.imageList[1]),
                IMAGE3: encodeURIComponent(this.feedForm.imageList[2]),
                FILENAME1: this.feedForm.fileList[0],
                FILENAME2: this.feedForm.fileList[1],
                FILENAME3: this.feedForm.fileList[2]
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功') {
                this.$message({
                    message: '异常反馈提交成功',
                    center: true,
                    type: 'success'
                })
                setTimeout(() => {
                    window.location.href = '/feedBackList'
                },2000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.feedForm.isLoading = false
        },
        getFile(file, fileList) {
            console.log('file', file)
            console.log('fileList', fileList)
            this.feedForm.fileList = []
            this.feedForm.imageList = []
            fileList.forEach(item => {
                this.feedForm.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.feedForm.imageList.push(result)
                })
            })
        },
        handleRemove(file, fileList) {
            console.log('file', file)
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.feedForm.fileList = []
            this.feedForm.imageList = []
            fileList.forEach(item => {
                this.feedForm.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.feedForm.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        },
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.feedForm.pnl = pnl
        }
        const pname = localStorage.getItem('PNAME')
        if(pname){
            this.feedForm.pname = pname
        }
        const sec_nm = localStorage.getItem('SEC_NM')
        if(sec_nm) {
            this.feedForm.sec_nm = sec_nm
        }
    }
}
</script>

