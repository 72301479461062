<template>
    <div>
        <div v-if="!isSuccess">
            <el-alert v-if="isShow" type="error" show-icon>
                <template slot='title'>
                    <div class="iconSize" style="text-align: left;">该问卷没有发布，请联系系统管理员</div>
                </template>
            </el-alert>
            <div class="title">{{title}}</div>
            <!-- <div class="explain"><{{explain}}</div> -->
            <div class="explain" v-html="explain"></div>
            <el-form :model="queForm" ref="queForm" size="medium">
                <div v-for="(subject, index) in subject_list" :key="index" class="type">
                    <div v-if="subject.is_show==='Y'" class="subject-title">
                        {{GetNumberTo(subject.type_no)}}、{{subject.type_name}}
                    </div>
                    <div class="subject">{{index + 1}}. {{subject.title_nm}}</div>
                    <div v-if="subject.class_no === '1'" class="radio-content">
                        <div v-for="(option, optionindex) in subject.options" :key="optionindex">
                            <el-radio 
                                class="radio-content-item"
                                :label="option.content_no" 
                                v-model="subject.singleAnswer"  
                                @change="handleCurrentSelection(option.content_no, option.title_no)">
                                {{option.content_no}}&nbsp;&nbsp;&nbsp;{{option.content_nm}}
                            </el-radio>
                            <br>
                        </div>
                    </div>

                    <!-- <el-radio-group v-model="subject.checked" @change="handleSingleSelectionChange" >
                        <el-radio 
                        v-for="(option, optionindex) in subject.options" 
                        :label="option.content_no"
                        :key="optionindex" >{{option.content_nm}}</el-radio>
                    </el-radio-group> -->

                    <el-table 
                        :ref="'multipleTable' + subject.title_no"
                        v-if="subject.class_no === '2'"
                        :data="subject.options" 
                        size="medium" 
                        class="content"
                        :show-header="false"
                        @selection-change="handleSelectionChange">
                        <el-table-column align="left" type="selection" width="42">
                        </el-table-column>
                        <el-table-column align="left"  prop="content_no" width="35">
                        </el-table-column>
                        <el-table-column align="left"  prop="content_nm">
                        </el-table-column>
                    </el-table>
                    <el-input
                        v-if="subject.class_no === '3'"
                        type="textarea"
                        :rows="2"
                        v-model="subject.txtAnswer"
                        @blur="handleInputChange(subject.txtAnswer, subject.title_no)"
                        @input="forceUpdate"
                        placeholder="请输入">
                    </el-input>
                    <div v-if="subject.class_no === '4'" class="radio-content">
                        <div v-for="(option, optionindex) in subject.options" :key="optionindex">
                            <el-radio 
                                class="radio-content-item"
                                :label="option.content_no" 
                                v-model="subject.singleAnswer"  
                                @change="handleCurrentSelection2(option.content_no, option.title_no)">
                                {{option.content_no}}&nbsp;&nbsp;&nbsp;{{option.content_nm}}
                            </el-radio>
                            <br>
                            <el-input
                                v-if="subject.class_no === '4' 
                                && option.title_no === subject.title_no 
                                && option.is_answer === 'Y' 
                                && option.content_no === subject.singleAnswer"
                                type="textarea"
                                :rows="1"
                                v-model="subject.txtAnswer"
                                @blur="handleInputChange(subject.txtAnswer, subject.title_no)"
                                @input="forceUpdate"
                                placeholder="请输入">
                            </el-input>
                        </div>
                    </div>
                    <el-checkbox-group 
                        class="radio-content"
                        v-model="subject.multipleAnswer" 
                        @change="handleCheckedChange"
                        v-if="subject.class_no === '5'">
                        <el-checkbox 
                            class="radio-content-item"
                            v-for="(option, optionindex) in subject.options" 
                            :label="option"
                            @change="forceUpdate"
                            :key="optionindex" >{{option.content_no}}&nbsp;&nbsp;&nbsp;{{ option.content_nm}}
                            <el-input
                                style="float:left;margin-top: 10px;"
                                v-if="subject.class_no === '5' 
                                && option.title_no === subject.title_no 
                                && option.is_answer === 'Y' 
                                && subject.multipleAnswer.includes(option)"
                                type="textarea"
                                :rows="1"
                                v-model="option.answer_content"
                                @blur="handleInputChange(subject.txtAnswer, subject.title_no)"
                                @input="forceUpdate"
                                placeholder="请输入">
                            </el-input>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <br>
                <el-row style="margin-bottom: 60px">
                    <el-col :span="24">
                        <el-button 
                            v-if="!isShow"
                            style="width: 100%;"
                            type="primary" 
                            :loading="queForm.isLoading"
                            @click="submitForm('queForm')">提交问卷</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div v-if="isSuccess">
            <el-card class="card">
                <el-image style="width: 30px;margin-right: 10px;" lazy class="image" fit="cover" :src="require('@/assets/success.png')" alt=""></el-image>
                <span class="span">提交成功！</span>
            </el-card>
        </div>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf, { api_url } from '../../../conf'
import numberTo from '../../../plugin/numberTo'
import moment from 'moment'
export default {
    data() {
        return {
            pnl: '',
            isShow: false,
            isSuccess: false,
            pub_no: '',
            title: '',
            explain: '',
            queForm: {
                isLoading: false,
                index1: 0
            },
            subject_list: [],
            content_list: [],
            post_data: []
        }
    },
    methods: {
        handleCheckedChange(value) {
            console.log('value', value)
        },
        TextAreaTo(str) {
            var reg = new RegExp("\n", "g")
            var regSpace = new RegExp(" ", "g")
            str = str.replace(reg, "<br>")
            str = str.replace(regSpace, "&nbsp;")
            return str 
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.customValid(this.subject_list)){
                    console.log('this.subject_list', this.subject_list)
                    this.SaveQuestionnaire()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        customValid(list) {
            let isValid = true
            list.forEach(item => {
                if(isValid) {
                    if(item.class_no === '1') {
                        if(!item.singleAnswer) {
                            this.$message({
                                message: item.title_nm + ' 必须填写！',
                                center: true,
                                type: 'error'
                            })
                            isValid = false
                        }
                    // }else if(item.class_no === '2') {
                    //     item.multipleAnswer.forEach(el => {
                    //         if(el.length === 0) {
                    //             // isValid = false
                    //             return false
                    //         }
                    //     })
                    }else if(item.class_no === '3') {
                        if(!item.txtAnswer) {
                            this.$message({
                                message: item.title_nm + ' 必须填写！',
                                center: true,
                                type: 'error'
                            })
                            isValid = false
                        }
                    }
                }
            })

            return isValid
        },
        async SaveQuestionnaire() {
            this.subject_list.forEach(item => {
                console.log('item', item)
                if(item.class_no === '1') {
                    this.post_data.push({
                        pub_no: this.pub_no,
                        use_time: 0,
                        source: '',
                        ip: '',
                        fdate: moment().format('YYYY-MM-DD'),
                        pnl: this.pnl,
                        title_no: item.title_no,
                        content_no: item.singleAnswer,
                        content_nm: ''
                    })
                }else if(item.class_no === '2') {
                    item.multipleAnswer.forEach(el => {
                        this.post_data.push({
                            pub_no: this.pub_no,
                            use_time: 0,
                            source: '',
                            ip: '',
                            fdate: moment().format('YYYY-MM-DD'),
                            pnl: this.pnl,
                            title_no: item.title_no,
                            content_no: el,
                            content_nm: ''
                        })
                    })
                }else if(item.class_no === '3') {
                    this.post_data.push({
                        pub_no: this.pub_no,
                        use_time: 0,
                        source: '',
                        ip: '',
                        fdate: moment().format('YYYY-MM-DD'),
                        pnl: this.pnl,
                        title_no: item.title_no,
                        content_no: '',
                        content_nm: item.txtAnswer
                    })
                } else if(item.class_no === '4') {
                    this.post_data.push({
                        pub_no: this.pub_no,
                        use_time: 0,
                        source: '',
                        ip: '',
                        fdate: moment().format('YYYY-MM-DD'),
                        pnl: this.pnl,
                        title_no: item.title_no,
                        content_no: item.singleAnswer,
                        content_nm: item.txtAnswer
                        // content_nm: this.getIsAnswer(item.singleAnswer, item.options) ? item.txtAnswer : ''
                    })
                } else if(item.class_no === '5') {
                    item.multipleAnswer.forEach(el => {
                        this.post_data.push({
                            pub_no: this.pub_no,
                            use_time: 0,
                            source: '',
                            ip: '',
                            fdate: moment().format('YYYY-MM-DD'),
                            pnl: this.pnl,
                            title_no: item.title_no,
                            content_no: el.content_no,
                            content_nm: el.answer_content
                        })
                    })
                }
            })
            console.log('this.post_data', this.post_data)
            const res = await axios.post(api_url + '/gateway/weixinapi/WxHrQuestionnaireAnswers/SaveQuestionnaire', qs.stringify({
                dtos: this.post_data
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.isSuccess = true
            }
        },
        // getIsAnswer(singleAnswer, options){
        //     options.forEach(option => {
        //         if(option.is_answer === 'Y' && option.content_no === singleAnswer) {
        //             console.log('true', true)
        //             return true
        //         }
        //     })
        //     // console.log('false', false)
        //     // return false
        // },
        forceUpdate() {
            this.$forceUpdate()
        },
        handleInputChange(val, title_no) {
            console.log('val', val)
            console.log('title_no', title_no)
            this.subject_list.forEach(item => {
                if(item.title_no === title_no) {
                    item.txtAnswer = val
                }
            })
            console.log('this.subject_list', this.subject_list)
        },
        handleSelectionChange(val) {
            console.log('val', val)
            this.subject_list.forEach(el => {
                val.forEach(item => {
                    if(item.title_no === el.title_no) {
                        if(el.multipleAnswer.indexOf(item.content_no) < 0) {
                            el.multipleAnswer.push(item.content_no)
                        }
                    }
                })
            })
        },
        handleCurrentSelection(val, title_no) {
            // console.log('handleCurrentSelection')
            // console.log('val', val)
            // console.log('title_no', title_no)
            // console.log('subject', subject)
            // console.log('option', option)
            this.subject_list.forEach(item => {
                if(item.title_no === title_no) {
                    item.singleAnswer = val
                    this.$forceUpdate()
                }
            })
            // console.log('this.subject_list', this.subject_list)
        },
        handleCurrentSelection2(val, title_no) {
            // console.log('handleCurrentSelection2')
            // console.log('val', val)
            // console.log('title_no', title_no)
            // console.log('subject', subject)
            // console.log('option', option)
            this.subject_list.forEach(item => {
                // item.txtAnswer = ''
                if(item.title_no === title_no) {
                    item.txtAnswer = ''
                    item.singleAnswer = val
                    this.$forceUpdate()
                }
            })
            // console.log('this.subject_list', this.subject_list)
        },
        async GetQuestionnaire() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrQuestionnaireAnswers/Questionnaire', qs.stringify({
                PUB_NO: this.pub_no
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.title = res.data.ResponseData.Table1[0].pub_title
                // this.explain = res.data.ResponseData.Table1[0].explain
                this.explain = this.TextAreaTo(res.data.ResponseData.Table1[0].explain)
                // console.log(res.data.ResponseData.Table3)
                this.subject_list = res.data.ResponseData.Table2
                this.content_list = res.data.ResponseData.Table3
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.isShow = true
            }

            this.GetData()
        },
        GetData() {
            this.subject_list.forEach(item => {
                item.txtAnswer = ''
                item.singleAnswer = ''
                item.multipleAnswer = []
                item.options = []
                this.content_list.forEach(option => {
                    if(item.title_no === option.title_no) {
                        option.answer_content = ''
                        item.options.push(
                            option
                        )
                    }
                })
                this.$forceUpdate()
            })   
            console.log('this.subject_list', this.subject_list)
        },
        GetNumberTo(index) {
            return numberTo(index)
        },
    },
    mounted() {
        const id = this.$route.params.id
        if(id) {
            this.pub_no = id
            this.GetQuestionnaire()
        }
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
        }
    }
}
</script>

<style scoped>
    .title{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 10px;
    }
    .explain{
        font-size: 14px;
        text-align: left;
        margin-bottom: 30px;
    }
    .type{
        font-size: 15px;
        font-weight: bold;
        text-align: left;
        margin-bottom: 10px;
        margin-top: 20px;
    }
    .subject-title{
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .subject{
        margin-bottom: 10px;
    }
    .content{
        font-size: 14px;
        font-weight: normal;
    }
    .radio-content{
        margin-top: 10px;
    }
    .radio-content-item{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .span{
        font-size: 20px;
        font-weight: bold;
        margin: 0px;
        padding: 0px;
    }
</style>
