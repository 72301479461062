<template>
    <div>
        <el-table 
            :data="tableData" 
            :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
            size="mini" 
            lazy
            v-loading="table_loading" 
            style="font-size: 11px;"
            border>
            <el-table-column prop="fname" label="项目" min-width="38px" align="center">
            </el-table-column>
            <el-table-column label="标准温度时间" min-width="88px" align="center" v-if="ftype == 2">
                <template slot-scope="scope">
                    <el-input size="mini" v-model="scope.row.fname" disabled></el-input>
                </template>
            </el-table-column>
            <el-table-column label="实测记录" min-width="98px" align="center">
                <el-table-column label="7:30-8:30" min-width="46px" align="center">
                    <template slot-scope="scope">
                        <el-input size="mini" v-model="scope.row.ffwd1" disabled></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="13:30-14:30" min-width="54px" align="center">
                    <template slot-scope="scope">
                        <el-input size="mini" v-model="scope.row.ffwd2" disabled></el-input>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
        <el-row style="margin-top: 20px;margin-bottom: 60px" :gutter="20">
            <!-- <el-col :span="12">
                <el-button 
                    style="width: 100%;"
                    type="success" 
                    :loading="isLoading"
                    @click="SubmitFormList('checkDetailForm')">更新</el-button>
            </el-col> -->
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="danger" 
                    :loading="isLoading"
                    @click="delDialogVisible = true">删除</el-button>
            </el-col>
        </el-row>
        <el-dialog title="删除确认？" :visible.sync="delDialogVisible" size="mini" width="90%">
            <span class="dialog-footer">
                删除单号[ {{this.checkno}} ], 是否继续?
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="delDialogVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="DelCheckDetail">确 定</el-button>
            </span>
        </el-dialog>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
export default {
    data() {
        return {
            checkno: '',
            tableData: [],
            table_loading: false,
            isLoading: false,
            delDialogVisible: false,
            ftype: 2
        }
    },
    methods: {
        async GetCheckDetailData2() {
            this.table_loading = true
            this.tableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/CheckDetailData2', qs.stringify({
                checkno: this.checkno
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.Table2.length > 0) {
                    this.tableData = res.data.ResponseData.Table2
                }

                let table1 = res.data.ResponseData.Table1
                this.tableData.forEach(item => {
                    table1.map(el => {
                        if(item.fid1 === el.checkflowssid) {
                            item.ffwd1 = el.checkvalue
                        }
                        if(item.fid2 === el.checkflowssid) {
                            item.ffwd2 = el.checkvalue
                        }
                    })
                })      

                this.ftype = res.data.ResponseData.Table3[0].ftype
                console.log('this.tableData', this.tableData)
            }
            this.table_loading = false
        },
        async DelCheckDetail() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/DelCheckDetail', qs.stringify({
                checkno: this.checkno
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: '删除成功！',
                    center: true,
                    type: 'success',
                    duration: 2000
                })
                this.delDialogVisible = false
                setTimeout(function(){
                    window.location.href = '/checkList'
                },2000)
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        }
    },
    mounted() {
        const checkno = this.$route.params.no
        if(checkno) {
            this.checkno = checkno
            this.GetCheckDetailData2()
        }
    },
}
</script>