<template>
    <div>
         <el-form :model="checkDetailForm" ref="checkDetailForm" size="mini" label-position="left">
            <div v-for="(subject, index) in checkDetailForm.subject_list" :key="index" >
                <div class="subject">{{subject.fsortno}}、{{subject.fname}}</div>
                <div v-for="(content, index) in checkDetailForm.content_list" :key="index" class="content">
                    <el-form-item 
                        :label="content.title" 
                        v-if="subject.fid === content.fpid" 
                        style="display: inline-block;font-weight: bold;">
                        <br>
                        <!-- <el-radio v-model="content.answer" label="1" style="margin-left: 20px">是</el-radio>
                        <el-radio v-model="content.answer" label="0">否</el-radio> -->
                        <el-radio v-model="content.answer" label="1">正常</el-radio>
                        <el-radio v-model="content.answer" label="2">异常</el-radio>
                        <el-radio v-model="content.answer" label="3">无此项</el-radio>
                        <el-radio v-model="content.answer" label="4">复查OK</el-radio>
                    </el-form-item>
                    <el-form-item v-if="subject.fid === content.fpid">
                        <el-input v-model="content.remark" type="textarea" autosize></el-input>
                    </el-form-item>
                    <el-form-item v-if="subject.fid === content.fpid && content.have_image === 1">
                        <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(content.image_id)">
                            <i class="el-icon-picture-outline"></i> 点击查看
                        </el-link>
                    </el-form-item>
                </div>
            </div>
            <el-row style="margin-top: 10px;margin-bottom: 60px" :gutter="20">
                <el-col :span="12">
                    <el-button 
                        style="width: 100%;"
                        type="success" 
                        :loading="checkDetailForm.isLoading"
                        @click="SubmitFormList('checkDetailForm')">更新</el-button>
                </el-col>
                <el-col :span="12">
                    <el-button 
                        style="width: 100%;"
                        type="danger" 
                        :loading="checkDetailForm.isLoading"
                        @click="delDialogVisible = true">删除</el-button>
                </el-col>
            </el-row>
        </el-form>
        <!-- <el-dialog :visible.sync="imageDialogTableVisible" size="mini" width="90%">
            <span class="dialog-footer">
                <el-image
                    :key="item.DB_ID"
                    v-for="item in imageList"
                    lazy
                    style="width: 100%;"
                    :src="item.path"
                    fit="fill">
                    <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                    </div>
                    <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="mini" @click="imageDialogTableVisible = false">关闭</el-button>
            </span>
        </el-dialog> -->
        <el-dialog title="删除确认？" :visible.sync="delDialogVisible" size="mini" width="90%">
            <span class="dialog-footer">
                删除单号[ {{this.checkno}} ], 是否继续?
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="delDialogVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="DelCheckDetail">确 定</el-button>
            </span>
        </el-dialog>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            checkDetailForm: {
                subject_list: [],
                content_list: [],
                isLoading: false,
                fid: '',
                sec_no: '',
                odr_no: '',
                pnl: '',
            },
            checkno: '',
            // imageDialogTableVisible: false,
            imageList: [],
            delDialogVisible: false
        }
    },
    methods: {
        ShowImages(id) {
            // console.log(id)
            // this.imageDialogTableVisible = true
            this.GetImagesByID(id)
        },
        async GetImagesByID(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        // this.imageList.push({
                        //     id: item.DB_ID,
                        //     path: conf.image_domain + item.FImgPath
                        // })
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                           path
                        )
                    })
                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
                // console.log('this.imageList', this.imageList)
            }
        },
        SubmitFormList(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CustomValid(this.checkDetailForm.content_list)){
                    console.log('this.checkDetailForm.content_list', this.checkDetailForm.content_list)
                    this.SaveCheckList()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        CustomValid(list) {
            let isValid = true
            list.forEach(item => {
                if(!item.answer && isValid) {
                    isValid = false
                    this.$message({
                        message: item.title + ' 必须填写！',
                        center: true,
                        type: 'error',
                        duration: 2000
                    })
                    return isValid
                }
            })    
            return isValid
        },
        async SaveCheckList() {
            this.checkDetailForm.isLoading = true
            let dtos = []
            this.checkDetailForm.content_list.map(item => {
                let AleCheckresultDTO = {
                    CHECKFLOWID: this.checkDetailForm.fid,
                    CHECKFLOWSID: item.fpid,
                    CHECKFLOWSSID: item.fid,
                    // CHECKVALUE: item.answer === '1' ? '无异常' : '异常',
                    CHECKVALUE: this.GetCheckValue(item.answer),
                    REMARKS: item.remark,
                    SEC_NO: this.checkDetailForm.sec_no,
                    ODR_NO: this.checkDetailForm.odr_no,
                    PNL: this.checkDetailForm.pnl,
                    CHECKNO: this.checkno
                }
                dtos.push(AleCheckresultDTO)
            })
            console.log('dtos', dtos)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/EditSaveCheckListData', qs.stringify({
                dtos
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: '保存成功！',
                    center: true,
                    type: 'success',
                    duration: 2000
                })
                setTimeout(function(){
                    window.location.href = '/checkList'
                },2000)
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.checkDetailForm.isLoading = false
        },
        async GetCheckDetailData() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/CheckDetailData', qs.stringify({
                checkno: this.checkno
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.Table1.length > 0) {
                    this.checkDetailForm.odr_no = res.data.ResponseData.Table1[0].odr_no
                    this.checkDetailForm.fid = res.data.ResponseData.Table1[0].checkflowid
                    this.checkDetailForm.sec_no = res.data.ResponseData.Table1[0].sec_no
                }
                if(res.data.ResponseData.Table2.length > 0) {
                    this.checkDetailForm.subject_list = res.data.ResponseData.Table2
                }
                if(res.data.ResponseData.Table3.length > 0) {
                    res.data.ResponseData.Table3.forEach(item => {
                        item.title = item.fsortno + '.' + item.fname
                        res.data.ResponseData.Table1.map(el => {
                            if(el.checkflowssid === item.fid) {
                                // item.answer = el.checkvalue === '异常' ? '0' : '1',
                                item.answer = this.SetCheckValue(el.checkvalue)
                                item.remark = el.remarks
                                item.image_id = el.DB_ID
                                item.have_image = el.have_image
                            }
                        })
                    })
                    this.checkDetailForm.content_list = res.data.ResponseData.Table3
                    console.log('this.checkDetailForm.content_list', this.checkDetailForm.content_list)
                }
            }
        },
        GetCheckValue(val) {
            let check_value = ''
            if(val === '1') {
                check_value = '正常'
            }else if(val === '2') {
                check_value = '异常'
            }else if(val === '3') {
                check_value = '无此项'
            }else if(val === '4') {
                check_value = '复查OK'
            }
            return check_value
        },
        SetCheckValue(val) {
            let check_value = ''
            if(val === '正常') {
                check_value = '1'
            }else if(val === '异常') {
                check_value = '2'
            }else if(val === '无此项') {
                check_value = '3'
            }else if(val === '复查OK') {
                check_value = '4'
            }
            return check_value
        },
        showDelCheckDetail() {
            this.delDialogVisible = true
        },
        async DelCheckDetail() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/DelCheckDetail', qs.stringify({
                checkno: this.checkno
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: '删除成功！',
                    center: true,
                    type: 'success',
                    duration: 2000
                })
                this.delDialogVisible = false
                setTimeout(function(){
                    window.location.href = '/checkList'
                },2000)
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
    },
    mounted() {
        const checkno = this.$route.params.no
        if(checkno) {
            this.checkno = checkno
            this.GetCheckDetailData()
        }
        this.GetSignature()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.checkDetailForm.pnl = pnl
        }
    }
}
</script>

<style scoped>
.subject{
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
    color: #409EFF;
}
.content{
    /* font-size: 14px; */
    text-align: left;
    word-wrap: break-word;
    /* margin-bottom: 10px; */
    /* margin-top: 10px; */
}
</style>