<template>
    <div>
        <el-table :data="tableAssNoData" size="small" :show-header="false">
            <el-table-column prop="label" label="设备详情" width="90px">
            </el-table-column>
            <el-table-column prop="value">
            </el-table-column>
            <el-table-column align="right" width="90px">
                <el-button 
                    size="small" 
                    type="primary" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI">扫码</el-button>
            </el-table-column>
        </el-table>
        <el-table :data="tableData" size="small" :show-header="false">
            <el-table-column prop="label" width="90px">
            </el-table-column>
            <el-table-column prop="value">
            </el-table-column>
        </el-table>
        <el-row :gutter="10" style="margin-top: 10px" v-if="this.tableAssNoData[0].value">
            <el-col :span="12">
                <!-- <el-popover
                    placement="top-end"
                    width="160"
                    style="text-align: center"
                    v-model="visible">
                    <p>确定要报修此设备?</p>
                    <div style="text-align: center; margin: 0">
                        <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                        <el-button type="primary" size="mini" @click="Report">确定</el-button>
                    </div>
                    <template slot='reference'>
                        <el-button size="small" style="width: 100%" type="danger">快速报修</el-button>
                    </template>
                </el-popover> -->
                <el-button size="small"  type="danger" style="width: 100%" @click="ToFix">快速报修</el-button>
            </el-col>
            <el-col :span="12">
                <el-dropdown :hide-on-click="false" trigger="click" style="width: 100%">
                    <el-button size="small"  type="primary" style="width: 100%">
                    保养<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                    <el-dropdown-menu slot="dropdown" >
                        <el-dropdown-item @click.native="ToMaintain(0)">一级保养</el-dropdown-item>
                        <el-dropdown-item @click.native="ToMaintain(1)" :disabled="!this.right">二级保养</el-dropdown-item>
                        <el-dropdown-item @click.native="ToMaintain(2)" :disabled="!this.right">三级保养</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-row>
        <el-collapse v-model="activeNames" style="margin-top: 25px">
            <el-collapse-item title="保养记录" name="1" >
                <el-table :data="tableCheckData" size="small" :show-header="false" label="">
                    <el-table-column prop="name">
                        <template slot-scope="scope">
                            <div>{{scope.row.ctime}}</div>
                            <div>{{scope.row.cwhy}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="txt" align="right">
                        <template slot-scope="scope">
                            <div>{{scope.row.cman}}</div>
                            <div>{{scope.row.chow}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-collapse-item>
        </el-collapse>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            tableAssNoData: [{
                label: '设备编号',
                value: ''
            }],
            tableData: [],
            tableCheckData: [],
            activeNames: ['0'],
            visible: false,
            right: false
        }
    },
    methods: {
        async ToFix() {
            if(this.tableAssNoData[0].value) {
                window.location.href = '/repairOrder/' + this.tableAssNoData[0].value
            }
        }, 
        async ToMaintain(n) {
            if(this.tableAssNoData[0].value) {
                window.location.href = '/firstMaintain/' + this.tableAssNoData[0].value + '/' + n
            }
        },
        // ShowCheckDetail(row) {
        //     window.location.href = '/maintainDetail/' + row.cno
        // },
        GetJSAPI() {
            // 测试
            // this.tableAssNoData[0].value = 'XM3033' // dev
            // this.tableAssNoData[0].value = 'ZM2032' // dev
            // this.tableAssNoData[0].value = '00010001NA'  // dev
            // this.tableAssNoData[0].value = '00150147NA'  // dev
            // this.GetAssetInfo(this.tableAssNoData[0].value) // dev
            // this.GetAsscheckList(this.tableAssNoData[0].value) // dev
            // 
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    this.tableAssNoData[0].value = result
                    this.GetAssetInfo(this.tableAssNoData[0].value)
                    this.GetAsscheckList(this.tableAssNoData[0].value)
                }
            })
        },
        async GetAssetInfo(ASS_NO) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsAssets/AssetByNo', qs.stringify({
                ASS_NO
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.tableData = []
                res.data.ResponseData.forEach(item => {
                    this.tableData.push({
                        label: '设备名称',
                        value: item.ASS_NM
                    })
                    this.tableData.push({
                        label: '所在部门',
                        value: item.SEC_NM
                    })
                    this.tableData.push({
                        label: '设备规格',
                        value: item.ASS_GG
                    })
                    this.tableData.push({
                        label: '存放地点',
                        value: item.ADDR
                    })
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetAsscheckList(ASS_NO) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsAsscheckinfos/AsscheckinfoList', qs.stringify({
                ASS_NO
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.tableCheckData = []
                res.data.ResponseData.forEach(item => {
                    this.tableCheckData.push({
                        ctime: item.C_TIME,
                        cman: item.C_MAN,
                        cwhy: item.C_WHY || '日常保养',
                        chow: item.C_HOW || '无异常',
                        cno: item.C_NO
                    })
                })    
                this.activeNames = ['1']
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1){
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetMaintainRight() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsAsscheckinfos/MaintainRight', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.right = res.data.ResponseData[0].Column1 === 1 ? true : false
            }
        },
    },
    mounted() {
        const id = this.$route.params.id
        if(id) {
            this.tableAssNoData[0].value = id
            this.GetAssetInfo(this.tableAssNoData[0].value)
            this.GetAsscheckList(this.tableAssNoData[0].value)
        }
        this.GetSignature()
        this.GetMaintainRight()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            // this.pnl = '19301' // dev
        }
    }
}
</script>