<template>
    <div>
        <el-form :model="checkListForm" ref="checkListForm" label-width="60px" size="small">
            <el-form-item label="表格" prop="fid" style="font-weight: bold">
                <el-row>
                    <el-col :span="18">
                        <el-select 
                            @change="checkChange"
                            v-model="checkListForm.fid" 
                            placeholder="请选择表格" 
                            style="width: 100%">
                            <el-option
                                v-for="item in checkListForm.fids"
                                :key="item.fid"
                                :label="item.fname"
                                :value="item.fid">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="5" style="margin-left: 10px">
                        <el-button style="width: 90%" type="primary" @click="ToProcessCheck">新增</el-button>
                    </el-col>
                </el-row>
            </el-form-item>
        </el-form>
        <el-table :data="checkTable"   
            :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
            size="mini" 
            @row-click="ShowDetail"
            v-loading="isLoading" 
            style="font-size: 11px;"
            border>
            <el-table-column prop="checkno" label="创建人 | 日期" min-width="100px">
                <template slot-scope="scope">
                    <div>{{scope.row.info}}</div>
                    <div>{{scope.row.idate}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="odr_no" label="指令号 | 货号" min-width="108px">
                <template slot-scope="scope">
                    <div>{{scope.row.odr_no}}</div>
                    <div>{{scope.row.artic_no}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="sec_nm" label="部门名称 | 表格" min-width="106px">
                <template slot-scope="scope">
                    <div>{{scope.row.sec_nm}}</div>
                    <div>{{scope.row.fname}}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
// import moment from 'moment'
export default {
    data() {
        return {
            checkListForm: {
                fids: [],
                fid: '',
                pnl: ''
            },
            isLoading: false,
            checkTable: [],
            isAdmin: false
        }
    },
    methods: {
        ShowDetail(row) {
            console.log(row)
            if(row.ftype == 1) {
                window.location.href = '/checkDetail/' + row.checkno
            }else{
                window.location.href = '/checkDetail2/' + row.checkno
            }
        },
        async GetCheckFlowByMadeNo() {
            this.checkListForm.fids = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/CheckFlowByMadeNo', qs.stringify({
                pnl: this.checkListForm.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.checkListForm.fids = res.data.ResponseData
                }
            }
        },
        checkChange() {
            this.GetCheckListByID()
        },
        async GetCheckListByID() {
            this.checkTable = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/CheckListByID', qs.stringify({
                id: this.checkListForm.fid,
                pnl: this.checkListForm.pnl,
                isAdmin: this.isAdmin
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                res.data.ResponseData.forEach(item => {
                    item.info = item.pnl + ' - ' + item.pname
                    // item.idate = moment(item.idate).format('YYYY-MM-DD HH:mm')
                })
                this.checkTable = res.data.ResponseData
            }
        },
        ShowProcessCheck(checkno,ftype) {
            if(ftype == 1) {
                window.location.href = '/checkDetail/' + checkno
            }else{
                window.location.href = '/checkDetail2/' + checkno
            }
        },
        ToProcessCheck() {
            window.location.href = '/checkCreate'
        }
    },
    mounted() {
        this.GetCheckFlowByMadeNo()
        this.GetCheckListByID()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.checkListForm.pnl = pnl 
            // this.checkListForm.pnl = '43528' // dev
            // this.checkListForm.pnl = '25001' // dev
            // this.isAdmin = true
            if(pnl === '25001' || pnl === '7657' || pnl === '21025') {
                this.isAdmin = true
            }
        }
    },
}
</script>