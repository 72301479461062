<template>
    <div>
        <el-table :data="simpleNoData" size="small" :show-header="false">
            <el-table-column prop="label" label="设备详情" width="80px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column>
            <el-table-column align="right" width="90px">
                <el-button 
                    size="small" 
                    type="primary" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI">扫码</el-button>
            </el-table-column>
        </el-table>
        <el-table :data="simpleData" size="small" :show-header="false">
            <el-table-column prop="label" width="80px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-table style="margin-top: 20px" :data="jy_data" size="mini" border v-if="jy_data.length > 0">
            <el-table-column prop="index" label="序号">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.code}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="qtyl" label="左脚" >
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #67C23A">{{scope.row.qtyl}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="qtyr" label="右脚" >
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #409EFF">{{scope.row.qtyr}}</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" >
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="danger"
                        @click="handleDelete(scope.$index, scope.row)">删</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="dialogTableVisible" size="mini" width="90%">
            <span slot="title" class="dialog-footer">
                <div style="font-weight: bold;text-align: left;font-size: 16px;">样品单号不一致</div>
                <div style="text-align: left;font-size: 14px;margin-top: 20px;color: #F56C6C;font-weight: bold">是否先提交已交样样品?</div>
            </span>
            <el-table :data="jy_data">
                <el-table-column property="code" label="序号"></el-table-column>
                <el-table-column property="qtyl" label="左脚"></el-table-column>
                <el-table-column property="qtyr" label="右脚"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogTableVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="submitForm('SDForm')">确定</el-button>
            </span>
        </el-dialog>
        <el-form :model="SDForm" :rules="rules" ref="SDForm" label-width="100px" size="mini" style="margin-top: 20px;" v-if="this.simpleNoData[0].value">
            <el-form-item label="指定接收人" prop="use_no">
                <el-autocomplete
                    style="width: 100%"
                    size="small"
                    class="inline-input"
                    v-model="SDForm.use_name"
                    :fetch-suggestions="querySearch"
                    :placeholder="SDForm.use_name"
                    :trigger-on-focus="false"
                    @select="handleSelect"
                ></el-autocomplete>
            </el-form-item>
            <el-row style="margin-top: 20px;" v-if="this.simpleNoData[0].value">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        size="medium"
                        :loading="isLoading"
                        :disabled="jy_data.length <= 0"
                        @click="submitForm('SDForm')">提交交样</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-collapse v-model="activeNames" style="margin-top: 30px;">
            <el-collapse-item name="1">
                <template slot="title">
                    <div style="font-weight: bold">上传记录</div>
                </template>
                <el-table :data="simplesData" stripe size="small" style="width: 100%">
                    <el-table-column prop="iodate" label="完工时间" width="85px" align="left">
                        <template slot-scope="scope">
                            <div style="font-weight: bold;color: #909399">{{scope.row.iodate}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="typeName" label="类型" width="60px" align="left">
                    </el-table-column>
                    <el-table-column label="双数 / 左脚数 / 右脚数" align="right">
                        <template slot-scope="scope">
                            <div style="font-size: 13px;font-weight: bold;">
                                <span style="color: #E6A23C">{{scope.row.qty}}</span>
                                <span style="color: #409EFF">{{scope.row.qtyl}}</span>
                                <span style="color: #67C23A">{{scope.row.qtyr}}</span> 
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../../conf'
import qs from 'qs'
import wx from 'weixin-js-sdk'
import moment from 'moment'
export default {
    data() {
        return {
            pnl: '',
            user_data: [],
            simpleNoData: [{
                label: '样品单号',
                value: ''
            }],
            simpleData: [],
            simplesData:[{
                label: '完工类型',
                value: ''
            }],
            jy_data: [],
            SDForm: {
                iqty: 0,
                iqtyl: 0,
                iqtyr: 0,
                sqty: 0,
                qty: 0,
                qtyl: 0,
                qtyr: 0,
                use_no: '',
                use_name: '',
                kqty: 0,
                jqty: 0
            },
            rules: {
                use_name: [
                    {required: true, message: '请输入指定签收人', trigger: 'change'}
                ],
            },
            dialogTableVisible: false,
            isLoading: false,
            activeNames: ['0'],
            // index: 0 // dev
        }
    },
    methods: {
        GetJSAPI() {
            // 测试数据
            // 单号;序号;左;右
            // 'HDS202110000383-001;1;0'

            // if(this.index === 0 ) {
            //     let result = 'HDS202111000348-001;1;0' // dev
            //     this.GetCodeData(result) // dev
            //     this.index ++ // dev
            // }else if(this.index === 1){
            //     let result = 'HDS202111000367-001;0;1' // dev
            //     this.GetCodeData(result) // dev
            //     this.index ++ // dev
            // }else {
            //     let result = 'HDS202111000030-001;0;1' // dev
            //     this.GetCodeData(result) // dev
            // }

            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    this.simpleNoData[0].value = result
                    this.GetCodeData(result)
                }
            })
        },
        async GetCodeData(result) {
            let sample_no = result.split('-')[0]
            let qrcode_data = result.split('-')[1].split(';')
            // console.log('this.qrcode_data', qrcode_data)
            if(!this.simpleNoData[0].value) {
                this.simpleNoData[0].value = sample_no
                // console.log('this.simpleNoData[0].value',this.simpleNoData[0].value)
                await this.GetSampleData(this.simpleNoData[0].value)  
                this.GetSamplesData(this.simpleNoData[0].value) 
                this.GetAppSysUser(this.simpleNoData[0].value)
            } 
            // else if(this.simpleNoData[0].value !== sample_no){
            //     // 提示样品单号不同，是否先提交当前样品
            //     this.dialogTableVisible = true
            // }

            this.SetWGData(qrcode_data)
        },
        SetWGData(sample_no, qrcode_data) {
            // if(this.jy_data.findIndex((item, index) => {
            //     console.log('index', index)
            //     if(item.code === qrcode_data[0]) {
            //         this.$message({
            //             message: this.simpleNoData[0].value + '-' + item.code + ' 已扫描！',
            //             center: true,
            //             type: 'success'
            //         })
            //         return true
            //     }
            // })) {
            //     this.jy_data.push({
            //         code: qrcode_data[0],
            //         qtyl: qrcode_data[1],
            //         qtyr: qrcode_data[2],
            //         qty: (parseInt(qrcode_data[1]) + parseInt(qrcode_data[2])) / 2
            //     })
            // }

             if(this.jy_data.findIndex((item) => {
                return item.no !== sample_no
            }) > -1) {
                // 提示样品单号不同，是否先提交当前样品
                this.dialogTableVisible = true
                return
            }

            if(this.jy_data.findIndex((item) => {
                // console.log('item.code', item.code)
                // console.log('qrcode_data[0]', qrcode_data[0])
                return item.code == qrcode_data[0]
            }) > -1) {
                this.$message({
                    message: sample_no + '-' + qrcode_data[0] + ' 已扫描！',
                    center: true,
                    type: 'success',
                    duration: 800
                })
                // console.log('来了老弟')
            }else {
                this.jy_data.push({
                    no: sample_no,
                    code: qrcode_data[0],
                    qtyl: qrcode_data[1],
                    qtyr: qrcode_data[2],
                    qty: (parseInt(qrcode_data[1]) + parseInt(qrcode_data[2])) / 2
                })
            }

            this.WGQtyComputed()
        },
        WGQtyComputed() {
            let qtyl = 0
            let qtyr = 0
            let qty = 0
            this.jy_data.forEach(item => {
                qtyl += parseInt(item.qtyl)
                qtyr += parseInt(item.qtyr)
                qty += (parseInt(item.qtyl) + parseInt(item.qtyr)) / 2
            })

            this.SDForm.qtyl = qtyl
            this.SDForm.qtyr = qtyr
            this.SDForm.qty = qty
            this.qtylrComputed()
        },
        qtylrComputed() {
            this.SDForm.qty = (this.SDForm.qtyl + this.SDForm.qtyr) / 2
            this.ValidQty()
        },
        ValidQty() {
            console.log('this.simpleData', this.simpleData)
            let iqtyl = this.SDForm.iqtyl + this.SDForm.qtyl
            let iqtyr = this.SDForm.iqtyr + this.SDForm.qtyr
            this.simpleData[3].value = this.SDForm.iqty + this.SDForm.qty + '（左：' + iqtyl + '，右：' + iqtyr + '）'
        },
        handleDelete(index, row) {
            console.log('index', index)
            console.log('row', row)
            this.jy_data.splice(index, 1)
            this.WGQtyComputed()
        },
        async GetSampleData(yvou_no) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SampleCompletion/SampleCompletion', qs.stringify({
                yvou_no: yvou_no
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.simpleData = []
                res.data.ResponseData.map(item => {
                    this.simpleData.push({
                        label: '样品款式',
                        value: item.sstyle
                    })
                    this.simpleData.push({
                        label: '样品类型',
                        value: item.stype
                    })
                    this.simpleData.push({
                        label: '样品品牌',
                        value: item.scus_nm
                    })
                    this.simpleData.push({
                        label: '完工双数',
                        value: item.iqty + '（左：' + item.iqtyl + '，右：' + item.iqtyr + '）'
                    })

                    this.SDForm.iqty = item.iqty // 完工双数
                    this.SDForm.iqtyl = item.iqtyl // 完工左
                    this.SDForm.iqtyr = item.iqtyr // 完工右
                    this.SDForm.sqty = item.sqty // 下单双数
                    this.SDForm.kqty = item.kqty // 库存数
                    this.SDForm.jqty = item.jqty // 已交样数
                })
            }
        },
        async GetSamplesData(yvou_no) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SampleDelivery/SampleYJRecord', qs.stringify({
                yvou_no
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.simplesData = []
                res.data.ResponseData.map(item => {
                    this.simplesData.push({
                        iodate: moment(item.iodate).format('YYYY-MM-DD'),
                        typeName: item.typeName,
                        qty: item.qty + ' (双)，',
                        qtyl: '左：' + (!item.qtyl ? 0 : item.qtyl) + '，',
                        qtyr: '右：' + (!item.qtyr ? 0 : item.qtyr) 
                    })
                })
            }
        },
        querySearch(queryString, cb) {
            var restaurants = this.user_data
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
            // 调用 callback 返回建议列表的数据
            cb(results)
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
            }
        },
        handleSelect(item) {
            console.log('item', item)
            this.SDForm.use_name = item.value
            this.SDForm.use_no = item.use_no
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetAppSysUser(yvou_no) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SampleDelivery/GetAppSysUser', qs.stringify({
                yvou_no: yvou_no 
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                if(res.data.ResponseData.length === 1) {
                    this.SDForm.use_no = res.data.ResponseData[0].use_no
                    this.SDForm.use_name = res.data.ResponseData[0].use_name
                }

                res.data.ResponseData.map(item => {
                    this.user_data.push({
                        value: item.use_name,
                        label: item.use_no,
                    })
                })
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CustomValidate()){
                    console.log(this.SDForm)
                    this.SaveSample()
                }else{
                    console.log(valid.message)
                    return false
                }
                this.dialogTableVisible = false
            })
        },
        async SaveSample() {
            const SamplesDTO = {
                YVOU_NO: this.simpleNoData[0].value, // 单号
                DB_ID: this.DB_ID,
                YJWCDATE: moment(this.yjwcdate).format('YYYY-MM-DD')
            }
            let SamplejyList = []
            let SampleqrcodeList = []
            this.jy_data.map(item => {
                const AleKfSamplejyDTO = {
                    YVOU_NO: this.simpleNoData[0].value
                }
                const AleKfSampleqrcodeDTO = {
                    YVOU_NO: this.simpleNoData[0].value,
                    QRCODE: this.simpleNoData[0].value + '-' + item.code,
                    QTYL: item.qtyl,
                    QTYR: item.qtyr,
                }
                SamplejyList.push(
                    AleKfSamplejyDTO
                )
                SampleqrcodeList.push(
                    AleKfSampleqrcodeDTO
                )
            })
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SampleDelivery/SaveJYJL', qs.stringify({
                use_pnl: this.pnl,
                use_no: this.SDForm.use_no,
                SamplesDTO,
                SamplejyList,
                SampleqrcodeList     
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'success'
                })
                this.GetSamplesData(this.simpleNoData[0].value)
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        CustomValidate() {
            // console.log('this.SDForm.kqty', this.SDForm.kqty)
            // console.log('this.SDForm.qty', this.SDForm.qty)
            // console.log('this.SDForm.jqty', this.SDForm.jqty)
            if(this.SDForm.jqty + this.SDForm.qty > this.SDForm.kqty) {
                this.$message({
                    message: '样品交样数不能大于库存数！',
                    center: true,
                    type: 'warning'
                })
                return false
            }
            return true
        },
    },
    mounted() {
        this.GetSignature() // prod
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>