<template>
    <div>
        <div v-if="footer_menu.page1 && on_job">
            <el-image class="home-bar" fit="fit" lazy :src="require('@/assets/images/hq2.png')" alt=""></el-image>
            <el-carousel height="40px" direction="vertical" style="margin: 195px 0px 0px 0px">
                <el-carousel-item v-for="item in ekp_notices" :key="item.title">
                    <el-alert class="carousel-alert" :closable="false">
                        <template slot='title'>
                            <span style="margin-right: 10px;"> 
                                <el-tag type="danger" size="mini" effect="dark">[公告]</el-tag></span>
                            <span>
                                <el-link @click="toEKP(item.url)" :underline="false">
                                    {{ item.title }}
                                </el-link>
                            </span>
                        </template>
                    </el-alert>
                </el-carousel-item>
            </el-carousel>
            <el-card class="box-card" shadow="never">
                <h4>常用应用</h4>
                <el-row :gutter="30" style="display: flex;flex-wrap: wrap;">
                    <el-col :span="6" v-for="common in common_apps" :key="common.label">
                        <router-link :to="common.link_url">
                            <div>
                                <el-image class="app-icon" fit="contain" :src="common.icon_url"></el-image>
                            </div>
                            <span class="app-span">{{ common.label }}</span>
                        </router-link> 
                    </el-col>
                </el-row>
            </el-card>
            <el-card class="footer">
                <el-row type="flex" class="row-bg" justify="space-between">
                    <el-col :span="6">
                        <div style="margin-bottom: -3px;" @click="select_menu(1)">
                            <el-image fit="cover" :src="footer_menu.image_src1" alt=""></el-image>
                        </div>
                        <div :style="footer_menu.style1" @click="select_menu(1)">首页</div>
                    </el-col>
                    <el-col :span="6">
                        <div style="margin-bottom: -3px;" @click="select_menu(2)">
                            <el-image fit="cover" :src="footer_menu.image_src2" alt=""></el-image>
                        </div>
                        <div :style="footer_menu.style2" @click="select_menu(2)">应用</div>
                    </el-col>
                    <el-col :span="6">
                        <div style="margin-bottom: -3px;" @click="select_menu(3)">
                            <el-image fit="cover" :src="footer_menu.image_src3" alt=""></el-image>
                        </div>
                        <div :style="footer_menu.style3" @click="select_menu(3)">我的</div>
                    </el-col>
                </el-row>
            </el-card>
        </div>
        <el-result 
            icon="error" 
            title="您访问的资源不存在" 
            subTitle="请点击关闭按钮，退出该页面；如果是返厂员工，请点击注册按钮，重新注册" 
            v-if="!on_job">
            <template slot="extra">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-button type="primary" style="width: 100%" size="medium" @click="PageClose">关闭</el-button>
                    </el-col>
                </el-row>
            </template>
        </el-result>
    </div>
</template>

<script>
import conf from '../conf'
import qs from 'qs'
import axios from 'axios'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            on_job: true,
            pnl: '',
            pname: '',
            squareUrl: '',
            nickname: '',
            sec_nm: '',
            gw_sn: '',
            zw_nm: '',
            work_age: 0,
            ekp_url: 'http://ekp.qzhqxf.com:9611/sys/news/sys_news_main/sysNewsMain.do?method=view&fdId=',
            footer_menu: {
                page1: true,
                image_src1: require('@/assets/icon/iconPark24/首页_home.svg'),
                style1: {
                    color: '#409EFF',
                },
                page2: false,
                image_src2: require('@/assets/icon/iconPark24/606266/全部_all-application.svg'),
                style2: {
                    color: '#606266',
                },
                page3: false,
                image_src3: require('@/assets/icon/iconPark24/606266/用户_use.svg'),
                style3: {
                    color: '#606266',
                }
            },
            ekp_notices: [],
            // ekp_notices: [{
            //     title: '2024年自愿无偿献血事宜的通知',
            //     name: '熊欣芸',
            //     date: '08-05',
            //     url: 'http://ekp.qzhqxf.com:9611/sys/news/sys_news_main/sysNewsMain.do?method=view&fdId=191208498247ab55138297f4cc4a21a3',
            // },{
            //     title: '8月6日（周二）辞职人员共计17人',
            //     name: '张艳琴',
            //     date: '08-05',
            //     url: 'http://ekp.qzhqxf.com:9611/sys/news/sys_news_main/sysNewsMain.do?method=view&fdId=191210be01f984397562916400292a03',
            // },{
            //     title: '八月职行力每月一课学习 通知',
            //     name: '严丽娉',
            //     date: '08-02',
            //     url: 'http://ekp.qzhqxf.com:9611/sys/news/sys_news_main/sysNewsMain.do?method=view&fdId=191121e36e3d6a61dce1f894967801d5',
            // }],
            common_apps: []
            // common_apps: [{
            //     link_url: '/weekMenuImage',
            //     icon_url: require('@/assets/icon/md/餐厅菜谱.png'),
            //     label: '餐厅菜谱'
            // },{
            //     link_url: '/workOrderList',
            //     icon_url: require('@/assets/icon/md/工务维修.png'),
            //     label: '工务维修'
            // },{
            //     link_url: '/ehsCheckList',
            //     icon_url: require('@/assets/menu/EHS.png'),
            //     label: 'EHS'
            // },{
            //     link_url: '/maintain',
            //     icon_url: require('@/assets/icon/md/设备保养.png'),
            //     label: '设备保养'
            // },{
            //     link_url: '/inputOutput6',
            //     icon_url: require('@/assets/icon/md/投入产出.png'),
            //     label: '投入产出'
            // },{
            //     link_url: '/workInProcess',
            //     icon_url: require('@/assets/icon/md/在制明细.png'),
            //     label: '在制明细'
            // },{
            //     link_url: '/checkList',
            //     icon_url: require('@/assets/icon/md/生产巡检.png'),
            //     label: '生产巡检'
            // },{
            //     link_url: '/secYcRecord',
            //     icon_url: require('@/assets/icon/md/线别异常.png'),
            //     label: '线别异常'
            // },{
            //     link_url: '/repairOrderList',
            //     icon_url: require('@/assets/icon/md/设备报修.png'),
            //     label: '设备报修'
            // },{
            //     link_url: '/questionList',
            //     icon_url: require('@/assets/icon/md/我要提问.png'),
            //     label: '我要提问'
            // },{
            //     link_url: '/sample',
            //     icon_url: require('@/assets/icon/md/样鞋借用.png'),
            //     label: '样鞋借用'
            // },{
            //     link_url: '/remoteClock',
            //     icon_url: require('@/assets/icon/md/远程打卡.png'),
            //     label: '远程打卡'
            // }],
        }
    },
    methods: {
        select_menu(id) {
            if(id === 1) {
                window.location.href = '/homeIndex'
            }else if(id === 2) {
                window.location.href = '/homeApps'
            }else if(id === 3) {
                window.location.href = '/homeInfo'
            }
        },
        async getOpenid(code) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/AccessToken', qs.stringify({
                code
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                localStorage.setItem('openid', res.data.ResponseData.OPENID)
                localStorage.setItem('access_token', res.data.ResponseData.ACCESS_TOKEN)
                window.location.replace(conf.server_url_index)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async getAccessCode() {
            const state = ''
            const scope = 'snsapi_userinfo'
            const redirectUrl = encodeURIComponent(conf.server_url_index)
            const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${conf.weixinInfo.appid}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
            window.location.replace(url)
        },
        async getUserInfo(openid) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/UserInfo', qs.stringify({
                access_token: localStorage.getItem('access_token'),
                openid
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.squareUrl = res.data.ResponseData.HEADIMGURL
                this.nickname = res.data.ResponseData.NICKNAME
                localStorage.setItem('nickname', this.nickname)
                localStorage.setItem('headimgurl', this.squareUrl)
                this.CheckFirstLogin(openid)
            }else {
            // }else if(res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                localStorage.setItem('openid', '')
                this.getAccessCode()
            }
        },
        async CheckFirstLogin(openid) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxUsers/CheckOpenid', qs.stringify({
                openid
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                window.location.href = '/register'
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 0) {
                // 用openid取员工信息
                this.pnl = res.data.ResponseData[0].PNL
                this.pname = res.data.ResponseData[0].PNAME
                this.gw_sn = res.data.ResponseData[0].GW_SN
                this.sec_nm = res.data.ResponseData[0].SECNM
                this.work_age = res.data.ResponseData[0].WORK_AGE
                this.zw_nm = res.data.ResponseData[0].ZW_NM

                localStorage.setItem('PNL', this.pnl)
                localStorage.setItem('PNAME', this.pname)
                localStorage.setItem('PHONE', res.data.ResponseData[0].PHONE)
                localStorage.setItem('SEC_NO', res.data.ResponseData[0].SECNO)
                localStorage.setItem('SEC_NM', this.sec_nm)
                localStorage.setItem('WORK_AGE', this.work_age)
                localStorage.setItem('GW_SN', this.gw_sn)

                this.on_job = !res.data.ResponseData[0].DDATE ? true : false
                if(this.pnl === '1653' || this.pnl === '7735') { // 屏蔽
                    this.on_job = false
                }
                localStorage.setItem('ON_JOB', this.on_job ? 'Y' : 'N')
            }
        },
        toEKP(url) {
            window.location.href = url
        },
        PageClose() {
            wx.closeWindow()
        },
        async GetEKPCompanyNotice() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EKPCompanyNotice/GetEKPCompanyNotice')
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                res.data.ResponseData.forEach(item => {
                    item.title = item.title.substring(0, 20)
                    item.url = this.ekp_url + item.fd_id
                    this.ekp_notices.push(item)
                })
                // console.log('this.ekp_notices', this.ekp_notices)
            }
        },
        async GetCommonApps() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WeChat/GetCommonApps', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                res.data.ResponseData.forEach(item => {
                    // require('@/assets/icon/md/设备报修.png'),
                    // var path = '@/assets/icon/md/' + item.label + '.png'
                    // item.icon_url = require('@/assets/icon/md/' + item.label + '.png')
                    item.icon_url = conf.image_domain + item.FImgPath
                    this.common_apps.push(item)
                })
            }
        },
    },
    mounted() {
        this.$route.meta.title = '寰球移动办公平台'
        window.document.title = '寰球移动办公平台'
        this.GetEKPCompanyNotice()
        this.GetCommonApps()
    },
    created() {
        /** dev */
        if(!this.$route.query.openid){
            // 非跳转
            const openid = localStorage.getItem('openid') 
            if(!openid) {
                if(this.$route.query.code) {
                    this.getOpenid(this.$route.query.code)
                }else {
                    this.getAccessCode()
                }
            }else{
                this.getUserInfo(openid)
            }
        }else{ // 跳转 传 openid
            localStorage.setItem('openid', this.$route.query.openid)
            this.getUserInfo(this.$route.query.openid)
        }
        /** dev */
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
            // this.pnl = '55540' // dev
            // this.pnl = '66135' // dev
        }
    }
}
</script>

<style scoped>
    a:link { 
        text-decoration: none;
        color: #606266;
    }
    a:visited {
        text-decoration: none;
        color: #606266;
    }
    a:active { 
        text-decoration: blink;
        color: #606266;
    }
    .app-icon {
        width: 40px;
        height: 40px;
        margin-bottom: 4px;
        margin-top: 20px;
    }
    .app-span {
        font-size: 12px; 
        /* font-weight: 550; */
    }
    .box-card {
        padding: 0px 0px 15px 0px;
        margin-bottom: 5px;
        border-radius: 8px;
        /* margin: 15px -10px 5px -10px; */
    }
    .box-card h4 {
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 0px 10px 20px;
        margin: -20px -20px 10px -20px;
        background-color: rgb(217, 236, 255);
    }
    .footer {
        position: fixed; /* 使用fixed定位 */
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding: 0px 0px 15px 0px;
        font-size: 11px;
    }
    .span {
        font-size: 11px; 
        font-weight: 550;
    }
    /** home */
    .home-bar {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 200px;
    }
    .carousel-alert {
        background-color: rgb(225, 243, 216);
        padding: 10px 0px;
        border-radius: 8px;
    }
</style>