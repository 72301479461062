<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;margin-top: -20px;" stretch>
            <el-tab-pane label="件资按月" name="first" lazy>
                <el-form :model="pieceForm" :rules="rules" ref="pieceForm" label-width="80px" size="small">
                    <el-form-item label="件资月份" prop="yymm">
                        <el-col :span="16">
                            <el-date-picker
                                v-model="pieceForm.yymm"
                                align="center"
                                placeholder="请选择件资月份"
                                size="small"
                                type="month"
                                style="width: 100%;"
                                @change="GetPieceCapital">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="8">
                            <el-button type="primary" @click="submitForm('pieceForm')" icon="el-icon-refresh">刷新</el-button>
                        </el-col>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="件资按日" name="second" lazy>
                <el-form :model="pieceForm2" :rules="rules2" ref="pieceForm2" label-width="80px" size="small">
                    <el-form-item label="件资日期" prop="date">
                        <el-col :span="16">
                            <el-date-picker
                                v-model="pieceForm2.date"
                                align="center"
                                placeholder="请选择件资日期"
                                size="small"
                                style="width: 100%;"
                                @change="GetPieceCapital2">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="8">
                            <el-button type="primary" @click="submitForm2('pieceForm2')" icon="el-icon-refresh">刷新</el-button>
                        </el-col>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <el-alert type="success" v-if="isShow" center>
            <template slot='title'>
                <div>件资合计<span style="font-weight: bold;"> {{Math.floor(this.total_amt * 100) / 100}}</span></div>
                <div>（交单时间原因非最终件资）</div>
            </template>
        </el-alert>
        <el-table height="510px" 
            :data="table_data" 
            :show-header="false" 
            style="margin-top: -10px" 
            size="small" 
            v-loading="table_loading"  
            fit>
            <el-table-column width="60px" align="right">
                <template slot-scope="scope">
                    <div style="font-weight: bold; color: #409EFF;">{{scope.row.pdate}}</div>
                </template>
            </el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <div style="font-weight: bold">{{scope.row.opera_nm}}</div>
                    <div>{{scope.row.odr_no}} 【{{scope.row.style}}】</div>
                    <div>
                        <span class="col-span">
                            <i class="el-icon-receiving" style="font-weight: bold; color: #67C23A;"> {{scope.row.qty}}</i>
                        </span> 
                        <span class="col-span">
                            <i class="el-icon-price-tag" style="font-weight: bold; color: #E6A23C;"> {{scope.row.price}}</i>
                        </span> 
                        <span class="col-span" style="font-weight: bold; color: #F56C6C;">￥ {{scope.row.amt}}</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-row :gutter="20" style="margin-top:20px" v-if="table_data.length > 0 && activeName === 'first'">
            <el-col :span="12">
                <!-- <a :href="'tel:' + phone"> -->
                <!-- <el-button style="width: 100%" size="medium" type="danger" @click="dialogFormVisible = true">件资有误反馈</el-button> -->
                <!-- </a> -->
                <el-dropdown style="width: 100%" placement="top" :hide-on-click="false" trigger="click">
                    <el-button type="danger" style="width: 100%" size="medium">
                        件资有误反馈<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <a :href="'tel:' + phone">
                            <el-dropdown-item>反馈电话</el-dropdown-item>
                        </a>
                        <el-dropdown-item @click.native="dialogFormVisible = true">反馈留言</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
            <el-col :span="12">
                <el-button style="width: 100%" 
                    size="medium" 
                    type="success" 
                    :loading="isLoading" 
                    @click="ConfirmPieceCapital" 
                    :disabled="isVerify">件资无误确认</el-button>
            </el-col>
        </el-row>
        <el-dialog title="反馈信息" :visible.sync="dialogFormVisible" width="80%">
            <el-form :model="feedbackForm" size="mini" label-width="0px">
                <el-form-item label="">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="请填写具体的反馈信息"
                        maxlength="100"
                        show-word-limit
                        v-model="feedbackForm.phoneJzRemark">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="publicFeedback" :loading="isLoading2">提交</el-button>
            </div>
        </el-dialog>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import moment from 'moment'
export default {
    data() {
        return {
            isVerify: false,
            phone: '059522923035',
            activeName: 'first',
            pieceForm: {
                yymm: moment().format('YYYY-MM'),
            },
            pieceForm2: {
                date: moment().format('YYYY-MM-DD')
            },
            table_data: [],
            isShow: false,
            pnl: '',
            total_amt: 0,
            rules: {
                yymm: [
                    {required: true, message: '请选择件资月份', trigger: 'change'}
                ],
            },
            rules2: {
                date: [
                    {required: true, message: '请选择件资日期', trigger: 'change'}
                ]
            },
            isLoading: false,
            table_loading: false,
            feedbackForm: {
                phoneJzRemark: ''
            },
            dialogFormVisible: false,
            isLoading2: false,
        }
    },
    methods: {
        async publicFeedback() {
            this.isLoading2 = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PieceCapital/PublicFeedback', qs.stringify({
                pnl: this.pnl,
                yymm: moment(this.pieceForm.yymm).format('YYYY-MM'),
                phoneJzRemark: this.feedbackForm.phoneJzRemark
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: this.pieceForm.yymm + '，件资反馈成功！',
                    center: true,
                    type: 'success'
                })
                this.dialogFormVisible = false
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }
            this.isLoading2 = false
        },
        handleClick(tab) {
            console.log('tab', tab)
            if(tab.index === '0' || !tab.index) {
                this.submitForm('pieceForm')
            }else if(tab.index === '1') {
                this.submitForm2('pieceForm2')
            }
        },
        submitForm(formName) {
            console.log('formName', formName)
            console.log('this.$refs',  this.$refs)
            console.log('this.$refs[formName]',  this.$refs[formName])
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.pieceForm)
                    this.GetPieceCapital()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        submitForm2(formName) {
            console.log('formName', formName)
            console.log('this.$refs',  this.$refs)
            console.log('this.$refs[formName]',  this.$refs[formName])
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.pieceForm2)
                    this.GetPieceCapital2()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async GetPieceCapital() {
            if(moment(this.pieceForm.yymm).format('YYYY-MM-DD') === 'Invalid date') {
                return
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PieceCapital/PieceCapital', qs.stringify({
                pnl: this.pnl,
                yymm: moment(this.pieceForm.yymm).format('YYYY-MM')
            }))
            console.log(res)
            this.table_loading = true
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.table_data = []
                this.total_amt = 0
                res.data.ResponseData.map(item => {
                    this.table_data.push({
                        pdate: moment(item.pdate).format('MM/DD'),
                        odr_no: item.odr_no,
                        style: item.style,
                        opera_nm: item.xh + '、' + item.opera_nm,
                        qty: item.qty,
                        price: item.price,
                        amt: item.amt
                    })

                    this.total_amt += item.amt
                })
                if(res.data.ResponseData.length > 0) {
                    this.isShow = true
                }
            }else {
                this.isShow = false
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.table_loading = false
        },
        async GetPieceCapital2() {
            if(moment(this.pieceForm2.date).format('YYYY-MM-DD') === 'Invalid date') {
                return
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PieceCapital/PieceCapital2', qs.stringify({
                pnl: this.pnl,
                date: moment(this.pieceForm2.date).format('YYYY-MM-DD')
            }))
            console.log(res)
            this.table_loading = true
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.table_data = []
                this.total_amt = 0
                res.data.ResponseData.map(item => {
                    this.table_data.push({
                        pdate: moment(item.pdate).format('MM/DD'),
                        odr_no: item.odr_no,
                        style: item.style,
                        opera_nm: item.xh + '、' + item.opera_nm,
                        qty: item.qty,
                        price: item.price,
                        amt: item.amt
                    })
                    console.log('this.table_data', this.table_data)
                    this.total_amt += item.amt
                })
                if(res.data.ResponseData.length > 0) {
                    this.isShow = true
                }
            }else {
                this.isShow = false
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.table_loading = false
        },
        async ConfirmPieceCapital() {
            this.isLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PieceCapital/ConfirmPieceCapital', qs.stringify({
                pnl: this.pnl,
                yymm: moment(this.pieceForm.yymm).format('YYYY-MM')
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: this.pieceForm.yymm + '，件资确认成功！',
                    center: true,
                    type: 'success'
                })
                this.GetPieceCapitalStatus()
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }
            this.isLoading = false
        },
        async GetPieceCapitalStatus() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PieceCapital/PieceCapitalStatus', qs.stringify({
                pnl: this.pnl,
                yymm: moment(this.pieceForm.yymm).format('YYYY-MM')
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                if(res.data.ResponseData.length > 0) {
                    this.isVerify = res.data.ResponseData[0].isPhoneJzSured
                }
            }
        }
    },
    mounted() {
        this.GetPieceCapitalStatus()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
        }

        // 测试数据
        // this.pnl = '66389'
        //  this.pnl = '68985'
    }
}
</script>

<style scoped>
    .col-span{
        margin-right: 20px;
    }
</style>