<template>
    <div>
        <el-row :gutter="10">
            <el-col :span="6" v-for="item in secsData" :key="item.value">
                <el-button :type="item.btn_type" size="mini" style="width: 100%;" @click="selection(item)">{{item.label}}</el-button>
            </el-col>
        </el-row>
        <el-form :model="pipForm" ref="pipForm" label-width="0px" size="mini" style="padding-top: 40px;">
            <el-row :gutter="10">
                <el-col :span="10">
                    <el-form-item prop="start">
                        <el-date-picker
                            v-model="pipForm.start"
                            style="width: 100%;"
                            size="mini"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            placeholder="开始日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item prop="end">
                        <el-date-picker
                            v-model="pipForm.end"
                            style="width: 100%;"
                            size="mini"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            placeholder="截止日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item>
                        <el-button 
                            size="mini" 
                            type="primary" 
                            style="width: 100%;"
                            @click="SubmitForm('pipForm')">刷新</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item prop="key">
                <el-input v-model="pipForm.key" placeholder="请输入指令单号、工厂型号查询">
                    <el-button slot="append" style="color: #409EFF;" icon="el-icon-search" @click="SubmitForm('pipForm')"></el-button>
                </el-input>
            </el-form-item>
        </el-form>
        <el-table 
            show-summary
            max-height="550"
            :data="tableData" 
            :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
            size="mini" 
            v-loading="table_loading" 
            style="font-size: 11px;"
            border>
            <!-- <el-table-column prop="iod" label="配套日期" min-width="78px" align="center">
            </el-table-column> -->
            <el-table-column prop="iod" label="日期" min-width="50px" align="center">
            </el-table-column>
            <el-table-column prop="odr_no" label="指令单号" min-width="113px" align="center">
            </el-table-column>
            <el-table-column prop="artic_no" label="工厂型号" min-width="113px" align="center">
            </el-table-column>
            <!-- <el-table-column prop="qty" label="配套数" align="center">
            </el-table-column> -->
            <el-table-column prop="qty" label="配套数" min-width="58px" align="center">
            </el-table-column>
        </el-table>
        <br><br><br>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import moment from 'moment'
export default {
    data() {
        return {
            secsData: [
                {label: '全部', btn_type: 'primary', value: ''},
                {label: '高频', btn_type: 'default', value: '高频'},
                {label: '熔断', btn_type: 'default', value: '熔断'},
                {label: '印刷', btn_type: 'default', value: '印刷'}
            ],
            pipForm: {
                type: '',
                start: new moment().format("YYYY-MM-DD"),
                end: new moment().format("YYYY-MM-DD"),
                key: '' 
            },
            table_loading: false,
            tableData: []
        }
    },
    methods: {
        selection(e) {
            this.secsData.forEach(item => {
                if(item.value === e.value) {
                    item.btn_type = 'primary'
                    this.pipForm.type = item.value
                    // this.GetProcessInProcessData()
                }else {
                    item.btn_type = 'default'
                }
            })
        },
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.pipForm)
                    this.GetProcessInProcessData()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async GetProcessInProcessData() {
            this.tableData = []
            this.table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/ProcessInProcess/MatchingOutput', qs.stringify({
                start: this.pipForm.start, 
                end: this.pipForm.end, 
                type: this.pipForm.type, 
                key: this.pipForm.key
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                var filterData = res.data.ResponseData
                if(this.pipForm.key) {
                    filterData = res.data.ResponseData.filter((currentValue, index, arr) => {
                        if(currentValue.odr_no.indexOf(this.pipForm.key) > -1 
                            || currentValue.artic_no.indexOf(this.pipForm.key) > -1) {
                            return arr
                        }
                    })
                }
                filterData.map(item => {
                    this.tableData.push({
                        iod: moment(item.iod).format('MM-DD'),
                        odr_no: item.odr_no,
                        artic_no: item.artic_no,
                        qty: item.qty
                    })
                })

                // 合计
                // if(filterData.length > 0) {
                //     this.tableData.push({
                //         iod: '',
                //         odr_no: '合计',
                //         artic_no: '',
                //         qty: filterData.reduce((total, item) => {
                //             // console.log(total)
                //             // console.log(item)
                //             return total + item.qty
                //         }, 0)
                //     })
                // }
            }

            this.table_loading = false
        }
    },
    mounted() {
        this.GetProcessInProcessData()
    }
}
</script>