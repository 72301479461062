<template>
    <div>
        <el-alert type="error" v-if="!isRight" show-icon>
            <template slot='title'>
                <div class="iconSize">没有访问权限！</div>
            </template>
        </el-alert>
        <div v-if="isRight">
            <el-form :model="spaForm" ref="spaForm" label-width="70px" size="small">
                <el-form-item label="日期" prop="iod" style="font-weight: bold">
                    <el-date-picker
                        v-model="spaForm.iod"
                        @change="GetOperaDataAudit"
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        style="width: 100%"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <el-skeleton style="width: 100%;text-align: left;margin-bottom: 20px" :rows="10" animated :loading="skeleton_loading">
                <el-table 
                    :data="tableData" 
                    size="mini" 
                    :show-header="false" 
                    :default-expand-all="true"
                    style="margin-bottom: 20px;font-size: 13px;">
                    <el-table-column type="expand" width="15">
                        <template slot-scope="props">
                            <div>
                                <span v-for="item in props.row.children" :key="item.DB_ID" style="margin-right: 30px">
                                    <el-badge :value="item.qty" class="item" v-if="item.pname" style="margin-top: 5px">
                                        <el-tag size="small" :type="item.verify ? 'success' : 'default'"> {{item.pname}} </el-tag>
                                    </el-badge>
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="opera_nm" label="工序名称">
                        <template slot-scope="scope">
                            <div>{{scope.row.key.opera_no}}、{{scope.row.key.opera_nm}}、{{ scope.row.key.style }}、{{ scope.row.key.odr_no }}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="fqty" label="操作"  width="55">
                        <template slot-scope="scope">
                            <div v-if="(spaForm.qty - (scope.row.key.fqty ? scope.row.key.fqty : 0)) > 0">
                                <el-tag size="mini" effect="dark" type="warning">- {{spaForm.qty - (scope.row.key.fqty ? scope.row.key.fqty : 0)}} </el-tag>
                            </div>
                        </template>
                    </el-table-column> -->
                </el-table>
            </el-skeleton>
            <el-row>
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="success" 
                        :loading="isLoading"
                        @click="GjjzIoListAudit">件资审核</el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import moment from 'moment'
import qs from 'qs'
export default {
    data() {
        return {
            spaForm: {
                pnl: '',
                qty: '',
                style: '',
                odr_no: '',
                iod: '',
                artic_no: '',
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() || time.getTime() < moment().add(-7, 'day')
                },
            },
            rules: {},
            isRight: false,
            isLoading: false,
            tableData: [],
            skeleton_loading: false
        }
    },
    methods: {
        async GetOperaDataAudit() {
            this.skeleton_loading = true
            this.tableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/GetOperaDataShoesAudit',qs.stringify({
                // style: this.spaForm.style,
                // odr_no: this.spaForm.odr_no,
                iod: this.spaForm.iod,
                // sec_no: this.spaForm.sec_no,
                made_no: '7',
                opera_no: '703-1'
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.tableData = this.GroupByOperaNo(res.data.ResponseData, ['opera_no','opera_nm','style','odr_no','fqty'])
                console.log('tableData', this.tableData)
            }
            this.skeleton_loading = false
        },
        GroupByOperaNo(datas, keys, callBack) {
            const list = datas || []
            const groups = []
            list.forEach(v => {
                // let key = v.opera_no
                let key = {}
                keys.forEach(k => {
                    key[k] = v[k]
                })
                let group = groups.find(v => {
                    return v._key === JSON.stringify(key)
                })
                if(!group) {
                    // group = {
                    //     odr_no: v.odr_no,
                    //     style: v.style,
                    //     opera_no: v.opera_no,
                    //     opera_nm: v.opera_nm,
                    //     fqty: v.fqty,
                    // }
                    group = {
                        _key: JSON.stringify(key),
                        key: key
                    }
                    groups.push(group)
                }
                if(callBack) {
                    group.children = callBack(group.children, v)
                }else {
                    group.children = group.children || []
                    if(v.qty > 0) {
                        group.children.push({
                            pname: v.pname,
                            qty: v.qty,
                            pnl: v.pnl,
                            DB_ID: v.DB_ID,
                            verify: v.verify
                        })
                    }
                }
            })
            return groups
        },
        async GjjzIoListAudit() {
            // const dtos = []
            this.isLoading = true
            // this.tableData.forEach(item => {
            //     item.children.forEach(el => {
            //         dtos.push({
            //             DB_ID:  el.DB_ID,
            //             VERIFYMAN: this.spaForm.pnl
            //         })
            //     })
            // })
            
            // console.log('dtos', dtos)
            const dto = {
                VERIFYMAN: this.spaForm.pnl,
                IOD: this.spaForm.iod,
                MADE_NO: '7',
                OPERA_NO: '703-1'
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/GjjzIoAudit', qs.stringify({
                dto
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: '件资审核成功',
                    center: true,
                    type: 'success'
                })

                this.GetOperaDataAudit()
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '件资审核失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '件资审核失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
    },
    mounted() {

    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.spaForm.pnl = pnl
        }

        // admin
        if(this.spaForm.pnl === '16037' || this.spaForm.pnl === '55129' || this.spaForm.pnl === '43528' ) {
            this.isRight = true
        }
    }
}
</script>