<template>
    <div>
        <el-card shadow="always" v-loading="data_count_loading">
            <el-row :gutter="20" style="padding-bottom: 10px; padding-top: 5px">
                <el-col :span="12" v-for="item in TagDatas" :key="item.tag_type">
                    <el-badge :value="item.badge_value === '0' ? null : item.badge_value" style="width: 100%"> 
                        <el-tag
                            style="width: 100%;"
                            :type="item.tag_type"
                            @click="tag_click(item)"
                            :effect="item.tag_effect">
                            {{item.label}}
                        </el-tag>
                    </el-badge>
                </el-col>
            </el-row>
        </el-card>
        <div id="card_detail">
            <el-card :id="item.DB_ID" shadow="always" v-for="(item, index) in water_list" :key="index" class="box-card" v-loading="data_loading">
                <el-row>
                    <el-col :span="6" style="text-align: left">购水日期</el-col>
                    <el-col :span="18" style="text-align: right">{{item.FDATE}}</el-col>
                </el-row>
                <el-divider/>
                <el-row>
                    <el-col :span="6" style="text-align: left">购水信息</el-col>
                    <el-col :span="18" style="text-align: right">
                        {{item.FTYPE === '1' ? item.SEC_NM : '宿舍：' + item.ROOM_NO}}，
                        {{ item.QQTY }}桶，{{ item.FREMARK }}</el-col>
                </el-row>
                <el-divider/>
                <el-row>
                    <el-col :span="6" style="text-align: left">联系信息</el-col>
                    <el-col :span="18" style="text-align: right">{{item.PNAME}}，
                        <a :href="'tel:' + item.TEL">
                            <i class="el-icon-mobile-phone"></i>{{item.TEL}}
                        </a>
                    </el-col>
                </el-row>
                <el-divider v-if="is_manager && item.FSTATUS === '0'"/>
                <el-row :gutter="10" v-if="is_manager && item.FSTATUS === '0'">
                    <el-col :span="24">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="success" 
                            @click="doneExpectDiolog(item.DB_ID, item.QQTY, item.PRICE)">送水完成</el-button>
                    </el-col>
                </el-row>
                <el-divider v-if="item.FSTATUS ==='1' && item.HAVE_IMAGE !== 0" />
                <el-row v-if="item.FSTATUS ==='1' && item.HAVE_IMAGE !== 0">
                    <el-col :span="6" style="text-align: left">问题图片</el-col>
                    <el-col :span="18" style="text-align: right">
                        <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.DB_ID)">
                            <i class="el-icon-picture-outline"></i> 点击查看
                        </el-link>
                    </el-col>
                </el-row>
            </el-card>
        </div>
        <el-row style="margin-top: 20px;margin-bottom: 20px;" v-if="!is_manager">
            <el-col :span="24">
                <router-link to="/deliverWater">
                    <el-button style="width: 100%" type="primary">桶装水购买</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-row style="margin-top: 20px;margin-bottom: 20px;" v-if="is_manager">
            <el-col :span="24">
                <router-link to="/deliverWater">
                    <el-button style="width: 100%" type="success">桶装水配送</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-dialog title="送水完成" :visible.sync="doneDialogFormVisible" width="80%">
            <el-form :model="doneForm" ref="doneForm" :rules="rules" size="mini" label-width="55px">
                <el-form-item label="数量" prop="qty">
                    <el-input-number 
                        size="small" 
                        :min="1"
                        :precision="0"
                       
                        v-model="doneForm.qty"></el-input-number> 桶
                </el-form-item>
                <el-form-item label="凭证" prop="fileList">
                    <el-upload 
                        action='' 
                        ref="upload"
                        multiple
                        :on-change="getFile" 
                        :limit="1" 
                        :on-remove="handleRemove"
                        list-type="picture"
                        :auto-upload="false" 
                        :accept="'image/*'"
                        :on-exceed="handleExceed"
                        style="width: 100%;" >
                        <el-button size="small" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="doneDialogFormVisible = false">取消</el-button>
                <el-button size="small" type="success" @click="SubmitForm('doneForm')">提交</el-button>
            </div>
        </el-dialog>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import wx from 'weixin-js-sdk'
import { compressImage } from '../../../plugin/compress.js'
export default {
    data() {
        return {
            TagDatas: [
                {badge_value: '0', tag_type: 'primary', tag_effect: 'dark', label: '待送水', type: '0'},
                {badge_value: '0', tag_type: 'success', tag_effect: 'plain', label: '已送达', type: '1'}
            ],
            data_count_loading: false,
            data_loading: false,
            water_list: [],
            doneDialogFormVisible: false,
            current_DB_ID: '',
            is_manager: false,
            doneForm: {
                fileList: [],
                imageList: [],
                qty: 1
            },
            rules: {
                fileList: [
                    {required: true, message: '请上传完成凭证', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        async GetDataCounts() {
            this.data_count_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDeliverWaters/GetDeliverListCounts', qs.stringify({
                pnl: this.is_manager ? '' : this.pnl,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                res.data.ResponseData.map(item => {
                    this.TagDatas.forEach(el => {
                        if(el.type === item.FSTATUS.toString()) {
                            el.badge_value = item.FCOUNT === 0 ? null : item.FCOUNT
                        }
                    })
                })
            }else {
                this.$message({
                    message: '获取工务维修数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }

            this.data_count_loading = false
            this.GetDataByType('0')
        },
        tag_click(item) {
            this.TagDatas.forEach(el => {
                if(item.tag_type === el.tag_type) {
                    el.tag_effect = 'dark'
                    this.GetDataByType(el.type)
                } else {
                    el.tag_effect = 'plain'
                }
            })
        },
        async GetDataByType(type) {
            this.data_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDeliverWaters/GetDeliverWaterList', qs.stringify({
                pnl: this.is_manager ? '' : this.pnl,
                type: type
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.water_list = res.data.ResponseData
            }else {
                this.$message({
                    message: '获取桶装水数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.data_loading = false
        },
        doneExpectDiolog(DB_ID, QTY, PRICE) {
            this.doneDialogFormVisible = true
            this.current_DB_ID = DB_ID
            this.doneForm.qty = QTY
            this.current_PRICE = PRICE
            // this.doneForm.done_reason = ''
        },
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CustomValidate()){
                    this.doneExpect()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        CustomValidate() {
            if(this.doneForm.fileList.length === 0) {
                this.$message({
                    message: '请上传完成凭证',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
                return false
            }
            return true
        },
        doneExpect() {
            this.updateStatus(this.current_DB_ID, 1, this.current_PRICE)
            this.doneDialogFormVisible = false
        },
        async updateStatus(DB_ID, FSTATUS, PRICE) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDeliverWaters/UpdateStatus', qs.stringify({
                DB_ID, 
                FSTATUS,
                PRICE,
                QTY: this.doneForm.qty,
                IMAGE1: encodeURIComponent(this.doneForm.imageList[0]),
                FILENAME1: this.doneForm.fileList[0]
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.$message({
                    message: '送水已完成！',
                    center: true,
                    type: 'success'
                })
                this.GetDataCounts()
                this.water_list.forEach(el => {
                    if(el.DB_ID === DB_ID) {
                        el.FSTATUS = FSTATUS
                    }
                })
            }
        },
        ShowImages(id) {
            this.GetImagesByID(id)
        },
        async GetImagesByID(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        // this.imageList.push({
                        //     id: item.DB_ID,
                        //     path: conf.image_domain + item.FImgPath
                        // })
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                           path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
                console.log('this.imageList', this.imageList)
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
        getFile(file, fileList) {
            this.doneForm.fileList = []
            this.doneForm.imageList = []
            fileList.forEach(item => {
                this.doneForm.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.doneForm.imageList.push(result)
                })
                // console.log('this.doneForm.imageList', this.doneForm.imageList)
            })
        },
        handleRemove(file, fileList) {
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.doneForm.fileList = []
            this.doneForm.imageList = []
            fileList.forEach(item => {
                this.doneForm.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.doneForm.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        },
    },
    mounted() {
        this.GetSignature()
        this.GetDataCounts()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
            // this.pnl = '66031' // dev
            // this.pnl = '62638' // dev
            if(this.pnl === '62638' || this.pnl === '58157' || this.pnl === '51254') {
                this.is_manager = true
            }
        }
    }
}
</script>