<template>
    <div>
        <el-alert
            type="error"
            v-if="!isRight"
            show-icon>
            <template slot='title'>
                <div class="iconSize">没有访问权限！</div>
            </template>
        </el-alert>
        <div v-if="isRight">
            <el-form :model="surfaceAuditForm" ref="surfaceAuditForm" label-width="70px" size="small">
                <el-form-item label="组别" prop="sec_no" style="font-weight: bold">
                    <el-select v-model="surfaceAuditForm.sec_no" placeholder="请选择组别" style="width: 100%;" @change="SecChange">
                        <el-option
                            v-for="item in sec_nos"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            <span style="float: left">{{ item.label }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="日期" prop="iod" style="font-weight: bold">
                    <el-date-picker
                        v-model="surfaceAuditForm.iod"
                        @change="GetOdrNoData"
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        style="width: 100%"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="指令单" prop="odr_no" style="font-weight: bold">
                    <el-select v-model="surfaceAuditForm.odr_no" placeholder="请选择指令单" size="small" style="width: 100%;" @change="SetArticQty">
                        <el-option
                            v-for="item in odr_nos"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            <span style="float: left">{{ item.label }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="货号" prop="artic_no" style="font-weight: bold">
                    <el-input v-model="surfaceAuditForm.artic_no" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="缴库数" prop="qty" style="font-weight: bold">
                    <el-input v-model="surfaceAuditForm.qty" :disabled="true"></el-input>
                </el-form-item>
            </el-form>
            <el-skeleton style="width: 100%;text-align: left;margin-bottom: 20px" :rows="10" animated :loading="skeleton_loading">
                <el-table 
                    :data="tableData" 
                    size="mini" 
                    :show-header="false" 
                    :default-expand-all="true"
                    style="margin-bottom: 20px;font-size: 13px;">
                    <el-table-column type="expand" width="15">
                        <template slot-scope="props">
                            <div>
                                <span v-for="item in props.row.children" :key="item.DB_ID" style="margin-right: 30px">
                                    <el-badge :value="item.qty" class="item" v-if="item.pname">
                                        <el-tag size="small" :type="item.verify ? 'success' : 'default'"> {{item.pname}} </el-tag>
                                    </el-badge>
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="opera_nm" label="工序名称">
                        <template slot-scope="scope">
                            <div>{{scope.row.opera_no}}、{{scope.row.opera_nm}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fqty" label="操作"  width="55">
                        <template slot-scope="scope">
                            <div v-if="(surfaceAuditForm.qty - (scope.row.fqty ? scope.row.fqty : 0)) > 0">
                            <el-tag size="mini" effect="dark" type="warning">- {{surfaceAuditForm.qty - (scope.row.fqty ? scope.row.fqty : 0)}} </el-tag>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-skeleton>
            <el-row>
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="success" 
                        :loading="isLoading"
                        @click="GjjzIoListAudit">件资审核</el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import moment from 'moment'
import qs from 'qs'
export default {
    data() {
        return {
            sec_nos: [],
            surfaceAuditForm: {
                sec_no: '',
                iod: '',
                odr_no: '',
                artic_no: '',
                pnl: '',
                qty: '',
                style: ''
            },
            odr_nos: [],
            tableData: [],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() || time.getTime() < moment().add(-19, 'day')
                },
            },
            isLoading: false,
            isRight: false,
            skeleton_loading: false
        }
    },
    methods: {
        SecChange(item) {
            console.log('item', item)
            this.GetOdrNoData()
            this.GetOperaDataAudit()
        },
        async GetSecDataAudit() {
            this.secs = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/GetSecDataAudit', qs.stringify({
                pnl: this.surfaceAuditForm.pnl,
                made_no: '3'
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.forEach(item => {
                    this.sec_nos.push({
                        label: item.sec_nm,
                        value: item.sec_no,
                    })
                })

                if(this.surfaceAuditForm.pnl === '68397') {
                    this.sec_nos.push({
                        label: '针三6组',
                        value: 'HQ92J8'
                    })
                }
            }
        },
        async GetOdrNoData() {
            this.odr_nos = []
            this.surfaceAuditForm.odr_no = ''
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/GetOdrNoData',qs.stringify({
                sec_no: this.surfaceAuditForm.sec_no,
                iod: this.surfaceAuditForm.iod,
                made_no: '3',
                pro_no: '03'
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.forEach(item => {
                    this.odr_nos.push({
                        label: item.odr_no,
                        value: item.odr_no,
                        artic_no: item.artic_no,
                        qty: item.qty,
                        style: item.style
                    })
                })
            }
        },
        SetArticQty() {
            if(this.odr_nos) {
                let data = this.odr_nos.find(item => item.value === this.surfaceAuditForm.odr_no )
                this.surfaceAuditForm.artic_no = data.artic_no
                this.surfaceAuditForm.qty = data.qty
                this.surfaceAuditForm.style = data.style
                this.GetOperaDataAudit()
            }
        },
        async GetOperaDataAudit() {
            this.skeleton_loading = true
            this.tableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/GetOperaDataAudit',qs.stringify({
                style: this.surfaceAuditForm.style,
                odr_no: this.surfaceAuditForm.odr_no,
                iod: this.surfaceAuditForm.iod,
                sec_no: this.surfaceAuditForm.sec_no,
                made_no: '3',
                operaclass_no: ''
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.tableData = this.GroupByOperaNo(res.data.ResponseData, 'opera_no')
                console.log('tableData', this.tableData)
            }
            this.skeleton_loading = false
        },
        GroupByOperaNo(datas, key, callBack) {
            const list = datas || []
            const groups = []
            list.forEach(v => {
                let key = v.opera_no
                let group = groups.find(v => {
                    return v.opera_no === key
                })
                if(!group) {
                    group = {
                        opera_no: v.opera_no,
                        opera_nm: v.opera_nm,
                        fqty: v.fqty,
                    }
                    groups.push(group)
                }
                if(callBack) {
                    group.children = callBack(group.children, v)
                }else {
                    group.children = group.children || []
                    if(v.qty > 0) {
                        group.children.push({
                            pname: v.pname,
                            qty: v.qty,
                            pnl: v.pnl,
                            DB_ID: v.DB_ID,
                            verify: v.verify
                        })
                    }
                }
            })
            return groups
        },
        async GjjzIoListAudit() {
            const dtos = []
            this.isLoading = true
            this.tableData.forEach(item => {
                item.children.forEach(el => {
                    dtos.push({
                        DB_ID:  el.DB_ID,
                        VERIFYMAN: this.surfaceAuditForm.pnl
                    })
                })
            })
            
            console.log('dtos', dtos)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsGjjzIos/GjjzIoListAudit', qs.stringify({
                dtos
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: '件资审核成功',
                    center: true,
                    type: 'success'
                })

                this.GetOperaDataAudit()
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '件资审核失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '件资审核失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        async GetAuditPerson() {
            this.secs = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxProProcessauditSettings/GetAuditPerson', qs.stringify({
                pnl: this.surfaceAuditForm.pnl,
                made_no: '3'
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                if(res.data.ResponseData.length > 0) {
                    this.isRight = true
                }
            }else {
                this.isRight = false
            }
        },
    },
    mounted() {
        this.GetSecDataAudit()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.surfaceAuditForm.pnl = pnl
            // this.surfaceAuditForm.pnl = '13200'
            // this.surfaceAuditForm.pnl = '12994' // dev
            // this.surfaceAuditForm.pnl = '22699' // dev 
            // this.surfaceAuditForm.pnl = '0441' // dev
            // this.surfaceAuditForm.pnl = '53942' // dev
            // this.surfaceAuditForm.pnl = '0363' // dev
            // this.surfaceAuditForm.pnl = '' // dev
        }

        this.GetAuditPerson()
    }
}
</script>