<template>
    <div>
        <el-form :model="waterForm" :rules="rules" ref="waterForm" label-width="90px" size="medium">
            <el-form-item label="员工工号" prop="pnl" style="font-weight: bold">
                <el-input v-model="waterForm.pnl" placeholder="请输入员工工号" size="medium" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="员工姓名" prop="pname" style="font-weight: bold">
                <el-input v-model="waterForm.pname" size="medium" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone" style="font-weight: bold">
                <el-input v-model="waterForm.phone" size="medium" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="送水性质" prop="ftype" style="font-weight: bold">
                <el-radio v-model="waterForm.ftype" label="1" @change="typeChange">部门</el-radio>
                <el-radio v-model="waterForm.ftype" label="2" @change="typeChange" :disabled="!is_room">个人</el-radio>
            </el-form-item>
            <el-form-item v-if="waterForm.ftype === '1'" label="送水单位" prop="sec_nm" style="font-weight: bold">
                <el-cascader
                    :show-all-levels="false"
                    v-model="waterForm.sec_nm"
                    @change="SecChange"
                    clearable
                    style="width: 100%"
                    placeholder="请选择单位"
                    :options="options">
                </el-cascader>
            </el-form-item>
            <el-form-item v-if="waterForm.ftype === '2'" label="房间号" prop="room_no" style="font-weight: bold">
                <el-input v-model="waterForm.room_no" size="medium" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="送水数量" prop="qqty" style="font-weight: bold">
                <el-input-number 
                    size="medium" 
                    :min="1"
                    :precision="0"
                    style="width: 85%;" 
                    placeholder="请输入桶装水数量" 
                    :disabled="waterForm.ftype === '2'"
                    v-model="waterForm.qqty"></el-input-number> 桶
            </el-form-item>
            <el-form-item v-if="is_manager" label="送水图片" prop="fileList" style="font-weight: bold">
                <el-upload 
                        action='' 
                        ref="upload"
                        multiple
                        :on-change="getFile" 
                        :limit="1" 
                        :on-remove="handleRemove"
                        list-type="picture"
                        :auto-upload="false" 
                        :accept="'image/*'"
                        :on-exceed="handleExceed"
                        style="width: 100%;" >
                        <el-button size="small" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                    </el-upload>
            </el-form-item>
            <el-form-item label="其他说明" prop="fremark" style="font-weight: bold">
                <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入具体位置信息"
                    v-model="waterForm.fremark">
                </el-input>
            </el-form-item>
            <el-row style="margin-top: 10px;margin-bottom: 20px">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        :type="is_manager ? 'success' : 'primary'" 
                        :loading="isLoading"
                        @click="submitForm('waterForm')">提交送水申请单</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import { compressImage } from '../../../plugin/compress.js'
export default {
    data() {
        return {
            waterForm: {
                pnl: '',
                pname: '',
                phone: '',
                ftype: '',
                room_no: '',
                fremark: '',
                qqty: 0,
                sec_nm: '',
                fstatus: '0',
                qty: 0
            },
            is_room: false,
            is_manager: false,
            options: [],
            isLoading: false,
            fileList: [],
            imageList: [],
            rules: {
                pnl: [
                    {required: true, message: '请输入员工工号', trigger: 'blur'}
                ],
                ftype: [
                    {required: true, message: '请选择送水性质', trigger: 'change'}
                ],
                qqty: [
                    {required: true, message: '请输入桶装水数量', trigger: 'change'}
                ],
            } 
        }
    },
    methods: {
        SecChange(item) {
            console.log('item', item)
            this.waterForm.sec_nm = item[1]
        },
        async GetSecData() {
            this.secs = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDeliverWaters/GetSecData',)
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.options = this.GroupBy(res.data.ResponseData, 'sec_nm1')
                console.log('options', this.options)
            }
        },
        GroupBy(datas, key, callBack) {
            const list = datas || []
            const groups = []
            list.forEach(v => {
                let key = v.sec_nm1
                let group = groups.find(v => {
                    return v.value === key
                })
                if(!group) {
                    group = {
                        label: v.sec_nm1,
                        value: v.sec_nm1
                    }
                    groups.push(group)
                }
                if(callBack) {
                    group.children = callBack(group.children, v)
                }else {
                    group.children = group.children || []
                    group.children.push({
                        label: v.sec_nm,
                        value: v.sec_nm
                    })
                }
            })
            return groups
        },
        async getPersonByPnl() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDeliverWaters/PersonByPnl', qs.stringify({
                pnl: this.waterForm.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.waterForm.pname = res.data.ResponseData[0].pname
                this.waterForm.sec_no = res.data.ResponseData[0].sec_no
                this.waterForm.room_no = res.data.ResponseData[0].room_no
                this.waterForm.phone = res.data.ResponseData[0].tel
                if(this.waterForm.room_no) {
                    this.is_room = true
                    // this.waterForm.ftype = 1
                }
            }
        },
        typeChange() {
            if(this.waterForm.ftype === '1') {
                this.waterForm.qqty = 0
                this.GetSecData()
            }else{
                this.waterForm.qqty = 1
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.waterForm)
                    this.SaveDeliverWater()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveDeliverWater() {
            this.isLoading = true
            if(this.is_manager) {
                this.waterForm.qty = this.waterForm.qqty
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxDeliverWaters/SaveDeliverWater', qs.stringify({
                dto: this.waterForm,
                IMAGE1: encodeURIComponent(this.imageList[0]),
                FILENAME1: this.fileList[0]
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                console.log('送水单提交成功:', res.data.ResponseData)
                this.$message({
                    message: '送水单提交成功',
                    center: true,
                    type: 'success'
                })

                setTimeout(function(){
                    window.location.href = '/deliverWaterList'
                },2000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '送水单提交失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '送水单提交失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        getFile(file, fileList) {
            this.fileList = []
            this.imageList = []
            fileList.forEach(item => {
                this.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.imageList.push(result)
                })
                // console.log('this.imageList', this.imageList)
            })
        },
        handleRemove(file, fileList) {
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.fileList = []
            this.imageList = []
            fileList.forEach(item => {
                this.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        }
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.waterForm.pnl = pnl
            // this.waterForm.pnl = '66031'
            // this.waterForm.pnl = '62638'
            if(this.waterForm.pnl === '62638' || this.waterForm.pnl === '58157' || this.waterForm.pnl === '51254') {
                this.is_manager = true
                this.waterForm.fstatus = '1'
                this.is_room = false
            }
            this.getPersonByPnl()
        }
        const pname = localStorage.getItem('PNAME')
        if(pname){
            this.waterForm.pname = pname
        }
        const phone = localStorage.getItem('PHONE')
        if(phone){
            this.waterForm.phone = phone
        }
    }
}
</script>