<template>
    <div>
        EKP跳转中...
    </div>
</template>

<script>
import conf from '../../conf'
export default {
    data() {
        return {
            pnl: ''
        }
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    },
    mounted() {
        window.location.href = conf.ekp_url_safe
    }
}
</script>