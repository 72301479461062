<template>
    <div>
        <el-form 
            :model="pmForm" 
            :rules="Rules" 
            ref="pmForm" 
            label-width="0px" 
            size="small">
            <el-row :gutter="10">
                <el-col :span="18">
                    <el-form-item prop="date" style="font-weight: bold;">
                        <el-date-picker
                            v-model="pmForm.date"
                            style="width: 100%;"
                            size="small"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-button 
                        size="small" 
                        type="primary" 
                        icon="el-icon-refresh" 
                        style="width: 100%;"
                        @click="SubmitForm('pmForm')">刷新</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-table 
            max-height="580"
            :data="tableData" 
            :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
            size="mini" 
            v-loading="table_loading" 
            style="font-size: 11px;margin-top: 10px;"
            border>
            <el-table-column prop="style" label="工厂型体" min-width="95px" align="center">
            </el-table-column>
            <el-table-column prop="tqty" label="总指令" min-width="71px" align="center">
            </el-table-column>
            <el-table-column prop="qty" label="累配套" min-width="59px" align="center">
            </el-table-column>
            <el-table-column prop="deqty" label="总欠数" min-width="71px" align="center">
            </el-table-column>
            <el-table-column prop="dqty" label="日套数" min-width="59px" align="center">
            </el-table-column>
        </el-table>
        <br><br><br>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import moment from 'moment'
export default {
    data() {
        return {
            pmForm: {
                date: new moment().format("YYYY-MM-DD")
            },
            table_loading: false,
            tableData: [],
            Rules: {
                date: [
                    {required: true, message: '请选择日期', trigger: 'change'}
                ]
            }
        }
    },
    methods: {
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.pmForm)
                    this.GetClassMatchingOutput()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async GetClassMatchingOutput() {
            this.tableData = []
            this.table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/ProcessMatching/ClassMatchingOutput', qs.stringify({
                date: this.pmForm.date
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.tableData = res.data.ResponseData.Table1
                // 合计
                this.tableData.push({
                    style: '合计',
                    tqty: '',
                    qty: '',
                    deqty: '',
                    dqty: this.tableData.reduce((total, item) => {
                        return total + item.dqty
                    }, 0)
                })
                // 月累计配套
                this.tableData.push({
                    style: '月累计配套',
                    tqty: '',
                    qty: '',
                    deqty: '',
                    dqty: res.data.ResponseData.Table2[0].qty
                })
            }

            this.table_loading = false
        }
    },
    mounted() {
        this.GetClassMatchingOutput()
    },
}
</script>