<template>
    <div>
        <el-row style="margin-bottom: 25px" :gutter="30">
            <el-col :span="8" v-for="item in TagDatas" :key="item.tag_type">
                <el-badge :value="item.badge_value === '0' ? null : item.badge_value" class="item"> 
                    <el-button
                        :plain="item.tag_effect"
                        size="medium"
                        :type="item.tag_type"
                        style="width: 100%"
                        @click="tag_click(item)">
                        {{item.label}}
                    </el-button>
                </el-badge>
            </el-col>
        </el-row>
        <el-row style="margin-bottom:10px;">
            <el-col :span="24">
                <router-link to="/EHS">
                    <el-button style="width: 100%" type="primary">问题提报</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-dialog :visible.sync="dialogTableVisible" size="mini" width="90%">
            <span class="dialog-footer">
                <div style="font-weight: bold;text-align: left;font-size: 13px;margin-bottom: 10px">驳回原因</div>
                <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入驳回原因"
                    v-model="reason">
                </el-input>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogTableVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="UpdateEHS('驳回', dialog_item)">确定</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="dialogTableVisible2" size="mini" width="90%">
            <!-- <span slot="title" class="dialog-footer">
                <div style="font-weight: bold;text-align: left;font-size: 15px;">指定处理人</div>
            </span> -->
            <el-form :model="ehsForm" :rules="rules" ref="ehsForm" label-width="80px" size="mini">
                <el-form-item label="问题类别" prop="type">
                    <el-select 
                        v-model="ehsForm.type" 
                        placeholder="请选择问题类别" 
                        size="mini" 
                        @change="typeChange" 
                        style="width: 100%">
                        <el-option
                            v-for="item in ehsForm.ehs_type"
                            :key="item.FID"
                            :label="item.FNAME"
                            :value="item.FID">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级类别" prop="types">
                    <el-select 
                        v-model="ehsForm.types" 
                        placeholder="请选择问题类别" 
                        size="mini" 
                        @change="typesChange" 
                        style="width: 100%">
                        <el-option
                            v-for="item in ehsForm.ehs_types"
                            :key="item.FID"
                            :label="item.FNAME"
                            :value="item.FID">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="三级类别" prop="typess">
                    <el-select 
                        v-model="ehsForm.typess" 
                        placeholder="请选择问题类别" 
                        size="mini" 
                        @change="typessChange"
                        style="width: 100%">
                        <el-option
                            v-for="item in ehsForm.ehs_typess"
                            :key="item.FID"
                            :label="item.FNAME"
                            :value="item.FID">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="问题等级" prop="grade">
                    <el-select 
                        v-model="ehsForm.grade" 
                        placeholder="请选择问题等级" 
                        size="mini" 
                        style="width: 100%">
                        <el-option
                            v-for="item in ehsForm.grades"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogTableVisible2 = false">取消</el-button>
                <el-button size="mini" type="primary" @click="UpdateEHS('通过', dialog_item2)">确定</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="dialogTableVisible3" size="mini" width="90%">
            <el-form :model="handleForm" ref="handleForm" label-width="80px" size="mini">
                <!-- <el-form-item label="整改工号" prop="pnl">
                    <el-input v-model="pnl" placeholder="请输入员工工号" size="mini" @blur="getPersonByPnl"></el-input>
                </el-form-item>
                <el-form-item label="整改姓名" prop="pname">
                    <el-input v-model="handleForm.pname" size="mini" :disabled="true"></el-input>
                </el-form-item> -->
                <el-form-item label="整改日期" prop="date">
                    <el-date-picker
                        v-model="handleForm.date"
                        size="mini"
                        type="date"
                        align="center"
                        style="width: 100%"
                        placeholder="选择整改日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="整改描述" prop="desc">
                    <el-input
                        type="textarea"
                        :rows="3"
                        placeholder="请输入整改描述"
                        v-model="handleForm.desc">
                    </el-input>
                </el-form-item>
                <el-form-item label="整改图片" prop="image1">
                    <el-upload 
                        action='' 
                        ref="upload"
                        :on-change="getFile" 
                        :limit="3" 
                        list-type="picture"
                        :on-remove="handleRemove"
                        :auto-upload="false" 
                        :on-exceed="handleExceed"
                        style="width: 100%;" >
                        <el-button size="mini" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogTableVisible3 = false">取消</el-button>
                <el-button size="mini" type="primary" @click="SaveReply(dialog_item3)">确定</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="dialogTableVisible4" size="mini" width="90%" :show-close="false">
            <el-empty v-if="reply_list.length === 0" description="没有数据"></el-empty>
            <el-card v-for="item in reply_list" :key="item.DB_ID" shadow="always" v-loading="loading2" class="box-card">
                <el-row>
                    <el-col :span="10" style="text-align: left;font-size: 12px">整改人：{{item.FPNAME}}</el-col>
                    <el-col :span="14" style="text-align: right;font-size: 12px">要求完成日期：{{item.FDATE}}</el-col>
                </el-row>
                <el-row class="card-row">
                    <el-col :span="24" style="text-align: left;font-size: 13px">描述：{{item.FDESC}}</el-col>
                </el-row>
                <el-row class="card-row" v-if="item.FUNPASSDATE">
                    <el-col :span="24" style="text-align: left;font-size: 12px;font-weight: bold;color: #F56C6C">驳回日期：{{item.FUNPASSDATE}}</el-col>
                </el-row>
                <el-row class="card-row" v-if="item.FUNPASSDATE">
                    <el-col :span="24" style="text-align: left;font-size: 12px;font-weight: bold;color: #F56C6C">驳回原因：{{item.FUNPASSREASON}}</el-col>
                </el-row>
                <el-row class="card-row">
                    <el-col>
                        <el-image
                            lazy
                            style="width: 70px; height: 70px"
                            :src="item.IMAGELIST[0]"
                            fit="fill"
                            :preview-src-list="item.IMAGELIST"
                            :initial-index="1">
                        </el-image>
                    </el-col>
                </el-row >
               <el-row :gutter="20" v-if="!item.FUNPASSDATE && isManager && item.FSTATE === 2" class="card-row">
                    <el-col :span="24">
                        <el-button 
                            size="mini" 
                            style="width: 100%" 
                            type="danger" 
                            @click="HandleUnPass(item)">整改驳回</el-button>
                    </el-col>
                </el-row>
            </el-card>
        </el-dialog>
        <el-dialog :visible.sync="dialogTableVisible5" size="mini" width="90%">
            <el-form :model="serviceForm" size="mini" label-width="70px">
                <el-form-item label="考核评分">
                    <el-input-number size="mini" v-model="serviceForm.num" :step="1" />
                </el-form-item>
                <el-input
                    size="mini"
                    type="textarea"
                    :rows="3"
                    placeholder="请输入整改评价"
                    maxlength="50"
                    show-word-limit
                    v-model="serviceForm.rate_text">
                </el-input>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogTableVisible5 = false">取消</el-button>
                <el-button size="mini" type="primary" @click="UpdateEHS('评分', dialog_item5)">提交</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="dialogTableVisible6" size="mini" width="80%">
            <span class="dialog-footer">
                <div style="font-weight: bold;text-align: left;font-size: 13px;margin-bottom: 10px">驳回原因</div>
                <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入驳回原因"
                    v-model="reply_reason">
                </el-input>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogTableVisible6 = false">取消</el-button>
                <el-button size="mini" type="primary" @click="UpdateEHSReply(dialog_item6)">确定</el-button>
            </span>
        </el-dialog>
        <div style="margin-bottom: 10px">
            <el-empty v-if="ehs_list.length === 0" description="没有数据"></el-empty>
            <el-card v-for="item in ehs_list" :key="item.DB_ID" shadow="always" v-loading="loading" class="box-card">
                <el-row>
                    <el-col 
                        :span="16" 
                        v-if="item.FTYPE_NM"
                        style="text-align: left;font-weight: bold;font-size: 14px">【{{item.FTYPE_NM}}】</el-col>
                    <el-col :span="4" style="text-align: right;" v-if="item.FSTATE === 3 && item.FSTATENAME !== '驳回'">
                        <el-tag
                            type="success"
                            size="mini"
                            effect="dark">
                            {{item.FSTATENAME}}
                        </el-tag>
                    </el-col>
                    <el-col :span="item.FSTATE === 3 && item.FSTATENAME !== '驳回' ? 4 : 8" style="text-align: right;">
                        <el-tag
                            v-if="item.FGRADE"
                            type="danger"
                            size="mini"
                            effect="dark">
                            {{item.FGRADE}}
                        </el-tag>
                    </el-col>
                </el-row>
                <el-row v-if="item.FSTATENAME === '驳回'">
                    <el-col :span="20" style="text-align: left;font-weight: bold;font-size: 13px;color: #E6A23C">{{item.FUNPASSREASON}}</el-col>
                    <el-col :span="4" style="text-align: right;">
                        <el-tag
                            type="danger"
                            size="mini"
                            effect="dark">
                            {{item.FSTATENAME}}
                        </el-tag>
                    </el-col>
                </el-row>
                <el-row class="card-row">
                    <el-col :span="10" style="text-align: left;font-size: 12px">整改人：{{item.FZPNAME}}</el-col>
                    <el-col :span="14" style="text-align: right;font-size: 12px">要求完成日期：{{item.FZDATE}}</el-col>
                </el-row>
                <el-row class="card-row">
                    <el-col :span="24" style="text-align: left;font-size: 13px">描述：{{item.FDESC}}</el-col>
                </el-row>
                <el-row class="card-row">
                    <el-col :span="12" style="text-align: left;font-size: 12px">地址：{{item.FADDR}}</el-col>
                    <el-col :span="12" style="text-align: right;font-size: 12px">提报日期：{{item.FDATE}}</el-col>
                </el-row>
                <el-row class="card-row" v-if="item.FSTATENAME === '已完成'" style="color: #67C23A;font-weight: bold">
                    <el-col :span="4" style="text-align: left;font-size: 12px">得分：</el-col>
                    <el-col :span="20" style="text-align: left;font-size: 12px" v-if="item.FPLUS">
                        <el-rate
                            v-model="item.FPLUS"
                            disabled
                            show-score
                            text-color="#ff9900"
                            score-template="{value}">
                        </el-rate>
                    </el-col>
                    <el-col :span="20" style="text-align: left;font-size: 12px" v-if="item.FMINUS">
                        <el-rate
                            v-model="item.FMINUS"
                            disabled
                            show-score
                            text-color="#ff9900"
                            score-template="{value}">
                        </el-rate>
                    </el-col>
                </el-row>
                <el-row class="card-row" v-if="item.FSTATENAME === '已完成'" style="color: #67C23A;font-weight: bold">
                    <el-col :span="24" style="text-align: left;font-size: 12px">评价：{{item.FASSESS}}</el-col>
                </el-row>
                <el-row class="card-row">
                    <el-col>
                        <el-image
                            lazy
                            style="width: 70px; height: 70px"
                            :src="item.IMAGELIST[0]"
                            fit="fill"
                            :preview-src-list="item.IMAGELIST"
                            :initial-index="1">
                        </el-image>
                    </el-col>
                </el-row >
                <el-row :gutter="20" v-if="isManager && item.FSTATE === 1" class="card-row">
                    <el-col :span="12">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="success" 
                            @click="Pass(item)">通过</el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="danger" 
                            @click="UnPass(item)">驳回</el-button>
                    </el-col>
                </el-row>
                <el-row :gutter="20" v-if="item.FZPNL === pnl && item.FSTATE === 2" class="card-row">
                    <el-col :span="24">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="danger" 
                            @click="Handle(item)">整改问题</el-button>
                    </el-col>
                </el-row>
                <el-row :gutter="20" v-if="item.FSTATE === 2 && isManager && item.FISREPLY" class="card-row">
                    <el-col :span="12">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="success" 
                            @click="Audit(item)">整改审核</el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="warning" 
                            @click="Reply(item)">查询整改</el-button>
                    </el-col>
                </el-row>
                <el-row :gutter="20" v-if="(item.FPNL === pnl || item.FZPNL === pnl) && !isManager && item.FISREPLY && item.FSTATENAME !== '驳回'" class="card-row">
                    <el-col :span="24">
                        <el-button 
                            size="small" 
                            style="width: 100%" 
                            type="warning" 
                            @click="Reply(item)">查询整改</el-button>
                    </el-col>
                </el-row>
            </el-card>
        </div>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../conf'
import moment from 'moment'
import { compressImage } from '../../plugin/compress.js'
export default {
    data() {
        return {
            ehs_list: [],
            TagDatas: [
                {badge_value: '0', tag_type: 'danger', tag_effect: false, label: '待处理', type: 1},
                {badge_value: '0', tag_type: 'warning', tag_effect: true, label: '进行中', type: 2},
                {badge_value: '0', tag_type: 'success', tag_effect: true, label: '已完成', type: 3}
            ],
            pnl: '',
            isManager: false,
            // data_count_loading: false,
            loading: false,
            loading2: false,
            dialogTableVisible: false,
            dialogTableVisible2: false,
            dialogTableVisible3: false,
            dialogTableVisible4: false,
            dialogTableVisible5: false,
            dialogTableVisible6: false,
            reason: '',
            reply_reason: '',
            dialog_item: {},
            dialog_item2: {},
            dialog_item3: {},
            dialog_item4: {},
            dialog_item5: {},
            dialog_item6: {},
            ehsForm: {
                type:'',
                types:'',
                typess:'',
                typess_name: '',
                ehs_total_type: {},
                ehs_type: [],
                ehs_types: [],
                ehs_typess: [],
                grade: '',
                grades: [{
                    label: '高',
                    value: '高'
                },{
                    label: '中',
                    value: '中'
                },{
                    label: '低',
                    value: '低'
                }]
            },
            handleForm: {
                pname: '',
                date: moment().format('YYYY-MM-DD'),
                desc: '',
                image1: null,
                fileList: [],
                imageList: [],
            },
            reply_list: [],
            serviceForm: {
                num: '',
                rate_text: ''
            },
            rules: {
                type: [
                    {required: true, message: '请选择问题类别', trigger: 'change'}
                ],
                types: [
                    {required: true, message: '请选择二级类别', trigger: 'change'}
                ],
                typess: [
                    {required: true, message: '请选择三级类别', trigger: 'change'}
                ],
                grade: [
                    {required: true, message: '请选择三级类别', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        tag_click(item) {
            this.TagDatas.forEach(el => {
                if(item.tag_type === el.tag_type) {
                    el.tag_effect = false
                    this.getEHSData(el.type)
                } else {
                    el.tag_effect = true
                }
            })
            this.GetDataCounts()
        },
        Pass(item) {
            this.dialog_item2 = item
            this.dialogTableVisible2 = true
            this.getEHSType()
        },
        UnPass(item) {
            this.dialog_item = item
            this.dialogTableVisible = true
        },
        Handle(item) {
            this.dialog_item3 = item
            this.dialogTableVisible3 = true
        },
        HandleUnPass(item) {
            this.dialog_item6 = item
            // console.log('this.dialog_item6', this.dialog_item6)
            this.dialogTableVisible6 = true
        },
        async UpdateEHS(state, item) {
            // console.log('item', item)
            if(state === '通过') {
                item.FSTATE = 2
                item.FSTATENAME = '进行中'
                item.FTYPE_NO = this.ehsForm.typess,
                item.FTYPE_NM = this.ehsForm.typess_name
                item.FGRADE = this.ehsForm.grade
                item.FCHECKER = this.pnl
                item.FCHECKDATE = moment().format('YYYY-MM-DD HH:mm:ss')
            }else if(state === '驳回') {
                item.FSTATE = 3
                item.FSTATENAME = '驳回'
                item.FUNPASSDATE = moment().format('YYYY-MM-DD HH:mm:ss')
                item.FUNPASSREASON = this.reason
            }else if(state === '评分') {
                item.FSTATE = 3
                item.FSTATENAME = '已完成'
                item.FASSESS = this.serviceForm.rate_text
                if(this.serviceForm.num > 0) {
                    item.FPLUS = this.serviceForm.num
                } else {
                    item.FMINUS = this.serviceForm.num
                }
            }
            
            // console.log('item', item)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EHS/UpdateEHS', qs.stringify({
                wxHrEhsDTO: item
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$message({
                    message: '单据' + item.FSTATENAME + res.data.Message,
                    center: true,
                    type: 'success'
                })

                this.GetDataCounts()
                this.getEHSData(2)
            } else {
                this.$message({
                    message: '单据' + item.FSTATENAME + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }

            this.dialogTableVisible = false
            this.dialogTableVisible2 = false
            this.dialogTableVisible5 = false
        },
        async UpdateEHSReply(item) {
            console.log('item', item)
            item.FSTATE = 2
            item.FSTATENAME = '已驳回'
            item.FUNPASSDATE = moment().format('YYYY-MM-DD HH:mm:ss')
            item.FUNPASSREASON = this.reply_reason
             console.log('item', item)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EHS/UpdateEHSReply', qs.stringify({
                wxHrEhsReplyDTO: item
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$message({
                    message: '单据' + item.FSTATENAME + res.data.Message,
                    center: true,
                    type: 'success'
                })

                this.getEHSData(2)
            } else {
                this.$message({
                    message: '单据' + item.FSTATENAME + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }

            this.dialogTableVisible6 = false
            this.dialogTableVisible4 = false
        },
        async SaveReply(item) {
            let WxHrEhsReplyDTO = {
                FPID: item.DB_ID,
                FPNL: this.pnl,
                FPNAME: this.handleForm.pname,
                FDATE: this.handleForm.date,
                FDESC: this.handleForm.desc
            }
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EHS/SaveReply', qs.stringify({
                WxHrEhsReplyDTO,
                IMAGE1: encodeURIComponent(this.handleForm.imageList[0]),
                IMAGE2: encodeURIComponent(this.handleForm.imageList[1]),
                IMAGE3: encodeURIComponent(this.handleForm.imageList[2]),
                FILENAME1: this.handleForm.fileList[0],
                FILENAME2: this.handleForm.fileList[1],
                FILENAME3: this.handleForm.fileList[2],
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$message({
                    message: '单据' + item.FSTATENAME + res.data.Message,
                    center: true,
                    type: 'success'
                })

                this.GetDataCounts()
                this.getEHSData(2)
            } else {
                this.$message({
                    message: '单据' + item.FSTATENAME + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }

            this.dialogTableVisible3 = false
        },
        getFile(file, fileList) {
            console.log('file', file)
            console.log('fileList', fileList)
            this.handleForm.fileList = []
            this.handleForm.imageList = []
            fileList.forEach(item => {
                this.handleForm.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.handleForm.imageList.push(result)
                })
            })
        },
        handleRemove(file, fileList) {
            console.log('file', file)
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.handleForm.fileList = []
            this.handleForm.imageList = []
            fileList.forEach(item => {
                this.handleForm.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.handleForm.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 5
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 5MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        },
        Reply(item) {
            this.dialog_item4 = item
            this.dialogTableVisible4 = true
            this.GetReply(item.DB_ID)
        },
        Audit(item) {
            this.dialog_item5 = item
            this.dialogTableVisible5 = true
        },
        typeChange(id) {
            this.ehsForm.types = ''
            this.ehsForm.ehs_types = []
            this.ehsForm.ehs_types = this.ehs_total_type.Table2.filter(item => item.FPID === id)
            this.ehsForm.types = this.ehsForm.ehs_types[0].FNAME
        },
        typesChange(id) {
            this.ehsForm.typess = ''
            this.ehsForm.ehs_typess = []
            this.ehsForm.ehs_typess = this.ehs_total_type.Table3.filter(item => item.FPID === id)
            this.ehsForm.typess = this.ehsForm.ehs_typess[0].FNAME
            this.ehsForm.typess_name = this.ehsForm.ehs_typess[0].FNAME
        },
        typessChange(id) {
            this.ehsForm.typess_name = this.ehs_total_type.Table3.filter(item => item.FID === id)[0].FNAME
        },
        async GetReply(FPID) {
            this.loading2 = true
            this.reply_list = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EHS/GetReplyByID', qs.stringify({
                FPID
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.forEach(item => {
                    item.FDATE = moment(item.FDATE).format('YYYY-MM-DD')
                    item.IMAGELIST = []
                    if(item.PATH1) {
                        item.IMAGELIST.push(
                            conf.image_domain + item.PATH1
                        )
                    }
                    if(item.PATH2) {
                        item.IMAGELIST.push(
                            conf.image_domain + item.PATH2
                        )
                    }
                    if(item.PATH3) {
                        item.IMAGELIST.push(
                            conf.image_domain + item.PATH3
                        )
                    }
                })
                this.reply_list = res.data.ResponseData
            }else {
                this.$message({
                    message: '获取EHS数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.loading2 = false
        },
        async GetAdmin() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EHS/EHSAdmins')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                res.data.ResponseData.map(item => {
                    if(this.pnl === item.FPNL) {
                        this.isManager = true
                    }
                })
            }

            this.GetDataCounts()
            this.getEHSData(1)
        },
        async GetDataCounts() {
            // this.data_count_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EHS/EHSListCounts', qs.stringify({
                pnl: this.isManager ? '' : this.pnl,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                res.data.ResponseData.map(item => {
                    this.TagDatas.forEach(el => {
                        if(el.type === item.FSTATE) {
                            el.badge_value = item.FCOUNT === 0 ? null : item.FCOUNT
                        }
                    })
                })
            }else {
                this.$message({
                    message: '获取EHS数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            // this.data_count_loading = false
        },
        async getEHSData(state) {
            this.loading = true
            this.ehs_list = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EHS/EHSListNew', qs.stringify({
                pnl: this.isManager ? '' : this.pnl,
                state: state
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                res.data.ResponseData.forEach(async item => {
                    item.FDATE = moment(item.FDATE).format('YYYY-MM-DD')
                    item.FZDATE = moment(item.FZDATE).format('YYYY-MM-DD')
                    item.IMAGELIST = []
                    if(item.PATH1) {
                        item.IMAGELIST.push(
                            conf.image_domain + item.PATH1
                        )
                    }
                    if(item.PATH2) {
                        item.IMAGELIST.push(
                            conf.image_domain + item.PATH2
                        )
                    }
                    if(item.PATH3) {
                        item.IMAGELIST.push(
                            conf.image_domain + item.PATH3
                        )
                    }
                })
                
                this.ehs_list = res.data.ResponseData
            }else {
                this.$message({
                    message: '获取EHS数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.loading = false
        },
        async getEHSType() {
            this.ehs_total_type = {}
            this.ehsForm.ehs_type = []
            this.ehsForm.ehs_types = []
            this.ehsForm.ehs_typess = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/EHS/EHSTypes')
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.ehs_total_type = res.data.ResponseData
                this.ehsForm.ehs_type = res.data.ResponseData.Table1
                this.ehsForm.ehs_types = res.data.ResponseData.Table2
                this.ehsForm.ehs_typess = res.data.ResponseData.Table3
            }
        },
        async getPersonByPnl() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/SportsClock/PersonByPnl', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                this.handleForm.pname = res.data.ResponseData[0].pname
            }
        },
    },
    updated() {
        if(window.location.hash) {
            document.querySelector(window.location.hash).scrollIntoView(true)
        }
    },
    mounted() {
        this.GetAdmin()
        this.getPersonByPnl()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
            // this.pnl = '50283' // dev
            // this.pnl = '0332' // dev
            // this.pnl = '58250' // dev
            // this.pnl = '0596' // dev
            // this.pnl = '55129' // dev
        }
    },
}
</script>

<style scoped>
    .card-row {
        margin-top: 25px;
    }
</style>