<template>
    <div>
        <el-alert
            type="warning"
            show-icon>
            <template slot='title'>
                <div class="iconSize" style="text-align: left;">注意：由于数据采集周期限制，考勤结果可能有一天到两天的延时，最终结果以人力资源部发布为准。</div>
            </template>
        </el-alert>
        <el-form :model="attForm" :rules="rules" ref="attForm" label-width="80px">
            <el-date-picker
                v-model="attForm.start"
                size="medium"
                type="month"
                @change="dateChange"
                align="center"
                style="width: 100%;"
                placeholder="请选择考勤月份">
            </el-date-picker>
        </el-form>
        <el-alert
            style="margin-top: 20px"
            type="success"
            :closable="false"
            v-if="isShow"
            show-icon>
            <template slot='title'>
                <div class="iconSize">您本月考勤无异常</div>
            </template>
        </el-alert>
        <el-alert
            style="margin-top: 20px"
            type="success"
            :closable="false"
            v-if="isqq"
            show-icon>
            <template slot='title'>
                <div class="iconSize">您本月全勤</div>
            </template>
        </el-alert>
        <el-table :data="tableData" stripe size="medium" :show-header="false" style="margin-top: 20px" v-loading="loading" >
            <el-table-column prop="label" width="120px">
            </el-table-column>
            <el-table-column prop="value" align="right">
            </el-table-column>
        </el-table>
        <el-row style="margin-top: 20px">
            <el-col :span="24">
                <el-button style="width: 100%" :type="this.msgType" @click="AttDetail">考勤明细</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import moment from 'moment'
export default {
    data() {
        return {
            attForm: {
                start: moment().format('YYYY-MM-01'),
            },
            tableData: [],
            pnl: '',
            isShow: false,
            isqq: false,
            msgType: 'success',
            loading: false,
            rules: {
                start: [
                    {required: true, message: '请选择开始日期', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        AttDetail() {
            window.location.href = '/attendanceDetail/' + moment(this.attForm.start).format('YYYY-MM-DD')
        },
        dateChange() {
            this.getAtendance()
            this.getIsqq()
        },
        async getAtendance() {
            this.loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Attendance/StageAttendance', qs.stringify({
                start: this.attForm.start,
                end: moment(this.attForm.start).endOf('month').format("YYYY-MM-DD"),
                pnl: this.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.tableData = []
                this.isShow = false
                res.data.ResponseData.forEach(item => {
                    this.tableData.push({
                        label: '正常出勤',
                        value: item.w_day + ' 天'
                    })
                    this.tableData.push({
                        label: '旷工',
                        value: item.k_day + ' 天'
                    })
                    this.tableData.push({
                        label: '迟到',
                        value: item.f_time + ' 分钟'
                    })
                    this.tableData.push({
                        label: '早退',
                        value: item.b_time + ' 分钟'
                    })
                    this.tableData.push({
                        label: '请假',
                        value: item.h_day + ' 天'
                    })
                    this.tableData.push({
                        label: '请假',
                        // value: item.h_time + ' 分钟' // dev
                        value: item.h_time / 60 + ' 小时'
                    })
                    this.tableData.push({
                        label: '正常加班',
                        value: item.jbqq + ' 天'
                    })
                    this.tableData.push({
                        label: '休息加班',
                        value: item.jb_day + ' 天'
                    })

                    this.isShow = item.yc_num > 0 ? false : true
                    this.msgType = this.isShow ? 'success' : 'danger'
                })
            }
            this.loading = false
        },
        async getIsqq() {
            const res  = await axios.post(conf.api_url + '/gateway/weixinapi/Attendance/GetIsqq', qs.stringify({
                yymm: moment(this.attForm.start).format('YYYY-MM'),
                pnl: this.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.isqq = false
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.isqq = res.data.ResponseData[0].isqq === 'Y'  ? true : false
                }
            } 
        }
    },
    mounted() {
        this.dateChange()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
            // this.pnl = '51019' // dev
            // this.pnl = '48715' // dev
            // this.pnl = '35005' // dev
        }
    }
}
</script>