<template>
    <div>
        <el-table 
            :data="tableData" 
            :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center'}" 
            size="mini" 
            lazy
            v-loading="table_loading" 
            style="font-size: 11px;"
            border>
            <el-table-column prop="fname" label="项目" min-width="38px" align="center">
            </el-table-column>
            <el-table-column label="标准温度时间" min-width="88px" align="center">
                <template slot-scope="scope">
                    <el-select 
                        size="mini"
                        v-model="scope.row.ffname" 
                        placeholder="请选择" 
                        style="width: 100%">
                        <el-option
                            v-for="item in scope.row.fnames"
                            :key="item.fid"
                            size="mini"
                            :label="item.fname"
                            :value="item.fname">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="实测记录" min-width="98px" align="center">
                <el-table-column label="7:30-8:30" min-width="46px" align="center">
                    <template slot-scope="scope">
                        <el-input size="mini" v-model="scope.row.ffwd1"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="13:30-14:30" min-width="54px" align="center">
                    <template slot-scope="scope">
                        <el-input style="width: 100%" size="mini" v-model="scope.row.ffwd2"></el-input>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
        <el-row style="margin-top: 20px;margin-bottom: 20px">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    :loading="isLoading"
                    @click="SubmitSaveValid">提交</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
export default {
    data() {
        return {
            measureRecordForm: {
                sec_no: '',
                odr_no: '',
                fid: '',
                pnl: ''
            },
            tableData: [],
            table_loading: false,
            table3Data: [],
            isLoading: false
        }
    },
    methods: {
        async GetCheckListDatas4() {
            this.table_loading = true
            this.tableData = []
            this.table3Data = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/CheckListDatas4', qs.stringify({
                id: this.measureRecordForm.fid
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.Table2.length > 0) {
                    this.tableData = res.data.ResponseData.Table2
                }
                if(res.data.ResponseData.Table3.length > 0) {
                    this.table3Data = res.data.ResponseData.Table3
                }

                this.tableData.forEach(item => {
                    item.fnames = this.table3Data.filter(el => el.fpid === item.fid1)
                })

                console.log('this.tableData', this.tableData)
            }
            this.table_loading = false
        },
        SubmitSaveValid() {
            let fnameValid = true
            let wdValid = true
            this.tableData.forEach(item => {
                if(!item.ffname && fnameValid) {
                    fnameValid = false
                    this.$message({
                        message: '项目不能为空！',
                        center: true,
                        type: 'error',
                    })
                    return fnameValid
                }
                if((!item.ffwd1 || !item.ffwd2) && wdValid && fnameValid) {
                    wdValid = false
                    this.$message({
                        message: '温度不能为空！',
                        center: true,
                        type: 'error',
                    })
                    return wdValid
                }
            })
            if(fnameValid && wdValid) {
                this.SaveMeasureRecord()
            }
        },
        async SaveMeasureRecord() {
            console.log('this.tableData', this.tableData)
            this.isLoading = true
            let dtos = []
            this.tableData.map(item => {
                let AleCheckresultDTO = {
                    CHECKFLOWID: '',
                    CHECKFLOWSID: '',
                    CHECKFLOWSSID: '',
                    CHECKVALUE: '',
                    CHECKRESULT: '',
                    SEC_NO: '',
                    ODR_NO: '',
                    PNL: ''
                }
                if(item.ffwd1) {
                    AleCheckresultDTO = {
                        CHECKFLOWID: this.measureRecordForm.fid,
                        CHECKFLOWSID: item.fpid1,
                        CHECKFLOWSSID: item.fid1,
                        CHECKVALUE: item.ffwd1,
                        CHECKRESULT: item.fname1,
                        SEC_NO: this.measureRecordForm.sec_no,
                        ODR_NO: this.measureRecordForm.odr_no,
                        PNL: this.measureRecordForm.pnl
                    }
                    dtos.push(AleCheckresultDTO)
                }
                if(item.ffwd2) {
                    AleCheckresultDTO = {
                        CHECKFLOWID: this.measureRecordForm.fid,
                        CHECKFLOWSID: item.fpid2,
                        CHECKFLOWSSID: item.fid2,
                        CHECKVALUE: item.ffwd2,
                        CHECKRESULT: item.fname2,
                        SEC_NO: this.measureRecordForm.sec_no,
                        ODR_NO: this.measureRecordForm.odr_no,
                        PNL: this.measureRecordForm.pnl
                    }
                    dtos.push(AleCheckresultDTO)
                }
            })
            console.log('dtos', dtos)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/CheckList/SaveCheckListData', qs.stringify({
                dtos
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: '保存成功！',
                    center: true,
                    type: 'success',
                    duration: 3000
                })
                window.location.href = '/checkList'
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
    },
    mounted() {
        const id = this.$route.params.id
        if(id) {
            this.measureRecordForm.fid = id
            this.GetCheckListDatas4()
        }
        const sec_no = this.$route.params.sec
        if(sec_no) {
            this.measureRecordForm.sec_no = sec_no
        }
        const odr_no = this.$route.params.odr
        if(odr_no) {
            this.measureRecordForm.odr_no = decodeURIComponent(odr_no)
        }
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.measureRecordForm.pnl = pnl
        }
    }
}
</script>