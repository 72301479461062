<template>
    <div>
        <el-table :data="cutNoData" size="mini" :show-header="false" style="margin-bottom: 20px">
            <el-table-column prop="label" label="单号" width="80px">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold">{{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
                <template slot-scope="scope">
                    <div style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.value}}</div>
                </template>
            </el-table-column>
            <el-table-column align="right" width="90px">
                <el-button 
                    size="small" 
                    type="primary" 
                    icon="el-icon-full-screen" 
                    style="width: 100%;"
                    @click="GetJSAPI">扫码</el-button>
            </el-table-column>
        </el-table>
        <el-table :data="tableData" size="mini" border style="margin-bottom: 20px">
            <el-table-column prop="part_nm" label="部件名称">
                <template slot-scope="scope">
                    <div>{{scope.row.part_nm}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="osize" label="指令SIZE">
                <template slot-scope="scope">
                    <div>{{scope.row.osize}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="qty" label="数量">
                <template slot-scope="scope">
                    <div>{{scope.row.qty}}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-row style="margin-top: 20px;">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    size="medium"
                    :loading="isLoading"
                    :disabled="tableData.length <= 0"
                    @click="SavePreDispatch">部件接收</el-button>
            </el-col>
        </el-row>
        <!-- <el-collapse v-model="activeNames" style="margin-top: 30px;">
            <el-collapse-item name="1">
                <template slot="title">
                    <div style="font-weight: bold">上传记录</div>
                </template>
                <el-table :data="preDispatchData" size="mini" style="width: 100%">
                    <el-table-column prop="csend_no" label="派工单号" width="130px">
                    </el-table-column>
                    <el-table-column prop="odr_no" label="指令单号">
                    </el-table-column>
                    <el-table-column prop="qty" label="总数量" width="70px">
                    </el-table-column>
                </el-table>
            </el-collapse-item>
        </el-collapse> -->
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import moment from 'moment'
import qs from 'qs'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            cutNoData: [{
                label: '单号',
                value: ''
            }],
            tableData: [],
            isLoading: false,
            // activeNames: ['0'],
            // preDispatchData: []
        }
    },
    methods: {
        GetJSAPI() {
            // 测试数据
            // let result = "QAS/2109032-080;01;BW-B101;鞋头里;6.5;15;HPA20211113442" // dev
            // let result = "QAS/2103012-015;01;BW-A031-31;鞋身3外;6.5;62;HPA20210418410" // dev
            // this.setTableValue(result) // dev
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                    var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    this.setTableValue(result)
                }
            })
        },
        setTableValue(code_str) {
            this.code_values = code_str.split(';')
            console.log(this.code_values)
            this.cutNoData[0].value = this.code_values[6]
            this.tableData.push({
                odr_no: this.code_values[0],
                sch_no: this.code_values[1],
                part_no: this.code_values[2],
                part_nm: this.code_values[3],
                osize: this.code_values[4],
                qty: this.code_values[5],
                csend_no: this.code_values[6]
            })
          
            console.log('this.tableData', this.tableData)
        },
        async SavePreDispatch() {
            let dtos = []
            let PerDispatchDTO = {}
            this.tableData.forEach(item => {
                console.log(item)
                PerDispatchDTO = {
                    ODR_NO: item.odr_no,
                    SCH_NO: item.sch_no,
                    PART_NO: item.part_no,
                    OSIZE: item.osize,
                    CSEND_NO: item.csend_no,
                    QTY: item.qty,
                    PNL2: this.pnl,
                    DATE2: moment().format('YYYY-MM-DD')
                }

                dtos.push(PerDispatchDTO)
            })
            console.log('dtos', dtos)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PerDispatch/SavePerDispatch2', qs.stringify({
              dtos
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: '派单提交结果：' + res.data.ResponseData,
                    center: true,
                    type: 'success'
                })

                this.tableData = []
                this.cutNoData[0].value = ''
            }else if(res.data.Code === 1) {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }else if (res.data.Code === 1 && res.status === 200 && res.statusText === 'OK') {
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        this.GetSignature() // prod
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
        }
    }
}
</script>