<template>
    <div>
        <!-- <el-form :model="proInvForm" ref="proInvForm" size="small"> -->
            <el-row :gutter="10">
                <el-col :span="12">
                    <!-- <el-form-item label="" prop="vday" style="font-weight: bold;"> -->
                        <el-date-picker
                            v-model="proInvForm.day_odate"
                            style="width: 100%;"
                            size="small"
                            type="date"
                            align="center"
                            value-format="yyyy-MM-dd"
                            @change="DateChange"
                            placeholder="确认出货日期">
                        </el-date-picker>
                    <!-- </el-form-item> -->
                </el-col>
                <el-col :span="12">
                    <el-input size="small" v-model="proInvForm.keyword" placeholder="输入关键字搜索">
                        <!-- <el-button size="small" slot="append" style="color: #409EFF;" icon="el-icon-search" @click="SubmitForm('wipForm')"></el-button> -->
                    </el-input>
                </el-col>
                <!-- <el-col :span="6">
                    <el-button 
                        size="small" 
                        type="danger" 
                        icon="el-icon-refresh" 
                        style="width: 100%;"
                        @click="SubmitForm('proInvForm')">刷新</el-button>
                </el-col> -->
            </el-row>
        <!-- </el-form> -->
        <el-table 
            max-height="620px"
            ref="queryForm"
            :data="tableData.filter(data => !proInvForm.keyword 
            || data.odr_no.toLowerCase().includes(proInvForm.keyword.toLowerCase())
            || data.artic_no1.toLowerCase().includes(proInvForm.keyword.toLowerCase()))"
            stripe
            
            style="margin-top: 25px"
            v-loading="table_loading" 
            :header-cell-style="{background: '#66B1FF', color: 'white', textAlign: 'center', padding: 0, height: '32px'}" 
            :cell-style="() => { return 'padding: 0px;margin: 0px;height: 30px;font-size: 11px'}"
            size="mini" 
            border>
            <el-table-column prop="odr_no" fixed sortable label="指令单号"  min-width="80px" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.odr_no.substring(0, scope.row.odr_no.length - 8) }}</div>
                    <div>{{ scope.row.odr_no.substring(scope.row.odr_no.length - 8, scope.row.odr_no.length) }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="artic_no1" sortable label="客户型号" min-width="74px" align="center">
            </el-table-column>
            <!-- <el-table-column prop="UEPOS" label="行项目" min-width="42px" align="center">
            </el-table-column> -->
            <el-table-column prop="UEPOS" label="行项目" min-width="48px" align="center">
            </el-table-column>
            <el-table-column prop="CK" label="物流仓库" sortable min-width="82px" align="center">
            </el-table-column>
            <el-table-column prop="sec_nm" label="入库部门" sortable min-width="68px" align="center">
            </el-table-column>
            <el-table-column prop="atqty" label="正批总数" min-width="58px" align="center">
            </el-table-column>
            <el-table-column prop="day_oqty" label="确认出库" sortable min-width="58px" align="center">
            </el-table-column>
            <el-table-column prop="toqty" label="今日出库" sortable min-width="58px" align="center">
            </el-table-column>
            <el-table-column prop="diffoqty" label="出货差异" sortable min-width="58px" align="center">
            </el-table-column>
            <el-table-column prop="sqty" label="库存" sortable min-width="58px" align="center">
            </el-table-column>
            <el-table-column prop="i_dqty" label="入库欠数" sortable min-width="58px" align="center">
            </el-table-column>
        </el-table>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
export default {
    data() {
        return {
            tableData: [],
            table_loading: false,
            proInvForm: {
                day_odate: '',
                keyword: ''
            },
            // tableHeight: 600
        }
    },
    methods: {
        // getHeight() {
        //     this.$nextTick(() => {
        //         // window.innerHeight 浏览器窗口的可见高度，下面的 220 是除了table最大高度的剩余空间。
        //         console.log('window.innerHeight', window.innerHeight)
        //         console.log('this.$refs.queryForm.$el.offsetHeight', this.$refs.queryForm.$el.offsetHeight)
        //         let height = window.innerHeight - this.$refs.queryForm.$el.offsetHeight - 220
        //         this.tableHeight = height
        //     })
        // },
        DateChange() {
            this.GetProexpStoodr()
        },
        async GetProexpStoodr() {
            this.tableData = []
            this.table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/ProexpStoodr/GetProexpStoodr', qs.stringify({
                day_odate: this.proInvForm.day_odate
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // this.tableData = res.data.ResponseData
                var total_day_oqty = 0 
                var total_toqty = 0
                var total_diffoqty = 0
                var total_sqty = 0
                var total_i_dqty = 0
                res.data.ResponseData.forEach(item => {
                    // var odr_no1 = item.odr_no.substring(0, item.odr_no.length - 8)
                    // var odr_no2 = item.odr_no.substring(item.odr_no.length - 8, item.odr_no.length)
                    item.sec_nm = this.SecFormat1(this.SecFormat2(item.sec_nm))
                    this.tableData.push(item)
                    // 合计列
                    total_day_oqty += item.day_oqty
                    total_toqty += item.toqty
                    total_diffoqty += item.diffoqty
                    total_sqty += item.sqty
                    total_i_dqty += item.i_dqty
                })

                this.tableData.push({
                    odr_no: '合计',
                    day_oqty: total_day_oqty,
                    toqty: total_toqty,
                    diffoqty: total_diffoqty,
                    sqty: total_sqty,
                    i_dqty: total_i_dqty,
                    artic_no1: ''
                })
            }
            this.table_loading = false
        },
        SecFormat1(sec_nm) {
            if(sec_nm.indexOf('成型') === -1) {
                return sec_nm
            }
            else {
                sec_nm = sec_nm.replace('成型', '')
                return this.SecFormat1(sec_nm)
            }
        },
        SecFormat2(sec_nm) {
            if(sec_nm.indexOf('(') === -1) {
                return sec_nm
            }
            else {
                let index1 = sec_nm.indexOf('(')
                let index2 = sec_nm.indexOf(')')
                let sec_nm1 = sec_nm.substring(0, index1)
                let sec_nm2 = sec_nm.substring(index2 + 1, sec_nm.length)
                return this.SecFormat2(sec_nm1 + sec_nm2)
            }
        }
    },
    mounted() {
        this.GetProexpStoodr()
    },
    created() {
        // window.addEventListener('resize', this.getHeight)
    }
}
</script>