<template>
    <div>
        <el-table :data="tableData" size="small" :show-header="false">
            <el-table-column prop="label" width="90px">
            </el-table-column>
            <el-table-column prop="value">
            </el-table-column>
        </el-table>
        <el-row style="margin-top: 20px;">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    @click="UpdateFirstConfirm">首件确认</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import conf from '../../conf.js'
import axios from 'axios'
import qs from 'qs'
export default {
    data() {
        return {
            pnl: '',
            tableData: [],
            isLoading: false,
            id: ''
        }
    },
    methods: {
        async GetFirstConfirmDetail() {
            this.isLoading = true
            this.tableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/FirstConfirm/FirstConfirmDetail', qs.stringify({
              id: this.id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        this.tableData.push({
                            label: '日期',
                            value: item.iod
                        },{
                            label: '指令号',
                            value: item.odr_no
                        },{
                            label: '货号',
                            value: item.artic_no
                        },{
                            label: '颜色',
                            value: item.color
                        },{
                            label: '尺码',
                            value: item.osize
                        },{
                            label: '数量',
                            value: item.qty
                        },{
                            label: '备注',
                            value: item.remark
                        })
                    })
                }
            }
            this.isLoading = false
        },
        async UpdateFirstConfirm() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/FirstConfirm/UpdateFirstConfirm', qs.stringify({
              id: this.id,
              pnl: this.pnl
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                    this.$message({
                        message: '首件确认成功！',
                        center: true,
                        type: 'success',
                        duration: 3000
                    })
                    window.location.href = '/firstConfirm'
                }else if(res.data.Code === 1) {
                    this.$message({
                        message: res.data.Message,
                        center: true,
                        type: 'error'
                    })
                }
            }
        }
    },
    mounted() {
        const id = this.$route.params.id
        if(id) {
            this.id = id
            this.GetFirstConfirmDetail()
        }
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }
    }
}
</script>