<template>
    <div>
        <el-card :id="item.DB_ID" shadow="always" v-for="(item, index) in check_list" :key="index" class="box-card" v-loading="loading">
            <el-row>
                <el-col :span="6" style="text-align: left">处罚方式</el-col>
                <el-col :span="13" class="col-danger" style="text-align: right">
                    <el-tag size="mini" type="success">
                        {{item.IS_REVIEW === 'Y' ? '复查' : '初查'}}
                    </el-tag>
                </el-col>
                <el-col :span="5" class="col-danger" style="text-align: right">{{item.CHECK_RESULT}}</el-col>
            </el-row>
            <el-divider v-if="item.check_lists.length !== 0"/>
            <el-row v-if="item.check_lists.length !== 0">
                <el-col :span="6" style="text-align: left">问题描述</el-col>
                <el-col :span="18" style="text-align: right">
                    <span v-for="(items, indexs) in item.check_lists" :key="indexs">
                        <span>{{indexs + 1}}、</span><span>{{items.RESULT_NAME}}。</span>
                    </span>
                </el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="6" style="text-align: left">宿舍信息</el-col>
                <el-col :span="18" style="text-align: right">
                    房间号：{{item.ROOM_NO1}}，
                    床位号：{{item.BED_NO}}
                </el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="6" style="text-align: left">单据日期</el-col>
                <el-col :span="18" style="text-align: right">{{item.CHECK_DATE}}</el-col>
            </el-row>
            <el-divider v-if="item.HAVE_IMAGE !== 0" />
            <el-row v-if="item.HAVE_IMAGE !== 0">
                <el-col :span="6" style="text-align: left">违规图片</el-col>
                <el-col :span="18" style="text-align: right">
                    <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.DB_ID)">
                        <i class="el-icon-picture-outline"></i> 点击查看
                    </el-link>
                </el-col>
            </el-row>
        </el-card>
        <el-empty v-if="check_list.length === 0" description="没有数据"></el-empty>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            check_list: [],
            check_lists: [],
            temp_list: [],
            loading: false,
            imageList: [],
        }
    },
    methods: {
        async ShowImages(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                            path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
                // console.log('this.imageList', this.imageList)
            }
        },
        async GetCheckDetail() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/GetCheckDetail', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.temp_list = res.data.ResponseData
                this.GetCheckDetails()
            }
        },
        async GetCheckDetails() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/GetCheckDetails', qs.stringify({
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.check_lists = res.data.ResponseData
                this.temp_list.forEach(item => {
                    item.check_lists=[]
                    this.check_lists.forEach(items => {
                        if(item.DB_ID === items.FID) {
                            item.check_lists.push(items)
                        }
                    })
                })

                this.check_list = this.temp_list
                // console.log('this.check_list', this.check_list)
            }
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
    },
    updated() {
        if(window.location.hash) {
            document.querySelector(window.location.hash).scrollIntoView(true)
        }
    },
    mounted() {
        this.GetCheckDetail()
        // this.GetCheckDetails()
        this.GetSignature()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
            // this.pnl ='2756'
            // this.pnl ='61470'
            // this.pnl = '51964'
            // this.pnl = '63524'
        }
    }
}
</script>

<style>
    .box-card{
        margin-top: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 13px;
    } 
    .col-danger {
        color: #F56C6C;
        font-weight: bold;
    }
</style>