<template>
    <div>
        <el-form :model="pcsForm" :rules="rules" ref="pcsForm" label-width="80px" size="small">
            <el-form-item label="件资日期" prop="vday" style="font-weight: bold">
                <el-date-picker
                    v-model="pcsForm.vday"
                    style="width: 100%"
                    size="small"
                    type="date"
                    align="center"
                    value-format="yyyy-MM-dd"
                    placeholder="选择件资日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="制程组别" prop="sec_no" style="font-weight: bold">
                <el-row>
                    <el-col :span="10">
                        <el-select 
                            @change="MadeChange"
                            v-model="pcsForm.made_no" 
                            placeholder="请选择制程" 
                            style="width: 100%">
                            <el-option
                                v-for="item in made_nos"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="13" style="margin-left: 10px">
                        <el-cascader
                            :show-all-levels="false"
                            v-model="pcsForm.sec_no"
                            clearable
                            style="width: 100%"
                            placeholder="请选择组别"
                            :options="options">
                        </el-cascader>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="件资小于" prop="vgtag" style="font-weight: bold">
                <el-row>
                    <el-col :span="10">
                        <el-input-number 
                            size="small" 
                            :min="0"
                            :precision="0"
                            style="width: 100%;" 
                            placeholder="请输入日件资" 
                            v-model="pcsForm.vgtag">
                        </el-input-number>
                    </el-col>
                    <el-col :span="13" style="margin-left: 10px;">
                        <el-switch
                            v-model="pcsForm.datetype"
                            active-color="#ff4949"
                            inactive-color="#13ce66"
                            active-text="按月"
                            inactive-text="按天">
                        </el-switch>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-row style="margin-top: 20px">
                <el-col :span="24">
                    <el-button 
                        size="small"
                        style="width: 100%;"
                        type="primary" 
                        @click="submitForm('pcsForm')">查询</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-skeleton style="width: 100%;margin-top: 30px;text-align: left;" :rows="10" animated :loading="loading">
            <el-table :data="tableData" size="small" v-loading="table_loading" lazy >
                <el-table-column prop="name" align="left" :label="table_title" label-class-name="table_title_class">
                    <template slot-scope="scope">
                        <div style="text-align: right;">
                            
                            <span style="float: left;">{{scope.row.pname}} （ {{scope.row.pnl}} ）</span>
                            <span>{{table_avzj}}</span>
                            <span style="font-size: 13px;font-weight: bold;color: #67C23A">{{scope.row.avzj}}</span>
                        </div>
                        <!-- <div>
                            <span>{{table_avzj}}</span>
                            <span style="font-size: 13px;font-weight: bold;color: #67C23A">{{scope.row.avzj}}</span>
                        </div> -->
                        <div>
                            <span>{{table_mjz}}</span>
                            <span style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.mjz}}</span>
                            <span style="margin-left: 6px">{{table_mvjz}}</span>
                            <span style="font-size: 13px;font-weight: bold;color: #E6A23C">{{scope.row.mvjz}}</span>
                            <span style="margin-left: 6px">{{table_vday}}</span>
                            <span style="font-size: 13px;font-weight: bold;color: #409EFF">{{scope.row.vday}}</span>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="txt" align="right">
                    <template slot-scope="scope"> -->
                        <!-- <div>
                            <span>{{table_avzj}}</span>
                            <span style="font-size: 13px;font-weight: bold;color: #67C23A">{{scope.row.avzj}}</span>
                        </div>
                        <div>{{scope.row.sec_nm}}</div>
                        <div>
                            <span>{{table_mjz}}</span>
                            <span style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.jz}}</span>
                        </div>
                        <div>
                            <span>月均工资：</span>
                            <span style="font-size: 13px;font-weight: bold;">126.84</span>
                        </div>
                        <div>
                            <span>{{table_vday}}：</span>
                            <span style="font-size: 13px;font-weight: bold;">{{scope.row.vday}}</span>
                        </div> -->

                        <!-- <div>
                            <span style="text-align: right;">{{table_avzj}}</span>
                            <span style="font-size: 13px;font-weight: bold;color: #67C23A">{{scope.row.avzj}}</span>
                        </div>
                        <div>
                            <span style="margin-left: 6px">{{table_vday}}</span>
                            <span style="font-size: 13px;font-weight: bold;color: #F56C6C">{{scope.row.vday}}</span>
                        </div>    
                    </template>
                </el-table-column> -->
            </el-table>
        </el-skeleton>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import moment from 'moment'
export default {
    data() {
        return {
            // made_nos: [{ 
            //     value: '1',
            //     label: '冲裁'
            // },{ 
            //     value: '3',
            //     label: '帮面'
            // },{ 
            //     value: '6',
            //     label: '贴合'
            // },{ 
            //     value: '7',
            //     label: '成型'
            // },{ 
            //     value: 'A',
            //     label: '工艺'
            // }], 
            made_nos: [],
            pcsForm: {
                vday: new moment().format("YYYY-MM-DD"),
                vgtag: '',
                datetype: false,
                made_no: '',
                sec_no: ''
            },
            options: [],
            pnl: '',
            tableData: [],
            table_title: '员工列表',
            table_loading: false,
            loading: false,
            table_avzj: '当日薪资：￥',
            table_mjz: '月总工资：￥',
            table_mvjz: '日均工资：￥',
            table_vday: '出勤天数：',
            rules: {
                vday: [
                    {required: true, message: '请选择件资日期', trigger: 'change'}
                ],
                sec_no: [
                    {required: true, message: '请选择组别', trigger: 'change'}
                ],
                // vgtag: [
                //     {required: true, message: '请输入件资金额', trigger: 'change'}
                // ]
            }
        }
    },
    methods:{
        MadeChange(made_no) {
            this.GetSecData(made_no)
        },
        async GetSecData(made_no) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PieceCapitalStatus/GetSecData', qs.stringify({
                pnl: this.pnl,
                made_no,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.options = this.GroupBy(res.data.ResponseData, 'sec_nm1')
                console.log('options', this.options)
            }
        },
        GroupBy(datas, key, callBack) {
            const list = datas || []
            const groups = []
            list.forEach(v => {
                let key = v.sec_no1
                let group = groups.find(v => {
                    return v.value === key
                })
                if(!group) {
                    group = {
                        label: v.sec_nm1,
                        value: v.sec_no1
                    }
                    groups.push(group)
                }
                if(callBack) {
                    group.children = callBack(group.children, v)
                }else {
                    group.children = group.children || []
                    group.children.push({
                        label: v.sec_nm,
                        value: v.sec_no
                    })
                }
            })
            return groups
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.pcsForm)
                    this.loading = true
                    setTimeout(()=>{
                        this.getPieceCapitalStatus()
                        this.loading = false
                    },1000)
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async getPieceCapitalStatus() {
            this.tableData = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PieceCapitalStatus/PieceCapitalStatusSec', qs.stringify({
                vday: this.pcsForm.vday,
                sec_no: this.pcsForm.sec_no[1],
                vgtag: this.pcsForm.vgtag,
                datetype: this.pcsForm.datetype ? 1 : 0
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                res.data.ResponseData.map(item => {
                    this.tableData.push({
                        pnl: item.pnl,
                        pname: item.pname,
                        sec_nm: item.sec_nm,
                        avzj: item.avzj.toFixed(1),
                        // jz: this.pcsForm.datetype ? item.vgday + ' 天' : item.mjz.toFixed(2),
                        mjz: item.mjz.toFixed(1),
                        vday: this.pcsForm.datetype ? item.vgday : item.vday,
                        mvjz: (item.mjz / (this.pcsForm.datetype ? item.vgday : item.vday)).toFixed(1)
                    })
                })
                this.table_title = '员工列表，共 ' + this.tableData.length + ' 人'
                // console.log('this.pcsForm.datetype', this.pcsForm.datetype)
                // this.table_avzj = this.pcsForm.datetype ? '月平均：￥': '当日薪资：￥'
                // this.table_mjz = this.pcsForm.datetype ? '核算天数：' : '月总：￥'
            }
        },
        async GetMadeData() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/PieceCapitalStatus/MadeData', qs.stringify({
                pnl: this.pnl,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                // this.options = this.GroupBy(res.data.ResponseData, 'sec_nm1')
                // console.log('options', this.options)
                res.data.ResponseData.map(item => {
                    this.made_nos.push({
                        label: item.made_nm,
                        value: item.made_no
                    })
                })
            }
        },
    },
    mounted() {
        this.GetMadeData()
        // this.GetSecData()
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl
        }

        // dev
        // this.pnl = '68817' 

        // admin
        if(this.pnl === '55129' || this.pnl === '43528' || this.pnl === '68817' ) {
            this.pnl = '5669'
        }
    }
}
</script>

<style scoped>
    /* body {
        margin: 0;
    } */
    .table_title_class {
        font-size: 20px;
        font-weight: bold;
    }
</style>