<template>
    <div>
        <el-form :model="blackForm" :rules="rules" ref="blackForm" size="medium" style="margin-bottom: 10px">
            <el-row :gutter="10">
                <el-col :span="17">
                    <el-form-item label="" prop="cardid">
                        <el-input v-model="blackForm.cardid" placeholder="请输入身份证号、姓名" size="medium"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="7">
                    <el-button 
                        size="medium" 
                        type="primary" 
                        icon="el-icon-full-screen" 
                        style="width: 100%;"
                        @click="GetJSAPI">拍照</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-alert type="error" v-if="this.blackForm.isShow" show-icon style="margin-bottom: 20px">
            <template slot='title'>
                <div class="iconSize">身份证识别失败，请重试！</div>
            </template>
        </el-alert>
        <el-row style="margin-bottom: 20px">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;"
                    type="primary" 
                    @click="submitForm('blackForm')">黑名单验证</el-button>
            </el-col>
        </el-row>
        <el-result v-if="isSuccess" icon="success" title="验证成功" subTitle="此身份证不属于黑名单">
        </el-result>
        <el-result v-if="isError" icon="error" title="验证失败" subTitle="此身份证属于黑名单">
            <!-- <template slot="extra">
                <div>{{errorInfo}}</div> 
            </template> -->
        </el-result>
        <el-table 
            v-if="isError"
            :data="errorTableData" 
            :header-cell-style="{background: '#F56C6C', color: 'white', textAlign: 'center'}" 
            size="mini" 
            v-loading="error_table_loading" 
            border 
            style="margin-top: 15px">
            <el-table-column prop="pname" label="姓名" min-width="58px" align="center">
            </el-table-column>
            <el-table-column prop="pid" label="身份账号" min-width="150px" align="center">
            </el-table-column>
            <el-table-column prop="reason" label="离职原因" min-width="150px" align="center">
            </el-table-column>
            <el-table-column prop="result" label="结果" align="center">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import axios from 'axios'
import conf from '../../conf.js'
import qs from 'qs'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            blackForm: {
                cardid: '',
                isShow: false
            },
            image1: '',
            isSuccess: false,
            isError: false,
            // errorInfo: '',
            errorTableData: '',
            error_table_loading: false,
            rules: {
                cardid: [
                    { required: true, message: '请输入身份证号、姓名', trigger: 'blur' },
                    // { max: 18, min: 17, message: '身份证号码为18位', trigger: 'blur'}
                    { max: 18, message: '身份证号码不超过18位', trigger: 'blur'},
                    { min: 2, message: '姓名不低于2位', trigger: 'blur'}
                ]
            },
        }
    },
    methods: {
        async GetJSAPI() {
            await this.ChooseImage()
        },
        async ChooseImage() {
            wx.chooseImage({
                count: 1, // 默认9
                sizeType: ['compressed'], // sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                sourceType: ['album', 'camera'], // sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                success: res => {
                    var localIds = res.localIds // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                    this.GetLocalImage(localIds[0])
                }
            })
        },
        async GetLocalImage(localId) {
             wx.getLocalImgData({
                localId: localId, // 图片的localID
                success: res => {
                    var localData = res.localData // localData是图片的base64数据，可以用img标签显示
                    this.image1 = localData
                    this.GetAliOCR()
                }
            })
        },
        async GetAliOCR() {
            const loading = this.$loading({
                lock: true,
                text: '身份证识别中，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/AliOCR/GetAliOCR', qs.stringify({
                // IMAGE1: encodeURIComponent(this.image1)
                IMAGE1: this.image1
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                if(res.data.ResponseData.success) {
                    this.blackForm.isShow = false
                    this.blackForm.cardid = res.data.ResponseData.num
                    this.submitForm('blackForm')
                }else {
                    this.blackForm.isShow = true
                    this.isSuccess = false
                    this.isError = false
                }
            }else {
                this.blackForm.isShow = true
                this.isSuccess = false
                this.isError = false
            }
            loading.close()
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.blackForm)
                    this.GetBlackCheck()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async GetBlackCheck() {
            this.errorTableData = []
            this.error_table_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/BlackList/BlackCheck', qs.stringify({
                keyword: this.blackForm.cardid
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                // console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    this.isError = true
                    this.isSuccess = false
                    // this.errorInfo = '离职原因：' + res.data.ResponseData[0].reason
                    res.data.ResponseData.map(item => {
                        this.errorTableData.push({
                            pname: item.pname,
                            pid: item.pid,
                            reason: item.reason,
                            result: item.result
                        })
                    })
                    // console.log('res.data.ResponseData', res.data.ResponseData)
                }else {
                    this.isError = false
                    this.isSuccess = true
                }
            }

            this.error_table_loading = false
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['chooseImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
    },
    mounted() {
        this.GetSignature()
    },
    created() {
    }
}
</script>
