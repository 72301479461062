<template>
    <div>
        <el-form :model="infoForm" :rules="rules" ref="infoForm" label-width="80px">
            <el-form-item label="电话号码" prop="phoneNumber">
                <el-input v-model="infoForm.phoneNumber" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="姓名" prop="personNM">
                <el-input v-model="infoForm.personNM" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="personSex">
                <el-radio-group v-model="infoForm.personSex">
                    <el-radio-button label="男"></el-radio-button>
                    <el-radio-button label="女"></el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="出生日期" prop="personBirthday">
                <el-date-picker
                    v-model="infoForm.personBirthday"
                    type="date"
                    style="width: 100%"
                    placeholder="请选择出生日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="籍贯" prop="personNative">
                <el-input v-model="infoForm.personNative" placeholder="请输入籍贯"></el-input>
            </el-form-item>
            <el-form-item label="学历" prop="personEDU">
                <el-select 
                    v-model="infoForm.personEDU" 
                    placeholder="请选择学历" 
                    style="width: 100%">
                    <el-option
                        v-for="item in EDUs"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="应聘工种" prop="personWorkType">
                <el-input v-model="infoForm.personWorkType" placeholder="请输入应聘工种"></el-input>
            </el-form-item>
            <el-form-item label="介绍人" prop="personIntroducer">
                <el-input v-model="infoForm.personIntroducer" placeholder="请输入介绍人"></el-input>
            </el-form-item>
            <br>
            <el-row>
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="infoForm.isLoading"
                        @click="submitForm('infoForm')">提交</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import conf from '../../conf.js'
import qs from 'qs'
export default {
    data() {
        return {
            infoForm: {
                phoneNumber: '',
                personNM: '',
                personSex: '',
                personBirthday: '',
                personNative: '',
                personEDU: '',
                personWorkType: '',
                personIntroducer: '',
                isLoading: false
            },
            EDUs: [
                {
                    label: '小学',
                    value: '小学'
                },
                {
                    label: '初中',
                    value: '初中'
                },
                {
                    label: '高中',
                    value: '高中'
                },
                {
                    label: '中专',
                    value: '中专'
                },
                {
                    label: '职高',
                    value: '职高'
                },
                {
                    label: '大专',
                    value: '大专'
                },
                {
                    label: '本科',
                    value: '本科'
                },
                {
                    label: '研究生',
                    value: '研究生'
                },
                {
                    label: '博士',
                    value: '博士'
                },
            ],
            rules: {
                personNM: [
                    {required: true, message: '请输入姓名', trigger: 'blur'}
                ],
                personSex: [
                    {required: true, message: '请选择性别', trigger: 'change'}
                ],
                personBirthday: [
                    {required: true, message: '请选择出生日期', trigger: 'change'}
                ],
                personNative: [
                    {required: true, message: '请输入籍贯', trigger: 'blur'}
                ],
                personEDU: [
                    {required: true, message: '请选择学历', trigger: 'change'}
                ],
                personWorkType: [
                    {required: true, message: '请输入应聘工种', trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid){
                    console.log(this.infoForm)
                    this.SaveNewInfo()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        async SaveNewInfo() {
            this.infoForm.isLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/WxHrPersons/SaveNewPerson', qs.stringify({
                PERSON_PHONENO: this.infoForm.phoneNumber,
                PERSON_NM: this.infoForm.personNM,
                PERSON_SEX: this.infoForm.personSex,
                PERSON_BIRTHDAY: moment(this.infoForm.personBirthday).format('YYYY-MM-DD'),
                PERSON_NATIVE: this.infoForm.personNative,
                PERSON_EDU: this.infoForm.personEDU,
                PERSON_WORKTYPE: this.infoForm.personWorkType,
                PERSON_INTRODUCER: this.infoForm.personIntroducer,
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功') {
                setTimeout(function(){
                    window.location.href = '/personSuccess'
                },2000)
            }
        }
    },
    mounted() {
        const phone = localStorage.getItem('PHONE')
        if(phone){
            this.infoForm.phoneNumber = phone
        }
    }
}
</script>