<template>
    <div>
        <el-row style="margin-bottom: 30px;">
            <el-col :span="24">
                <router-link to="/roomCheckCreate">
                    <el-button style="width: 100%" type="primary" size="medium">添加宿舍检查</el-button>
                </router-link>
            </el-col>
        </el-row>
        <el-row :gutter="10" style="margin-bottom: 25px">
            <el-col :span="3" style="font-weight: bold">楼号</el-col>
            <el-col :span="3" v-for="(item, index) in building_nos" :key="index">
                <el-button :type="item.btn_type" size="small" style="width: 100%;font-weight: bold; font-size: 14px" @click="selection(item)">{{item.building_no}}</el-button>
            </el-col>
        </el-row>
        <el-row :gutter="10" style="margin-bottom: 25px">
            <el-col :span="3" style="font-weight: bold">层号</el-col>
            <el-col :span="3" v-for="(item, index) in floor_nos" :key="index">
                <el-button :type="item.btn_types" size="small" style="width: 100%;font-weight: bold; font-size: 14px" @click="selection2(item)">{{item.floor_no}}</el-button>
            </el-col>
        </el-row>
        <el-row :gutter="10" style="margin-bottom: 25px">
            <el-col :span="3" style="font-weight: bold">类型</el-col>
            <el-col :span="9" v-for="item in TagDatas" :key="item.tag_type" style="margin-right: 20px">
                <el-badge :value="item.badge_value === '0' ? null : item.badge_value" class="item" style="width: 100%"> 
                    <el-tag
                        size="medium"
                        style="width: 100%;font-weight: bold; font-size: 14px"
                        :type="item.tag_type"
                        @click="tag_click(item)"
                        :effect="item.tag_effect">
                        {{item.label}}
                    </el-tag>
                </el-badge>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24" style="text-align: right; color: #F56C6C;font-weight: bold;margin-bottom: 10px;font-size: 12px;">注：双击初查数据，进行复查</el-col>
        </el-row>
        <el-table :data="check_rooms" :show-header="false" stripe v-loading="table_loading" size="mini" @row-dblclick="RoomCheckReview">
            <el-table-column prop="col1" width="270px">
                <template slot-scope="scope">
                    <div>
                        <span style="color: #606266; font-weight: bold; font-size: 14px">{{scope.row.ROOM_NO1}}</span>,
                        <span style="color: #606266; font-weight: bold; font-size: 14px">{{scope.row.BED_NO}}</span>床,
                        <span style="color: #606266; font-weight: bold; font-size: 14px">{{scope.row.PNAME}}</span>
                        <span style="margin-left:10px;">
                            <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(scope.row.DB_ID)">
                                <i class="el-icon-picture-outline"></i> 凭证
                            </el-link>
                        </span>
                    </div>
                    <div>
                        <span style="font-size: 11px;color: #606266" v-for="(items, index) in scope.row.entitys" :key="index">{{items.RESULT_NAME}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="col2" align="right">
                <template slot-scope="scope">
                    <div style="color: #E6A23C; font-weight: bold; font-size: 12px">
                        <el-tag size="mini" type="primary" v-if="scope.row.IS_REVIEW === 'Y'">
                            {{scope.row.IS_REVIEW === 'Y' ? '复查' : '初查'}}
                        </el-tag>
                        <el-tag size="mini" type="success" v-if="scope.row.IS_REVIEW !== 'Y'">
                            {{scope.row.IS_REVIEW === 'Y' ? '复查' : '初查'}}
                        </el-tag>
                    </div>
                    <div style="color: #E6A23C; font-weight: bold; font-size: 12px">
                        <el-tag
                            size="mini"
                            type="warning"
                            effect="dark">
                            {{scope.row.CHECK_RESULT}}
                        </el-tag>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import moment from 'moment'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            building_nos: [],
            floor_nos: [],
            fstart: '',
            fend: '',
            building_no: '',
            floor_no: '',
            check_rooms: [],
            tableData: [],
            table_loading: false,
            is_review: '',
            TagDatas: [
                {badge_value: '0', tag_type: 'success', tag_effect: 'plain', label: '初查', type: 'N'},
                {badge_value: '0', tag_type: 'primary', tag_effect: 'plain', label: '复查', type: 'Y'},
            ]
        }
    },
    methods: {
        tag_click(item) {
            this.TagDatas.forEach(el => {
                if(item.tag_type === el.tag_type) {
                    el.tag_effect = el.tag_effect === 'dark' ? 'plain' : 'dark'
                    if(el.tag_effect === 'dark') {
                        this.is_review = el.label === '复查' ? 'Y' : 'N'
                    }
                    
                    this.GetCheckRoomList()
                } else {
                    el.tag_effect = 'plain'
                    this.is_review = ''
                }
            })
        },
        async GetDataCounts() {
            this.data_count_loading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/GetCheckListCounts', qs.stringify({
                fstart: this.fstart,
                fend: this.fend,
                building_no: this.building_no,
                floor_no: this.floor_no
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success){
                res.data.ResponseData.map(item => {
                    this.TagDatas.forEach(el => {
                        if(el.type === item.IS_REVIEW) {
                            el.badge_value = item.REVIEW_COUNT === 0 ? null : item.REVIEW_COUNT
                        }
                    })
                })
            }else {
                this.$message({
                    message: '获取7s数据失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.data_count_loading = false
        },
        async ShowImages(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                            path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
            }
        },
        async GetBuildingNo() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/GetBuildingNo')
            console.log(res)
            this.building_nos = []
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                res.data.ResponseData.forEach(item => {
                    item.btn_type = 'default'
                })

                this.building_nos = res.data.ResponseData
                this.GetCheckRoomList()
            }
        },
        async GetBuildFloor(no) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/GetBuildFloor', qs.stringify({
                no
            }))
            console.log(res)
            this.floor_nos = []
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                res.data.ResponseData.forEach(item => {
                    item.btn_types = 'default'
                })
                
                this.floor_nos = res.data.ResponseData
                this.GetCheckRoomList()
            }
        },
        selection(e) {
            this.building_nos.forEach(item => {
                if(item.building_no === e.building_no) {
                    item.btn_type = 'primary'
                    this.building_no = item.building_no
                    this.GetBuildFloor(item.building_no)
                }else {
                    item.btn_type = 'default'
                }
            })
        },
        selection2(e) {
            this.floor_nos.forEach(item => {
                if(item.floor_no === e.floor_no) {
                    item.btn_types = 'primary'
                    this.floor_no = item.floor_no
                    this.GetCheckRoomList()
                }else {
                    item.btn_types = 'default'
                }
            })
        },
        async GetCheckRoomList() {
            this.table_loading = true
            this.check_rooms = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxCheckrooms/GetCheckRoomList', qs.stringify({
                fstart: this.fstart,
                fend: this.fend,
                building_no: this.building_no,
                floor_no: this.floor_no,
                is_review: this.is_review
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.check_rooms = res.data.ResponseData
            }

            this.GetDataCounts()
            this.table_loading = false
        },
        RoomCheckReview(row) {
            console.log('row', row)
            // if(row.IS_REVIEW === 'Y') {
            //     this.$message({
            //         message: '该房间床位已复查，无需再复查',
            //         center: true,
            //         type: 'error',
            //         duration: 2000
            //     })
            //     return
            // }

            let href = '/roomCheckCreate/' + row.DB_ID
            if(this.building_no) {
                href += '/' + this.building_no
            }
            if(this.floor_no) {
                href += '/' + this.floor_no
            }

            console.log('href', href)
            window.location.href = href

            // window.location.href = '/roomCheckCreate/' + row.DB_ID + '/' + this.building_no + '/' + this.floor_no
            // window.location.href = '/roomCheckCreate2/' + row.DB_ID
        }
    },
    async mounted() {
        if(this.$route.params.b_no) {
            this.building_no = this.$route.params.b_no
        }
        if(this.$route.params.f_no) {
            this.floor_no = this.$route.params.f_no
        }

        await this.GetBuildingNo()
        if(this.$route.params.b_no) {
            this.building_nos.find(item => item.building_no === this.building_no).btn_type = 'primary'
        }
        
        await this.GetBuildFloor(this.building_no)
        if(this.$route.params.f_no) {
            this.floor_nos.find(item => item.floor_no === this.floor_no).btn_types = 'primary'
        }
    },
    created() {
        let weekOfDay = parseInt(moment().format('d'))
        this.fstart = moment().subtract(weekOfDay - 1, 'days').format('YYYY-MM-DD')
        this.fend = moment().add(7 - weekOfDay, 'days').format('YYYY-MM-DD')
    }
}
</script>