<template>
    <div>
        <el-table :data="tableData" size="small">
            <el-table-column prop="label" width="100px" label="记录详情">
            </el-table-column>
            <el-table-column prop="value">
            </el-table-column>
        </el-table>
        <el-table :data="tablesData" size="small">
            <el-table-column prop="label" width="100px" label="执行保养项目">
                 <template slot-scope="scope">
                    <!-- <div></div> -->
                    <div><i style="color: green;font-size: 18px; font-weight: bold" class="el-icon-check"></i> {{scope.row.label}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="value">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
export default {
    data() {
        return {
            tableData: [],
            tablesData: [],
            cno: ''
        }
    },
    methods: {
        async GetAsscheckinfo() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsAsscheckinfos/AsscheckinfoAll', qs.stringify({
                C_NO: this.cno
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.tableData = []
                res.data.ResponseData.checkInfo.forEach(item => {
                    this.tableData.push({
                        label: '设备编号',
                        value: item.ASS_NO
                    })
                    this.tableData.push({
                        label: '设备名称',
                        value: item.ASS_NM
                    })
                    this.tableData.push({
                        label: '所在部门',
                        value: item.SEC_NM
                    })
                    this.tableData.push({
                        label: '设备规格',
                        value: item.ASS_GG
                    })
                    this.tableData.push({
                        label: '保养日期',
                        value: item.C_TIME
                    })
                    this.tableData.push({
                        label: '保养类型',
                        value: '日常保养'
                    })
                    this.tableData.push({
                        label: '保养人员',
                        value: item.C_MAN
                    })
                })    
                this.tablesData = []
                res.data.ResponseData.checkInfos.forEach(item => {
                    this.tablesData.push({
                        label: item.CHECKNO,
                        value: item.CHECKNAME
                    })
                })    
            }
        }
    },
    mounted() {
        const id = this.$route.params.id
        if(id) {
            this.cno = id
            this.GetAsscheckinfo()
        }
    },
    created() {

    }
}
</script>