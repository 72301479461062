<template>
    <div>
        <el-card shadow="always" v-for="(item, index) in check_detail" :key="index" class="box-card" v-loading="loading">
            <el-row>
                <el-col :span="6" style="text-align: left">类别等级</el-col>
                <el-col :span="15" style="text-align: right;font-weight: bold">
                    {{item.DANGER_TYPE1}}，{{item.DANGER_TYPE2}}
                </el-col>
                <el-col :span="3" style="text-align: right">
                    <el-tag v-if="item.DANGER_LEVEL === '红'" size="mini" effect="dark" type="danger">
                        {{item.DANGER_LEVEL}}
                    </el-tag>
                    <el-tag v-if="item.DANGER_LEVEL === '橙'" size="mini" effect="dark" type="warning">
                        {{item.DANGER_LEVEL}}
                    </el-tag>
                    <el-tag v-if="item.DANGER_LEVEL === '黄'" size="mini" effect="dark" color="yellow">
                        {{item.DANGER_LEVEL}}
                    </el-tag>
                    <el-tag v-if="item.DANGER_LEVEL === '蓝'" size="mini" effect="dark" type="">
                        {{item.DANGER_LEVEL}}
                    </el-tag>
                </el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="6" style="text-align: left">区域描述</el-col>
                <el-col :span="18" style="text-align: right">
                    {{item.DISTRICT}}, {{item.CHECK_DESCRIBE}}
                </el-col>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="6" style="text-align: left">单据日期</el-col>
                <el-col :span="18" style="text-align: right">{{item.CHECK_DATE}}</el-col>
            </el-row>
            <el-divider v-if="item.HAVE_IMAGE !== 0" />
            <el-row v-if="item.HAVE_IMAGE !== 0">
                <el-col :span="6" style="text-align: left">问题图片</el-col>
                <el-col :span="18" style="text-align: right">
                    <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.DB_ID)">
                        <i class="el-icon-picture-outline"></i> 点击查看
                    </el-link>
                </el-col>
            </el-row>
            <el-divider v-if="item.HANDLE_ID" />
            <el-row v-if="item.HANDLE_ID">
                <el-col :span="6" style="text-align: left">整改说明</el-col>
                <el-col :span="18" style="text-align: right">
                    {{item.HANDLE_EXPLAIN}}
                </el-col>
            </el-row>
            <el-divider v-if="item.HANDLE_ID" />
            <el-row v-if="item.HANDLE_ID">
                <el-col :span="6" style="text-align: left">整改图片</el-col>
                <el-col :span="18" style="text-align: right">
                    <el-link style="font-weight: bold;font-size: 13px;color: #409EFF" @click="ShowImages(item.HANDLE_ID)">
                        <i class="el-icon-picture-outline"></i> 点击查看
                    </el-link>
                </el-col>
            </el-row>
        </el-card>
        <el-empty v-if="check_detail.length === 0" description="没有数据"></el-empty>
        <el-form 
            :model="ehsDetailForm" 
            ref="ehsDetailForm" 
            :rules="rules" 
            label-width="80px" 
            size="small" 
            v-if="!is_handle"
            style="margin-top: 40px">
            <el-form-item label="整改描述" prop="handle_explain" style="font-weight: bold">
                <el-input type="textarea" :rows="5" v-model="ehsDetailForm.handle_explain" placeholder="请输入整改内容描述"></el-input>
            </el-form-item>
            <el-form-item label="整改图片" prop="fileList" style="font-weight: bold;">
                <el-upload 
                    action='' 
                    ref="upload"
                    :on-change="getFile" 
                    :limit="3" 
                    :on-remove="handleRemove"
                    list-type="picture"
                    :auto-upload="false" 
                    :accept="'image/*'"
                    :on-exceed="handleExceed"
                    style="width: 100%;" >
                    <el-button size="medium" type="info">选择图片<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item>
            <el-row style="margin-top: 10px;margin-bottom: 20px" v-if="this.beSubmit">
                <el-col :span="24">
                    <el-button 
                        style="width: 100%;"
                        type="primary" 
                        :loading="isLoading"
                        @click="SubmitForm('ehsDetailForm')">提交EHS稽查整改</el-button>
                </el-col>
            </el-row>
            <el-row style="margin-top: 10px;" v-if="this.beCancel">
                <el-col :span="24">
                    <el-button 
                        plain
                        style="width: 100%;"
                        type="primary" 
                        :loading="isLoading"
                        @click="BackToEdit">撤回到编辑页面</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
import wx from 'weixin-js-sdk'
import { compressImage } from '../../../plugin/compress.js'
import moment from 'moment'
export default {
    data() {
        return {
            loading: false,
            db_id: '',
            pnl: '',
            is_handle: true,
            check_detail: [],
            ehsDetailForm: {
                handle_explain: '',
                danger_level: ''
            },
            isLoading: false,
            fileList: [],
            imageList: [],
            beCancel: false,
            beSubmit: false,
            rules: {
                handle_explain: [
                    {required: true, message: '请输入整改内容描述', trigger: 'blur'}
                ],
            }
        }
    },
    methods: {
        BackToEdit() {
            if(this.$route.params.id) {
                window.location.href = '/ehsCheckCreate2' + '/' + this.$route.params.id + '/2'
            }
        },
        async ShowImages(id) {
            this.imageList = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/Timages/GetImagesByID', qs.stringify({
                id
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                if(res.data.ResponseData.length > 0) {
                    res.data.ResponseData.map(item => {
                        let path = conf.image_domain + item.FImgPath
                        this.imageList.push(
                            path
                        )
                    })

                    wx.previewImage({
                        current: this.imageList[0], // 当前显示图片的 http 链接
                        urls: this.imageList // 需要预览的图片 http 链接列表
                    })
                }
            }
        },
        async GetCheckDetail(db_id) {
            this.loading = true
            this.check_detail = []
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/GetEhsCheckDetail', qs.stringify({
                db_id,
                pnl: this.pnl
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.check_detail = res.data.ResponseData
                this.is_handle = this.check_detail[0].HANDLE_ID === null || this.check_detail[0].HANDLE_ID === '' ? false : true
                this.ehsDetailForm.danger_level =  this.check_detail[0].DANGER_LEVEL
                console.log('this.is_handle', this.is_handle)
                console.log(moment().diff(this.check_detail[0].CHECK_DATE, 'days'))
                if(moment().diff(this.check_detail[0].CHECK_DATE, 'days') <= 1 && this.check_detail[0].DB_CREATE_USERNO === this.pnl){
                    this.beCancel = true
                }
                if(this.check_detail[0].is_pass === 1) {
                    this.beSubmit = true
                }
            }
            this.loading = false
        },
        SubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if(valid && this.CustomValidate()){
                    this.UpdateEhsCheck()
                }else{
                    console.log(valid.message)
                    return false
                }
            })
        },
        CustomValidate() {
            if(this.fileList.length === 0) {
                this.$message({
                    message: '请上传违规图片',
                    center: true,
                    type: 'error',
                    duration: 1000
                })
                return false
            }
            return true
        },
        async UpdateEhsCheck() {
            this.isLoading = true
            const dto = {
                DB_ID: this.db_id,
                PNL2: this.pnl,
                HANDLE_EXPLAIN: this.ehsDetailForm.handle_explain,
                DANGER_LEVEL: this.ehsDetailForm.danger_level
            }
            console.log('dto', dto)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/HqWxEhsCheck/UpdateEhsCheck', qs.stringify({
                dto,
                IMAGE1: encodeURIComponent(this.imageList[0]),
                IMAGE2: encodeURIComponent(this.imageList[1]),
                IMAGE3: encodeURIComponent(this.imageList[2]),
                FILENAME1: this.fileList[0],
                FILENAME2: this.fileList[1],
                FILENAME3: this.fileList[2]
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0 && res.data.Message === '成功'){
                this.$message({
                    message: '稽查整改提交成功',
                    center: true,
                    type: 'success'
                })
                setTimeout(()=> {
                    window.location.href = '/ehsCheckList'
                }, 1000)
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success && res.data.Code === 0) {
                this.$message({
                    message: '提交检查问题失败：' + res.data.Message,
                    center: true,
                    type: 'warning'
                })
            }else if(res.status === 200 && res.statusText === 'OK' && res.data.Success === false) {
                 this.$message({
                    message: '提交检查问题失败：' + res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        getFile(file, fileList) {
            console.log('file', file)
            this.fileList = []
            this.imageList = []
            fileList.forEach(item => {
                this.fileList.push(item.name)
                if(!this.beforeAvatarUpload(item)) {
                    return
                }
                let quality = 1
                let size = this.getSize(item)
                if(size > 1 && size < 2) {
                    quality = 0.5
                }else if(size > 2) {
                    quality = 0.2
                }
                compressImage(item.url, {
                    width: 390, // 压缩后图片的宽
                    // height: 200, // 压缩后图片的高，宽高若只传一个，则按图片原比例进行压缩
                    quality: quality // 压缩后图片的清晰度，取值0-1，不传默认为0.7，值越小，所绘制出的图像越模糊
                    }).then(result => { // result即为压缩后的结果
                    this.imageList.push(result)
                })
                console.log('this.imageList', this.imageList)
            })
        },
        handleRemove(file, fileList) {
            console.log('file', file)
            let index = fileList.indexOf(file)
            if(index > -1) {
                fileList.splice(index, 1)
            }

            this.fileList = []
            this.imageList = []
            fileList.forEach(item => {
                this.fileList.push(item.name)
                this.getBase64(item.raw).then(res => {
                    this.imageList.push(res)
                })
            })
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.onloadend = function() {
                    resolve(imgResult);
                }
            })
        },
        getSize(file) {
            return file.size / 1024 / 1024 
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isJPG && !isPNG) {
                this.$message.error('上传凭证图片只能是 JPG、PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传凭证图片大小不能超过 10MB!')
            }

            return isLt2M && (isJPG || isPNG)
        },
        handleExceed() {
            this.$message({
                message: '只允许最多上传三张照片',
                type: 'warning',
                center: true
            })
        },
        async GetSignature() {
            const timestamp = Math.round(new Date() / 1000)
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/TokenAuthorize/Signature', qs.stringify({
                timestamp: timestamp,
                nonceStr: conf.JSSDKInfo.nonceStr,
                url: decodeURIComponent(window.entryUrl)
            }))
            console.log(res)
            if(res.data.Code === 0 && res.status === 200 && res.statusText === 'OK') {
                console.log(res.data.ResponseData)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: conf.weixinInfo.appid, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: conf.JSSDKInfo.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.ResponseData,// 必填，签名
                    jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
                })
            }
        },
    },
    mounted() {
        this.db_id = this.$route.params.id
        console.log('id', this.db_id)
        if(this.db_id) {
            this.GetCheckDetail(this.db_id)
            this.GetSignature()
        }
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl){
            this.pnl = pnl 

            // this.pnl = '60347' // dev
            // this.pnl = '14657'
            // this.pnl = '0332' // dev
            // this.pnl = '64181' // dev
        }
    }
}
</script>

<style scoped>
    .box-card{
        margin-top: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 13px;
    } 
    .col-danger {
        color: #F56C6C;
        font-weight: bold;
    }
</style>