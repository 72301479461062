<template>
    <div>
        <el-table :data="tableData" size="small">
            <el-table-column prop="label" width="100px" label="设备详情">
            </el-table-column>
            <el-table-column prop="value">
            </el-table-column>
        </el-table>
        <el-table :data="tablesData" size="small" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="42" prop="ISOK">
            </el-table-column>
            <el-table-column prop="CHECKNO" width="82px" label="保养项目">
            </el-table-column>
            <el-table-column prop="CHECKNAME">
            </el-table-column>
        </el-table>
        <el-row style="margin-top: 20px">
            <el-col :span="24">
                <el-button 
                    style="width: 100%;" 
                    type="primary" 
                    :loading="isLoading"
                    @click="Maintain">提交</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import conf from '../../../conf'
export default {
    data() {
        return {
            tableData: [],
            tablesData: [],
            cno: '',
            pnl: '',
            pname: '',
            multipleSelection: [],
            n: 1,
            isLoading: false
        }
    },
    methods: {
        async Maintain() {
            if(this.multipleSelection.length === 0) {
                this.$message({
                    message: '请选择保养项目',
                    center: true,
                    type: 'error'
                })
                return
            }
            const dto = {
                ASS_NO: this.cno,
                C_TYPE: 0,
                C_MAN: this.pnl + '-' + this.pname 
            }
            for (let index = 0; index < this.tablesData.length; index++) {
                if(this.multipleSelection.indexOf(this.tablesData[index])> -1) {
                    this.tablesData[index].ISOK = true
                }else{
                    this.tablesData[index].ISOK = false
                }
            }

            const dtos = this.tablesData
            console.log('dtos', dtos)
            this.isLoading = true
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsAsscheckinfos/Maintain', qs.stringify({
                dto, dtos
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.$message({
                    message: '提交成功！',
                    center: true,
                    type: 'success'
                })
                setTimeout(function(){
                    console.log('this.cno', this.cno)
                    window.location.href = '/maintain/' + dto.ASS_NO
                },1000)
            }else{
                this.$message({
                    message: res.data.Message,
                    center: true,
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
            console.log('val', val)
        },
        async GetAsscheckinfo() {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsAssets/AssetByNo', qs.stringify({
                ASS_NO: this.cno
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.tableData = []
                res.data.ResponseData.forEach(item => {
                    this.tableData.push({
                        label: '设备编号',
                        value: this.cno
                    })
                    this.tableData.push({
                        label: '设备名称',
                        value: item.ASS_NM
                    })
                    this.tableData.push({
                        label: '所在部门',
                        value: item.SEC_NM
                    })
                    this.tableData.push({
                        label: '设备规格',
                        value: item.ASS_GG
                    })
                    this.tableData.push({
                        label: '存放地点',
                        value: item.ADDR
                    })
                    this.GetCheckList(item.TYPE_NO)
                })    
            }
        },
        async GetCheckList(TYPE_NO) {
            const res = await axios.post(conf.api_url + '/gateway/weixinapi/RzsAsscheckinfos/CheckList', qs.stringify({
                TYPE_NO,
                CHECKTYPE: this.n
            }))
            console.log(res)
            if(res.status === 200 && res.statusText === 'OK' && res.data.Success) {
                this.tablesData = []
                res.data.ResponseData.forEach(item => {
                    this.tablesData.push({
                        CHECKNO: item.CheckNo,
                        CHECKNAME: item.CheckName,
                        ISOK: false
                    })
                })    
            }
        }
    },
    mounted() {
        const id = this.$route.params.id
        if(id) {
            this.cno = id
            this.GetAsscheckinfo()
        }
        const n = this.$route.params.n
        if(n) {
            this.n = n
            this.$route.meta.title = '设备' + parseInt(parseInt(n) + 1) + '级保养作业'
        }
    },
    created() {
        const pnl = localStorage.getItem('PNL')
        if(pnl) {
            this.pnl = pnl
        }
        const pname = localStorage.getItem('PNAME')
        if(pname) {
            this.pname = pname
        }
    }
}
</script>